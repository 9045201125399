import { PollItem } from '../../live-polling/models/poll-item.model';
import { Poll, PollGroup, PollIntermission, PollResponse } from '@shared/api';
import { sortBy, orderBy, clamp } from 'lodash';

export function createPollItem(poll: Poll, pollResponses: PollResponse[]): PollItem {
    const choicePoll = poll.choicepoll;
    const textPoll = poll.textpoll;
    const ratingPoll = poll.ratingpoll;
    const choicePollChoices = choicePoll ? sortBy(choicePoll.choices, 'order') : undefined;

    const pollResponse = pollResponses ? pollResponses.find((r) => r.poll === poll.id) : undefined;

    return {
        poll,
        textPoll,
        ratingPoll,
        choicePoll,
        choicePollChoices,
        pollResponse
    };
}

export function calculateFirstItems(pollGroupDetail: PollGroup): {
    currentPoll?: number;
    currentPollIntermission?: number;
} {
    const firstItem = (pollGroupDetail.polls as any[])
        .concat(pollGroupDetail.intermissions)
        .sort((a, b) => a.order - b.order)[0];
    return {
        currentPoll: firstItem && pollGroupDetail.polls.includes(firstItem) ? firstItem.id : undefined,
        currentPollIntermission: firstItem && !pollGroupDetail.polls.includes(firstItem) ? firstItem.id : undefined
    };
}

export function getCurrentItemOrder(
    pollItem: PollItem,
    pollIntermission: PollIntermission,
    pollGroupDetail: PollGroup
): number {
    const orderedItemIds: any = (pollGroupDetail.polls as any)
        .concat(pollGroupDetail.intermissions)
        .sort((a, b) => a.order - b.order)
        .map((item) => item.id);
    const itemId = pollItem ? pollItem.poll.id : pollIntermission ? pollIntermission.id : undefined;
    return orderedItemIds.indexOf(itemId) + 1;
}

export function calculateNextPoll(
    currentPoll: number,
    currentPollIntermission: number,
    polls: Poll[],
    intermissions: PollIntermission[],
    relativeChange: -1 | 1
): { currentPoll?: number; currentPollIntermission?: number } {
    const allItems = orderBy<any>([...polls, ...intermissions], 'order');
    const currentIndex = allItems.findIndex((i) => i.id === (currentPoll ? currentPoll : currentPollIntermission));

    const newIndex = clamp(currentIndex + relativeChange, -1, allItems.length);

    const item = allItems[newIndex];

    // Reached the end, survey is complete
    if (!item) {
        return {
            currentPoll: undefined,
            currentPollIntermission: undefined
        };
    }

    const itemId = item.id;
    // Poll intermissions dont have poll_types
    const isPoll = !!item.poll_type;

    return {
        currentPoll: isPoll ? itemId : undefined,
        currentPollIntermission: !isPoll ? itemId : undefined
    };
}
