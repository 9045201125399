import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { VideoCallApiService } from '../../services/video-call-api.service';
import { VideoCallsApiActions } from '../actions/video-calls-api.actions';
import { tap, switchMap, map, catchError, withLatestFrom, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { of, forkJoin } from 'rxjs';
import { ToastService } from '../../../../core/toast/services/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { AblyMessagingService } from '../../../../core/ably/services/ably-messaging.service';
import { VideoCallService } from '../../services/video-call.service';
import { AuthenticationFacadeService } from '../../../../core/authentication/services/authentication-facade.service';
import { RealtimeActions } from '../../../../core/root-store/store/realtime/actions/realtime.actions';
import { AuthenticationActions } from '../../../../core/root-store/store/authentication/actions/authentication.actions';
import { VideoCallIncomingModalService } from '@features/video-calls/services/video-call-incoming-modal.service';

@Injectable()
export class VideoCallApiEffects {
    subscribeToAblyVideoCallDeclineEvents$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(RealtimeActions.connectedToPrivateChannel),
                withLatestFrom(this.authenticationFacadeService.getAuthenticatedPerson()),
                switchMap(([{}, authedPerson]) =>
                    this.ablyMessagingService.getMessageStream([AblyVideoCallEvent.VideoCallDeclined], true).pipe(
                        tap(({ data }) => {
                            const message: { first_name: string; last_name: string; cancelled_by: number } =
                                JSON.parse(data);
                            // Only show the caller unavailable dialog if it was cancelled by the other user
                            if (authedPerson && authedPerson.id !== message.cancelled_by) {
                                this.videoCallService.showCallerUnavailableDialog(message);
                            }
                            this.videoCallIncomingModalService.dismissIncomingVideoCallDialog();
                        }),
                        takeUntil(this.actions$.pipe(ofType(AuthenticationActions.logout)))
                    )
                )
            ),
        { dispatch: false }
    );

    inviteToCall$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(VideoCallsApiActions.inviteToCallSuccess),
                tap(({ incomingCall }) => this.videoCallService.setUrl(incomingCall.video_call_link)),
                switchMap(() =>
                    this.ablyMessagingService.getMessageStream([AblyVideoCallEvent.VideoCallAccepted], true)
                )
            ),
        { dispatch: false }
    );

    blockCaller$ = createEffect(() =>
        this.actions$.pipe(
            ofType(VideoCallsApiActions.blockCaller),
            switchMap(({ incomingCall }) => {
                return this.videoCallApiService.blockCaller(incomingCall.decline_call_endpoint).pipe(
                    map(() => VideoCallsApiActions.blockCallerSuccess({ fName: incomingCall.first_name })),
                    catchError((error: HttpErrorResponse) =>
                        of(VideoCallsApiActions.blockCallerFailure({ error: error.message }))
                    )
                );
            })
        )
    );

    blockCallerSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(VideoCallsApiActions.blockCallerSuccess),
                switchMap(({ fName }) =>
                    forkJoin([this.translateService.get('VIDEO_CALLER_BLOCKED_TOAST_MESSAGE'), of(fName)])
                ),
                tap(([translation, fName]) => this.toastService.success(`${fName} ${translation}`))
            ),
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private videoCallApiService: VideoCallApiService,
        private toastService: ToastService,
        private translateService: TranslateService,
        private ablyMessagingService: AblyMessagingService,
        private videoCallService: VideoCallService,
        private videoCallIncomingModalService: VideoCallIncomingModalService,
        private authenticationFacadeService: AuthenticationFacadeService
    ) {}
}

export enum AblyVideoCallEvent {
    VideoCallAccepted = 'video_call_accepted',
    VideoCallDeclined = 'video_call_declined'
}
