import { Injectable } from '@angular/core';
import { DeviceService } from '../../../core/device/services/device.service';
import { StorageService } from '../../../core/storage/services/storage.service';

export const CONTAINER_KEY = 'cc-container-new';

@Injectable({
    providedIn: 'root'
})
export class ContainerService {
    private readonly containerAppNames = ['container', 'app', 'summit'];

    constructor(
        private storageService: StorageService,
        private deviceService: DeviceService
    ) {}

    public getApp(): string {
        return this.storageService.get(CONTAINER_KEY);
    }

    public setApp(app: string): void {
        this.storageService.put(CONTAINER_KEY, app);
    }

    public resetApp(): void {
        this.storageService.clear(CONTAINER_KEY);
    }

    public isRunningInContainer(appUrl: string): boolean {
        const app = this.getApp();
        return app === appUrl;
    }

    public async isContainerApp(appUrl: string): Promise<boolean> {
        if (!this.deviceService.isNative()) {
            return this.containerAppNames.includes(appUrl);
        }

        const nativeAppUrl = await this.deviceService.getNativeAppUrl();
        return this.containerAppNames.includes(nativeAppUrl);
    }
}
