import { createFeatureSelector, createSelector } from '@ngrx/store';
import { infoPageStoreKey } from '../models/info-page-app-state.model';
import { InfoPageState } from '../models/info-page-module-state.model';

const selectInfoPageDetailsPageState = createFeatureSelector<InfoPageState>(infoPageStoreKey);

const selectInfoPage = () =>
    createSelector(selectInfoPageDetailsPageState, (infoPageState: InfoPageState) => {
        if (!infoPageState || !infoPageState.infoPageDetailsPage || !infoPageState.infoPageDetailsPage.infoPage) {
            return;
        }
        return infoPageState.infoPageDetailsPage.infoPage;
    });

const selectPageState = () =>
    createSelector(selectInfoPageDetailsPageState, (infoPageState: InfoPageState) => {
        return infoPageState.infoPageDetailsPage.initialising;
    });

const selectTitle = createSelector(selectInfoPageDetailsPageState, (state) => {
    return state.infoPageDetailsPage?.infoPage?.title;
});

export const InfoPageDetailsPageSelectors = {
    selectInfoPage,
    selectPageState,
    selectTitle
};
