<ion-header
    *ngIf="{
        canChat: canChat$ | async,
        canVideoCall: canVideoCall$ | async,
        canUseMeetingBooking: canUseMeetingBooking$ | async
    } as personHeaderData"
    class="person-header__container"
>
    <div class="container">
        <div class="person-header__action-container">
            <div class="icon-container">
                <cc-person-icon
                    size="xlarge"
                    [person]="person"
                    (profilePictureClicked)="onZoomPicture()"
                ></cc-person-icon>
                <cc-online-status *ccIfAuthenticated [deviceIds]="person.device_ids"></cc-online-status>
            </div>
            <div class="person-details">
                <ion-text>
                    <h4 class="title-margin">
                        <cc-person-name [person]="person"></cc-person-name>
                    </h4>
                </ion-text>
                <ion-label *ngIf="person?.job_title">
                    {{ person.job_title }}
                </ion-label>
                <ion-label *ngIf="person?.company">
                    {{ person.company }}
                </ion-label>
                <ion-label *ngIf="person?.address">
                    {{ person.address }}
                </ion-label>
                <cc-tag-list *ngIf="peopleTags" class="person-header__tags" [tags]="peopleTags"></cc-tag-list>
            </div>
        </div>
        <div *ngIf="actions" class="person-actions">
            <cc-person-header-button
                *ngIf="person.email && actions[PersonHeaderAction.Email] !== false"
                label="PEOPLE_BUTTON_EMAIL"
                icon="email"
                classes="email-button"
                [ccActionPopover]="ActionPopoverType.Email"
                [actionContent]="person.email"
            ></cc-person-header-button>

            <cc-person-header-button
                *ngIf="personHeaderData.canVideoCall && actions[PersonHeaderAction.VideoCall] !== false"
                class="cc-video-call"
                classes="video-call-button"
                icon="videocam"
                [label]="person.accepts_video_calls ? 'PEOPLE_BUTTON_VIDEO' : 'PEOPLE_BUTTON_VIDEO_UNAVAILABLE'"
                (click)="onVideoCall()"
            ></cc-person-header-button>

            <cc-person-header-button
                *ngIf="person.phone && actions[PersonHeaderAction.Phone] !== false"
                classes="phone-button"
                label="PEOPLE_BUTTON_PHONE"
                icon="call"
                [ccActionPopover]="ActionPopoverType.Phone"
                [actionContent]="person.phone"
            ></cc-person-header-button>

            <cc-person-header-button
                *ngIf="person.website && actions[PersonHeaderAction.Website] !== false"
                classes="website-button"
                label="PEOPLE_BUTTON_WEBSITE"
                icon="website"
                [ccActionPopover]="ActionPopoverType.Website"
                [actionContent]="person.website"
            ></cc-person-header-button>

            <cc-person-header-button
                *ngIf="personHeaderData.canChat && actions[PersonHeaderAction.Chat] !== false"
                class="cc-chat"
                classes="chat-button"
                label="PEOPLE_BUTTON_CHAT"
                icon="chat"
                (click)="onChat()"
            ></cc-person-header-button>

            <cc-person-header-button
                *ngIf="personHeaderData.canUseMeetingBooking && actions[PersonHeaderAction.VideoCall] !== false"
                classes="meeting-button"
                icon="today"
                label="PEOPLE_BUTTON_SCHEDULE_MEETING"
                (click)="onScheduleMeeting()"
            ></cc-person-header-button>
        </div>
    </div>
</ion-header>
