import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SlimPeople as SlimPerson } from '@shared/api';
import { TooltipOptions } from 'ng2-tooltip-directive-major-angular-updates';

@Component({
    selector: 'cc-agenda-card-attendee-item',
    templateUrl: './agenda-card-attendee-item.component.html',
    styleUrl: './agenda-card-attendee-item.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgendaCardAttendeeItemComponent {
    @Input({ required: true })
    public attendee: SlimPerson;

    public tooltipOptions: TooltipOptions = {
        'hide-delay': 0,
        'show-delay': 100,
        'animation-duration': 200
    };
}
