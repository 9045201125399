import { Injectable } from '@angular/core';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { AuthenticationFacadeService } from '@core/authentication/services/authentication-facade.service';
import { MeetingBookingApiService } from '@features/meeting-booking/services/meeting-booking-api/meeting-booking-api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, of, switchMap, withLatestFrom } from 'rxjs';
import { AppCoreActions } from '../../app-core/actions/app-core.actions';
import { GlobalSettingsActions } from '../actions/global-settings.actions';

@Injectable()
export class GlobalSettingsEffects {
    public fetchMeetingBookingConfig$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppCoreActions.initialiseAppSuccess),
            switchMap(() => this.authenticationFacadeService.getAuthenticatedPerson()),
            filter((person) => person?.meeting_booking_enabled),
            withLatestFrom(this.appCoreFacadeService.getAppName()),
            switchMap(([_, appName]) =>
                this.meetingBookingApiService.getMeetingBookingConfig(appName).pipe(
                    map((res) => GlobalSettingsActions.fetchMeetingBookingConfigSuccess({ config: res })),
                    catchError(() => of(GlobalSettingsActions.fetchMeetingBookingConfigFailure()))
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private appCoreFacadeService: AppCoreFacadeService,
        private authenticationFacadeService: AuthenticationFacadeService,
        private meetingBookingApiService: MeetingBookingApiService
    ) {}
}
