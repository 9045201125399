<ion-card
    class="alert-list-item__card"
    [ngClass]="{ unread: !notification.read && !notification.archived }"
    role="listitem"
    @fadeIn
    @fadeOut
>
    <ion-item lines="none" [attr.aria-label]="'ALERTS_ARIA_LABEL_ITEM' | translate">
        <ion-label class="ion-text-wrap">
            <div class="alert-list-item__header">
                <h2
                    class="alert-list-item__title"
                    [style.font-weight]="!notification.read && !notification.archived ? 600 : 400"
                >
                    {{ alert.title }}
                </h2>

                <div class="alert-list-item__actions">
                    <ion-button
                        *ngIf="!notification.read"
                        class="alert-list-item__action"
                        fill="clear"
                        [tooltip]="'ALERTS_MARK_READ' | translate"
                        [options]="tooltipOptions"
                        (click)="markAsRead.emit()"
                    >
                        <i slot="start" class="material-icons-outlined" aria-hidden="true">mark_email_read</i>
                    </ion-button>
                    <ion-button
                        class="alert-list-item__action"
                        fill="clear"
                        [tooltip]="(!notification.archived ? 'ALERTS_ARCHIVE' : 'ALERTS_UNARCHIVE') | translate"
                        [options]="tooltipOptions"
                        (click)="onToggleArchive()"
                    >
                        <i slot="start" class="material-icons-outlined" aria-hidden="true">{{
                            !notification.archived ? 'archive' : 'unarchive'
                        }}</i>
                    </ion-button>
                </div>
            </div>

            <p class="alert-list-item__time">{{ alert.start | dateRelative }}</p>
        </ion-label>
    </ion-item>

    <div
        class="alert-list-item__content wysiwyg-content"
        [ccCompileHtml]="alert.description_rich || alert.description || alert.description_short"
    ></div>

    <ion-item *ngIf="alert.alert_type === 'popup' && alert.embedded_survey_id" lines="none">
        <cc-alert-survey-container
            class="alert-list-item__survey"
            [pollGroupId]="alert.embedded_survey_id"
        ></cc-alert-survey-container>
    </ion-item>

    <ion-item *ngIf="alert.alert_type === 'persistent' && alert.deeplink_url" lines="none">
        <div class="alert-list-item__footer-buttons">
            <ion-button class="alert-list-item__footer-button" (click)="onAlertAction()" color="primary">
                {{ alert.cta_button_text }}
            </ion-button>
        </div>
    </ion-item>
</ion-card>
