import { NgModule } from '@angular/core';

import {
    nativeClassesInitializer,
    nativeBackUpInitializer,
    nativeEventsInitializer
} from './core/native/native.module';
import { sentryInitializer } from './core/error-handling/error-handling.module';

@NgModule({
    providers: [nativeClassesInitializer, nativeEventsInitializer, nativeBackUpInitializer, sentryInitializer]
})
export class AppLoadModule {}
