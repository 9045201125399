import { createSelector } from '@ngrx/store';
import { AppState } from '../../app-state.model';

const selectGlobalSettingsState = (state: AppState) => state.globalSettings;

const meetingsState = createSelector(selectGlobalSettingsState, (state) => state.meetingBookingConfig);
const canCreateDelegateMeetings = createSelector(meetingsState, (state) => state?.delegate_to_delegate);
const canCreateExhibitorMeetings = createSelector(meetingsState, (state) => state?.delegate_to_exhibitor);

export const GlobalSettingsSelectors = {
    meetingsState,
    canCreateDelegateMeetings,
    canCreateExhibitorMeetings
};
