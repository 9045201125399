import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Module } from '@shared/api';
import { ApiSettings } from '@shared/settings/api-settings.constant';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ModuleApiService {
    constructor(private http: HttpClient) {}

    public getModule(appName: string, moduleId: number | string): Observable<Module> {
        const url = ApiSettings.fullUrl(appName, ModuleApiEndpoints.module(moduleId));
        return this.http.get<Module>(url);
    }
}

export const ModuleApiEndpoints = {
    modules: '/modules/',
    module: (moduleId: number | string) => `/modules/${moduleId}/`
};
