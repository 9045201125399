<ion-header>
    <ion-toolbar class="tabbar-more__toolbar" color="sidebar">
        <ion-title class="cc-primary-header">{{ 'TABBAR_MENU' | translate }}</ion-title>
        <ion-buttons slot="end">
            <ion-button fill="clear" (click)="dismiss()">
                <i class="material-icons" slot="icon-only" aria-label="Close">close</i>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content class="tabbar-more__content" color="sidebar">
    <ion-list lines="none">
        <ion-item
            *ngFor="let module of modules"
            class="tabbar-more__item"
            color="sidebar"
            button
            [class.selected]="selectedModuleId === module.id"
            [routerLink]="module.routeCommands"
            (click)="onModuleClick()"
        >
            <cc-module-icon slot="start" [module]="module" [attr.aria-label]="module.name"></cc-module-icon>
            <ion-label>{{ module.name }}</ion-label>
        </ion-item>
    </ion-list>

    <ion-list class="tabbar-more__footer" lines="none">
        <ion-item *ngIf="portalConfig" class="tabbar-more__item" color="sidebar" button (click)="portalReturn()">
            <i aria-hidden="true" slot="start" class="material-icons">apps</i>
            <ion-label> {{ 'SIDEBAR_LEFT_BACK_TO_PORTAL' | translate }} {{ portalConfig.portal_name }} </ion-label>
        </ion-item>

        <ion-item class="tabbar-more__item" color="sidebar" button (click)="openTerms()">
            <i aria-hidden="true" slot="start" class="material-icons">policy</i>
            <ion-label>
                {{ 'SIDEBAR_LEFT_TERMS_AND_CONDITIONS' | translate }}
            </ion-label>
        </ion-item>

        <cc-experimental-switch></cc-experimental-switch>
    </ion-list>
</ion-content>
