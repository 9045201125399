import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieEndpoints } from '@core/banner/constants/cookie-consent-endpoints.constant';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CookieApiService {
    constructor(private httpClient: HttpClient) {}

    public setCookieConsent(appName: string, consent: boolean): Observable<any> {
        const url = CookieEndpoints.analyticsConsent(appName);
        return this.httpClient.post(url, { analytics_opt_in: consent });
    }
}
