import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retryBackoff } from 'backoff-rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class RateLimitInterceptor implements HttpInterceptor {
    constructor() {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            retryBackoff({
                initialInterval: 300,
                maxRetries: 5,
                shouldRetry: (errorResponse: HttpErrorResponse) =>
                    errorResponse.status && [429, 503].includes(errorResponse.status)
            })
        );
    }
}
