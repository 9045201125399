import { NgModule } from '@angular/core';
import { AppCommonModule } from '@common/common.module';
import { EnvironmentModule } from '@core/environment/environment.module';
import { LanguagesModule } from '@features/languages/languages.module';
import { BannerAdModule } from '@shared/banner-ad/banner-ad.module';
import { ModuleIconComponent } from './components/module-icon/module-icon.component';
import { SidebarLeftContainerComponent } from './components/sidebar-left-container/sidebar-left-container.component';
import { SidebarLeftIconsComponent } from './components/sidebar-left-icons/sidebar-left-icons.component';
import { SidebarLeftListComponent } from './components/sidebar-left-list/sidebar-left-list.component';
import { SidebarRightComponent } from './components/sidebar-right/sidebar-right.component';
import { TabbarMoreComponent } from './components/tabbar-more/tabbar-more.component';
import { TabbarComponent } from './components/tabbar/tabbar.component';
import { ToolbarLeftComponent } from './components/toolbar-left/toolbar-left.component';
import { ToolbarRightComponent } from './components/toolbar-right/toolbar-right.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { NavigationStoreModule } from './store/navigation-store.module';
import { RouterModule } from '@angular/router';
import { CustomCssSwitchComponent } from './components/custom-css-switch/custom-css-switch.component';

@NgModule({
    imports: [AppCommonModule, LanguagesModule, NavigationStoreModule, EnvironmentModule, BannerAdModule, RouterModule],
    declarations: [
        ToolbarRightComponent,
        ToolbarLeftComponent,
        ToolbarComponent,
        SidebarRightComponent,
        SidebarLeftListComponent,
        SidebarLeftIconsComponent,
        SidebarLeftContainerComponent,
        TabbarComponent,
        TabbarMoreComponent,
        ModuleIconComponent,
        CustomCssSwitchComponent
    ],
    exports: [ToolbarComponent, SidebarRightComponent, SidebarLeftContainerComponent, TabbarComponent]
})
export class NavigationModule {}
