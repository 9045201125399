import { Injectable } from '@angular/core';
import { NavigationHistoryFacadeService } from './navigation-history-facade.service';
import { NavigationHistoryActions } from '../store/actions/navigation-history.actions';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class NavigationHistoryService {
    constructor(private navigationHistoryFacadeService: NavigationHistoryFacadeService) {}

    public stamp(): void {
        this.navigationHistoryFacadeService.dispatch(NavigationHistoryActions.stamp());
    }

    public forget(): void {
        this.navigationHistoryFacadeService.dispatch(NavigationHistoryActions.forget());
    }

    public back(): void {
        this.navigationHistoryFacadeService.dispatch(NavigationHistoryActions.back());
    }

    public canGoBack(): Observable<boolean> {
        return this.navigationHistoryFacadeService.getHistory().pipe(map((history) => history.length > 1));
    }
}
