import { Injectable, Type } from '@angular/core';
import { ConfirmDialogOptions } from '@core/dialog/interfaces/confirm-dialog-options.interface';
import { ErrorDialogOptions } from '@core/dialog/interfaces/error-dialog-options.interface';
import { PasswordDialogOptions } from '@core/dialog/interfaces/password-dialog-options.interface';
import { SuccessDialogOptions } from '@core/dialog/interfaces/success-dialog-options.interface';
import { AlertController, ModalController, ModalOptions } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, from, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DialogService {
    constructor(
        private translateService: TranslateService,
        private alertsController: AlertController,
        private modalController: ModalController
    ) {}

    public openDialog<TComponent, TReturn>(options: ModalOptions<Type<TComponent>>): Observable<TReturn> {
        return from(this.modalController.create(options)).pipe(
            switchMap((modal) =>
                from(modal.present()).pipe(
                    switchMap(() => modal.onWillDismiss()),
                    map((result) => result.data)
                )
            )
        );
    }

    public showError(options?: ErrorDialogOptions): Observable<any> {
        const translateParams = options?.translateParams ?? {};

        return combineLatest({
            title: this.translateService.get('DIALOG_ERROR_TITLE', translateParams),
            message: this.translateService.get(options?.message ?? 'DIALOG_ERROR_MESSAGE', translateParams),
            confirmText: this.translateService.get('DIALOG_ERROR_OK', translateParams)
        }).pipe(
            switchMap(({ title, message, confirmText }) =>
                this.alertsController.create({
                    header: title,
                    message,
                    cssClass: 'cc-dialog',
                    buttons: [confirmText]
                })
            ),
            switchMap(async (alert) => {
                await alert.present();
                return alert.onDidDismiss();
            })
        );
    }

    public showSuccess(options?: SuccessDialogOptions): Observable<any> {
        const translateParams = options?.translateParams ?? {};

        return combineLatest({
            title: this.translateService.get(options?.title ?? 'DIALOG_SUCCESS_TITLE', translateParams),
            message: this.translateService.get(options?.message ?? 'DIALOG_SUCCESS_MESSAGE', translateParams),
            confirmText: this.translateService.get('DIALOG_SUCCESS_OK', translateParams)
        }).pipe(
            switchMap(({ title, message, confirmText }) =>
                this.alertsController.create({
                    header: title,
                    message,
                    cssClass: 'cc-dialog',
                    buttons: [confirmText]
                })
            ),
            switchMap(async (alert) => {
                await alert.present();
                return alert.onDidDismiss();
            })
        );
    }

    public showConfirm(options?: ConfirmDialogOptions): Observable<boolean> {
        const translateParams = options?.translateParams ?? {};

        return combineLatest({
            title: this.translateService.get(options?.title ?? 'DIALOG_CONFIRM_TITLE', translateParams),
            message: this.translateService.get(options?.message ?? 'DIALOG_CONFIRM_MESSAGE', translateParams),
            confirmText: this.translateService.get(options?.confirmText ?? 'DIALOG_CONFIRM_OK', translateParams),
            cancelText: this.translateService.get(options?.cancelText ?? 'DIALOG_CONFIRM_CANCEL', translateParams)
        }).pipe(
            switchMap(({ title, message, confirmText, cancelText }) =>
                this.alertsController.create({
                    header: title,
                    message,
                    cssClass: 'cc-dialog',
                    buttons: [
                        {
                            text: cancelText,
                            role: 'cancel'
                        },
                        {
                            text: confirmText,
                            role: 'confirm'
                        }
                    ]
                })
            ),
            switchMap(async (alert) => {
                await alert.present();
                return alert.onDidDismiss();
            }),
            filter((dismissed) => dismissed.role === 'confirm'),
            map(() => true)
        );
    }

    public showPasswordConfirm(options?: PasswordDialogOptions): Observable<string> {
        const translateParams = options?.translateParams ?? {};

        return combineLatest({
            title: this.translateService.get(options?.title ?? 'CONFIRM_PASSWORD_TITLE', translateParams),
            subtitle: this.translateService.get(options?.subtitle ?? 'CONFIRM_PASSWORD_SUBTITLE', translateParams),
            message: this.translateService.get(options?.message ?? 'CONFIRM_PASSWORD_LABEL', translateParams),
            confirmText: this.translateService.get(options?.confirmText ?? 'CONFIRM', translateParams),
            cancelText: this.translateService.get(options?.cancelText ?? 'CANCEL', translateParams)
        }).pipe(
            switchMap(({ title, subtitle, message, confirmText, cancelText }) =>
                this.alertsController.create({
                    header: title,
                    subHeader: subtitle,
                    cssClass: 'cc-dialog',
                    inputs: [
                        {
                            label: message,
                            type: 'password',
                            name: 'password'
                        }
                    ],
                    buttons: [
                        {
                            text: cancelText,
                            role: 'cancel'
                        },
                        {
                            text: confirmText,
                            role: 'confirm'
                        }
                    ]
                })
            ),
            switchMap(async (alert) => {
                await alert.present();
                return alert.onDidDismiss();
            }),
            filter(({ role }) => role === 'confirm'),
            map(({ data }) => data.values.password)
        );
    }
}
