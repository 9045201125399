import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { DeviceService } from '@core/device/services/device.service';
import { BeforeInstallEvent } from '@core/pwa/interfaces/before-install-event.interface';
import { AppRoutingActions } from '@core/root-store/store/app-routing/actions/app-routing.actions';
import { AppPageRoutes } from '@core/routing/constants/app-page-routes.constant';
import { AppRoutingFacadeService } from '@core/routing/services/app-routing-facade.service';
import { WINDOW } from '@core/window.service';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class PwaInstallService {
    public canInstall$: Observable<boolean>;

    private canInstallSubject$ = new BehaviorSubject<boolean>(false);
    private isInstalled: boolean;
    private deferredPrompt: BeforeInstallEvent;

    constructor(
        @Inject(WINDOW) private window: Window & { navigator: { standalone: boolean } },
        @Inject(DOCUMENT) private document: Document,
        private deviceService: DeviceService,
        private appRoutingFacadeService: AppRoutingFacadeService,
        private appCoreFacadeService: AppCoreFacadeService
    ) {
        this.isInstalled = this.wasLaunchedFromInstalledApp();
        this.canInstall$ = this.canInstallSubject$.asObservable();

        this.listenToInstallPrompt();
        this.checkCanInstallIos();
        this.listenForInstallations();
    }

    public promptDownload(): void {
        if (this.deviceService.isWebIos()) {
            this.appRoutingFacadeService.dispatch(
                AppRoutingActions.goToAppPage({ urlSegments: [AppPageRoutes.pwaInstall] })
            );

            return;
        }

        this.deferredPrompt?.prompt();
    }

    private checkCanInstallIos(): void {
        this.appCoreFacadeService
            .getAppSettings()
            .pipe(
                filter(Boolean),
                filter((app) => !this.isInstalled && app.pwa_enabled && this.deviceService.isWebIos())
            )
            .subscribe(() => {
                this.canInstallSubject$.next(true);
            });
    }

    private listenToInstallPrompt(): void {
        fromEvent(this.window, 'beforeinstallprompt')
            .pipe(
                tap((event) => event.preventDefault()),
                filter(() => !this.isInstalled)
            )
            .subscribe((event: BeforeInstallEvent) => {
                if (event) {
                    this.deferredPrompt = event;
                }

                this.canInstallSubject$.next(true);
            });
    }

    private wasLaunchedFromInstalledApp(): boolean {
        if (this.document.referrer.startsWith('android-app://')) {
            return true;
        }

        const isStandalone = this.window.matchMedia('(display-mode: standalone)').matches;
        if (this.window.navigator.standalone || isStandalone) {
            return true;
        }

        return false;
    }

    private listenForInstallations(): void {
        fromEvent(this.window, 'appinstalled').subscribe(() => {
            this.deferredPrompt = null;
            this.canInstallSubject$.next(false);
        });
    }
}
