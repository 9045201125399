import { Injectable } from '@angular/core';
import { URLOpenListenerEvent } from '@capacitor/app';
import { NavigationHistoryService } from '@core/navigation-history/services/navigation-history.service';
import { ReplaySubject, Subject } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { CapacitorService } from './capacitor.service';
import { DeeplinksService } from './deeplinks.service';

@Injectable({
    providedIn: 'root'
})
export class NativeEventsService {
    public backButtonPressed$ = new Subject<void>();
    public appUrlOpen$ = new ReplaySubject<URLOpenListenerEvent>(1);

    constructor(
        private capacitorService: CapacitorService,
        private navigationHistoryService: NavigationHistoryService,
        private deeplinksService: DeeplinksService
    ) {}

    public registerNativeEvents(): void {
        if (!this.capacitorService.isNative) {
            return;
        }

        this.capacitorService.app.addListener('backButton', () => {
            this.backButtonPressed$.next();
        });

        this.backButtonPressed$
            .pipe(switchMap(() => this.navigationHistoryService.canGoBack().pipe(take(1))))
            .subscribe((canGoBack) => {
                if (canGoBack) {
                    this.navigationHistoryService.back();
                    return;
                }

                if (this.capacitorService.isPluginAvailable('App')) {
                    this.capacitorService.app.exitApp();
                }

                this.backButtonPressed$.complete();
            });

        this.capacitorService.app.addListener('appUrlOpen', (appUrlOpen) => {
            this.appUrlOpen$.next(appUrlOpen);
        });

        this.appUrlOpen$.subscribe((appUrlOpen) => {
            this.deeplinksService.handle(appUrlOpen.url);
        });
    }
}
