import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CustomIcon } from '@core/icons/constants/custom-icons.constant';
import { ICONS } from '@core/icons/icons.module';
import { Observable, of, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class IconsService {
    private cachedIcons = new Map<string, string>();
    private requests = new Map<string, Observable<string>>();

    constructor(
        @Inject(ICONS) private icons: Record<CustomIcon, string>,
        private http: HttpClient
    ) {}

    public getIcon(name: CustomIcon): Observable<string> {
        const isCached = this.cachedIcons.has(name);

        if (isCached) {
            const cachedResult = this.cachedIcons.get(name);
            return of(cachedResult);
        }

        return this.requestSvg(name);
    }

    private requestSvg(name: CustomIcon): Observable<string> {
        const existingRequest = this.requests.get(name);

        if (existingRequest) {
            return existingRequest;
        }

        const request = new ReplaySubject<string>(1);
        this.requests.set(name, request);

        const path = this.icons[name];

        return this.http.get(path, { responseType: 'text' }).pipe(
            tap((svg) => {
                this.cachedIcons.set(name, svg);
                request.next(svg);
            })
        );
    }
}
