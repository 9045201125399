import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, props } from '@ngrx/store';
import { BasicAuth } from '@shared/api';

export const AuthInterceptorActions = createActionGroup({
    source: 'Auth Interceptor',
    events: {
        'Refresh Token Failure': props<{ error: HttpErrorResponse }>(),
        'Refresh Token Success': props<{ basicAuth: BasicAuth }>()
    }
});
