import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
    selector: '[ccInfiniteScroll]'
})
export class InfiniteScrollDirective {
    @Input()
    public ccInfiniteScrollDirection: 'vertical' | 'horizontal' = 'vertical';

    @Input()
    public ccInfiniteScrollThreshold: number = 0.9;

    @Input()
    public ccInfiniteScrollIsLoading: boolean;

    @Input()
    public ccInfiniteScrollHasReachedEnd: boolean;

    @Output()
    public ccInfiniteScrollReachEnd = new EventEmitter<void>();

    @HostListener('scroll', ['$event'])
    public onScroll(event: Event): void {
        if (this.ccInfiniteScrollHasReachedEnd) {
            return;
        }

        if (this.lastEmittedTime && Date.now() - this.lastEmittedTime <= 2000) {
            return;
        }

        const target: HTMLElement = event.target as HTMLElement;

        const containerScrollLength = this.getContainerScrollLength(target);

        const threshold = containerScrollLength * this.ccInfiniteScrollThreshold;
        const current = this.getCurrentScrollDistance(target);

        if (current > threshold && !this.ccInfiniteScrollIsLoading) {
            this.ccInfiniteScrollReachEnd.emit();
            this.lastEmittedTime = Date.now();
        }
    }

    private lastEmittedTime: number;

    private getContainerScrollLength(target: HTMLElement): number {
        return this.ccInfiniteScrollDirection === 'vertical' ? target.scrollHeight : target.scrollWidth;
    }

    private getCurrentScrollDistance(target: HTMLElement): number {
        if (this.ccInfiniteScrollDirection === 'vertical') {
            return target.scrollTop + target.clientHeight;
        } else {
            return target.scrollLeft + target.clientWidth;
        }
    }
}
