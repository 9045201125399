import { NgModule } from '@angular/core';
import { ImageCropperDialogComponent } from './components/image-cropper-dialog/image-cropper-dialog.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AppCommonModule } from '@common/common.module';
import { ImageViewerComponent } from './components/image-viewer/image-viewer.component';
import { ImageLoaderComponent } from './components/image-loader/image-loader.component';

@NgModule({
    declarations: [ImageCropperDialogComponent, ImageViewerComponent, ImageLoaderComponent],
    imports: [AppCommonModule, ImageCropperModule],
    exports: [ImageCropperDialogComponent, ImageViewerComponent, ImageLoaderComponent]
})
export class ImagesModule {}
