<div class="chat-message" [ngClass]="{ 'sent-by-peer': sentByPeer, 'emoji-only': message | isOnlyEmoji }">
    @if (sentByPeer) {
        <cc-person-icon class="chat-message__peer-icon" size="small" [person]="user"></cc-person-icon>
    }

    <div
        class="chat-message__text"
        [innerHTML]="message | linky: { stripPrefix: false } | htmlSanitise"
        ccUserGeneratedContent
    ></div>
</div>
