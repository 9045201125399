<div class="error404-container">
    <ion-card>
        <ion-card-header>
            <ng-lottie class="error404__animation" [options]="noDataAnimationOptions"></ng-lottie>
            <ion-card-title>
                {{ 'ERR0R_404_TITLE' | translate }}
            </ion-card-title>
            <ion-card-subtitle>
                {{ 'ERR0R_404_MESSAGE' | translate }}
            </ion-card-subtitle>
        </ion-card-header>
        <ion-card-content>
            <ion-button (click)="onHomepage()">
                {{ 'ERR0R_404_BUTTON' | translate }}
            </ion-button>
        </ion-card-content>
    </ion-card>
</div>
