import { UserBusinessCardsOrder } from '@features/user-business-cards/enums/user-business-cards-order.enum';
import { BusinessCardRequest } from '@features/user-business-cards/interfaces/business-card-request.interface';
import { createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { UserBusinessCardsPageActions } from '../actions/user-business-cards-page.actions';
import { UserBusinessCardsPageState } from '../models/user-business-cards-page-state.model';

export const userBusinessCardsPageContactsEntityAdapter = createEntityAdapter<BusinessCardRequest>();
export const userBusinessCardsPageRequestsEntityAdapter = createEntityAdapter<BusinessCardRequest>();

export const userBusinessCardsPageInitialState: UserBusinessCardsPageState = {
    flipped: false,
    contacts: userBusinessCardsPageContactsEntityAdapter.getInitialState({
        selected: null,
        searching: false,
        search: null,
        loading: false,
        offset: 0,
        total: 0,
        ordering: UserBusinessCardsOrder.MostRecent
    }),
    requests: userBusinessCardsPageRequestsEntityAdapter.getInitialState({
        loading: false,
        offset: 0,
        total: 0
    })
};

export const userBusinessCardsPageReducer = createReducer(
    userBusinessCardsPageInitialState,
    on(UserBusinessCardsPageActions.getRequests, (state) => ({
        ...state,
        requests: {
            ...state.requests,
            loading: true
        }
    })),
    on(UserBusinessCardsPageActions.getRequestsSuccess, (state, { response }) => ({
        ...state,
        requests: {
            ...userBusinessCardsPageRequestsEntityAdapter.addMany(response.results, state.requests),
            loading: false,
            total: response.count
        }
    })),
    on(UserBusinessCardsPageActions.getRequestsFailure, (state) => ({
        ...state,
        requests: {
            ...state.requests,
            loading: false
        }
    })),
    on(UserBusinessCardsPageActions.setRequestsOffset, (state, { offset }) => ({
        ...state,
        requests: {
            ...state.requests,
            offset
        }
    })),
    on(
        UserBusinessCardsPageActions.acceptRequestSuccess,
        UserBusinessCardsPageActions.declineRequestSuccess,
        (state, { request }) => ({
            ...state,
            requests: {
                ...userBusinessCardsPageRequestsEntityAdapter.removeOne(request.id, state.requests),
                offset: state.requests.offset - 1,
                total: state.requests.total - 1
            }
        })
    ),
    on(
        UserBusinessCardsPageActions.acceptRequestSuccess,
        UserBusinessCardsPageActions.processScanResultSuccess,
        (state, { request }) => {
            if ([...state.contacts.ids].includes(request.id)) {
                return state;
            }

            return {
                ...state,
                contacts: {
                    ...userBusinessCardsPageContactsEntityAdapter.addOne(request, state.contacts),
                    total: state.contacts.total + 1
                }
            };
        }
    ),
    on(UserBusinessCardsPageActions.updateContactSuccess, (state, { contact }) => ({
        ...state,
        contacts: userBusinessCardsPageContactsEntityAdapter.updateOne(
            { id: contact.id, changes: contact },
            state.contacts
        )
    })),
    on(UserBusinessCardsPageActions.deleteContactSuccess, (state, { id }) => ({
        ...state,
        contacts: {
            ...userBusinessCardsPageContactsEntityAdapter.removeOne(id, state.contacts),
            total: state.contacts.total - 1
        }
    })),
    on(UserBusinessCardsPageActions.getContacts, (state) => ({
        ...state,
        contacts: {
            ...state.contacts,
            loading: true
        }
    })),
    on(UserBusinessCardsPageActions.getContactsSuccess, (state, { response }) => ({
        ...state,
        contacts: {
            ...state.contacts,
            ...userBusinessCardsPageContactsEntityAdapter.addMany(response.results, state.contacts),
            loading: false,
            total: response.count
        }
    })),
    on(UserBusinessCardsPageActions.getContactsFailure, (state) => ({
        ...state,
        contacts: {
            ...state.contacts,
            loading: false
        }
    })),
    on(UserBusinessCardsPageActions.setContactsOffset, (state, { offset }) => ({
        ...state,
        contacts: {
            ...state.contacts,
            offset
        }
    })),
    on(UserBusinessCardsPageActions.selectContact, (state, { contact }) => ({
        ...state,
        contacts: {
            ...state.contacts,
            selected: contact.id
        }
    })),
    on(UserBusinessCardsPageActions.toggleContactsSearching, (state) => ({
        ...state,
        contacts: {
            ...state.contacts,
            searching: !state.contacts.searching
        }
    })),
    on(UserBusinessCardsPageActions.setContactsSearch, (state, { search }) => ({
        ...state,
        contacts: {
            ...userBusinessCardsPageContactsEntityAdapter.removeAll(state.contacts),
            offset: 0,
            selected: null,
            search
        }
    })),
    on(UserBusinessCardsPageActions.setContactsOrdering, (state, { ordering }) => ({
        ...state,
        contacts: {
            ...userBusinessCardsPageContactsEntityAdapter.removeAll(state.contacts),
            offset: 0,
            selected: null,
            ordering
        }
    })),
    on(UserBusinessCardsPageActions.toggleCardFlipped, (state) => ({
        ...state,
        flipped: !state.flipped
    })),
    on(UserBusinessCardsPageActions.leavePage, () => userBusinessCardsPageInitialState)
);
