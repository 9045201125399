import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { AppTag } from '@shared/tags/interfaces/app-tag.interface';
import { TagFilter } from '@shared/tags/interfaces/tag-filter.interface';

export const PeopleFilterActions = createActionGroup({
    source: 'People Filter',
    events: {
        'Fetch Filters': emptyProps(),
        'Fetch Filters Success': props<{ filters: AppTag[] }>(),
        'Fetch Filter Mode': emptyProps(),
        'Apply Filters': props<{ filters: TagFilter }>(),
        'Remove Filter': props<{ filterId: number }>(),
        'Filter Results': props<{ filters: TagFilter }>()
    }
});
