<div class="incoming-video-call-modal__container">
    <p class="incoming-video-call-modal__header">
        <i class="material-icons" aria-hidden="true"> contact_phone </i>

        {{ 'VIDEO_CALL_INCOMING_DIALOG_HEADER' | translate }}
    </p>

    <cc-person-icon class="incoming-video-call-modal__person-icon" [person]="person"></cc-person-icon>

    <h2 class="incoming-video-call-modal__name">
        <cc-person-name [person]="person" excludes="title pronouns"> </cc-person-name>
    </h2>

    <div class="incoming-video-call-modal__actions">
        <ion-button
            class="incoming-video-call-modal__action"
            color="danger"
            [attr.aria-label]="'VIDEO_CALL_INCOMING_DIALOG_DECLINE' | translate"
            (click)="decline()"
        >
            <i class="material-icons" slot="icon-only">call_end</i>
        </ion-button>

        <ion-button
            class="incoming-video-call-modal__action"
            color="primary"
            [attr.aria-label]="'VIDEO_CALL_INCOMING_DIALOG_ACCEPT' | translate"
            (click)="accept()"
        >
            <i class="material-icons" slot="icon-only">call</i>
        </ion-button>
    </div>

    <ion-button class="incoming-video-call-modal__block" fill="clear" expand="block" (click)="block()">
        <i class="material-icons" slot="start">block</i>
        <ion-label>
            {{ 'VIDEO_CALL_INCOMING_DIALOG_BLOCK' | translate }}
        </ion-label>
    </ion-button>
</div>
