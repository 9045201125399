import { Directive, HostBinding, HostListener, Input } from '@angular/core';
import { DialogService } from '@core/dialog/services/dialog/dialog.service';
import { LightboxImageDialogComponent } from '../../components/lightbox-image-dialog/lightbox-image-dialog.component';

@Directive({
    selector: '[ccLightboxImage]'
})
export class LightboxImageDirective {
    @Input()
    image?: string;

    @HostBinding('role')
    public role = 'button';

    @HostBinding('style.cursor')
    public cursor = 'zoom-in';

    @HostListener('click', ['$event'])
    public onClick(event: PointerEvent): void {
        const element = event.target as HTMLImageElement;

        /* If using inner image, remove query strings for sizing. We want to display the full res image */
        const imageUrl = this.image || element.src?.split('?')[0];

        if (!imageUrl) {
            return;
        }

        this.dialogService
            .openDialog({
                component: LightboxImageDialogComponent,
                componentProps: {
                    src: imageUrl
                },
                showBackdrop: true,
                cssClass: 'lightbox-image-dialog'
            })
            .subscribe();
    }

    constructor(private dialogService: DialogService) {}
}
