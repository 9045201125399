import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { delay, map, switchMap } from 'rxjs/operators';
import { NavigationSelectors } from '../store/selectors/navigation.selectors';
import { NavigationAppState } from '../store/models/navigation.state.model';
import { App, Module, NavigationStyleEnum } from '@shared/api';
import { RightSidebarItem } from './sidebar-right-items.service';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { AuthenticationFacadeService } from '@core/authentication/services/authentication-facade.service';
import { Breakpoints, BreakpointService } from '@shared/utilities/breakpoint.service';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NavigationFacadeService {
    constructor(
        private store: Store<NavigationAppState>,
        private breakpointService: BreakpointService,
        private appCoreFacadeService: AppCoreFacadeService,
        private authenticationFacadeService: AuthenticationFacadeService
    ) {}

    public getModules(): Observable<Module[]> {
        return this.store.select(NavigationSelectors.modules);
    }

    public getInitialising(): Observable<boolean> {
        return this.store.select(NavigationSelectors.initialising);
    }

    public getLeftSidebarCollapsed(): Observable<boolean> {
        return this.store.select(NavigationSelectors.leftSidebarCollapsed);
    }

    /*
        Emits when left sidebar finishes expanding/collapsing transition
        Matches $left-sidebar-transition-time in app-layout.component.scss
    */
    public getLeftSidebarCollapseComplete(): Observable<boolean> {
        return this.getLeftSidebarCollapsed().pipe(delay(400));
    }

    public getRightSidebarItems(): Observable<RightSidebarItem[]> {
        return this.store.select(NavigationSelectors.rightSidebarItems);
    }

    public getTabbarModules(): Observable<Module[]> {
        return this.store.select(NavigationSelectors.tabbarModules);
    }

    public getShouldDisplayToolbar(): Observable<boolean> {
        return this.appCoreFacadeService.getAppSettings().pipe(
            switchMap((app) => {
                if (app && app.privacy === App.PrivacyEnum.Public && !app.toggle_passcode) {
                    return of(true);
                }

                return this.authenticationFacadeService.hasAppAccess();
            })
        );
    }

    public getShouldDisplayTabbar(): Observable<boolean> {
        return this.appCoreFacadeService.getAppSettings().pipe(
            switchMap((app) => {
                if (!app || !app.menu_settings || app.menu_settings.menu_style !== NavigationStyleEnum.Tabbar) {
                    return of(false);
                }

                return this.breakpointService.is(({ width }) => width <= Breakpoints.Medium);
            })
        );
    }

    public getShouldDisplaySidebbar(): Observable<boolean> {
        return this.getShouldDisplayTabbar().pipe(map((shouldShowTabbar) => !shouldShowTabbar));
    }

    public dispatch(action: Action): void {
        this.store.dispatch(action);
    }
}
