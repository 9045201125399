import { Inject, Injectable } from '@angular/core';
import { AnalyticsPageView } from '@core/analytics/interfaces/analytics-page-view-data.interface';
import { AnalyticsSetting } from '@core/analytics/interfaces/analytics-setting.interface';
import { AnalyticsDataStreamService } from '@core/analytics/services/analytics-data-stream.service';
import { LoadScriptService } from '@core/browser-utilities/services/load-script.service';
import { TagStatus } from '@core/tag-management/constants/tag-status.constant';
import { TagBuilder } from '@core/tag-management/models/tag-builder.interface';
import { TagTracker } from '@core/tag-management/models/tag-token.interface';
import { WINDOW } from '@core/window.service';
import { CookieService } from 'ngx-cookie-service';
import { GtagService } from './gtag.service';

@Injectable({
    providedIn: 'root'
})
export class GtagBuilderService implements TagBuilder {
    public trackers: TagTracker[];

    constructor(
        private scriptLoader: LoadScriptService,
        private gtagService: GtagService,
        private analyticsService: AnalyticsDataStreamService,
        private cookieService: CookieService,
        @Inject(WINDOW) private window: Window
    ) {}

    public configure(trackers: TagTracker[]): TagBuilder {
        this.trackers = trackers;
        return this;
    }

    public load(): string {
        if (this.window.gtag || this.scriptLoader.getScript('gtag')) {
            return TagStatus.skipped('GTAG', 'Script already loaded');
        }

        if (!this.trackers || this.trackers.length === 0) {
            console.warn('CC Tag Manager: No tracking properties found for Google Analytics 4!');
            return TagStatus.skipped('GTAG', 'No tracking properties');
        }

        const loaded = this.scriptLoader.loadScript({
            src: `https://www.googletagmanager.com/gtag/js`,
            id: 'gtag',
            onload: () => {
                return this.setupTag();
            },
            onerror: (error) => {
                this.handleError(error);
            }
        });

        if (loaded) {
            return TagStatus.loaded('GTAG');
        }
    }

    public remove(): Promise<void> {
        return new Promise((resolve) => {
            // Delete cookies
            ['_ga', '_gid', '_gat'].forEach((key) =>
                this.cookieService.delete(key, '/', this.window.location.hostname)
            );

            // Remove script element
            this.scriptLoader.getScript('gtag').element.remove();

            // Unset global functions
            this.window.dataLayer = [];
            this.window.gtag = undefined;

            this.trackers.forEach((token) => {
                this.window[`ga-disable-${token}`] = true;
                this.cookieService.delete(`_ga_${token}`);
            });

            resolve();
        });
    }

    private handleError(error: string | Event): void {
        console.warn(error);
    }

    private setupTag(): void {
        this.reactivateDisabledProperties();

        this.window.dataLayer = this.window.dataLayer || [];

        this.window.gtag = function () {
            this.window?.dataLayer.push(arguments);
        };

        this.startTracking();
    }

    private startTracking(): void {
        this.window.gtag('js', new Date());

        this.gtagService.checkNative(this.trackers);

        this.analyticsService.event$.subscribe((event: AnalyticsSetting) =>
            this.gtagService.handleEvent({ ...event }, this.trackers)
        );
        this.analyticsService.pageView$.subscribe((view: AnalyticsPageView) =>
            this.gtagService.handlePageView({ ...view }, this.trackers)
        );
    }

    private reactivateDisabledProperties(): void {
        // Check any disabled tags matching the analytics tokens are re-enabled
        Object.keys(this.window)
            .filter((key) => this.trackers.some((token) => key === `ga-disable-${token.token}`))
            .map((key) => {
                this.window[key] = false;
            });
    }
}
