import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { DialogService } from '@core/dialog/services/dialog/dialog.service';

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {
    constructor(private dialogService: DialogService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((errorResponse: HttpErrorResponse) => {
                if (!errorResponse.error || !errorResponse.error.non_field_errors) {
                    return throwError(() => errorResponse);
                }

                const nonFieldErrors = errorResponse.error.non_field_errors;
                const message = Array.isArray(nonFieldErrors) ? nonFieldErrors[0] : nonFieldErrors;

                return this.dialogService.showError({ message }).pipe(switchMap(() => throwError(() => errorResponse)));
            })
        );
    }
}
