import { Inject, Injectable } from '@angular/core';
import { GoogleAnalyticsCommand } from '@core/analytics/enums/google-analytics-command.enum';
import { TrackerTarget } from '@core/analytics/enums/tracker-target.enum';
import { AnalyticsPageView } from '@core/analytics/interfaces/analytics-page-view-data.interface';
import { AnalyticsSetting } from '@core/analytics/interfaces/analytics-setting.interface';
import { DeviceService } from '@core/device/services/device.service';
import { TagTracker } from '@core/tag-management/models/tag-token.interface';
import { WINDOW } from '@core/window.service';
import { GtagCustomDimension } from '../enums/gtag-custom-dimension.enum';

@Injectable({
    providedIn: 'root'
})
export class GtagService {
    constructor(
        @Inject(WINDOW) private window: Window,
        private deviceService: DeviceService
    ) {}

    public handleEvent(settings: AnalyticsSetting, trackers: TagTracker[]): void {
        if (!this.window.gtag) {
            return;
        }

        trackers.forEach((tracker) => {
            this.window.gtag(GoogleAnalyticsCommand.Config, tracker.token, {
                custom_map: {
                    dimension1: GtagCustomDimension.App,
                    dimension2: GtagCustomDimension.User,
                    dimension3: GtagCustomDimension.Environment,
                    dimension4: GtagCustomDimension.EventCategory
                }
            });
            this.window.gtag(GoogleAnalyticsCommand.Event, settings.event, {
                event_category: settings.category,
                event_label: settings.label,
                [GtagCustomDimension.App]: settings.app,
                [GtagCustomDimension.User]: settings.username,
                [GtagCustomDimension.Environment]: settings.environmentName,
                [GtagCustomDimension.EventCategory]: settings.category,
                send_to: this.setTargetProperty(tracker)
            });
        });
    }

    public handlePageView(view: AnalyticsPageView, trackers: TagTracker[]): void {
        if (!this.window.gtag) {
            return;
        }

        trackers.forEach((tracker) => {
            this.window.gtag(GoogleAnalyticsCommand.Set, 'page_path', view.page_path);
            this.window.gtag(GoogleAnalyticsCommand.Event, 'page_view', {
                send_to: this.setTargetProperty(tracker)
            });
        });
    }

    public checkNative(trackers: TagTracker[]): void {
        if (!this.window.gtag) {
            return;
        }

        trackers.forEach((tracker) => {
            if (this.deviceService.isNative()) {
                this.configureNativeAnalytics(tracker);
            } else {
                this.window.gtag(GoogleAnalyticsCommand.Config, tracker.token);
            }
            this.window.gtag(GoogleAnalyticsCommand.Set, 'checkProtocolTask', null);
        });
    }

    private configureNativeAnalytics(tracker: TagTracker): void {
        this.deviceService.getUUID().then((uuid: string) => {
            if (tracker.name === TrackerTarget.Default) {
                this.window.gtag(GoogleAnalyticsCommand.Config, tracker.token, {
                    client_storage: 'none',
                    client_id: uuid
                });
            } else {
                this.window.gtag(GoogleAnalyticsCommand.Config, tracker.token, {
                    client_storage: 'none',
                    client_id: uuid,
                    name: tracker.name
                });
            }
        });
    }

    private setTargetProperty(tracker: TagTracker): string {
        return tracker.name === TrackerTarget.Default ? TrackerTarget.Default : TrackerTarget.Client;
    }
}
