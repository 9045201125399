<ng-container *ngIf="{ personVisibility: personVisibility$ | async } as data">
    <span *ngIf="person" class="person-name__full-name">
        <ng-container *ngIf="!excludes?.includes('title')">
            <span *ngIf="person?.title && data.personVisibility?.title" class="person-name__title">
                {{ person.title }}
            </span>
        </ng-container>
        <span *ngIf="data.personVisibility?.first_name" class="person-name__first-name">
            {{ person.first_name }}
        </span>
        <span *ngIf="data.personVisibility?.last_name" class="person-name__last-name">
            {{ person.last_name }}
        </span>
        <ng-container *ngIf="!excludes?.includes('pronouns')">
            <span *ngIf="person.pronouns && data.personVisibility?.pronouns" class="person-name__suffix">
                {{ '(' + person.pronouns + ')' }}
            </span>
        </ng-container>
    </span>
    <ng-content></ng-content>
</ng-container>
