import { Injectable } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { filter, map, startWith } from 'rxjs/operators';
import { ViewportRuler } from '@angular/cdk/overlay';
import { performant } from '@shared/rxjs/custom.rxjs';
import { Size } from '@shared/models/size.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BreakpointService {
    constructor(
        private breakpointObserver: BreakpointObserver,
        private viewportRuler: ViewportRuler
    ) {}

    public is(predicate: (size: Size) => boolean): Observable<boolean> {
        return this.size().pipe(map(predicate));
    }

    public width(): Observable<number> {
        return this.size().pipe(map(({ width }) => width));
    }

    public height(): Observable<number> {
        return this.size().pipe(map(({ height }) => height));
    }

    public size(): Observable<Size> {
        return this.viewportRuler.change().pipe(
            startWith(null), // Ensure the initial viewport size is also sent
            map(() => this.viewportRuler.getViewportSize()),
            filter(({ width, height }) => width > 0 && height > 0), // Avoid sending minimised sizes
            performant(10)
        );
    }

    public matches(value: string | string[]): Observable<boolean> {
        return this.breakpointObserver.observe(value).pipe(
            map(({ matches }) => matches),
            startWith(this.breakpointObserver.isMatched(value))
        );
    }
}

export const Breakpoints = {
    ExtraSmall: 370,
    Small: 576,
    Medium: 768,
    Large: 992,
    ExtraLarge: 1200
};
