import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'cc-person-header-button',
    templateUrl: './person-header-button.component.html',
    styleUrls: ['./person-header-button.component.scss']
})
export class PersonHeaderButtonComponent {
    @Input()
    public icon: string;

    @Input()
    public label: string;

    @Input()
    public classes: string;

    @Output()
    public clicked: EventEmitter<void> = new EventEmitter<void>();

    public emitClicked(): void {
        this.clicked.emit();
    }
}
