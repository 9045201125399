import { TagType } from '@core/tag-management/enums/tag-type.enum';
import { TagEntity } from '@core/tag-management/models/tag-entity.interface';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const TagManagerActions = createActionGroup({
    source: 'Tag Manager',
    events: {
        'Initialise Trackers': emptyProps(),
        'Remove Trackers': emptyProps(),
        'Remove Trackers Success': emptyProps(),
        'Initialise Analytics Success': emptyProps(),
        'Load Tracking Scripts': props<{ types: TagType[] }>(),
        'Load Tracking Scripts Success': emptyProps(),
        'Load Tags Success': props<{ tags }>(),
        'Load Tags Failure': props<{ error }>(),
        'Get Tags': emptyProps(),
        'Fetch Tags Success': props<{ tags: TagEntity[] }>(),
        'Fetch Tags Failure': props<{ error }>()
    }
});
