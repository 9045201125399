import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NativeClassesService } from './services/native-classes.service';
import { IonicModule } from '@ionic/angular';
import { TokenBackupService } from './services/token-backup.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { NativeEventsService } from './services/native-events.service';

export const nativeClassesInitializerFactory = (nativeClasses: NativeClassesService) => {
    return async () => {
        await nativeClasses.addNativeClasses();
    };
};

export const nativeClassesInitializer = {
    provide: APP_INITIALIZER,
    useFactory: nativeClassesInitializerFactory,
    multi: true,
    deps: [NativeClassesService]
};

export const nativeEventsInitializerFactory = (nativeEvents: NativeEventsService) => {
    return () => {
        nativeEvents.registerNativeEvents();
    };
};

export const nativeEventsInitializer = {
    provide: APP_INITIALIZER,
    useFactory: nativeEventsInitializerFactory,
    multi: true,
    deps: [NativeEventsService]
};

export const nativeBackUpInitializerFactory = (tokenBackup: TokenBackupService) => {
    return async () => {
        await tokenBackup.load();
    };
};

export const nativeBackUpInitializer = {
    provide: APP_INITIALIZER,
    useFactory: nativeBackUpInitializerFactory,
    multi: true,
    deps: [TokenBackupService]
};

@NgModule({
    declarations: [],
    imports: [IonicModule, CommonModule],
    providers: [InAppBrowser]
})
export class NativeModule {}
