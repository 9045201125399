import { NgModule } from '@angular/core';
import { alertsStoreKey } from './models/alerts.state.model';
import { StoreModule } from '@ngrx/store';
import { alertsReducer } from './reducers/alerts.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AlertsEffects } from './effects/alerts.effects';

@NgModule({
    imports: [StoreModule.forFeature(alertsStoreKey, alertsReducer), EffectsModule.forFeature([AlertsEffects])]
})
export class AlertsStoreModule {}
