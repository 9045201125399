export interface UserExport {
    role?: UserExport.RoleEnum;
    /**
     * This is a flag to determine if the user has unsubscribed from marketing emails for the app.
     */
    marketing_emails?: boolean;
    /**
     * This is a flag to determine if the user has unsubscribed from notification emails for the app.
     */
    notification_emails?: boolean;
}

export namespace UserExport {
    export enum RoleEnum {
        Owner = 'owner' as any,
        Admin = 'admin' as any,
        User = 'user' as any
    }
}
