import { Directive, HostListener } from '@angular/core';
import { Input } from '@angular/core';
import { UrlUtilityService } from '@shared/utilities/url-utility.service';

@Directive({
    selector: '[ccExternalHref]'
})
export class ExternalHrefDirective {
    @Input('ccExternalHref')
    externalHref: string;

    @HostListener('click')
    onElementClick(): void {
        this.urlUtilityService.open(this.externalHref);
    }

    constructor(private urlUtilityService: UrlUtilityService) {}
}
