import { AppRoutingActions } from '@core/root-store/store/app-routing/actions/app-routing.actions';
import { AppPageRoutes } from '@core/routing/constants/app-page-routes.constant';
import { AppUserPageRoutes } from '@core/routing/constants/user-page-routes.constant';
import { AlertBehaviourType } from '../enums/alert-notification-type.enum';
import { AppNotification } from '../interfaces/notification.interface';
import { NotificationDurations } from './notification-durations.constant';

const navigateToAlertsAction = AppRoutingActions.goToAppPage({
    urlSegments: [AppPageRoutes.user, AppUserPageRoutes.alerts]
});

const alertsNotificationDefaults: Partial<AppNotification> = {
    action: navigateToAlertsAction,
    actionAriaLabel: 'ALERTS_DIGEST_ARIA_LABEL'
};

export const AlertDigestNotificationId: string = 'digest-notification';

export const AlertsNotificationMap: Record<AlertBehaviourType, Partial<AppNotification>> = {
    [AlertBehaviourType.Digest]: {
        id: AlertDigestNotificationId,
        title: 'ALERTS_DIGEST_TITLE',
        message: 'ALERTS_DIGEST_INFO',
        duration: NotificationDurations.persist,
        ...alertsNotificationDefaults
    },
    [AlertBehaviourType.Fading]: {
        duration: NotificationDurations.default
    },
    [AlertBehaviourType.Persistent]: {
        duration: NotificationDurations.persist
    }
};
