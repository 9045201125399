import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { AppDocument } from '@shared/api';

@Component({
    selector: 'cc-documents-list',
    templateUrl: './documents-list.component.html',
    styleUrls: ['./documents-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentsListComponent {
    @Input()
    documents: AppDocument[];

    @Output()
    tapDocument = new EventEmitter<AppDocument>();

    iconToggle = AppDocument.IconToggleEnum;

    onTapDocument(document: AppDocument): void {
        this.tapDocument.emit(document);
    }
}
