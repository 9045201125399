import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { AuthenticationFacadeService } from '@core/authentication/services/authentication-facade.service';
import { GlobalScannerHandlerService } from '@features/global-scanner/interfaces/global-scanner-handler-service.interface';
import { GlobalScannerBaseQrData } from '@features/global-scanner/interfaces/global-scanner-qr-data.interface';
import { BusinessCardRequest } from '@features/user-business-cards/interfaces/business-card-request.interface';
import { UserBusinessCardsAlertService } from '@features/user-business-cards/services/user-business-cards-alert/user-business-cards-alert.service';
import { UserBusinessCardsApiService } from '@features/user-business-cards/services/user-business-cards-api/user-business-cards-api.service';
import { UserBusinessCardsFacadeService } from '@features/user-business-cards/services/user-business-cards-facade/user-business-cards-facade.service';
import { UserBusinessCardsPageActions } from '@features/user-business-cards/store/actions/user-business-cards-page.actions';
import { Observable, combineLatest, from, map, switchMap, take, tap } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GlobalScannerUserBusinessCardsService
    implements GlobalScannerHandlerService<GlobalScannerBaseQrData, BusinessCardRequest, HttpErrorResponse>
{
    constructor(
        private appCoreFacadeService: AppCoreFacadeService,
        private authenticationFacadeService: AuthenticationFacadeService,
        private userBusinessCardsApiService: UserBusinessCardsApiService,
        private userBusinessCardsAlertService: UserBusinessCardsAlertService,
        private userBusinessCardsFacadeService: UserBusinessCardsFacadeService
    ) {}

    public canProcess(): Observable<{ processable: boolean; message?: string }> {
        return combineLatest({
            app: this.appCoreFacadeService.getAppSettings(),
            user: this.authenticationFacadeService.getAuthenticatedPerson()
        }).pipe(
            take(1),
            map(({ app, user }) => ({
                processable: app.business_cards_enabled && user?.can_exchange_business_cards
            }))
        );
    }

    public processScan(data: GlobalScannerBaseQrData): Observable<BusinessCardRequest> {
        return this.appCoreFacadeService.getAppName().pipe(
            take(1),
            switchMap((appName) => this.userBusinessCardsApiService.processScan(appName, data.value))
        );
    }

    public onSuccess(request: BusinessCardRequest): Observable<unknown> {
        return from(this.userBusinessCardsAlertService.openProcessScanResultSuccessAlert(request)).pipe(
            tap(() =>
                this.userBusinessCardsFacadeService.dispatch(
                    UserBusinessCardsPageActions.processScanResultSuccess({ request })
                )
            )
        );
    }

    public onError(): Observable<unknown> {
        return from(this.userBusinessCardsAlertService.openProcessScanResultFailureAlert());
    }
}
