<div class="agenda-card-expanded__container">
    <cc-agenda-card-speakers-list
        *ngIf="enabledFeatures.expand_show_speakers && expandedSession.speakers?.length"
        [speakers]="expandedSession.speakers"
    ></cc-agenda-card-speakers-list>

    <cc-agenda-card-documents-list
        *ngIf="enabledFeatures.expand_show_documents && expandedSession.documents?.length"
        [documents]="expandedSession.documents"
    ></cc-agenda-card-documents-list>

    <div
        *ngIf="!expandedSession.documents.length && !expandedSession.speakers.length"
        class="agenda-card-expanded__no-data"
    >
        <span>
            {{ 'AGENDA_NO_DATA' | translate }}
        </span>
    </div>
</div>
