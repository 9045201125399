import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action } from '@shared/api';
import { ApiSettings } from '@shared/settings/api-settings.constant';
import { AppActionEndpoints } from '@shared/shared-actions/endpoints/app-action-endpoints.constant';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AppActionApiService {
    constructor(private httpClient: HttpClient) {}

    public fetchAction(appUrl: string, actionId: number): Observable<Action> {
        return this.httpClient.get<Action>(ApiSettings.fullUrl(appUrl, AppActionEndpoints.ActionDetail(actionId)));
    }
}
