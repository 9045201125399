import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'readableUrl'
})
export class ReadableUrlPipe implements PipeTransform {
    public transform(value: string): unknown {
        return value.replace(/^https?:\/\//, '');
    }
}
