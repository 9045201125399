import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoStoreModule } from './store/video-store.module';
import { IncomingVideoCallModalComponent } from './components/incoming-video-call-modal/incoming-video-call-modal.component';
import { AppCommonModule } from '@common/common.module';

@NgModule({
    declarations: [IncomingVideoCallModalComponent],
    imports: [CommonModule, AppCommonModule, VideoStoreModule]
})
export class VideoCallsModule {}
