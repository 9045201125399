import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Module } from '@shared/api';

@Component({
    selector: 'cc-module-icon',
    templateUrl: './module-icon.component.html',
    styleUrls: ['./module-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModuleIconComponent {
    @Input()
    public module: Module;

    @Input()
    public slot: string;

    public iconToggleEnum = Module.IconToggleEnum;

    constructor() {}
}
