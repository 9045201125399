import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    TrackByFunction
} from '@angular/core';
import { ExpandedSession } from '@features/agenda/agenda-session/models/expanded-session.model';
import { AgendaModuleEnabledFeatures } from '@shared/api/models/AgendaModule';
import { DateTime, Interval } from 'luxon';
import { InlineSession } from '../../../../features/agenda/agenda-module/models/inline-session.model';

@Component({
    selector: 'cc-schedule-list',
    templateUrl: './schedule-list.component.html',
    styleUrls: ['./schedule-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduleListComponent implements OnChanges {
    @Input()
    public sessions: Array<{ slim: InlineSession; expanded?: ExpandedSession }>;

    @Input()
    public enabledFeatures: AgendaModuleEnabledFeatures;

    @Input()
    public personalAgendaSessions: number[] = [];

    @Input()
    public togglingSessionsInProgress: number[] = [];

    @Input()
    public showTicket = true;

    @Input()
    public showDayDate = false;

    @Input()
    public scrollToFirstOn = false;

    @Input()
    public flatCardStyling: boolean;

    @Input()
    public personalWaitingListSessions: Array<number>;

    @Input()
    public addToMySchedule: boolean;

    @Output()
    public sessionClick = new EventEmitter<InlineSession>();

    @Output()
    public sessionExpanded = new EventEmitter<InlineSession>();

    @Output()
    public ticketClick = new EventEmitter<InlineSession>();

    public firstOn?: number;

    public trackByFn: TrackByFunction<{ slim: InlineSession; expanded?: ExpandedSession }> = (_, session) =>
        session.slim.id;

    public ngOnChanges({ sessions }: SimpleChanges): void {
        if (this.scrollToFirstOn && sessions.currentValue) {
            this.firstOn = this.sessions.find((session) => this.isOnNow(session.slim))?.slim.id;
        }
    }

    public onSessionClick(session: InlineSession): void {
        this.sessionClick.emit(session);
    }

    public onSessionExpanded(session: InlineSession): void {
        this.sessionExpanded.emit(session);
    }

    public onTicketClick(session: InlineSession): void {
        this.ticketClick.emit(session);
    }

    public isInPersonalAgenda(session: InlineSession): boolean {
        if (!this.personalAgendaSessions) {
            return false;
        }

        return this.personalAgendaSessions.includes(session.id);
    }

    public isOnWaitingList(session: InlineSession): boolean {
        if (!this.personalWaitingListSessions) {
            return false;
        }

        return this.personalWaitingListSessions.includes(session.id);
    }

    public isOnNow(session: InlineSession | ExpandedSession): boolean {
        const startTime = DateTime.fromISO(session.start);
        const endTime = DateTime.fromISO(session.end);
        const now = DateTime.now();

        return Interval.fromDateTimes(startTime, endTime).contains(now);
    }
}
