import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { People as Person } from '@shared/api';

@Component({
    selector: 'cc-business-card-details',
    templateUrl: './business-card-details.component.html',
    styleUrls: ['./business-card-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BusinessCardDetailsComponent {
    @Input()
    public person: Person;

    @Input()
    public profileFields: Array<keyof Person>;

    public canDisplayField(field: keyof Person): boolean {
        return this.profileFields.includes(field);
    }
}
