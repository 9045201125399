<ion-button
    class="agenda-card-schedule-button__container"
    fill="clear"
    aria-hidden="true"
    [disabled]="isTogglingInProgress"
    (click)="onTicketClick($event)"
>
    <div class="agenda-card-schedule-button__inner cc-schedule-all">
        <cc-capacity-status
            *ngIf="session.capacity"
            [isInWaitingList]="isOnWaitingList"
            [remainingPlaces]="session.remaining_places"
        ></cc-capacity-status>

        <ion-spinner
            *ngIf="isTogglingInProgress"
            class="agenda-card-schedule-button__container__spinner"
            @fadeIn
        ></ion-spinner>

        <i
            *ngIf="!isTogglingInProgress"
            placement="bottom"
            [showDelay]="500"
            [tooltip]="label | translate"
            [attr.aria-label]="label | translate"
            [ngClass]="{
                'material-icons': isInPersonalAgenda || isOnWaitingList,
                'material-icons-outlined': !isInPersonalAgenda && !isOnWaitingList
            }"
            @fadeIn
        >
            {{ icon }}
        </i>
    </div>
</ion-button>
