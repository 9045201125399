import { PaginationState } from '@shared/pagination/models/pagination-state.model';
import URI from 'urijs';

export const AlertsApiEndpoints = {
    alerts: `/alerts/`,
    pagedAlerts: `/alerts/table/`,
    pagedArchived: `/archived-alerts/table/`,
    notifications: `/me/notifications/table/`,
    readAllNotifications: `/me/notifications/read-all/`,
    archiveAllNotifications: `/me/notifications/archive-all/`,
    archiveNotification: (id: string) => `/me/notifications/${id}/archive/`,
    unarchiveNotification: (id: string) => `/me/notifications/${id}/unarchive/`,
    readNotification: (id: string) => `/me/notifications/${id}/read/`,
    read: (alertId: number) => `/alerts/${alertId}/read/`,
    paginate: (query: PaginationState, url: string) => {
        const page = query.page ?? 1;
        const limit = query.limit ?? 10;
        const offset = ((page - 1) * limit).toString();

        return URI(`${url}`)
            .search({
                search: query.search ?? '',
                offset,
                limit: limit.toString()
            })
            .readable();
    }
};
