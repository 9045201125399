import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { I18nTokenService } from '../services/i18n-token.service';
import { switchMap, take } from 'rxjs/operators';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
    constructor(
        private i18nTokenService: I18nTokenService,
        private appCoreFacadeService: AppCoreFacadeService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.appCoreFacadeService.getAppSettings().pipe(
            take(1),
            switchMap((app) => {
                if (!app) {
                    return next.handle(req);
                }

                const language: string = this.i18nTokenService.get(app.url_name) || app.language;

                const clonedRequest = req.clone({
                    headers: req.headers.set('Accept-Language', language)
                });

                return next.handle(clonedRequest);
            })
        );
    }
}
