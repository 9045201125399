import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SocialInfo } from '@shared/api';
import { BusinessCardEndpoints } from '@shared/business-cards/constants/business-cards-endpoints.constant';
import { BusinessCardDesign } from '@shared/business-cards/interfaces/business-card-design.interface';
import { ApiSettings } from '@shared/settings/api-settings.constant';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BusinessCardsApiService {
    constructor(private httpClient: HttpClient) {}

    public getMyCardDesign(appName: string): Observable<BusinessCardDesign> {
        const url = ApiSettings.fullUrl(appName, BusinessCardEndpoints.myCard());
        return this.httpClient.get<BusinessCardDesign>(url);
    }

    public getSocialInfo(appName: string): Observable<Array<SocialInfo>> {
        const url = ApiSettings.fullUrl(appName, BusinessCardEndpoints.mySocialInfo());
        return this.httpClient.get<Array<SocialInfo>>(url);
    }

    public exchangeBusinessCards(
        appUrl: string,
        personId: number,
        payload: { message?: string; is_company?: boolean }
    ): Observable<void> {
        const url = ApiSettings.fullUrl(appUrl, BusinessCardEndpoints.exchange(personId));

        return this.httpClient.post<void>(url, payload);
    }
}
