import { Injectable } from '@angular/core';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { PaginatedResponse } from '@shared/pagination/models/paginated-response.model';
import { PaginationState } from '@shared/pagination/models/pagination-state.model';
import { PersistedPaginationStore } from '@shared/pagination/services/persisted-pagination-store/persisted-pagination-store.service';
import { Observable } from 'rxjs';
import { map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { SlimPeople } from '@shared/api';
import { AttendeeApiService } from '../attendee-api/attendee-api.service';
import { AttendeeRealtimeService } from '../attendee-realtime/attendee-realtime.service';
import { AblyEventType } from '@core/ably/enums/ably-event-type.enum';

@Injectable()
export class AttendeePaginationStore extends PersistedPaginationStore<
    SlimPeople,
    { sessionId: number; moduleId: number }
> {
    public readonly listenForAttendeeChanges = this.effect((_) =>
        _.pipe(
            withLatestFrom(this.query$),
            map(([, query]) => query.sessionId),
            switchMap((sessionId) =>
                this.attendeeRealtimeService.getAttendeeUpdateStream({ sessionId }).pipe(
                    withLatestFrom(this.hasReachedEnd$),
                    tap(([updateEvent, hasReachedEnd]) =>
                        this.processAttendeeChangeEvent({ ...updateEvent, hasReachedEnd })
                    )
                )
            )
        )
    );

    constructor(
        private attendeeApiService: AttendeeApiService,
        private attendeeRealtimeService: AttendeeRealtimeService,
        private appCoreFacadeService: AppCoreFacadeService
    ) {
        super();
    }

    protected fetchResource(
        query: PaginationState & { sessionId: number; moduleId: number }
    ): Observable<PaginatedResponse<SlimPeople>> {
        return this.appCoreFacadeService.getAppName().pipe(
            take(1),
            switchMap((appName) =>
                this.attendeeApiService.getSessionAttendees({
                    appName,
                    moduleId: query.moduleId,
                    sessionId: query.sessionId,
                    query: {
                        page: query.page,
                        limit: query.limit,
                        search: query.search
                    }
                })
            )
        );
    }

    private processAttendeeChangeEvent(options: {
        attendee: SlimPeople;
        event: AblyEventType;
        hasReachedEnd: boolean;
    }): void {
        switch (options.event) {
            case AblyEventType.Create:
                if (options.hasReachedEnd) {
                    this.addItem({ item: options.attendee, position: 'end' });
                } else {
                    this.incrementCount();
                }
                break;
            case AblyEventType.Delete:
                this.removeItem((attendee) => attendee.id === options.attendee.id);
        }
    }
}
