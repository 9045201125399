import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { PwaEndpoints } from '@core/pwa/constants/pwa-endpoints.constant';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PwaSetupService {
    constructor(
        @Inject(DOCUMENT) private document: Document,
        private httpClient: HttpClient
    ) {}

    public setWebManifest(appName: string): void {
        const headElement = this.removeExistingManifest();

        const manifestUrl = PwaEndpoints.manifest(appName);
        const link = this.createManifestElement(manifestUrl);

        headElement.appendChild(link);
    }

    public removeExistingManifest(): HTMLHeadElement {
        const headElement = this.document.getElementsByTagName('head')[0];

        const existingManifestElement = this.document.querySelector('link[rel=manifest]');

        if (existingManifestElement) {
            headElement.removeChild(existingManifestElement);
        }

        return headElement;
    }

    public fetchRemoteManifest(appName: string): Observable<unknown> {
        const manifestUrl = PwaEndpoints.manifest(appName);

        return this.httpClient.get(manifestUrl);
    }

    private createManifestElement(manifestUrl: string): HTMLLinkElement {
        const link = this.document.createElement('link');
        link.href = manifestUrl;
        link.rel = 'manifest';

        return link;
    }
}
