export const iconsDirectory = '/assets/icons/';

export type CustomIcon =
    | 'facebook'
    | 'instagram'
    | 'linkedin'
    | 'snapchat'
    | 'twitterOld'
    | 'youtube'
    | 'googleplus'
    | 'document'
    | 'excel'
    | 'image'
    | 'pdf'
    | 'powerpoint'
    | 'website'
    | 'crown'
    | 'voting-chip'
    | 'twitter';

export const CustomIcons: Record<CustomIcon, string> = {
    facebook: getIconSrc('facebook'),
    instagram: getIconSrc('instagram'),
    linkedin: getIconSrc('linkedin'),
    snapchat: getIconSrc('snapchat'),
    twitterOld: getIconSrc('twitterOld'),
    youtube: getIconSrc('youtube'),
    googleplus: getIconSrc('googleplus'),
    document: getIconSrc('document'),
    excel: getIconSrc('excel'),
    image: getIconSrc('image'),
    pdf: getIconSrc('pdf'),
    powerpoint: getIconSrc('powerpoint'),
    website: getIconSrc('website'),
    crown: getIconSrc('crown'),
    twitter: getIconSrc('twitter'),
    'voting-chip': getIconSrc('voting-chip')
};

function getIconSrc(icon: CustomIcon): string {
    return `${iconsDirectory}${icon}.svg`;
}
