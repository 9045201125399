import { NgModule } from '@angular/core';
import { AppCommonModule } from '@common/common.module';

import { CookieNoticeDialogComponent } from './components/cookie-notice-dialog/cookie-notice.component';
import { PrivacySettingsDialogComponent } from './components/privacy-settings-dialog/privacy-settings-dialog.component';
import { TosDialogComponent } from './components/tos-dialog/tos-dialog.component';

@NgModule({
    declarations: [PrivacySettingsDialogComponent, TosDialogComponent, CookieNoticeDialogComponent],
    imports: [AppCommonModule]
})
export class TosModule {}
