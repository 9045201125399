import { RightSidebarItem } from '@core/navigation/services/sidebar-right-items.service';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const NavigationActions = createActionGroup({
    source: 'Navigation',
    events: {
        Created: emptyProps(),
        'App Image Clicked': emptyProps(),
        'Portal Return Click': emptyProps(),
        'Open Terms and Conditions': emptyProps(),
        'Real Time Update': emptyProps(),
        'Toggle Left Sidebar Collapsed': emptyProps(),
        'Search Submitted': props<{ searchTerm: string }>(),
        'Load Language': props<{ languageCode: string }>(),
        'Set Right Sidebar Items': props<{ rightSidebarItems: RightSidebarItem[] }>(),
        'Set Left Sidebar Collapsed': props<{ collapsed: boolean }>(),
        Destroyed: emptyProps()
    }
});
