import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { People as Person } from '@shared/api';

@Component({
    selector: 'cc-business-card-icons',
    templateUrl: './business-card-icons.component.html',
    styleUrls: ['./business-card-icons.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BusinessCardIconsComponent {
    @Input()
    public person: Person;

    @Input()
    public size: 'thumbnail' | 'stacked' | 'default' = 'default';

    @Input()
    public companyIcon: string;
}
