import { ActionPopoverType } from '@shared/action-popover/enums/action-popover-type.enum';

export enum CompanyDetailActionButtonType {
    phoneNumber,
    email,
    mainRepresentativeChat,
    website,
    scheduleMeeting,
    leaveBusinessCard
}

export interface CompanyDetailActionButton {
    type: CompanyDetailActionButtonType;
    text: string;
    icon: string;
    popover?: ActionPopoverType;
    popoverContent?: string;
}
