<ion-card class="agenda-card__card" [class.flat-styling]="flatStyling">
    <ion-item
        button
        class="agenda-card__item ion-item-reset"
        [ccCssProps]="{
            '--background': session.list_row_bk_colour,
            '--color': session.list_row_text_colour
        }"
        (click)="onSessionClick()"
    >
        <div class="agenda-card__container" [class.show-waiting-list-banner]="isOnWaitingList && !showTicket">
            <div *ngIf="isOnNow" class="agenda-card__banner">
                <span>{{ 'AGENDA_ON_NOW' | translate }}</span>
            </div>

            <div class="agenda-card__content" [ngClass]="{ 'on-now': isOnNow, active: isTogglingInProgress }">
                <div class="agenda-card__top">
                    <img
                        *ngIf="session.tile_image"
                        class="agenda-card__session-photo"
                        [src]="session.tile_image | imageDimensions: 80 : 80"
                    />

                    <div class="agenda-card__heading">
                        <div class="agenda-card__heading-date">
                            <span *ngIf="showDate" class="start-date">
                                {{ session.start | localisedTimezone: 'shortDate' }}
                            </span>
                        </div>

                        <div class="agenda-card__header-top-row">
                            <div class="agenda-card__header-time">
                                <span *ngIf="!session.hide_start_time" class="start-time">
                                    {{ session.start | localisedTimezone: 'time' }}
                                </span>
                                <span *ngIf="!session.hide_start_time && !session.hide_end_time"> - </span>
                                <span *ngIf="!session.hide_end_time" class="end-time">
                                    {{ session.end | localisedTimezone: 'time' }}
                                </span>
                                <cc-timezone-tooltip
                                    *ngIf="!session.hide_start_time || !session.hide_end_time"
                                    [time]="session.start"
                                ></cc-timezone-tooltip>
                            </div>

                            <cc-agenda-card-schedule-button
                                *ngIf="showTicket && session.can_favourite !== false && addToMySchedule === true"
                                [session]="session"
                                [isInPersonalAgenda]="isInPersonalAgenda"
                                [isOnWaitingList]="isOnWaitingList"
                                [isTogglingInProgress]="isTogglingInProgress"
                                (ticketClick)="onTicketClick()"
                            ></cc-agenda-card-schedule-button>
                        </div>

                        <div class="agenda-card__header-heading">
                            <h2 class="agenda-card__header-title cc-primary-header">
                                {{ session.title }}
                            </h2>
                            <h4
                                *ngIf="enabledFeatures.expand_show_subtitle"
                                class="agenda-card__header-subtitle cc-primary-header"
                            >
                                {{ session.subtitle }}
                            </h4>
                        </div>
                    </div>
                </div>

                <div *ngIf="enabledFeatures.show_attendees" class="agenda-card__attendees">
                    <cc-agenda-card-attendees-list
                        [moduleId]="session.agenda"
                        [sessionId]="session.id"
                    ></cc-agenda-card-attendees-list>
                </div>

                <cc-agenda-card-expanded
                    *ngIf="expanded && expandedSession"
                    [expandedSession]="expandedSession"
                    [enabledFeatures]="enabledFeatures"
                ></cc-agenda-card-expanded>
            </div>

            <div
                class="agenda-card__footer"
                [ngClass]="{
                    spacer:
                        (enabledFeatures.expand_show_features &&
                            enabledFeatures.expand_show_location &&
                            session.location.length > 0) ||
                        session.tracks.length > 0
                }"
            >
                <div class="agenda-card__location-chips">
                    <cc-location-chips
                        *ngIf="session.location && session.location.length > 0 && enabledFeatures.expand_show_location"
                        [attr.aria-label]="
                            'SCHEDULE_LIST_LOCATIONS' | translate: { num_locations: session.location.length }
                        "
                        [locations]="session.location"
                        [clickable]="false"
                    >
                    </cc-location-chips>

                    <cc-track-chips
                        *ngIf="session.tracks && session.tracks.length > 0"
                        [attr.aria-label]="'SCHEDULE_LIST_TRACKS' | translate: { num_tracks: session.tracks.length }"
                        [tracks]="session.tracks"
                    >
                    </cc-track-chips>
                </div>

                <cc-agenda-card-feature-icons
                    *ngIf="enabledFeatures.expand_show_features"
                    [session]="session"
                ></cc-agenda-card-feature-icons>
            </div>

            <p *ngIf="isOnWaitingList && !showTicket" class="agenda-card__full-message">
                {{ 'AGENDA_CARDS_WAITING_LIST_DESC' | translate }}
            </p>
        </div>
    </ion-item>

    <cc-agenda-card-expand-button
        *ngIf="enabledFeatures.expand_show_speakers || enabledFeatures.expand_show_documents"
        [expanded]="expanded"
        [session]="session"
        (toggleExpandedClicked)="onToggleExpandedClicked()"
    ></cc-agenda-card-expand-button>

    <div *ngIf="isOnWaitingList && !showTicket" class="agenda-card__waiting-list">
        <i class="material-icons-outlined" aria-hidden="true">hourglass_empty</i>
        {{ 'AGENDA_CARDS_WAITING_LIST_LABEL' | translate }}
    </div>
</ion-card>
