import { Pipe, PipeTransform } from '@angular/core';
import { isArray } from 'lodash';

@Pipe({
    name: 'concat'
})
export class ConcatPipe implements PipeTransform {
    transform(input: any, character: string = ' '): any {
        if (!isArray(input)) {
            return input;
        }

        return input.join(character);
    }
}
