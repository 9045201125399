import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { first } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class LoadScriptService {
    private scripts: ScriptConfig[] = [];

    constructor(
        @Inject(DOCUMENT) private document,
        private rendererFactory: RendererFactory2
    ) {}

    public loadScript(opts: ScriptConfig): boolean {
        const scriptExists: HTMLScriptElement = this.document.getElementById(opts.id) as HTMLScriptElement;

        if (!scriptExists) {
            const head = this.document.getElementsByTagName('head')[0];

            const renderer: Renderer2 = this.rendererFactory.createRenderer(null, null);
            const script: HTMLScriptElement = renderer.createElement('script');

            script.id = opts.id;
            script.type = 'text/javascript';
            script.src = opts.src;
            script.async = true;
            script.onload = () => {
                opts.onload(opts.id);
            };
            script.onerror = (event) => {
                opts.onerror(event);
            };

            renderer.appendChild(head, script);
            this.scripts.push({ ...opts, element: script });
            return true;
        }
    }

    public getScript(id: string): ScriptConfig {
        return this.scripts.find((script: ScriptConfig) => script.id === id);
    }

    public delayedLoadScript(delay: Observable<any>, opts: ScriptConfig): void {
        delay.pipe(first()).subscribe(() => this.loadScript(opts));
    }
}

export interface ScriptConfig {
    src: string;
    id: string;
    onload: (id: string) => void;
    onerror: (error: string | Event) => void;
    element?: HTMLScriptElement;
}
