<div class="emoji-picker-popover__container">
    <emoji-mart
        emoji=""
        title=""
        color="var(--primary-color)"
        [darkMode]="false"
        [showPreview]="false"
        [emojiTooltip]="false"
        [totalFrequentLines]="1"
        [isNative]="true"
        [hideObsolete]="false"
        [exclude]="exclude"
        (emojiClick)="dismiss($event)"
    ></emoji-mart>
</div>
