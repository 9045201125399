/**
 * Routes scoped to a page within the user section of the app. /{appName}/user/*
 */
export const AppUserPageRoutes = {
    alerts: 'alerts',
    profile: 'profile',
    personalAgenda: 'personal-agenda',
    notes: 'notes',
    chat: 'chat',
    surveys: 'surveys',
    account: 'account',
    badge: 'badge',
    badgeContacts: 'event-connections',
    businessCards: 'business-cards',
    meetings: 'meetings'
};
