import { HttpErrorResponse } from '@angular/common/http';
import { UserBusinessCardsOrder } from '@features/user-business-cards/enums/user-business-cards-order.enum';
import { BusinessCardRequest } from '@features/user-business-cards/interfaces/business-card-request.interface';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SocialInfo } from '@shared/api';
import { PaginatedResponse } from '@shared/pagination/models/paginated-response.model';

export const UserBusinessCardsPageActions = createActionGroup({
    source: 'User Business Cards Page',
    events: {
        'Enter Page': emptyProps(),
        'Leave Page': emptyProps(),
        'Get Contacts': emptyProps(),
        'Get Contacts Success': props<{ response: PaginatedResponse<BusinessCardRequest> }>(),
        'Get Contacts Failure': props<{ error: HttpErrorResponse }>(),
        'Select Contact': props<{ contact: BusinessCardRequest }>(),
        'Set Contacts Offset': props<{ offset: number }>(),
        'Toggle Contacts Searching': emptyProps(),
        'Set Contacts Search': props<{ search: string }>(),
        'Set Contacts Ordering': props<{ ordering: UserBusinessCardsOrder }>(),
        'Get Requests': emptyProps(),
        'Get Requests Success': props<{ response: PaginatedResponse<BusinessCardRequest> }>(),
        'Get Requests Failure': props<{ error: HttpErrorResponse }>(),
        'Set Requests Offset': props<{ offset: number }>(),
        'Accept Request': props<{ request: BusinessCardRequest }>(),
        'Accept Request Success': props<{ request: BusinessCardRequest }>(),
        'Accept Request Failure': props<{ error: HttpErrorResponse }>(),
        'Decline Request': props<{ request: BusinessCardRequest }>(),
        'Decline Request Success': props<{ request: BusinessCardRequest }>(),
        'Decline Request Failure': props<{ error: HttpErrorResponse }>(),
        'View Profile Request': props<{ request: BusinessCardRequest }>(),
        'Go To Edit Page': emptyProps(),
        'Open Share Modal': emptyProps(),
        'Toggle Card Flipped': emptyProps(),
        'Open Scanner': emptyProps(),
        'Phone Selected Contact': emptyProps(),
        'Email Selected Contact': emptyProps(),
        'Open Selected Contact Notes': emptyProps(),
        'Download Selected Contact': emptyProps(),
        'Delete Selected Contact': emptyProps(),
        'Open Social Selected Contact': props<{ social: SocialInfo }>(),
        'Process Scan Result Success': props<{ request: BusinessCardRequest }>(),
        'Delete Contact': props<{ id: string }>(),
        'Delete Contact Success': props<{ id: string }>(),
        'Delete Contact Failure': props<{ error: HttpErrorResponse }>(),
        'Download Contact': props<{ id: string }>(),
        'Download Contact Success': props<{ id: string }>(),
        'Download Contact Failure': props<{ error: HttpErrorResponse }>(),
        'Download All Contacts': emptyProps(),
        'Download All Contacts Success': emptyProps(),
        'Download All Contacts Failure': props<{ error: HttpErrorResponse }>(),
        'Open Notes Modal': props<{ contact: BusinessCardRequest }>(),
        'Update Contact': props<{ id: string; update: Partial<BusinessCardRequest> }>(),
        'Update Contact Success': props<{ contact: BusinessCardRequest }>(),
        'Update Contact Failure': props<{ error: HttpErrorResponse }>()
    }
});
