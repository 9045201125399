import { NgModule } from '@angular/core';
import { AppCommonModule } from '@common/common.module';
import { HtmlModule } from '@features/html/html.module';
import { LottieModule } from 'ngx-lottie';
import { MultipleChoicePollComponent } from './components/multiple-choice-poll/multiple-choice-poll.component';
import { PollControlsComponent } from './components/poll-controls/poll-controls.component';
import { PollIntermissionComponent } from './components/poll-intermission/poll-intermission.component';
import { PollProxyVotesComponent } from './components/poll-proxy-votes/poll-proxy-votes.component';
import { PollComponent } from './components/poll/poll.component';
import { RatingPollComponent } from './components/rating-poll/rating-poll.component';
import { SurveyHeaderComponent } from './components/survey-header/survey-header.component';
import { SurveyComponent } from './components/survey/survey.component';
import { TextPollComponent } from './components/text-poll/text-poll.component';

@NgModule({
    declarations: [
        PollComponent,
        MultipleChoicePollComponent,
        TextPollComponent,
        RatingPollComponent,
        PollIntermissionComponent,
        PollControlsComponent,
        SurveyHeaderComponent,
        SurveyComponent,
        PollProxyVotesComponent
    ],
    imports: [AppCommonModule, LottieModule, HtmlModule],
    exports: [
        PollComponent,
        MultipleChoicePollComponent,
        TextPollComponent,
        RatingPollComponent,
        PollIntermissionComponent,
        PollControlsComponent,
        SurveyHeaderComponent,
        SurveyComponent,
        PollProxyVotesComponent
    ]
})
export class LivePollingSurveysModule {}
