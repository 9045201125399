import { Action, createReducer, on } from '@ngrx/store';
import { NavigationHistoryState } from '../models/navigation-history.state.model';
import { NavigationHistoryActions } from '../actions/navigation-history.actions';
import { AppCoreActions } from '../../../root-store/store/app-core/actions/app-core.actions';

export const navigationHistoryInitialState: NavigationHistoryState = {
    history: []
};

const navigationHistoryReducerFn = createReducer(
    navigationHistoryInitialState,
    on(NavigationHistoryActions.stampSuccess, (state, { item }) => ({
        ...state,
        history: [...state.history, item]
    })),
    on(NavigationHistoryActions.updateSuccess, (state, { item }) => ({
        ...state,
        history: [...state.history.slice(0, -1), item]
    })),
    on(NavigationHistoryActions.forget, NavigationHistoryActions.back, (state) => ({
        ...state,
        history: state.history.slice(0, -1)
    })),
    on(AppCoreActions.initialiseAppSuccess, () => navigationHistoryInitialState)
);

export function navigationHistoryReducer(
    state: NavigationHistoryState | undefined,
    action: Action
): NavigationHistoryState {
    return navigationHistoryReducerFn(state, action);
}
