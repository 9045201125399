import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { AuthenticationFacadeService } from '@core/authentication/services/authentication-facade.service';
import { AuthenticationActions } from '@core/root-store/store/authentication/actions/authentication.actions';
import { AppPageRoutes } from '@core/routing/constants/app-page-routes.constant';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class IsAuthenticatedGuard {
    constructor(
        private authenticationFacadeService: AuthenticationFacadeService,
        private router: Router,
        private appCoreFacadeService: AppCoreFacadeService
    ) {}

    canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.authenticationFacadeService.isAuthenticated().pipe(
            withLatestFrom(this.appCoreFacadeService.getAppName()),
            map(([isAuthenticated, appUrl]) => {
                if (isAuthenticated) {
                    return true;
                }

                this.authenticationFacadeService.dispatch(
                    AuthenticationActions.setRedirectUrl({ redirectUrl: state.url })
                );

                return this.router.createUrlTree([appUrl, AppPageRoutes.login]);
            })
        );
    }
}
