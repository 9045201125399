<div class="no-data__container">
    <ion-card class="no-data__card" @fadeIn>
        <ion-card-header class="no-data__card-header">
            <ng-lottie class="no-data__animation" [options]="noDataAnimationOptions"></ng-lottie>
        </ion-card-header>

        <ion-card-content class="no-data__card-content">
            <ion-card-title class="no-data__title">
                {{ title || 'EMPTY_DATA_DEFAULT_TITLE' | translate }}
            </ion-card-title>

            <ion-card-subtitle *ngIf="message" class="no-data__message">
                {{ message | translate }}
            </ion-card-subtitle>
        </ion-card-content>
    </ion-card>
</div>
