import { Pipe, PipeTransform } from '@angular/core';
import { FileIcons } from '@features/documents/enums/file-icons.enum';

@Pipe({
    name: 'mimetypeIcon',
    pure: true
})
export class MimetypeIconPipe implements PipeTransform {
    transform(mimetype: string, _?: any): any {
        if (!mimetype) {
            return FileIcons.PDF;
        }

        if (['document', 'word', 'text'].some((textFlag) => mimetype.includes(textFlag))) {
            return FileIcons.Text;
        } else if (mimetype.includes('excel')) {
            return FileIcons.Excel;
        } else if (mimetype.includes('image')) {
            return FileIcons.Image;
        } else if (mimetype.includes('pdf')) {
            return FileIcons.PDF;
        } else if (mimetype.includes('powerpoint')) {
            return FileIcons.Powerpoint;
        }

        return FileIcons.PDF;
    }
}
