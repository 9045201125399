interface BaseAction {
    label?: string;
    action: Action.ActionEnum;
    value: string;
    id: number;
}

interface BasicAction extends BaseAction {
    action: Exclude<Action.ActionEnum, Action.ActionEnum.OPENSESSION | Action.ActionEnum.OPENABSTRACT>;
}

interface OpenSessionAction extends BaseAction {
    action: Action.ActionEnum.OPENSESSION;
    module: number;
}

interface OpenAbstractAction extends BaseAction {
    action: Action.ActionEnum.OPENABSTRACT;
    module: number;
}

export type Action = BasicAction | OpenSessionAction | OpenAbstractAction;

export namespace Action {
    export enum ActionEnum {
        OPENDIALOG = 'OPEN_DIALOG',
        OPENURL = 'OPEN_URL',
        OPENMODULE = 'OPEN_MODULE',
        AWARDPOINTS = 'AWARD_POINTS',
        COMPLETECHALLENGE = 'COMPLETE_CHALLENGE',
        OPENSESSION = 'OPEN_SESSION',
        OPENPROFILE = 'OPEN_PROFILE',
        OPENCOMPANY = 'OPEN_COMPANY',
        OPENMAP = 'OPEN_MAP',
        OPENDOCUMENT = 'OPEN_DOCUMENT',
        OPENSURVEY = 'OPEN_SURVEY',
        OPENINFOBOOTH = 'OPEN_INFOBOOTH',
        OPENQANDA = 'OPEN_QANDA',
        OPENLIVEPOLL = 'OPEN_LIVE_POLL',
        OPENAGENDAFILTERS = 'OPEN_AGENDA_FILTERS',
        OPENLOOPINROOM = 'OPEN_LOOPIN_ROOM',
        OPENBADGE = 'OPEN_BADGE',
        OPENSCANNER = 'OPEN_SCANNER',
        OPENABSTRACT = 'OPEN_ABSTRACT',
        OPENEVENTSCHEDULE = 'OPEN_EVENT_SCHEDULE',
        OPENNOTES = 'OPEN_NOTES',
        OPENMEETINGS = 'OPEN_MEETINGS',
        OPENBUSINESSCARDS = 'OPEN_BUSINESS_CARDS',
        OPENSURVEYS = 'OPEN_OWN_SURVEYS',
        OPENEDITPROFILE = 'OPEN_EDIT_PROFILE'
    }
}
