import * as models from '@shared/api';

export interface DocumentModule {
    module: number;
    documents?: Array<number>;
    document_ordering?: models.ContentOrderingSettings;
    documentview?: DocumentModule.DocumentviewEnum;
}

export namespace DocumentModule {
    export enum DocumentviewEnum {
        All = 'all',
        Specific = 'specific'
    }
}
