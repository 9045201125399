export enum MeetingNotificationType {
    inviteSent = 'invite_sent',
    inviteAccepted = 'invite_accepted',
    inviteDeclined = 'invite_declined',
    meetingCancelled = 'meeting_cancelled',
    meetingUpdated = 'meeting_updated',
    meetingPending = 'meeting_pending',
    meetingStarted = 'meeting_started',
    updateAccepted = 'update_accepted',
    updateDeclined = 'update_declined'
}
