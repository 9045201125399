import { HttpErrorResponse } from '@angular/common/http';
import { MeetingCategory } from '@features/meeting-booking/enums/meeting-category.enum';
import { CreateMeetingPayload } from '@features/meeting-booking/interfaces/create-meeting-payload.interface';
import { VideoCall } from '@features/video-calls/models/video-call.interface';
import { createAction, props } from '@ngrx/store';
import { MeetingBookingConfigModel } from '../models/meeting-booking-config.model';
import { MeetingBookingTag } from '../models/meeting-booking-tag.model';
import { MeetingLocation } from '../models/meeting-location.interface';
import { ExpandedMeeting, Meeting } from '../models/meeting.model';
import { AvailabilityQueryParams } from '@features/meeting-booking/interfaces/availability-query-params.interface';

const prefix = `[Meeting Booking] `;

const loadMeetingBookingConfig = createAction(`${prefix} Load meeting booking config`);

const loadMeetingBookingConfigSuccess = createAction(
    `${prefix} Load meeting booking config success`,
    props<{ config: MeetingBookingConfigModel }>()
);

const setBookableRange = createAction(`${prefix} Set bookable range`, props<{ start: string; end: string }>());

const cancelMeeting = createAction(`${prefix} Cancel meeting`, props<{ meetingId: Meeting['id'] }>());

const updateUserMeeting = createAction(
    `${prefix} Update user meeting`,
    props<{ meetingId: Meeting['id']; meeting: Partial<Meeting>; status: MeetingCategory }>()
);

const updateRecurringUserMeeting = createAction(
    `${prefix} Update recurring user meeting`,
    props<{
        recurrenceParentId: Meeting['id'];
        meeting: Partial<Meeting>;
        originalStart: string;
        status: MeetingCategory;
    }>()
);

const createMeeting = createAction(
    `${prefix} Create meeting`,
    props<{ meeting: CreateMeetingPayload; invitees: Array<number> }>()
);

const createMeetingSuccess = createAction(`${prefix} Create meeting success`);

const updateMeeting = createAction(
    `${prefix} Update meeting`,
    props<{ meetingId: string; meeting: Partial<CreateMeetingPayload>; invitees?: Array<number> }>()
);

const updateRecurringMeeting = createAction(
    `${prefix} Update recurring meeting`,
    props<{
        recurringParentId: string;
        originalStart: string;
        meeting: Partial<CreateMeetingPayload>;
        invitees?: Array<number>;
    }>()
);

const updateMeetingSeries = createAction(
    `${prefix} Update meeting series`,
    props<{ meetingId: string; meeting: Partial<CreateMeetingPayload>; invitees?: Array<number> }>()
);

const updateMeetingSuccess = createAction(`${prefix} Update meeting success`);

const getLocations = createAction(`${prefix} Get locations`, props<{ range?: AvailabilityQueryParams }>());

const getLocationsSuccess = createAction(`${prefix} Get locations success`, props<{ locations: MeetingLocation[] }>());

const getAvailableTags = createAction(`${prefix} Get available tags`);

const getAvailableTagsSuccess = createAction(
    `${prefix} Get available tags success`,
    props<{ tags: MeetingBookingTag[] }>()
);

const startVideoCall = createAction(`${prefix} Start video call`, props<{ meetingId: string }>());
const startVideoCallSuccess = createAction(`${prefix} Start video call success`, props<{ incomingCall: VideoCall }>());

const joinMeeting = createAction(`${prefix} Join meeting`, props<{ meeting: ExpandedMeeting }>());

const httpError = createAction(`${prefix} Http Error`, props<{ error: HttpErrorResponse }>());
const nullifyError = createAction(`${prefix} Nullify error`);

const leavePage = createAction(`${prefix} Leave page`);

export const MeetingBookingActions = {
    loadMeetingBookingConfig,
    loadMeetingBookingConfigSuccess,
    setBookableRange,
    cancelMeeting,
    updateUserMeeting,
    createMeeting,
    createMeetingSuccess,
    updateMeeting,
    updateMeetingSuccess,
    getLocations,
    getLocationsSuccess,
    getAvailableTags,
    getAvailableTagsSuccess,
    startVideoCall,
    startVideoCallSuccess,
    joinMeeting,
    httpError,
    nullifyError,
    leavePage,
    updateMeetingSeries,
    updateRecurringUserMeeting,
    updateRecurringMeeting
};
