import { Injectable } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { PeopleMinimal } from '@shared/api';
import { PersonPopoverComponent } from '@shared/person-popover/components/person-popover/person-popover.component';
import { BreakpointService, Breakpoints } from '@shared/utilities/breakpoint.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PersonPopoverService {
    constructor(
        private popoverController: PopoverController,
        private modalController: ModalController,
        private breakpointService: BreakpointService
    ) {}

    public async display(person: PeopleMinimal, event: MouseEvent, showBackdrop: boolean): Promise<void> {
        const isSmall = await firstValueFrom(this.breakpointService.is(({ width }) => width <= Breakpoints.Small));

        if (isSmall) {
            return this.displayBottomModal(person, showBackdrop);
        }

        this.displayPopover(person, event, showBackdrop);
    }

    private async displayBottomModal(person: PeopleMinimal, showBackdrop: boolean): Promise<void> {
        const modal = await this.modalController.create({
            component: PersonPopoverComponent,
            componentProps: {
                person
            },
            showBackdrop,
            cssClass: 'person-popover-modal',
            breakpoints: [0.22],
            handle: false,
            initialBreakpoint: 0.22
        });

        await modal.present();
    }

    private async displayPopover(person: PeopleMinimal, event: MouseEvent, showBackdrop: boolean): Promise<void> {
        const popover = await this.popoverController.create({
            component: PersonPopoverComponent,
            componentProps: {
                person
            },
            showBackdrop,
            event,
            side: 'bottom',
            alignment: 'center',
            cssClass: 'person-popover'
        });
        await popover.present();
    }
}
