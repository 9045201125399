<ion-list class="cc-list-cards cc-agenda-list-cards schedule-list-grid" lines="none" role="list">
    <cc-agenda-card
        *ngFor="let session of sessions; trackBy: trackByFn"
        class="schedule-list__card"
        role="listitem"
        [ngClass]="'cc-schedule-list-item-' + session.slim.id"
        [attr.aria-label]="'AGENDA_EVENT_ACTION' | translate"
        [session]="session.slim"
        [expandedSession]="session.expanded"
        [isInPersonalAgenda]="isInPersonalAgenda(session.slim)"
        [isTogglingInProgress]="togglingSessionsInProgress.includes(session.slim.id)"
        [isOnNow]="isOnNow(session.slim)"
        [showTicket]="showTicket"
        [isOnWaitingList]="isOnWaitingList(session.slim)"
        [showDate]="showDayDate"
        [flatStyling]="flatCardStyling"
        [enabledFeatures]="enabledFeatures"
        [ccScrollTo]="session.slim.id === firstOn"
        [addToMySchedule]="addToMySchedule"
        (sessionClick)="onSessionClick(session.slim)"
        (sessionExpanded)="onSessionExpanded(session.slim)"
        (ticketClick)="onTicketClick(session.slim)"
    >
    </cc-agenda-card>
</ion-list>
