import { AppNotification } from '@core/notifications/interfaces/notification.interface';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { NotificationsActions } from '../actions/notifications.actions';
import { NotificationsState } from '../models/notifications-state.model';

export const notificationsEntityAdapater: EntityAdapter<AppNotification> = createEntityAdapter<AppNotification>();

export const notificationsInitialState: NotificationsState = notificationsEntityAdapater.getInitialState();

export const notificationsReducer = createReducer(
    notificationsInitialState,
    on(NotificationsActions.showNotification, (state, { notification }) =>
        notificationsEntityAdapater.addOne(notification, state)
    ),
    on(NotificationsActions.dismissNotification, (state, { id }) => notificationsEntityAdapater.removeOne(id, state))
);
