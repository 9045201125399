// Connecting to the firebase project, currently used for presence tracking
export const FirebaseInfo = {
    apiKey: 'AIzaSyC3vEAtWJWmPQIhkydSlLqEBgOQYDWlxog',
    authDomain: 'crowdcomms-user-presence.firebaseapp.com',
    projectId: 'crowdcomms-user-presence',
    storageBucket: 'crowdcomms-user-presence.appspot.com',
    messagingSenderId: '669308280339',
    appId: '1:669308280339:web:0df3c06083ea64d6a829e6',
    refreshInterval: 55 * 1000
};

// Each environment has their own database
export const firebaseDatabaseUrl = (environment: string): string =>
    `https://crowdcomms-user-presence-${environment}.firebaseio.com`;
