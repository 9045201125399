import { Injectable } from '@angular/core';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { AuthenticationFacadeService } from '@core/authentication/services/authentication-facade.service';
import { BannerStorageKeys } from '@core/banner/constants/banner-storage-keys.constant';
import { CookieStorageKeys } from '@core/banner/constants/cookie-storage-keys.constant';
import { CookieConfigurationState } from '@core/banner/interfaces/cookie-configuration-state.interface';
import { BannerApiService } from '@core/banner/services/banner-api/banner-api.service';
import { BannerFacadeService } from '@core/banner/services/banner-facade/banner-facade.service';
import { CookieConsentService } from '@core/banner/services/cookie-consent/cookie-consent.service';
import { TrackingTransparencyService } from '@core/native/services/tracking-transparency.service';
import { AppCoreActions } from '@core/root-store/store/app-core/actions/app-core.actions';
import { StorageService } from '@core/storage/services/storage.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { BannerActions } from '../actions/banner.actions';

@Injectable()
export class BannerEffects {
    public listenToAppAccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AppCoreActions.initialiseAppSuccess),
                switchMap(() =>
                    this.authenticationFacadeService.hasAppAccess().pipe(
                        tap((hasAccess) => {
                            if (hasAccess) {
                                this.bannerFacadeService.dispatch(BannerActions.fetchGlobalBannerConfig());
                            } else {
                                this.bannerFacadeService.dispatch(BannerActions.resetGlobalBanner());
                            }
                        })
                    )
                )
            ),
        { dispatch: false }
    );

    public fetchGlobalBannerConfig$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BannerActions.fetchGlobalBannerConfig),
            withLatestFrom(this.appCoreFacadeService.getAppName()),
            switchMap(([_, appName]) =>
                this.bannerApiService.getBannerConfig(appName).pipe(
                    map((config) => BannerActions.fetchGlobalBannerConfigSuccess({ config })),
                    catchError((error) => of(BannerActions.fetchGlobalBannerConfigFailure({ error })))
                )
            )
        )
    );

    public getHasDismissedGlobalBanner$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppCoreActions.initialiseAppSuccess),
            withLatestFrom(this.appCoreFacadeService.getAppName()),
            map(([_, appName]) => this.storageService.get(BannerStorageKeys.hasDismissed(appName))),
            filter((hasDismissed) => !!hasDismissed),
            map(() => BannerActions.setHasDismissed({ hasDismissed: true }))
        )
    );

    public setHasDismissedGlobalBanner$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BannerActions.dismissGlobalBanner),
            withLatestFrom(this.appCoreFacadeService.getAppName()),
            tap(([_, appName]) => {
                this.storageService.put(BannerStorageKeys.hasDismissed(appName), true);
            }),
            map(() => BannerActions.setHasDismissed({ hasDismissed: true }))
        )
    );

    public fetchCookieConfigurationOnAppInitialise$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppCoreActions.initialiseAppSuccess),
            map(() => BannerActions.fetchCookiesConfiguration())
        )
    );

    public fetchCookieConfiguration$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(BannerActions.fetchCookiesConfiguration),
            withLatestFrom(this.appCoreFacadeService.getAppName()),
            switchMap(([_, appName]) =>
                from(this.trackingTransparencService.getApprovalStatus()).pipe(
                    map((trackingTransparencyApproval) => {
                        let cookies: CookieConfigurationState = this.storageService.getFromStorage(
                            CookieStorageKeys.preferences(appName)
                        );

                        // If this is null it means we're not on iOS native, just return it directly from storage.
                        if (trackingTransparencyApproval === null) {
                            return cookies;
                        }

                        // Otherwise continue and modify the stored value.
                        cookies = {
                            ...cookies,
                            cookies_dismissed: true,
                            allow_analytics: trackingTransparencyApproval,
                            allow_advertising: trackingTransparencyApproval
                        };

                        return cookies;
                    })
                )
            ),
            map((cookies) => BannerActions.fetchCookiesConfigurationSuccess({ cookies }))
        );
    });

    public sendCookieConfiguration = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                BannerActions.fetchCookiesConfigurationSuccess,
                BannerActions.acceptAllCookiesSuccess,
                BannerActions.saveSelectedCookiesSuccess
            ),
            withLatestFrom(this.appCoreFacadeService.getAppName()),
            switchMap(([cookies, appName]) => {
                const payload = {
                    allow_advertising: false,
                    allow_analytics: false,
                    allow_functional: false,
                    allow_performance: false,
                    cookies_dismissed: false,
                    ...cookies.cookies
                };
                return this.cookieService
                    .setCookies(appName, payload)
                    .pipe(map(() => BannerActions.sendCookiesConfigurationSuccess()));
            })
        );
    });

    public acceptAllCookies$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(BannerActions.acceptAllCookies),
            map(() => {
                const config: CookieConfigurationState = {
                    allow_advertising: true,
                    allow_analytics: true,
                    allow_functional: true,
                    allow_performance: true,
                    cookies_dismissed: true
                };
                return config;
            }),
            map((cookies) => {
                return BannerActions.acceptAllCookiesSuccess({ cookies });
            })
        );
    });

    public saveSelectedCookies$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(BannerActions.saveSelectedCookies),
            map(({ cookies }) => {
                return BannerActions.saveSelectedCookiesSuccess({ cookies });
            })
        );
    });

    public cookiesDimissed$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(BannerActions.saveSelectedCookiesSuccess, BannerActions.acceptAllCookiesSuccess),
            map((_) => {
                return BannerActions.cookiesDismissed();
            })
        );
    });

    constructor(
        private actions$: Actions,
        private bannerApiService: BannerApiService,
        private appCoreFacadeService: AppCoreFacadeService,
        private storageService: StorageService,
        private authenticationFacadeService: AuthenticationFacadeService,
        private bannerFacadeService: BannerFacadeService,
        private trackingTransparencService: TrackingTransparencyService,
        private cookieService: CookieConsentService
    ) {}
}
