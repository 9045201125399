import { Component } from '@angular/core';
import { EmojiEvent } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { PopoverController } from '@ionic/angular';

@Component({
    selector: 'cc-emoji-picker-popover',
    templateUrl: './emoji-picker-popover.component.html',
    styleUrls: ['./emoji-picker-popover.component.scss']
})
export class EmojiPickerPopoverComponent {
    public exclude = ['flags', 'symbols'];

    constructor(private popoverController: PopoverController) {}

    public dismiss(event: EmojiEvent): void {
        this.popoverController.dismiss(event.emoji.native);
    }
}
