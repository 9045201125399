import { Injectable, Signal } from '@angular/core';
import { Realtime, Types as AblyTypes } from 'ably';
import { BehaviorSubject, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';
import { AblyFactoryService } from './ably-factory.service';
import { flatMap } from 'lodash';
import { AblyApiService } from './ably-api.service';
import { ablyChannelNames } from '@core/ably/constants/ably-channel-names.constant';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable({
    providedIn: 'root'
})
export class AblyMessagingService {
    public connected$: Observable<boolean>;
    public connected: Signal<boolean>;

    private ablyClient: Realtime;
    private privateChannelName: string;
    private moduleChannelName: string;
    private connectedSubject$: BehaviorSubject<boolean>;

    private channelsMap: Map<string, AblyTypes.RealtimeChannelCallbacks>;
    private eventsSubject$: Subject<AblyTypes.Message>;

    private readonly connectionNotInitialiseMessage = 'Ably Client has not been initialised.';

    constructor(
        private ablyFactoryService: AblyFactoryService,
        private ablyApiService: AblyApiService
    ) {
        this.channelsMap = new Map<string, AblyTypes.RealtimeChannelCallbacks>();
        this.eventsSubject$ = new Subject<AblyTypes.Message>();
        this.connectedSubject$ = new BehaviorSubject<boolean>(false);
        this.connected$ = this.connectedSubject$.asObservable();
        this.connected = toSignal(this.connected$);
    }

    public initialise(appName: string, deviceId: string): Observable<void> {
        if (this.ablyClient) {
            this.disconnect();
        }

        return this.ablyApiService
            .getAblyToken(appName)
            .pipe(switchMap(({ token }) => this.createAblyClient(appName, token, deviceId)));
    }

    /**
     * Connect to the public messaging channel for this app.
     *
     * @param channelName the public channel id is the app shortcode.
     */
    public connectToPublicChannel(channelName: string): void {
        this.connectToChannel(channelName);
    }

    /**
     * Connect to a user specific private channel.
     *
     * @param channelName the private channel id
     */
    public connectToPrivateChannel(channelName: string): void {
        this.connectToChannel(channelName);
        this.privateChannelName = channelName;
    }

    /**
     * Disconnect from the currently connected private channel.
     */
    public disconnectFromPrivateChannel(): void {
        this.disconnectFromChannel(this.privateChannelName);
        this.privateChannelName = undefined;
    }

    /**
     * Connect to a user specific module channel.
     */
    public connectToModuleChannel(appUrl: string, moduleId: number): void {
        const channelName = ablyChannelNames.module(appUrl, moduleId);

        if (this.moduleChannelName) {
            if (this.moduleChannelName === channelName) {
                return;
            }

            this.disconnectFromModuleChannel();
        }

        this.connectToChannel(channelName);
        this.moduleChannelName = channelName;
    }

    /**
     * Disconnect from the currently connected module channel.
     */
    public disconnectFromModuleChannel(): void {
        if (!this.moduleChannelName) {
            return;
        }

        this.disconnectFromChannel(this.moduleChannelName);
        this.moduleChannelName = undefined;
    }

    /**
     * Disconnect from the public channel.
     */
    public disconnectFromPublicChannel(channelName: string): void {
        this.disconnectFromChannel(channelName);
    }

    public disconnect(): void {
        if (!this.ablyClient) {
            return;
        }

        for (const channel of [...this.channelsMap.values()]) {
            this.disconnectFromChannel(channel.name);
        }

        this.ablyClient.close();
    }

    /**
     * Gets a message stream of all events filtered by the entity names provided.
     */
    public getMessageStream(entityNames?: string[], noSuffix?: boolean): Observable<AblyTypes.Message> {
        const eventNames = noSuffix ? entityNames : this.getEventNames(entityNames);

        if (!eventNames) {
            return this.eventsSubject$.asObservable();
        }

        return this.eventsSubject$.asObservable().pipe(filter((event) => eventNames.includes(event.name)));
    }

    private readonly failedToConnectMessage = (clientId: string): string =>
        `Ably Client: Failed to connect to ably with key: ${clientId}`;

    private channelIsConnected(channelName: string): boolean {
        return this.channelsMap.has(channelName);
    }

    private connectToChannel(channelName: string): void {
        if (!this.ablyClient) {
            console.error(this.connectionNotInitialiseMessage);
            return;
        }

        if (this.channelIsConnected(channelName)) {
            return;
        }

        const channel = this.ablyClient.channels.get(channelName);

        this.channelsMap.set(channelName, channel);

        channel.subscribe((message) => this.eventsSubject$.next(message));
    }

    private disconnectFromChannel(channelName: string): void {
        if (!this.ablyClient) {
            console.error(this.connectionNotInitialiseMessage);
            return;
        }

        if (!this.channelIsConnected(channelName)) {
            return;
        }

        const channel = this.channelsMap.get(channelName);

        if (!channel) {
            return;
        }

        channel.unsubscribe();
        channel.detach();

        this.channelsMap.delete(channelName);
    }

    private getEventNames(entityNames: string[]): string[] {
        if (!entityNames) {
            return undefined;
        }

        const eventTypes = ['create', 'update', 'delete', 'sent'];

        return flatMap(entityNames, (entityName) => eventTypes.map((eventType) => `${entityName}.${eventType}`));
    }

    private createAblyClient(appName: string, token: string, deviceId: string): Observable<void> {
        const ablyClient = this.ablyFactoryService.createAblyClient({
            token,
            clientId: deviceId,
            authCallback: (__, callback) => {
                this.ablyApiService
                    .getAblyToken(appName)
                    .pipe(
                        tap((response) => callback('', response.token)),
                        catchError((error) => {
                            callback(error.message, '');
                            return of(error.message);
                        })
                    )
                    .subscribe();
            }
        });

        const connectionSubject$ = new ReplaySubject<void>();

        ablyClient.connection.once('connected', () => {
            console.log('AblyClient: established initial connection');

            this.ablyClient = ablyClient;
        });

        ablyClient.connection.on('connected', () => {
            console.log('AblyClient: connected');

            this.connectedSubject$.next(true);
            connectionSubject$.next();
            connectionSubject$.complete();
        });

        ablyClient.connection.once('failed', () => {
            const errorMessage = this.failedToConnectMessage(ablyClient.clientId);
            console.error(errorMessage);
            this.connectedSubject$.next(false);
            connectionSubject$.error(errorMessage);
        });

        ablyClient.connection.on('disconnected', () => {
            console.warn('Ably Client: Connection lost, temporarily disconnected. Ably will attempt to reconnect...');
            this.connectedSubject$.next(false);
        });

        ablyClient.connection.on('suspended', () => {
            console.warn('Ably Client: Ably has been disconnected for 2 minutes, connection suspended.');
            this.connectedSubject$.next(false);
        });

        ablyClient.connection.on('closed', () => {
            console.warn('Ably Client: Connection closed.');
            this.connectedSubject$.next(false);
        });

        return connectionSubject$.asObservable();
    }
}
