import { Directive, TemplateRef, ViewContainerRef, OnInit } from '@angular/core';
import { AuthenticationFacadeService } from '../../../core/authentication/services/authentication-facade.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
    selector: '[ccIfAuthenticated]'
})
export class IfAuthenticatedDirective implements OnInit {
    constructor(
        private authenticationFacadeService: AuthenticationFacadeService,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef
    ) {}

    ngOnInit(): void {
        this.authenticationFacadeService
            .isAuthenticated()
            .pipe(untilDestroyed(this))
            .subscribe((isAuthenticated) => {
                if (isAuthenticated) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                } else {
                    this.viewContainer.clear();
                }
            });
    }
}
