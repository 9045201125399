export interface ContentOrderingCriteria {
    id?: number;
    field: string;
    direction?: ContentOrderingCriteria.DirectionEnum;
    order?: number;
    ordering_settings: number;
}

export namespace ContentOrderingCriteria {
    export enum DirectionEnum {
        Ascending = 'ascending',
        Descending = 'descending'
    }
}
