export interface SlimApp {
    url_name: string;
    name?: string;
    /**
     * Accepts your current local time and converts it to UTC based off the timezone you select
     */
    start: Date;
    /**
     * Accepts your current local time and converts it to UTC based off the timezone you select
     */
    end: Date;
    has_portal_module?: string;
    timezone: string;
    privacy?: SlimApp.PrivacyEnum;
}

export namespace SlimApp {
    export enum PrivacyEnum {
        Public = 'public' as any,
        Private = 'private' as any,
        Secure = 'secure' as any
    }
}
