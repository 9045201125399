import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { ActionPopoverType } from '@shared/action-popover/enums/action-popover-type.enum';
import { ToastService } from '@core/toast/services/toast/toast.service';
import { UrlUtilityService } from '@shared/utilities/url-utility.service';

@Component({
    selector: 'cc-action-popover',
    templateUrl: './action-popover.component.html',
    styleUrls: ['./action-popover.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionPopoverComponent implements OnInit {
    @Input()
    actionType: ActionPopoverType;

    @Input()
    actionContent: string;

    actionPopoverData: ActionPopoverData;

    constructor(
        private popoverController: PopoverController,
        private toastService: ToastService,
        private urlUtilityService: UrlUtilityService
    ) {}

    ngOnInit(): void {
        this.actionPopoverData = ActionPopoverDataObject[this.actionType];
    }

    copy(): void {
        this.close();
        this.toastService.success(this.actionPopoverData.copyToast);
        navigator.clipboard.writeText(this.actionContent);
    }

    action(): void {
        switch (this.actionType) {
            case ActionPopoverType.Phone:
                this.urlUtilityService.openPhoneNumber(this.actionContent);
                break;
            case ActionPopoverType.Email:
                this.urlUtilityService.openEmail(this.actionContent);
                break;
            case ActionPopoverType.Website:
                this.urlUtilityService.open(this.actionContent);
                break;
        }
        this.close();
    }

    close(): void {
        this.popoverController.dismiss();
    }
}

interface ActionPopoverData {
    header: string;
    icon: string;
    copyToast: string;
    hint: string;
}

const ActionPopoverDataObject: Record<ActionPopoverType, ActionPopoverData> = {
    [ActionPopoverType.Email]: {
        header: 'ACTION_POPOVER_EMAIL_HEADER',
        icon: 'email',
        copyToast: 'ACTION_POPOVER_EMAIL_TOAST',
        hint: 'ACTION_POPOVER_EMAIL_HINT'
    },
    [ActionPopoverType.Phone]: {
        header: 'ACTION_POPOVER_PHONE_HEADER',
        icon: 'phone',
        copyToast: 'ACTION_POPOVER_PHONE_TOAST',
        hint: 'ACTION_POPOVER_PHONE_HINT'
    },
    [ActionPopoverType.Website]: {
        header: 'ACTION_POPOVER_WEBSITE_HEADER',
        icon: 'launch',
        copyToast: 'ACTION_POPOVER_WEBSITE_TOAST',
        hint: 'ACTION_POPOVER_WEBSITE_HINT'
    }
};
