import { NgModule } from '@angular/core';
import { PersonComponent } from './pages/person/person.component';
import { PersonDocumentsComponent } from './components/person-documents/person-documents.component';
import { PersonSocialMediaLinksComponent } from './components/person-social-media-links/person-social-media-links.component';
import { PersonAgendaSessionsComponent } from './components/person-agenda-sessions/person-agenda-sessions.component';
import { PeopleStoreModule } from './store/people-store.module';
import { PersonFooterComponent } from './components/person-footer/person-footer.component';
import { PeopleRoutingModule } from './people-routing.module';
import { HtmlModule } from '@features/html/html.module';
import { CustomFieldsModule } from '@shared/custom-fields/custom-fields.module';
import { AppCommonModule } from '@common/common.module';
import { SharedPeopleModule } from '@shared/people/shared-people.module';
import { SharedAgendaModule } from '@shared/agenda/shared-agenda.module';
import { ImagesModule } from '@shared/images/images.module';
import { SharedSocialMediaModule } from '@shared/social-media/shared-social-media.module';
import { SearchModule } from '@shared/search/search.module';
import { SharedDocumentsModule } from '@shared/documents/shared-documents.module';
import { BannerAdModule } from '@shared/banner-ad/banner-ad.module';
import { PersonBusinessCardExchangeComponent } from './components/person-business-card-exchange/person-business-card-exchange.component';
import { BusinessCardsModule } from '@shared/business-cards/business-cards.module';

@NgModule({
    declarations: [
        PersonComponent,
        PersonDocumentsComponent,
        PersonSocialMediaLinksComponent,
        PersonAgendaSessionsComponent,
        PersonFooterComponent,
        PersonBusinessCardExchangeComponent
    ],
    imports: [
        AppCommonModule,
        PeopleRoutingModule,
        PeopleStoreModule,
        HtmlModule,
        CustomFieldsModule,
        SharedPeopleModule,
        SharedAgendaModule,
        SharedSocialMediaModule,
        SharedDocumentsModule,
        ImagesModule,
        SearchModule,
        BannerAdModule,
        BusinessCardsModule
    ]
})
export class PeopleModule {}
