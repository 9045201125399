import { InjectionToken, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomIcons } from './constants/custom-icons.constant';

export const ICONS = new InjectionToken('icons');

@NgModule({
    imports: [CommonModule],
    providers: [
        {
            provide: ICONS,
            useValue: CustomIcons
        }
    ]
})
export class IconsModule {}
