import { Injectable } from '@angular/core';
import { PortalService } from '@features/portal/services/portal.service';
import { Router } from '@angular/router';
import { AppPageRoutes } from '../../routing/constants/app-page-routes.constant';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppCoreApiService } from '@core/app-core/services/app-core-api.service';
import { ContainerService } from '@features/container/services/container.service';
import { AppModuleRoutes } from '@core/routing/constants/module-routes.constant';
import { captureException } from '@sentry/angular-ivy';

@Injectable({
    providedIn: 'root'
})
export class DeeplinksService {
    constructor(
        private portalService: PortalService,
        private router: Router,
        private appCoreApiService: AppCoreApiService,
        private appCoreFacadeService: AppCoreFacadeService,
        private containerService: ContainerService
    ) {}

    public handle(url: string): void {
        const action = this.findMatchingAction(url);

        if (!action) {
            return;
        }

        const params = new URLSearchParams(url.split('?')[1]);
        const targetAppUrl = params.get('targetAppUrl');
        const id = params.get('id');
        const moduleId = params.get('moduleId');

        const actionParams = { action, id, moduleId };
        if (!this.paramsAreValid(targetAppUrl, actionParams)) {
            captureException(new Error('Invalid deeplink params'), {
                extra: { url }
            });
            return;
        }

        combineLatest([
            this.appCoreApiService.getAppSettings(targetAppUrl),
            this.appCoreFacadeService.getAppSettings(),
            this.containerService.isContainerApp(targetAppUrl)
        ])
            .pipe(take(1))
            .subscribe(([deepLinkApp, currentApp, isContainerApp]) => {
                if (isContainerApp) {
                    this.containerService.setApp(deepLinkApp.url_name);
                }

                if (!isContainerApp && currentApp && currentApp.url_name !== deepLinkApp.url_name) {
                    this.portalService.setPortalConfigForApp(deepLinkApp.url_name, {
                        target: deepLinkApp.url_name,
                        portal: currentApp.url_name,
                        portal_name: currentApp.name
                    });
                }

                this.handleAction(targetAppUrl, actionParams);
            });
    }

    private handleAction(appUrl: string, params: DeepLinkActionParams): void {
        if (params.action === DeeplinksAction.OpenSession) {
            this.router.navigate([
                appUrl,
                AppPageRoutes.module,
                params.moduleId,
                AppModuleRoutes.agenda,
                AppPageRoutes.session,
                params.id
            ]);
            return;
        }

        const route = DeeplinkActionsRouteMap[params.action];

        if (!route) {
            return;
        }

        this.router.navigate([appUrl, route, params.id]);
    }

    private findMatchingAction(url: string): DeeplinksAction {
        return Object.values(DeeplinksAction).find((deepLinksAction) => url.includes(deepLinksAction));
    }

    private paramsAreValid(appUrl: string, params: DeepLinkActionParams): boolean {
        if (!appUrl) {
            return false;
        }

        if (!params.id) {
            return false;
        }

        if (params.action === DeeplinksAction.OpenSession && !params.moduleId) {
            return false;
        }

        return true;
    }
}

export enum DeeplinksAction {
    OpenSession = 'open-session',
    OpenModule = 'open-module',
    OpenInfopage = 'open-infopage',
    OpenCompany = 'open-company',
    OpenMap = 'open-map',
    OpenProfile = 'open-profile',
    OpenSurvey = 'open-survey',
    OpenQanda = 'open-qanda',
    OpenPoll = 'open-poll'
}

export type DeepLinkActionParams =
    | {
          action: DeeplinksAction.OpenSession;
          id: string;
          moduleId: string;
      }
    | {
          action: Exclude<DeeplinksAction, DeeplinksAction.OpenSession>;
          id: string;
      };

export const DeeplinkActionsRouteMap = {
    [DeeplinksAction.OpenSession]: AppPageRoutes.session,
    [DeeplinksAction.OpenModule]: AppPageRoutes.module,
    [DeeplinksAction.OpenInfopage]: AppPageRoutes.infoPage,
    [DeeplinksAction.OpenCompany]: AppPageRoutes.company,
    [DeeplinksAction.OpenMap]: AppPageRoutes.map,
    [DeeplinksAction.OpenProfile]: AppPageRoutes.person,
    [DeeplinksAction.OpenSurvey]: AppPageRoutes.poll,
    [DeeplinksAction.OpenQanda]: AppPageRoutes.qanda,
    [DeeplinksAction.OpenPoll]: AppPageRoutes.livePoll
};

export interface StoredDeeplinkAction {
    action: DeeplinksAction;
    id: string;
    appUrl: string;
}
