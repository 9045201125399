import { Directive, HostBinding, HostListener, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PeopleMinimal } from '@shared/api';
import { PersonPopoverService } from '@shared/person-popover/services/person-popover/person-popover.service';

@Directive({
    selector: '[ccPersonPopover]'
})
export class PersonPopoverDirective {
    @Input('ccPersonPopover')
    public person: Partial<PeopleMinimal>;

    @Input()
    public showBackdrop: boolean = true;

    @HostBinding('attr.role')
    public role: string = 'button';

    @HostBinding('attr.aria-label')
    public get ariaLabel(): string {
        return this.translateService.instant('PERSON_POPOVER_ARIA_LABEL', { first_name: this.person.first_name });
    }

    @HostListener('click', ['$event'])
    public onClick(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();
        this.personPopoverService.display(this.person, event, this.showBackdrop);
    }

    constructor(
        private personPopoverService: PersonPopoverService,
        private translateService: TranslateService
    ) {}
}
