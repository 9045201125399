import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, take, map } from 'rxjs/operators';
import { AppCoreActions } from '../../root-store/store/app-core/actions/app-core.actions';
import { AppRouteParams } from '../../routing/constants/app-route-params.constant';
import { AppCoreFacadeService } from '../services/app-core-facade.service';

/**
 * This guard will trigger an app to initialise, and then wait for initialisation to complete before resolving.
 */
@Injectable({
    providedIn: 'root'
})
export class AppInitialisationGuard {
    constructor(private appCoreFacadeService: AppCoreFacadeService) {}

    canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
        this.appCoreFacadeService
            .getAppName()
            .pipe(take(1))
            .subscribe((appName) => {
                const currentAppName = appName;
                const newAppName = next.paramMap.get(AppRouteParams.appName);

                if (currentAppName !== newAppName) {
                    this.appCoreFacadeService.dispatch(AppCoreActions.initialiseApp({ appName: newAppName }));
                }
            });

        return this.appCoreFacadeService.getAppInitialising().pipe(
            filter((init) => !init),
            take(1),
            map(() => true)
        );
    }
}
