export interface FontFile {
    id?: number;
    font_config?: number;
    font_type: FontFile.FontTypeEnum;
    font_file?: string;
}

export namespace FontFile {
    export enum FontTypeEnum {
        Ttf = 'ttf',
        Otf = 'otf',
        Woff = 'woff',
        Woff2 = 'woff2',
        Eot = 'eot',
        Svg = 'svg'
    }
}
