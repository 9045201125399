import { Injectable } from '@angular/core';
import { SoundEffects } from '@common/constants/sound-effects.enum';
import { SoundService } from '@common/services/sound/sound.service';
import { ModalController } from '@ionic/angular';
import { IncomingVideoCallModalComponent } from '../components/incoming-video-call-modal/incoming-video-call-modal.component';
import { IncomingVideoCallAction } from '../enums/incoming-video-call-action.enum';
import { VideoCall } from '../models/video-call.interface';
import { VideoCallsActions } from '../store/actions/video-calls.actions';
import { VideoCallFacadeService } from './video-call-facade.service';

@Injectable({
    providedIn: 'root'
})
export class VideoCallIncomingModalService {
    private modal: HTMLIonModalElement;

    constructor(
        private modalController: ModalController,
        private soundService: SoundService,
        private videoCallFacadeService: VideoCallFacadeService
    ) {}

    public async showIncomingVideoCallDialog(incomingCall: VideoCall): Promise<void> {
        this.modal = await this.modalController.create({
            component: IncomingVideoCallModalComponent,
            componentProps: {
                incomingCall
            },
            backdropDismiss: false,
            cssClass: 'incoming-video-call-modal'
        });

        const incomingCallSound = this.soundService.get(SoundEffects.incomingCall, 0.2);

        incomingCallSound.loop(true);
        incomingCallSound.play();

        await this.modal.present();

        const { data } = await this.modal.onDidDismiss();

        switch (data) {
            case IncomingVideoCallAction.Accept:
                this.videoCallFacadeService.dispatch(VideoCallsActions.acceptCall({ incomingCall }));
                break;
            case IncomingVideoCallAction.Decline:
                this.videoCallFacadeService.dispatch(VideoCallsActions.declineCall({ incomingCall }));
                break;
            case IncomingVideoCallAction.Block:
                this.videoCallFacadeService.dispatch(VideoCallsActions.blockCaller({ incomingCall }));
                break;
        }

        incomingCallSound.stop();
    }

    public dismissIncomingVideoCallDialog(): void {
        if (this.modal) {
            this.modal.dismiss();
        }
    }
}
