import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationFacadeService } from '@core/authentication/services/authentication-facade.service';
import { PollSubmission } from '@features/live-polling/models/poll-submission.model';
import { SurveyApiSettings } from '@features/surveys/constants/surveys-api-settings.constant';
import { PollGroup, PollResponse } from '@shared/api';
import { ApiSettings } from '@shared/settings/api-settings.constant';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { TotalVoteCount } from '../models/total-vote-count.model';

@Injectable({
    providedIn: 'root'
})
export class LivePollingSurveysApiService {
    private readonly baseUrl = CONFIG.API_URL;

    constructor(
        private httpClient: HttpClient,
        private authenticationFacadeService: AuthenticationFacadeService
    ) {}

    public getPollGroups(appUrl: string): Observable<PollGroup[]> {
        const url = ApiSettings.fullUrl(appUrl, SurveyApiSettings.pollGroups);
        return this.httpClient.get<PollGroup[]>(url);
    }

    public getPollGroup(appUrl: string, id: number): Observable<PollGroup> {
        const url = ApiSettings.fullUrl(appUrl, SurveyApiSettings.pollGroup(id));
        return this.httpClient.get<PollGroup>(url);
    }

    public submitPoll(appName: string, submission: PollSubmission): Observable<any> {
        const url = this.prependBaseUrl(appName, SurveyApiSettings.pollSubmit);
        return this.authenticationFacadeService.getAnonymousTokenIfUnauthenticated().pipe(
            take(1),
            switchMap((anonymousToken: string) => {
                const body = {
                    anonymous_id: anonymousToken,
                    response: submission.submissionValue,
                    poll: submission.pollId,
                    object_id: submission.objectId,
                    content_type: submission.contentType
                };
                return this.httpClient.post(url, body);
            })
        );
    }

    public submitProxyVotes(
        appName: string,
        pollId: string | number,
        responses: Array<number | number[] | string>
    ): Observable<null> {
        const url = this.prependBaseUrl(appName, SurveyApiSettings.proxyPollSubmit);

        return this.httpClient.put<null>(url, {
            poll: pollId,
            responses
        });
    }

    public emailSurveys(appName: string, user: string): Observable<any> {
        const url = this.prependBaseUrl(appName, ApiSettings.emailSurveys(user));
        return this.httpClient.get(url, { responseType: 'blob' });
    }

    public getTotalVoteCount(appName: string, pollId: string | number): Observable<TotalVoteCount> {
        const url = this.prependBaseUrl(appName, SurveyApiSettings.totalVoteCount(pollId));

        return this.httpClient.get<TotalVoteCount>(url);
    }

    public getProxyResponses(appName: string, pollId: string | number): Observable<PollResponse[]> {
        const url = this.prependBaseUrl(appName, SurveyApiSettings.proxyVoteResponses(pollId));

        return this.httpClient.get<PollResponse[]>(url);
    }

    private prependBaseUrl = (appName: string, endpointUrl: string): string => {
        return `${this.baseUrl}apps/${appName}${endpointUrl}`;
    };
}
