import { Injectable } from '@angular/core';
import { SurveyModule, PollGroup, PollResponse } from '@shared/api';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { AuthenticationFacadeService } from '../../../core/authentication/services/authentication-facade.service';
import { SurveyApiSettings } from '../constants/surveys-api-settings.constant';
import { HttpClient } from '@angular/common/http';
import { ApiSettings } from '@shared/settings/api-settings.constant';

@Injectable({
    providedIn: 'root'
})
export class SurveysApiService {
    constructor(
        private http: HttpClient,
        private authenticationFacadeService: AuthenticationFacadeService
    ) {}

    public getSurveyModule(appName: string, surveyModuleId: number): Observable<SurveyModule> {
        const url = ApiSettings.fullUrl(appName, SurveyApiSettings.surveyModule(surveyModuleId));
        return this.http.get<SurveyModule>(url);
    }

    public getPollGroups(appName: string): Observable<PollGroup[]> {
        const url = ApiSettings.fullUrl(appName, SurveyApiSettings.pollGroups);
        return this.http.get<PollGroup[]>(url);
    }

    public getPollGroupsForModule(appName: string, moduleId: number): Observable<PollGroup[]> {
        const url = ApiSettings.fullUrl(appName, SurveyApiSettings.pollGroupsForModule(moduleId));
        return this.http.get<PollGroup[]>(url);
    }

    public getPollGroupDetail(appName: string, pollGroupId: number): Observable<PollGroup> {
        const url = ApiSettings.fullUrl(appName, SurveyApiSettings.pollGroupDetail(pollGroupId));
        return this.http.get<PollGroup>(url);
    }

    public getPollResponses(appName: string): Observable<PollResponse[]> {
        const pollResponsesUrl$ = this.getPollResponsesUrl();
        return pollResponsesUrl$.pipe(
            switchMap((url) => {
                const fullUrl = ApiSettings.fullUrl(appName, url);
                return this.http.get<PollResponse[]>(fullUrl);
            })
        );
    }

    private getPollResponsesUrl(): Observable<string> {
        return this.authenticationFacadeService.getAnonymousTokenIfUnauthenticated().pipe(
            take(1),
            map((anonymousToken: string) => {
                return anonymousToken
                    ? SurveyApiSettings.pollResponsesAnonymous(anonymousToken)
                    : SurveyApiSettings.pollResponses;
            })
        );
    }
}
