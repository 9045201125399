<img
    *ngIf="theme?.banner_image"
    tabindex="1"
    alt="App Banner"
    class="banner-image cc-sidebar-left-banner"
    [src]="theme.banner_image"
    (click)="onAppBannerClick()"
/>

<cc-banner-ad class="cc-sidebar-left-ad"></cc-banner-ad>

<div class="module-container" role="list">
    <ion-item
        *ngFor="let module of modules"
        class="module-item"
        lines="none"
        color="sidebar"
        [ngClass]="[selectedModule === module.id ? 'selected' : '', 'cc-sidebar-left-list-item-' + module.id]"
        [routerLink]="module.routeCommands"
        (click)="onModuleClick()"
    >
        <cc-module-icon slot="start" [module]="module"></cc-module-icon>
        <ion-label class="ion-text-wrap cc-primary-header">
            {{ module.name }}
        </ion-label>
    </ion-item>
</div>

<div class="footer">
    <ion-item class="collapse-menu-button" lines="none" button (click)="onToggleCollapse()" color="sidebar">
        <i class="material-icons" slot="start" aria-hidden="true">menu_open</i>
        <ion-label class="cc-primary-header" aria-expanded="true">
            {{ 'SIDEBAR_LEFT_COLLAPSE_HINT' | translate }}
        </ion-label>
    </ion-item>

    <ion-item *ngIf="portalConfig" button lines="none" (click)="onPortalReturn()" color="sidebar">
        <i class="material-icons" slot="start" aria-hidden="true">apps</i>
        <ion-label> {{ 'SIDEBAR_LEFT_BACK_TO_PORTAL' | translate }} {{ portalConfig.portal_name }} </ion-label>
    </ion-item>

    <ion-item button lines="none" (click)="onTosDialogClick()" color="sidebar">
        <i class="material-icons" slot="start" aria-hidden="true">policy</i>
        <ion-label>
            {{ 'SIDEBAR_LEFT_TERMS_AND_CONDITIONS' | translate }}
        </ion-label>
    </ion-item>

    <cc-experimental-switch></cc-experimental-switch>

    <a href="https://www.crowdcomms.com" target="_blank" rel="noopener">
        <ion-item *ngIf="showPoweredByLogo" lines="none" color="sidebar">
            <cc-powered-by-logo [shade]="poweredByLogoShade"></cc-powered-by-logo>
        </ion-item>
    </a>
</div>
