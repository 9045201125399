import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    Output,
    SimpleChanges
} from '@angular/core';
import { PeopleMinimal, PeopleVisibility } from '@shared/api';
import { fadeIn } from '@common/animations/animations';
import { AvatarSize } from '@shared/models/avatar-size.type';

@Component({
    selector: 'cc-person-icon',
    templateUrl: './person-icon.component.html',
    styleUrls: ['./person-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [fadeIn()]
})
export class PersonIconComponent implements OnChanges {
    @Input()
    public person: PeopleMinimal;

    @Input()
    public peopleVisibility?: PeopleVisibility;

    @Input()
    public srcSize = 200;

    @Input()
    public size: AvatarSize = 'medium';

    @Input()
    public icon: string;

    @Input()
    public iconAriaLabel: string;

    @Output()
    public profilePictureClicked?: EventEmitter<string> = new EventEmitter<string>();

    @Output()
    public iconButtonClicked?: EventEmitter<void> = new EventEmitter<void>();

    @HostBinding('class') get classes(): string {
        return `size-${this.size}`;
    }

    public shouldDisplayProfilePhoto: boolean;
    public shouldDisplayFirstName: boolean;
    public shouldDisplayLastName: boolean;

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.person || changes.peopleVisibility) {
            const pictureVisible = !this.peopleVisibility || (this.peopleVisibility && this.peopleVisibility.picture);
            this.shouldDisplayProfilePhoto = pictureVisible && !!this.person?.app_picture;

            const firstNameVisible =
                !this.peopleVisibility || (this.peopleVisibility && this.peopleVisibility.first_name);
            this.shouldDisplayFirstName = firstNameVisible && !!this.person?.first_name;

            const lastNameVisible =
                !this.peopleVisibility || (this.peopleVisibility && this.peopleVisibility.last_name);
            this.shouldDisplayLastName = lastNameVisible && !!this.person?.last_name;
        }
    }

    public onProfilePictureClicked(profilePictureUrl: string): void {
        this.profilePictureClicked.emit(profilePictureUrl);
    }

    public onIconButtonClicked(): void {
        this.iconButtonClicked.emit();
    }
}
