import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { TermsAndConditions } from '../../models/terms-and-conditions.model';

@Injectable({
    providedIn: 'root'
})
export class TosApiService {
    constructor(private httpClient: HttpClient) {}

    get(appUrl: string): Observable<TermsAndConditions> {
        const url = `${CONFIG.API_URL}apps/${appUrl}/terms/`;
        return this.httpClient.get<TermsAndConditions>(url);
    }

    hasAccepted(appUrl: string): Observable<boolean> {
        const url = `${CONFIG.API_URL}apps/${appUrl}/terms/has-accepted/`;
        return this.httpClient.get<{ has_agreed: boolean }>(url).pipe(map((response) => response.has_agreed));
    }

    agree(appUrl: string): Observable<any> {
        const url = `${CONFIG.API_URL}apps/${appUrl}/terms/agree/`;

        return this.httpClient.post(url, {});
    }
}
