import { AgendaModuleView } from '@features/agenda/agenda-module/enums/agenda-module-view.enum';

export interface AgendaModule {
    module: number;
    default_session_image?: string;
    schedule_tip?: boolean;
    filter_position?: AgendaModule.FilterPositionEnum;
    hide_location_filter?: boolean;
    show_filters_by_default?: boolean;
    show_download?: boolean;
    is_managed_schedule?: boolean;
    show_attendees?: boolean;
    expand_show_documents?: boolean;
    expand_show_features?: boolean;
    expand_show_location?: boolean;
    expand_show_speakers?: boolean;
    expand_show_subtitle?: boolean;
    google_calendar_id?: string;
    create_google_calendar?: boolean;
    group_sessions_by?: string;
    filters_limit_results: boolean;
    utc_start_times: string[];
    filter_options: {
        locations: AgendaModule.InlineLocation[];
        tracks: AgendaModule.InlineTrack[];
    };
    calendar_view_enabled: boolean;
    default_view: AgendaModuleView;
    show_whats_next_prompt: boolean;
    chat_moderator_groups: Array<string>;
    waiting_list_enabled: boolean;
}

export interface AgendaModuleEnabledFeatures {
    show_attendees?: boolean;
    expand_show_documents?: boolean;
    expand_show_features?: boolean;
    expand_show_location?: boolean;
    expand_show_speakers?: boolean;
    expand_show_subtitle?: boolean;
}

export namespace AgendaModule {
    export enum FilterPositionEnum {
        Top = 'top',
        Bottom = 'bottom'
    }

    export interface InlineLocation {
        id: number;
        name: string;
    }

    export interface InlineTrack {
        id: number;
        name: string;
        parent_track: number;
        order: number;
    }
}
