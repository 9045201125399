import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { SupportedFormat } from '@capacitor-community/barcode-scanner';
import { CapacitorService } from '@core/native/services/capacitor.service';

@Injectable({
    providedIn: 'root'
})
export class QrScannerNativeService {
    constructor(
        private capacitorService: CapacitorService,
        @Inject(DOCUMENT) private docuemnt: Document
    ) {}

    public prepareScanner(): Promise<void> {
        return this.capacitorService.barcodeScanner.prepare();
    }

    public async getScanResult(): Promise<string> {
        await this.capacitorService.barcodeScanner.checkPermission({ force: true });

        await this.hideBackground();

        const result = await this.capacitorService.barcodeScanner.startScan({
            targetedFormats: [SupportedFormat.QR_CODE]
        });

        await this.showBackground();

        return result.content;
    }

    public async cleanupScanner(): Promise<void> {
        await this.capacitorService.barcodeScanner.stopScan();
        await this.showBackground();
    }

    /**
     * Selects the elements from the dom to be used in the hide and show logic.
     * Unfortuantly this is the only work around to show the native scanner as it presents itself behind the webview content.
     * The suggested fix on their github is to set body opacity to 0 however we still want the modal toolbar to allow the user to exit.
     */
    private selectElements(): { appPageElement: HTMLElement; appModalElement: HTMLElement } {
        return {
            appPageElement: this.docuemnt.querySelector('cc-app-page') as HTMLElement,
            appModalElement: this.docuemnt.querySelector('ion-modal') as HTMLElement
        };
    }

    private async hideBackground(): Promise<void> {
        await this.capacitorService.barcodeScanner.hideBackground();
        const { appPageElement, appModalElement } = this.selectElements();

        appPageElement.style.display = 'none';
        appModalElement.style.setProperty('--background', 'none');
    }

    private async showBackground(): Promise<void> {
        await this.capacitorService.barcodeScanner.showBackground();
        const { appPageElement, appModalElement } = this.selectElements();

        appPageElement.style.display = 'unset';
        appModalElement.style.setProperty('--background', 'var(--ion-background-color, #fff)');
    }
}
