import { PeopleListState, PeopleState } from '../models/people.state.model';
import { Action, createReducer, on } from '@ngrx/store';
import { PeopleApiActions } from '../actions/people-api.actions';
import { PeopleDetailActions } from '../actions/people-detail.actions';
import { PeopleListActions } from '../actions/people-list.actions';
import { PeopleFilterActions } from '../actions/people-filter.actions';
import { TagFilterMode } from '@shared/tags/enums/tag-filter-mode.enum';
import { PeoplePageSize } from '@features/people/constants/people-pagination.constant';

export const initialState: PeopleState = {
    peopleModule: {},
    detailState: undefined,
    listState: undefined,
    peopleVisibility: undefined,
    filterState: undefined
};

const peopleReducerFn = createReducer(
    initialState,
    on(PeopleListActions.moduleChanged, (state) => ({
        ...state,
        filterState: initialState.filterState
    })),
    on(PeopleApiActions.fetchVisibilitySuccess, (state, { peopleVisibility }) => ({
        ...state,
        peopleVisibility
    })),
    on(PeopleDetailActions.enterPage, (state) => ({
        ...state,
        detailState: {
            initialising: true,
            customFields: undefined,
            person: undefined,
            sessions: undefined,
            businessCard: undefined
        }
    })),
    on(PeopleApiActions.fetchDetailSuccess, (state, { person, sessions, customFields }) => {
        return {
            ...state,
            detailState: {
                ...state.detailState,
                initialising: false,
                person,
                customFields,
                sessions
            }
        };
    }),
    on(PeopleApiActions.fetchDetailBusinessCardSuccess, (state, { businessCard }) => ({
        ...state,
        detailState: {
            ...state.detailState,
            businessCard
        }
    })),
    on(PeopleDetailActions.leavePage, (state) => ({
        ...state,
        detailState: undefined
    })),
    on(PeopleApiActions.fetchPeopleModuleSuccess, (state, { module }) => ({
        ...state,
        detailState: undefined,
        listState: {
            ...state.listState,
            module
        }
    })),

    // List ons
    on(PeopleListActions.enterPage, (state, { moduleId }) => ({
        ...state,
        listState: {
            ...initialState.listState,
            moduleId,
            initialising: true,
            refreshing: false
        },
        filterState: {
            ...state.filterState,
            filterMode: TagFilterMode.Additive
        }
    })),
    on(PeopleListActions.enterPage, PeopleListActions.submitSearch, (state) => ({
        ...state,
        listState: {
            ...state.listState,
            pagination: {
                ...state.listState.pagination,
                page: 0,
                limit: PeoplePageSize,
                visiblePages: [0]
            },
            pages: {},
            people: [],
            scrollBottomDisabled: false,
            scrollTopDisabled: true
        }
    })),
    on(PeopleListActions.leavePage, (state) => ({
        ...state,
        listState: undefined
    })),
    on(PeopleApiActions.fetchListSuccess, (state, { paginatedResponse, module }) => ({
        ...state,
        listState: {
            ...state.listState,
            module,
            initialising: false,
            refreshing: false,
            people: paginatedResponse.results,
            pages: {
                0: paginatedResponse.results.map((person) => person.id)
            },
            scrollBottomDisabled: !paginatedResponse.next
        }
    })),
    on(PeopleListActions.scrollTo, (state, { position }) => {
        const currentlyVisiblePages = state.listState.pagination.visiblePages;
        let newPageNumber, visiblePages, cleanupState;
        switch (position) {
            case 'top':
                newPageNumber = currentlyVisiblePages[0] - 1;
                visiblePages = [newPageNumber, ...currentlyVisiblePages];
                if (visiblePages.length > 3) {
                    cleanupState = cleanupPeoplePage(visiblePages.pop(), state.listState);
                }
                break;
            case 'bottom':
                newPageNumber = currentlyVisiblePages[currentlyVisiblePages.length - 1] + 1;
                visiblePages = [...currentlyVisiblePages, newPageNumber];
                if (visiblePages.length > 3) {
                    cleanupState = cleanupPeoplePage(visiblePages.shift(), state.listState);
                }
                break;
        }
        return {
            ...state,
            listState: {
                ...state.listState,
                pagination: {
                    ...state.listState.pagination,
                    page: newPageNumber,
                    visiblePages
                },
                ...cleanupState
            }
        };
    }),
    on(PeopleApiActions.fetchPaginationSuccess, (state, { page, paginatedResponse }) => ({
        ...state,
        listState: {
            ...state.listState,
            people: [...state.listState.people, ...paginatedResponse.results],
            pages: {
                ...state.listState.pages,
                [page]: paginatedResponse.results.map((person) => person.id)
            },
            scrollBottomDisabled: !paginatedResponse.next
        }
    })),
    on(PeopleListActions.submitSearch, (state, { searchTerm }) => ({
        ...state,
        listState: {
            ...state.listState,
            initialising: true,
            pagination: {
                ...state.listState.pagination,
                search: searchTerm
            }
        }
    })),

    // People Filters
    on(PeopleFilterActions.applyFilters, (state, { filters }) => ({
        ...state,
        filterState: {
            ...state.filterState,
            appliedFilters: filters.selectedTags,
            filterMode: filters.filterMode
        }
    })),
    on(PeopleFilterActions.fetchFiltersSuccess, (state, { filters }) => ({
        ...state,
        filterState: {
            ...state.filterState,
            filters
        }
    })),
    on(PeopleFilterActions.removeFilter, (state, { filterId }) => ({
        ...state,
        filterState: {
            ...state.filterState,
            appliedFilters: state.filterState.appliedFilters?.filter((filter) => filter.id !== filterId)
        }
    }))
);

export function peopleReducer(state: PeopleState | undefined, action: Action): PeopleState {
    return peopleReducerFn(state, action);
}

export const cleanupPeoplePage = (page: number, state: PeopleListState): any => {
    const peopleIds = state.pages[page];
    const pages = {
        ...state.pages
    };
    delete pages[page];
    return {
        pages,
        people: [...state.people].filter((person) => !peopleIds.includes(person.id))
    };
};
