import { Injectable } from '@angular/core';
import QrScanner from 'qr-scanner';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class QrScannerFactoryService {
    public createQrScanner(videoElement: HTMLVideoElement, subject: Subject<string>): QrScanner {
        return new QrScanner(videoElement, (result) => {
            subject.next(result);
            subject.complete();
        });
    }
}
