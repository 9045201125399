import { Directive, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ChatEmojiService } from '@shared/emoji/services/chat-emoji/chat-emoji.service';
import { asyncScheduler, distinctUntilChanged, filter, map, throttleTime } from 'rxjs';

@UntilDestroy()
@Directive({
    selector: '[ccTextEmojis]'
})
export class TextEmojisDirective implements OnInit {
    constructor(
        private control: NgControl,
        private chatEmojiService: ChatEmojiService
    ) {}

    public ngOnInit(): void {
        this.control.control.valueChanges
            .pipe(
                untilDestroyed(this),
                filter((value) => !!value),
                distinctUntilChanged(),
                throttleTime(100, asyncScheduler, { leading: true }),
                map((chatInputValue) => this.chatEmojiService.replaceTextWithEmojis(chatInputValue))
            )
            .subscribe((chatInputValue: string) => this.control.control.setValue(chatInputValue, { emitEvent: false }));
    }
}
