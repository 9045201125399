import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'cc-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent {
    @Input()
    public search: UntypedFormControl;
}
