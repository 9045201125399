import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppDocument, DocumentModule } from '@shared/api';
import { HttpClient } from '@angular/common/http';
import { ApiSettings } from '@shared/settings/api-settings.constant';

@Injectable({
    providedIn: 'root'
})
export class DocumentsApiService {
    constructor(private http: HttpClient) {}

    public getDocuments(appName: string): Observable<AppDocument[]> {
        const url = ApiSettings.fullUrl(appName, Endpoints.documents);
        return this.http.get<AppDocument[]>(url);
    }

    public getModuleDocuments(appName: string, moduleId: number): Observable<AppDocument[]> {
        const url = ApiSettings.fullUrl(appName, Endpoints.moduleDocuments(moduleId));
        return this.http.get<AppDocument[]>(url);
    }

    public getDocumentModule(appName: string, moduleId: number): Observable<DocumentModule> {
        const url = ApiSettings.fullUrl(appName, Endpoints.module(moduleId));
        return this.http.get<DocumentModule>(url);
    }

    public getDocument(appName: string, documentId: number): Observable<AppDocument> {
        const url = ApiSettings.fullUrl(appName, Endpoints.document(documentId));
        return this.http.get<AppDocument>(url);
    }
}

export const Endpoints = {
    documents: '/documents/?fields=id,name,mimetype,icon_toggle,thumbnail',
    moduleDocuments: (moduleId: number) =>
        `/modules/${moduleId}/document/documents/?fields=id,name,mimetype,icon_toggle,thumbnail,link`,
    module: (moduleId: number) => `/modules/${moduleId}/document/`,
    document: (documentId: number) => `/documents/${documentId}/`
};
