import { Component, Input, OnInit } from '@angular/core';
import { NavigationHistoryService } from '@core/navigation-history/services/navigation-history.service';
import { SidebarType } from '@core/navigation/enums/sidebar-type.enum';
import { NavigationFacadeService } from '@core/navigation/services/navigation-facade.service';
import { RightSidebarItem } from '@core/navigation/services/sidebar-right-items.service';
import { SidebarService } from '@core/navigation/services/sidebar.service';
import { NavigationActions } from '@core/navigation/store/actions/navigation.actions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppLanguage, People } from '@shared/api';
import { combineLatest, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthenticationFacadeService } from '../../../../core/authentication/services/authentication-facade.service';
import { I18nService } from '../../../../core/i18n/services/i18n.service';

@UntilDestroy()
@Component({
    selector: 'cc-sidebar-right',
    templateUrl: './sidebar-right.component.html',
    styleUrls: ['./sidebar-right.component.scss']
})
export class SidebarRightComponent implements OnInit {
    @Input()
    appUrl: string;

    sidebarItems$: Observable<RightSidebarItem[]>;
    loggedInUser$: Observable<People>;
    language$: Observable<string>;

    languages: AppLanguage[];

    constructor(
        private sidebarService: SidebarService,
        private i18nService: I18nService,
        private navigationFacadeService: NavigationFacadeService,
        private navigationHistoryService: NavigationHistoryService,
        private authenticationFacadeService: AuthenticationFacadeService
    ) {}

    ngOnInit(): void {
        this.language$ = this.i18nService.language$;
        this.languages = this.i18nService.languages;

        this.loggedInUser$ = this.authenticationFacadeService.getAuthenticatedPerson();
        this.sidebarItems$ = this.navigationFacadeService.getRightSidebarItems();

        combineLatest([
            this.authenticationFacadeService.isLoginRequired(),
            this.authenticationFacadeService.isPasscodeRequired()
        ])
            .pipe(
                untilDestroyed(this),
                map(([loginRequired, passcodeRequired]) => {
                    return !loginRequired && !passcodeRequired;
                }),
                tap((hasAccess) => this.sidebarService.enable(SidebarType.Right, hasAccess))
            )
            .subscribe();
    }

    public loadLanguage(languageCode: string): void {
        this.navigationFacadeService.dispatch(NavigationActions.loadLanguage({ languageCode }));
    }

    public onItemClick(): void {
        this.navigationHistoryService.forget();
        this.closeSidebar();
    }

    public closeSidebar(): void {
        this.sidebarService.close(SidebarType.Right);
    }
}
