export const AuthEndpointUrls = {
    checkEmail: `/check-email/`,
    downloadTokenFullUrl: () => `${CONFIG.API_URL}users/get-download-token/`,
    forgotPasswordFullUrl: () => `${CONFIG.API_URL}users/forgot-password/`,
    login: `/auth/login/`,
    logout: `/auth/logout/`,
    refreshToken: '/refresh-token/',
    register: `/auth/register/`,
    sendVerificationEmail: `/send-email-verification/`,
    updatePasswordFullUrl: () => `${CONFIG.API_URL}users/update-password/`,
    verifyPasscode: '/verify-passcode/',
    verifyEmailFullUrl: (token) => `${CONFIG.API_URL}users/verify-email/${token}/`,
    getAppIdentityProviders: (appName) => `${CONFIG.API_URL}apps/${appName}/identity-providers/`
};
