import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserBusinessCardsEndpoints } from '@features/user-business-cards/constants/user-business-cards-endpoints.constant';
import { UserBusinessCardsOrder } from '@features/user-business-cards/enums/user-business-cards-order.enum';
import { BusinessCardRequest } from '@features/user-business-cards/interfaces/business-card-request.interface';
import { BusinessCardSettings } from '@features/user-business-cards/interfaces/business-card-settings.interface';
import { BusinessCardEndpoints } from '@shared/business-cards/constants/business-cards-endpoints.constant';
import { BusinessCardDesign } from '@shared/business-cards/interfaces/business-card-design.interface';
import { PaginatedResponse } from '@shared/pagination/models/paginated-response.model';
import { ApiSettings } from '@shared/settings/api-settings.constant';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserBusinessCardsApiService {
    constructor(private httpClient: HttpClient) {}

    public saveMyCard(appName: string, payload: Partial<BusinessCardDesign>): Observable<BusinessCardDesign> {
        const url = ApiSettings.fullUrl(appName, BusinessCardEndpoints.myCard());
        return this.httpClient.patch<BusinessCardDesign>(url, payload);
    }

    public getRequests(
        appName: string,
        options: { offset: number; limit: number }
    ): Observable<PaginatedResponse<BusinessCardRequest>> {
        const url = ApiSettings.fullUrl(appName, UserBusinessCardsEndpoints.requests());

        return this.httpClient.get<PaginatedResponse<BusinessCardRequest>>(url, {
            params: {
                offset: options.offset,
                limit: options.limit,
                state: 'submitted'
            }
        });
    }

    public getContacts(
        appName: string,
        options: { offset: number; limit: number; search?: string; ordering: UserBusinessCardsOrder }
    ): Observable<PaginatedResponse<BusinessCardRequest>> {
        const url = ApiSettings.fullUrl(appName, UserBusinessCardsEndpoints.requests());

        return this.httpClient.get<PaginatedResponse<BusinessCardRequest>>(url, {
            params: {
                offset: options.offset,
                limit: options.limit,
                search: options.search ?? '',
                state_in: ['accepted', 'returned'].join(),
                ordering: options.ordering
            }
        });
    }

    public acceptRequest(appName: string, id: string): Observable<BusinessCardRequest> {
        const url = ApiSettings.fullUrl(appName, UserBusinessCardsEndpoints.acceptRequest(id));
        return this.httpClient.post<BusinessCardRequest>(url, {});
    }

    public declineRequest(appName: string, id: string): Observable<BusinessCardRequest> {
        const url = ApiSettings.fullUrl(appName, UserBusinessCardsEndpoints.declineRequest(id));
        return this.httpClient.post<BusinessCardRequest>(url, {});
    }

    public processScan(appName: string, result: string): Observable<BusinessCardRequest> {
        const url = ApiSettings.fullUrl(appName, UserBusinessCardsEndpoints.scan());
        return this.httpClient.get<BusinessCardRequest>(url, { params: { code: result } });
    }

    public deleteContact(appName: string, id: string): Observable<void> {
        const url = ApiSettings.fullUrl(appName, UserBusinessCardsEndpoints.request(id));
        return this.httpClient.delete<void>(url);
    }

    public getAppDesign(appName: string): Observable<BusinessCardDesign> {
        const url = ApiSettings.fullUrl(appName, BusinessCardEndpoints.design());
        return this.httpClient.get<BusinessCardDesign>(url);
    }

    public getAppSettings(appName: string): Observable<BusinessCardSettings> {
        const url = ApiSettings.fullUrl(appName, BusinessCardEndpoints.settings());
        return this.httpClient.get<BusinessCardSettings>(url);
    }

    public downloadAllContacts(appName: string): Observable<Blob> {
        const url = ApiSettings.fullUrl(appName, UserBusinessCardsEndpoints.downloadAll());
        return this.httpClient.get<Blob>(url, { responseType: 'blob' as 'json' });
    }

    public downloadContact(appName: string, id: string): Observable<Blob> {
        const url = ApiSettings.fullUrl(appName, UserBusinessCardsEndpoints.download(id));
        return this.httpClient.get<Blob>(url, { responseType: 'blob' as 'json' });
    }

    public updateContact(
        appName: string,
        id: string,
        update: Partial<BusinessCardRequest>
    ): Observable<BusinessCardRequest> {
        const url = ApiSettings.fullUrl(appName, UserBusinessCardsEndpoints.request(id));
        return this.httpClient.patch<BusinessCardRequest>(url, update);
    }
}
