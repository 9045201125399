import { Injectable } from '@angular/core';
import { Store, Action } from '@ngrx/store';
import { AppState } from '../../root-store/store/app-state.model';
import { Observable } from 'rxjs';
import { AppRoutingSelectors } from '../../root-store/store/app-routing/selectors/app-routing.selectors';
import { Data, Params } from '@angular/router';
import { AppRouteParams } from '../constants/app-route-params.constant';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AppRoutingFacadeService {
    constructor(private store: Store<AppState>) {}

    getCurrentRoute(): Observable<any> {
        return this.store.select(AppRoutingSelectors.selectCurrentRoute);
    }

    getCurrentModuleId(): Observable<number | undefined> {
        return this.getRouteParam(AppRouteParams.moduleId).pipe(
            map((moduleId) => (moduleId ? parseInt(moduleId, 10) : undefined))
        );
    }

    getFragment(): Observable<string> {
        return this.store.select(AppRoutingSelectors.selectFragment);
    }

    getQueryParams(): Observable<Params> {
        return this.store.select(AppRoutingSelectors.selectQueryParams);
    }

    getQueryParam(param: string): Observable<string> {
        return this.store.select(AppRoutingSelectors.selectQueryParam(param));
    }

    getRouteParams(): Observable<Params> {
        return this.store.select(AppRoutingSelectors.selectRouteParams);
    }

    getRouteParam(param: string): Observable<string> {
        return this.store.select(AppRoutingSelectors.selectRouteParam(param));
    }

    getRouteData(): Observable<Data> {
        return this.store.select(AppRoutingSelectors.selectRouteData);
    }

    getUrl(): Observable<string> {
        return this.store.select(AppRoutingSelectors.selectUrl);
    }

    dispatch(action: Action): void {
        return this.store.dispatch(action);
    }
}
