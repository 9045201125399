import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpStatusResponseMessages } from '@core/notifications/constants/http-status-response-messages.const';
import { NotificationTheme } from '@core/notifications/enums/notification-theme.enum';
import { AppNotification } from '@core/notifications/interfaces/notification.interface';
import { NotificationsService } from '@core/notifications/services/notifications/notifications.service';

@Injectable({
    providedIn: 'root'
})
export class ToastService {
    constructor(private notificationsService: NotificationsService) {}

    public success(message?: string, options?: Partial<AppNotification>): void {
        this.notificationsService.createNotification({
            title: options?.title ?? 'TOAST_SUCCESS_TITLE',
            theme: NotificationTheme.Success,
            icon: 'check',
            duration: options?.duration,
            message: message ?? 'TOAST_SUCCESS_MESSAGE_DEFAULT',
            translateParams: options?.translateParams,
            silent: true
        });
    }

    public notify(message: string, options?: Partial<AppNotification>): void {
        this.notificationsService.createNotification({
            title: options?.title ?? 'TOAST_INFO_TITLE',
            theme: NotificationTheme.Info,
            icon: options?.icon ?? 'info',
            duration: options?.duration,
            buttons: options?.buttons,
            message,
            translateParams: options?.translateParams,
            silent: true
        });
    }

    public error(message?: string, options?: Partial<AppNotification>): void {
        this.notificationsService.createNotification({
            title: options?.title ?? 'TOAST_WARN_TITLE',
            theme: NotificationTheme.Warning,
            icon: options?.icon ?? 'warning',
            duration: options?.duration,
            message: message ?? 'TOAST_WARN_MESSAGE_DEFAULT',
            translateParams: options?.translateParams,
            silent: true
        });
    }

    public errorFromHttp(err: HttpErrorResponse, options?: Partial<AppNotification>): void {
        this.notificationsService.createNotification({
            title: HttpStatusResponseMessages[err.status],
            theme: NotificationTheme.Warning,
            icon: options?.icon ?? 'error',
            duration: options?.duration,
            message: err.error?.non_field_errors?.join(',') ?? 'ERROR_SOMETHING_WENT_WRONG',
            translateParams: options?.translateParams,
            silent: true
        });
    }
}
