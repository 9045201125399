export const GamificationApiSettings = {
    challengeModule: (moduleId) => `/modules/${moduleId}/challenge/`,
    challengeAttempts: `/challenge-attempts/`,
    leaderboard: (moduleId, offset, limit) =>
        `/modules/${moduleId}/challenge/leaderboard/table/?offset=${offset}&limit=${limit}`,
    challenge: (challengeId) => `/challenges/${challengeId}/`,
    challengeProgress: (challengeId) => GamificationApiSettings.challenge(challengeId) + 'progress/',
    progress: (moduleId) => GamificationApiSettings.challengeModule(moduleId) + 'my-progress/',
    paginatedChallenges: (moduleId) => GamificationApiSettings.challengeModule(moduleId) + 'challenges/',
    challengeCompletions: (moduleId) => GamificationApiSettings.challengeModule(moduleId) + 'challenge-completions/',
    submitSingleChallenge: (challengeId) => `/challenges/${challengeId}/code/`,
    submitAllChallenges: (moduleId) => `/modules/${moduleId}/challenge/try-code/`,
    submitScanChallenge: (moduleId: string) => `/modules/${moduleId}/challenge/scan/`,
    leaderboardPage: (moduleId) => `/modules/${moduleId}/challenge/leaderboard/cursor/`
};
