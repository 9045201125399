import { Injectable } from '@angular/core';
import { AblyMessagingService } from '@core/ably/services/ably-messaging.service';
import { AuthenticationFacadeService } from '@core/authentication/services/authentication-facade.service';
import { ChallengeCompletion } from '@shared/api';
import { Observable, filter, map, withLatestFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ChallengeCompletionRealtimeService {
    constructor(
        private ablyMessagingService: AblyMessagingService,
        private authenticationFacadeService: AuthenticationFacadeService
    ) {}

    public getRealtimeStream(): Observable<ChallengeCompletion> {
        return this.ablyMessagingService.getMessageStream(['challengecompletion']).pipe(
            map((message) => JSON.parse(message.data) as ChallengeCompletion),
            withLatestFrom(this.authenticationFacadeService.getAuthenticatedPerson()),
            filter(([_, loggedInUser]) => !!loggedInUser),
            filter(([completion, loggedInUser]) => completion.challenge && completion.person === loggedInUser.id),
            map(([completion]) => completion)
        );
    }
}
