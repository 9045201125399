import { Injectable } from '@angular/core';
import { CapacitorService } from '@core/native/services/capacitor.service';
import { v4 as uuid } from 'uuid';
import { StorageService } from '../../storage/services/storage.service';

@Injectable({
    providedIn: 'root'
})
export class DeviceService {
    public readonly deviceIdKey = 'device-id';

    constructor(
        private capacitorService: CapacitorService,
        private storageService: StorageService
    ) {}

    /*
     * Returns true if the app is being run on a mobile device
     */
    isMobile(): boolean {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    /*
     * Returns true if the app is being run in a native shell
     */
    isNative(): boolean {
        return this.capacitorService.isNative;
    }

    /*
     * Returns true of app is on an iOS device
     */
    isIos(): boolean {
        return this.capacitorService.platform === 'ios';
    }

    isWebIos(): boolean {
        return /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
    }

    /**
     * Returns true if the browser is Safari.
     */
    isSafari(): boolean {
        const appVersion = navigator.appVersion;
        return appVersion.includes('Safari') && !appVersion.includes('Chrome');
    }

    isIosChrome(): boolean {
        return this.isWebIos() && /CriOS/.test(navigator.userAgent);
    }

    isIosSafari(): boolean {
        return this.isWebIos() && this.isSafari() && !this.isIosChrome();
    }

    /*
     * Returns the appUrl when using a web app
     */
    getWebAppUrl(): string {
        return window.location.pathname.split('/')[1];
    }

    /*
     * Returns the appUrl when using a native app (com.supplier.appUrl)
     */
    async getNativeAppUrl(): Promise<string> {
        if (!this.isNative()) {
            return '';
        }

        const deviceInfo = await this.capacitorService.app.getInfo();
        return (deviceInfo.id.split('.')[2] || '').toLowerCase();
    }

    async getUUID(): Promise<string> {
        if (!this.isNative()) {
            return '';
        }

        const deviceInfo = await this.capacitorService.device.getId();
        return deviceInfo.identifier;
    }

    /*
     * Returns the app name
     */
    async getAppName(): Promise<string> {
        if (this.isNative()) {
            return await this.getNativeAppUrl();
        }
        return this.getWebAppUrl();
    }

    /*
     * Returns the provider when using a native app but defaults to crowdcomms (com.supplier.appUrl)
     */
    async getProvider(): Promise<string> {
        const defaultProvider = 'crowdcomms';

        if (!this.isNative()) {
            return defaultProvider;
        }

        const appInfo = await this.capacitorService.app.getInfo();
        return appInfo.id.split('.')[1] || 'crowdcomms';
    }

    /*
     * Generate and store a unique id for the device. This will be used by services
     * such as Ably for the client online presence
     */
    setDeviceId(): void {
        // Check if an id is already stored and do nothing if there is one
        const deviceId = this.storageService.getFromStorage(this.deviceIdKey);

        if (deviceId) {
            return;
        }

        // If no id then generate one
        const id = uuid();

        // store the id in localstorage
        this.storageService.setStorageItem(this.deviceIdKey, id);
    }
}
