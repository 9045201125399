import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'cc-lightbox-image-dialog',
    templateUrl: './lightbox-image-dialog.component.html',
    styleUrls: ['./lightbox-image-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LightboxImageDialogComponent {
    @Input()
    public src: string;

    constructor(private modalController: ModalController) {}

    public closeDialog(): void {
        this.modalController.dismiss();
    }
}
