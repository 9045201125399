import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InfiniteScrollDirective } from './directives/infinite-scroll/infinite-scroll.directive';
import { InfiniteScrollContainerComponent } from './components/infinite-scroll-container/infinite-scroll-container.component';

@NgModule({
    imports: [CommonModule],
    declarations: [InfiniteScrollDirective, InfiniteScrollContainerComponent],
    exports: [InfiniteScrollDirective, InfiniteScrollContainerComponent]
})
export class PaginationModule {}
