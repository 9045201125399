import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SocialInfo } from '@shared/api';
import { BusinessCardDesign } from '@shared/business-cards/interfaces/business-card-design.interface';

export const BusinessCardsActions = createActionGroup({
    source: 'Business Cards',
    events: {
        reset: emptyProps(),
        'Get My Card Design': emptyProps(),
        'Get My Card Design Success': props<{ design: BusinessCardDesign }>(),
        'Get My Card Design Failure': props<{ error: HttpErrorResponse }>(),
        'Get Social Info': emptyProps(),
        'Get Social Info Success': props<{ socialInfo: Array<SocialInfo> }>(),
        'Get Social Info Failure': props<{ error: HttpErrorResponse }>(),
        'Open Exchange Modal': props<{ personId: number; isCompany?: boolean }>(),
        'Exchange Business Card': props<{ personId: number; message?: string; isCompany?: boolean }>(),
        'Exchange Business Card Success': emptyProps(),
        'Exchange Business Card Failure': props<{ error: HttpErrorResponse }>()
    }
});
