import { TrackerTarget } from '@core/analytics/enums/tracker-target.enum';
import { TagEntity } from '@core/tag-management/models/tag-entity.interface';
import { TagType } from '@core/tag-management/enums/tag-type.enum';
import { createReducer, on } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { TagManagerActions } from '../actions/tag-manager.actions';

export const tagManagerFeatureKey = 'tagManager';

export interface TagManagementState {
    tags: TagEntity[];
    loadedTags: TagEntity[];
}

export const initialState: TagManagementState = {
    tags: [
        { id: 1, name: TrackerTarget.Default, token: environment.GOOGLE_ANALYTICS_UA, tag_type: TagType.GA },
        { id: 2, name: TrackerTarget.Default, token: environment.GOOGLE_ANALYTICS_GA4, tag_type: TagType.GTAG }
    ],
    loadedTags: []
};

export const tagManagerReducer = createReducer(
    initialState,
    on(TagManagerActions.fetchTagsSuccess, (state, { tags }) => ({ ...state, tags: [...state.tags, ...tags] })),
    on(TagManagerActions.loadTagsSuccess, (state, { tags }) => ({
        ...state,
        loadedTags: [...state.loadedTags, ...tags]
    }))
);
