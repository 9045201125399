export interface AppUser {
    id?: number;
    app: string;
    user: string;
    uuid?: string;
    role?: AppUser.RoleEnum;
    /**
     * This is a flag to determine if the user has unsubscribed from marketing emails for the app.
     */
    marketing_emails?: boolean;
    /**
     * This is a flag to determine if the user has unsubscribed from notification emails for the app.
     */
    notification_emails?: boolean;
    created?: Date;
    blocked?: boolean;
    is_active?: boolean;
}

export namespace AppUser {
    export enum RoleEnum {
        Owner = 'owner',
        Admin = 'admin',
        User = 'user'
    }
}
