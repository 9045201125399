import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const PeopleListActions = createActionGroup({
    source: 'People List',
    events: {
        'Enter Page': props<{ moduleId: number }>(),
        'Fetch Next Page': props<{ moduleId: number; onComplete: () => void; onEndReached: () => void }>(),
        'Submit Search': props<{ moduleId: number; searchTerm: string }>(),
        'Select Person': props<{ personId: number }>(),
        'Leave Page': emptyProps(),
        'Scroll To': props<{ position: 'top' | 'bottom'; onComplete: () => void }>(),
        'Module Changed': emptyProps()
    }
});
