import { NgModule } from '@angular/core';
import { AppCommonModule } from '@common/common.module';
import { NotificationComponent } from './components/notification/notification.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { NotificationsStoreModule } from './store/notifications-store.module';
import { DeeplinkNotificationPageComponent } from './pages/deeplink-notification-page/deeplink-notification-page.component';

@NgModule({
    declarations: [NotificationsComponent, NotificationComponent, DeeplinkNotificationPageComponent],
    imports: [AppCommonModule, NotificationsStoreModule],
    exports: [NotificationsComponent]
})
export class NotificationsModule {}
