import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PortalApp } from '@shared/api';

import { filterBySearchTerm } from '../../../../shared/utilities/sorting-utility.functions';
import { filterPortalAppsByStart } from '../../services/portal.functions';
import { portalStoreKey } from '../models/portal-app-state.model';
import { PortalState } from '../models/portal-module-state.model';

const selectPortalState = createFeatureSelector<PortalState>(portalStoreKey);

const selectFilteredPortalApps = () =>
    createSelector(selectPortalState, (portalState: PortalState) => {
        if (!selectPortalState) {
            return;
        }

        const searchTerm = portalState.portalPageState ? portalState.portalPageState.searchTerm : undefined;

        const filteredPortalApps = filterBySearchTerm(portalState.portalApps, searchTerm, ['related_app', 'app_name']);

        return filteredPortalApps.filter((app: PortalApp) =>
            filterPortalAppsByStart(app, portalState.portalPageState.filterName)
        );
    });

const selectPortalPageState = () =>
    createSelector(selectPortalState, (portalState: PortalState) => {
        return portalState.portalPageState;
    });

const selectSinglePortalApp = () =>
    createSelector(selectPortalState, (portalState: PortalState) => {
        if (!selectPortalState) {
            return;
        }

        return portalState.portalApp;
    });

const selectPortalInfoPageState = () =>
    createSelector(selectPortalState, (portalState: PortalState) => {
        return portalState.portalInfoPageState;
    });

const selectTitle = createSelector(selectPortalState, (state) => {
    return state?.portalApp?.app_name;
});

const selectPortalModule = createSelector(selectPortalState, (state) => {
    return state?.portalModule;
});

export const PortalSelectors = {
    selectFilteredPortalApps,
    selectPortalPageState,
    selectSinglePortalApp,
    selectPortalInfoPageState,
    selectTitle,
    selectPortalModule
};
