import { Component } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { AppCoreFacadeService } from '../../../app-core/services/app-core-facade.service';
import { AppCoreActions } from '../../../root-store/store/app-core/actions/app-core.actions';

@Component({
    selector: 'cc-error404',
    templateUrl: './error404.component.html',
    styleUrls: ['./error404.component.scss']
})
export class Error404Component {
    public readonly noDataAnimationOptions: AnimationOptions = {
        autoplay: true,
        path: '/assets/lottie-animations/no-data.lottie.json',
        loop: false
    };

    constructor(private appCoreFacadeService: AppCoreFacadeService) {}

    public onHomepage(): void {
        this.appCoreFacadeService.dispatch(AppCoreActions.navigateHome());
    }
}
