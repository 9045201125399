import * as models from '@shared/api';

export interface UnRestrictedApp {
    url_name: string;
    theme?: models.InlineAppTheme;
    privacy?: UnRestrictedApp.PrivacyEnum;
    is_archived?: boolean;
    notification_delegate?: string;
    is_trial?: boolean;
    created?: Date;
    id?: number;
    language: UnRestrictedApp.LanguageEnum;
    email_validation?: boolean;
    app_bundle_id?: string;
    date_format?: string;
    /**
     * Toggle whether to send an email to users who have joined the app and do not have a usable password.
     */
    send_nopass_email?: boolean;
    tos_enabled?: boolean;
    /**
     * Comma separated list of custom domains
     */
    custom_domains?: string;
    name?: string;
    meetings_enabled?: boolean;
    widgets?: string;
    enable_chat_emails?: boolean;
    presenter_key?: string;
    is_active?: boolean;
    acm_certificate_arn?: string;
    toggle_password?: boolean;
    linkedin_api_key?: string;
    os_app_key?: string;
    owner: string;
    uuid?: string;
    languages?: Array<models.AppLanguage>;
    twenty_four_hour_time?: boolean;
    native_splash?: string;
    /**
     * Accepts your current local time and converts it to UTC based off the timezone you select
     */
    end: Date;
    expected_attendants?: number;
    toggle_passcode?: boolean;
    /**
     * Global override for chat functions
     */
    can_chat?: boolean;
    tos_text?: string;
    country?: string;
    /**
     * Accepts your current local time and converts it to UTC based off the timezone you select
     */
    start: Date;
    font_config?: models.InlineAppFontConfig;
    default_portal?: string;
    os_app?: string;
    allow_user_people_edits?: boolean;
    toggle_user_menu?: boolean;
    cloudfront_distribution_arn?: string;
    personal_agendas_enabled?: boolean;
    timezone: string;
    homepage?: string;
}

export namespace UnRestrictedApp {
    export enum PrivacyEnum {
        Public = 'public' as any,
        Private = 'private' as any,
        Secure = 'secure' as any
    }
    export enum LanguageEnum {
        En = 'en' as any,
        Fr = 'fr' as any,
        De = 'de' as any,
        Pl = 'pl' as any,
        Pt = 'pt' as any,
        Es = 'es' as any,
        Ru = 'ru' as any,
        Ar = 'ar' as any,
        Th = 'th' as any,
        Sv = 'sv' as any,
        Ja = 'ja' as any,
        Ko = 'ko' as any,
        ZhHans = 'zh-hans' as any,
        ZhHant = 'zh-hant' as any
    }
}
