import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExpandedApiDrivenNotification } from '@core/notifications/interfaces/expanded-api-driven-notification.interface';
import { AppAlert } from '@shared/api';
import { PaginatedResponse } from '@shared/pagination/models/paginated-response.model';
import { PaginationState } from '@shared/pagination/models/pagination-state.model';
import { Observable } from 'rxjs';
import { ApiSettings } from '../../../shared/settings/api-settings.constant';
import { AlertsApiEndpoints } from '../constants/alerts-api-endpoints.constant';
import { NotificationsQuery } from '../models/notifications-query.interface';

@Injectable({
    providedIn: 'root'
})
export class AlertsApiService {
    constructor(private httpClient: HttpClient) {}

    public getAlerts(appUrl: string): Observable<AppAlert[]> {
        return this.httpClient.get<AppAlert[]>(ApiSettings.fullUrl(appUrl, AlertsApiEndpoints.alerts));
    }

    public getPagedNotifications(
        appUrl: string,
        query: PaginationState & NotificationsQuery
    ): Observable<PaginatedResponse<ExpandedApiDrivenNotification>> {
        const url = ApiSettings.fullUrl(appUrl, AlertsApiEndpoints.notifications);

        const page = query.page ?? 1;
        const limit = query.limit ?? 10;
        const offset = ((page - 1) * limit).toString();

        const paginationQuery = { limit, offset, search: query.search ?? '' };

        return this.httpClient.get<PaginatedResponse<ExpandedApiDrivenNotification>>(url, {
            params: {
                ...query,
                ...paginationQuery
            }
        });
    }

    public archiveNotification(appUrl: string, id: string): Observable<ExpandedApiDrivenNotification> {
        const url = ApiSettings.fullUrl(appUrl, AlertsApiEndpoints.archiveNotification(id));

        return this.httpClient.post<ExpandedApiDrivenNotification>(url, {});
    }

    public unarchiveNotification(appUrl: string, id: string): Observable<ExpandedApiDrivenNotification> {
        const url = ApiSettings.fullUrl(appUrl, AlertsApiEndpoints.unarchiveNotification(id));

        return this.httpClient.post<ExpandedApiDrivenNotification>(url, {});
    }

    public archiveAllNotifications(appUrl: string): Observable<ExpandedApiDrivenNotification> {
        const url = ApiSettings.fullUrl(appUrl, AlertsApiEndpoints.archiveAllNotifications);

        return this.httpClient.post<ExpandedApiDrivenNotification>(url, {});
    }

    public markNotificationAsRead(appUrl: string, id: string): Observable<ExpandedApiDrivenNotification> {
        const url = ApiSettings.fullUrl(appUrl, AlertsApiEndpoints.readNotification(id));

        return this.httpClient.post<ExpandedApiDrivenNotification>(url, {});
    }

    public markAllNotificationsAsRead(appUrl: string): Observable<ExpandedApiDrivenNotification> {
        const url = ApiSettings.fullUrl(appUrl, AlertsApiEndpoints.readAllNotifications);

        return this.httpClient.post<ExpandedApiDrivenNotification>(url, {});
    }

    public sendRead(alertId: number, appUrl: string): Observable<AppAlert> {
        return this.httpClient.post<AppAlert>(ApiSettings.fullUrl(appUrl, AlertsApiEndpoints.read(alertId)), {});
    }
}
