import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthEndpointUrls } from '@core/authentication/constants/auth-endpoint-urls';
import { retryBackoff } from 'backoff-rxjs';
import { Observable } from 'rxjs';

@Injectable()
export class ServerUnresponsiveInterceptor implements HttpInterceptor {
    private readonly applicableRoutes = [
        AuthEndpointUrls.checkEmail,
        AuthEndpointUrls.login,
        AuthEndpointUrls.register
    ];

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const shouldInterceptUrl = !!this.applicableRoutes.find((routeSegment) => request.url.includes(routeSegment));

        if (!shouldInterceptUrl) {
            return next.handle(request);
        }

        return next.handle(request).pipe(
            retryBackoff({
                initialInterval: 300,
                maxRetries: 5,
                shouldRetry: (error: HttpErrorResponse) => error.status === 0 || error.status.toString().startsWith('5')
            })
        );
    }
}
