import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { take, map, switchMap, tap, catchError } from 'rxjs/operators';
import { AnalyticsOptionsService } from '../../analytics/services/analytics-options.service';
import { AnalyticsService } from '../../analytics/services/analytics.service';
import { AppCoreFacadeService } from '../../app-core/services/app-core-facade.service';
import { AppRouteParams } from '../../routing/constants/app-route-params.constant';
import { ModuleService } from '@core/module/services/module/module.service';
import { ToastService } from '@core/toast/services/toast/toast.service';

@Injectable({
    providedIn: 'root'
})
export class ModuleGuard {
    constructor(
        private appCoreFacadeService: AppCoreFacadeService,
        private moduleService: ModuleService,
        private analyticsService: AnalyticsService,
        private analyticsOptionsService: AnalyticsOptionsService,
        private toastService: ToastService
    ) {}

    canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
        const moduleId = parseInt(next.paramMap.get(AppRouteParams.moduleId), 10);
        // Redirect if route if it is of form /{appName}/modules/{moduleId}
        if (!next.children.length) {
            return this.appCoreFacadeService.getAppName().pipe(
                take(1),
                switchMap((appName) => this.moduleService.getModule(appName, moduleId)),
                switchMap((module) => this.moduleService.handleModule(module)),
                map(() => false),
                catchError(() => {
                    this.toastService.error('UNAVAILABLE_MODULE_TOAST');
                    return of(false);
                })
            );
        }
        // Load route if it is of form /{appName}/modules/{moduleId}/**/*
        return of(true).pipe(tap(() => this.sendAnalytics(moduleId)));
    }

    private sendAnalytics(moduleId: number): void {
        const options = this.analyticsOptionsService.viewPage('module', moduleId);
        this.analyticsService.emit(options);
    }
}
