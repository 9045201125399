import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { SocialInfo } from '@shared/api';

@Component({
    selector: 'cc-business-card-socials',
    templateUrl: './business-card-socials.component.html',
    styleUrls: ['./business-card-socials.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BusinessCardSocialsComponent implements OnChanges {
    @Input()
    public socialInfo: Array<SocialInfo>;

    @Input()
    public socialFields: Array<string>;

    @Output()
    public socialClicked: EventEmitter<SocialInfo> = new EventEmitter<SocialInfo>();

    public filteredSocialInfo: Array<SocialInfo>;

    public ngOnChanges(): void {
        this.filteredSocialInfo = this.socialInfo.filter((x) => this.canDisplayField(x.site));
    }

    public emitSocialClicked(socialInfo: SocialInfo): void {
        this.socialClicked.emit(socialInfo);
    }

    public canDisplayField(field: string): boolean {
        return this.socialFields.includes(field);
    }
}
