import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CustomIcon } from '@core/icons/constants/custom-icons.constant';
import { IconsService } from '@core/icons/services/icons/icons.service';
import { Observable, ReplaySubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
    selector: 'cc-svg-icon',
    templateUrl: './svg-icon.component.html',
    styleUrls: ['./svg-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SvgIconComponent implements OnChanges, OnInit {
    @Input()
    public name: CustomIcon | string;

    public svg$: Observable<string>;

    private nameChanged$: ReplaySubject<CustomIcon> = new ReplaySubject<CustomIcon>(1);

    constructor(private iconsService: IconsService) {}

    public ngOnInit(): void {
        this.svg$ = this.nameChanged$.pipe(switchMap((name) => this.iconsService.getIcon(name)));
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.name) {
            this.nameChanged$.next(changes.name.currentValue);
        }
    }
}
