import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EnvironmentExtras } from '@core/environment/models/environment-extras.model';
import { IEnvironment } from '@core/environment/models/environment.model';
import { WINDOW } from '@core/window.service';
import { merge } from 'lodash';
import { Observable, firstValueFrom } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable()
export class AppConfigService {
    /*
    Load runtime configuration from the assets folder and merge with the baked-in environment.
    This will allow things like the API url to be overidden in different envrionments (eg: UAT testing, on-prem, regional)
    */
    private appConfig: Partial<IEnvironment & EnvironmentExtras> = {};

    constructor(
        private http: HttpClient,
        @Inject(WINDOW) private window: Window
    ) {}

    public async loadAppConfig(): Promise<void | any> {
        const appName = this.window.location.pathname.split('/')[1];

        const [genericAppConfig, appSpecificAppConfig] = await Promise.all([
            firstValueFrom(this.getAppConfig('/assets/data/appConfig.json')).catch(() => ({})),
            firstValueFrom(this.getAppConfig(`${window.location.origin}/${appName}/assets/data/appConfig.json`)).catch(
                () => ({})
            )
        ]);

        this.appConfig = {
            ...genericAppConfig,
            ...appSpecificAppConfig
        };

        merge(environment, this.appConfig);
    }

    public getConfig(): any {
        return this.appConfig;
    }

    private getAppConfig(url: string): Observable<Partial<IEnvironment & EnvironmentExtras>> {
        return this.http.get(url, {
            headers: {
                'ngsw-bypass': 'true'
            },
            params: {
                cacheBuster: Date.now()
            }
        });
    }
}
