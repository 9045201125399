import { HttpErrorResponse } from '@angular/common/http';
import { CookieConfigurationState } from '@core/banner/interfaces/cookie-configuration-state.interface';
import { GlobalBannerConfig } from '@core/banner/interfaces/global-banner-config.interface';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const BannerActions = createActionGroup({
    source: 'Banner',
    events: {
        // banner
        'Reset Global Banner': emptyProps(),
        'Fetch Global Banner Config': emptyProps(),
        'Fetch Global Banner Config Success': props<{ config: GlobalBannerConfig }>(),
        'Fetch Global Banner Config Failure': props<{ error: HttpErrorResponse }>(),
        'Set Has Dismissed': props<{ hasDismissed: boolean }>(),
        'Dismiss Global Banner': emptyProps(),
        // cookies
        'Fetch Cookies Configuration': emptyProps(),
        'Accept All Cookies': emptyProps(),
        'Cookies Dismissed': emptyProps(),
        'Send Cookies Configuration Success': emptyProps(),
        'Fetch Cookies Configuration Success': props<{ cookies: CookieConfigurationState }>(),
        'Accept All Cookies Success': props<{ cookies: CookieConfigurationState }>(),
        'Save Selected Cookies': props<{ cookies: CookieConfigurationState }>(),
        'Save Selected Cookies Success': props<{ cookies: CookieConfigurationState }>(),
        'Show Cookies Customisation': props<{ show: boolean }>()
    }
});
