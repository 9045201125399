import { Injectable } from '@angular/core';
import { BusinessCardRequest } from '@features/user-business-cards/interfaces/business-card-request.interface';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class UserBusinessCardsAlertService {
    constructor(
        private alertController: AlertController,
        private translateService: TranslateService
    ) {}

    public async openProcessScanResultSuccessAlert(request: BusinessCardRequest): Promise<void> {
        const header = this.translateService.instant('BUSINESS_CARDS_SCAN_SUCCESS_ALERT_TITLE');
        const message = this.translateService.instant('BUSINESS_CARDS_SCAN_SUCCESS_ALERT_MESSAGE', {
            firstName: request.card.profile.first_name,
            lastName: request.card.profile.last_name
        });
        const button = this.translateService.instant('BUSINESS_CARDS_SCAN_SUCCESS_ALERT_DISMISS');

        const alert = await this.alertController.create({
            header,
            message,
            buttons: [button]
        });

        await alert.present();
    }

    public async openProcessScanResultFailureAlert(): Promise<void> {
        const header = this.translateService.instant('BUSINESS_CARDS_SCAN_FAILURE_ALERT_TITLE');
        const message = this.translateService.instant('BUSINESS_CARDS_SCAN_FAILURE_ALERT_MESSAGE');
        const button = this.translateService.instant('BUSINESS_CARDS_SCAN_FAILURE_ALERT_DISMISS');

        const alert = await this.alertController.create({
            header,
            message,
            buttons: [button]
        });

        await alert.present();
    }
}
