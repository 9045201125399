import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, props } from '@ngrx/store';
import { App } from '@shared/api';

export const AppCoreApiActions = createActionGroup({
    source: 'App Core Api',
    events: {
        'Load App Settings Success': props<{ appSettings: App }>(),
        'Load App Settings Failure': props<{ error: HttpErrorResponse }>()
    }
});
