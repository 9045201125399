import { Component, EventEmitter, HostBinding, HostListener, Input, OnInit, Output } from '@angular/core';
import { Module } from '@api';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { AuthenticationFacadeService } from '@core/authentication/services/authentication-facade.service';
import { ModuleService } from '@core/module/services/module/module.service';
import { Observable, of } from 'rxjs';
import { map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { OpenModuleOptions } from './interfaces/open-module-options.interface';

@Component({
    selector: 'cc-open-module',
    templateUrl: './open-module.component.html',
    styleUrls: ['./open-module.component.scss']
})
export class OpenModuleComponent implements OnInit {
    @Input()
    public id: string;

    @Input()
    public options: string;

    @Output()
    public moduleClick = new EventEmitter<string>();

    @HostBinding('attr.role')
    public role = 'button';

    @HostBinding('attr.tabindex')
    public tabIndex = 0;

    @HostBinding('style.display')
    public componentVisibility: 'none' | undefined;

    @HostListener('keyup.enter')
    @HostListener('click')
    public openModule(): void {
        if (!this.id) {
            return;
        }
        this.appCoreFacadeService
            .getAppName()
            .pipe(
                switchMap((appUrlName: string) => this.moduleService.getModule(appUrlName, this.id)),
                tap((module: Module) => {
                    this.moduleClick.emit(this.id);
                    return this.moduleService.handleModule(module);
                }),
                take(1)
            )
            .subscribe();
    }

    public elementOptions: OpenModuleOptions;
    public hasVisibility$: Observable<boolean>;

    constructor(
        private moduleService: ModuleService,
        private appCoreFacadeService: AppCoreFacadeService,
        private authenticationFacadeService: AuthenticationFacadeService
    ) {}

    public ngOnInit(): void {
        this.elementOptions = this.options && JSON.parse(this.options);

        // If there's any privacy we want to hide this element as soon as possible.
        if (this.elementOptions?.allowedGroups) {
            this.componentVisibility = 'none';
        }

        this.hasVisibility$ = this.getWidgetVisibility();

        this.hasVisibility$.subscribe((hasVisibility) => {
            this.componentVisibility = hasVisibility ? undefined : 'none';
        });
    }

    private getWidgetVisibility(): Observable<boolean> {
        if (!this.elementOptions?.allowedGroups) {
            return of(true);
        }

        const groups = this.elementOptions.allowedGroups.split(',');

        return this.authenticationFacadeService.getAuthenticatedPerson().pipe(
            map((authenticatedPerson) => {
                if (!authenticatedPerson) {
                    return false;
                }

                return authenticatedPerson.peoplegroups.some((group) => groups.includes(group));
            }),
            shareReplay(1)
        );
    }
}
