import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { DeviceService } from '../../device/services/device.service';
import { CapacitorService } from './capacitor.service';

@Injectable({
    providedIn: 'root'
})
export class SplashScreenService {
    private webSplashScreen: HTMLElement;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private deviceService: DeviceService,
        private capacitorService: CapacitorService
    ) {
        this.webSplashScreen = this.document.getElementById('cc-splashscreen');

        if (this.deviceService.isNative()) {
            this.webSplashScreen.classList.add('cc-hide');
        }
    }

    show(): void {
        if (this.deviceService.isNative()) {
            this.capacitorService.splashScreen.show();
        } else {
            this.webSplashScreen.classList.remove('cc-hide');
        }
    }

    hide(): void {
        if (this.deviceService.isNative()) {
            this.capacitorService.splashScreen.hide();
        } else {
            this.webSplashScreen.classList.add('cc-hide');
        }
    }
}
