import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { AppRoutingActions } from '@core/root-store/store/app-routing/actions/app-routing.actions';
import { AppPageRoutes } from '@core/routing/constants/app-page-routes.constant';
import { AppUserPageRoutes } from '@core/routing/constants/user-page-routes.constant';
import { AgendaModuleService } from '@features/agenda/agenda-module/services/agenda-module.service';
import { DocumentsApiService } from '@features/documents/services/documents-api.service';
import { GlobalScannerService } from '@features/global-scanner/services/global-scanner/global-scanner.service';
import { LoopinService } from '@features/loopin/services/loopin/loopin.service';
import { AlertController } from '@ionic/angular';
import { Action, AppDocument } from '@shared/api';
import { UrlUtilityService } from '@shared/utilities/url-utility.service';
import { firstValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppModuleRoutes } from '@core/routing/constants/module-routes.constant';

@Injectable({
    providedIn: 'root'
})
export class ActionHandlerService {
    constructor(
        private alertController: AlertController,
        private documentsApiService: DocumentsApiService,
        private urlService: UrlUtilityService,
        private appCoreFacadeService: AppCoreFacadeService,
        private agendaModuleService: AgendaModuleService,
        private loopinService: LoopinService,
        private router: Router,
        private globalScannerService: GlobalScannerService
    ) {}

    public async handleActions(actions: Action[]): Promise<void> {
        if (!actions) {
            return;
        }

        for (const action of actions) {
            await this.handleAction(action);
        }
    }

    public async handleDocument(value: string): Promise<void> {
        const documentId = parseInt(value, 10);
        const appName = await this.appCoreFacadeService.getAppName().pipe(take(1)).toPromise();
        const documents: AppDocument[] = await this.documentsApiService.getDocuments(appName).toPromise();
        const document = documents.find((d) => d.id === documentId);
        this.urlService.open(document.link);
    }

    public async handleAction(action: Action): Promise<void> {
        switch (action.action) {
            case Action.ActionEnum.OPENDIALOG:
                const dialog = await this.alertController.create({
                    message: action.value,
                    buttons: ['close']
                });
                dialog.present();
                break;
            case Action.ActionEnum.OPENURL:
                this.urlService.open(action.value);
                break;
            case Action.ActionEnum.OPENMODULE:
                const appName = await this.appCoreFacadeService.getAppName().pipe(take(1)).toPromise();

                const targetRoute = this.router.createUrlTree([appName, AppPageRoutes.module, action.value]).toString();

                const currentRoute = this.router.url;
                const isAlreadyOnRoute = currentRoute.includes(targetRoute);

                if (isAlreadyOnRoute) {
                    break;
                }

                this.appCoreFacadeService.dispatch(AppRoutingActions.goToAppModule({ urlSegments: [action.value] }));
                break;
            case Action.ActionEnum.OPENSCANNER:
                await firstValueFrom(this.globalScannerService.openScanner());
                break;
            case Action.ActionEnum.AWARDPOINTS:
                break;
            case Action.ActionEnum.COMPLETECHALLENGE:
                break;
            case Action.ActionEnum.OPENSESSION:
                this.appCoreFacadeService.dispatch(
                    AppRoutingActions.goToAppModule({
                        urlSegments: [action.module, AppModuleRoutes.agenda, AppPageRoutes.session, action.value]
                    })
                );
                break;
            case Action.ActionEnum.OPENPROFILE:
                this.appCoreFacadeService.dispatch(
                    AppRoutingActions.goToAppPage({ urlSegments: [AppPageRoutes.person, action.value] })
                );
                break;
            case Action.ActionEnum.OPENCOMPANY:
                this.appCoreFacadeService.dispatch(
                    AppRoutingActions.goToAppPage({ urlSegments: [AppPageRoutes.company, action.value] })
                );
                break;
            case Action.ActionEnum.OPENMAP:
                this.appCoreFacadeService.dispatch(
                    AppRoutingActions.goToAppPage({ urlSegments: [AppPageRoutes.map, action.value] })
                );
                break;
            case Action.ActionEnum.OPENDOCUMENT:
                this.handleDocument(action.value);
                break;
            case Action.ActionEnum.OPENSURVEY:
                this.appCoreFacadeService.dispatch(
                    AppRoutingActions.goToAppPage({ urlSegments: [AppPageRoutes.poll, action.value] })
                );
                break;
            case Action.ActionEnum.OPENINFOBOOTH:
                this.appCoreFacadeService.dispatch(
                    AppRoutingActions.goToAppPage({ urlSegments: [AppPageRoutes.infoPage, action.value] })
                );
                break;
            case Action.ActionEnum.OPENQANDA:
                this.appCoreFacadeService.dispatch(
                    AppRoutingActions.goToAppPage({ urlSegments: [AppPageRoutes.qanda, action.value] })
                );
                break;
            case Action.ActionEnum.OPENLIVEPOLL:
                this.appCoreFacadeService.dispatch(
                    AppRoutingActions.goToAppPage({ urlSegments: [AppPageRoutes.livePoll, action.value] })
                );
                break;
            case Action.ActionEnum.OPENAGENDAFILTERS:
                this.agendaModuleService.openModuleWithFilterOpen(parseInt(action.value, 10));
                break;
            case Action.ActionEnum.OPENLOOPINROOM:
                this.loopinService.generateLinkAndOpenRoom(parseInt(action.value, 10));
                break;
            case Action.ActionEnum.OPENBADGE:
                this.appCoreFacadeService.dispatch(
                    AppRoutingActions.goToAppUserPage({ urlSegments: [AppUserPageRoutes.badge] })
                );
                break;
            case Action.ActionEnum.OPENABSTRACT:
                this.appCoreFacadeService.dispatch(
                    AppRoutingActions.goToAppModule({
                        urlSegments: [action.module, AppModuleRoutes.abstracts, action.value]
                    })
                );
                break;
            case Action.ActionEnum.OPENEVENTSCHEDULE:
                this.appCoreFacadeService.dispatch(
                    AppRoutingActions.goToAppUserPage({ urlSegments: [AppUserPageRoutes.personalAgenda] })
                );
                break;
            case Action.ActionEnum.OPENNOTES:
                this.appCoreFacadeService.dispatch(
                    AppRoutingActions.goToAppUserPage({ urlSegments: [AppUserPageRoutes.notes] })
                );
                break;
            case Action.ActionEnum.OPENMEETINGS:
                this.appCoreFacadeService.dispatch(
                    AppRoutingActions.goToAppUserPage({ urlSegments: [AppUserPageRoutes.meetings] })
                );
                break;
            case Action.ActionEnum.OPENBUSINESSCARDS:
                this.appCoreFacadeService.dispatch(
                    AppRoutingActions.goToAppUserPage({ urlSegments: [AppUserPageRoutes.businessCards] })
                );
                break;
            case Action.ActionEnum.OPENSURVEYS:
                this.appCoreFacadeService.dispatch(
                    AppRoutingActions.goToAppUserPage({ urlSegments: [AppUserPageRoutes.surveys] })
                );
                break;
            case Action.ActionEnum.OPENEDITPROFILE:
                this.appCoreFacadeService.dispatch(
                    AppRoutingActions.goToAppUserPage({ urlSegments: [AppUserPageRoutes.profile] })
                );
                break;
        }
    }
}
