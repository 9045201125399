import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { ContainerService } from '@features/container/services/container.service';
import { DeviceService } from '../../device/services/device.service';
import { PageRoutes } from '../../routing/constants/page-routes.constant';

/**
 * This guard will redirect from the top level route to either an app or the container page.
 */
@Injectable({
    providedIn: 'root'
})
export class AppRedirectGuard {
    constructor(
        private deviceService: DeviceService,
        private router: Router,
        private containerService: ContainerService
    ) {}

    async canActivate(next: ActivatedRouteSnapshot): Promise<UrlTree | boolean> {
        if (next.firstChild) {
            return true;
        }

        const containerChildAppUrl = this.containerService.getApp();

        if (containerChildAppUrl) {
            return this.router.createUrlTree([containerChildAppUrl]);
        }

        const nativeAppUrl = await this.deviceService.getNativeAppUrl();
        const isContainerApp = await this.containerService.isContainerApp(nativeAppUrl);

        if (!nativeAppUrl || isContainerApp) {
            return this.router.createUrlTree([PageRoutes.container]);
        }

        return this.router.createUrlTree([nativeAppUrl]);
    }
}
