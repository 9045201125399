import { NgModule } from '@angular/core';
import { SsoAuthService } from '@core/authentication/services/sso-auth.service';
import { SsoService } from '@core/authentication/services/sso.service';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../../../environments/environment';
import { appReducers, metaReducers } from './reducers';
import { AppCoreEffects } from './store/app-core/effects/app-core.effects';
import { AppRoutingEffects } from './store/app-routing/effects/app-routing.effects';
import { AuthenticationEffects } from './store/authentication/effects/authentication.effects';
import { ErrorHandlingEffects } from './store/error-handling/effects/error-handling.effects';
import { GlobalSettingsEffects } from './store/global-settings/effects/global-settings.effects';
import { RealtimeEffects } from './store/realtime/effects/realtime.effects';

const environmentSpecificImports = [];
if (!environment.PRODUCTION) {
    environmentSpecificImports.push(StoreDevtoolsModule.instrument({}));
}

@NgModule({
    imports: [
        StoreRouterConnectingModule.forRoot(),
        StoreModule.forRoot(appReducers, {
            metaReducers,
            runtimeChecks: {
                // Verifies the immutability of state and actions - this is of critical importance when working with NgRx store.
                strictStateImmutability: true,
                strictActionImmutability: true,
                // Serializability will allow us to persist the store state if we want to at some point but is not completely necessary.
                strictStateSerializability: true,
                strictActionSerializability: false
            }
        }),
        EffectsModule.forRoot([
            AppCoreEffects,
            AuthenticationEffects,
            ErrorHandlingEffects,
            RealtimeEffects,
            AppRoutingEffects,
            GlobalSettingsEffects
        ]),
        ...environmentSpecificImports
    ],
    declarations: [],
    exports: [],
    providers: [SsoService, SsoAuthService]
})
export class RootStoreModule {}
