import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'cc-image-viewer',
    templateUrl: './image-viewer.component.html',
    styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent {
    @Input()
    public imageUrl: string;

    constructor(private modalController: ModalController) {}

    public closeModal(): void {
        this.modalController.dismiss();
    }
}
