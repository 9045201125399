import * as models from '@shared/api';

export interface AppBootstrap {
    id?: number;
    name?: string;
    url_name: string;
    language: AppBootstrap.LanguageEnum;
    country: AppBootstrap.CountryEnum;
    timezone: string;
    /**
     * Accepts your current local time and converts it to UTC based off the timezone you select
     */
    start: Date;
    /**
     * Accepts your current local time and converts it to UTC based off the timezone you select
     */
    end: Date;
    is_active?: boolean;
    created?: Date;
    modules?: Array<models.Module>;
    privacy?: AppBootstrap.PrivacyEnum;
    owner: string;
    homepage?: number;
    expected_attendants?: number;
    passcode?: string;
    uuid?: string;
    email_validation?: boolean;
    toggle_user_menu?: boolean;
    default_portal?: string;
    date_format?: string;
    os_app?: string;
    toggle_passcode?: boolean;
    notification_delegate?: string;
    google_api_key?: string;
    app_bundle_id?: string;
    /**
     * Global override for chat functions
     */
    can_chat?: boolean;
    linkedin_api_key?: string;
    languages?: Array<models.AppLanguage>;
    font_config?: models.AppFontConfig;
    is_trial?: boolean;
    meetings_enabled?: boolean;
    twenty_four_hour_time?: boolean;
}

export namespace AppBootstrap {
    export enum LanguageEnum {
        En = 'en',
        Fr = 'fr',
        De = 'de',
        Pl = 'pl',
        Pt = 'pt',
        Es = 'es',
        Ru = 'ru',
        Ar = 'ar',
        Th = 'th',
        Sv = 'sv',
        Ja = 'ja',
        Ko = 'ko',
        ZhHans = 'zh-hans',
        ZhHant = 'zh-hant'
    }
    export enum CountryEnum {
        AF = 'AF',
        AX = 'AX',
        AL = 'AL',
        DZ = 'DZ',
        AS = 'AS',
        AD = 'AD',
        AO = 'AO',
        AI = 'AI',
        AQ = 'AQ',
        AG = 'AG',
        AR = 'AR',
        AM = 'AM',
        AW = 'AW',
        AU = 'AU',
        AT = 'AT',
        AZ = 'AZ',
        BS = 'BS',
        BH = 'BH',
        BD = 'BD',
        BB = 'BB',
        BY = 'BY',
        BE = 'BE',
        BZ = 'BZ',
        BJ = 'BJ',
        BM = 'BM',
        BT = 'BT',
        BO = 'BO',
        BQ = 'BQ',
        BA = 'BA',
        BW = 'BW',
        BV = 'BV',
        BR = 'BR',
        IO = 'IO',
        BN = 'BN',
        BG = 'BG',
        BF = 'BF',
        BI = 'BI',
        CV = 'CV',
        KH = 'KH',
        CM = 'CM',
        CA = 'CA',
        KY = 'KY',
        CF = 'CF',
        TD = 'TD',
        CL = 'CL',
        CN = 'CN',
        CX = 'CX',
        CC = 'CC',
        CO = 'CO',
        KM = 'KM',
        CG = 'CG',
        CD = 'CD',
        CK = 'CK',
        CR = 'CR',
        CI = 'CI',
        HR = 'HR',
        CU = 'CU',
        CW = 'CW',
        CY = 'CY',
        CZ = 'CZ',
        DK = 'DK',
        DJ = 'DJ',
        DM = 'DM',
        DO = 'DO',
        EC = 'EC',
        EG = 'EG',
        SV = 'SV',
        GQ = 'GQ',
        ER = 'ER',
        EE = 'EE',
        ET = 'ET',
        FK = 'FK',
        FO = 'FO',
        FJ = 'FJ',
        FI = 'FI',
        FR = 'FR',
        GF = 'GF',
        PF = 'PF',
        TF = 'TF',
        GA = 'GA',
        GM = 'GM',
        GE = 'GE',
        DE = 'DE',
        GH = 'GH',
        GI = 'GI',
        GR = 'GR',
        GL = 'GL',
        GD = 'GD',
        GP = 'GP',
        GU = 'GU',
        GT = 'GT',
        GG = 'GG',
        GN = 'GN',
        GW = 'GW',
        GY = 'GY',
        HT = 'HT',
        HM = 'HM',
        VA = 'VA',
        HN = 'HN',
        HK = 'HK',
        HU = 'HU',
        IS = 'IS',
        IN = 'IN',
        ID = 'ID',
        IR = 'IR',
        IQ = 'IQ',
        IE = 'IE',
        IM = 'IM',
        IL = 'IL',
        IT = 'IT',
        JM = 'JM',
        JP = 'JP',
        JE = 'JE',
        JO = 'JO',
        KZ = 'KZ',
        KE = 'KE',
        KI = 'KI',
        KW = 'KW',
        KG = 'KG',
        LA = 'LA',
        LV = 'LV',
        LB = 'LB',
        LS = 'LS',
        LR = 'LR',
        LY = 'LY',
        LI = 'LI',
        LT = 'LT',
        LU = 'LU',
        MO = 'MO',
        MK = 'MK',
        MG = 'MG',
        MW = 'MW',
        MY = 'MY',
        MV = 'MV',
        ML = 'ML',
        MT = 'MT',
        MH = 'MH',
        MQ = 'MQ',
        MR = 'MR',
        MU = 'MU',
        YT = 'YT',
        MX = 'MX',
        FM = 'FM',
        MD = 'MD',
        MC = 'MC',
        MN = 'MN',
        ME = 'ME',
        MS = 'MS',
        MA = 'MA',
        MZ = 'MZ',
        MM = 'MM',
        NA = 'NA',
        NR = 'NR',
        NP = 'NP',
        NL = 'NL',
        NC = 'NC',
        NZ = 'NZ',
        NI = 'NI',
        NE = 'NE',
        NG = 'NG',
        NU = 'NU',
        NF = 'NF',
        KP = 'KP',
        MP = 'MP',
        OM = 'OM',
        PK = 'PK',
        PW = 'PW',
        PS = 'PS',
        PA = 'PA',
        PG = 'PG',
        PY = 'PY',
        PE = 'PE',
        PH = 'PH',
        PN = 'PN',
        PL = 'PL',
        PT = 'PT',
        PR = 'PR',
        QA = 'QA',
        RE = 'RE',
        RO = 'RO',
        RU = 'RU',
        RW = 'RW',
        BL = 'BL',
        SH = 'SH',
        KN = 'KN',
        LC = 'LC',
        MF = 'MF',
        PM = 'PM',
        VC = 'VC',
        WS = 'WS',
        SM = 'SM',
        ST = 'ST',
        SA = 'SA',
        SN = 'SN',
        RS = 'RS',
        SC = 'SC',
        SL = 'SL',
        SG = 'SG',
        SX = 'SX',
        SK = 'SK',
        SI = 'SI',
        SB = 'SB',
        SO = 'SO',
        ZA = 'ZA',
        GS = 'GS',
        KR = 'KR',
        SS = 'SS',
        ES = 'ES',
        LK = 'LK',
        SD = 'SD',
        SR = 'SR',
        SJ = 'SJ',
        SZ = 'SZ',
        SE = 'SE',
        CH = 'CH',
        SY = 'SY',
        TW = 'TW',
        TJ = 'TJ',
        TZ = 'TZ',
        TH = 'TH',
        TL = 'TL',
        TG = 'TG',
        TK = 'TK',
        TO = 'TO',
        TT = 'TT',
        TN = 'TN',
        TR = 'TR',
        TM = 'TM',
        TC = 'TC',
        TV = 'TV',
        UG = 'UG',
        UA = 'UA',
        AE = 'AE',
        GB = 'GB',
        UM = 'UM',
        US = 'US',
        UY = 'UY',
        UZ = 'UZ',
        VU = 'VU',
        VE = 'VE',
        VN = 'VN',
        VG = 'VG',
        VI = 'VI',
        WF = 'WF',
        EH = 'EH',
        YE = 'YE',
        ZM = 'ZM',
        ZW = 'ZW'
    }
    export enum PrivacyEnum {
        Public = 'public',
        Private = 'private',
        Secure = 'secure'
    }
}
