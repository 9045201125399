import { ChangeDetectionStrategy, Component, HostListener, Input } from '@angular/core';
import { Module } from '@api/models/models';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { ModuleApiService } from '@core/module/services/module-api/module-api.service';
import { ToastService } from '@core/toast/services/toast/toast.service';
import { AgendaModuleService } from '@features/agenda/agenda-module/services/agenda-module.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'cc-open-agenda-filter',
    templateUrl: './open-agenda-filter.component.html',
    styleUrls: ['./open-agenda-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpenAgendaFilterComponent {
    @Input()
    id: number;

    @HostListener('click')
    openAgenda(): void {
        if (!this.id) {
            return;
        }
        this.appCoreFacadeService
            .getAppName()
            .pipe(
                switchMap((appUrl) => this.moduleApiService.getModule(appUrl, this.id)),
                filter((module) => module.type === Module.TypeEnum.Agenda),
                tap(() => this.agendaModuleService.openModuleWithFilterOpen(this.id)),
                catchError(() => {
                    const translation = this.translateService.instant('AGENDA_UNAVAILABLE');
                    this.toastService.error(translation);

                    return EMPTY;
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    constructor(
        private appCoreFacadeService: AppCoreFacadeService,
        private moduleApiService: ModuleApiService,
        private toastService: ToastService,
        private translateService: TranslateService,
        private agendaModuleService: AgendaModuleService
    ) {}
}
