import { Injectable } from '@angular/core';
import { Location, LocationType, PinDrop } from '@api';
import { AppRoutingActions } from '@core/root-store/store/app-routing/actions/app-routing.actions';
import { AppPageRoutes } from '@core/routing/constants/app-page-routes.constant';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { SessionLocation, SessionPindrop } from '@features/agenda/models/session-location.model';

@Injectable({
    providedIn: 'root'
})
export class LocationService {
    constructor(private appCoreFacadeService: AppCoreFacadeService) {}

    openLocation(location: Location | SessionLocation): void {
        // Get the model type depending if its a normal location, or a session location
        const locationType = 'model_type' in location ? location.model_type : location.resourcetype;

        switch (locationType) {
            case LocationType.locationMap:
                return this.appCoreFacadeService.dispatch(
                    AppRoutingActions.goToAppPage({ urlSegments: [AppPageRoutes.map, location.id] })
                );
            case LocationType.pindrop:
                return this.appCoreFacadeService.dispatch(
                    AppRoutingActions.goToAppPage({
                        urlSegments: [AppPageRoutes.map, (location as PinDrop | SessionPindrop).map],
                        extras: { queryParams: { pindrops: location.id } }
                    })
                );
        }
    }
}
