import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NavigationHistoryEffects } from './effects/navigation-history.effects';
import { navigationHistoryStoreKey } from './models/navigation-history.state.model';
import { navigationHistoryReducer } from './reducers/navigation-history.reducer';

@NgModule({
    imports: [
        StoreModule.forFeature(navigationHistoryStoreKey, navigationHistoryReducer),
        EffectsModule.forFeature([NavigationHistoryEffects])
    ]
})
export class NavigationHistoryStoreModule {}
