import { Injectable } from '@angular/core';
import { ApiSettings } from '../../settings/api-settings.constant';
import { MableCustomField, MableCustomFieldGroup, MableCustomValue } from '../../api';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class CustomFieldsApiService {
    private readonly customFieldsUrl = ApiSettings.customFields;
    private readonly customFieldValuesUrl = ApiSettings.customValues;
    private readonly customFieldGroupsUrl = ApiSettings.customFieldGroups;

    constructor(private http: HttpClient) {}

    public getCustomFields(appName: string, params: any): Observable<MableCustomField[]> {
        const url = ApiSettings.fullUrl(appName, this.customFieldsUrl);
        return this.http.get<MableCustomField[]>(url, { params });
    }

    public getCustomFieldValues(appName: string, params: any): Observable<MableCustomValue[]> {
        const url = ApiSettings.fullUrl(appName, this.customFieldValuesUrl);
        return this.http.get<MableCustomValue[]>(url, { params });
    }

    public getCustomFieldGroups(appName: string, params: any): Observable<MableCustomFieldGroup[]> {
        const url = ApiSettings.fullUrl(appName, this.customFieldGroupsUrl);
        return this.http.get<MableCustomFieldGroup[]>(url, { params });
    }
}
