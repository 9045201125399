import { ChangeDetectionStrategy, Component } from '@angular/core';

/**
 * This page is a stub page so that API emails have a place to direct to
 */
@Component({
    selector: 'cc-deeplink-notification-page',
    templateUrl: './deeplink-notification-page.component.html',
    styleUrls: ['./deeplink-notification-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeeplinkNotificationPageComponent {}
