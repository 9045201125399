<ion-avatar *ngIf="person" class="cc-person-icon" slot="start" aria-hidden="true">
    <button class="cc-person-icon__button" (click)="onProfilePictureClicked(person.app_picture)">
        <ng-container *ngIf="shouldDisplayProfilePhoto; else initials">
            <img [src]="person.app_picture | imageDimensions: srcSize : srcSize" [alt]="person.last_name" />
        </ng-container>
        <ng-template #initials>
            <ion-label class="text-accent bold">
                <span *ngIf="shouldDisplayFirstName">{{ person.first_name | slice: 0 : 1 | uppercase }}</span>
                <span *ngIf="shouldDisplayLastName">{{ person.last_name | slice: 0 : 1 | uppercase }}</span>
            </ion-label>
        </ng-template>
    </button>
    <button
        *ngIf="icon"
        class="cc-person-icon__icon"
        [attr.aria-label]="iconAriaLabel || ('ICON' | translate)"
        (click)="onIconButtonClicked()"
    >
        <i class="material-icons" @fadeIn>{{ icon }}</i>
    </button>
</ion-avatar>
