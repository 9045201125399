import { NgModule } from '@angular/core';
import { QrComponent } from '../qr/components/qr/qr.component';
import { AppCommonModule } from '@common/common.module';

@NgModule({
    declarations: [QrComponent],
    imports: [AppCommonModule],
    exports: [QrComponent]
})
export class QrModule {}
