import { Injectable } from '@angular/core';
import { TagEntity } from '@core/tag-management/models/tag-entity.interface';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { TagManagementState } from '../reducers/tag-manager.reducer';
import { TagManagementSelectors } from '../selectors/tag-manager.selectors';

@Injectable({
    providedIn: 'root'
})
export class TagManagerFacadeService {
    constructor(private store: Store<TagManagementState>) {}

    public dispatch(action: Action): void {
        this.store.dispatch(action);
    }

    public selectTags(): Observable<TagEntity[]> {
        return this.store.select(TagManagementSelectors.selectTagEntities);
    }

    public selectLoadedTags(): Observable<TagEntity[]> {
        return this.store.select(TagManagementSelectors.selectLoadedTags);
    }
}
