export interface TaskResult {
    task_id: string;
    progress: number;
    has_errors: boolean;
    app: string;
    created?: Date;
    errors?: string;
    status?: TaskResult.StatusEnum;
    file?: string;
    module?: number;
}

export namespace TaskResult {
    export enum StatusEnum {
        Pending = 'pending' as any,
        Started = 'started' as any,
        Failed = 'failed' as any,
        Success = 'success' as any
    }
}
