import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { AppAlert } from '@shared/api';
import { AlertsTabEnum } from '../../enums/alerts-tab.enum';

export const AlertsActions = createActionGroup({
    source: 'Alerts',
    events: {
        'Enter Page': emptyProps(),
        'Leave Page': emptyProps(),
        'Change Tab': props<{ newTab: AlertsTabEnum }>(),
        'Decrement Notifications Badge': emptyProps(),
        'Increment Notifications Badge': emptyProps(),
        'Mark As Read': props<{ alert: AppAlert }>(),
        'Fetch Unread Notification Count': emptyProps(),
        'Update Stored Notification Count': props<{ count: number }>(),
        'Realtime New Alert': props<{ alert: AppAlert }>(),
        'Fetch Alerts': emptyProps(),
        'Get Deeplink Failure': emptyProps()
    }
});
