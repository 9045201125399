import { Injectable } from '@angular/core';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { AuthenticationFacadeService } from '@core/authentication/services/authentication-facade.service';
import { AuthenticationActions } from '@core/root-store/store/authentication/actions/authentication.actions';
import { ToastService } from '@core/toast/services/toast/toast.service';
import { DateTime } from 'luxon';
import { BehaviorSubject, Observable, combineLatest, filter, map, take } from 'rxjs';

export enum TimezoneMode {
    Event = 'event',
    Local = 'local'
}

@Injectable({
    providedIn: 'root'
})
export class SelectedTimezoneService {
    public eventAndLocalTimezonesDiffer$: Observable<boolean>;
    public activeTimezone$: Observable<string>;
    public currentTimezoneDisplay$: Observable<TimezoneMode>;
    public localTimezone: string;
    public eventTimezone$: Observable<string>;
    public eventTimezoneOffsetHours$: Observable<number>;

    private timezoneModeSubject$ = new BehaviorSubject<TimezoneMode>(TimezoneMode.Local);

    constructor(
        private appCoreFacadeService: AppCoreFacadeService,
        private toastService: ToastService,
        private authFacadeService: AuthenticationFacadeService
    ) {
        this.currentTimezoneDisplay$ = this.timezoneModeSubject$.asObservable();

        this.localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        this.eventTimezone$ = this.appCoreFacadeService.getAppSettings().pipe(map((settings) => settings?.timezone));

        this.eventTimezoneOffsetHours$ = this.eventTimezone$.pipe(
            map((timezone) => {
                const localTimezoneHours = DateTime.now().offset;
                const eventTimezoneHours = DateTime.now().setZone(timezone).offset;

                return (eventTimezoneHours - localTimezoneHours) / 60;
            })
        );

        this.eventAndLocalTimezonesDiffer$ = this.eventTimezone$.pipe(
            filter(Boolean),
            map((eventTimezone) => {
                const localTimeOffset = DateTime.now().offset;
                const eventTimeOffset = DateTime.now().setZone(eventTimezone).offset;

                return localTimeOffset !== eventTimeOffset;
            })
        );

        this.activeTimezone$ = combineLatest({
            eventTimezone: this.eventTimezone$,
            mode: this.timezoneModeSubject$,
            eventAndLocalTimezonesDiffer: this.eventAndLocalTimezonesDiffer$
        }).pipe(
            map(({ eventTimezone, mode, eventAndLocalTimezonesDiffer }) => {
                if (mode === TimezoneMode.Local || !eventAndLocalTimezonesDiffer) {
                    return this.localTimezone;
                }

                return eventTimezone;
            })
        );
    }

    public setInitialTimezone(timezone: TimezoneMode): void {
        this.timezoneModeSubject$.next(timezone);
    }

    public setTimezone(timezone: TimezoneMode): void {
        this.timezoneModeSubject$.next(timezone);

        this.authFacadeService.dispatch(
            AuthenticationActions.patchAuthenticatedPerson({ patch: { timezone_preference: timezone } })
        );

        this.activeTimezone$.pipe(take(1)).subscribe((activeTimezone) => {
            this.toastService.notify(
                timezone === TimezoneMode.Event ? 'TIMEZONE_TOAST_EVENT' : 'TIMEZONE_TOAST_LOCAL',
                {
                    translateParams: { timezone: activeTimezone },
                    title: 'TIMEZONE_TOAST_TITLE'
                }
            );
        });
    }
}
