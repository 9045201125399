import {
    Component,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import { People as Person, SocialInfo } from '@shared/api';
import { BusinessCardBackgroundPath } from '@shared/business-cards/constants/business-cards-background-path.constant';
import { BusinessCardBackground } from '@shared/business-cards/enums/business-card-background.enum';
import { BusinessCardDesign } from '@shared/business-cards/interfaces/business-card-design.interface';

@Component({
    selector: 'cc-business-card',
    templateUrl: './business-card.component.html',
    styleUrls: ['./business-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BusinessCardComponent implements OnChanges {
    @Input()
    public design: BusinessCardDesign;

    @Input()
    public person: Person;

    @Input()
    public socialInfo: Array<SocialInfo>;

    @Input()
    public stacked: boolean;

    @Output()
    public socialClicked: EventEmitter<SocialInfo> = new EventEmitter<SocialInfo>();

    public background: string;
    public color: string;

    public ngOnChanges(changes: SimpleChanges): void {
        if (!changes.design) {
            return;
        }

        this.background = this.getBackground();
        this.color = this.getColor();
    }

    public emitSocialClicked(socialInfo: SocialInfo): void {
        this.socialClicked.emit(socialInfo);
    }

    private getBackground(): string {
        if (this.design.custom_card_color) {
            return this.design.custom_card_color;
        }

        let background = !!this.design.custom_background ? this.design.custom_background : this.design.background;

        // If the background is in the enum then convert it to the asset path
        if (Object.values(BusinessCardBackground).includes(background as BusinessCardBackground)) {
            background = BusinessCardBackgroundPath(background as BusinessCardBackground);
        }

        return `url(${background}) center center / cover no-repeat`;
    }

    private getColor(): string {
        if (this.design.custom_text_color) {
            return this.design.custom_text_color;
        }

        return 'white';
    }
}
