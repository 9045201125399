import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { EnabledTags } from './interfaces/enabled-tags.interface';
import { CookieNoticeService } from './services/cookie-notice.service';
import { NavigationFacadeService } from '@core/navigation/services/navigation-facade.service';
import { NavigationActions } from '@core/navigation/store/actions/navigation.actions';

@Component({
    selector: 'cc-cookie-notice-dialog-dialog',
    templateUrl: './cookie-notice-dialog.component.html',
    styleUrls: ['./cookie-notice-dialog.component.scss']
})
export class CookieNoticeDialogComponent implements OnInit {
    public enabledTags$: Observable<EnabledTags>;
    public showClientSection$: Observable<boolean>;
    constructor(
        private modalController: ModalController,
        private cookieNoticeService: CookieNoticeService,
        private navigationFacadeService: NavigationFacadeService
    ) {}

    public ngOnInit(): void {
        this.enabledTags$ = this.cookieNoticeService.getEnabledTags();
        this.showClientSection$ = this.enabledTags$.pipe(
            map((enabled) => enabled.ga || enabled.gtag || enabled.hotjar)
        );
    }

    public showTermsAndConditions(): void {
        this.navigationFacadeService.dispatch(NavigationActions.openTermsAndConditions());
    }

    public close(): void {
        this.modalController.dismiss();
    }
}
