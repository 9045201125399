import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AttendeesEndpoints } from '@shared/agenda/constants/attendees-endpoints.constant';
import { SlimPeople } from '@shared/api';
import { PaginatedResponse } from '@shared/pagination/models/paginated-response.model';
import { PaginationState } from '@shared/pagination/models/pagination-state.model';
import { ApiSettings } from '@shared/settings/api-settings.constant';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AttendeeApiService {
    constructor(private httpClient: HttpClient) {}

    public getSessionAttendees(options: {
        appName: string;
        moduleId: number;
        sessionId: number;
        query: PaginationState;
    }): Observable<PaginatedResponse<SlimPeople>> {
        const url = ApiSettings.fullUrl(
            options.appName,
            AttendeesEndpoints.attendees({
                moduleId: options.moduleId.toString(),
                sessionId: options.sessionId.toString()
            })
        );

        return this.httpClient.get<PaginatedResponse<SlimPeople>>(url, {
            params: {
                limit: options.query.limit,
                offset: options.query.limit * (options.query.page - 1)
            }
        });
    }
}
