import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { App, People } from '@shared/api';
import { PrivacySettings } from '../../models/privacy-settings.model';

@Component({
    selector: 'cc-privacy-settings-dialog',
    templateUrl: './privacy-settings-dialog.component.html',
    styleUrls: ['./privacy-settings-dialog.component.scss']
})
export class PrivacySettingsDialogComponent implements OnInit {
    @Input()
    app: App;

    @Input()
    loggedInUser: People;

    @Input()
    useDefaults: boolean = false;

    privacySettingsForm: UntypedFormGroup;
    items: { control: string; props: PrivacyItemProps; show: boolean }[] = [];

    constructor(
        private formBuilder: UntypedFormBuilder,
        private modalController: ModalController
    ) {}

    ngOnInit(): void {
        this.createForm(this.app, this.loggedInUser);
    }

    createForm(app: App, _: People): void {
        const values = {
            profileVisible: this.useDefaults ? true : this.loggedInUser.show_in_people_modules,
            chatEnabled: this.useDefaults ? true : this.loggedInUser.chat_enabled,
            acceptsVideoCalls: this.useDefaults ? true : this.loggedInUser.accepts_video_calls,
            meetingBookingEnabled: this.useDefaults ? true : this.loggedInUser.meeting_booking_enabled,
            businessCardsEnabled: this.useDefaults ? true : this.loggedInUser.business_cards_enabled
        };

        const controls = {};
        Object.entries(values).forEach(([key, defaultValue]) => {
            // Form control
            controls[key] = this.formBuilder.control(defaultValue);
            // Item to be used in template
            this.items.push({ control: key, props: this.getPropsForSetting(key), show: this.showControl(key, app) });
        });
        this.privacySettingsForm = this.formBuilder.group(controls);
    }

    onSubmit(): void {
        const response = this.privacySettingsForm.value as PrivacySettings;
        this.modalController.dismiss(response);
    }

    getPropsForSetting(setting: string): PrivacyItemProps {
        const settingsDictionary: Record<keyof PrivacySettings, PrivacyItemProps> = {
            acceptsVideoCalls: {
                label: 'PRIVACY_SETTINGS_DIALOG_LABEL_AVAILABLE_FOR_VIDEO_MEETINGS',
                default: 'Available for Video Meetings',
                sublabel: 'PRIVACY_SETTINGS_DIALOG_SUBLABEL_AVAILABLE_FOR_VIDEO_MEETINGS'
            },
            chatEnabled: {
                label: 'PRIVACY_SETTINGS_DIALOG_LABEL_CHAT',
                default: 'Chat Messaging',
                sublabel: 'PRIVACY_SETTINGS_DIALOG_SUBLABEL_CHAT'
            },
            profileVisible: {
                label: 'PRIVACY_SETTINGS_DIALOG_LABEL_PROFILE_VISIBLE',
                default: 'Profile Visible in App',
                sublabel: 'PRIVACY_SETTINGS_DIALOG_SUBLABEL_PROFILE_VISIBLE'
            },
            meetingBookingEnabled: {
                label: 'PRIVACY_SETTINGS_DIALOG_LABEL_MEETING_BOOKING',
                default: 'Available for Meeting Booking',
                sublabel: 'PRIVACY_SETTINGS_DIALOG_SUBLABEL_MEETING_BOOKING'
            },
            businessCardsEnabled: {
                label: 'PRIVACY_SETTINGS_DIALOG_LABEL_BUSINESS_CARDS',
                default: 'Available for Business Card exchanges',
                sublabel: 'PRIVACY_SETTINGS_DIALOG_SUBLABEL_BUSINESS_CARDS'
            }
        };

        return settingsDictionary[setting];
    }

    showControl(key: string, app: App): boolean {
        switch (key) {
            case 'chatEnabled':
                return app.can_chat;
            case 'acceptsVideoCalls':
                return app.enable_delegate_video_calls;
            case 'meetingBookingEnabled':
                return app.mbookings_enabled || this.loggedInUser?.meeting_booking_allowed;
            case 'businessCardsEnabled':
                return app.business_cards_enabled;
        }
        return true;
    }
}

interface PrivacyItemProps {
    label: string;
    default: string;
    sublabel?: string;
}
