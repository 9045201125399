import { Injectable } from '@angular/core';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { LoopinExternalLinks } from '@features/loopin/constants/loopin-external-links.constant';
import { ToastService } from '@core/toast/services/toast/toast.service';
import { switchMap, take } from 'rxjs/operators';
import { LoopinApiService } from '../loopin-api/loopin-api.service';
import { UrlUtilityService } from '@shared/utilities/url-utility.service';

@Injectable({
    providedIn: 'root'
})
export class LoopinService {
    constructor(
        private appCoreFacade: AppCoreFacadeService,
        private loopinApiService: LoopinApiService,
        private toastService: ToastService,
        private urlUtilityService: UrlUtilityService
    ) {}

    public generateLinkAndOpenRoom(roomId: number): void {
        this.appCoreFacade
            .getAppName()
            .pipe(
                take(1),
                switchMap((appName: string) => this.loopinApiService.getTokenDetails(appName, roomId))
            )
            .subscribe({
                next: (details) => {
                    const url = LoopinExternalLinks.loopinRoom(details);
                    this.urlUtilityService.open(url);
                },
                error: (error) => this.toastService.error(error.error.detail)
            });
    }
}
