import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { fadeIn, fadeOut } from '@common/animations/animations';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AttendeePaginationStore } from '@shared/agenda/services/attendee-pagination-store/attendee-pagination-store.service';
import { SlimPeople as SlimPerson } from '@shared/api';
import { PaginatedResponse } from '@shared/pagination/models/paginated-response.model';
import { IntersectionStatus } from '@shared/utilities/from-intersection-observer';
import { Observable, ReplaySubject, filter, take, BehaviorSubject, debounceTime } from 'rxjs';

@UntilDestroy()
@Component({
    selector: 'cc-agenda-card-attendees-list',
    templateUrl: './agenda-card-attendees-list.component.html',
    styleUrl: './agenda-card-attendees-list.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [AttendeePaginationStore],
    animations: [fadeIn(), fadeOut()]
})
export class AgendaCardAttendeesListComponent implements OnInit {
    @ViewChild('attendeesList', { static: false })
    public element: ElementRef;

    @Input({ required: true })
    public moduleId: number;

    @Input({ required: true })
    public sessionId: number;

    public resource$: Observable<PaginatedResponse<SlimPerson>>;
    public isLoading$: Observable<boolean>;
    public hasReachedEnd$: Observable<boolean>;

    public hasOverflowed$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public canScrollForwards$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public canScrollBackwards$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    private scrollAmount: number = 45 * 5;
    private visibilityState$: ReplaySubject<IntersectionStatus> = new ReplaySubject(1);

    constructor(private attendeePaginationStore: AttendeePaginationStore) {}

    public trackByFn = (_: number, attendee: SlimPerson): number => attendee.id;

    public ngOnInit(): void {
        this.resource$ = this.attendeePaginationStore.resource$;
        this.isLoading$ = this.attendeePaginationStore.isLoading$;
        this.hasReachedEnd$ = this.attendeePaginationStore.hasReachedEnd$;

        this.initialiseWhenFirstVisible();
        this.updateScrollButtonsOnAttendeeChanges();
    }

    public scrollForwards(event: Event): void {
        if (!this.element) {
            return;
        }

        event.stopPropagation();
        this.element.nativeElement.scrollLeft += this.scrollAmount;
    }

    public scrollBackwards(event: Event): void {
        if (!this.element) {
            return;
        }

        event.stopPropagation();
        this.element.nativeElement.scrollLeft -= this.scrollAmount;
    }

    public onVisibilityChanged(state: IntersectionStatus): void {
        this.visibilityState$.next(state);
    }

    public onScroll(): void {
        this.updateScrollButtons();
    }

    public fetchNextPage(): void {
        this.attendeePaginationStore.nextPage();
    }

    public preventEventPropagation(event: Event): void {
        event.stopPropagation();
    }

    private updateScrollButtons(): void {
        if (!this.element) {
            return;
        }
        const scrollableArea = this.element.nativeElement.scrollWidth - this.element.nativeElement.clientWidth;
        this.hasOverflowed$.next(scrollableArea > 0);
        const tolerance = 5;
        this.canScrollForwards$.next(scrollableArea - this.element.nativeElement.scrollLeft > tolerance);
        this.canScrollBackwards$.next(this.element.nativeElement.scrollLeft > tolerance);
    }

    private initialiseWhenFirstVisible(): void {
        this.visibilityState$
            .pipe(
                filter((state) => state === IntersectionStatus.Visible),
                take(1),
                untilDestroyed(this)
            )
            .subscribe(() => {
                this.attendeePaginationStore.initialise({
                    page: 1,
                    limit: 20,
                    sessionId: this.sessionId,
                    moduleId: this.moduleId
                });
                this.attendeePaginationStore.listenForAttendeeChanges();
            });
    }

    private updateScrollButtonsOnAttendeeChanges(): void {
        this.resource$.pipe(untilDestroyed(this), debounceTime(50)).subscribe(() => this.updateScrollButtons());
    }
}
