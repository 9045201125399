<div
    *ngIf="{
        isOnline: (isOnline$ | async)
    } as indicatorData"
    [ngClass]="{ 'indicator-only': indicatorOnly }"
    class="online-status"
>
    <div class="online-status__indicator" [ngClass]="getIndicatorClass(indicatorData.isOnline)"></div>
    <span *ngIf="!indicatorOnly" class="online-status__text">
        {{ (indicatorData.isOnline ? 'PEOPLE_ONLINE' : 'PEOPLE_OFFLINE') | translate }}
    </span>
</div>
