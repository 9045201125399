import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppCommonModule } from '@common/common.module';
import { DateFormatPipe } from '@common/pipes/date-format/date-format.pipe';
import { FixedTimezoneTooltipComponent } from './component/fixed-timezone-tooltip/fixed-timezone-tooltip.component';
import { TimezoneTooltipComponent } from './components/timezone-tooltip/timezone-tooltip.component';
import { LocalisedTimezonePipe } from './pipes/localised-timezone.pipe';

@NgModule({
    declarations: [LocalisedTimezonePipe, TimezoneTooltipComponent, FixedTimezoneTooltipComponent],
    exports: [LocalisedTimezonePipe, TimezoneTooltipComponent, FixedTimezoneTooltipComponent],
    imports: [AppCommonModule],
    providers: [DatePipe, DateFormatPipe]
})
export class LocaleModule {}
