import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AppTag, VisualAppTag } from '@shared/tags/interfaces/app-tag.interface';

@Component({
    selector: 'cc-tag-list',
    templateUrl: './tag-list.component.html',
    styleUrls: ['./tag-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagListComponent {
    @Input()
    public tags: Array<AppTag | VisualAppTag>;
}
