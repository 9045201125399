<div class="action-popover__text">
    <span class="action-popover__header">{{ actionPopoverData.header | translate }}</span>
    <span class="action-popover__content">{{ actionContent }}</span>
</div>

<ion-button
    class="action-popover__action ion-no-margin"
    fill="clear"
    expand="full"
    color="default"
    [attr.aria-label]="'ACTION_POPOVER_COPY_HINT' | translate"
    [title]="'ACTION_POPOVER_COPY_HINT' | translate"
    (click)="copy()"
>
    <i class="material-icons" aria-hidden="true">content_copy</i>
</ion-button>

<ion-button
    class="action-popover__action ion-no-margin"
    fill="clear"
    expand="full"
    color="default"
    [attr.aria-label]="actionPopoverData.hint | translate"
    [title]="actionPopoverData.hint | translate"
    (click)="action()"
>
    <i class="material-icons" aria-hidden="true">{{ actionPopoverData.icon }}</i>
</ion-button>

<ion-button
    class="action-popover__action ion-no-margin"
    fill="clear"
    expand="full"
    color="default"
    [attr.aria-label]="'ACTION_POPOVER_CLOSE_HINT' | translate"
    [title]="'ACTION_POPOVER_CLOSE_HINT' | translate"
    (click)="close()"
>
    <i class="material-icons" aria-hidden="true">close</i>
</ion-button>
