import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ChatRouteParams } from './constants/chat-route-params.constant';
import { ChatPageComponent } from './pages/chat-page/chat-page.component';
import { CustomRoute } from '@core/routing/types/custom-route';
import { chatPagePopupGuard } from '@features/chat/guards/chat-page-popup-guard/chat-page-popup.guard';

const routes: CustomRoute[] = [
    {
        path: `:${ChatRouteParams.chatId}`,
        component: ChatPageComponent,
        canActivate: [chatPagePopupGuard()]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ChatRoutingModule {}
