<div class="poll-controls">
    <div class="poll-controls__previous">
        <ion-button
            *ngIf="showPreviousButton"
            color="accent"
            expand="block"
            [attr.aria-label]="'POLL_PREV_QUESTION' | translate"
            (click)="onPollPrevious()"
        >
            <i class="material-icons" slot="icon-only" aria-hidden="true">chevron_left</i>
        </ion-button>
    </div>

    <div class="poll-controls__submit">
        <ion-button *ngIf="closed" expand="block" disabled>
            {{ 'LIVE_POLL_CLOSED' | translate }}
        </ion-button>

        <ion-button *ngIf="!closed && submitting" expand="block" disabled>
            {{ 'SENDING' | translate }}
        </ion-button>

        <ion-button *ngIf="!closed && !submitting" expand="block" (click)="onPollSubmit()" [disabled]="disabled">
            {{ submitButtonText | translate }}
        </ion-button>
    </div>

    <div class="poll-controls__next">
        <ion-button
            *ngIf="showNextButton"
            color="accent"
            expand="block"
            [attr.aria-label]="'POLL_NEXT_QUESTION' | translate"
            (click)="onPollNext()"
        >
            <i class="material-icons" slot="icon-only" aria-hidden="true">chevron_right</i>
        </ion-button>
    </div>
</div>
