import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { NavigationHistoryActions } from '../actions/navigation-history.actions';
import { debounceTime, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { NavigationHistoryFacadeService } from '../../services/navigation-history-facade.service';
import { AnalyticsService } from '../../../analytics/services/analytics.service';
import { ActivatedRoute, Router } from '@angular/router';
import { routerNavigatedAction } from '@ngrx/router-store';
import { excludedHistoryRoutes } from '@core/navigation-history/constants/excluded-history-routes.constant';

@Injectable()
export class NavigationHistoryEffects {
    listenToRouterEvents$ = createEffect(() =>
        this.actions$.pipe(
            ofType(routerNavigatedAction),
            debounceTime(300),
            filter(({ payload }) => {
                const urlWithoutParams = payload.routerState.url.split('?')[0];
                const isExcluded = excludedHistoryRoutes.some((x) => new RegExp(x).test(urlWithoutParams));
                return !isExcluded;
            }),
            map(() => NavigationHistoryActions.stamp())
        )
    );

    stamp$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NavigationHistoryActions.stamp),
            withLatestFrom(this.navigationHistoryFacadeService.getCurrentState()),
            tap(() => this.analyticsService.setItemsFromNavigation(this.route.snapshot.params)),
            map(([, latest]) => {
                const [url, params] = this.router.url.split('?');

                if (latest && latest.url === url) {
                    return NavigationHistoryActions.updateSuccess({ item: { url, params } });
                }

                return NavigationHistoryActions.stampSuccess({ item: { url, params } });
            })
        )
    );

    restore$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(NavigationHistoryActions.back),
                switchMap(() => this.navigationHistoryFacadeService.getCurrentState().pipe(take(1))),
                filter((state) => !!state),
                tap(({ url, params }) => {
                    let urlToNavigateTo = url;

                    if (params) {
                        urlToNavigateTo += '?' + params;
                    }

                    this.router.navigateByUrl(urlToNavigateTo);
                })
            ),
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private navigationHistoryFacadeService: NavigationHistoryFacadeService,
        private analyticsService: AnalyticsService,
        private router: Router,
        private route: ActivatedRoute
    ) {}
}
