export interface MenuSettings {
    menu_style: NavigationStyleEnum;
    tab_menu_items: Array<number>;
    default_sidebar_collapsed: boolean;
    show_collapsed_labels: boolean;
    show_tab_bar_labels: boolean;
}

export enum NavigationStyleEnum {
    Sidebar = 'sidebar',
    Tabbar = 'tabs'
}
