export interface NativeAppSettings {
    id?: number;
    name?: string;
    short_desc?: string;
    long_desc?: string;
    keywords?: string;
    ios_status?: NativeAppSettings.IosStatusEnum;
    android_status?: NativeAppSettings.AndroidStatusEnum;
    push_notifications_meeting_invites?: boolean;
    app: number;
    icon?: number;
    splash?: number;
    banner?: number;
}

export namespace NativeAppSettings {
    export enum IosStatusEnum {
        Idle = 'idle',
        Pending = 'pending',
        Submitted = 'submitted',
        InStore = 'in_store'
    }
    export enum AndroidStatusEnum {
        Idle = 'idle',
        Pending = 'pending',
        Submitted = 'submitted',
        InStore = 'in_store'
    }
}
