<div
    *ngIf="{
        modules: modules$ | async,
        initialising: initialising$ | async,
        app: app$ | async,
        collapsed: collapsed$ | async,
        selectedModule: selectedModule$ | async,
        portalConfig: portalConfig$ | async,
        poweredByLogoShade: poweredByLogoShade$ | async
    } as sidebarLeftData"
    class="sidebar-left-container"
>
    <ng-container *ngIf="sidebarLeftData.initialising">
        <ng-container *ngTemplateOutlet="loading"></ng-container>
    </ng-container>
    <ng-container *ngIf="!sidebarLeftData.initialising">
        <cc-sidebar-left-list
            *ngIf="!sidebarLeftData.collapsed"
            [modules]="sidebarLeftData.modules"
            [portalConfig]="sidebarLeftData.portalConfig"
            [selectedModule]="sidebarLeftData.selectedModule"
            [theme]="sidebarLeftData.app?.theme"
            [showPoweredByLogo]="sidebarLeftData.app?.show_powered_by_logo"
            [poweredByLogoShade]="sidebarLeftData.poweredByLogoShade"
            (moduleClick)="onModuleClick()"
            (appBannerClick)="onAppBannerClick()"
            (openTosDialog)="showTosDialog()"
            (portalReturn)="portalReturnClick()"
            (toggleCollapse)="onToggleCollapse()"
        >
        </cc-sidebar-left-list>
        <cc-sidebar-left-icons
            *ngIf="sidebarLeftData.collapsed"
            [modules]="sidebarLeftData.modules"
            [portalConfig]="sidebarLeftData.portalConfig"
            [selectedModule]="sidebarLeftData.selectedModule"
            [theme]="sidebarLeftData.app?.theme"
            [showLabels]="sidebarLeftData.app?.menu_settings?.show_collapsed_labels"
            (moduleClick)="onModuleClick()"
            (appIconClick)="onAppBannerClick()"
            (portalReturn)="portalReturnClick()"
            (toggleCollapse)="onToggleCollapse()"
        >
        </cc-sidebar-left-icons>
    </ng-container>
</div>

<ng-template #loading>
    <cc-loading-spinner></cc-loading-spinner>
</ng-template>
