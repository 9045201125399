export const chatEmojis = [
    { regex: /(\>\:\()/g, replacement: '😠' },
    { regex: /(\:\))/g, replacement: '🙂' },
    { regex: /(\:\-\))/g, replacement: '🙂' },
    { regex: /(\:\()/g, replacement: '🙁' },
    { regex: /(\:\-\()/g, replacement: '🙁' },
    { regex: /(\:D)/gi, replacement: '😄' },
    { regex: /(\:\-D)/gi, replacement: '😄' },
    { regex: /(\;\))/g, replacement: '😉' },
    { regex: /(\;\-\))/g, replacement: '😉' },
    { regex: /(\:P)/gi, replacement: '😛' },
    { regex: /(\:O)/gi, replacement: '😮' },
    { regex: /(\:\'\))/g, replacement: '😂' },
    { regex: /(<3)/g, replacement: '❤️' }
];
