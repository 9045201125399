import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { Auth, authState, signInWithCustomToken, signOut, user, UserCredential } from '@angular/fire/auth';
import { Database, DatabaseReference, objectVal, ref, serverTimestamp, set } from '@angular/fire/database';

@Injectable({
    providedIn: 'root'
})
export class FirebaseService {
    constructor(
        private db: Database,
        private auth: Auth
    ) {}

    getPath = (deviceId: string): string => `${deviceId}`;

    async signInWithToken(token: string): Promise<UserCredential> {
        return signInWithCustomToken(this.auth, token);
    }

    async signOut(): Promise<void> {
        // If they never signed in, don't sign them out
        if (!user) {
            return;
        }
        return signOut(this.auth);
    }

    updatePresence(deviceId: string): Promise<void> {
        return set(this.getRef(deviceId), serverTimestamp());
    }

    isOnline(deviceIds: string[] = []): Observable<boolean> {
        return authState(this.auth).pipe(
            filter((authedUser) => !!authedUser),
            // Watch presences of all device ids and return true if any are online
            switchMap(() => combineLatest(deviceIds.map((deviceId) => this.getPresence(deviceId)))),
            map((statuses: boolean[]) => statuses.includes(true))
        );
    }

    getPresence(deviceId: string): Observable<boolean> {
        return objectVal<number>(this.getRef(deviceId)).pipe(map((timestamp) => !!timestamp));
    }

    private getRef(deviceId: string): DatabaseReference {
        return ref(this.db, this.getPath(deviceId));
    }
}
