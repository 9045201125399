/**
 * Routes scoped to a page within a module of an app. /{appName}/modules/*
 */
export const AppModuleRoutes = {
    agenda: 'agenda',
    challenge: 'gamification',
    companies: 'companies',
    document: 'document',
    feed: 'feed',
    html: 'html',
    iframe: 'iframe',
    portal: 'portal',
    infoPage: 'info-page',
    infobooth: 'infobooth' /* Old URL for infoPage - still used by custom HTML templates */,
    livepolling: 'livepolling',
    livestream: 'live-stream',
    map: 'map',
    people: 'people',
    qanda: 'qanda',
    socialmedia: 'social-media',
    survey: 'survey',
    widget: 'widget',
    loopin: 'loopin',
    videolibrary: 'videolibrary',
    abstracts: 'abstracts'
};
