import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Poll, TextPoll } from '@shared/api';

@Component({
    selector: 'cc-text-poll',
    templateUrl: './text-poll.component.html',
    styleUrls: ['./text-poll.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextPollComponent {
    @Input()
    public poll: Poll;

    @Input()
    public textPoll: TextPoll;

    @Input()
    public closed: boolean;

    @Input()
    public set initialValue(initialValue: { value: any }) {
        this.textInputValue = initialValue.value;
    }

    @Input()
    public showPreviousButton: boolean;

    @Input()
    public showNextButton: boolean;

    @Input()
    public submitting: boolean;

    @Input()
    public submitButtonText: string = 'SUBMIT';

    @Output()
    public pollSubmit = new EventEmitter<string>();

    @Output()
    public pollNext = new EventEmitter<void>();

    @Output()
    public pollPrevious = new EventEmitter<void>();

    textInputValue: string;

    onPollSubmit(): void {
        this.pollSubmit.emit(this.textInputValue);
    }

    onPollNext(): void {
        this.pollNext.emit();
    }

    onPollPrevious(): void {
        this.pollPrevious.emit();
    }

    onTextInputChange($event): void {
        this.textInputValue = $event.target.value;
    }

    formIsValid(): boolean {
        return this.textInputValue && this.textPoll && this.textInputValue.length <= this.textPoll.character_limit;
    }

    getTextInputLength(): number {
        return this.textInputValue ? this.textInputValue.length : 0;
    }
}
