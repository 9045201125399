<cc-page @fadeIn>
    <ng-container
        *ngIf="{
            isAuthenticated: isAuthenticated$ | async,
            currentTab: currentTab$ | async,
            paginationResponse: paginationResponse$ | async,
            loading: loading$ | async
        } as pageData"
    >
        <cc-search-bar *ngIf="searchInput" [search]="searchInput">
            <div *ngIf="pageData.isAuthenticated" class="tabs">
                <ion-tab-bar [selectedTab]="pageData.currentTab" class="ion-toolbar-indicated">
                    <ion-tab-button [tab]="tabEnum.Current" (click)="onChangeTab(tabEnum.Current)">
                        <ion-label>
                            <span>
                                {{ 'ALERTS_MY_ALERTS_TAB' | translate }}
                            </span>
                        </ion-label>
                    </ion-tab-button>

                    <ion-tab-button [tab]="tabEnum.Archived" (click)="onChangeTab(tabEnum.Archived)">
                        <ion-label>
                            <span>
                                {{ 'ALERTS_ARCHIVED_ALERTS_TAB' | translate }}
                            </span>
                        </ion-label>
                    </ion-tab-button>
                </ion-tab-bar>
            </div>
        </cc-search-bar>

        <cc-alert-list-filter-panel
            [disabled]="pageData.loading"
            [currentItems]="pageData.paginationResponse"
            (setFilter)="updateQuery($event)"
            (archiveAll)="archiveAll()"
            (markAllRead)="markAllRead()"
        >
        </cc-alert-list-filter-panel>

        <ion-content ccIonicScrollbar>
            <div class="alerts__container">
                <ng-container *ngIf="pageData.paginationResponse">
                    <ng-container *ngIf="!pageData.paginationResponse.length && !pageData.loading">
                        <cc-no-data class="alerts__no-data none-sent" title="ALERTS_NO_ALERTS"></cc-no-data>
                    </ng-container>

                    <ng-container *ngIf="pageData.paginationResponse.length">
                        <div class="alerts__list" [attr.aria-label]="'ALERTS_ARIA_LABEL_LIST' | translate">
                            <ng-container *ngFor="let notification of pageData.paginationResponse; trackBy: trackByFn">
                                <cc-alert-list-item
                                    *ngIf="notification.alert; else notificationTemplate"
                                    [alert]="notification.alert"
                                    [notification]="notification"
                                    (unarchive)="unarchiveNotification(notification.id)"
                                    (markAsRead)="markNotificationAsRead(notification.id)"
                                    (archive)="archiveNotification(notification)"
                                >
                                </cc-alert-list-item>

                                <ng-template #notificationTemplate>
                                    <cc-notification-list-item
                                        [notification]="notification"
                                        (actionClicked)="onActionClicked(notification)"
                                        (archive)="archiveNotification(notification)"
                                        (markAsRead)="markNotificationAsRead(notification.id)"
                                        (unarchive)="unarchiveNotification(notification.id)"
                                    >
                                    </cc-notification-list-item>
                                </ng-template>
                            </ng-container>

                            <ion-infinite-scroll
                                threshold="100px"
                                [disabled]="hasReachedEnd$ | async"
                                (ionInfinite)="nextPage($event)"
                            >
                                <ion-infinite-scroll-content></ion-infinite-scroll-content>
                            </ion-infinite-scroll>
                        </div>
                    </ng-container>
                </ng-container>

                <cc-loading-spinner *ngIf="pageData.loading" class="alerts__loading-spinner"></cc-loading-spinner>
            </div>
        </ion-content>
    </ng-container>
</cc-page>
