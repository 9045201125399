import { createSelector } from '@ngrx/store';
import { AppDocument, People, Session, Track } from '@shared/api';

import { AppState } from '../../../../../core/root-store/store/app-state.model';
import { AgendaAppState, agendaStoreKey } from '../../../store/models/agenda.state.model';
import { ExpandedParticipantGroup, ExpandedSession } from '../../models/expanded-session.model';
import { SessionLocation } from '@features/agenda/models/session-location.model';

const selectAgendaState = (state: AgendaAppState) => state[agendaStoreKey];
const selectSessionState = createSelector(selectAgendaState, (state) => state.sessionPage);

const selectFromSession = <T>(property: keyof ExpandedSession) =>
    createSelector<AppState, [any], T>(selectSessionState, (state) => {
        if (!state?.session) {
            return;
        }
        return state.session[property];
    });

const selectCurrentTab = createSelector(selectSessionState, (state) => {
    if (!state) {
        return;
    }
    return state.currentTab;
});

const selectHasContent = createSelector(selectSessionState, (state) => {
    if (!state || !state.session) {
        return;
    }
    return (
        !!state.session.livestream ||
        !!state.session.video ||
        !!state.session.zoom_meeting ||
        !!state.session.zoom_webinar ||
        (!!state.session.video_call && state.session.video_call_display === Session.VideoCallDisplayEnum.Embedded)
    );
});

const selectLoopinRoomId = createSelector(selectSessionState, (state) => {
    if (!state || !state.session) {
        return;
    }

    return state.session.loopin_room;
});

const selectTitle = createSelector(selectSessionState, (state) => {
    return state?.session?.title;
});

const selectEmbeddedVideoCallUrl = createSelector(selectSessionState, (state) => {
    return state?.embeddedVideoCallUrl;
});

export const AgendaSessionPageSelectors = {
    initialising: createSelector(selectSessionState, (state) => state?.initialising),
    session: createSelector(selectSessionState, (state) => (state ? state.session : undefined)),
    module: createSelector(selectSessionState, (state) => (state ? state.module : undefined)),
    confirmNavigation: createSelector(selectSessionState, (state) => state?.confirmNavigation),
    locations: selectFromSession<SessionLocation[]>('location'),
    tracks: selectFromSession<Track[]>('tracks'),
    documents: selectFromSession<AppDocument[]>('documents'),
    speakers: selectFromSession<People[]>('speakers'),
    participantGroups: selectFromSession<ExpandedParticipantGroup[]>('participant_groups'),
    currentTab: selectCurrentTab,
    hasContent: selectHasContent,
    tabNotifications: createSelector(selectSessionState, (state) => state?.tabNotifications),
    playbackTokens: createSelector(selectSessionState, (state) => state?.playbackTokens),
    playbackError: createSelector(selectSessionState, (state) => state?.playbackError),
    loopinRoomId: selectLoopinRoomId,
    title: selectTitle,
    embeddedVideoCallUrl: selectEmbeddedVideoCallUrl
};
