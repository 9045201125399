import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

/*
 * Directive to allow setting of css variables which isn't possible in ngStyle
 * https://github.com/angular/angular/issues/9343
 */

@Directive({
    selector: '[ccCssProps]'
})
export class CssPropsDirective implements OnChanges {
    @Input('ccCssProps')
    cssProps: Record<string, unknown>;

    constructor(private element: ElementRef) {}

    ngOnChanges({ cssProps }: SimpleChanges): void {
        if (cssProps && cssProps.currentValue) {
            Object.entries(cssProps.currentValue).forEach(([property, value]) =>
                this.element.nativeElement.style.setProperty(property, value)
            );
        }
    }
}
