import { AppState } from '@core/root-store/store/app-state.model';
import { NavigationItemState } from '../../models/navigation-item-state.model';

export const navigationHistoryStoreKey = 'navigationHistory';

export interface NavigationHistoryState {
    history: NavigationItemState[];
}

export interface NavigationHistoryAppState extends AppState {
    [navigationHistoryStoreKey]: NavigationHistoryState;
}
