import { MableCustomField, MableCustomFieldGroup, MableCustomValue } from '@shared/api';
import { orderBy, uniqBy } from 'lodash';

export const convertCustomValuesToFields = (
    customValues: MableCustomValue[],
    isOwnProfile: boolean
): (MableCustomField | MableCustomFieldGroup)[] => {
    // Convert from value holding field -> field holding value
    const allFields: any[] = customValues
        .filter((value) => filterFieldByDisplay(value.custom_field as MableCustomField, isOwnProfile))
        .map((customValue) => {
            const { custom_field, ...childValue } = customValue;
            return {
                ...(custom_field as MableCustomField),
                childValue
            };
        });

    // Custom fields are fields without groups
    const customFields: MableCustomField[] = allFields.filter((field) => !field.group);

    // List of field groups unique by their id
    const customFieldGroups = uniqBy<MableCustomFieldGroup>(
        customValues
            .filter((customValue) => !!(customValue.custom_field as MableCustomField).group)
            .map((customValue) => (customValue.custom_field as MableCustomField).group as MableCustomFieldGroup),
        (customFieldGroup) => customFieldGroup.id
    );

    // Populate field groups with fields which belong in group
    const populatedCustomFieldGroups: MableCustomFieldGroup[] = customFieldGroups.map((customFieldGroup) => {
        const childFields = orderBy(
            allFields.filter((field) => field.group && field.group.id === customFieldGroup.id),
            'order'
        );
        return {
            ...customFieldGroup,
            childFields
        } as any;
    });

    const result = [...customFields, ...populatedCustomFieldGroups];

    return orderBy(result, 'order');
};

const filterFieldByDisplay = (field: { display?: MableCustomField.DisplayEnum }, isOwnProfile: boolean): boolean => {
    if (field.display === MableCustomField.DisplayEnum.Everyone) {
        return true;
    }
    if (field.display === MableCustomField.DisplayEnum.Hidden) {
        return false;
    }
    return isOwnProfile;
};
