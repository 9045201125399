export interface AppLanguage {
    id?: number;
    title?: string;
    language_code: AppLanguage.LanguageCodeEnum;
    order?: number;
    app: number;
}

export namespace AppLanguage {
    export enum LanguageCodeEnum {
        En = 'en',
        Fr = 'fr',
        De = 'de',
        Pl = 'pl',
        Pt = 'pt',
        Es = 'es',
        Ru = 'ru',
        Ar = 'ar',
        Th = 'th',
        Sv = 'sv',
        Ja = 'ja',
        Ko = 'ko',
        ZhHans = 'zh-hans',
        ZhHant = 'zh-hant'
    }
}
