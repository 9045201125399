import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiSettings } from '@shared/settings/api-settings.constant';
import { Observable } from 'rxjs';
import { AblyEndpoints } from '../constants/ably-endpoints.constant';
import { AblyTokenResponse } from '../interfaces/ably-token-response.interface';

@Injectable({
    providedIn: 'root'
})
export class AblyApiService {
    constructor(private http: HttpClient) {}

    public getAblyToken(appName: string): Observable<AblyTokenResponse> {
        const url = ApiSettings.fullUrl(appName, AblyEndpoints.ablyToken());
        return this.http.get<AblyTokenResponse>(url);
    }
}
