import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { People, PeopleModule, PeopleVisibility, SlimPeople } from '@shared/api';
import { BusinessCard } from '@shared/business-cards/interfaces/business-card.interface';
import { PaginatedResponse } from '@shared/pagination/models/paginated-response.model';
import { PaginationState } from '@shared/pagination/models/pagination-state.model';
import { ApiSettings } from '@shared/settings/api-settings.constant';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../../../core/environment/services/environment.service';
import { ExpandedPeople } from '../models/expanded-people.model';
import { PeopleSessions } from '../models/people-session.model';
import { PeopleEndpoints } from '../constants/people-endpoints.constant';
import { AppTag } from '@shared/tags/interfaces/app-tag.interface';
import { TagQuery } from '@shared/tags/interfaces/tag-query.interface';

@Injectable({
    providedIn: 'root'
})
export class PeopleApiService {
    constructor(
        private httpClient: HttpClient,
        private environmentService: EnvironmentService
    ) {}

    public getPerson(appUrl: string, personId: number | string): Observable<People> {
        const url = `${this.environmentService.API_URL}apps/${appUrl}${PeopleEndpoints.person(personId)}`;
        return this.httpClient.get<People>(url);
    }

    public getPersonPublicBusinessCard(appUrl: string, personId: number | string): Observable<BusinessCard> {
        const url = ApiSettings.fullUrl(appUrl, PeopleEndpoints.personPublicBusinessCard(personId));
        return this.httpClient.get<BusinessCard>(url);
    }

    public inviteToCall(appUrl: string, personId: number): Observable<any> {
        const apiUrl = this.environmentService.API_URL;
        const url = PeopleEndpoints.inviteToCall(appUrl, personId, apiUrl);
        return this.httpClient.post<any>(url, {});
    }

    public getAllPaginatedPeople(
        appUrl: string,
        query: PaginationState,
        params?: Record<string, string | number>
    ): Observable<PaginatedResponse<SlimPeople>> {
        return this.httpClient.get<PaginatedResponse<SlimPeople>>(
            `${this.environmentService.API_URL}apps/${appUrl}${PeopleEndpoints.paginatedPeople(query)}`,
            {
                params: {
                    ...params
                }
            }
        );
    }

    public getUncachedPaginatedPeople(
        appUrl: string,
        query: PaginationState,
        params?: Record<string, string | number>
    ): Observable<PaginatedResponse<SlimPeople>> {
        const [page, limit, search] = [query.page ?? 1, query.limit ?? 10, query.search ?? ''];
        const offset = ((page - 1) * limit).toString();

        return this.httpClient.get<PaginatedResponse<SlimPeople>>(
            `${this.environmentService.API_URL}apps/${appUrl}${PeopleEndpoints.uncachedPaginatedPeople()}`,
            {
                params: {
                    ...{ search, offset, limit },
                    ...params
                }
            }
        );
    }

    public getExpandedPerson(appName: string, personId: number): Observable<ExpandedPeople> {
        const url = ApiSettings.fullUrl(appName, PeopleEndpoints.expandedPerson(personId));
        return this.httpClient.get<ExpandedPeople>(url);
    }

    public getSocialInfo(appName: string, personId: number): Observable<any> {
        const url = ApiSettings.fullUrl(appName, PeopleEndpoints.socialInfo(personId));
        return this.httpClient.get(url);
    }

    public getVisibility(appName: string): Observable<PeopleVisibility> {
        const url = ApiSettings.fullUrl(appName, PeopleEndpoints.visibility);
        return this.httpClient.get<PeopleVisibility>(url);
    }

    public getPeopleModule(appName: string, moduleId: number): Observable<PeopleModule> {
        const url = ApiSettings.fullUrl(appName, PeopleEndpoints.peopleModule(moduleId));
        return this.httpClient.get<PeopleModule>(url);
    }

    public getPaginatedPeople(
        appName: string,
        moduleId: number,
        search: string,
        page: number,
        limit: number,
        filters?: TagQuery
    ): Observable<PaginatedResponse<People>> {
        const url = ApiSettings.fullUrl(appName, PeopleEndpoints.table(moduleId, search, page, limit));
        return this.httpClient.get<PaginatedResponse<People>>(url, { params: { ...filters } });
    }

    public getPeopleSessions(appName: string, personId: number): Observable<PeopleSessions> {
        const url = ApiSettings.fullUrl(appName, PeopleEndpoints.peopleSessions(personId));
        return this.httpClient.get<PeopleSessions>(url);
    }

    public getAutocompletePaginatedPeople(options: {
        appUrl: string;
        query: PaginationState;
        filters?: 'canChat'[];
    }): Observable<PaginatedResponse<SlimPeople>> {
        const url = ApiSettings.fullUrl(options.appUrl, PeopleEndpoints.autocompletePeople());
        return this.httpClient.get<PaginatedResponse<SlimPeople>>(url, {
            params: {
                ...options.query,
                ...(options.filters?.includes('canChat') ? { chat_enabled: true } : {})
            }
        });
    }

    public getPeopleTags(appName: string): Observable<AppTag[]> {
        const url = ApiSettings.fullUrl(appName, PeopleEndpoints.peopleTags());
        return this.httpClient.get<AppTag[]>(url);
    }
}
