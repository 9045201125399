import { ChangeDetectionStrategy, Component, ElementRef, HostListener, OnInit } from '@angular/core';

@Component({
    selector: 'cc-tag',
    templateUrl: './tag.component.html',
    styleUrls: ['./tag.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagComponent implements OnInit {
    @HostListener('click', ['$event'])
    public stopPropagation(event: MouseEvent): void {
        event.stopPropagation();
    }

    public id: number;

    constructor(private elementRef: ElementRef) {}

    public ngOnInit(): void {
        this.id = this.elementRef.nativeElement.getAttribute('data-tagged-id');
    }
}
