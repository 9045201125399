<ion-item
    [ccCssProps]="{
        '--background': session.list_row_bk_colour,
        '--color': session.list_row_text_colour
    }"
    class="agenda-card-expand-button__container ion-no-margin ion-no-padding"
>
    <ion-button
        class="agenda-card-expand-button__button ion-no-margin"
        color="accent"
        fill="clear"
        expand="full"
        (click)="onToggleExpandedClicked()"
    >
        <i *ngIf="!expanded" class="material-icons arrow-up"> keyboard_arrow_down </i>
        <i *ngIf="expanded" class="material-icons arrow-down"> keyboard_arrow_up </i>
    </ion-button>
</ion-item>
