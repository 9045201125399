export interface AppChallenge {
    order?: number;
    points_awarded?: number;
    hidden?: boolean;
    attempts_limit?: number;
    repeat_limit?: number;
    disabled?: boolean;
    id?: number;
    description?: string;
    image?: string;
    title: string;
    module: number;
    closed?: boolean;
    condition: AppChallengeCondition;
    condition_filter: string;
    options: Array<AppChallengeOption>;
}

export interface AppChallengeOption {
    id: number;
    label: string;
    order: number;
    image?: string;
}

export enum AppChallengeCondition {
    Text = 'text',
    QR = 'qr',
    Choice = 'choice',
    ChoicePollResponse = 'choice_poll_response',
    TextPollResponse = 'text_poll_response',
    RatingPollResponse = 'rating_poll_response',
    PollResponse = 'poll_response',
    ProfilePictureUploaded = 'profile_picture_uploaded',
    ActivityFeedPost = 'activity_feed_post',
    ActivityFeedUpload = 'activity_feed_upload',
    ActivityFeedComment = 'activity_feed_comment',
    AcceptBusinessCard = 'accept_business_card',
    ReturnedBusinessCard = 'returned_business_card',
    CustomizeBusinessCard = 'customize_business_card',
    QAndAQuestionCreated = 'qanda_question_created'
}
