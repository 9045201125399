import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { App, MableCustomValue, MableUser, People, UserAuthInfo, UserEmailInfo } from '@shared/api';
import { IdentityProvider } from '@shared/api/models/IdentityProvider';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { AppCoreFacadeService } from '../../app-core/services/app-core-facade.service';
import { AppCoreSelectors } from '../../root-store/store/app-core/selectors/app-core.selectors';
import { AppState } from '../../root-store/store/app-state.model';
import { AuthenticationApiActions } from '../../root-store/store/authentication/actions/authentication-api.actions';
import { AuthenticationSelectors } from '../../root-store/store/authentication/selectors/authentication.selectors';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationFacadeService {
    refreshAuthenticatedPersonSuccess$ = this.actions$.pipe(
        ofType(AuthenticationApiActions.refreshAuthenticatedPersonSuccess)
    );

    refreshAuthenticatedPersonFailure$ = this.actions$.pipe(
        ofType(AuthenticationApiActions.refreshAuthenticatedPersonFailure)
    );

    refreshDownloadTokenSuccess$ = this.actions$.pipe(ofType(AuthenticationApiActions.getDownloadTokenSuccess));
    refreshDownloadTokenFailure$ = this.actions$.pipe(ofType(AuthenticationApiActions.getDownloadTokenFailure));

    loginSuccess$ = this.actions$.pipe(ofType(AuthenticationApiActions.loginSuccess));
    loginFailure$ = this.actions$.pipe(ofType(AuthenticationApiActions.loginFailure));

    loggedInUser = toSignal(this.getAuthenticatedPerson());
    isLoggedIn = toSignal(this.isAuthenticated());

    constructor(
        private store: Store<AppState>,
        private actions$: Actions,
        private appCoreFacadeService: AppCoreFacadeService
    ) {}

    isAuthenticated(): Observable<boolean> {
        return this.store.select(AuthenticationSelectors.selectIsAuthenticated);
    }

    getUserAuthInfo(): Observable<UserAuthInfo> {
        return this.store.select(AuthenticationSelectors.selectUserAuthInfo);
    }

    getUser(): Observable<MableUser> {
        return this.store.select(AuthenticationSelectors.selectUser);
    }

    getAuthenticatedPerson(): Observable<People> {
        return this.store.select(AuthenticationSelectors.selectAuthenticatedPerson);
    }

    getAnonymousToken(): Observable<string> {
        return this.store.select(AuthenticationSelectors.selectAnonymousToken);
    }

    getAnonymousTokenIfUnauthenticated(): Observable<string> {
        return this.store.select(AuthenticationSelectors.selectAnonymousTokenIfUnauthenticated);
    }

    getPasscodeTokens(): Observable<{ [key: string]: boolean }> {
        return this.store.select(AuthenticationSelectors.selectPasscodeTokens);
    }

    getUserEmailInfo(): Observable<UserEmailInfo> {
        return this.store.select(AuthenticationSelectors.selectUserEmailInfo);
    }

    getRedirectUrl(): Observable<string> {
        return this.store.select(AuthenticationSelectors.selectRedirectUrl);
    }

    getHasDismissedMfa(): Observable<boolean> {
        return this.store.select(AuthenticationSelectors.selectHasDismissedMfa);
    }

    getOtpSignature(): Observable<string> {
        return this.store.select(AuthenticationSelectors.selectOtpSignature);
    }

    isLoginRequired(): Observable<boolean> {
        return combineLatest([
            this.appCoreFacadeService.getAppInitialising(),
            this.store.select(AuthenticationSelectors.selectUserAuthInfo),
            this.store.select(AppCoreSelectors.selectAppSettings)
        ]).pipe(
            map(([initialising, userAuthInfo, appSettings]) => {
                return initialising || (appSettings && appSettings.privacy !== App.PrivacyEnum.Public && !userAuthInfo);
            })
        );
    }

    isPasscodeRequired(): Observable<boolean> {
        return combineLatest([
            this.appCoreFacadeService.getAppInitialising(),
            this.store.select(AuthenticationSelectors.selectPasscodeTokens),
            this.store.select(AppCoreSelectors.selectAppSettings),
            this.store.select(AppCoreSelectors.selectAppName)
        ]).pipe(
            map(([initialising, passcodeTokens, appSettings, appName]) => {
                return initialising || (appSettings.toggle_passcode && !passcodeTokens[appName]);
            })
        );
    }

    isEmailVerificationRequired(): Observable<boolean> {
        return combineLatest([
            this.appCoreFacadeService.getAppInitialising(),
            this.store.select(AuthenticationSelectors.selectUserAuthInfo),
            this.store.select(AppCoreSelectors.selectAppSettings)
        ]).pipe(
            map(([initialising, userAuthInfo, appSettings]) => {
                return initialising || (appSettings.email_validation && userAuthInfo && !userAuthInfo.user.valid_email);
            })
        );
    }

    isMfaRequired(): Observable<boolean> {
        return combineLatest([
            this.appCoreFacadeService.getAppInitialising(),
            this.appCoreFacadeService.getAppSettings(),
            this.store.select(AuthenticationSelectors.selectUserAuthInfo),
            this.store.select(AuthenticationSelectors.selectHasDismissedMfa)
        ]).pipe(
            map(([initialising, appSettings, userAuthInfo, hasDismissedMfa]) => {
                if (initialising) {
                    return true;
                }

                const isPublic = appSettings.privacy === App.PrivacyEnum.Public;
                if (isPublic && !userAuthInfo) {
                    return false;
                }

                const mfaSecurity = appSettings.mfa_security;
                if (mfaSecurity === App.MfaSecurityEnum.Email && userAuthInfo && !userAuthInfo.meta.mfa_required) {
                    return false;
                }

                if (mfaSecurity === App.MfaSecurityEnum.Hide && userAuthInfo && !userAuthInfo.meta.mfa_required) {
                    return false;
                }

                return (
                    !userAuthInfo ||
                    userAuthInfo.meta.mfa_required ||
                    (!userAuthInfo.meta.has_mfa_device && !hasDismissedMfa)
                );
            })
        );
    }

    hasAppAccess(): Observable<boolean> {
        return combineLatest([
            this.isLoginRequired(),
            this.isPasscodeRequired(),
            this.isEmailVerificationRequired(),
            this.isMfaRequired()
        ]).pipe(
            map(([loginRequired, passcodeRequired, emailVerificationRequired, isMfaRequired]) => {
                return !loginRequired && !passcodeRequired && !emailVerificationRequired && !isMfaRequired;
            }),
            distinctUntilChanged()
        );
    }

    hasAppAccessAndAuthenticated(): Observable<boolean> {
        return combineLatest([this.hasAppAccess(), this.getAuthenticatedPerson()]).pipe(
            map(([hasAccess, loggedInUser]) => hasAccess && !!loggedInUser),
            distinctUntilChanged()
        );
    }

    selectAppIdentityProviders(): Observable<IdentityProvider[]> {
        return this.store.select(AuthenticationSelectors.selectAppIdentityProviders);
    }

    hasSsoSettled(): Observable<boolean> {
        return this.store.select(AuthenticationSelectors.selectSsoHasSettled);
    }

    getUserCustomFieldValues(): Observable<MableCustomValue[]> {
        return this.store.select(AuthenticationSelectors.selectUserCustomFieldValues);
    }

    dispatch(action: Action): void {
        return this.store.dispatch(action);
    }
}
