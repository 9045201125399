import * as models from '@shared/api';

export interface PeopleModule {
    id?: number;
    people?: Array<number>;
    peoplegroups?: Array<string>;
    people_ordering?: models.ContentOrderingSettings;
    peoplegroup_ordering?: models.ContentOrderingSettings;
    view_type?: string;
    uuid?: string;
    people_display?: PeopleModule.PeopleDisplayEnum;
    module: number;
    show_grid_view: boolean;
}

export namespace PeopleModule {
    export enum PeopleDisplayEnum {
        All = 'all' as any,
        Peoplegroups = 'peoplegroups' as any,
        People = 'people' as any
    }
}
