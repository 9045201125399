import * as models from '@shared/api';

export interface ChallengeModule {
    challenges?: Array<number>;
    challenge_ordering?: models.ContentOrderingSettings;
    allow_answer_all?: boolean;
    presenter_blurb?: string;
    share_stats_for_all?: boolean;
    id?: number;
    entrants?: Array<number>;
    module: number;
    tab_order: Array<ChallengeModuleTab>;
}

export enum ChallengeModuleTab {
    Challenges = 'challenges',
    Leaderboard = 'leaderboard',
    Info = 'info'
}
