import { AppRoutingActions } from '@core/root-store/store/app-routing/actions/app-routing.actions';
import { AppPageRoutes } from '@core/routing/constants/app-page-routes.constant';
import { AppUserPageRoutes } from '@core/routing/constants/user-page-routes.constant';
import { BusinessCardNotificationType } from '../enums/business-card-notification-type.enum';
import { AppNotification } from '../interfaces/notification.interface';

const navigateToBusinessCardsAction = AppRoutingActions.goToAppPage({
    urlSegments: [AppPageRoutes.user, AppUserPageRoutes.businessCards]
});

const businessCardNotificationDefaults: Partial<AppNotification> = {
    action: navigateToBusinessCardsAction,
    actionAriaLabel: 'BUSINESS_CARD_NOTIFICATION_ARIA_LABEL'
};

export const BusinessCardNotificationMap: Record<BusinessCardNotificationType, Partial<AppNotification>> = {
    [BusinessCardNotificationType.request_sent]: {
        title: 'BUSINESS_CARD_NOTIFICATION_REQUEST_SENT_TITLE',
        message: 'BUSINESS_CARD_NOTIFICATION_REQUEST_SENT_MESSAGE',
        ...businessCardNotificationDefaults
    },
    [BusinessCardNotificationType.request_accepted]: {
        title: 'BUSINESS_CARD_NOTIFICATION_REQUEST_ACCEPTED_TITLE',
        message: 'BUSINESS_CARD_NOTIFICATION_REQUEST_ACCEPTED_MESSAGE',
        ...businessCardNotificationDefaults
    }
};
