import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { BusinessCard } from '@shared/business-cards/interfaces/business-card.interface';

@Component({
    selector: 'cc-person-business-card-exchange',
    templateUrl: './person-business-card-exchange.component.html',
    styleUrls: ['./person-business-card-exchange.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PersonBusinessCardExchangeComponent {
    @Input()
    public businessCard: BusinessCard;

    @Output()
    public exchangeClicked: EventEmitter<void> = new EventEmitter<void>();

    public emitExchangeClicked(): void {
        this.exchangeClicked.emit();
    }
}
