<ng-container
    *ngIf="{
        video: video$ | async,
        playbackTokens: playbackTokens$ | async,
        step: step$ | async
    } as videoData"
>
    <div
        class="cc-video-container"
        ccIntersectionObserver
        [ngSwitch]="videoData.step"
        [ngClass]="{
            ready: videoData.step === videoStepEnum.Ready,
            'waiting-for-play': videoData.step === videoStepEnum.WaitingForPlay
        }"
        (click)="play(videoData.step, $event)"
        (visibilityChange)="onVisibilityChange($event)"
    >
        <ng-container *ngSwitchCase="videoStepEnum.Error">
            <div class="error">
                <i class="material-icons">error</i>
                <span>
                    {{ 'VIDEO_ERROR' | translate: { error: error } }}
                </span>
                <ion-button fill="outline" color="danger" (click)="retry($event)">
                    {{ 'VIDEO_TRY_AGAIN' | translate }}
                </ion-button>
            </div>
        </ng-container>

        <ng-container *ngSwitchCase="videoStepEnum.FetchingVideo">
            <ng-container *ngTemplateOutlet="loading"></ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="videoStepEnum.FetchingPlaybackInfo">
            <ng-container *ngTemplateOutlet="loading"></ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="videoStepEnum.WaitingForPlay">
            <div
                class="poster"
                [ngStyle]="posterStyle"
                [ngClass]="{ 'no-poster': !videoData.video?.poster && !videoData.video?.thumbnail }"
            ></div>

            <button class="play-button">
                <i class="material-icons">play_arrow</i>
            </button>

            <div class="video-info">
                <span class="video-title truncate">{{ videoData.video?.title }}</span>
                <span class="video-duration">{{ videoData.video?.duration | duration }}</span>
            </div>
        </ng-container>

        <ng-container *ngSwitchCase="videoStepEnum.Ready">
            <cc-mux-player
                [contentId]="videoData.video?.id"
                [contentTitle]="videoData.video?.title"
                [poster]="videoData?.video?.poster"
                [autoPlay]="true"
                [playbackTokens]="videoData.playbackTokens"
                [muted]="muted"
            >
            </cc-mux-player>
        </ng-container>
    </div>
</ng-container>

<ng-template #loading>
    <div class="loading-container">
        <cc-loading-spinner></cc-loading-spinner>
    </div>
</ng-template>
