import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthenticationActions } from '@core/root-store/store/authentication/actions/authentication.actions';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppCoreFacadeService } from '../../../core/app-core/services/app-core-facade.service';
import { AppPageRoutes } from '../../routing/constants/app-page-routes.constant';
import { AuthenticationFacadeService } from '../services/authentication-facade.service';

/**
 * This guard will redirect the user to the login page if they are not authenticated but need to be.
 */
@Injectable({
    providedIn: 'root'
})
export class AuthenticationGuard {
    private unprotectedAppRoutes = [
        AppPageRoutes.login,
        AppPageRoutes.verifyEmail,
        AppPageRoutes.passwordReset,
        AppPageRoutes.pwaInstall
    ];

    constructor(
        private authenticationFacadeService: AuthenticationFacadeService,
        private appCoreFacadeService: AppCoreFacadeService,
        private router: Router
    ) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        /**
         * Fetch whether SSO has settled (i.e. is not being used or has successfully exchanged)
         * Then halt this stream until the value has settled to prevent race conditions
         */
        return combineLatest({
            hasAccess: this.authenticationFacadeService.hasAppAccess(),
            hasSsoSettled: this.authenticationFacadeService.hasSsoSettled()
        }).pipe(
            filter(({ hasSsoSettled }) => !!hasSsoSettled),
            switchMap(({ hasAccess }) => {
                const nextChildRouteUrl =
                    next.firstChild && next.firstChild.firstChild
                        ? next.firstChild.firstChild.url.map((s) => s.path).join('/')
                        : '';
                return of(hasAccess || this.unprotectedAppRoutes.some((r) => nextChildRouteUrl.includes(r)));
            }),
            withLatestFrom(this.appCoreFacadeService.getAppName()),
            map(([canActivate, appName]) => {
                if (canActivate) {
                    return true;
                }

                const lastIndexOfSlash = state.url.lastIndexOf('/');

                // Only add a redirect url if the url contains more than just the app name.
                // This is to avoid conflicting redirects.
                if (lastIndexOfSlash !== 0) {
                    this.authenticationFacadeService.dispatch(
                        AuthenticationActions.setRedirectUrl({ redirectUrl: state.url })
                    );
                }

                return this.router.createUrlTree([appName, AppPageRoutes.login]);
            })
        );
    }
}
