import {
    PollContentType,
    PollSubmitContentType
} from '@shared/engagement/live-polls-panel/enums/poll-content-type.enum';

export const SurveyApiSettings = {
    poll: (id: number) => `/polls/${id}/?expand=~all`,
    pollGroup: (id: number) => `/pollgroups/${id}/?expand=~all`,
    pollGroupDetail: (id: number) => `/pollgroups/${id}/?expand=~all`,
    pollGroups: '/pollgroups/',
    pollGroupsForModule: (moduleId: number) =>
        `/modules/${moduleId}/survey/pollgroups/?fields=id,title,polls,login_required`,
    pollIntermission: (id: number) => `/pollintermissions/${id}/`,
    pollIntermissions: '/pollintermissions/',
    pollResponse: (pollId: number) => `/polls/${pollId}/response/`,
    pollResults: (pollId: number) => `/polls/${pollId}/results/`,
    pollSessionResponse: (
        pollId: number,
        sessionId: number,
        contentType: PollContentType = PollSubmitContentType.Session
    ) => `/polls/${pollId}/response/?content_type=${contentType}&object_id=${sessionId}`,
    pollResponseAnonymous: (pollId: number, anonymousId: string) =>
        `/polls/${pollId}/response/?anonymous_id=${anonymousId}`,
    pollResponses: '/pollresponses/',
    pollResponsesAnonymous: (anonymousId) => `/pollresponses/?anonymous_id=${anonymousId}`,
    pollSubmit: '/pollresponses/submit/',
    proxyPollSubmit: '/pollresponses/submit-proxy/',
    polls: '/polls/',
    surveyModule: (moduleId: number) => `/modules/${moduleId}/survey/`,
    totalVoteCount: (pollId: string | number) => `/polls/${pollId}/total-vote-count/`,
    proxyVoteResponses: (pollId: string | number) => `/polls/${pollId}/proxy-responses/`
};
