import { Injectable } from '@angular/core';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { ToastService } from '@core/toast/services/toast/toast.service';
import { PollItem } from '@features/live-polling/models/poll-item.model';
import { PollSubmission } from '@features/live-polling/models/poll-submission.model';
import { SurveysApiService } from '@features/surveys/services/surveys-api.service';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { Poll, PollGroup, PollIntermission, PollResponse } from '@shared/api';
import { forkJoin, Observable, switchMap, tap, withLatestFrom } from 'rxjs';
import { ChoicePollChoiceFormItem } from '../models/choice-poll-choice-ui-item.model';
import { LivePollingSurveysApiService } from './live-polling-surveys-api.service';

@Injectable()
export class SurveyStoreService extends ComponentStore<SurveyState> {
    public readonly isLoading$: Observable<boolean> = this.select((state) => state.initialising);
    public readonly isSubmitting$: Observable<boolean> = this.select((state) => state.submitting);

    public readonly setCurrentIndex = this.updater((state, currentIndex: number) => ({
        ...state,
        currentIndex
    }));

    private readonly pollGroupId$: Observable<number> = this.select((state) => state.pollGroupId);

    private setInitialising = this.updater((state, initialising: boolean) => ({
        ...state,
        initialising
    }));

    private setSubmitting = this.updater((state, submitting: boolean) => ({
        ...state,
        submitting
    }));

    private setupState = this.updater((state, resource: PollGroupResponse) => {
        const { pollGroup, pollResponses } = resource;
        const orderedItems = this.combineAndSort(pollGroup);
        const { currentPoll, currentPollIntermission } = this.calculateFirstItems(
            pollGroup,
            pollResponses,
            orderedItems
        );
        return {
            ...state,
            pollGroup,
            pollResponses,
            currentPoll,
            currentPollIntermission,
            orderedItems,
            totalCount: orderedItems.length,
            currentIndex: 0,
            pollInitialValue: this.setInitialValue(currentPoll),
            pollChoiceItems: this.createPollChoices(currentPoll)
        };
    });

    private updateCurrentPollState = this.updater((state, cardinality: PollCardinality) => {
        const newIndex = state.currentIndex + cardinality;
        const { currentPoll, currentPollIntermission } = this.calculateNextItem(newIndex);

        if (!currentPoll && !currentPollIntermission) {
            return {
                ...state,
                currentIndex: newIndex,
                complete: true
            };
        }

        return {
            ...state,
            currentIndex: newIndex,
            currentPoll,
            currentPollIntermission,
            pollInitialValue: this.setInitialValue(currentPoll),
            pollChoiceItems: this.createPollChoices(currentPoll)
        };
    });

    private updateResponse = this.updater((state, submittedPoll: PollSubmission) => {
        const pollResponses = state.pollResponses.map((response) => {
            if (response.poll === submittedPoll.pollId) {
                return {
                    ...response,
                    response_obj: {
                        response: submittedPoll.submissionValue
                    }
                };
            }
            return response;
        });
        return {
            ...state,
            pollResponses
        };
    });

    private readonly fetchResource = this.effect((pollGroupId$: Observable<number>) => {
        const appName$ = this.appCoreFacadeService.getAppName();
        return pollGroupId$.pipe(
            tap(() => this.setInitialising(true)),
            withLatestFrom(appName$),
            switchMap(([groupId, app]) =>
                forkJoin({
                    pollGroup: this.surveyApiService.getPollGroupDetail(app, groupId),
                    pollResponses: this.surveyApiService.getPollResponses(app)
                })
            ),
            tap(({ pollGroup, pollResponses }) => {
                this.setInitialising(false);
                this.setupState({ pollGroup, pollResponses });
            })
        );
    });

    private readonly submitPoll = this.effect((submission$: Observable<PollSubmission>) => {
        let payload: PollSubmission;
        return submission$.pipe(
            tap((submittedPoll: PollSubmission) => {
                payload = submittedPoll;
                this.setSubmitting(true);
            }),
            withLatestFrom(this.appCoreFacadeService.getAppName()),
            switchMap(([submittedPoll, app]) => this.surveysApiService.submitPoll(app, submittedPoll)),
            tapResponse(
                () => {
                    this.submitComplete(payload);
                },
                (error: string) => this.toastService.error(error)
            )
        );
    });

    constructor(
        private surveyApiService: SurveysApiService,
        private appCoreFacadeService: AppCoreFacadeService,
        private surveysApiService: LivePollingSurveysApiService,
        private toastService: ToastService
    ) {
        super();
    }

    public initialise(pollGroupId: number): void {
        this.setState({
            initialising: true,
            submitting: false,
            pollGroupId
        });

        this.pollGroupId$.subscribe((groupId) => this.fetchResource(groupId));
    }

    public nextPollItem(): void {
        this.updateCurrentPollState(PollCardinality.Next);
    }

    public prevPollItem(): void {
        this.updateCurrentPollState(PollCardinality.Prev);
    }

    public submitPollItem(submission: PollSubmission): void {
        this.submitPoll(submission);
    }

    private submitComplete(payload: PollSubmission): void {
        this.updateCurrentPollState(PollCardinality.Next);
        this.setSubmitting(false);
        this.updateResponse(payload);
    }

    private calculateFirstItems(
        pollGroupDetail: PollGroup,
        pollResponses: PollResponse[],
        orderedItems: PollItemTypes[]
    ): PollItemResult {
        const firstItem = orderedItems[0];
        const response = { currentPoll: undefined, currentPollIntermission: undefined };

        if (!firstItem) {
            return response;
        }

        const isPoll = (pollGroupDetail.polls as Poll[]).find((poll) => poll.id === firstItem.id);

        if (isPoll) {
            response.currentPoll = this.createPollItem(firstItem as Poll, pollResponses);
        } else {
            response.currentPollIntermission = firstItem;
        }
        return response;
    }

    private calculateNextItem(index: number): PollItemResult {
        const state = this.get();
        const item = state.orderedItems[index];

        if (!item) {
            return {
                currentPoll: undefined,
                currentPollIntermission: undefined
            };
        }

        const isPoll = !!(item as Poll).poll_type;

        return {
            currentPoll: isPoll ? this.createPollItem(item as Poll, state.pollResponses) : undefined,
            currentPollIntermission: !isPoll ? item : undefined
        };
    }

    private combineAndSort(pollGroup: PollGroup): PollItemTypes[] {
        return [...(pollGroup.polls as Poll[]), ...pollGroup.intermissions].sort((a, b) => a.order - b.order);
    }

    private createPollItem(poll: Poll, responses: PollResponse[]): PollItem {
        const textPoll = poll.textpoll;
        const ratingPoll = poll.ratingpoll;
        const choicePoll = poll.choicepoll;
        const choicePollChoices = choicePoll
            ? [...poll.choicepoll?.choices]?.sort((a, b) => a.order - b.order)
            : undefined;
        const pollResponse = responses?.find((response) => response.poll === poll.id);

        return { poll, textPoll, ratingPoll, choicePoll, choicePollChoices, pollResponse };
    }

    private createPollChoices(pollItem: PollItem): ChoicePollChoiceFormItem[] {
        if (!pollItem || !pollItem?.choicePollChoices) {
            return [];
        }

        const choices = pollItem.choicePollChoices.map((choice) => ({
            id: choice.id,
            text: choice.text,
            selected: pollItem.pollResponse ? pollItem.pollResponse.response_obj.response.includes(choice.id) : false,
            disabled: false
        }));
        return choices;
    }

    private setInitialValue(pollItem: PollItem): PollInitialValue {
        if (!pollItem) {
            return undefined;
        }
        switch (pollItem.poll.poll_type) {
            case Poll.PollTypeEnum.Text:
            case Poll.PollTypeEnum.Rating:
                return { value: pollItem.pollResponse ? pollItem.pollResponse.response_obj.response : undefined };
            case Poll.PollTypeEnum.Choice:
                // This value is not used for choice polls - the choicePollChoiceFormItems have the initial selected values.
                return undefined;
        }
    }
}

export interface SurveyState {
    // intermediate state values
    initialising: boolean;
    submitting: boolean;

    // triggers
    pollGroupId: number;

    // API objects
    pollGroup?: PollGroup;
    pollResponses?: PollResponse[];

    // calculated values
    complete?: boolean;
    currentIndex?: number;
    currentPoll?: PollItem;
    currentPollIntermission?: PollIntermission;
    orderedItems?: PollItemTypes[];
    totalCount?: number;
    pollInitialValue?: PollInitialValue;
    pollChoiceItems?: ChoicePollChoiceFormItem[];
}

interface PollGroupResponse {
    pollGroup: PollGroup;
    pollResponses: PollResponse[];
}

interface PollItemResult {
    currentPoll: PollItem;
    currentPollIntermission: PollIntermission;
}

interface PollInitialValue {
    value: string | number;
}

enum PollCardinality {
    Next = 1,
    Prev = -1
}

type PollItemTypes = Poll | PollIntermission;
