import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Shade } from '@core/branding/enums/shade.enum';
import { PortalConfig } from '@features/portal/interfaces/portal-config.interface';
import { AppTheme } from '@shared/api';
import { ModuleWithRouteCommands } from '@shared/api/models/ModuleWithRouteCommands';

@Component({
    selector: 'cc-sidebar-left-list',
    templateUrl: './sidebar-left-list.component.html',
    styleUrls: ['./sidebar-left-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarLeftListComponent {
    @Input()
    public modules: Array<ModuleWithRouteCommands>;

    @Input()
    public theme: AppTheme;

    @Input()
    public showPoweredByLogo: boolean;

    @Input()
    public poweredByLogoShade: Shade;

    @Input()
    public portalConfig: PortalConfig;

    @Input()
    public selectedModule: number;

    @Output()
    public moduleClick = new EventEmitter<void>();

    @Output()
    public toggleCollapse = new EventEmitter<void>();

    @Output()
    public appBannerClick = new EventEmitter<void>();

    @Output()
    public portalReturn = new EventEmitter<void>();

    @Output()
    public openTosDialog = new EventEmitter<void>();

    public onModuleClick(): void {
        this.moduleClick.emit();
    }

    public onToggleCollapse(): void {
        this.toggleCollapse.emit();
    }

    public onAppBannerClick(): void {
        this.appBannerClick.emit();
    }

    public onPortalReturn(): void {
        this.portalReturn.emit();
    }

    public onTosDialogClick(): void {
        this.openTosDialog.emit();
    }
}
