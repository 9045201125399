import { NavigationExtras } from '@angular/router';
import { createActionGroup, props } from '@ngrx/store';

export const AppRoutingActions = createActionGroup({
    source: 'Navigation',
    events: {
        'Go To App Module': props<{ urlSegments: (string | number)[]; extras?: NavigationExtras }>(),
        'Go To App Module Page': props<{ urlSegments: (string | number)[]; extras?: NavigationExtras }>(),
        'Go To App Page': props<{ urlSegments: (string | number)[]; extras?: NavigationExtras }>(),
        'Go To App User Page': props<{ urlSegments: (string | number)[]; extras?: NavigationExtras }>(),
        'Go To Page': props<{ urlSegments: (string | number)[]; extras?: NavigationExtras }>()
    }
});
