import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { fadeIn } from '@common/animations/animations';
import { AnimationOptions } from 'ngx-lottie';

@Component({
    selector: 'cc-poll-intermission',
    templateUrl: './poll-intermission.component.html',
    styleUrls: ['./poll-intermission.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [fadeIn(200)]
})
export class PollIntermissionComponent {
    @Input()
    public message: string;

    @Input()
    public completed: boolean = false;

    @Input()
    public navButtonText: string;

    @Input()
    public navButtonIcon: string;

    @Input()
    public showButton: boolean = true;

    @Input()
    public showBackButton: boolean = true;

    @Output()
    public navButtonClick = new EventEmitter<void>();

    public readonly completeAnimationOptions: AnimationOptions = {
        autoplay: true,
        path: '/assets/lottie-animations/completed.lottie.json',
        loop: false
    };

    public onNavButtonClick(): void {
        this.navButtonClick.emit();
    }
}
