import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { VideoCall } from '../../models/video-call.interface';

export const VideoCallsApiActions = createActionGroup({
    source: 'Video Calls Api',
    events: {
        'Invite To Call Success': props<{ incomingCall: VideoCall }>(),
        'Invite To Call Failure': props<{ error: HttpErrorResponse }>(),
        'Block Caller': props<{ incomingCall: VideoCall }>(),
        'Block Caller Success': props<{ fName: string }>(),
        'Block Caller Failure': props<{ error: string }>(),
        'Decline Call Success': emptyProps(),
        'Decline Call Failure': props<{ error: string }>(),
        'Cancel Call Success': emptyProps(),
        'Cancel Call Failure': props<{ error: string }>()
    }
});
