import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { fadeIn } from '@common/animations/animations';
import { InlineSession } from '@features/agenda/agenda-module/models/inline-session.model';

@Component({
    selector: 'cc-agenda-card-schedule-button',
    templateUrl: './agenda-card-schedule-button.component.html',
    styleUrl: './agenda-card-schedule-button.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [fadeIn()]
})
export class AgendaCardScheduleButtonComponent implements OnChanges {
    @Input({ required: true })
    public session: InlineSession;

    @Input({ required: true })
    public isInPersonalAgenda: boolean;

    @Input({ required: true })
    public isOnWaitingList: boolean;

    @Input({ required: true })
    public isTogglingInProgress: boolean;

    @Output()
    public ticketClick = new EventEmitter<void>();

    public icon: string;
    public label: string;

    public onTicketClick(event: Event): void {
        event.stopPropagation();
        this.ticketClick.emit();
    }

    public ngOnChanges(): void {
        this.icon = this.getIcon();
        this.label = this.getLabel();
    }

    private getLabel(): string {
        if (this.isInPersonalAgenda || this.isOnWaitingList) {
            return this.session.remove_from_schedule_text || 'SCHEDULE_LIST_REMOVE_LABEL';
        }

        return this.session.add_to_schedule_text || 'SCHEDULE_LIST_ADD_LABEL';
    }

    private getIcon(): string {
        return this.session.schedule_icon || 'local_activity';
    }
}
