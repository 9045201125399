<ng-container *ngIf="banner$ | async as banners">
    <div *ngIf="banners.length" class="banner-ad" [ngClass]="{ 'inside-module': moduleId }" aria-hidden="true">
        <ion-card (click)="bannerClicked(banners)">
            <div [@slideAnimation]="count">
                <div *ngFor="let banner of banners; let i = index">
                    <img
                        *ngIf="count % banners.length === i"
                        [src]="banner.image"
                        [ngClass]="{ isClickable: banner.url }"
                        [alt]="banner.url"
                        class="slide"
                    />
                </div>
            </div>
        </ion-card>
    </div>
</ng-container>
