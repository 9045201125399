import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NotesState } from '../models/notes-state.model';
import { notesStoreKey } from '../models/notes-app-state.model';
import { Note } from '@shared/api';
import { NoteType } from '../../enums/note-type.enum';
import { flow } from 'lodash';
import { AppRoutingSelectors } from '@core/root-store/store/app-routing/selectors/app-routing.selectors';
import { NotesRouteParams } from '@features/notes/constants/notes-route-params.constant';

const selectNotesState = createFeatureSelector<NotesState>(notesStoreKey);

const selectNotesListPageState = createSelector(selectNotesState, (notesState) => notesState.notesListPageState);

const selectAllNotes = createSelector(selectNotesState, (notesState) => notesState.notes);

const selectNotesListPageNotes = createSelector(
    selectNotesState,
    selectNotesListPageState,
    (notesState, notesListPageState) => {
        if (!notesListPageState || !notesState.notes) {
            return undefined;
        }

        const filterByNoteType = (notes: Note[]) => {
            switch (notesListPageState.visibleNoteType) {
                case NoteType.all:
                    return notes;
                case NoteType.general:
                    return notes.filter((n) => !n.content_object);
                case NoteType.session:
                    return notes.filter((n) => n.content_object);
            }
        };

        const sortNotes = (notes: Note[]) => {
            // Sort the notes chronologically.
            return [...notes].sort((n1, n2) => (new Date(n1.created) > new Date(n2.created) ? -1 : 1));
        };

        return flow([filterByNoteType, sortNotes])(notesState.notes);
    }
);

const selectNote = (noteId: number) =>
    createSelector(selectNotesState, (notesState) => (notesState.notes || []).find((n) => n.id === noteId));

const selectNotePageState = createSelector(selectNotesState, (notesState) => notesState.notePageState);

const selectTitle = createSelector(selectNotesState, AppRoutingSelectors.selectRouteParams, (state, params) => {
    const noteId = +params[NotesRouteParams.noteId];
    return state.notes?.find((note) => note.id === noteId)?.title;
});

export const NotesSelectors = {
    selectAllNotes,
    selectNotesListPageNotes,
    selectNotesListPageState,
    selectNote,
    selectNotePageState,
    selectTitle
};
