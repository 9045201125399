import { Inject, Injectable } from '@angular/core';
import { PortalService } from '@core/../features/portal/services/portal.service';
import { AblyMessagingService } from '@core/ably/services/ably-messaging.service';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { AuthenticationFacadeService } from '@core/authentication/services/authentication-facade.service';
import { I18nService } from '@core/i18n/services/i18n.service';
import { SidebarType } from '@core/navigation/enums/sidebar-type.enum';
import { SidebarRightItemsService } from '@core/navigation/services/sidebar-right-items.service';
import { AppCoreActions } from '@core/root-store/store/app-core/actions/app-core.actions';
import { AuthenticationActions } from '@core/root-store/store/authentication/actions/authentication.actions';
import { TosDialogService } from '@core/tos/services/tos-dialog/tos-dialog.service';
import { WINDOW } from '@core/window.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Breakpoints, BreakpointService } from '@shared/utilities/breakpoint.service';
import { of } from 'rxjs';
import {
    buffer,
    catchError,
    debounceTime,
    filter,
    map,
    switchMap,
    take,
    takeUntil,
    tap,
    withLatestFrom
} from 'rxjs/operators';
import { moduleEntityNames } from '../../constants/modules-entity-names.constant';
import { NavigationApiService } from '../../services/navigation-api.service';
import { SidebarService } from '../../services/sidebar.service';
import { NavigationApiActions } from '../actions/navigation-api.actions';
import { NavigationActions } from '../actions/navigation.actions';

@Injectable()
export class NavigationEffects {
    listenForUpdates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NavigationActions.created),
            switchMap(() => {
                const stream = this.ablyMessagingService.getMessageStream(moduleEntityNames);
                return stream.pipe(
                    /* When modules are reordered, a new message is sent for every module.
                     * This waits 0.5 seconds after the previously emitted message and then groups up all the changes */
                    buffer(stream.pipe(debounceTime(500))),
                    filter((messages) => !!messages.length),
                    map(() => NavigationActions.realTimeUpdate()),
                    takeUntil(this.actions$.pipe(ofType(NavigationActions.destroyed)))
                );
            })
        )
    );

    fetchModules$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NavigationActions.created, NavigationActions.realTimeUpdate),
            switchMap(() => this.authenticationFacadeService.hasAppAccess()),
            filter((hasAccess) => hasAccess),
            withLatestFrom(this.appCoreFacadeService.getAppName()),
            switchMap(([, appUrl]) =>
                this.navigationApiService.getModules(appUrl).pipe(
                    map((modules) => NavigationApiActions.getModulesSuccess({ modules })),
                    catchError((error) => of(NavigationApiActions.getModulesFailure({ error })))
                )
            )
        )
    );

    hideLeftSidebarIfNotAuthorised$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(NavigationActions.created),
                switchMap(() =>
                    this.authenticationFacadeService
                        .hasAppAccess()
                        .pipe(takeUntil(this.actions$.pipe(ofType(NavigationActions.destroyed))))
                ),
                tap((hasAccess) => this.sidebarService.enable(SidebarType.Left, hasAccess))
            ),
        { dispatch: false }
    );

    appImageClicked$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NavigationActions.appImageClicked),
            map(() => AppCoreActions.navigateHome())
        )
    );

    portalReturnClick$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(NavigationActions.portalReturnClick),
                withLatestFrom(this.appCoreFacadeService.getAppName()),
                tap(([_, appName]) => {
                    this.portalService.returnToParentPortal(appName);
                })
            ),
        { dispatch: false }
    );

    openTermsAndConditionsDialog$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(NavigationActions.openTermsAndConditions),
                withLatestFrom(this.appCoreFacadeService.getAppName()),
                switchMap(([, appUrl]) => this.tosDialogService.showTosDialog(appUrl))
            ),
        { dispatch: false }
    );

    loadLanguage$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(NavigationActions.loadLanguage),
                withLatestFrom(this.appCoreFacadeService.getAppName()),
                tap(([{ languageCode }, appUrl]) => this.i18nService.setLanguage(appUrl, languageCode)),
                tap(() => this.window.location.reload())
            ),
        { dispatch: false }
    );

    getRightSidebarItems$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NavigationActions.created, AuthenticationActions.completed),
            switchMap(() => this.sidebarRightItemsService.getRightSidebarItems()),
            map((rightSidebarItems) => NavigationActions.setRightSidebarItems({ rightSidebarItems }))
        )
    );

    defaultCollapseSidebar$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppCoreActions.initialiseAppSuccess),
            withLatestFrom(this.appCoreFacadeService.getAppSettings()),
            map(([_, app]) => app.menu_settings),
            filter((menuSettings) => !!menuSettings && menuSettings.default_sidebar_collapsed),
            switchMap(() => this.breakpointService.is(({ width }) => width >= Breakpoints.ExtraLarge).pipe(take(1))),
            filter((isExpanded) => isExpanded),
            map(() => NavigationActions.setLeftSidebarCollapsed({ collapsed: true }))
        )
    );

    constructor(
        private actions$: Actions,
        private appCoreFacadeService: AppCoreFacadeService,
        private navigationApiService: NavigationApiService,
        private sidebarService: SidebarService,
        private sidebarRightItemsService: SidebarRightItemsService,
        private ablyMessagingService: AblyMessagingService,
        private authenticationFacadeService: AuthenticationFacadeService,
        private tosDialogService: TosDialogService,
        private i18nService: I18nService,
        private breakpointService: BreakpointService,
        private portalService: PortalService,
        @Inject(WINDOW) private window: Window
    ) {}
}
