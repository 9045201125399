import { NgModule } from '@angular/core';
import { AppCommonModule } from '@common/common.module';
import { ActionPopoverModule } from '@shared/action-popover/action-popover.module';
import { PaginationModule } from '@shared/pagination/pagination.module';
import { OnlineStatusComponent } from './components/online-status/online-status.component';
import { PeopleCardComponent } from './components/people-card/people-card.component';
import { PeopleGridComponent } from './components/people-grid/people-grid.component';
import { PeopleListComponent } from './components/people-list/people-list.component';
import { PersonHeaderButtonComponent } from './components/person-header-button/person-header-button.component';
import { PersonHeaderComponent } from './components/person-header/person-header.component';
import { ResponsiveDisplayComponent } from './components/responsive-display/responsive-display.component';
import { AppTagModule } from '@shared/tags/app-tag.module';

@NgModule({
    declarations: [
        OnlineStatusComponent,
        PeopleGridComponent,
        PeopleListComponent,
        PeopleCardComponent,
        PersonHeaderComponent,
        PersonHeaderButtonComponent,
        ResponsiveDisplayComponent
    ],
    imports: [AppCommonModule, ActionPopoverModule, PaginationModule, AppTagModule],
    exports: [
        OnlineStatusComponent,
        PeopleGridComponent,
        PeopleListComponent,
        PeopleCardComponent,
        PersonHeaderComponent,
        PersonHeaderButtonComponent,
        ResponsiveDisplayComponent
    ]
})
export class SharedPeopleModule {}
