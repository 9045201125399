<div *ngIf="poll && textPoll" class="poll">
    <div class="poll__content-container">
        <ion-card class="poll__content">
            <h2>{{ poll.title }}</h2>
            <div class="poll__text-input-container">
                <ion-textarea
                    maxlength="{{ textPoll.character_limit }}"
                    [autoGrow]="true"
                    [value]="textInputValue"
                    [placeholder]="'ENTER_POLL_ANSWER' | translate"
                    [counter]="true"
                    (ionInput)="onTextInputChange($event)"
                ></ion-textarea>
            </div>
        </ion-card>
    </div>
    <cc-poll-controls
        [submitButtonText]="submitButtonText"
        [disabled]="!formIsValid()"
        [submitting]="submitting"
        [closed]="closed"
        [showPreviousButton]="showPreviousButton"
        [showNextButton]="showNextButton"
        (pollNext)="onPollNext()"
        (pollPrevious)="onPollPrevious()"
        (pollSubmit)="onPollSubmit()"
    ></cc-poll-controls>
</div>
