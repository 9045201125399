import { Injectable } from '@angular/core';
import { AblyMessagingService } from '@core/ably/services/ably-messaging.service';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { ExpandedApiDrivenNotification } from '@core/notifications/interfaces/expanded-api-driven-notification.interface';
import { PaginatedResponse } from '@shared/pagination/models/paginated-response.model';
import { PaginationState } from '@shared/pagination/models/pagination-state.model';
import { PersistedPaginationStore } from '@shared/pagination/services/persisted-pagination-store/persisted-pagination-store.service';
import { filter, map, Observable, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs';
import { NotificationsQuery } from '../models/notifications-query.interface';
import { AlertsApiActions } from '../store/actions/alerts-api.actions';
import { AlertsActions } from '../store/actions/alerts.actions';
import { AlertsApiService } from './alerts-api.service';
import { AlertsFacadeService } from './alerts-facade.service';

@Injectable()
export class NotificationsPaginationStoreService extends PersistedPaginationStore<
    ExpandedApiDrivenNotification,
    NotificationsQuery
> {
    protected readonly realtimeUpdates = this.effect(() =>
        this.ablyMessagingService.getMessageStream(['notification_created'], true).pipe(
            takeUntil(this.destroy$),
            map(({ data }) => JSON.parse(data) as ExpandedApiDrivenNotification),
            withLatestFrom(this.query$),
            filter(([_, query]) => {
                if (query.read || query.archived) {
                    return false;
                }

                return true;
            }),
            tap(([notification]) => this.addItem({ item: notification }))
        )
    );

    constructor(
        private alertsApiService: AlertsApiService,
        private appCoreFacadeService: AppCoreFacadeService,
        private alertsFacadeService: AlertsFacadeService,
        private ablyMessagingService: AblyMessagingService
    ) {
        super();
    }

    public archiveNotification(notification: ExpandedApiDrivenNotification): void {
        this.appCoreFacadeService
            .getAppName()
            .pipe(
                switchMap((appUrl) => this.alertsApiService.archiveNotification(appUrl, notification.id)),
                take(1)
            )
            .subscribe(() => {
                this.removeItem((item) => item.id === notification.id);
                if (!notification.read) {
                    this.alertsFacadeService.dispatch(AlertsActions.decrementNotificationsBadge());
                }
                this.alertsFacadeService.dispatch(AlertsApiActions.archiveSuccess());
            });
    }

    public unarchiveNotification(id: string): void {
        this.appCoreFacadeService
            .getAppName()
            .pipe(
                switchMap((appUrl) => this.alertsApiService.unarchiveNotification(appUrl, id)),
                take(1)
            )
            .subscribe(() => {
                this.removeItem((item) => item.id === id);
            });
    }

    public archiveAllNotifications(): void {
        this.appCoreFacadeService
            .getAppName()
            .pipe(
                switchMap((appUrl) => this.alertsApiService.archiveAllNotifications(appUrl)),
                take(1)
            )
            .subscribe(() => {
                this.refreshQuery();
                this.alertsFacadeService.dispatch(AlertsApiActions.archiveAllSuccess());
                this.alertsFacadeService.dispatch(AlertsActions.updateStoredNotificationCount({ count: 0 }));
            });
    }

    public markNotificationAsRead(id: string): void {
        this.appCoreFacadeService
            .getAppName()
            .pipe(
                switchMap((appUrl) => this.alertsApiService.markNotificationAsRead(appUrl, id)),
                take(1),
                withLatestFrom(this.query$)
            )
            .subscribe(([_, query]) => {
                if (query.read === false) {
                    this.removeItem((item) => item.id === id);
                } else {
                    this.patchItem({
                        find: (item) => item.id === id,
                        newItem: { read: true }
                    });
                }

                this.alertsFacadeService.dispatch(AlertsActions.decrementNotificationsBadge());
                this.alertsFacadeService.dispatch(AlertsApiActions.markAsReadSuccess());
            });
    }

    public markAllNotificationsAsRead(): void {
        this.appCoreFacadeService
            .getAppName()
            .pipe(
                switchMap((appUrl) => this.alertsApiService.markAllNotificationsAsRead(appUrl)),
                take(1)
            )
            .subscribe(() => {
                this.refreshQuery();
                this.alertsFacadeService.dispatch(AlertsActions.updateStoredNotificationCount({ count: 0 }));
                this.alertsFacadeService.dispatch(AlertsApiActions.markAllAsReadSuccess());
            });
    }

    public showAllNotifications(): void {
        this.patchQuery({
            read: undefined,
            page: 1
        });
    }

    public showOnlyReadNotifications(): void {
        this.patchQuery({
            read: true,
            page: 1
        });
    }

    public showOnlyUnreadNotifications(): void {
        this.patchQuery({
            read: false,
            page: 1
        });
    }

    public showArchivedNotifications(): void {
        this.patchQuery({
            archived: true,
            page: 1
        });
    }

    public showUnarchivedNotifications(): void {
        this.patchQuery({
            archived: false,
            page: 1
        });
    }

    protected fetchResource(
        query: PaginationState & NotificationsQuery
    ): Observable<PaginatedResponse<ExpandedApiDrivenNotification>> {
        return this.appCoreFacadeService.getAppName().pipe(
            take(1),
            switchMap((appName) => this.alertsApiService.getPagedNotifications(appName, query))
        );
    }
}
