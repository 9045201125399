import { NgModule } from '@angular/core';
import { AppCommonModule } from '@common/common.module';
import { BusinessCardComponent } from './components/business-card/business-card.component';
import { BusinessCardTitleComponent } from './components/business-card-title/business-card-title.component';
import { BusinessCardDetailsComponent } from './components/business-card-details/business-card-details.component';
import { BusinessCardSocialsComponent } from './components/business-card-socials/business-card-socials.component';
import { BusinessCardIconsComponent } from './components/business-card-icons/business-card-icons.component';
import { BusinessCardSocialIconComponent } from './components/business-card-social-icon/business-card-social-icon.component';
import { BusinessCardThumbnailComponent } from './components/business-card-thumbnail/business-card-thumbnail.component';
import { BusinessCardReverseComponent } from './components/business-card-reverse/business-card-reverse.component';
import { QrModule } from '@shared/qr/qr.module';
import { BusinessCardThumbnailSkeletonComponent } from './components/business-card-thumbnail-skeleton/business-card-thumbnail-skeleton.component';
import { BusinessCardSkeletonComponent } from './components/business-card-skeleton/business-card-skeleton.component';
import { BusinessCardsStoreModule } from './store/business-cards-store.module';
import { BusinessCardExchangeModalComponent } from './components/business-card-exchange-modal/business-card-exchange-modal.component';

@NgModule({
    declarations: [
        BusinessCardComponent,
        BusinessCardTitleComponent,
        BusinessCardDetailsComponent,
        BusinessCardSocialsComponent,
        BusinessCardIconsComponent,
        BusinessCardSocialIconComponent,
        BusinessCardThumbnailComponent,
        BusinessCardReverseComponent,
        BusinessCardThumbnailSkeletonComponent,
        BusinessCardSkeletonComponent,
        BusinessCardExchangeModalComponent
    ],
    imports: [AppCommonModule, QrModule, BusinessCardsStoreModule],
    exports: [
        BusinessCardComponent,
        BusinessCardReverseComponent,
        BusinessCardThumbnailComponent,
        BusinessCardThumbnailSkeletonComponent,
        BusinessCardSkeletonComponent
    ]
})
export class BusinessCardsModule {}
