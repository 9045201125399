import { HttpClient } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { A11yModule } from './a11y/a11y.module';
import { AblyModule } from './ably/ably.module';
import { AnalyticsModule } from './analytics/analytics.module';
import { AppCoreModule } from './app-core/app-core.module';
import { AuthenticationModule } from './authentication/authentication.module';
import { BannerModule } from './banner/banner.module';
import { BrandingModule } from './branding/branding.module';
import { BrowserUtilitiesModule } from './browser-utilities/browser-utilities.module';
import { DeviceModule } from './device/device.module';
import { ErrorHandlingModule } from './error-handling/error-handling.module';
import { FirebaseModule } from './firebase/firebase.module';
import { I18nModule } from './i18n/i18n.module';
import { IconsModule } from './icons/icons.module';
import { LocaleModule } from './locale/locale.module';
import { ModuleModule } from './module/module.module';
import { NativeModule } from './native/native.module';
import { NavigationHistoryModule } from './navigation-history/navigation-history.module';
import { NavigationModule } from './navigation/navigation.module';
import { NotificationsModule } from './notifications/notifications.module';
import { RootStoreModule } from './root-store/root-store.module';
import { StorageModule } from './storage/storage.module';
import { TagManagerModule } from './tag-management/tag-manager.module';
import { ToastModule } from './toast/toast.module';
import { TosModule } from './tos/tos.module';
import { WINDOW_PROVIDERS } from './window.service';

@NgModule({
    declarations: [],
    exports: [
        AblyModule,
        AnalyticsModule,
        AppCoreModule,
        AuthenticationModule,
        BrandingModule,
        BrowserUtilitiesModule,
        DeviceModule,
        ErrorHandlingModule,
        I18nModule,
        NativeModule,
        RootStoreModule,
        NavigationModule,
        NavigationHistoryModule,
        StorageModule,
        FirebaseModule,
        A11yModule,
        IconsModule,
        BannerModule,
        TosModule,
        ModuleModule,
        ToastModule,
        TagManagerModule,
        NotificationsModule,
        LocaleModule
    ],
    providers: [HttpClient, WINDOW_PROVIDERS]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error(
                'CoreModule has already been loaded. You should only import Core modules in the AppModule only.'
            );
        }
    }
}
