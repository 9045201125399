<cc-modal
    icon="notifications_active"
    [header]="alert.title"
    [canClose]="!alert.mandatory_completion"
    (closeClicked)="dismiss()"
>
    <ion-content ccIonicScrollbar>
        <div class="alert-popup-modal__rich-text" [ccCompileHtml]="alert.description_rich"></div>
        <cc-survey
            *ngIf="alert.embedded_survey_id"
            [pollGroupId]="alert.embedded_survey_id"
            (pollCompleted)="pollComplete()"
        ></cc-survey>
    </ion-content>
</cc-modal>
