import { Inject, Injectable } from '@angular/core';
import { ToastService } from '@core/toast/services/toast/toast.service';
import { WINDOW } from '@core/window.service';
import { Observable, shareReplay } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NetworkStatusService {
    public isOffline$: Observable<boolean>;

    constructor(
        @Inject(WINDOW) private window: Window,
        private toastService: ToastService
    ) {
        this.isOffline$ = this.getIsOfflineStream().pipe(shareReplay(1));
    }

    private getIsOfflineStream(): Observable<boolean> {
        return new Observable<boolean>((observer) => {
            observer.next(!this.window.navigator.onLine);

            this.window.addEventListener('online', () => {
                this.toastService.success('PWA_ONLINE_TOAST');
                observer.next(false);
            });

            this.window.addEventListener('offline', () => {
                this.toastService.error('PWA_OFFLINE_TOAST');
                observer.next(true);
            });
        });
    }
}
