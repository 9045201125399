import { MeetingCategory } from '@features/meeting-booking/enums/meeting-category.enum';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { meetingBookingKey } from '../models/meeting-booking-app-state.model';
import { MeetingBookingState } from '../models/meeting-booking-state.model';
import { meetingListAdapters } from '../reducers/meeting-booking.reducer';

const meetingBookingState = createFeatureSelector<MeetingBookingState>(meetingBookingKey);

const entityAdapterSelectors = {
    [MeetingCategory.Completed]: meetingListAdapters[MeetingCategory.Completed].getSelectors(),
    [MeetingCategory.Upcoming]: meetingListAdapters[MeetingCategory.Upcoming].getSelectors(),
    [MeetingCategory.Sent]: meetingListAdapters[MeetingCategory.Sent].getSelectors(),
    [MeetingCategory.Received]: meetingListAdapters[MeetingCategory.Received].getSelectors()
};

const meetingsState = createSelector(meetingBookingState, (state) => state.meetings);

const upcomingMeetingsState = createSelector(meetingBookingState, (state) => state.meetings[MeetingCategory.Upcoming]);

const receivedMeetingsState = createSelector(meetingBookingState, (state) => state.meetings[MeetingCategory.Received]);

const completedMeetingsState = createSelector(
    meetingBookingState,
    (state) => state.meetings[MeetingCategory.Completed]
);

const sentMeetingsState = createSelector(meetingBookingState, (state) => state.meetings[MeetingCategory.Sent]);

const upcomingMeetings = createSelector(
    upcomingMeetingsState,
    entityAdapterSelectors[MeetingCategory.Upcoming].selectAll
);

const receivedMeetings = createSelector(
    receivedMeetingsState,
    entityAdapterSelectors[MeetingCategory.Received].selectAll
);

const completedMeetings = createSelector(
    completedMeetingsState,
    entityAdapterSelectors[MeetingCategory.Completed].selectAll
);

const sentMeetings = createSelector(sentMeetingsState, entityAdapterSelectors[MeetingCategory.Sent].selectAll);

const selectedAvailability = createSelector(meetingBookingState, (state) => state.availability.selectedAvailability);

const receivedAvailability = createSelector(meetingBookingState, (state) => state.availability.receivedAvailability);

const selectedDate = createSelector(meetingBookingState, (state) => state.availability.selectedDate);

const availabilityIsLoading = createSelector(meetingBookingState, (state) => state.availability.loading);

export const MeetingBookingSidebarSelectors = {
    upcomingMeetings,
    receivedMeetings,
    completedMeetings,
    sentMeetings,
    selectedAvailability,
    receivedAvailability,
    selectedDate,
    availabilityIsLoading,
    sentMeetingsState,
    meetingsState
};
