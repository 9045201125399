import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NotificationsEffects } from './effects/notifications.effects';
import { notificationsStateKey } from './models/notifications-state.model';
import { notificationsReducer } from './reducers/notifications.reducer';

@NgModule({
    imports: [
        StoreModule.forFeature(notificationsStateKey, notificationsReducer),
        EffectsModule.forFeature([NotificationsEffects])
    ]
})
export class NotificationsStoreModule {}
