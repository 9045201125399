<div *ngIf="poll && ratingPoll" class="poll">
    <div class="poll__content-container">
        <ion-card class="poll__content">
            <h2>{{ poll.title }}</h2>

            <p *ngIf="!canSubmit" class="poll__description">
                {{ 'SURVEYS_BLURB_ANSWERED' | translate }}
            </p>

            <div *ngIf="ratingIsSlider()" class="poll__slider-container">
                <ion-range
                    class="poll__slider"
                    step="1"
                    color="accent"
                    [snaps]="true"
                    [pin]="true"
                    [min]="ratingPoll.min_rating"
                    [max]="ratingPoll.max_rating"
                    [disabled]="!canSubmit || closed"
                    [(ngModel)]="selectedRangeValue"
                >
                </ion-range>
                <ng-container *ngTemplateOutlet="labelContainer"></ng-container>
            </div>

            <div *ngIf="!ratingIsSlider()" class="poll__stars-container">
                <div class="poll__stars-container-inner">
                    <ion-button
                        *ngFor="let starValue of starValues"
                        class="poll__star-button"
                        fill="clear"
                        role="button"
                        [disabled]="!canSubmit || closed"
                        [attr.aria-label]="starValue + ' ' + ('SURVEY_STARS' | translate)"
                        (click)="selectRangeValue(starValue)"
                    >
                        <i *ngIf="starValue <= selectedRangeValue" class="material-icons" aria-hidden="true">star</i>
                        <i
                            *ngIf="selectedRangeValue === undefined || starValue > selectedRangeValue"
                            class="material-icons"
                            aria-hidden="true"
                            [ngClass]="{
                                faded: selectedRangeValue === undefined || starValue > selectedRangeValue + 1
                            }"
                            >star_border</i
                        >
                    </ion-button>
                </div>
                <ng-container *ngTemplateOutlet="labelContainer"></ng-container>
            </div>
        </ion-card>
    </div>
    <cc-poll-controls
        [submitButtonText]="submitButtonText"
        [disabled]="!formIsValid() || !canSubmit"
        [submitting]="submitting"
        [closed]="closed"
        [showPreviousButton]="showPreviousButton"
        [showNextButton]="showNextButton"
        (pollNext)="onPollNext()"
        (pollPrevious)="onPollPrevious()"
        (pollSubmit)="onPollSubmit()"
    ></cc-poll-controls>
</div>

<ng-template #labelContainer>
    <div class="poll__range-label-container">
        <div>{{ ratingPoll.min_label }}</div>
        <div>{{ ratingPoll.max_label }}</div>
    </div>
</ng-template>
