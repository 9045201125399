import { gamificationKey, GamificationAppState } from '../models/gamification-app-state.model';
import { createSelector } from '@ngrx/store';
import { AppChallengeWithCompletions } from '@features/gamification/interfaces/challenge-with-completions.interface';

const selectGamificationState = (state: GamificationAppState) => state[gamificationKey];

// Gamification page

const selectGamificationPageState = createSelector(
    selectGamificationState,
    (gamificationState) => gamificationState.gamificationPageState
);

const selectGamificationPageInitialising = createSelector(
    selectGamificationPageState,
    (gamificationPageState) => gamificationPageState?.initialising
);

const selectGamificationPageModule = createSelector(
    selectGamificationPageState,
    (gamificationPageState) => gamificationPageState?.module
);

const selectGamificationPageProgress = createSelector(
    selectGamificationPageState,
    (gamificationPageState) => gamificationPageState?.progress
);

// Challenges Page

const selectGamificationChallengesPageState = createSelector(
    selectGamificationState,
    (gamificationState) => gamificationState.challengesPageState
);

const selectGamificationChallengesPageInitialising = createSelector(
    selectGamificationChallengesPageState,
    (challengesPageState) => challengesPageState?.initialising
);

const selectGamificationChallengesPageFilter = createSelector(
    selectGamificationChallengesPageState,
    (challengesPageState) => challengesPageState?.filter
);

const selectGamificationChallengesPageNextPage = createSelector(
    selectGamificationChallengesPageState,
    (challengesPageState) => challengesPageState?.nextChallengesPageUrl
);

const selectGamificationChallengesPageChallenges = createSelector(
    selectGamificationChallengesPageState,
    (challengesPageState) => challengesPageState?.challenges
);

const selectGamificationChallengesPageCompletions = createSelector(
    selectGamificationChallengesPageState,
    (challengesPageState) => challengesPageState?.completions
);

const selectGamificationChallengesPageChallengesWithCompletions = createSelector(
    selectGamificationChallengesPageChallenges,
    selectGamificationChallengesPageCompletions,
    (challenges, completions) =>
        challenges.map((challenge) => {
            const matchingCompletions = completions.filter((completion) => completion.challenge === challenge.id);
            return { ...challenge, completions: matchingCompletions } as AppChallengeWithCompletions;
        })
);

// Leaderboard Page

const selectGamificationLeaderboardPageState = createSelector(
    selectGamificationState,
    (gamificationState) => gamificationState.leaderboardPageState
);

const selectGamificationLeaderboardPageInitialising = createSelector(
    selectGamificationLeaderboardPageState,
    (leaderboardPageState) => leaderboardPageState?.initialising
);

const selectGamificationLeaderboard = createSelector(
    selectGamificationLeaderboardPageState,
    (leaderboardPageState) => leaderboardPageState?.scores
);

const selectGamificationLeaderboardNextCursorUrl = createSelector(
    selectGamificationLeaderboardPageState,
    (leaderboardPageState) => leaderboardPageState?.nextCursorUrl
);

// Challenge Detail Page

const selectChallengeDetailPageState = createSelector(
    selectGamificationState,
    (gamificationState) => gamificationState.challengeDetailPageState
);

const selectChallengeDetailPageChallenge = createSelector(
    selectChallengeDetailPageState,
    (challengeDetailPageState) => challengeDetailPageState?.challenge
);

const selectChallengeDetailPageProgress = createSelector(
    selectChallengeDetailPageState,
    (challengeDetailPageState) => challengeDetailPageState?.progress
);

const selectChallengeDetailPageInitialising = createSelector(
    selectChallengeDetailPageState,
    (challengeDetailPageState) => challengeDetailPageState?.initialising
);

const selectChallengeDetailPageSubmitting = createSelector(
    selectChallengeDetailPageState,
    (challengeDetailPageState) => challengeDetailPageState?.submitting
);

const selectChallengeDetailPageTitle = createSelector(selectChallengeDetailPageState, (challengeDetailPageState) => {
    return challengeDetailPageState?.challenge?.title;
});

export const GamificationSelectors = {
    // Gamification Page
    selectGamificationPageInitialising,
    selectGamificationPageModule,
    selectGamificationPageProgress,
    // Challenges Page
    selectGamificationChallengesPageInitialising,
    selectGamificationChallengesPageFilter,
    selectGamificationChallengesPageNextPage,
    selectGamificationChallengesPageChallengesWithCompletions,
    selectGamificationChallengesPageChallenges,
    selectGamificationChallengesPageCompletions,
    // Leaderboard Page
    selectGamificationLeaderboardPageInitialising,
    selectGamificationLeaderboard,
    selectGamificationLeaderboardNextCursorUrl,
    // Challenge Detail Page
    selectChallengeDetailPageChallenge,
    selectChallengeDetailPageProgress,
    selectChallengeDetailPageInitialising,
    selectChallengeDetailPageSubmitting,
    selectChallengeDetailPageTitle
};
