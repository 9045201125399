import { Injectable } from '@angular/core';
import { SoundEffects } from '@common/constants/sound-effects.enum';
import { Howl } from 'howler';
import { HowlerFactoryService } from '../howler-factory/howler-factory.service';

@Injectable({
    providedIn: 'root'
})
export class SoundService {
    private sounds: Map<SoundEffects, Howl>;

    constructor(private howlerFactoryService: HowlerFactoryService) {
        this.sounds = new Map<SoundEffects, Howl>();
    }

    /**
     * Gets a given sound
     * @param name The name of the sound, used for file name and cache key.
     * @param volume The volume (between 0 and 1).
     * @returns A howl class
     */
    public get(name: SoundEffects, volume: number): Howl {
        const sound = this.sounds.get(name);

        // If we already have the sound loaded then just set the volume and return it.
        if (sound) {
            sound.volume(volume);
            return sound;
        }

        const newSound = this.howlerFactoryService.createHowl({
            src: [`assets/sounds/${name}.mp3`],
            volume
        });

        this.sounds.set(name, newSound);

        return newSound;
    }

    /**
     * Gets a given sound and plays it
     * @param name The name of the sound, used for file name and cache key.
     * @param volume The volume (between 0 and 1).
     * @returns A howl class
     */
    public play(name: SoundEffects, volume: number): Howl {
        const sound = this.get(name, volume);
        sound.play();

        return sound;
    }

    /**
     * Gets a given sound and stops it
     * @param name The name of the sound, used for file name and cache key.
     * @param volume The volume (between 0 and 1).
     * @returns A howl class
     */
    public stop(name: SoundEffects, volume: number): Howl {
        const sound = this.get(name, volume);
        sound.stop();

        return sound;
    }
}
