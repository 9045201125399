import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PeopleEndpoints } from '@features/people/constants/people-endpoints.constant';
import { PeopleMinimal } from '@shared/api';
import { ApiSettings } from '@shared/settings/api-settings.constant';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PersonPopoverApiService {
    constructor(private httpClient: HttpClient) {}

    public getMinimalPerson(appUrl: string, personId: number): Observable<PeopleMinimal> {
        return this.httpClient.get<PeopleMinimal>(ApiSettings.fullUrl(appUrl, PeopleEndpoints.person(personId)), {
            params: {
                fields: [
                    'id',
                    'app_picture',
                    'first_name',
                    'last_name',
                    'job_title',
                    'pronouns',
                    'company',
                    'accepts_video_calls',
                    'chat_enabled',
                    'video_calls_enabled',
                    'meetings_can_invite',
                    'meeting_booking_enabled',
                    'meeting_booking_allowed'
                ]
            }
        });
    }
}
