import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { VideoCall } from '../../models/video-call.interface';

export const VideoCallsActions = createActionGroup({
    source: 'Video Call',
    events: {
        'Accept Call': props<{ incomingCall: VideoCall }>(),
        'Decline Call': props<{ incomingCall: VideoCall }>(),
        'Block Caller': props<{ incomingCall: VideoCall }>(),
        'Cancel Call': emptyProps()
    }
});
