import * as models from '@shared/api';

export interface SurveyModule {
    id?: number;
    pollgroups?: Array<number>;
    pollgroup_ordering?: models.ContentOrderingSettings;
    uuid?: string;
    filter?: string;
    survey_display?: SurveyModule.SurveyDisplayEnum;
    module: number;
}

export namespace SurveyModule {
    export enum SurveyDisplayEnum {
        All = 'all' as any,
        Specific = 'specific' as any
    }
}
