import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { AppCoreFacadeService } from '../../app-core/services/app-core-facade.service';
import { switchMap, take } from 'rxjs/operators';
import { PortalService } from '@features/portal/services/portal.service';
import { ContainerService } from '@features/container/services/container.service';

@Injectable()
export class OneSignalInterceptor implements HttpInterceptor {
    constructor(
        private appCoreFacadeService: AppCoreFacadeService,
        private portalService: PortalService,
        private containerService: ContainerService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return forkJoin([
            this.appCoreFacadeService.getAppName().pipe(take(1)),
            this.appCoreFacadeService.getOneSignalUserId().pipe(take(1))
        ]).pipe(
            switchMap(([appName, userId]) => {
                if (req.url.indexOf('login') >= 0 || req.url.indexOf('registration') >= 0) {
                    return next.handle(req);
                }

                let headers = req.headers;

                if (userId) {
                    headers = headers.set('X-ONESIGNAL-USER-ID', userId);
                }

                appName = this.getAppName(appName);

                if (appName) {
                    headers = headers.set('X-CC-APP-ENTRY-POINT', appName);
                }

                const clone = req.clone({ headers });
                return next.handle(clone);
            })
        );
    }

    private getAppName(appName: string): string {
        const isRunningInContainer = this.containerService.isRunningInContainer(appName);
        if (isRunningInContainer) {
            return 'container';
        }

        const portalConfig = this.portalService.getPortalConfigForApp(appName);

        if (portalConfig && portalConfig.portal) {
            return portalConfig.portal;
        }

        return appName;
    }
}
