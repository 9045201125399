import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ExpandedSession } from '@features/agenda/agenda-session/models/expanded-session.model';
import { AgendaModuleEnabledFeatures } from '@shared/api';

@Component({
    selector: 'cc-agenda-card-expanded',
    templateUrl: './agenda-card-expanded.component.html',
    styleUrl: './agenda-card-expanded.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgendaCardExpandedComponent {
    @Input({ required: true })
    public expandedSession: ExpandedSession;

    @Input({ required: true })
    public enabledFeatures: AgendaModuleEnabledFeatures;
}
