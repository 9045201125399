import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PeopleFilterActions } from '../actions/people-filter.actions';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { PeopleApiService } from '@features/people/services/people-api.service';
import { PeopleApiActions } from '../actions/people-api.actions';
import { withLatestFrom, switchMap, map, catchError, of } from 'rxjs';
import { PeopleListActions } from '../actions/people-list.actions';

@Injectable()
export class PeopleFilterEffects {
    fetchFilters$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PeopleFilterActions.fetchFilters, PeopleListActions.enterPage),
            withLatestFrom(this.appCoreFacadeService.getAppName()),
            switchMap(([_, appName]) => {
                return this.peopleApiService.getPeopleTags(appName).pipe(
                    map((filters) => PeopleFilterActions.fetchFiltersSuccess({ filters })),
                    catchError((error) => of(PeopleApiActions.httpError({ error })))
                );
            })
        )
    );

    constructor(
        private actions$: Actions,
        private appCoreFacadeService: AppCoreFacadeService,
        private peopleApiService: PeopleApiService
    ) {}
}
