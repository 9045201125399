import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';

export const NativeClassName = 'native';
export const BrowserClassName = 'browser';

@Injectable({
    providedIn: 'root'
})
export class NativeClassesService {
    addNativeClasses(): void {
        if (!Capacitor.isNativePlatform()) {
            document.body.classList.add(BrowserClassName);
            return;
        }

        document.body.classList.add(NativeClassName);

        const platform = Capacitor.getPlatform();
        if (platform) {
            document.body.classList.add('native-' + platform.toLowerCase());
        }
    }
}
