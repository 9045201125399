import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { PeopleFacadeService } from '@features/people/services/people-facade.service';
import { People, PeopleVisibility } from '@shared/api';
import { Observable } from 'rxjs';

export type PersonName = Pick<People, 'first_name' | 'last_name' | 'pronouns' | 'title'>;

@Component({
    selector: 'cc-person-name',
    templateUrl: './person-name.component.html',
    styleUrls: ['./person-name.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PersonNameComponent implements OnInit {
    @Input()
    public person: PersonName;

    @Input()
    public excludes: string | Array<keyof PersonName>;

    public personVisibility$: Observable<PeopleVisibility>;

    constructor(private peopleFacadeService: PeopleFacadeService) {}

    public ngOnInit(): void {
        this.personVisibility$ = this.peopleFacadeService.getVisibility();
    }
}
