export interface LiveDisplay {
    id?: number;
    headers?: Array<number>;
    footers?: Array<number>;
    app: string;
    background_color?: string;
    toggle_background?: LiveDisplay.ToggleBackgroundEnum;
    name: string;
    transition_style?: string;
    transition_duration?: number;
    screen_duration?: number;
    is_visible?: boolean;
    background_image?: number;
}

export namespace LiveDisplay {
    export enum ToggleBackgroundEnum {
        Color = 'background_color',
        Image = 'background_image'
    }
}
