<ion-card class="notification-list-item__card" [ngClass]="{ unread: !notification.read }">
    <ion-item>
        <ion-label class="ion-text-wrap">
            <div class="notification-list-item__content">
                <div class="notification-list-item__header">
                    <div class="notification-list-item__text">
                        <h2 [style.font-weight]="!notification.read ? 600 : 400">{{ title }}</h2>
                        <p *ngFor="let message of messages">{{ message }}</p>
                        <p class="notification-list-item__time">{{ notification.created | dateRelative }}</p>
                    </div>
                </div>

                <div class="notification-list-item__actions">
                    <ion-button
                        *ngIf="!notification.read"
                        class="notification-list-item__action"
                        fill="clear"
                        [tooltip]="'ALERTS_MARK_READ' | translate"
                        [attr.aria-label]="'ALERTS_MARK_READ' | translate"
                        [options]="tooltipOptions"
                        (click)="markAsRead.emit()"
                    >
                        <i slot="start" class="material-icons-outlined" aria-hidden="true">mark_email_read</i>
                    </ion-button>
                    <ion-button
                        class="notification-list-item__action"
                        fill="clear"
                        [tooltip]="(!notification.archived ? 'ALERTS_ARCHIVE' : 'ALERTS_UNARCHIVE') | translate"
                        [attr.aria-label]="(!notification.archived ? 'ALERTS_ARCHIVE' : 'ALERTS_UNARCHIVE') | translate"
                        [options]="tooltipOptions"
                        (click)="onToggleArchive()"
                    >
                        <i slot="start" class="material-icons-outlined" aria-hidden="true">{{
                            !notification.archived ? 'archive' : 'unarchive'
                        }}</i>
                    </ion-button>
                </div>
            </div>
            <div class="notification-list-item__footer-buttons">
                <ion-button class="notification-list-item__footer-button" (click)="actionClicked.emit()">{{
                    'ALERTS_NOTIFICATION_VIEW' | translate
                }}</ion-button>
            </div>
        </ion-label>
    </ion-item>
</ion-card>
