import { createSelector } from '@ngrx/store';

import { BannerAppState, BannerState, bannerStoreKey } from '../models/banner.state.model';

const bannerState = (state: BannerAppState) => state[bannerStoreKey];

const globalBannerConfig = createSelector(bannerState, (state: BannerState) => state.globalBanner);

const selectCookieConfig = createSelector(bannerState, (state: BannerState) => state.cookies);
const selectShowCookieCustomisation = createSelector(
    bannerState,
    (state: BannerState) => state.cookies?.showCustomisation
);
const selectFunctionalConsent = createSelector(bannerState, (state: BannerState) => state.cookies?.allow_functional);
const selectPerformanceConsent = createSelector(bannerState, (state: BannerState) => state.cookies?.allow_performance);
const selectAnalyticsConsent = createSelector(bannerState, (state: BannerState) => state.cookies?.allow_analytics);
const selectAdvertisingConsent = createSelector(bannerState, (state: BannerState) => state.cookies?.allow_advertising);
const selectCookiesDismissed = createSelector(bannerState, (state: BannerState) => state.cookies?.cookies_dismissed);

export const BannerSelectors = {
    globalBannerConfig,
    selectCookieConfig,
    selectFunctionalConsent,
    selectPerformanceConsent,
    selectAnalyticsConsent,
    selectAdvertisingConsent,
    selectShowCookieCustomisation,
    selectCookiesDismissed
};
