<ion-item class="search-input__item" lines="none">
    <i class="material-icons ignore-rtl" aria-hidden="true" slot="start">search</i>
    <ion-input
        type="text"
        name="search"
        [ariaLabel]="'SHARED_SEARCH' | translate"
        [attr.placeholder]="'SHARED_SEARCH' | translate"
        [formControl]="searchControl"
    ></ion-input>
</ion-item>
