import { routerReducer } from '@ngrx/router-store';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { appCoreReducer } from './store/app-core/reducers/app-core.reducer';
import { AppState } from './store/app-state.model';
import { authenticationReducer } from './store/authentication/reducers/authentication.reducer';
import { globalSettingsReducer } from './store/global-settings/reducers/global-settings.reducer';

export const appReducers: ActionReducerMap<AppState> = {
    router: routerReducer,
    appCore: appCoreReducer,
    authentication: authenticationReducer,
    globalSettings: globalSettingsReducer
};

export const metaReducers: MetaReducer<AppState>[] = !environment.PRODUCTION ? [] : [];
