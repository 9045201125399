import { Directive, HostBinding } from '@angular/core';

/* TODO: Review if this directive is still required after upgrading to ionic v6 */

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[button], ion-button'
})
export class ButtonAccessibilityDirective {
    @HostBinding('attr.role')
    public role = 'button';
}
