import { Injectable } from '@angular/core';
import { chatEmojis } from '@shared/emoji/constants/chat-emojis.constant';

@Injectable({
    providedIn: 'root'
})
export class ChatEmojiService {
    public replaceTextWithEmojis(text: string): string {
        for (const emoji of chatEmojis) {
            text = text.replace(emoji.regex, emoji.replacement);
        }

        return text;
    }
}
