import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { NavigationItemState } from '../../models/navigation-item-state.model';

export const NavigationHistoryActions = createActionGroup({
    source: 'Navigation History',
    events: {
        Stamp: emptyProps(),
        'Stamp Success': props<{ item: NavigationItemState }>(),
        'Update Success': props<{ item: NavigationItemState }>(),
        Forget: emptyProps(),
        Back: emptyProps()
    }
});
