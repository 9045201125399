import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { PortalApp } from '@shared/api';

import { AppRoutingActions } from '../../../core/root-store/store/app-routing/actions/app-routing.actions';
import { AppModuleRoutes } from '../../../core/routing/constants/module-routes.constant';
import { AppRoutingFacadeService } from '../../../core/routing/services/app-routing-facade.service';
import { StorageService } from '../../../core/storage/services/storage.service';
import { PortalConfigStorageKeys } from '../constants/portal-config-storage-keys.contant';
import { PortalPageRoutes } from '../constants/portal-page-routes.constant';
import { PortalConfig } from '../interfaces/portal-config.interface';
import { PortalListPageActions } from '../store/actions/portal-list-page.actions';
import { PortalFacadeService } from './portal-facade.service';

@Injectable({
    providedIn: 'root'
})
export class PortalService {
    constructor(
        private storageService: StorageService,
        private portalFacadeService: PortalFacadeService,
        private appRoutingFacadeService: AppRoutingFacadeService,
        private location: Location
    ) {}

    public setPortalConfigForApp(appName: string, portalConfig: PortalConfig): void {
        const key = this.getConfigKeyForApp(appName);

        this.storageService.put(key, portalConfig);
    }

    public getPortalConfigForApp(appName: string): PortalConfig {
        const key = this.getConfigKeyForApp(appName);

        return this.storageService.get(key);
    }

    public clearPortalConfigForApp(appName: string): void {
        const key = this.getConfigKeyForApp(appName);

        this.storageService.clear(key);
    }

    public returnToParentPortal(appName: string): void {
        const portalConfig = this.getPortalConfigForApp(appName);

        this.clearPortalConfigForApp(appName);
        this.goToPortalState(portalConfig.portal);
    }

    public goToPortalApp(targetApp: PortalApp, portalUrl: string, portalName: string): void {
        const portalConfig: PortalConfig = {
            target: targetApp.related_app,
            portal: portalUrl,
            portal_name: portalName
        };

        if (
            targetApp.hide_description &&
            !this.location.path().includes(`${AppModuleRoutes.portal}/${PortalPageRoutes.portalInfo}`)
        ) {
            this.appRoutingFacadeService.dispatch(
                AppRoutingActions.goToAppModule({
                    urlSegments: [targetApp.module, AppModuleRoutes.portal, PortalPageRoutes.portalInfo, targetApp.id]
                })
            );
            return;
        }

        this.setPortalConfigForApp(targetApp.related_app, portalConfig);
        this.portalFacadeService.dispatch(PortalListPageActions.preauthNavToPortalApp({ portalApp: targetApp }));
    }

    public goToPortalState(target: string): void {
        // When a child portal attempts to navigate back after refreshing the cache, lazy loaded portal effects haven't been loaded
        // so navigation fails and clears the portal cookie making it impossible to return. Calling the router action directly as that'll be available on app load
        this.appRoutingFacadeService.dispatch(AppRoutingActions.goToPage({ urlSegments: [target] }));
    }

    private getConfigKeyForApp(appName: string): string {
        return PortalConfigStorageKeys.appConfig(appName);
    }
}
