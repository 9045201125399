export interface LiveDisplayScreenSocialMedia {
    id?: number;
    headers?: Array<number>;
    footers?: Array<number>;
    twitter_streams?: Array<number>;
    background_color?: string;
    toggle_background?: LiveDisplayScreenSocialMedia.ToggleBackgroundEnum;
    title?: string;
    order?: number;
    is_visible?: boolean;
    card_color?: string;
    card_text_color?: string;
    layout?: LiveDisplayScreenSocialMedia.LayoutEnum;
    social_media?: LiveDisplayScreenSocialMedia.SocialMediaEnum;
    background_image?: number;
    live_display: number;
}

export namespace LiveDisplayScreenSocialMedia {
    export enum ToggleBackgroundEnum {
        Color = 'background_color',
        Image = 'background_image'
    }
    export enum LayoutEnum {
        Grid = 'grid',
        Collage = 'collage',
        Single = 'single'
    }
    export enum SocialMediaEnum {
        Twitter = 'twitter',
        Facebook = 'facebook',
        Instagram = 'instagram'
    }
}
