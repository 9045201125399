import { Injectable } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { ActionPopoverComponent } from '@shared/action-popover/components/action-popover/action-popover.component';
import { ActionPopoverType } from '@shared/action-popover/enums/action-popover-type.enum';

@Injectable({
    providedIn: 'root'
})
export class ActionPopoverService {
    constructor(private popoverController: PopoverController) {}

    async display(actionType: ActionPopoverType, actionContent: string, event: MouseEvent): Promise<void> {
        const popover = await this.popoverController.create({
            component: ActionPopoverComponent,
            componentProps: {
                actionType,
                actionContent
            },
            event,
            cssClass: 'action-popover'
        });
        await popover.present();
    }
}
