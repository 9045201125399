import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AlertsState, alertsStoreKey } from '../models/alerts.state.model';
import { alertsEntityAdapter } from '../reducers/alerts.reducer';

const selectAlertsState = createFeatureSelector<AlertsState>(alertsStoreKey);

export const alertAdapterSelectors = alertsEntityAdapter.getSelectors();

const selectAlerts = createSelector(selectAlertsState, (state) => alertAdapterSelectors.selectAll(state.alerts));

const selectUnreadAlerts = createSelector(selectAlertsState, (state) =>
    alertAdapterSelectors.selectAll(state.alerts).filter((alert) => !alert.read)
);
const selectUnreadAlertIds = createSelector(selectAlertsState, (state) =>
    alertAdapterSelectors
        .selectAll(state.alerts)
        .filter((alert) => !alert.read)
        .map((alert) => alert.id)
);
const selectUnreadAlertsCount = createSelector(selectAlertsState, (state) => state.unreadNotificationCount);

export const AlertsSelectors = {
    currentTab: createSelector(selectAlertsState, (state) => state.currentTab),
    alertsLoading: createSelector(selectAlertsState, (state) => state.alerts.loading),
    alertIds: createSelector(selectAlertsState, (state) => state.alerts.ids),
    unreadAlertsCount: selectUnreadAlertsCount,
    alerts: selectAlerts,
    unreadAlerts: selectUnreadAlerts,
    unreadAlertIds: selectUnreadAlertIds
};
