import { LocationMap } from '@api';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Size } from '@shared/models/size.model';

export const MapDetailActions = createActionGroup({
    source: 'Map Detail',
    events: {
        'Enter Detail': props<{ mapId: number; pindrop: number }>(),
        'Load Gmap': props<{ map: LocationMap; url: string }>(),
        'Load Leaflet': props<{ map: LocationMap; imageSize: Size; pindrop: number; mapSize: Size }>(),
        'See All': emptyProps(),
        'Leave Page': emptyProps(),
        'Load failure': emptyProps()
    }
});
