import { AppCoreState } from '../models/app-core-state.model';
import { createReducer, on, Action } from '@ngrx/store';
import { AppCoreActions } from '../actions/app-core.actions';
import { AppCoreApiActions } from '../actions/app-core-api.actions';

export const initialState: AppCoreState = {
    appInitialising: true,
    appName: undefined,
    appSettings: undefined,
    oneSignalUserId: undefined
};

const appCoreReducerFn = createReducer(
    initialState,
    on(AppCoreActions.initialiseAppSuccess, (state) => ({
        ...state,
        appInitialising: false
    })),
    on(AppCoreActions.initialiseApp, (state, { appName }) => ({
        ...initialState,
        appName
    })),
    on(AppCoreActions.setAppName, (state, { appName }) => ({
        ...state,
        appName
    })),
    on(AppCoreApiActions.loadAppSettingsSuccess, (state, { appSettings }) => ({
        ...state,
        appSettings
    })),
    on(AppCoreActions.initialisePushNotificationsSuccess, (state, { osUserId }) => {
        return { ...state, oneSignalUserId: osUserId };
    })
);

export function appCoreReducer(state: AppCoreState | undefined, action: Action): AppCoreState {
    return appCoreReducerFn(state, action);
}
