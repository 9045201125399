import { Directive, ElementRef } from '@angular/core';
@Directive({
    selector: '[ccIonicScrollbar]'
})
export class ScrollbarDirective {
    constructor(el: ElementRef) {
        const stylesheet = `
            .inner-scroll {
                --overflow: auto;
            }

            @supports (overflow: overlay) {
                .inner-scroll {
                    --overflow: overlay;
                }
            }

            ::-webkit-scrollbar {
                width: 10px;
            }
            
            ::-webkit-scrollbar-thumb {
                border: 2px solid rgba(255, 255, 255, 0);
                background-clip: padding-box;
                background-color: rgba(var(--cc-scrollbar-thumb-color), 0);
                opacity: 0.2;
                border-radius: 10px;
            }
            
            ::-webkit-scrollbar-button {
                display:none;
            }
            
            *:hover::-webkit-scrollbar-thumb {
                background-color: rgba(var(--cc-scrollbar-thumb-color), 0.5);
            }

            @media screen and (max-width: 768px) {
                ::-webkit-scrollbar-thumb {
                    background-color: rgba(var(--cc-scrollbar-thumb-color), 0.8);
                }
            }
            
            ::-webkit-scrollbar-thumb:hover {
                cursor: pointer;
                background-color: rgba(var(--cc-scrollbar-thumb-color), 0.8);
            }
        `;

        if (!el.nativeElement.shadowRoot) {
            console.warn('Ionic Scrollbar Directive was applied to a component with no shadow root.');
            return;
        }

        const barStyle = document.createElement('style');
        barStyle.append(stylesheet);
        el.nativeElement.shadowRoot.appendChild(barStyle);
    }
}
