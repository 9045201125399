import { NgModule } from '@angular/core';
import { LanguageSelectionComponent } from './components/language-selection/language-selection.component';
import { AppCommonModule } from '@common/common.module';

@NgModule({
    declarations: [LanguageSelectionComponent],
    imports: [AppCommonModule],
    exports: [LanguageSelectionComponent]
})
export class LanguagesModule {}
