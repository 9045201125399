import { NgModule } from '@angular/core';
import { ChatModule } from './chat/chat.module';
import { AlertsModule } from './alerts/alerts.module';
import { VideoCallsModule } from './video-calls/video-calls.module';
import { GlobalScannerModule } from './global-scanner/global-scanner.module';
import { ChallengeCompletionModule } from './challenge-completion/challenge-completion.module';

@NgModule({
    exports: [AlertsModule, ChatModule, VideoCallsModule, GlobalScannerModule, ChallengeCompletionModule]
})
export class FeaturesModule {}
