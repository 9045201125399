import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MuxPlayerComponent } from './components/mux-player/mux-player.component';
import { AppCommonModule } from '@common/common.module';

@NgModule({
    declarations: [MuxPlayerComponent],
    imports: [AppCommonModule],
    exports: [MuxPlayerComponent],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA // Needed to use mux player web component.
    ]
})
export class MuxModule {}
