import { ComponentRef, Directive, Injector, Input, OnChanges, OnDestroy, ViewContainerRef } from '@angular/core';
import { AuthenticationFacadeService } from '@core/authentication/services/authentication-facade.service';
import { HtmlService } from '@features/html/services/html/html.service';
import { MableCustomValue, People } from '@shared/api';
import { combineLatest, Subscription } from 'rxjs';

const profileFields: Array<keyof People> = [
    'address',
    'about',
    'app_picture',
    'badge_code',
    'company',
    'email',
    'first_name',
    'last_name',
    'phone',
    'title',
    'job_title',
    'pronouns',
    'location',
    'website'
];

@Directive({
    selector: '[ccCompileHtml]'
})
/*
WARNING: Change detection will only work in components compiled from this directive,
if the component you're using is set to use the default change detection (not on push)
*/
export class CompileHtmlDirective implements OnChanges, OnDestroy {
    @Input('ccCompileHtml')
    public html: string;

    // List of embedded components which must be cleaned up
    private embeddedComponents: ComponentRef<any>[] = [];
    private subscription: Subscription;

    constructor(
        private viewContainerRef: ViewContainerRef,
        private htmlService: HtmlService,
        private injector: Injector,
        private authenticationFacadeService: AuthenticationFacadeService
    ) {}

    public ngOnChanges(): void {
        this.subscription?.unsubscribe();

        this.subscription = combineLatest({
            person: this.authenticationFacadeService.getAuthenticatedPerson(),
            customValues: this.authenticationFacadeService.getUserCustomFieldValues()
        }).subscribe(({ person, customValues }) => {
            this.cleanupEmbeddedComponents();

            const tokenMap = this.generateTokenMap(person, customValues);

            this.html = this.htmlService.interpolateCustomValues({ html: this.html, tokens: tokenMap });
            this.viewContainerRef.element.nativeElement.innerHTML = this.html;

            this.embeddedComponents = [
                ...this.htmlService.embedComponents(this.viewContainerRef, this.injector),
                ...this.htmlService.replaceOpenModuleDirectiveWithComponents(this.viewContainerRef, this.injector)
            ];
        });
    }

    public ngOnDestroy(): void {
        this.cleanupEmbeddedComponents();
        this.subscription?.unsubscribe();
    }

    private generateTokenMap(
        person: People | undefined,
        customValues: MableCustomValue[]
    ): Record<string, string> | undefined {
        if (!person) {
            return undefined;
        }

        const tokenMap: Record<string, string> = {};

        profileFields.forEach((field) => {
            if (!person?.[field]) {
                return;
            }

            tokenMap[field] = person[field].toString();
        });

        customValues?.forEach((customValue) => {
            const key = customValue.custom_field as string;

            tokenMap[key] = customValue.value;
        });

        person.peoplegroups?.forEach((group) => {
            tokenMap[group] = 'true';
        });

        return tokenMap;
    }

    private cleanupEmbeddedComponents(): void {
        this.htmlService.destroyComponents(this.embeddedComponents);
    }
}
