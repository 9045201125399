import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { fadeIn, fadeOut } from '@common/animations/animations';

@Component({
    selector: 'cc-alert-survey-container',
    templateUrl: './alert-survey-container.component.html',
    styleUrls: ['./alert-survey-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [fadeIn(), fadeOut()]
})
export class AlertSurveyContainerComponent {
    @Input()
    public pollGroupId: number;

    public loadSurvey: boolean = false;

    public loadSurveyClicked(): void {
        this.loadSurvey = true;
    }
}
