import { NgModule } from '@angular/core';
import { ActionPopoverComponent } from './components/action-popover/action-popover.component';
import { ActionPopoverDirective } from './directives/action-popover.directive';
import { AppCommonModule } from '@common/common.module';

@NgModule({
    declarations: [ActionPopoverComponent, ActionPopoverDirective],
    exports: [ActionPopoverDirective],
    imports: [AppCommonModule]
})
export class ActionPopoverModule {}
