import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NotificationType } from '@capacitor/haptics';
import { SoundEffects } from '@common/constants/sound-effects.enum';
import { SoundService } from '@common/services/sound/sound.service';
import { CapacitorService } from '@core/native/services/capacitor.service';
import { ModalController } from '@ionic/angular';
import { AnimationOptions } from 'ngx-lottie';
import { timer } from 'rxjs';

@Component({
    selector: 'cc-challenge-failed-modal',
    templateUrl: './challenge-failed-modal.component.html',
    styleUrl: './challenge-failed-modal.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChallengeFailedModalComponent implements OnInit {
    public readonly incorrectAnimationOptions: AnimationOptions = {
        autoplay: true,
        path: '/assets/lottie-animations/incorrect.lottie.json',
        loop: false
    };

    constructor(
        private modalController: ModalController,
        private soundService: SoundService,
        private capacitorService: CapacitorService
    ) {}

    public ngOnInit(): void {
        timer(2000).subscribe(() => {
            this.modalController.dismiss();
        });

        this.soundService.play(SoundEffects.challengeFailed, 0.1);

        if (this.capacitorService.isNative) {
            this.capacitorService.haptics.notification({ type: NotificationType.Error });
        }
    }
}
