import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@core/environment/services/environment.service';
import { MfaCalibrateApiResponse } from '@features/mfa/models/mfa-calibrate-api-response.model';
import { MfaDevice } from '@features/mfa/models/mfa-device.model';
import { MfaQr } from '@features/mfa/models/mfa-qr';
import { MfaValidateApiResponse } from '@features/mfa/models/mfa-validate-api-response.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MfaApiService {
    constructor(
        private httpClient: HttpClient,
        private environmentService: EnvironmentService
    ) {}

    public getDevices(appName: string): Observable<MfaDevice[]> {
        const url = `${this.environmentService.API_URL}${MfaEndpoints.devices(appName)}`;
        return this.httpClient.get<MfaDevice[]>(url);
    }

    public updateDevice(appName: string, deviceId: number, device: Partial<MfaDevice>): Observable<MfaDevice> {
        const url = `${this.environmentService.API_URL}${MfaEndpoints.device(appName, deviceId)}`;
        return this.httpClient.patch<MfaDevice>(url, device);
    }

    public getQr(appName: string): Observable<MfaQr> {
        const url = `${this.environmentService.API_URL}${MfaEndpoints.qr(appName)}`;
        return this.httpClient.get<MfaQr>(url);
    }

    public calibrate(appName: string, otpToken: string): Observable<MfaCalibrateApiResponse> {
        const url = `${this.environmentService.API_URL}${MfaEndpoints.calibrate(appName)}`;
        return this.httpClient.post<MfaCalibrateApiResponse>(url, { otp_token: otpToken });
    }

    public validate(appName: string, otpToken: string, trust: boolean): Observable<MfaValidateApiResponse> {
        const url = `${this.environmentService.API_URL}${MfaEndpoints.validate(appName)}`;
        return this.httpClient.post<MfaValidateApiResponse>(url, { otp_token: otpToken, remember_device: trust });
    }

    public deleteDevice(appName: string, deviceId: number): Observable<void> {
        const url = `${this.environmentService.API_URL}${MfaEndpoints.device(appName, deviceId)}`;
        return this.httpClient.delete<void>(url);
    }

    public sendEmail(appName: string): Observable<void> {
        const url = `${this.environmentService.API_URL}${MfaEndpoints.sendEmail(appName)}`;
        return this.httpClient.post<void>(url, {});
    }
}

export const MfaEndpoints = {
    devices: (appName: string) => `apps/${appName}/me/mfa-devices/`,
    device: (appName: string, deviceId: number) => `${MfaEndpoints.devices(appName)}${deviceId}/`,
    qr: (appName: string) => `${MfaEndpoints.devices(appName)}qr-code/`,
    calibrate: (appName: string) => `${MfaEndpoints.devices(appName)}calibrate/`,
    validate: (appName: string) => `${MfaEndpoints.devices(appName)}validate-token/`,
    sendEmail: (appName: string) => `apps/${appName}/send-mfa-email/`
};
