import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BannerEndpoints } from '@core/banner/constants/banner-endpoints.constant';
import { GlobalBannerConfig } from '@core/banner/interfaces/global-banner-config.interface';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BannerApiService {
    constructor(private httpClient: HttpClient) {}

    public getBannerConfig(app: string): Observable<GlobalBannerConfig> {
        const url = BannerEndpoints.globalBannerConfig(app);

        return this.httpClient.get<GlobalBannerConfig>(url);
    }
}
