<div class="lightbox-image-dialog__container">
    <ion-button
        class="ion-no-margin ion-no-padding lightbox-image-dialog__close-button"
        fill="clear"
        shape="round"
        color="default"
        [attr.aria-label]="'LIGHTBOX_IMAGE_CLOSE' | translate"
        (click)="closeDialog()"
    >
        <i class="material-icons" aria-hidden="true">close</i>
    </ion-button>

    <img
        class="lightbox-image-dialog__background-image"
        alt=""
        [src]="src | imageDimensions: 48 : 48"
        (click)="closeDialog()"
    />
    <img class="lightbox-image-dialog__image" alt="" [src]="src" />
</div>
