import { HttpErrorResponse } from '@angular/common/http';
import { MfaQr } from '@features/mfa/models/mfa-qr';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { App, MableCustomValue, People, UserAuthInfo, UserEmailInfo } from '@shared/api';
import { IdentityProvider } from '@shared/api/models/IdentityProvider';

export const AuthenticationApiActions = createActionGroup({
    source: 'Authentication Api',
    events: {
        'Initialise Authentication Success': props<{ authenticatedPerson: People }>(),
        'Initialise Authentication Failure': props<{ error: HttpErrorResponse }>(),
        'Login Get Token Success': props<{ appName: string; userAuthInfo: UserAuthInfo }>(),
        'Login Success': props<{
            userAuthInfo: UserAuthInfo;
            hasDismissedMfa: boolean;
            appSettings: App;
            isPasscodeRequired: boolean;
        }>(),
        'Login Failure': props<{ error: HttpErrorResponse }>(),
        'Refresh Authenticated Person Success': props<{ authenticatedPerson: People }>(),
        'Refresh Authenticated Person Failure': props<{ error: HttpErrorResponse }>(),
        'Get Download Token Success': props<{ token: string; expires: string }>(),
        'Get Download Token Failure': props<{ error: HttpErrorResponse }>(),
        'Check Email Address Success': props<{ userEmailInfo: UserEmailInfo }>(),
        'Check Email Address Failure': props<{ error: HttpErrorResponse }>(),
        'Submit Passcode Success': props<{ appName: string; passcode: string }>(),
        'Submit Passcode Failure': props<{ error: HttpErrorResponse }>(),
        'Register User Success': props<{ email: string; password: string }>(),
        'Register User Failure': props<{ error: HttpErrorResponse }>(),
        'Request Reset Password Success': emptyProps(),
        'Request Reset Password Failure': props<{ error: HttpErrorResponse }>(),
        'Request Verification Email Success': emptyProps(),
        'Request Verification Email Failure': props<{ error: HttpErrorResponse }>(),
        'Verify Email Address Success': emptyProps(),
        'Verify Email Address Failure': props<{ error: HttpErrorResponse }>(),
        'Update Password Success': emptyProps(),
        'Update Password Failure': props<{ error: HttpErrorResponse }>(),
        'Get App Identity Providers Success': props<{ providers: IdentityProvider[] }>(),
        'Get App Identity Providers Failure': props<{ error: HttpErrorResponse }>(),
        'Exchange Auth Token Success': props<{ userAuthInfo: UserAuthInfo; appName: string; otpSignature: string }>(),
        'Exchange Auth Token Failure': props<{ error: HttpErrorResponse }>(),
        'Mfa Get Qr Success': props<{ mfaQrCode: MfaQr }>(),
        'Mfa Get Qr Failure': props<{ error: HttpErrorResponse }>(),
        'Mfa Validate Success': props<{ otpSignature: string }>(),
        'Mfa Validate Failure': props<{ error: HttpErrorResponse }>(),
        'Mfa Calibrate Success': props<{ otpSignature: string }>(),
        'Mfa Calibrate Failure': props<{ error: HttpErrorResponse }>(),
        'Manual Mfa Calibrate Success': props<{ otpSignature: string }>(),
        'Mfa Send Email Success': emptyProps(),
        'Mfa Send Email Failure': props<{ error: HttpErrorResponse }>(),
        'Get Authenticated Person Success': props<{ authenticatedPerson: People }>(),
        'Get Authenticated Person Failure': props<{ error: HttpErrorResponse }>(),
        'Authentication Not Available': emptyProps(),
        'Sync custom field values': emptyProps(),
        'Set user custom field values': props<{ customFieldValues: MableCustomValue[] }>()
    }
});
