import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { EmojiPickerPopoverService } from '@shared/emoji/services/emoji-picker-popover/emoji-picker-popover.service';

@Component({
    selector: 'cc-chat-detail-footer',
    templateUrl: './chat-detail-footer.component.html',
    styleUrls: ['./chat-detail-footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatDetailFooterComponent {
    @Output()
    public send: EventEmitter<string> = new EventEmitter<string>();

    public messageControl = new FormControl<string>('', [Validators.maxLength(500), Validators.required]);

    constructor(private emojiPickerPopoverService: EmojiPickerPopoverService) {}

    public onSend(): void {
        if (!this.messageControl.valid) {
            return;
        }

        this.send.emit(this.messageControl.value);
        this.messageControl.setValue('');
    }

    public onEmojiPickerClicked(event: MouseEvent): void {
        this.emojiPickerPopoverService.openEmojiPicker(event).then((emoji) => {
            if (!emoji) {
                return;
            }

            const currentValue = this.messageControl.value;
            const isEmpty = !currentValue || currentValue.length === 0;

            // If it's empty set the value and send the message.
            if (isEmpty) {
                this.messageControl.setValue(emoji);
                this.onSend();
                return;
            }

            // If it's not empty then just add the emoji to the end of the current message.
            const lastValueIsSpace = currentValue.slice(-1) === ' ';
            this.messageControl.setValue(`${currentValue}${lastValueIsSpace ? '' : ' '}${emoji}`);
        });
    }
}
