import { NgModule } from '@angular/core';
import { ExperimentalSwitchComponent } from './components/experimental-switch/experimental-switch.component';
import { ExperimentalDirective } from './directives/experimental/experimental.directive';
import { AppCommonModule } from '@common/common.module';

@NgModule({
    declarations: [ExperimentalSwitchComponent, ExperimentalDirective],
    imports: [AppCommonModule],
    exports: [ExperimentalSwitchComponent, ExperimentalDirective]
})
export class EnvironmentModule {}
