import { NgModule } from '@angular/core';
import { AppCommonModule } from '@common/common.module';
import { LightboxImageDialogComponent } from './components/lightbox-image-dialog/lightbox-image-dialog.component';
import { LightboxImageDirective } from './directives/lightbox-image/lightbox-image.directive';
import { AudioPlayerComponent } from './components/audio-player/audio-player.component';

@NgModule({
    imports: [AppCommonModule],
    declarations: [LightboxImageDialogComponent, LightboxImageDirective, AudioPlayerComponent],
    exports: [LightboxImageDirective, AudioPlayerComponent]
})
export class MediaModule {}
