import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, props } from '@ngrx/store';
import { LiveStream } from '@features/live-stream/interfaces/stream';
import { AgendaModule } from '@shared/api';
import { PlaybackTokens } from '@shared/mux/interfaces/playback-tokens.interface';
import { ExpandedSession } from '../../models/expanded-session.model';
import { NowNextResponse } from '../../interfaces/now-next-response.interface';

export const AgendaSessionApiActions = createActionGroup({
    source: 'Agenda Api',
    events: {
        'Fetch Session Page Success': props<{ session: ExpandedSession; module: AgendaModule }>(),
        'Fetch Session Page Failure': props<{ error: HttpErrorResponse }>(),
        'Fetch Playback Tokens Success': props<{ playbackTokens: PlaybackTokens }>(),
        'Fetch Playback Tokens Failure': props<{ error: string }>(),
        'Fetch Livestream Success': props<{ liveStream: LiveStream }>(),
        'Fetch Livestream Failure': props<{ error: HttpErrorResponse }>(),
        'Fetch Now Next Success': props<{ response: NowNextResponse }>(),
        'Fetch Now Next Failure': props<{ error: HttpErrorResponse }>()
    }
});
