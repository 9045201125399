import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { AppRoutingActions } from '../../../core/root-store/store/app-routing/actions/app-routing.actions';
import { AppPageRoutes } from '../../../core/routing/constants/app-page-routes.constant';
import { AppRoutingFacadeService } from '../../../core/routing/services/app-routing-facade.service';
import { AuthenticationFacadeService } from '@core/authentication/services/authentication-facade.service';
import { AuthenticationActions } from '@core/root-store/store/authentication/actions/authentication.actions';

@Injectable({
    providedIn: 'root'
})
export class LoginPromptService {
    constructor(
        private alertController: AlertController,
        private translateService: TranslateService,
        private location: Location,
        private appRoutingFacadeService: AppRoutingFacadeService,
        private authenticationFacadeService: AuthenticationFacadeService
    ) {}

    async showPrompt(message: string, redirectUrl?: string): Promise<void> {
        const translation = await this.translateService.get(message).toPromise();
        const alert = await this.alertController.create({
            header: 'Log In?',
            message: translation,
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary'
                },
                {
                    text: 'Login',
                    handler: () => this.navigateToLoginWithRedirect(redirectUrl)
                }
            ]
        });

        await alert.present();
    }

    navigateToLoginWithRedirect(redirectUrl?: string): void {
        const redirect = redirectUrl || this.location.path();
        this.navigateToLogin(redirect);
    }

    navigateToLogin(redirect?: string): void {
        if (redirect) {
            this.authenticationFacadeService.dispatch(AuthenticationActions.setRedirectUrl({ redirectUrl: redirect }));
        }
        this.appRoutingFacadeService.dispatch(AppRoutingActions.goToAppPage({ urlSegments: [AppPageRoutes.login] }));
    }
}
