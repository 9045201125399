import { RatingPoll } from './RatingPoll';
import { TextPoll } from './TextPoll';
import { ChoicePoll } from './ChoicePoll';

export interface Poll {
    order?: number;
    poll_type: Poll.PollTypeEnum;
    updated?: Date;
    created?: Date;
    id?: number;
    pollgroup: number;
    required?: boolean;
    is_active?: boolean;
    title: string;
    description?: string;
    ratingpoll: RatingPoll;
    textpoll: TextPoll;
    choicepoll: ChoicePoll;
    single_response_only?: boolean;
}

export namespace Poll {
    export enum PollTypeEnum {
        Text = 'text',
        Choice = 'choice',
        Rating = 'rating'
    }
}
