import { HttpErrorResponse } from '@angular/common/http';
import { PollSubmission } from '@features/live-polling/models/poll-submission.model';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { PollResponse } from '@shared/api';

export const LivePollingApiActions = createActionGroup({
    source: 'Livepolling Api',
    events: {
        'Submit Poll Success': props<{ submission: PollSubmission }>(),
        'Submit Poll Failure': props<{ error: HttpErrorResponse }>(),
        'Get Livepoll Page Data Success': props<{
            pollGroup;
            livePollGroup;
            pollResponse;
            proxyResponses: PollResponse[];
            allowedNumberOfResponses: number;
        }>(),
        'Get Livepoll Page Data Failure': props<{ error: HttpErrorResponse }>(),
        'Get Livepolling Landing Page Data Success': props<{ livePollingModuleId; livePollingModule }>(),
        'Get Livepolling Landing Page Data Failure': props<{ error: HttpErrorResponse }>(),
        'Submit Proxy Votes Success': props<{ livePollGroupId: number }>(),
        'Submit Proxy Votes Failure': emptyProps()
    }
});
