import { environment } from 'src/environments/environment';

const experimentalStorageKey = 'experimental-mode';
const experimentalOn = 'on';

export class ExperimentalService {
    /* Toggles experimental mode - when on, features hidden behind experimental will appear in the app after a refresh */
    public static toggleExperimental(): void {
        const experimentalModeOn = localStorage.getItem(experimentalStorageKey) === experimentalOn;

        if (experimentalModeOn) {
            localStorage.removeItem(experimentalStorageKey);
        } else {
            localStorage.setItem(experimentalStorageKey, experimentalOn);
        }

        this.reload();
    }

    public static isExperimentalModeOn(): boolean {
        return localStorage.getItem(experimentalStorageKey) === experimentalOn && this.shouldShowExperimentalSwitch();
    }

    public static shouldShowExperimentalSwitch(): boolean {
        return !environment.PRODUCTION;
    }

    private static reload(): void {
        window.location.reload();
    }
}
