import { VideoCallState } from '../models/video-call.state';
import { createReducer, on, Action } from '@ngrx/store';
import { VideoCallsApiActions } from '../actions/video-calls-api.actions';
import { VideoCallsActions } from '../actions/video-calls.actions';

export const initialState: VideoCallState = {
    videoCall: undefined
};

const videoCallReducerFn = createReducer(
    initialState,
    on(VideoCallsApiActions.inviteToCallSuccess, (state, { incomingCall }) => ({
        ...state,
        videoCall: incomingCall
    })),
    on(VideoCallsActions.acceptCall, VideoCallsActions.blockCaller, VideoCallsActions.declineCall, (state) => ({
        ...state,
        videoCall: undefined
    }))
);

export function videoCallReducer(state: VideoCallState | undefined, action: Action): VideoCallState {
    return videoCallReducerFn(state, action);
}
