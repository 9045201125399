import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class VideoCallApiService {
    constructor(private httpClient: HttpClient) {}

    acceptCall(url: string): Observable<any> {
        return this.httpClient.post(url, {});
    }

    declineCall(url: string): Observable<any> {
        return this.httpClient.post(url, {});
    }

    blockCaller(url: string): Observable<any> {
        return this.httpClient.post(url, { block_user: true });
    }
}
