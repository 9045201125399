export interface LiveDisplaySponsors {
    id?: number;
    headers?: Array<number>;
    footers?: Array<number>;
    sponsors?: Array<number>;
    background_color?: string;
    toggle_background?: LiveDisplaySponsors.ToggleBackgroundEnum;
    title?: string;
    order?: number;
    is_visible?: boolean;
    card_color?: string;
    card_text_color?: string;
    display_type?: LiveDisplaySponsors.DisplayTypeEnum;
    background_image?: number;
    live_display: number;
}

export namespace LiveDisplaySponsors {
    export enum ToggleBackgroundEnum {
        Color = 'background_color',
        Image = 'background_image'
    }
    export enum DisplayTypeEnum {
        FullScreen = 'full_screen',
        Banner = 'banner'
    }
}
