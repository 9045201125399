import { createActionGroup, props } from '@ngrx/store';
import { Module } from '@shared/api';

export const NavigationApiActions = createActionGroup({
    source: 'Navigation Api',
    events: {
        'Get Modules Success': props<{ modules: Module[] }>(),
        'Get Modules Failure': props<{ error: any }>()
    }
});
