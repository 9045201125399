import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

const GoogleFontsStylesheetBaseUrl = 'https://fonts.googleapis.com/css2';
@Injectable({
    providedIn: 'root'
})
export class GoogleFontsService {
    constructor(private httpClient: HttpClient) {}

    public getFontFamiliesStylesheetUrl(fontFamily: string): string | null {
        const url = new URL(GoogleFontsStylesheetBaseUrl);

        const param = `${fontFamily}:wght@100;200;300;400;500;600;700;800;900`;
        url.searchParams.append('family', param);
        url.searchParams.append('display', 'swap');

        return url.toString();
    }
}
