import { Action, createReducer, on } from '@ngrx/store';
import { NavigationState } from '../models/navigation.state.model';
import { AppCoreActions } from '../../../root-store/store/app-core/actions/app-core.actions';
import { NavigationActions } from '../actions/navigation.actions';
import { NavigationApiActions } from '../actions/navigation-api.actions';

export const initialState: NavigationState = {
    modules: undefined,
    initialising: undefined,
    rightSidebarItems: [],
    leftSidebarCollapsed: false
};

const navigationReducerFn = createReducer(
    initialState,
    on(NavigationActions.created, (state) => ({
        ...state,
        initialising: true
    })),
    on(NavigationApiActions.getModulesSuccess, (state, { modules }) => ({
        ...state,
        modules,
        initialising: false
    })),
    on(NavigationActions.setRightSidebarItems, (state, { rightSidebarItems }) => ({
        ...state,
        rightSidebarItems
    })),
    on(NavigationActions.toggleLeftSidebarCollapsed, (state) => ({
        ...state,
        leftSidebarCollapsed: !state.leftSidebarCollapsed
    })),
    on(NavigationActions.setLeftSidebarCollapsed, (state, { collapsed }) => ({
        ...state,
        leftSidebarCollapsed: collapsed
    })),
    on(AppCoreActions.initialiseApp, () => initialState)
);

export function navigationReducer(state: NavigationState | undefined, action: Action): NavigationState {
    return navigationReducerFn(state, action);
}
