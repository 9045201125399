import { Injectable } from '@angular/core';
import { AppTrackingStatus } from 'capacitor-plugin-app-tracking-transparency';
import { CapacitorService } from './capacitor.service';

@Injectable({
    providedIn: 'root'
})
export class TrackingTransparencyService {
    constructor(private capacitorService: CapacitorService) {}

    public async getApprovalStatus(): Promise<boolean | null> {
        if (!this.capacitorService.isNative || this.capacitorService.platform !== 'ios') {
            return null;
        }

        let status = await this.getStatus();

        if (status === 'notDetermined') {
            status = await this.getStatusFromPrompt();
        }

        return status === 'authorized';
    }

    private async getStatus(): Promise<AppTrackingStatus> {
        const { status } = await this.capacitorService.trackingTransparency.getStatus();
        return status;
    }

    private async getStatusFromPrompt(): Promise<AppTrackingStatus> {
        const { status } = await this.capacitorService.trackingTransparency.requestPermission();
        return status;
    }
}
