import { MableCustomFieldGroup } from './MableCustomFieldGroup';

export interface MableCustomField {
    uuid?: string;
    field_type: string;
    pk?: string;
    object_id: string;
    data_type?: string;
    key: string;
    display?: MableCustomField.DisplayEnum;
    limit?: number;
    json_name?: string;
    editable?: MableCustomField.EditableEnum;
    order?: number;
    tooltip?: string;
    bg_color?: string;
    text_color?: string;
    content_type: number;
    childValue?: any;
    childFields?: Array<MableCustomField>;
    group?: number | MableCustomFieldGroup;
}

export namespace MableCustomField {
    export enum DisplayEnum {
        Hidden = 'hidden' as any,
        Everyone = 'everyone' as any,
        Person = 'person' as any
    }
    export enum EditableEnum {
        Admin = 'admin' as any,
        Person = 'person' as any
    }
}
