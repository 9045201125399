import { Injectable } from '@angular/core';
import { AuthenticationActions } from '@core/root-store/store/authentication/actions/authentication.actions';
import { DeviceService } from '../../device/services/device.service';
import { AuthenticationFacadeService } from './authentication-facade.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import urijs from 'urijs';
@Injectable({
    providedIn: 'root'
})
export class SsoService {
    public static readonly BrowserParams = 'location=no,closebuttoncaption=Cancel';

    constructor(
        private inAppBrowser: InAppBrowser,
        private device: DeviceService,
        private authFacadeService: AuthenticationFacadeService
    ) {}

    open(login_url: string): void {
        if (this.device.isNative()) {
            this.openNative(login_url);
        } else {
            this.openWeb(login_url);
        }
    }

    private openWeb(url: string): void {
        window.open(url, '_self', SsoService.BrowserParams);
    }

    private async openNative(url: string): Promise<void> {
        const browserWindow = this.inAppBrowser.create(url, '_blank');

        let token: string;

        browserWindow.on('loadstop').subscribe((event) => {
            const q = urijs(event.url);
            const queryParams = q.query(true) as { token: string };
            token = queryParams.token;
            if (token) {
                browserWindow.close();
            }
        });

        browserWindow.on('exit').subscribe(() => {
            if (token) {
                this.authFacadeService.dispatch(AuthenticationActions.ssoExchangeToken({ authToken: token }));
            }
        });
    }
}
