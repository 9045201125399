import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { AuthenticationFacadeService } from '@core/authentication/services/authentication-facade.service';
import { PollSubmission } from '@features/live-polling/models/poll-submission.model';
import { ChoicePollChoiceFormItem } from '@shared/live-polling-surveys/models/choice-poll-choice-ui-item.model';
import { SurveyState, SurveyStoreService } from '@shared/live-polling-surveys/services/survey-store.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'cc-survey',
    templateUrl: './survey.component.html',
    styleUrls: ['./survey.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [SurveyStoreService]
})
export class SurveyComponent implements OnInit {
    @Input()
    public pollGroupId: number;

    @Input()
    public showCompleteButton: boolean = true;

    @Input()
    public hideCard: boolean;

    @Output()
    public pollCompleted = new EventEmitter<void>();

    public state$: Observable<SurveyState>;
    public loading$: Observable<boolean>;
    public submitting$: Observable<boolean>;
    public isAuthenticated$: Observable<boolean>;

    public choicePollChoiceFormItems$: Observable<ChoicePollChoiceFormItem[]>;
    public pollInitialValue$: Observable<{ value: number | string }>;

    constructor(
        private surveyStoreService: SurveyStoreService,
        private authFacadeService: AuthenticationFacadeService
    ) {}

    ngOnInit(): void {
        this.surveyStoreService.initialise(this.pollGroupId);
        this.state$ = this.surveyStoreService.state$;
        this.loading$ = this.surveyStoreService.isLoading$;
        this.submitting$ = this.surveyStoreService.isSubmitting$;
        this.isAuthenticated$ = this.authFacadeService.isAuthenticated();
    }

    public onPollNext(): void {
        this.surveyStoreService.nextPollItem();
    }

    public onPollPrev(): void {
        this.surveyStoreService.prevPollItem();
    }

    public onPollSubmit(event: PollSubmission): void {
        this.surveyStoreService.submitPollItem(event);
    }

    public onComplete(): void {
        this.pollCompleted.emit();
    }
}
