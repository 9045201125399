<ion-item
    *ngIf="shouldShowExperimentalSwitch"
    button
    lines="none"
    class="experimental-switch"
    color="sidebar"
    (click)="toggleExperimental()"
>
    <div slot="start">
        <i aria-hidden="true" class="material-icons">science</i>
    </div>
    <ion-label> Turn experimental features {{ isExperimentalOn ? 'off' : 'on' }} </ion-label>
</ion-item>
