import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { PeopleAppState } from '../store/models/people.state.model';
import { Observable } from 'rxjs';
import {
    AppDocument,
    MableCustomField,
    MableCustomFieldGroup,
    People,
    PeopleModule,
    PeopleVisibility,
    SocialInfo
} from '@shared/api';
import { PeopleSelectors } from '../store/selectors/people.selectors';
import { PaginationState } from '../../../shared/pagination/models/pagination-state.model';
import { ExpandedPeople } from '../models/expanded-people.model';
import { PeopleSessions } from '../models/people-session.model';
import { BusinessCard } from '@shared/business-cards/interfaces/business-card.interface';
import { AppTag } from '@shared/tags/interfaces/app-tag.interface';
import { TagFilterMode } from '@shared/tags/enums/tag-filter-mode.enum';

@Injectable({
    providedIn: 'root'
})
export class PeopleFacadeService {
    constructor(private store: Store<PeopleAppState>) {}

    getVisibility(): Observable<PeopleVisibility> {
        return this.store.select(PeopleSelectors.selectPeopleVisibility);
    }

    getPerson(): Observable<ExpandedPeople> {
        return this.store.select(PeopleSelectors.selectPerson);
    }

    getDetailInitialising(): Observable<boolean> {
        return this.store.select(PeopleSelectors.selectDetailInitialising);
    }

    getDocuments(language: string): Observable<AppDocument[]> {
        return this.store.select(PeopleSelectors.selectDocuments(language));
    }

    getCustomFields(): Observable<(MableCustomField | MableCustomFieldGroup)[]> {
        return this.store.select(PeopleSelectors.selectCustomFields);
    }

    getSessions(): Observable<
        { module: { name: string; id: number }; sessions: Array<{ slim: PeopleSessions.Session }> }[]
    > {
        return this.store.select(PeopleSelectors.selectSessions);
    }

    getBusinessCard(): Observable<BusinessCard> {
        return this.store.select(PeopleSelectors.selectPersonBusinessCard);
    }

    getSocialInfo(): Observable<SocialInfo[]> {
        return this.store.select(PeopleSelectors.selectSocialInfo);
    }

    getModule(moduleId: number): Observable<PeopleModule> {
        return this.store.select(PeopleSelectors.selectModule(moduleId));
    }

    getListInitialising(): Observable<boolean> {
        return this.store.select(PeopleSelectors.selectListInitialising);
    }

    getListRefreshing(): Observable<boolean> {
        return this.store.select(PeopleSelectors.selectListRefreshing);
    }

    getListPagination(): Observable<PaginationState> {
        return this.store.select(PeopleSelectors.selectPaginationState);
    }

    getListPeople(): Observable<People[]> {
        return this.store.select(PeopleSelectors.selectListPeople);
    }

    getListScrollTopDisabled(): Observable<boolean> {
        return this.store.select(PeopleSelectors.listScrollTopDisabled);
    }

    getListScrollBottomDisabled(): Observable<boolean> {
        return this.store.select(PeopleSelectors.listScrollBottomDisabled);
    }

    getListModuleId(): Observable<number> {
        return this.store.select(PeopleSelectors.listModuleId);
    }

    getListModule(): Observable<PeopleModule> {
        return this.store.select(PeopleSelectors.listModule);
    }

    public getFilters(): Observable<AppTag[]> {
        return this.store.select(PeopleSelectors.selectFilters);
    }

    public getAppliedFilters(): Observable<AppTag[]> {
        return this.store.select(PeopleSelectors.selectAppliedFilters);
    }

    public getFilterMode(): Observable<TagFilterMode> {
        return this.store.select(PeopleSelectors.selectFilterMode);
    }

    dispatch(action: Action): void {
        return this.store.dispatch(action);
    }
}
