import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Poll, RatingPoll } from '@shared/api';
import { range } from 'lodash';
import { ratingPollStyle } from '../../constants/rating-poll-style';

@Component({
    selector: 'cc-rating-poll',
    templateUrl: './rating-poll.component.html',
    styleUrls: ['./rating-poll.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RatingPollComponent implements OnChanges {
    @Input()
    public poll: Poll;

    @Input()
    public get ratingPoll(): RatingPoll {
        return this._ratingPoll;
    }

    public set ratingPoll(ratingPoll: RatingPoll) {
        this._ratingPoll = ratingPoll;
        if (ratingPoll) {
            this.starValues = range(1, ratingPoll.max_rating + 1);
        }
    }

    @Input()
    public set initialValue(initialValue: { value: any }) {
        this.selectedRangeValue = initialValue.value;
    }

    @Input()
    public closed: boolean;

    @Input()
    public showPreviousButton: boolean;

    @Input()
    public showNextButton: boolean;

    @Input()
    public submitting: boolean;

    @Input()
    public submitButtonText: string = 'SUBMIT';

    @Output()
    public pollSubmit = new EventEmitter<number>();

    @Output()
    public pollNext = new EventEmitter<void>();

    @Output()
    public pollPrevious = new EventEmitter<void>();

    public starValues: number[];
    public selectedRangeValue: number;
    public canSubmit: boolean;

    private _ratingPoll: RatingPoll;

    public ngOnChanges(): void {
        const hasAnswered = !!this.selectedRangeValue;
        const editable = !this.poll.single_response_only;

        this.canSubmit = editable || (!editable && !hasAnswered);
    }

    public selectRangeValue(value): void {
        this.selectedRangeValue = value;
    }

    public ratingIsSlider(): boolean {
        return !!(this.ratingPoll && this.ratingPoll.rating_style === ratingPollStyle.slider);
    }

    public onPollSubmit(): void {
        this.pollSubmit.emit(this.selectedRangeValue);
    }

    public onPollNext(): void {
        this.pollNext.emit();
    }

    public onPollPrevious(): void {
        this.pollPrevious.emit();
    }

    public formIsValid(): boolean {
        return !!this.selectedRangeValue || this.selectedRangeValue === 0;
    }
}
