import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SoundEffects } from '@common/constants/sound-effects.enum';
import { SoundService } from '@common/services/sound/sound.service';
import { NativeBadgeService } from '@core/native/services/native-badge.service';
import { PwaBadgeService } from '@core/native/services/pwa-badge.service';
import { AlertNotificationType } from '@core/notifications/enums/alert-notification-type.enum';
import { NotificationsService } from '@core/notifications/services/notifications/notifications.service';
import { AuthenticationActions } from '@core/root-store/store/authentication/actions/authentication.actions';
import { RealtimeActions } from '@core/root-store/store/realtime/actions/realtime.actions';
import { AppUserPageRoutes } from '@core/routing/constants/user-page-routes.constant';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AlertTypeEnum, AppAlert, NotificationSettingsPreference } from '@shared/api';
import { of } from 'rxjs';
import {
    catchError,
    distinctUntilChanged,
    filter,
    map,
    switchMap,
    take,
    takeUntil,
    tap,
    withLatestFrom
} from 'rxjs/operators';
import { AblyMessagingService } from '../../../../core/ably/services/ably-messaging.service';
import { AppCoreFacadeService } from '../../../../core/app-core/services/app-core-facade.service';
import { AuthenticationFacadeService } from '../../../../core/authentication/services/authentication-facade.service';
import { AppCoreActions } from '../../../../core/root-store/store/app-core/actions/app-core.actions';
import { ToastService } from '../../../../core/toast/services/toast/toast.service';
import { AlertsApiService } from '../../services/alerts-api.service';
import { AlertsFacadeService } from '../../services/alerts-facade.service';
import { AlertsService } from '../../services/alerts.service';
import { AlertsApiActions } from '../actions/alerts-api.actions';
import { AlertsActions } from '../actions/alerts.actions';

@Injectable()
export class AlertsEffects {
    listenForAlerts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RealtimeActions.connectedToPrivateChannel),
            switchMap(() =>
                this.ablyMessagingService.getMessageStream([AlertNotificationType.Sent], true).pipe(
                    takeUntil(this.actions$.pipe(ofType(RealtimeActions.disconnectedFromPrivateChannel))),
                    map(({ name, data }) => ({
                        action: name.split('.')[1],
                        alert: JSON.parse(data) as AppAlert
                    })),
                    switchMap(({ alert }) =>
                        this.authenticationFacadeService.getAuthenticatedPerson().pipe(
                            filter(Boolean),
                            filter((user) => user.peoplegroups.some((id) => alert.groups.includes(id.toString()))),
                            map(() => AlertsActions.realtimeNewAlert({ alert }))
                        )
                    )
                )
            )
        )
    );

    playSoundOnNewRealtimeAlert$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AlertsActions.realtimeNewAlert),
                withLatestFrom(this.authenticationFacadeService.getAuthenticatedPerson()),
                filter(
                    ([{ alert }, loggedInUser]) =>
                        alert.alert_type === AlertTypeEnum.Popup &&
                        (!loggedInUser ||
                            loggedInUser.in_app_notification_preference ===
                                NotificationSettingsPreference.soundNotifications)
                ),
                tap(() => this.soundService.play(SoundEffects.incomingAlert, 0.2))
            ),
        { dispatch: false }
    );

    fetchAlertsOnTosCompleted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActions.tosCompleted),
            map(() => AlertsActions.fetchAlerts())
        )
    );

    fetchNotificationsOnTosCompleted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActions.tosCompleted),
            map(() => AlertsActions.fetchUnreadNotificationCount())
        )
    );

    fetchAlerts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AlertsActions.fetchAlerts),
            switchMap(() =>
                this.authenticationFacadeService.hasAppAccessAndAuthenticated().pipe(filter(Boolean), take(1))
            ),
            withLatestFrom(this.appCoreFacadeService.getAppName()),
            switchMap(([, appUrl]) =>
                this.alertsApiService.getAlerts(appUrl).pipe(
                    map((alerts) =>
                        AlertsApiActions.fetchAlertsSuccess({ alerts: alerts.filter((alert) => !alert.archived) })
                    ),
                    catchError((error: HttpErrorResponse) => of(AlertsApiActions.fetchAlertsFailure({ error })))
                )
            )
        )
    );

    fetchInitialNotificationCount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AlertsActions.fetchUnreadNotificationCount),
            switchMap(() =>
                this.authenticationFacadeService.hasAppAccessAndAuthenticated().pipe(filter(Boolean), take(1))
            ),
            withLatestFrom(this.appCoreFacadeService.getAppName()),
            switchMap(([_, appName]) =>
                this.alertsApiService
                    .getPagedNotifications(appName, {
                        read: false,
                        offset: 0,
                        limit: 0
                    })
                    .pipe(
                        map((res) => AlertsActions.updateStoredNotificationCount({ count: res.count })),
                        catchError((error: HttpErrorResponse) => of(AlertsApiActions.fetchAlertsFailure({ error })))
                    )
            )
        )
    );

    showModalAlert$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AlertsApiActions.fetchAlertsSuccess),
                switchMap(() => {
                    // A little reference to avoid spitting out duplicates.
                    let lastAlertId: number;

                    return this.alertsFacadeService.getUnreadPopupAlerts().pipe(
                        // Keep this alive till another app gets initialised or we logout.
                        takeUntil(
                            this.actions$.pipe(ofType(AppCoreActions.initialiseApp, AuthenticationActions.logout))
                        ),
                        map((alerts) => this.alertsService.filterExpiredAlerts(alerts)),
                        filter((alerts) => !!alerts.length),
                        map((alerts) => alerts.reverse()[0]),
                        filter((alert) => alert.id !== lastAlertId),
                        tap((alert) => (lastAlertId = alert.id)),
                        switchMap((alert) => this.alertsService.showAlertModalDialog(alert))
                    );
                })
            ),
        { dispatch: false }
    );

    showDigest$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AlertsApiActions.fetchAlertsSuccess),
                filter(() => !this.location.path().includes(`/${AppUserPageRoutes.alerts}`)),
                switchMap(() => this.alertsFacadeService.getUnreadAlertCount()),
                tap((count) => {
                    if (count) {
                        this.notificationService.createAlertsDigestNotification(count);
                    }
                }),
                take(1)
            ),
        { dispatch: false }
    );

    updateBadgeNumber$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AppCoreActions.initialiseAppSuccess),
                switchMap(() => this.authenticationFacadeService.hasAppAccessAndAuthenticated()),
                filter((hasAccess) => hasAccess),
                switchMap(() => this.alertsFacadeService.getBadgeNumber()),
                distinctUntilChanged(),
                tap((badgeNumber) => {
                    this.nativeBadgeService.setNativeBadge(badgeNumber);
                    this.pwaBadgeService.setPwaBadge(badgeNumber);
                })
            ),
        { dispatch: false }
    );

    archiveAllSuccessToast$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AlertsApiActions.archiveAllSuccess),
                map(() => this.toastService.success('ALERTS_TOAST_ALL_ARCHIVED'))
            ),
        { dispatch: false }
    );

    markRead$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AlertsActions.markAsRead),
            withLatestFrom(this.appCoreFacadeService.getAppName()),
            switchMap(([{ alert }, appUrl]) =>
                this.alertsApiService.sendRead(alert.id, appUrl).pipe(
                    map(() => AlertsApiActions.markAlertAsReadSuccess({ alert })),
                    catchError((error: HttpErrorResponse) => of(AlertsApiActions.markAsReadFailure({ error })))
                )
            )
        )
    );

    markAllReadSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AlertsApiActions.markAllAsReadSuccess),
                map(() => this.toastService.success('ALERTS_TOAST_ALL_READ'))
            ),
        { dispatch: false }
    );

    archiveSuccessToast$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AlertsApiActions.archiveSuccess),
                map(() => this.toastService.success('ALERTS_TOAST_ARCHIVED'))
            ),
        { dispatch: false }
    );

    unarchiveSuccessToast$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AlertsApiActions.unarchiveSuccess),
                map(() => this.toastService.success('ALERTS_TOAST_UNARCHIVED'))
            ),
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private appCoreFacadeService: AppCoreFacadeService,
        private authenticationFacadeService: AuthenticationFacadeService,
        private alertsApiService: AlertsApiService,
        private alertsFacadeService: AlertsFacadeService,
        private ablyMessagingService: AblyMessagingService,
        private alertsService: AlertsService,
        private nativeBadgeService: NativeBadgeService,
        private pwaBadgeService: PwaBadgeService,
        private toastService: ToastService,
        private soundService: SoundService,
        private notificationService: NotificationsService,
        private location: Location
    ) {}
}
