import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthenticationFacadeService } from '../../../authentication/services/authentication-facade.service';
import { TermsAndConditions } from '../../models/terms-and-conditions.model';
import { TosApiService } from '../../services/tos-api/tos-api.service';

@Component({
    selector: 'cc-tos-dialog',
    templateUrl: './tos-dialog.component.html',
    styleUrls: ['./tos-dialog.component.scss']
})
export class TosDialogComponent implements OnInit {
    @Input()
    public appUrl: string;

    public termsAndConditions$: Observable<TermsAndConditions>;
    public hasAccepted$: Observable<boolean>;

    public showingPrivacyPolicy = false;

    constructor(
        private tosApiService: TosApiService,
        private modalController: ModalController,
        private authenticationFacadeService: AuthenticationFacadeService
    ) {}

    public ngOnInit(): void {
        this.termsAndConditions$ = this.tosApiService.get(this.appUrl);
        this.hasAccepted$ = this.authenticationFacadeService
            .isAuthenticated()
            .pipe(
                switchMap((isAuthenticated) =>
                    isAuthenticated ? this.tosApiService.hasAccepted(this.appUrl) : of(true)
                )
            );
    }

    public togglePrivacyPolicyVisibility(): void {
        this.showingPrivacyPolicy = !this.showingPrivacyPolicy;
    }

    public acceptTos(): void {
        this.tosApiService.agree(this.appUrl).subscribe(() => this.close(true));
    }

    public close(data?: boolean): void {
        this.modalController.dismiss(data);
    }
}
