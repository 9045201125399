<ng-container
    *ngIf="{
        canChat: canChat$ | async,
        canVideoCall: canVideoCall$ | async,
        canUseMeetingBooking: canUseMeetingBooking$ | async
    } as footerData"
>
    <ion-footer
        *ngIf="footerData.canChat || footerData.canVideoCall || footerData.canUseMeetingBooking"
        class="cc-chat-footer"
    >
        <ion-toolbar>
            <div class="person-footer__container">
                <ion-button
                    *ngIf="footerData.canVideoCall"
                    class="person-footer__action-video"
                    expand="block"
                    fill="clear"
                    (click)="onVideoCall()"
                >
                    <div class="person-footer__action-video-container">
                        <i class="material-icons" aria-hidden="true">videocam</i>
                        <ion-label *ngIf="person.accepts_video_calls">{{
                            'PEOPLE_BUTTON_VIDEO' | translate
                        }}</ion-label>
                        <ion-label *ngIf="!person.accepts_video_calls">
                            {{ 'PEOPLE_BUTTON_VIDEO_UNAVAILABLE' | translate }}
                        </ion-label>
                    </div>
                </ion-button>

                <ion-button
                    *ngIf="footerData.canChat"
                    class="person-footer__action-chat"
                    expand="block"
                    fill="clear"
                    (click)="onChat()"
                >
                    <div class="person-footer__action-chat-container">
                        <i class="material-icons" aria-hidden="true">chat</i>
                        <ion-label color="light">
                            {{ 'PEOPLE_BUTTON_CHAT' | translate }}
                        </ion-label>
                    </div>
                </ion-button>

                <ion-button
                    *ngIf="footerData.canUseMeetingBooking"
                    class="person-footer__action-meeting"
                    expand="block"
                    fill="clear"
                    (click)="onScheduleMeeting()"
                >
                    <div class="person-footer__action-meeting-container">
                        <i class="material-icons" aria-hidden="true">today</i>
                        <ion-label color="light">
                            {{ 'PEOPLE_BUTTON_SCHEDULE_MEETING' | translate }}
                        </ion-label>
                    </div>
                </ion-button>
            </div>
        </ion-toolbar>
    </ion-footer>
</ng-container>
