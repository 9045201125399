import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserAuthInfo } from '@shared/api';
import { Observable } from 'rxjs';
@Injectable()
export class SsoAuthService {
    auth: any;

    constructor(private http: HttpClient) {}

    validate(token: string): Observable<SsoValidateResponse> {
        return this.http.post<SsoValidateResponse>(`${CONFIG.API_URL}auth/exchange-token/`, { token });
    }
}

export interface SsoValidateResponse extends UserAuthInfo {
    otp_signature?: string;
}
