import { Action, createReducer, on } from '@ngrx/store';
import { GlobalSettingsActions } from '../actions/global-settings.actions';
import { GlobalSettingsState } from '../models/global-settings-state.model';

export const initialState: GlobalSettingsState = {
    meetingBookingConfig: undefined
};

const globalSettingsReducerFn = createReducer(
    initialState,
    on(GlobalSettingsActions.fetchMeetingBookingConfigSuccess, (state, { config }) => ({
        ...state,
        meetingBookingConfig: config
    }))
);

export function globalSettingsReducer(state: GlobalSettingsState | undefined, action: Action): GlobalSettingsState {
    return globalSettingsReducerFn(state, action);
}
