import { Injectable } from '@angular/core';

export const BACKUP_INTERVAL = 5000;

import { AuthStorageKeys } from '@core/authentication/constants/auth-storage-keys';
import { DeviceService } from '@core/device/services/device.service';
import { StorageService } from '@core/storage/services/storage.service';
import { CapacitorService } from './capacitor.service';

@Injectable({
    providedIn: 'root'
})
export class TokenBackupService {
    constructor(
        private storageService: StorageService,
        private deviceService: DeviceService,
        private capacitorService: CapacitorService
    ) {}

    /**
     * This is used due to local storage periodically clearing on native apps.
     * It will keep a backup of the auth info in native storage and load it out when local storage is empty.
     */
    async load(): Promise<void> {
        if (!this.capacitorService.isNative || !this.capacitorService.isPluginAvailable('storage')) {
            return;
        }

        const storageKey = await this.getStorageKey();
        // Load the auth info from local storage.
        const localStorageAuthInfo = this.storageService.get(storageKey);

        // If there is no auth info.
        if (!localStorageAuthInfo || !localStorageAuthInfo.refresh_token) {
            // Load the auth info from native stoage.
            const nativeStorageAuthInfoResponse = await this.capacitorService.storage.get({ key: storageKey });
            const nativeStorageAuthInfoValue = nativeStorageAuthInfoResponse.value;

            // If there is auth info in native storage.
            if (nativeStorageAuthInfoValue) {
                // Save it into local storage.
                this.storageService.setStorageItem(storageKey, nativeStorageAuthInfoValue);
            }
        }
    }

    async save(): Promise<void> {
        if (!this.capacitorService.isNative || !this.capacitorService.isPluginAvailable('storage')) {
            return;
        }

        const storageKey = await this.getStorageKey();
        const localStorageAuthInfo = this.storageService.get(storageKey);
        return this.capacitorService.storage.set({ key: storageKey, value: JSON.stringify(localStorageAuthInfo) });
    }

    async clear(): Promise<void> {
        if (!this.capacitorService.isNative || !this.capacitorService.isPluginAvailable('storage')) {
            return;
        }

        const storageKey = await this.getStorageKey();
        return this.capacitorService.storage.remove({ key: storageKey });
    }

    private async getStorageKey(): Promise<string> {
        const appName = await this.deviceService.getAppName();
        return AuthStorageKeys.appUserAuthInfo(appName);
    }
}
