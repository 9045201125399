import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import {
    SelectedTimezoneService,
    TimezoneMode
} from '@core/locale/services/selected-timezone/selected-timezone.service';
import { DateTime } from 'luxon';
import { Observable, map } from 'rxjs';

@Component({
    selector: 'cc-timezone-tooltip',
    templateUrl: './timezone-tooltip.component.html',
    styleUrls: ['./timezone-tooltip.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimezoneTooltipComponent implements OnInit {
    @Input()
    public time: string;

    public shouldDisplay$: Observable<boolean>;
    public activeTimezone$: Observable<string>;
    public tooltipText$: Observable<string>;

    constructor(private selectedTimezoneService: SelectedTimezoneService) {}

    public ngOnInit(): void {
        this.shouldDisplay$ = this.selectedTimezoneService.eventAndLocalTimezonesDiffer$;

        const today = new Date().toISOString();

        this.activeTimezone$ = this.selectedTimezoneService.activeTimezone$.pipe(
            map((timezone) => DateTime.fromISO(this.time || today).setZone(timezone).offsetNameShort)
        );

        this.tooltipText$ = this.selectedTimezoneService.currentTimezoneDisplay$.pipe(
            map(
                (display) =>
                    ({
                        [TimezoneMode.Event]: 'TIMEZONE_NOTICE_EVENT',
                        [TimezoneMode.Local]: 'TIMEZONE_NOTICE_LOCAL'
                    })[display]
            )
        );
    }
}
