import { Selector } from '@ngrx/store';
import { AppState } from '@core/root-store/store/app-state.model';
import { NavigationSelectors } from '@core/navigation/store/selectors/navigation.selectors';
import { PeopleSelectors } from '@features/people/store/selectors/people.selectors';
import { SurveysSelectors } from '@features/surveys/store/selectors/surveys-selectors';
import { CompanyDetailPageSelectors } from '@features/companies/store/selectors/company-detail-page.selectors';
import { InfoPageDetailsPageSelectors } from '@features/info-pages/store/selectors/info-page-details-page.selector';
import { AgendaSessionPageSelectors } from '@features/agenda/agenda-session/store/selectors/agenda-session-page.selectors';
import { GamificationSelectors } from '@features/gamification/store/selectors/gamification.selectors';
import { LivePollingSelectors } from '@features/live-polling/store/selectors/live-polling.selectors';
import { MapSelectors } from '@features/map/store/selectors/map.selectors';
import { NotesSelectors } from '@features/notes/store/selectors/notes.selectors';
import { PortalSelectors } from '@features/portal/store/selectors/portal.selectors';
import { AbstractsSelectors } from '@features/abstracts/store/selectors/abstracts.selectors';
import { VodSelectors } from '@features/vod/store/vod-store/selectors/vod.selectors';

export type TitleSelector =
    | 'module'
    | 'peopleDetail'
    | 'pollDetail'
    | 'companyDetail'
    | 'infoPageDetail'
    | 'sessionDetail'
    | 'challengeDetail'
    | 'livePollDetail'
    | 'mapDetail'
    | 'noteDetail'
    | 'portalDetail'
    | 'vodDetail'
    | 'abstractDetail';

export const TitleSelectors: Record<TitleSelector, Selector<AppState, string | undefined>> = {
    module: NavigationSelectors.moduleTitle,
    peopleDetail: PeopleSelectors.selectPersonPageTitle,
    pollDetail: SurveysSelectors.selectSurveyTitle,
    companyDetail: CompanyDetailPageSelectors.title,
    infoPageDetail: InfoPageDetailsPageSelectors.selectTitle,
    sessionDetail: AgendaSessionPageSelectors.title,
    challengeDetail: GamificationSelectors.selectChallengeDetailPageTitle,
    livePollDetail: LivePollingSelectors.selectTitle,
    mapDetail: MapSelectors.selectTitle,
    noteDetail: NotesSelectors.selectTitle,
    portalDetail: PortalSelectors.selectTitle,
    vodDetail: VodSelectors.selectTitle,
    abstractDetail: AbstractsSelectors.selectTitle
};
