import { Injectable } from '@angular/core';
import { FirebaseEnvironment, IEnvironment } from '@core/environment/models/environment.model';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class EnvironmentService implements IEnvironment {
    constructor() {}

    get API_URL(): string {
        return environment.API_URL;
    }

    get SITE_URL(): string {
        return environment.SITE_URL;
    }

    get CC_MEDIA_URL(): string {
        return environment.CC_MEDIA_URL;
    }

    get PRODUCTION(): boolean {
        return environment.PRODUCTION;
    }

    get GOOGLE_ANALYTICS_GA4(): string {
        return environment.GOOGLE_ANALYTICS_GA4;
    }

    get GOOGLE_ANALYTICS_UA(): string {
        return environment.GOOGLE_ANALYTICS_UA;
    }

    get VERSION(): string {
        return environment.VERSION;
    }

    get name(): string {
        return environment.name;
    }

    get firebase(): FirebaseEnvironment {
        return environment.firebase;
    }

    get muxDataKey(): string {
        return environment.muxDataKey;
    }

    get CONTAINER_ONESIGNAL_APP_ID(): string {
        return environment.CONTAINER_ONESIGNAL_APP_ID;
    }

    get ZOOM_EMBED_URL(): string {
        return environment.ZOOM_EMBED_URL;
    }

    get BREAKOUTS_URL(): string {
        return environment.BREAKOUTS_URL;
    }

    get WEB_PUSH_PUBLIC_KEY(): string {
        return environment.WEB_PUSH_PUBLIC_KEY;
    }
}
