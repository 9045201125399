import { createFeatureSelector, createSelector } from '@ngrx/store';
import { meetingBookingKey } from '../models/meeting-booking-app-state.model';
import { MeetingBookingState } from '../models/meeting-booking-state.model';

const meetingBookingState = createFeatureSelector<MeetingBookingState>(meetingBookingKey);

const availableTags = createSelector(meetingBookingState, (state) => state.availableTags);
const availableLocations = createSelector(meetingBookingState, (state) => state.locations);
const minMeetingLength = createSelector(meetingBookingState, (state) => state.config.min_length);
const maxMeetingLength = createSelector(meetingBookingState, (state) => state.config.max_length);
const dayStartTime = createSelector(meetingBookingState, (state) => state.config.meeting_start);
const dayEndTime = createSelector(meetingBookingState, (state) => state.config.meeting_end);
const meetingType = createSelector(meetingBookingState, (state) => state.config.meeting_type);
const useCompanyLocation = createSelector(meetingBookingState, (state) => state.config.use_company_location);
const config = createSelector(meetingBookingState, (state) => state.config);
const bookableRange = createSelector(meetingBookingState, (state) => state.bookable);

export const MeetingBookingSelectors = {
    availableTags,
    availableLocations,
    minMeetingLength,
    maxMeetingLength,
    dayStartTime,
    dayEndTime,
    meetingType,
    useCompanyLocation,
    config,
    bookableRange
};
