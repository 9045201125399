<div class="poll-intermission">
    <ion-card>
        <ng-lottie
            *ngIf="completed"
            class="complete-animation"
            [options]="completeAnimationOptions"
            @fadeIn
        ></ng-lottie>
        <div class="poll-intermission-content" [ccCompileHtml]="message"></div>
    </ion-card>
    <div *ngIf="showBackButton" class="back-button-container">
        <ion-button
            color="accent"
            (click)="onNavButtonClick()"
            shape="round"
            [attr.aria-label]="'POLL_CONTINUE' | translate"
        >
            <i class="material-icons" aria-hidden="true">{{ navButtonIcon }}</i>
        </ion-button>
        <div translate>{{ navButtonText }}</div>
    </div>
</div>
