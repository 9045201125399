import * as models from '@shared/api';

export interface AppFontConfig {
    id?: number;
    font_custom_files?: Array<models.FontFile>;
    app: string;
    font_toggle?: AppFontConfig.FontToggleEnum;
    font_family?: string;
    active?: boolean;
}

export namespace AppFontConfig {
    export enum FontToggleEnum {
        Google = 'google',
        Custom = 'custom',
        Browser = 'browser'
    }
}
