<div class="agenda-card-speaker-item__container" [ccPersonPopover]="speaker">
    <cc-person-icon
        class="agenda-card-speaker-item__picture"
        size="medium"
        [person]="speaker"
        [tooltip]="[speaker.title, speaker.first_name, speaker.last_name] | concat"
        [options]="tooltipOptions"
        [person]="speaker"
    ></cc-person-icon>
</div>
