import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { AlertTypeEnum, AppAlert } from '@shared/api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AlertsTabEnum } from '../enums/alerts-tab.enum';
import { AlertsAppState } from '../store/models/alerts.state.model';
import { AlertsSelectors } from '../store/selectors/alerts.selectors';

@Injectable({
    providedIn: 'root'
})
export class AlertsFacadeService {
    constructor(private store: Store<AlertsAppState>) {}

    public getCurrentTab(): Observable<AlertsTabEnum> {
        return this.store.select(AlertsSelectors.currentTab);
    }

    public getAlerts(): Observable<AppAlert[]> {
        return this.store.select(AlertsSelectors.alerts);
    }

    public getAlertIds(): Observable<number[]> {
        return this.store.select(AlertsSelectors.alertIds) as Observable<number[]>;
    }

    public getAlertSurveys(): Observable<AppAlert[]> {
        return this.store
            .select(AlertsSelectors.unreadAlerts)
            .pipe(map((alerts) => alerts.filter((alert) => alert.embedded_survey_id)));
    }

    public getUnreadAlerts(): Observable<AppAlert[]> {
        return this.store.select(AlertsSelectors.unreadAlerts);
    }

    public getUnreadAlertIds(): Observable<number[]> {
        return this.store.select(AlertsSelectors.unreadAlertIds);
    }

    public getUnreadAlertCount(): Observable<number> {
        return this.store.select(AlertsSelectors.unreadAlertsCount);
    }

    public getUnreadPopupAlerts(): Observable<AppAlert[]> {
        return this.store
            .select(AlertsSelectors.unreadAlerts)
            .pipe(map((alerts) => alerts.filter((alert) => alert.alert_type === AlertTypeEnum.Popup)));
    }

    public getBadgeNumber(): Observable<number> {
        return this.store.select(AlertsSelectors.unreadAlertsCount);
    }

    public getAlertsLoading(): Observable<boolean> {
        return this.store.select(AlertsSelectors.alertsLoading);
    }

    public dispatch(action: Action): void {
        this.store.dispatch(action);
    }
}
