import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FocusTrap, FocusTrapFactory } from '@angular/cdk/a11y';

@Injectable({
    providedIn: 'root'
})
export class FocusService {
    constructor(
        @Inject(DOCUMENT) private document: Document,
        private focusTrapFactory: FocusTrapFactory
    ) {}

    async trapFocusByElementId(elementId: string): Promise<FocusTrap> {
        const element = this.document.getElementById(elementId);
        if (!element) {
            return;
        }
        return this.trapFocusOnElement(element);
    }

    async trapFocusOnElement(element: HTMLElement): Promise<FocusTrap> {
        const trap = this.focusTrapFactory.create(element);
        await trap.focusInitialElementWhenReady();
        return trap;
    }

    async handleIonicModalFocus(modal: HTMLIonModalElement, focusElementId?: string): Promise<void> {
        const lastFocusedElement = document.activeElement as HTMLElement;
        const focusTrap = await this.trapFocusOnElement(modal);
        modal.onDidDismiss().then(() => {
            focusTrap.destroy();
            if (focusElementId) {
                this.focusElementById(focusElementId);
                return;
            }
            if (lastFocusedElement) {
                lastFocusedElement.focus();
            }
        });
    }

    focusElementById(elementId: string): HTMLElement {
        let element = this.document.getElementById(elementId);
        if (!element) {
            return;
        }
        if (element.tagName.toLowerCase() === 'ion-button') {
            element = element.shadowRoot.querySelector('button');
        }
        element.focus();

        return element;
    }

    focusElementBySelector(selector: string): HTMLElement {
        const element = this.document.querySelector(selector) as HTMLElement;
        if (!element) {
            return;
        }
        element.focus();

        return element;
    }
}
