import { PaginatedResponse } from '../../models/paginated-response.model';
import { PaginationStore } from '../pagination-store/pagination-store.service';

/**
 * @description
 * Used when you want to implement an infinite-scroll-like pagination, where going to the next page will not erase the previous results.
 * This class should be extended by a non-root injectable that implements the fetchResource method, which is responsible for fetching
 * results using the current active query.
 *
 * @example
 * See people-pagination-store.service.ts
 */
export abstract class PersistedPaginationStore<
    TResource,
    TExtraQuery = Record<string, number | string>
> extends PaginationStore<TResource, TExtraQuery> {
    public readonly hasReachedEnd$ = this.select((state) => {
        return state.resource.results.length >= state.resource.count;
    });

    protected updateResource = this.updater((state, response: PaginatedResponse<TResource>) => {
        /* If the query is set to page 1, we do not want to concatenate new results on to old ones */
        if (state.query.page === 1) {
            return {
                ...state,
                resource: response
            };
        }

        /* Else, combine the newly returned results with the existing ones */
        return {
            ...state,
            resource: {
                count: response.count,
                results: [...state.resource.results, ...response.results]
            }
        };
    });
}
