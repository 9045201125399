import { HttpErrorResponse } from '@angular/common/http';
import { AppNotification } from '@core/notifications/interfaces/notification.interface';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const NotificationsActions = createActionGroup({
    source: 'Notifications',
    events: {
        'Show Notification': props<{ notification: AppNotification }>(),
        'Dismiss Notification': props<{ id: string }>(),
        'Notification Clicked': props<{ id: string }>(),
        'Notification Actioned': props<{ id: string }>(),
        'Api Driven Notification Actioned': props<{ id: string; customDeeplink?: string; canMarkAsRead?: boolean }>(),
        'Request Web Push Permission': emptyProps(),
        'Subscribe To Web Push Notification Success': emptyProps(),
        'Subscribe To Web Push Notification Failure': props<{ error: HttpErrorResponse }>()
    }
});
