import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InlineSession } from '@features/agenda/agenda-module/models/inline-session.model';

@Component({
    selector: 'cc-agenda-card-feature-icons',
    templateUrl: './agenda-card-feature-icons.component.html',
    styleUrl: './agenda-card-feature-icons.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgendaCardFeatureIconsComponent {
    @Input({ required: true })
    public session: InlineSession;
}
