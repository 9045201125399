import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { AuthenticationFacadeService } from '@core/authentication/services/authentication-facade.service';
import { ToastService } from '@core/toast/services/toast/toast.service';
import { MeetingBookingFacadeService } from '@features/meeting-booking/services/meeting-booking-facade/meeting-booking-facade.service';
import { MeetingBookingSidebarActions } from '@features/meeting-booking/store/actions/meeting-booking-sidebar.actions';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MeetingBookingEnabledGuard {
    constructor(
        private appCoreFacadeService: AppCoreFacadeService,
        private router: Router,
        private toastService: ToastService,
        private authenticationFacadeService: AuthenticationFacadeService,
        private meetingBookingFacadeService: MeetingBookingFacadeService
    ) {}

    public canActivate(): Observable<boolean | UrlTree> {
        return combineLatest([
            this.appCoreFacadeService.getAppSettings(),
            this.authenticationFacadeService.getAuthenticatedPerson()
        ]).pipe(
            map(([app, user]) => {
                if (app?.mbookings_enabled && user?.meeting_booking_enabled && user?.meeting_booking_allowed) {
                    this.meetingBookingFacadeService.dispatch(MeetingBookingSidebarActions.initialiseSidebar());
                    return true;
                }

                this.toastService.error('AUTHENTICATION_NOT_ALLOWED');

                return this.router.createUrlTree([app.url_name]);
            })
        );
    }
}
