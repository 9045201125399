import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { SocialInfo } from '@shared/api';

@Component({
    selector: 'cc-business-card-social-icon',
    templateUrl: './business-card-social-icon.component.html',
    styleUrls: ['./business-card-social-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BusinessCardSocialIconComponent {
    @Input()
    public social: SocialInfo;

    @Output()
    public clicked: EventEmitter<void> = new EventEmitter<void>();

    public emitClicked(): void {
        this.clicked.emit();
    }
}
