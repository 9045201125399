import { Injectable } from '@angular/core';
import { AppRoutingFacadeService } from '../../../../core/routing/services/app-routing-facade.service';
import { AppRoutingActions } from '../../../../core/root-store/store/app-routing/actions/app-routing.actions';
import { AppModuleRoutes } from '../../../../core/routing/constants/module-routes.constant';

@Injectable({
    providedIn: 'root'
})
export class AgendaModuleService {
    constructor(private appRoutingFacadeService: AppRoutingFacadeService) {}

    openModuleWithFilterOpen(moduleId: number): void {
        this.appRoutingFacadeService.dispatch(
            AppRoutingActions.goToAppModule({
                urlSegments: [moduleId, AppModuleRoutes.agenda],
                extras: { queryParams: { tracksOpen: true } }
            })
        );
    }
}
