import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'cc-survey-header',
    templateUrl: './survey-header.component.html',
    styleUrls: ['./survey-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SurveyHeaderComponent {
    @Input()
    public currentCount: number;

    @Input()
    public totalCount: number;

    public getProgressValue(): number {
        return this.currentCount / this.totalCount;
    }
}
