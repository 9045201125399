import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { fadeIn } from '@common/animations/animations';
import { People } from '@shared/api';

@Component({
    selector: 'cc-agenda-card-speakers-list',
    templateUrl: './agenda-card-speakers-list.component.html',
    styleUrl: './agenda-card-speakers-list.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [fadeIn()]
})
export class AgendaCardSpeakersListComponent {
    @Input({ required: true })
    public speakers: Array<People>;
}
