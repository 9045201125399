import { createActionGroup, props } from '@ngrx/store';
import { PortalApp, PortalModule } from '@shared/api';

export const PortalApiActions = createActionGroup({
    source: 'Portal Api',
    events: {
        'Get Portal List Page Data Success': props<{ portalApps: PortalApp[]; portalModule: PortalModule }>(),
        'Get Portal List Page Data Failure': props<{ error: string }>(),
        'Get Portal Info Page Data Success': props<{ portalApp: PortalApp }>(),
        'Get Portal Info Page Data Failure': props<{ error: string }>()
    }
});
