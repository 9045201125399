export interface LiveDisplayScreenGaming {
    id?: number;
    headers?: Array<number>;
    footers?: Array<number>;
    background_color?: string;
    toggle_background?: LiveDisplayScreenGaming.ToggleBackgroundEnum;
    title?: string;
    order?: number;
    is_visible?: boolean;
    card_color?: string;
    card_text_color?: string;
    info_visible?: boolean;
    info_title?: string;
    info_content?: string;
    info_icon_text?: string;
    info_icon_toggle?: string;
    info_card_location?: LiveDisplayScreenGaming.InfoCardLocationEnum;
    background_image?: number;
    live_display: number;
    game: number;
    info_icon_image?: number;
}

export namespace LiveDisplayScreenGaming {
    export enum ToggleBackgroundEnum {
        Color = 'background_color',
        Image = 'background_image'
    }
    export enum InfoCardLocationEnum {
        Left = 'left',
        Right = 'right',
        Bottom = 'bottom'
    }
}
