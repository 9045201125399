import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AnalyticsOptionsService } from './services/analytics-options.service';

@NgModule({
    declarations: [],
    exports: [],
    imports: [CommonModule],
    providers: [AnalyticsOptionsService]
})
export class AnalyticsModule {}
