import { Injectable } from '@angular/core';
import { take, first, tap, filter } from 'rxjs/operators';
import { AuthenticationFacadeService } from '../../authentication/services/authentication-facade.service';
import { EnvironmentService } from '../../environment/services/environment.service';
import { AppCoreFacadeService } from '../../app-core/services/app-core-facade.service';
import { AppRouteParams } from '@core/routing/constants/app-route-params.constant';
import { GoogleAnalyticItem } from '../interfaces/google-analytics-item.interface';
import { GoogleAnalyticOptions } from '../interfaces/google-analytics-options.interface';
import { Router, NavigationEnd } from '@angular/router';
import { GoogleAnalyticsEventType } from '../enums/google-analytics-event-type.enum';
import { AnalyticsSetting } from '../interfaces/analytics-setting.interface';
import { AnalyticsDataStreamService } from './analytics-data-stream.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {
    window = window;
    currentItems: GoogleAnalyticItem[] = [];

    constructor(
        private authenticationFacadeService: AuthenticationFacadeService,
        private environmentService: EnvironmentService,
        private appCoreFacadeService: AppCoreFacadeService,
        private router: Router,
        private analyticsDataStream: AnalyticsDataStreamService
    ) {}

    public initialise(): void {
        this.subscribeToRouteChanges();
    }

    public setItemsFromNavigation(params: any): void {
        this.currentItems = [];
        this.currentItems.push({ key: 'app', value: params[AppRouteParams.appName] });
        if (params[AppRouteParams.moduleId]) {
            this.currentItems.push({ key: 'module', value: params[AppRouteParams.moduleId] });
        }
    }

    public getCategory(items: GoogleAnalyticItem[]): string {
        let category = '';
        items.forEach((item) => {
            category = this.appendItemIfValid(category, item);
        });
        return category.slice(1);
    }

    public emit(options: GoogleAnalyticOptions): void {
        this.authenticationFacadeService
            .getUser()
            .pipe(take(1))
            .subscribe((user) => {
                const username = user ? user.username : undefined;

                const mostRecent = this.currentItems[this.currentItems.length - 1];
                if (mostRecent && mostRecent.key === options.item.key) {
                    this.currentItems[this.currentItems.length - 1].value = options.item.value;
                }
                const newItems = [...this.currentItems];
                if (mostRecent === undefined || mostRecent.key !== options.item.key) {
                    newItems.push(options.item);
                    if (options.event === GoogleAnalyticsEventType.View) {
                        this.currentItems.push(options.item);
                    }
                }

                this.appCoreFacadeService
                    .getAppName()
                    .pipe(
                        first(),
                        tap((app) => {
                            const category = this.getCategory([
                                { key: 'user', value: username },
                                { key: 'app', value: app },
                                ...newItems
                            ]);
                            const settings: AnalyticsSetting = {
                                category,
                                event: options.event,
                                label: options.label,
                                app,
                                username,
                                environmentName: this.environmentService.name
                            };
                            this.sendAnalytics(settings);
                        })
                    )
                    .subscribe();
            });
    }

    public clear(): void {
        this.currentItems = [];
    }

    public emitUserAuthEvent(username: string, app: string, event: GoogleAnalyticsEventType): void {
        const items = [
            { key: 'user', value: username },
            { key: 'app', value: app }
        ];
        const category = this.getCategory(items);
        const settings: AnalyticsSetting = {
            category,
            event,
            label: username,
            app,
            username,
            environmentName: this.environmentService.name
        };
        this.sendAnalytics(settings);
    }

    public sendAnalytics(settings: AnalyticsSetting): void {
        this.analyticsDataStream.sendEvent(settings);
    }

    public appendItemIfValid(message: string, item: GoogleAnalyticItem): string {
        if (item.value && item.value.length > 0) {
            return `${message}.${item.key}(${item.value})`;
        }
        return message;
    }

    private subscribeToRouteChanges(): void {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                untilDestroyed(this)
            )
            .subscribe((event: NavigationEnd) => {
                this.analyticsDataStream.sendPageView({
                    page_path: event.urlAfterRedirects
                });
            });
    }
}
