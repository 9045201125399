import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
    public transform(
        date: string | number,
        options: DateFormatCode | Intl.DateTimeFormatOptions,
        timezone?: string
    ): string {
        if (!date) {
            return;
        }
        // If format options are supplied, use them. If a format code is supplied, get options for the code.
        const formatOptions = typeof options === 'object' ? options : this.getFormatOptions(options);

        return new Intl.DateTimeFormat(navigator.language, {
            timeZone: timezone,
            ...formatOptions
        }).format(new Date(date));
    }

    private getFormatOptions(code: DateFormatCode): Intl.DateTimeFormatOptions {
        switch (code) {
            case 'time':
                return { hour: 'numeric', minute: 'numeric' };
            case 'longDate':
                return { weekday: 'long', month: 'long', year: 'numeric', day: 'numeric' };
            case 'shortDate':
                return { weekday: 'long', day: 'numeric', month: 'long' };
            case 'shortDateAndTime':
                return { dateStyle: 'short', timeStyle: 'short', hour12: true };
            case 'dateAndTime':
                return { minute: 'numeric', hour: 'numeric', day: 'numeric', month: 'short', year: 'numeric' };
        }
    }
}

export type DateFormatCode = 'time' | 'longDate' | 'shortDate' | 'shortDateAndTime' | 'dateAndTime';
