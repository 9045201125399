import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { PortalApp, PortalModule } from '@shared/api';
import { Observable } from 'rxjs';

import { PortalAppState } from '../store/models/portal-app-state.model';
import { PortalInfoPageState, PortalPageState } from '../store/models/portal-module-state.model';
import { PortalSelectors } from '../store/selectors/portal.selectors';

@Injectable({
    providedIn: 'root'
})
export class PortalFacadeService {
    constructor(private store: Store<PortalAppState>) {}

    getFilteredPortalApps(): Observable<PortalApp[]> {
        return this.store.select(PortalSelectors.selectFilteredPortalApps());
    }

    getPortalPageState(): Observable<PortalPageState> {
        return this.store.select(PortalSelectors.selectPortalPageState());
    }

    getSinglePortalApp(): Observable<PortalApp> {
        return this.store.select(PortalSelectors.selectSinglePortalApp());
    }

    getPortalInfoPageState(): Observable<PortalInfoPageState> {
        return this.store.select(PortalSelectors.selectPortalInfoPageState());
    }

    getPortalModule(): Observable<PortalModule> {
        return this.store.select(PortalSelectors.selectPortalModule);
    }

    dispatch(action: Action): void {
        this.store.dispatch(action);
    }
}
