import { NgModule } from '@angular/core';
import { AppCommonModule } from '@common/common.module';
import { HtmlModule } from '@features/html/html.module';
import { LivePollingSurveysModule } from '@shared/live-polling-surveys/live-polling-surveys.module';
import { PaginationModule } from '@shared/pagination/pagination.module';
import { SearchModule } from '@shared/search/search.module';
import { AlertsRoutingModule } from './alerts-routing.module';
import { AlertListFilterPanelComponent } from './components/alert-list-filter-panel/alert-list-filter-panel.component';
import { AlertListItemComponent } from './components/alert-list-item/alert-list-item.component';
import { AlertPopupModalComponent } from './components/alert-popup-modal/alert-popup-modal.component';
import { AlertSurveyContainerComponent } from './components/alert-survey-container/alert-survey-container.component';
import { NotificationListItemComponent } from './components/notification-list-item/notification-list-item.component';
import { AlertsComponent } from './pages/alerts/alerts.component';
import { AlertsStoreModule } from './store/alerts-store.module';

@NgModule({
    declarations: [
        AlertsComponent,
        AlertListItemComponent,
        AlertPopupModalComponent,
        AlertListFilterPanelComponent,
        AlertSurveyContainerComponent,
        NotificationListItemComponent
    ],
    imports: [
        AppCommonModule,
        AlertsRoutingModule,
        AlertsStoreModule,
        HtmlModule,
        SearchModule,
        LivePollingSurveysModule,
        PaginationModule
    ]
})
export class AlertsModule {}
