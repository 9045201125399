import { ChangeDetectionStrategy, Component, Input, TrackByFunction } from '@angular/core';
import { Track } from '@shared/api';
import { ChipStyle } from '@shared/agenda/interfaces/chip-style.interface';
import Color from 'color';

const textDark = 'rgba(0, 0, 0, 0.87)';
const textLight = 'rgba(255, 255, 255, 0.87)';

@Component({
    selector: 'cc-track-chips',
    templateUrl: './track-chips.component.html',
    styleUrls: ['./track-chips.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrackChipsComponent {
    @Input()
    public tracks: Track[];

    public trackByFn: TrackByFunction<Track> = (_: number, track: Track) => track.id;

    public getChipStyle(track: Track): ChipStyle {
        const backgroundColor = track.bg_colour;
        const textColor = track.text_colour;
        const isLight = Color(backgroundColor).isLight();
        const fallbackTextColor = isLight ? textDark : textLight;

        return {
            'background-color': backgroundColor,
            color: textColor ?? fallbackTextColor
        };
    }
}
