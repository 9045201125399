import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

@Component({
    selector: 'cc-capacity-status',
    templateUrl: './capacity-status.component.html',
    styleUrls: ['./capacity-status.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CapacityStatusComponent implements OnChanges {
    @Input()
    public isInWaitingList: boolean;

    @Input()
    public remainingPlaces: number;

    public label: string;

    public ngOnChanges(): void {
        this.label = this.getLabel();
    }

    private getLabel(): string {
        if (this.isInWaitingList) {
            return 'AGENDA_ON_WAITING_LIST';
        }

        if (this.remainingPlaces) {
            return 'AGENDA_SPACES_LEFT';
        }

        return 'AGENDA_FULL';
    }
}
