import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BannerEffects } from './effects/banner.effects';
import { bannerStoreKey } from './models/banner.state.model';
import { bannerReducer } from './reducers/banner.reducer';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature(bannerStoreKey, bannerReducer),
        EffectsModule.forFeature([BannerEffects])
    ]
})
export class BannerStoreModule {}
