import { createReducer, on } from '@ngrx/store';
import { BusinessCardsActions } from '../actions/business-cards.actions';
import { BusinessCardsState } from '../models/business-cards-state.model';

export const businessCardsInitialState: BusinessCardsState = {
    design: undefined,
    socialInfo: undefined
};

export const businessCardsReducer = createReducer(
    businessCardsInitialState,
    on(BusinessCardsActions.getMyCardDesignSuccess, (state, { design }) => ({ ...state, design })),
    on(BusinessCardsActions.getSocialInfoSuccess, (state, { socialInfo }) => ({ ...state, socialInfo })),
    on(BusinessCardsActions.reset, () => businessCardsInitialState)
);
