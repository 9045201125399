import { EnvironmentExtras } from '@core/environment/models/environment-extras.model';
import { IEnvironment } from '@core/environment/models/environment.model';
import { firebaseDatabaseUrl, FirebaseInfo } from './firebase';

export const environment: IEnvironment & EnvironmentExtras = {
    API_URL: 'https://staging-api.crowdcomms.com/',
    SITE_URL: 'staging.crowdcomms.com/',
    CC_MEDIA_URL: 'media.crowdcomms.com/',
    PRODUCTION: false,
    GOOGLE_ANALYTICS_GA4: 'G-VP5XZP00HF',
    GOOGLE_ANALYTICS_UA: 'UA-68990594-1',
    VERSION: `${require('../../package.json').version}-staging`,
    name: 'staging',
    firebase: {
        ...FirebaseInfo,
        databaseURL: firebaseDatabaseUrl('staging')
    },
    muxDataKey: 'b63ofo95pnbagrg3fp2dfi6et',
    CONTAINER_ONESIGNAL_APP_ID: '075de669-482d-4829-a022-97939f531deb',
    ZOOM_EMBED_URL: 'https://zm-embed-staging.crowdcomms.com/',
    BREAKOUTS_URL: 'https://breakouts-staging.crowdcomms.com/',
    PWA_ENABLED: true,
    WEB_PUSH_PUBLIC_KEY: 'BHLflnqLJQboL6ZfHV6uJRnpXipry064ftkE1aDmwr8DQfsHFI1M0Jzwbv6VU6eY0d07qxUPBJFm7dWrleczDHk'
};
