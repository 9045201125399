import { createFeatureSelector, createSelector } from '@ngrx/store';
import { userBusinessCardsKey, UserBusinessCardsState } from '../models/user-business-cards-state.model';
import {
    userBusinessCardsPageContactsEntityAdapter,
    userBusinessCardsPageRequestsEntityAdapter
} from '../reducers/user-business-cards-page.reducer';

const userBadgeContactsStateSelector = createFeatureSelector<UserBusinessCardsState>(userBusinessCardsKey);
const userBadgeContactsPageStateSelector = createSelector(userBadgeContactsStateSelector, (state) => state.page);

const contactEntitySelectors = userBusinessCardsPageContactsEntityAdapter.getSelectors();

const selectContacts = createSelector(userBadgeContactsPageStateSelector, (state) =>
    contactEntitySelectors.selectAll(state.contacts)
);

const selectContactsLoading = createSelector(userBadgeContactsPageStateSelector, (state) => state.contacts.loading);
const selectContactsTotal = createSelector(userBadgeContactsPageStateSelector, (state) => state.contacts.total);
const selectContactsOffset = createSelector(userBadgeContactsPageStateSelector, (state) => state.contacts.offset);
const selectContactsSelectedId = createSelector(userBadgeContactsPageStateSelector, (state) => state.contacts.selected);
const selectContactsSearching = createSelector(userBadgeContactsPageStateSelector, (state) => state.contacts.searching);
const selectContactsSearch = createSelector(userBadgeContactsPageStateSelector, (state) => state.contacts.search);
const selectContactsOrdering = createSelector(userBadgeContactsPageStateSelector, (state) => state.contacts.ordering);

const selectContactsDictionary = createSelector(userBadgeContactsPageStateSelector, (state) =>
    contactEntitySelectors.selectEntities(state.contacts)
);

const selectContactsSelected = createSelector(
    selectContactsDictionary,
    selectContactsSelectedId,
    (contactsDictionary, selectedContactId) => contactsDictionary[selectedContactId]
);

const selectContactsWithoutSelected = createSelector(
    selectContacts,
    selectContactsSelected,
    (contacts, selectedContact) => contacts.filter((x) => x.id !== selectedContact?.id)
);
const requestEntitySelectors = userBusinessCardsPageRequestsEntityAdapter.getSelectors();

const selectRequests = createSelector(userBadgeContactsPageStateSelector, (state) =>
    requestEntitySelectors.selectAll(state.requests)
);

const selectRequestsLoading = createSelector(userBadgeContactsPageStateSelector, (state) => state.requests.loading);
const selectRequestsTotal = createSelector(userBadgeContactsPageStateSelector, (state) => state.requests.total);
const selectRequestsOffset = createSelector(userBadgeContactsPageStateSelector, (state) => state.requests.offset);

const selectFlipped = createSelector(userBadgeContactsPageStateSelector, (state) => state.flipped);

export const UserBusinessCardsPageSelectors = {
    selectContacts,
    selectContactsWithoutSelected,
    selectContactsSelected,
    selectContactsLoading,
    selectContactsTotal,
    selectContactsOffset,
    selectContactsSearching,
    selectContactsSearch,
    selectContactsOrdering,
    selectRequests,
    selectRequestsLoading,
    selectRequestsTotal,
    selectRequestsOffset,
    selectFlipped
};
