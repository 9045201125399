import { Pipe, PipeTransform } from '@angular/core';
import { Duration } from 'luxon';

@Pipe({
    name: 'duration'
})
/*
 * Converts seconds to HH:MM:SS format
 */
export class DurationPipe implements PipeTransform {
    transform(seconds: number, format = 'hh:mm:ss'): string {
        return Duration.fromMillis(seconds * 1000).toFormat(format);
    }
}
