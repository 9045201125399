import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { fadeIn } from '@common/animations/animations';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { AppRoutingActions } from '@core/root-store/store/app-routing/actions/app-routing.actions';
import { AppPageRoutes } from '@core/routing/constants/app-page-routes.constant';
import { AppRoutingFacadeService } from '@core/routing/services/app-routing-facade.service';
import { PersonActionService } from '@features/people/services/person-action/person-action.service';
import { ModalController, PopoverController } from '@ionic/angular';
import { PeopleMinimal } from '@shared/api';
import { PersonPopoverApiService } from '@shared/person-popover/services/person-popover-api/person-popover-api.service';
import { TooltipOptions } from 'ng2-tooltip-directive-major-angular-updates';
import { concat, Observable, of } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';

@Component({
    selector: 'cc-person-popover',
    templateUrl: './person-popover.component.html',
    styleUrls: ['./person-popover.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [fadeIn()]
})
export class PersonPopoverComponent implements OnInit {
    @Input()
    public person: PeopleMinimal;

    public minimalPerson$: Observable<PeopleMinimal>;

    public canCall$: Observable<boolean>;
    public canChat$: Observable<boolean>;
    public canAttendMeetings$: Observable<boolean>;

    public tooltipOptions: TooltipOptions = {
        'hide-delay': 0,
        'animation-duration': 0
    };

    constructor(
        private appRoutingFacadeService: AppRoutingFacadeService,
        private appCoreFacadeService: AppCoreFacadeService,
        private popoverController: PopoverController,
        private modalController: ModalController,
        private personPopoverApiService: PersonPopoverApiService,
        private personActionService: PersonActionService
    ) {}

    public ngOnInit(): void {
        this.minimalPerson$ = concat(
            of(this.person),
            this.appCoreFacadeService
                .getAppName()
                .pipe(switchMap((appName) => this.personPopoverApiService.getMinimalPerson(appName, this.person.id)))
        ).pipe(shareReplay(1));

        this.canCall$ = this.minimalPerson$.pipe(switchMap((person) => this.personActionService.canVideoCall(person)));
        this.canChat$ = this.minimalPerson$.pipe(switchMap((person) => this.personActionService.canChat(person)));
        this.canAttendMeetings$ = this.minimalPerson$.pipe(
            switchMap((person) => this.personActionService.canUseMeetingBooking(person))
        );
    }

    public openPerson(): void {
        this.appRoutingFacadeService.dispatch(
            AppRoutingActions.goToAppPage({ urlSegments: [AppPageRoutes.person, this.person.id] })
        );
        this.close();
    }

    public videoCall(person: PeopleMinimal): void {
        this.personActionService.videoCall(person);
        this.close();
    }

    public startChat(): void {
        this.personActionService.chat(this.person);
        this.close();
    }

    public scheduleMeeting(): void {
        this.personActionService.scheduleMeeting(this.person);
        this.close();
    }

    // As this popover can also be opened as a modal we need to check what we're currently being displayed as.
    public async close(): Promise<void> {
        const currentPopover = await this.popoverController.getTop();

        if (currentPopover) {
            this.popoverController.dismiss();
            return;
        }

        this.modalController.dismiss();
    }
}
