import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { AuthenticationFacadeService } from '@core/authentication/services/authentication-facade.service';
import { AppCoreActions } from '@core/root-store/store/app-core/actions/app-core.actions';
import { PeopleApiService } from '@features/people/services/people-api.service';
import { of } from 'rxjs';
import { PeopleApiActions } from '../actions/people-api.actions';
import { PeopleListActions } from '../actions/people-list.actions';

@Injectable()
export class PeopleEffects {
    fetchVisibility$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppCoreActions.initialiseAppSuccess),
            switchMap(() => this.authenticationFacadeService.hasAppAccess()),
            filter((hasAccess) => hasAccess),
            withLatestFrom(this.appCoreFacadeService.getAppName()),
            switchMap(([, appUrl]) => this.peopleApiService.getVisibility(appUrl)),
            map((peopleVisibility) => PeopleApiActions.fetchVisibilitySuccess({ peopleVisibility })),
            catchError((error: HttpErrorResponse) => of(PeopleApiActions.fetchVisibilityFailure({ error })))
        )
    );

    fetchPeopleModule$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PeopleListActions.enterPage),
            withLatestFrom(this.appCoreFacadeService.getAppName()),
            switchMap(([{ moduleId }, appName]) => this.peopleApiService.getPeopleModule(appName, moduleId)),
            map((module) => PeopleApiActions.fetchPeopleModuleSuccess({ module }))
        )
    );

    constructor(
        private actions$: Actions,
        private appCoreFacadeService: AppCoreFacadeService,
        private peopleApiService: PeopleApiService,
        private authenticationFacadeService: AuthenticationFacadeService
    ) {}
}
