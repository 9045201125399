import { MeetingBookingConfigModel } from '@features/meeting-booking/store/models/meeting-booking-config.model';
import { createAction, props } from '@ngrx/store';

const prefix = '[Global Settings] ';

const fetchMeetingBookingConfig = createAction(prefix + 'Fetch meeting config');
const fetchMeetingBookingConfigSuccess = createAction(
    prefix + 'Fetch meeting config success',
    props<{ config: MeetingBookingConfigModel }>()
);
const fetchMeetingBookingConfigFailure = createAction(prefix + 'Fetch meeting config failure');

export const GlobalSettingsActions = {
    fetchMeetingBookingConfig,
    fetchMeetingBookingConfigSuccess,
    fetchMeetingBookingConfigFailure
};
