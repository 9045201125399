<div class="agenda-card-speakers-list__container">
    <div class="agenda-card-speakers-list__header" @fadeIn>
        <i class="agenda-card-speakers-list__icon material-icons"> people </i>

        <ion-label class="agenda-card-speakers-list__title">
            {{ 'AGENDA_CARDS_SPEAKERS_LIST_TITLE' | translate }}
        </ion-label>
    </div>

    <div class="agenda-card-speakers-list__items">
        <cc-agenda-card-speaker-item
            *ngFor="let speaker of speakers"
            class="agenda-card-speakers-list__item"
            [speaker]="speaker"
            @fadeIn
        ></cc-agenda-card-speaker-item>
    </div>
</div>
