import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { VideoCallsActions } from '../actions/video-calls.actions';
import { VideoCallApiService } from '../../services/video-call-api.service';
import { VideoCallService } from '../../services/video-call.service';
import { VideoCallFacadeService } from '../../services/video-call-facade.service';
import { VideoCallsApiActions } from '../actions/video-calls-api.actions';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class VideoCallEffects {
    acceptCall$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(VideoCallsActions.acceptCall),
                switchMap(({ incomingCall }) =>
                    this.videoCallApiService
                        .acceptCall(incomingCall.accept_call_endpoint)
                        .pipe(tap(() => this.videoCallService.setUrl(incomingCall.video_call_link)))
                )
            ),
        { dispatch: false }
    );

    declineCall$ = createEffect(() =>
        this.actions$.pipe(
            ofType(VideoCallsActions.declineCall),
            switchMap(({ incomingCall }) =>
                this.videoCallApiService.declineCall(incomingCall.decline_call_endpoint).pipe(
                    map(() => VideoCallsApiActions.declineCallSuccess()),
                    catchError((error: HttpErrorResponse) =>
                        of(VideoCallsApiActions.declineCallFailure({ error: error.message }))
                    )
                )
            )
        )
    );

    blockCaller$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(VideoCallsActions.blockCaller),
                tap(({ incomingCall }) => this.videoCallService.showBlockCallerDialog(incomingCall))
            ),
        { dispatch: false }
    );

    cancelCall$ = createEffect(() =>
        this.actions$.pipe(
            ofType(VideoCallsActions.cancelCall),
            withLatestFrom(this.videoFacadeService.getCall()),
            switchMap(([{}, videoCall]) =>
                this.videoCallApiService.declineCall(videoCall.decline_call_endpoint).pipe(
                    map(() => VideoCallsApiActions.cancelCallSuccess()),
                    catchError((error: HttpErrorResponse) =>
                        of(VideoCallsApiActions.cancelCallFailure({ error: error.message }))
                    )
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private videoCallApiService: VideoCallApiService,
        private videoCallService: VideoCallService,
        private videoFacadeService: VideoCallFacadeService
    ) {}
}
