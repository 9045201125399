<div class="track-chips__container">
    <div class="track-chips__list">
        <ion-chip
            *ngFor="let track of tracks; trackBy: trackByFn"
            class="track-chips__item"
            [ngClass]="'track-chip-' + track.id"
            [ngStyle]="getChipStyle(track)"
        >
            <ion-label class="track-chips__item-label">{{ track.name }}</ion-label>
        </ion-chip>
    </div>
</div>
