import { MapState, mapStoreKey } from '../models/map.state.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { filterBySearchTerm, getSortedItems } from '../../../../shared/utilities/sorting-utility.functions';

const selectMapState = createFeatureSelector<MapState>(mapStoreKey);

const selectListState = createSelector(selectMapState, (state: MapState) => state.mapsListPageState);
const selectDetailState = createSelector(selectMapState, (state: MapState) => state.mapDetailPageState);
const selectMaps = (moduleId: number) =>
    createSelector(selectMapState, (state: MapState) => {
        const mapModule = state.mapModule[moduleId];
        if (!state || !mapModule) {
            return [];
        }
        const filteredMaps = filterBySearchTerm(state.maps, state.mapsListPageState.searchTerm, [
            'location_name'
        ]).filter((locationMap) => locationMap.module === moduleId);
        return getSortedItems(filteredMaps, mapModule.map_ordering, mapModule.maps);
    });

const selectTitle = createSelector(selectMapState, (state) => {
    const mapId = state.mapDetailPageState?.mapId;
    return state.maps?.find((map) => map.id === mapId)?.location_name;
});

export const MapSelectors = {
    selectMaps,
    selectListState,
    selectDetailState,
    selectTitle
};
