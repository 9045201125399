export interface AppAlert {
    style?: string;
    target_url?: string;
    app: string;
    groups: Array<string>;
    created?: Date | string;
    id?: number;
    native_notification?: boolean;
    title: string;
    email_alert?: boolean;
    sms_template?: string;
    email_template?: string;
    alert_type?: AlertTypeEnum;
    attachment?: string;
    icon?: string;
    is_active?: boolean;
    sms_alert?: boolean;
    uuid?: string;
    end?: string;
    start: string;
    sent?: boolean;
    description?: string;
    description_rich?: string;
    description_short?: string;
    state?: AlertStatusEnum;
    mandatory_completion?: boolean;
    cta_button_text?: string;
    embedded_survey_id?: number;
    archived?: boolean;
    read?: boolean;
    deeplink_action?: string;
    deeplink_url?: string;
    deeplink_value?: string;
}

export enum AlertTypeEnum {
    Notify = 'notify',
    Popup = 'popup',
    Persistent = 'persistent',
    Fading = 'fading'
}

export enum AlertStatusEnum {
    Sent = 'sent',
    Ready = 'ready'
}
