import { Injectable } from '@angular/core';
import { BannerAd } from '@shared/api';
import { UrlUtilityService } from '../../utilities/url-utility.service';
import { AnalyticsService } from '../../../core/analytics/services/analytics.service';
import { AnalyticsOptionsService } from '../../../core/analytics/services/analytics-options.service';

@Injectable({
    providedIn: 'root'
})
export class BannerAdService {
    constructor(
        private urlUtilityService: UrlUtilityService,
        private analyticsOptionsService: AnalyticsOptionsService,
        private analyticsService: AnalyticsService
    ) {}

    handleBannerClick(banner: BannerAd): void {
        const url = banner.url;
        if (url === undefined || url === null) {
            return;
        }
        const analyticsOptions = this.analyticsOptionsService.clickItem('banner_ad', banner.id);
        this.analyticsService.emit(analyticsOptions);
        if (url.indexOf('http') === 0) {
            this.urlUtilityService.open(url);
        }
    }
}
