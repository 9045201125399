export interface LiveDisplayScreenActivityFeed {
    id?: number;
    headers?: Array<number>;
    footers?: Array<number>;
    background_color?: string;
    toggle_background?: LiveDisplayScreenActivityFeed.ToggleBackgroundEnum;
    title?: string;
    order?: number;
    is_visible?: boolean;
    card_color?: string;
    card_text_color?: string;
    layout?: LiveDisplayScreenActivityFeed.LayoutEnum;
    transition_style?: string;
    transition_duration?: number;
    background_image?: number;
    live_display: number;
    activity_feed: number;
}

export namespace LiveDisplayScreenActivityFeed {
    export enum ToggleBackgroundEnum {
        Color = 'background_color',
        Image = 'background_image'
    }
    export enum LayoutEnum {
        Grid = 'grid',
        Collage = 'collage',
        Single = 'single'
    }
}
