<ion-card *ngIf="socialInfo && socialInfo.length">
    <ion-card-header>
        <ion-card-title class="bold">
            {{ 'PEOPLE_SOCIAL_MEDIA_TITLE' | translate }}
        </ion-card-title>
    </ion-card-header>
    <ion-card-content>
        <div class="person-social-media__container">
            <ng-container *ngFor="let site of socialInfo">
                <cc-social-media [socialInfo]="site"></cc-social-media>
            </ng-container>
        </div>
    </ion-card-content>
</ion-card>
