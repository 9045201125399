export enum GoogleAnalyticsEventType {
    // Existing Event Actions
    Click = 'CLICK',
    View = 'VIEW',
    EnterLoggedInUser = 'ENTER_LOGGEDIN_USER',
    Logout = 'LOGOUT',

    // Recommended GTAG Event Actions
    LOGIN = 'login',
    SEARCH = 'search',
    SELECT_CONTENT = 'select_content',
    SHARE = 'share',
    SIGN_UP = 'sign_up',
    VIEW_ITEM = 'view_item',
    VIEW_ITEM_LIST = 'view_item_list',
    VIEW_PROMOTION = 'view_promotion',
    VIEW_SEARCH_RESULT = 'view_search_results',
    VIEW_SEARCH_RESULTS = 'view_search_results'
}
