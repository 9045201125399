<ion-header class="modal__header ion-no-border">
    <ion-toolbar class="modal__toolbar">
        <i *ngIf="icon" class="modal__icon material-icons" slot="start" aria-hidden="true">{{ icon }}</i>

        <ion-title>
            <div class="modal__title ion-text-wrap">{{ header | translate }}</div>
        </ion-title>

        <ion-buttons class="modal__buttons" slot="end">
            <ion-button
                *ngIf="canClose"
                class="modal__close-button"
                color="default"
                fill="clear"
                (click)="emitCloseClicked()"
            >
                <i class="material-icons" slot="icon-only">close</i>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ng-content></ng-content>
