<ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="typeEnum.Menu">
        <ion-button
            *ngIf="showMenuButton"
            class="toolbar-menu-button clear-on-primary"
            fill="clear"
            (click)="onMenuButtonClick()"
        >
            <i class="material-icons" slot="icon-only" aria-label="Navigation Menu" aria-expanded="false">menu</i>
        </ion-button>
    </ng-container>
    <ng-container *ngSwitchCase="typeEnum.Apps">
        <ion-button class="toolbar-portal-button clear-on-primary" fill="clear" (click)="onMenuButtonClick()">
            <i class="material-icons" slot="icon-only" aria-label="Navigation Back to portal">apps</i>
        </ion-button>
    </ng-container>
    <ng-container *ngSwitchCase="typeEnum.Home">
        <ion-button class="toolbar-home-button clear-on-primary" fill="clear" (click)="onMenuButtonClick()">
            <i class="material-icons" slot="icon-only" aria-label="Navigation Home">home</i>
        </ion-button>
    </ng-container>
</ng-container>

<ion-button *ngIf="canGoBack" class="clear-on-primary cc-toolbar-back-button" fill="clear" (click)="onBackClick()">
    <i class="material-icons" slot="icon-only" aria-label="Navigation Back">chevron_left</i>
</ion-button>
