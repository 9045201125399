import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { tagManagerFeatureKey, tagManagerReducer } from './reducers/tag-manager.reducer';
import { TagManagerEffects } from './effects/tag-manager.effects';

@NgModule({
    imports: [
        StoreModule.forFeature(tagManagerFeatureKey, tagManagerReducer),
        EffectsModule.forFeature([TagManagerEffects])
    ]
})
export class TagManagerStoreModule {}
