<cc-page
    *ngIf="
        {
            initialising: initialising$ | async,
            person: person$ | async,
            sessions: sessions$ | async,
            customFields: customFields$ | async,
            documents: documents$ | async,
            app: app$ | async,
            loggedInUser: loggedInUser$ | async,
            socialInfo: socialInfo$ | async,
            sidebarLeftExpanded: sidebarLeftExpanded$ | async,
            headerActions: headerActions$ | async,
            businessCard: businessCard$ | async,
            canExchangeBusinessCards: canExchangeBusinessCards$ | async
        } as pageData;
        else loading
    "
    [ngClass]="'cc-people-page-' + pageData.person?.id"
>
    <div class="loading-container" *ngIf="pageData.initialising">
        <ng-container *ngTemplateOutlet="loading"></ng-container>
    </div>
    <ng-container *ngIf="pageData.initialising === false">
        <cc-person-header [person]="pageData.person" [actions]="pageData.headerActions"></cc-person-header>
        <ion-content ccIonicScrollbar>
            <div class="container">
                <!--About Card-->
                <ion-card *ngIf="pageData.person.about">
                    <ion-card-header>
                        <ion-card-title class="bold" translate>PEOPLE_ABOUT_TITLE</ion-card-title>
                    </ion-card-header>
                    <ion-card-content>
                        <div [ccCompileHtml]="pageData.person.about" class="about-content wysiwyg-content"></div>
                    </ion-card-content>
                </ion-card>

                <!-- Business card exchange -->
                <cc-person-business-card-exchange
                    *ngIf="pageData.canExchangeBusinessCards"
                    [businessCard]="pageData.businessCard"
                    (exchangeClicked)="onExchangeBusinessCard()"
                ></cc-person-business-card-exchange>

                <!--Sessions-->
                <cc-person-agenda-sessions
                    [agendas]="pageData.sessions"
                    [enabledFeatures]="enabledFeatures"
                    [personalAgendaSessions]="pageData.loggedInUser ? pageData.loggedInUser.personal_agenda : []"
                    (sessionClick)="onTapSession($event)"
                ></cc-person-agenda-sessions>

                <!--Custom Fields-->
                <cc-custom-fields [customFields]="pageData.customFields"> </cc-custom-fields>

                <!--Social Media-->
                <cc-person-social-media-links [socialInfo]="pageData.socialInfo"> </cc-person-social-media-links>

                <!--Documents-->
                <cc-person-documents (tapDocument)="onTapDocument($event)" [documents]="pageData.documents">
                </cc-person-documents>
            </div>
        </ion-content>
        <!--Mobile Footer-->
        <cc-person-footer *ngIf="!pageData.sidebarLeftExpanded" [person]="pageData.person"></cc-person-footer>
    </ng-container>
</cc-page>

<ng-template #loading>
    <cc-loading-spinner></cc-loading-spinner>
</ng-template>
