import * as Sentry from '@sentry/angular-ivy';
import { Integrations } from '@sentry/tracing';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AppFailedComponent } from './pages/app-failed/app-failed.component';
import { Error404Component } from './pages/error404/error404.component';
import { Router } from '@angular/router';
import { AppCommonModule } from '@common/common.module';
import { LottieModule } from 'ngx-lottie';
import { SentryIgnoreErrors } from './constants/sentry-ignore-errors.constant';

export function sentryInitializerFactory() {
    return () => {
        Sentry.init({
            dsn: 'https://fd96d64bf9f149b1833cfbd76c8cf819@o94555.ingest.sentry.io/1207744',
            integrations: [
                new Integrations.BrowserTracing({
                    routingInstrumentation: Sentry.routingInstrumentation
                })
            ],
            environment: environment.name,
            release: environment.VERSION,
            ignoreErrors: SentryIgnoreErrors
        });
    };
}

export const sentryInitializer = {
    provide: APP_INITIALIZER,
    useFactory: sentryInitializerFactory,
    multi: true,
    deps: []
};

@NgModule({
    declarations: [AppFailedComponent, Error404Component],
    imports: [AppCommonModule, LottieModule],
    providers: [
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false
            })
        },
        {
            provide: Sentry.TraceService,
            deps: [Router]
        }
    ]
})
export class ErrorHandlingModule {}
