import { NgModule } from '@angular/core';
import { AutoScrollBottomComponent } from './components/auto-scroll-bottom/auto-scroll-bottom.component';
import { ScrollToDirective } from './directives/scroll-to/scroll-to.directive';
import { AppCommonModule } from '@common/common.module';
import { ResizeObserverModule } from '@shared/resize-observer/resize-observer.module';

@NgModule({
    declarations: [AutoScrollBottomComponent, ScrollToDirective],
    imports: [AppCommonModule, ResizeObserverModule],
    exports: [AutoScrollBottomComponent, ScrollToDirective]
})
export class ScrollModule {}
