<div class="business-card__container">
    <ion-card
        class="business-card__card"
        [style.background]="background"
        [style.--color]="color"
        [class.stacked]="stacked"
    >
        <ion-card-header class="business-card__header">
            <cc-business-card-title
                class="business-card__title"
                [stacked]="stacked"
                [profileFields]="design.profile_fields"
                [person]="person"
            ></cc-business-card-title>

            <cc-business-card-icons
                class="business-card__icons"
                [size]="stacked ? 'stacked' : 'default'"
                [person]="person"
                [companyIcon]="design.company_logo"
            ></cc-business-card-icons>
        </ion-card-header>

        <ion-card-content *ngIf="!stacked" class="business-card__content">
            <cc-business-card-details
                class="business-card__details"
                [profileFields]="design.profile_fields"
                [person]="person"
            ></cc-business-card-details>

            <cc-business-card-socials
                *ngIf="socialInfo.length"
                class="business-card__socials"
                [socialInfo]="socialInfo"
                [socialFields]="design.social_fields"
                (socialClicked)="emitSocialClicked($event)"
            ></cc-business-card-socials>
        </ion-card-content>

        <ion-item class="business-card__footer ion-item-reset" lines="none">
            <ng-content></ng-content>
        </ion-item>
    </ion-card>
</div>
