<ion-item button lines="full" class="chat-start-new-item__item" (click)="onStartNewChat()">
    <div class="chat-start-new-item__peer-icon" slot="start">
        <cc-person-icon size="small" [person]="person"></cc-person-icon>
    </div>

    <ion-label>
        <h3 class="chat-start-new-item__peer">
            <cc-person-name [person]="person"></cc-person-name>
        </h3>
    </ion-label>

    <i class="material-icons chat-start-new-item__icon" slot="end">add</i>
</ion-item>
