import { Injectable } from '@angular/core';
import { filterNil } from '@shared/rxjs/custom.rxjs';
import { BehaviorSubject, Observable } from 'rxjs';
import { AnalyticsPageView } from '../interfaces/analytics-page-view-data.interface';
import { AnalyticsSetting } from '../interfaces/analytics-setting.interface';

@Injectable({
    providedIn: 'root'
})
export class AnalyticsDataStreamService {
    private _event$: BehaviorSubject<AnalyticsSetting> = new BehaviorSubject(undefined);
    private _pageView$: BehaviorSubject<AnalyticsPageView> = new BehaviorSubject(undefined);

    constructor() {}

    public get event$(): Observable<AnalyticsSetting> {
        return this._event$.asObservable().pipe(filterNil());
    }

    public get pageView$(): Observable<AnalyticsPageView> {
        return this._pageView$.asObservable().pipe(filterNil());
    }

    public sendEvent(data: AnalyticsSetting): void {
        this._event$.next(data);
    }

    public sendPageView(data: AnalyticsPageView): void {
        this._pageView$.next(data);
    }
}
