@if (hasVisibility$ | async) {
    @switch (type) {
        @case (typeEnum.LightboxImage) {
            <div ccLightboxImage [image]="$any(elementOptions)?.image">
                <ng-container *ngTemplateOutlet="content"></ng-container>
            </div>
        }
        @case (typeEnum.DirectLink) {
            <cc-open-direct-link [actionId]="$any(elementOptions)?.action">
                <ng-container *ngTemplateOutlet="content"></ng-container>
            </cc-open-direct-link>
        }
        @default {
            <ng-container *ngTemplateOutlet="content"></ng-container>
        }
    }

    <ng-template #content>
        <ng-content></ng-content>
    </ng-template>
}
