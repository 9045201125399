import { UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { PaginatedResponse } from '../../models/paginated-response.model';
import { PaginationState } from '../../models/pagination-state.model';
import { PaginationStoreState } from '../../models/pagination-store-state.model';

@UntilDestroy()
export abstract class PaginationStore<TResource, TExtraQuery = any> extends ComponentStore<
    PaginationStoreState<TResource, TExtraQuery>
> {
    public readonly resource$: Observable<PaginatedResponse<TResource>> = this.select((state) => state.resource);
    public readonly query$: Observable<PaginationState & TExtraQuery> = this.select((state) => state.query);
    public readonly isLoading$: Observable<boolean> = this.select((state) => state.isLoading);

    /**
     * @description Update the existing query, useful when you want to only update part of the query and keep the exising items
     */
    public readonly patchQuery = this.updater((state, query: PaginationState & TExtraQuery) => ({
        ...state,
        query: {
            ...state.query,
            ...query
        }
    }));

    /**
     * @description Submit a completely new query that will wipe the existing one and replace it with the provided one
     */
    public readonly submitQuery = this.updater((state, query: PaginationState & TExtraQuery) => ({
        ...state,
        query
    }));

    public readonly refreshQuery = this.updater((state) => ({
        ...state,
        query: {
            ...state.query,
            page: 1
        }
    }));

    public readonly removeItem = this.updater((state, callback: (item: TResource) => boolean) => {
        const newItems = state.resource.results.filter((item) => !callback(item));

        return {
            ...state,
            resource: {
                results: newItems,
                count: state.resource.count - 1
            }
        };
    });

    public readonly addItem = this.updater((state, options: { item: TResource; position?: 'start' | 'end' }) => {
        let newItems: Array<TResource>;

        if (!options.position || options.position === 'start') {
            newItems = [options.item, ...state.resource.results];
        } else {
            newItems = [...state.resource.results, options.item];
        }

        return {
            ...state,
            resource: {
                results: newItems,
                count: state.resource.count + 1
            }
        };
    });

    public readonly decrementCount = this.updater((state) => {
        return {
            ...state,
            resource: {
                results: state.resource.results,
                count: state.resource.count - 1
            }
        };
    });

    public readonly incrementCount = this.updater((state) => {
        return {
            ...state,
            resource: {
                results: state.resource.results,
                count: state.resource.count + 1
            }
        };
    });

    public readonly patchItem = this.updater(
        (
            state,
            options: {
                find: (item: TResource) => boolean;
                newItem: Partial<TResource>;
            }
        ) => {
            const itemIndex = state.resource.results.findIndex(options.find);

            const newItems = state.resource.results.map((item, index) => {
                if (index !== itemIndex) {
                    return item;
                }

                return {
                    ...item,
                    ...options.newItem
                };
            });

            return {
                ...state,
                resource: {
                    ...state.resource,
                    results: newItems
                }
            };
        }
    );

    /**
     * @description Patch the query to fetch the next page in the pagination
     */
    public readonly nextPage = this.updater((state) => ({
        ...state,
        query: {
            ...state.query,
            page: state.query.page + 1
        }
    }));

    /**
     * @description Called when the resource has been fetched and needs to be slotted in to the state
     */
    protected updateResource = this.updater((state, response: PaginatedResponse<TResource>) => ({
        ...state,
        resource: response
    }));

    private setIsLoading = this.updater((state, isLoading: boolean) => ({
        ...state,
        isLoading
    }));

    private readonly fetchItems = this.effect((query$: Observable<PaginationState & TExtraQuery>) => {
        return query$.pipe(
            tap(() => this.setIsLoading(true)),
            switchMap((query) => this.fetchResource(query)),
            tap(() => this.setIsLoading(false)),
            tap((response: PaginatedResponse<TResource>) => {
                this.updateResource(response);
            })
        );
    });

    /**
     * @description To be called when you want the first lot of paginated results to be fetched.
     * No other methods can be used until this is called, and the component store will let you know if you do this.
     */
    public initialise(initialQuery: PaginationState & TExtraQuery): void {
        this.setState({ query: initialQuery, resource: { count: 0, results: [] }, isLoading: false });

        this.query$.subscribe((query) => {
            this.fetchItems(query);
        });
    }

    /**
     * @description Creates a search and hooks in to its value changes automatically so that it will trigger a search query.
     * @returns A form control to be passed in to an input in the template.
     */
    public createAndListenToSearch(initialSearchValue?: string): UntypedFormControl {
        const formControl = new UntypedFormControl(initialSearchValue);

        formControl.valueChanges.pipe(debounceTime(300), untilDestroyed(this)).subscribe((search) => {
            this.patchQuery({
                page: 1,
                search
            } as any);
        });

        return formControl;
    }

    /**
     * @description To be provided in the feature-specific class that extends this.
     * This method should take the query and make an API request with it, returning a paginated response.
     */
    protected abstract fetchResource(query: PaginationState): Observable<PaginatedResponse<TResource>>;
}
