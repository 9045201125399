import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExpandedApiDrivenNotification } from '@core/notifications/interfaces/expanded-api-driven-notification.interface';
import { NotificationsService } from '@core/notifications/services/notifications/notifications.service';
import { TooltipOptions } from 'ng2-tooltip-directive-major-angular-updates';

@Component({
    selector: 'cc-notification-list-item',
    templateUrl: './notification-list-item.component.html',
    styleUrls: ['./notification-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationListItemComponent implements OnInit {
    @Input()
    public notification: ExpandedApiDrivenNotification;

    @Output()
    public actionClicked = new EventEmitter<void>();

    @Output()
    public archive = new EventEmitter<void>();

    @Output()
    public markAsRead = new EventEmitter<void>();

    @Output()
    public unarchive = new EventEmitter<void>();

    public tooltipOptions: TooltipOptions = {
        'hide-delay': 0,
        'animation-duration': 0
    };

    public title: string;
    public messages: string[];

    constructor(private notificationsService: NotificationsService) {}

    ngOnInit(): void {
        const messageData = this.notificationsService.convertHtmlNotificationToObject(this.notification.message);
        this.title = messageData.title;
        this.messages = messageData.message;
    }

    public onToggleArchive(): void {
        if (this.notification.archived) {
            this.unarchive.emit();
        } else {
            this.archive.emit();
        }
    }
}
