import { Injectable } from '@angular/core';

import { Capacitor } from '@capacitor/core';
import { SplashScreen, SplashScreenPlugin } from '@capacitor/splash-screen';
import { StatusBar, StatusBarPlugin } from '@capacitor/status-bar';
import { Browser, BrowserPlugin } from '@capacitor/browser';
import { Preferences, PreferencesPlugin } from '@capacitor/preferences';
import { Camera, CameraPlugin } from '@capacitor/camera';
import { Device, DevicePlugin } from '@capacitor/device';
import { App, AppPlugin } from '@capacitor/app';
import { Filesystem, FilesystemPlugin } from '@capacitor/filesystem';
import { Badge, BadgePlugin } from '@capawesome/capacitor-badge';
import { FilePicker, FilePickerPlugin } from '@capawesome/capacitor-file-picker';
import { Share, SharePlugin } from '@capacitor/share';
import { Haptics, HapticsPlugin } from '@capacitor/haptics';

import { BarcodeScanner, BarcodeScannerPlugin } from '@capacitor-community/barcode-scanner';
import { AppTrackingTransparency, AppTrackingTransparencyPlugin } from 'capacitor-plugin-app-tracking-transparency';

@Injectable({
    providedIn: 'root'
})
export class CapacitorService {
    constructor() {}

    /**
     * Returns if the current instance is native or not.
     */
    public get isNative(): boolean {
        return Capacitor.isNativePlatform();
    }

    /**
     * Returns the current platform, ios, android or web.
     */
    public get platform(): string {
        return Capacitor.getPlatform();
    }

    /**
     * Returns the capacitor browser plugin.
     */
    public get browser(): BrowserPlugin {
        return Browser;
    }

    /**
     * Returns the capacitor device plugin.
     */
    public get device(): DevicePlugin {
        return Device;
    }

    /**
     * Returns the capacitor app plugin.
     */
    public get app(): AppPlugin {
        return App;
    }

    /**
     * Returns the capacitor splash screen plugin.
     */
    public get splashScreen(): SplashScreenPlugin {
        return SplashScreen;
    }

    /**
     * Returns the capacitor storage plugin.
     */
    public get storage(): PreferencesPlugin {
        return Preferences;
    }

    /**
     * Returns the capacitor storage plugin.
     */
    public get fileSystem(): FilesystemPlugin {
        return Filesystem;
    }

    /**
     * Returns the capacitor camera plugin.
     */
    public get camera(): CameraPlugin {
        return Camera;
    }

    /**
     * Return the capacitor community badge plugin.
     */
    public get badge(): BadgePlugin {
        return Badge;
    }

    /**
     * Returns the capacitor status bar plugin.
     */
    public get statusBar(): StatusBarPlugin {
        return StatusBar;
    }

    /**
     * Returns the capacitor community barcode & qr code scanner plugin.
     */
    public get barcodeScanner(): BarcodeScannerPlugin {
        return BarcodeScanner;
    }

    /**
     * Returns the capacitor community app tracking transparency plugin.
     */
    public get trackingTransparency(): AppTrackingTransparencyPlugin {
        return AppTrackingTransparency;
    }

    public get share(): SharePlugin {
        return Share;
    }

    public get filePicker(): FilePickerPlugin {
        return FilePicker;
    }

    public get haptics(): HapticsPlugin {
        return Haptics;
    }

    /**
     * Checks if a plugin is available.
     */
    public isPluginAvailable(plugin: string): boolean {
        return Capacitor.isPluginAvailable(plugin);
    }
}
