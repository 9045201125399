import { createFeatureSelector, createSelector } from '@ngrx/store';
import { businessCardsKey, BusinessCardsState } from '../models/business-cards-state.model';

const userBadgeContactsStateSelector = createFeatureSelector<BusinessCardsState>(businessCardsKey);

const selectDesign = createSelector(userBadgeContactsStateSelector, (state) => state.design);
const selectSocialInfo = createSelector(userBadgeContactsStateSelector, (state) => state.socialInfo);

export const BusinessCardsSelectors = {
    selectDesign,
    selectSocialInfo
};
