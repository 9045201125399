import { Injectable } from '@angular/core';
import { App, People } from '@shared/api';
import { DeviceService } from '../../device/services/device.service';
import { environment } from '../../../../environments/environment';
import { ContainerService } from '@features/container/services/container.service';
import { filter, from, interval, map, Observable, of, startWith, switchMap, take } from 'rxjs';
import OneSignal from 'onesignal-cordova-plugin';

@Injectable({
    providedIn: 'root'
})
export class PushNotificationService {
    constructor(
        private deviceService: DeviceService,
        private containerService: ContainerService
    ) {}

    public optIn(app: App, loggedInUser: People): Observable<string> {
        if (!this.deviceService.isNative()) {
            return of(null);
        }

        const isRunningInContainer = this.containerService.isRunningInContainer(app.url_name);
        const oneSignalAppId = isRunningInContainer ? environment.CONTAINER_ONESIGNAL_APP_ID : app.os_app;

        if (!oneSignalAppId) {
            return of(null);
        }

        OneSignal.initialize(oneSignalAppId);
        OneSignal.User.pushSubscription.optIn();
        OneSignal.login(loggedInUser.id.toString());

        // NOTE: In the future this will not need to return the subscription ID, as onesignal users will be identifiable via the external id.
        return this.oneSignalRequestPermission().pipe(switchMap(() => this.oneSignalPushSubscriptionId()));
    }

    public optOut(): void {
        if (!this.deviceService.isNative()) {
            return;
        }

        OneSignal.logout();
    }

    private oneSignalRequestPermission(): Observable<boolean> {
        return from(OneSignal.Notifications.canRequestPermission()).pipe(
            switchMap((canRequestPermission) =>
                canRequestPermission ? from(OneSignal.Notifications.requestPermission()) : of(true)
            )
        );
    }

    private oneSignalPushSubscriptionId(): Observable<string> {
        return interval(3000).pipe(
            startWith(null),
            map(() => OneSignal.User.pushSubscription?.id),
            filter(Boolean),
            take(1)
        );
    }
}
