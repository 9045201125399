import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { AuthenticationFacadeService } from '@core/authentication/services/authentication-facade.service';
import { BrandingService } from '@core/branding/services/branding.service';
import { Observable, combineLatest, map } from 'rxjs';

@Component({
    selector: 'cc-custom-css-switch',
    templateUrl: './custom-css-switch.component.html',
    styleUrls: ['./custom-css-switch.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomCssSwitchComponent implements OnInit {
    public customCssEnabled$: Observable<boolean>;
    public shouldShowCustomCssSwitch$: Observable<boolean>;

    constructor(
        private brandingService: BrandingService,
        private authenticationFacadeService: AuthenticationFacadeService,
        private appCoreFacadeService: AppCoreFacadeService
    ) {}

    public ngOnInit(): void {
        this.customCssEnabled$ = this.brandingService.customCssEnabled$;
        this.shouldShowCustomCssSwitch$ = combineLatest([
            this.authenticationFacadeService.getAuthenticatedPerson(),
            this.appCoreFacadeService.getAppSettings()
        ]).pipe(map(([loggedInUser, app]) => !!loggedInUser && loggedInUser.is_staff && !!app.theme.app_css));
    }

    public toggleCustomCss(): void {
        this.brandingService.toggleCustomCss();
    }
}
