import { Inject, Injectable } from '@angular/core';
import { GoogleAnalyticsCommand } from '@core/analytics/enums/google-analytics-command.enum';
import { TrackerTarget } from '@core/analytics/enums/tracker-target.enum';
import { AnalyticsPageView } from '@core/analytics/interfaces/analytics-page-view-data.interface';
import { AnalyticsSetting } from '@core/analytics/interfaces/analytics-setting.interface';
import { DeviceService } from '@core/device/services/device.service';
import { TagTracker } from '@core/tag-management/models/tag-token.interface';
import { WINDOW } from '@core/window.service';
import { GaDimension } from '../enums/ga-dimension.enum';

@Injectable({
    providedIn: 'root'
})
export class GaService {
    constructor(
        @Inject(WINDOW) private window: Window,
        private deviceService: DeviceService
    ) {}

    public handleEvent(settings: AnalyticsSetting, trackers: TagTracker[]): void {
        if (!this.window.ga) {
            return;
        }

        trackers.forEach((tracker) => {
            this.window.ga(
                this.prefixTargetProperty(tracker.name, GoogleAnalyticsCommand.Set),
                GaDimension.Environment,
                settings.environmentName
            );
            this.window.ga(
                this.prefixTargetProperty(tracker.name, GoogleAnalyticsCommand.Set),
                GaDimension.User,
                settings.username
            );
            this.window.ga(
                this.prefixTargetProperty(tracker.name, GoogleAnalyticsCommand.Set),
                GaDimension.App,
                settings.app
            );
            this.window.ga(
                this.prefixTargetProperty(tracker.name, GoogleAnalyticsCommand.Send),
                'event',
                settings.category,
                settings.event,
                settings.label
            );
        });
    }

    public handlePageView(view: AnalyticsPageView, trackers: TagTracker[]): void {
        if (!this.window.ga) {
            return;
        }

        trackers.forEach((tracker) => {
            this.window.ga(this.prefixTargetProperty(tracker.name, GoogleAnalyticsCommand.Set), 'page', view.page_path);
            this.window.ga(
                this.prefixTargetProperty(tracker.name, GoogleAnalyticsCommand.Send),
                'pageview',
                view.page_path
            );
        });
    }

    public checkNative(trackers: TagTracker[]): void {
        if (!this.window.ga) {
            return;
        }

        trackers.forEach((tracker) => {
            if (this.deviceService.isNative()) {
                this.configureNativeAnalytics(tracker);
            } else {
                this.configureWebAnalytics(tracker);
            }
            this.window.ga(
                this.prefixTargetProperty(tracker.name, GoogleAnalyticsCommand.Set),
                'checkProtocolTask',
                null
            );
            this.window.ga(this.prefixTargetProperty(tracker.name, GoogleAnalyticsCommand.Set), 'anonymizeIp', true);
        });
    }

    private configureNativeAnalytics(tracker: TagTracker): void {
        this.deviceService.getUUID().then((uuid: string) => {
            if (tracker.name === TrackerTarget.Default) {
                this.window.ga(GoogleAnalyticsCommand.Create, tracker.token, 'auto', {
                    storage: 'none',
                    clientId: uuid
                });
            } else {
                this.window.ga(GoogleAnalyticsCommand.Create, tracker.token, 'auto', {
                    storage: 'none',
                    clientId: uuid,
                    name: tracker.name
                });
            }
        });
    }

    private configureWebAnalytics(tracker: TagTracker): void {
        if (tracker.name === TrackerTarget.Default) {
            this.window.ga(
                GoogleAnalyticsCommand.Create,
                tracker.token,
                this.window.location.hostname === 'localhost' ? 'none' : 'auto'
            );
        } else {
            this.window.ga(
                GoogleAnalyticsCommand.Create,
                tracker.token,
                window.location.hostname === 'localhost' ? 'none' : 'auto',
                tracker.name
            );
        }
    }

    private prefixTargetProperty(trackerName: TrackerTarget, command: GoogleAnalyticsCommand): string {
        return trackerName === TrackerTarget.Client ? `${trackerName}.${command}` : command;
    }
}
