import { NgModule } from '@angular/core';
import { PersonPopoverComponent } from './components/person-popover/person-popover.component';
import { PersonPopoverDirective } from './directives/person-popover/person-popover.directive';
import { AppCommonModule } from '@common/common.module';

@NgModule({
    declarations: [PersonPopoverComponent, PersonPopoverDirective],
    exports: [PersonPopoverDirective],
    imports: [AppCommonModule]
})
export class PersonPopoverModule {}
