import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ExpandedPeople } from '@features/people/models/expanded-people.model';
import { PersonActionService } from '@features/people/services/person-action/person-action.service';
import { ActionPopoverType } from '@shared/action-popover/enums/action-popover-type.enum';
import { People } from '@shared/api';
import { PersonHeaderAction } from '@shared/people/enums/person-header-action.enum';
import { PersonHeaderActions } from '@shared/people/types/person-header-actions.type';
import { AppTag } from '@shared/tags/interfaces/app-tag.interface';
import { Observable } from 'rxjs';

@Component({
    selector: 'cc-person-header',
    templateUrl: './person-header.component.html',
    styleUrls: ['./person-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PersonHeaderComponent implements OnInit {
    @Input()
    public person: People | ExpandedPeople;

    /* To hide an action, it must explicitly be set to false. */
    @Input()
    public actions: PersonHeaderActions = {
        [PersonHeaderAction.Email]: true,
        [PersonHeaderAction.VideoCall]: true,
        [PersonHeaderAction.Phone]: true,
        [PersonHeaderAction.Website]: true,
        [PersonHeaderAction.Chat]: true,
        [PersonHeaderAction.BookMeeting]: true
    };

    public canChat$: Observable<boolean>;
    public canVideoCall$: Observable<boolean>;
    public canUseMeetingBooking$: Observable<boolean>;
    public peopleTags: AppTag[];

    public PersonHeaderAction = PersonHeaderAction;
    public ActionPopoverType = ActionPopoverType;

    constructor(private personActionService: PersonActionService) {}

    public ngOnInit(): void {
        this.canChat$ = this.personActionService.canChat(this.person);
        this.canUseMeetingBooking$ = this.personActionService.canUseMeetingBooking(this.person);
        this.canVideoCall$ = this.personActionService.canVideoCall(this.person);
        this.peopleTags = [...(this.person?.tags ?? []), ...(this.person?.user_tags ?? [])];
    }

    public onZoomPicture(): void {
        this.personActionService.zoomPicture(this.person);
    }

    public onChat(): void {
        this.personActionService.chat(this.person);
    }

    public onVideoCall(): void {
        this.personActionService.videoCall(this.person);
    }

    public onScheduleMeeting(): void {
        this.personActionService.scheduleMeeting(this.person);
    }
}
