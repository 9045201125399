export interface QandASession {
    /**
     * Number of questions allowed per user
     */
    questions_limit?: number;
    display_order?: QandASession.DisplayOrderEnum;
    /**
     * Display user names next to questions
     */
    display_names?: boolean;
    /**
     * Display questions on mobile devices
     */
    display_on_mobile?: boolean;
    /**
     * Display company names next to questions if applicable
     */
    display_company_name?: boolean;
    id?: number;
    is_moderated?: boolean;
    /**
     * Should a user be logged in to ask a question
     */
    login_required?: boolean;
    module?: number;
    name: string;
    display_date?: boolean;
    voting_enabled?: boolean;
    display_vote_count?: boolean;
    answers_enabled?: boolean;
    allowed_to_answer?: Array<number>;
}

export namespace QandASession {
    export enum DisplayOrderEnum {
        Date = 'date' as any,
        DateOldest = 'date-oldest' as any,
        Votes = 'votes' as any
    }
}
