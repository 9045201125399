import { AppCoreSelectors } from '@core/root-store/store/app-core/selectors/app-core.selectors';
import { AppRoutingSelectors } from '@core/root-store/store/app-routing/selectors/app-routing.selectors';
import { createSelector } from '@ngrx/store';
import { NavigationAppState, NavigationState, navigationStoreKey } from '../models/navigation.state.model';
import { AppRouteParams } from '@core/routing/constants/app-route-params.constant';

const selectNavigationState = (state: NavigationAppState) => state[navigationStoreKey];

const selectModules = createSelector(selectNavigationState, (state: NavigationState) => {
    if (!state.modules) {
        return [];
    }
    return state.modules.filter((module) => module.is_visible).sort((a, b) => a.order - b.order);
});

const selectInitialising = createSelector(selectNavigationState, (state) => state.initialising);

const selectRightSidebarItems = createSelector(selectNavigationState, (state) => state.rightSidebarItems);

const selectTabbarModules = createSelector(selectModules, AppCoreSelectors.selectAppSettings, (modules, app) => {
    if (!app || !app.menu_settings) {
        return [];
    }

    const tabbarItemIds = app.menu_settings.tab_menu_items;
    const matches = tabbarItemIds.map((tabBarItemId) => modules.find((x) => x.id === tabBarItemId));
    return matches.filter((x) => !!x);
});

const selectLeftSidebarCollapsed = createSelector(selectNavigationState, (state) => state.leftSidebarCollapsed);

const selectModuleTitle = createSelector(
    selectNavigationState,
    AppRoutingSelectors.selectRouteParams,
    (state, routeParams) => {
        if (!state.modules) {
            return;
        }
        const moduleId = +routeParams[AppRouteParams.moduleId];
        if (!moduleId) {
            return;
        }
        return state.modules.find((module) => module.id === moduleId)?.name;
    }
);

export const NavigationSelectors = {
    modules: selectModules,
    initialising: selectInitialising,
    rightSidebarItems: selectRightSidebarItems,
    tabbarModules: selectTabbarModules,
    leftSidebarCollapsed: selectLeftSidebarCollapsed,
    moduleTitle: selectModuleTitle
};
