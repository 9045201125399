import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {
    AgendaModuleEnabledFeatures,
    App,
    AppDocument,
    MableCustomField,
    MableCustomFieldGroup,
    People,
    SocialInfo
} from '@shared/api';
import { AppCoreFacadeService } from '../../../../core/app-core/services/app-core-facade.service';
import { PeopleFacadeService } from '../../services/people-facade.service';
import { PeopleDetailActions } from '../../store/actions/people-detail.actions';
import { AuthenticationFacadeService } from '../../../../core/authentication/services/authentication-facade.service';
import { I18nService } from '../../../../core/i18n/services/i18n.service';
import { map, switchMap } from 'rxjs/operators';
import { ExpandedPeople } from '../../models/expanded-people.model';
import { PeopleSessions } from '../../models/people-session.model';
import { ActivatedRoute } from '@angular/router';
import { PeopleRouteParams } from '../../constants/people-route-params.constant';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Breakpoints, BreakpointService } from '@shared/utilities/breakpoint.service';
import { PersonHeaderActions } from '@shared/people/types/person-header-actions.type';
import { PersonHeaderAction } from '@shared/people/enums/person-header-action.enum';
import { BusinessCard } from '@shared/business-cards/interfaces/business-card.interface';
import { PersonActionService } from '@features/people/services/person-action/person-action.service';

@UntilDestroy()
@Component({
    selector: 'cc-person',
    templateUrl: './person.component.html',
    styleUrls: ['./person.component.scss']
})
export class PersonComponent implements OnInit, OnDestroy {
    public initialising$: Observable<boolean>;
    public person$: Observable<ExpandedPeople>;
    public businessCard$: Observable<BusinessCard>;
    public canExchangeBusinessCards$: Observable<boolean>;
    public documents$: Observable<AppDocument[]>;
    public customFields$: Observable<(MableCustomField | MableCustomFieldGroup)[]>;
    public app$: Observable<App>;
    public loggedInUser$: Observable<People>;
    public socialInfo$: Observable<SocialInfo[]>;
    public sidebarLeftExpanded$: Observable<boolean>;
    public headerActions$: Observable<PersonHeaderActions>;
    public sessions$: Observable<
        { module: { name: string; id: number }; sessions: Array<{ slim: PeopleSessions.Session }> }[]
    >;

    public enabledFeatures: AgendaModuleEnabledFeatures = {
        expand_show_documents: false,
        expand_show_features: false,
        expand_show_location: false,
        expand_show_speakers: false,
        expand_show_subtitle: false
    };

    constructor(
        private appCoreFacadeService: AppCoreFacadeService,
        private peopleFacadeService: PeopleFacadeService,
        private authenticationFacadeService: AuthenticationFacadeService,
        private i18nService: I18nService,
        private route: ActivatedRoute,
        private breakpointService: BreakpointService,
        private personActionService: PersonActionService
    ) {}

    public ngOnInit(): void {
        const personId$ = this.route.paramMap.pipe(
            map((params) => parseInt(params.get(PeopleRouteParams.personId), 10))
        );

        personId$.pipe(untilDestroyed(this)).subscribe((personId) => {
            this.peopleFacadeService.dispatch(PeopleDetailActions.enterPage({ personId }));
        });
        this.initialising$ = this.peopleFacadeService.getDetailInitialising();
        this.person$ = this.peopleFacadeService.getPerson();
        this.documents$ = this.i18nService.language$.pipe(
            switchMap((language) => this.peopleFacadeService.getDocuments(language))
        );
        this.customFields$ = this.peopleFacadeService.getCustomFields();
        this.sessions$ = this.peopleFacadeService.getSessions();
        this.socialInfo$ = this.peopleFacadeService.getSocialInfo();
        this.app$ = this.appCoreFacadeService.getAppSettings();
        this.loggedInUser$ = this.authenticationFacadeService.getAuthenticatedPerson();
        this.sidebarLeftExpanded$ = this.breakpointService.is(({ width }) => width >= Breakpoints.Large);
        this.headerActions$ = this.sidebarLeftExpanded$.pipe(map((expanded) => this.getHeaderActions(expanded)));

        this.businessCard$ = this.peopleFacadeService.getBusinessCard();
        this.canExchangeBusinessCards$ = this.person$.pipe(
            switchMap((person) => this.personActionService.canUseBusinessCards(person))
        );
    }

    public onTapDocument(document: AppDocument): void {
        this.peopleFacadeService.dispatch(
            PeopleDetailActions.documentItemClick({
                document
            })
        );
    }

    public onTapSession(session: PeopleSessions.Session): void {
        this.peopleFacadeService.dispatch(
            PeopleDetailActions.agendaItemClick({
                sessionId: session.id,
                moduleId: session.module_id
            })
        );
    }

    public onExchangeBusinessCard(): void {
        this.peopleFacadeService.dispatch(PeopleDetailActions.openExchangeBusinessCardModal());
    }

    public ngOnDestroy(): void {
        this.peopleFacadeService.dispatch(PeopleDetailActions.leavePage());
    }

    /* Hide chat and video call buttons on small screens, as then the person footer is visible with those actions */
    private getHeaderActions(sidebarLeftExpanded: boolean): PersonHeaderActions {
        if (!sidebarLeftExpanded) {
            return { [PersonHeaderAction.Chat]: false, [PersonHeaderAction.VideoCall]: false };
        }
        return {};
    }
}
