import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ChoicePoll, ChoicePollChoice, Poll } from '@shared/api';
import { ChoicePollChoiceFormItem } from '../../models/choice-poll-choice-ui-item.model';

@Component({
    selector: 'cc-multiple-choice-poll',
    templateUrl: './multiple-choice-poll.component.html',
    styleUrls: ['./multiple-choice-poll.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultipleChoicePollComponent implements OnChanges {
    @Input()
    poll: Poll;

    @Input()
    choicePoll: ChoicePoll;

    @Input()
    choicePollChoices: ChoicePollChoice[];

    @Input()
    choicePollChoiceFormItems: ChoicePollChoiceFormItem[];

    @Input()
    submitting: boolean;

    @Input()
    closed: boolean;

    @Input()
    showPreviousButton: boolean;

    @Input()
    showNextButton: boolean;

    @Input()
    submitButtonText: string = 'SUBMIT';

    @Output()
    pollSubmit = new EventEmitter<number[]>();

    @Output()
    pollNext = new EventEmitter<void>();

    @Output()
    pollPrevious = new EventEmitter<void>();

    public canSubmit: boolean;

    public ngOnChanges(): void {
        const hasAnswered = this.choicePollChoiceFormItems.some((choice) => choice.selected);
        const hasProvidedSingleResponse = this.poll.single_response_only && hasAnswered;
        this.canSubmit = !hasProvidedSingleResponse || !hasAnswered;
    }

    public getDescription(): string {
        const minChoices = this.choicePoll.min_choices;
        const maxChoices = this.choicePoll.max_choices;

        if (minChoices === 1 && maxChoices === 1) {
            return 'SURVEYS_BLURB_SELECT_ONE';
        }

        if (maxChoices === minChoices) {
            return 'SURVEYS_BLURB_SELECT_SPECIFIC';
        }

        if (minChoices === 1) {
            return 'SURVEYS_BLURB_SELECT_FROM_1';
        }

        if (minChoices > 1 && !maxChoices) {
            return 'SURVEYS_BLURB_SELECT_UP_TO';
        }

        return 'SURVEYS_BLURB_SELECT_BETWEEN';
    }

    public onChoiceClick(choice: ChoicePollChoiceFormItem): void {
        const minChoices = this.choicePoll.min_choices;
        const maxChoices = this.choicePoll.max_choices;

        if (minChoices === 1 && maxChoices === 1) {
            this.choicePollChoiceFormItems.forEach((c) => (c.selected = false));
            choice.selected = true;
        } else {
            choice.selected = !choice.selected;
            const selectedItemCount = this.choicePollChoiceFormItems.filter((c) => c.selected).length;
            if (selectedItemCount >= maxChoices) {
                this.choicePollChoiceFormItems.filter((c) => !c.selected).forEach((c) => (c.disabled = true));
            } else {
                this.choicePollChoiceFormItems.forEach((c) => (c.disabled = false));
            }
        }
    }

    public formIsValid(): boolean {
        const selectedItemCount = this.choicePollChoiceFormItems.filter((c) => c.selected).length;
        return this.choicePoll.min_choices <= selectedItemCount && selectedItemCount <= this.choicePoll.max_choices;
    }

    public onPollSubmit(): void {
        const selectedItemIds = this.choicePollChoiceFormItems.filter((c) => c.selected).map((c) => c.id);
        this.pollSubmit.emit(selectedItemIds);
    }

    public onPollNext(): void {
        this.pollNext.emit();
    }

    public onPollPrevious(): void {
        this.pollPrevious.emit();
    }
}
