import { NgModule } from '@angular/core';
import { SocialMediaComponent } from '@shared/social-media/components/social-media/social-media.component';
import { AppCommonModule } from '@common/common.module';

@NgModule({
    declarations: [SocialMediaComponent],
    exports: [SocialMediaComponent],
    imports: [AppCommonModule]
})
export class SharedSocialMediaModule {}
