export enum MeetingAvailabilityState {
    Selected = 'selected',
    Busy = 'busy',
    NotSelected = 'not-selected'
}

export enum MeetingAvailabilityGroupState {
    Available = 'available',
    Unavailable = 'unavailable'
}
