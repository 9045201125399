import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[ccScrollTo]'
})
export class ScrollToDirective implements AfterViewInit {
    @Input('ccScrollTo')
    public shouldScroll = true;

    constructor(private elementRef: ElementRef) {}

    public ngAfterViewInit(): void {
        if (this.shouldScroll) {
            setTimeout(() => {
                this.elementRef.nativeElement.scrollIntoView({ behavior: 'smooth' });
            }, 100);
        }
    }
}
