import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { peopleStoreKey } from './models/people.state.model';
import { peopleReducer } from './reducers/people.reducer';
import { PeopleEffects } from './effects/people.effects';
import { PeopleDetailEffects } from './effects/people-detail.effects';
import { PeopleListEffects } from './effects/people-list.effects';
import { PeopleFilterEffects } from './effects/people-filters.effects';

@NgModule({
    imports: [
        StoreModule.forFeature(peopleStoreKey, peopleReducer),
        EffectsModule.forFeature([PeopleEffects, PeopleDetailEffects, PeopleListEffects, PeopleFilterEffects])
    ]
})
export class PeopleStoreModule {}
