import { Injectable } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { EmojiPickerPopoverComponent } from '@shared/emoji/components/chat-tab-emoji-popover/emoji-picker-popover.component';

@Injectable({
    providedIn: 'root'
})
export class EmojiPickerPopoverService {
    constructor(private popoverController: PopoverController) {}

    async openEmojiPicker($event: MouseEvent): Promise<string> {
        const popover = await this.popoverController.create({
            component: EmojiPickerPopoverComponent,
            alignment: 'center',
            side: 'top',
            event: $event,
            cssClass: 'ion-popover-fluid-width'
        });
        await popover.present();
        return popover.onDidDismiss().then((r) => r.data as string);
    }
}
