import { createFeatureSelector, createSelector } from '@ngrx/store';
import { VideoCallState, videoCallStoreKey } from '../models/video-call.state';

const selectVideoCallState = createFeatureSelector<VideoCallState>(videoCallStoreKey);

const selectVideoCall = createSelector(
    selectVideoCallState,
    (videoCallState: VideoCallState) => videoCallState.videoCall
);

export const VideoSelectors = {
    selectVideoCall
};
