<div class="agenda-card-feature-icons__container">
    <i
        class="material-icons agenda-card-feature-icons__icon"
        *ngIf="session.video || session.livestream"
        placement="bottom"
        [showDelay]="500"
        [attr.aria-label]="'AGENDA_CARDS_FEATURES_MEDIA' | translate"
        [tooltip]="'AGENDA_CARDS_FEATURES_MEDIA' | translate"
    >
        ondemand_video
    </i>

    <i
        class="material-icons agenda-card-feature-icons__icon"
        *ngIf="session.chat_enabled"
        placement="bottom"
        [showDelay]="500"
        [attr.aria-label]="'AGENDA_CARDS_FEATURES_CHAT' | translate"
        [tooltip]="'AGENDA_CARDS_FEATURES_CHAT' | translate"
    >
        chat
    </i>

    <i
        class="material-icons agenda-card-feature-icons__icon"
        *ngIf="session.livepoll"
        placement="bottom"
        [showDelay]="500"
        [attr.aria-label]="'AGENDA_CARDS_FEATURES_POLLING' | translate"
        [tooltip]="'AGENDA_CARDS_FEATURES_POLLING' | translate"
    >
        polls
    </i>

    <i
        class="material-icons agenda-card-feature-icons__icon"
        *ngIf="session.qanda"
        placement="bottom"
        [showDelay]="500"
        [attr.aria-label]="'AGENDA_CARDS_FEATURES_QANDA' | translate"
        [tooltip]="'AGENDA_CARDS_FEATURES_QANDA' | translate"
    >
        question_answer
    </i>

    <i
        class="material-symbols-rounded agenda-card-feature-icons__icon"
        *ngIf="session.activity_feed"
        placement="bottom"
        [showDelay]="500"
        [attr.aria-label]="'AGENDA_CARDS_FEATURES_ACTIVITY_FEED' | translate"
        [tooltip]="'AGENDA_CARDS_FEATURES_ACTIVITY_FEED' | translate"
    >
        share_reviews
    </i>
</div>
