import { Inject, Injectable } from '@angular/core';
import { AnalyticsPageView } from '@core/analytics/interfaces/analytics-page-view-data.interface';
import { AnalyticsDataStreamService } from '@core/analytics/services/analytics-data-stream.service';
import { LoadScriptService } from '@core/browser-utilities/services/load-script.service';
import { TagStatus } from '@core/tag-management/constants/tag-status.constant';
import { TagBuilder } from '@core/tag-management/models/tag-builder.interface';
import { WINDOW } from '@core/window.service';
import { CookieService } from 'ngx-cookie-service';
import { HotjarConfig } from '../models/hotjar-config.interface';

@Injectable({
    providedIn: 'root'
})
export class HotjarBuilderService implements TagBuilder {
    public config: HotjarConfig;
    private cookies = [
        '_hjClosedSurveyInvites',
        '_hjDonePolls',
        '_hjMinimizedPolls',
        '_hjShownFeedbackMessage',
        '_hjSessionTooLarge',
        '_hjSessionRejected',
        '_hjSessionResumed',
        '_hjid',
        '_hjRecordingLastActivity',
        '_hjTLDTest',
        '_hjUserAttributesHash',
        '_hjCachedUserAttributes',
        '_hjLocalStorageTest',
        '_hjIncludedInPageviewSample',
        '_hjIncludedInSessionSample',
        '_hjAbsoluteSessionInProgress',
        '_hjFirstSeen',
        '_hjViewportId',
        '_hjRecordingEnabled'
    ];

    constructor(
        private scriptLoader: LoadScriptService,
        private analyticsService: AnalyticsDataStreamService,
        private cookieService: CookieService,
        @Inject(WINDOW) private window: Window
    ) {}

    public configure(config: HotjarConfig): TagBuilder {
        this.config = config;
        return this;
    }

    public load(): string {
        if (this.window.hj || this.scriptLoader.getScript('hotjar')) {
            return TagStatus.skipped('HOTJAR', 'Script already loaded');
        }

        this.setupTag();

        const loaded = this.scriptLoader.loadScript({
            id: 'hotjar',
            src: `https://static.hotjar.com/c/hotjar-${this.config.tracker.token}.js?sv=${this.config.version}`,
            onload: () => {
                return this.subscribeToActions();
            },
            onerror: (error) => this.handleError(error)
        });

        if (loaded) {
            return TagStatus.loaded('HOTJAR');
        }
    }

    public remove(): void {
        // Delete cookies
        this.cookies.forEach((key) => this.cookieService.delete(key, '/', this.window.location.hostname));
    }

    private handleError(error: Event | string): void {
        console.warn(error);
    }

    private setupTag(): void {
        Object.defineProperty(this.window, 'hj', {
            value:
                this.window.hj ||
                function () {
                    (this.window.hj.q = this.window.hj.q || []).push(arguments);
                },
            configurable: true,
            writable: true
        });

        Object.defineProperty(this.window, '_hjSettings', {
            value: { hjid: this.config.tracker.token, hjsv: this.config.version },
            configurable: true,
            writable: true
        });
    }

    private subscribeToActions(): string {
        this.analyticsService.pageView$.subscribe((view: AnalyticsPageView) => this.window.hj('vpv', view.page_path));
        return TagStatus.loaded('HOTJAR');
    }
}
