<cc-modal header="BUSINESS_CARDS_EXCHANGE_MODAL_TITLE" (closeClicked)="dismiss()">
    <ion-content
        class="company-detail-business-card-modal__content"
        *ngIf="{
            design: design$ | async,
            profile: profile$ | async,
            social: social$ | async
        } as pageData"
    >
        <div class="company-detail-business-card-modal__card">
            <ng-container *ngIf="pageData.design && pageData.profile && pageData.social; else skeleton">
                <cc-business-card
                    [design]="pageData.design"
                    [person]="pageData.profile"
                    [socialInfo]="pageData.social"
                ></cc-business-card>
            </ng-container>

            <ng-template #skeleton>
                <cc-business-card-skeleton></cc-business-card-skeleton>
            </ng-template>
        </div>

        <ion-item class="company-detail-business-card-modal__message">
            <ion-textarea
                class="company-detail-business-card-modal__message-input"
                rows="6"
                labelPlacement="stacked"
                [label]="'BUSINESS_CARDS_EXCHANGE_MODAL_MESSAGE_LABEL' | translate"
                [placeholder]="'BUSINESS_CARDS_EXCHANGE_MODAL_PLACEHOLDER' | translate"
                [formControl]="messageControl"
            ></ion-textarea>
        </ion-item>
    </ion-content>

    <ion-footer>
        <ion-button class="company-detail-business-card-modal__button" expand="block" color="accent" (click)="send()">
            {{ 'BUSINESS_CARDS_EXCHANGE_MODAL_SEND_BUTTON' | translate }}
        </ion-button>
    </ion-footer>
</cc-modal>
