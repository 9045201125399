import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { AuthenticationFacadeService } from '@core/authentication/services/authentication-facade.service';
import { CustomHtmlElementType } from '@features/html/constants/custom-html-element-type.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, Observable, of, shareReplay } from 'rxjs';
import { CustomHtmlElementOptions } from './interfaces/element-options.interface';

@Component({
    selector: 'cc-custom-html-element',
    templateUrl: './custom-html-element.component.html',
    styleUrls: ['./custom-html-element.component.scss']
})
@UntilDestroy()
export class CustomHtmlElementComponent<TComponentType extends CustomHtmlElementType> implements OnInit {
    @Input()
    public type: TComponentType;

    @Input()
    public options: string;

    @HostBinding('style.display')
    public componentVisibility: 'none' | undefined;

    public typeEnum = CustomHtmlElementType;

    public elementOptions: CustomHtmlElementOptions[TComponentType] & { allowedGroups?: string };

    public hasVisibility$: Observable<boolean>;

    constructor(private authenticationFacadeService: AuthenticationFacadeService) {}

    public ngOnInit(): void {
        this.elementOptions = this.options && JSON.parse(this.options);

        // If there's any privacy we want to hide this element as soon as possible.
        if (this.elementOptions?.allowedGroups) {
            this.componentVisibility = 'none';
        }

        this.hasVisibility$ = this.getWidgetVisibility();

        this.hasVisibility$.pipe(untilDestroyed(this)).subscribe((hasVisibility) => {
            this.componentVisibility = hasVisibility ? undefined : 'none';
        });
    }

    private getWidgetVisibility(): Observable<boolean> {
        if (!this.elementOptions?.allowedGroups) {
            return of(true);
        }

        const groups = this.elementOptions.allowedGroups.split(',');

        return this.authenticationFacadeService.getAuthenticatedPerson().pipe(
            map((authenticatedPerson) => {
                if (!authenticatedPerson) {
                    return false;
                }

                return authenticatedPerson.peoplegroups.some((group) => groups.includes(group));
            }),
            shareReplay(1)
        );
    }
}
