import { Injectable } from '@angular/core';
import { AblyEventType } from '@core/ably/enums/ably-event-type.enum';
import { AblyMessagingService } from '@core/ably/services/ably-messaging.service';
import { AttendeeRealtimeEventData } from '@shared/agenda/interfaces/attendee-realtime-event-data.interface';
import { SlimPeople } from '@shared/api';
import { Observable, filter, map } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AttendeeRealtimeService {
    constructor(private ablyMessagingService: AblyMessagingService) {}

    public getAttendeeUpdateStream(options: {
        sessionId: number;
    }): Observable<{ attendee: SlimPeople; event: AblyEventType }> {
        return this.ablyMessagingService.getMessageStream(['scheduled-item']).pipe(
            map(({ data, name }) => ({
                data: JSON.parse(data) as AttendeeRealtimeEventData,
                event: name.split('.')[1] as AblyEventType
            })),
            filter(({ data }) => data.session_id === options.sessionId),
            map(({ data, event }) => ({ attendee: data.person, event }))
        );
    }
}
