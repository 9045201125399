import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NotificationsState, notificationsStateKey } from '../models/notifications-state.model';
import { notificationsEntityAdapater } from '../reducers/notifications.reducer';

const selectNotificationsState = createFeatureSelector<NotificationsState>(notificationsStateKey);

const entityAdapterSelectors = notificationsEntityAdapater.getSelectors();

const selectNotifications = createSelector(selectNotificationsState, entityAdapterSelectors.selectAll);
const selectNotificationsDict = createSelector(selectNotificationsState, entityAdapterSelectors.selectEntities);

export const selectNotification = (id: string) =>
    createSelector(selectNotificationsDict, (notifications) => {
        return notifications[id];
    });

export const NotificationsSelectors = {
    notifications: selectNotifications,
    notification: selectNotification
};
