import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DirectLinkEndpoints } from '@features/direct-link/constants/direct-link-endpoints.constant';
import { DirectLinkModule } from '@shared/api';
import { ApiSettings } from '@shared/settings/api-settings.constant';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DirectLinkApiService {
    constructor(private httpClient: HttpClient) {}

    public getDirectLinkModule(appUrl: string, moduleId: number): Observable<DirectLinkModule> {
        const url = ApiSettings.fullUrl(appUrl, DirectLinkEndpoints.DirectLinkModules());

        return this.httpClient
            .get<DirectLinkModule[]>(url)
            .pipe(map((modules) => modules.find((module) => module.id.toString() === moduleId.toString())));
    }
}
