<div class="location-chips__container" [ngClass]="{ clickable: clickable }">
    <i class="material-icons location-chips__icon" aria-hidden="true">place</i>
    <div class="location-chips__list">
        <ng-container
            *ngFor="let location of locations"
            [ngSwitch]="location['model_type'] || location['resourcetype']"
        >
            <div *ngSwitchCase="locationType.staticText" class="location-chips__item static-text" tabindex="0">
                {{ location.location_name }}
            </div>

            <div
                *ngSwitchCase="locationType.locationMap"
                class="location-chips__item"
                role="button"
                tabindex="0"
                [attr.aria-label]="'LOCATION_CHIP_ARIA_LABEL' | translate"
                (click)="onLocationClick(location)"
            >
                {{ location.location_name }}
            </div>

            <div
                *ngSwitchCase="locationType.pindrop"
                class="location-chips__item"
                role="button"
                tabindex="0"
                [attr.aria-label]="'LOCATION_CHIP_ARIA_LABEL' | translate"
                (click)="onLocationClick(location)"
            >
                {{ location.location_name }}
            </div>

            <!-- If the location doesn't have a model type -->
            <div *ngSwitchDefault class="location-chips__item" tabindex="0">{{ location.location_name }}</div>
        </ng-container>
    </div>
</div>
