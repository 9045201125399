import { NgModule } from '@angular/core';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { AppCommonModule } from '@common/common.module';
import { ExtendableSearchBarComponent } from './components/extendable-search-bar/extendable-search-bar.component';

@NgModule({
    declarations: [SearchBarComponent, SearchInputComponent, ExtendableSearchBarComponent],
    exports: [SearchBarComponent, SearchInputComponent, ExtendableSearchBarComponent],
    imports: [AppCommonModule]
})
export class SearchModule {}
