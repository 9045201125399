import { MfaQr } from '@features/mfa/models/mfa-qr';

export interface LoginState {
    loginPageState: LoginPageState;
    passwordResetPageState: PasswordResetPageState;
}

export interface LoginPageState {
    initialising: boolean;
    submitting: boolean;
    passwordVisible: boolean;
    loginStep: LoginStep;
    mfaQrCode: MfaQr;
    hasHitServerError: boolean;
}

export interface PasswordResetPageState {
    initialising: boolean;
    submitting: boolean;
}

export enum LoginStep {
    Register = 'register',
    VerifyEmail = 'verifyEmail',
    EnterPasscode = 'enterPasscode',
    CheckEmail = 'checkEmail',
    Login = 'login',
    MfaPromptOptional = 'mfaPromptOptional',
    MfaPromptEmail = 'mfaPromptEmail',
    MfaSetup = 'mfaSetup',
    MfaValidate = 'mfaValidate',
    MfaCalibrate = 'mfaCalibrate',
    MfaEmailValidate = 'mfaEmailValidate',
    AccessDenied = 'accessDenied',
    Loading = 'loading'
}
