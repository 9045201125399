import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiSettings } from '../../../shared/settings/api-settings.constant';
import { Observable } from 'rxjs';
import { BasicAuth, People, UserAuthInfo, UserEmailInfo } from '@shared/api';
import { AuthEndpointUrls } from '../constants/auth-endpoint-urls';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationApiService {
    constructor(private httpClient: HttpClient) {}

    login(appName: string, email: string, password: string): Observable<UserAuthInfo> {
        const url = ApiSettings.fullUrlV2(appName, AuthEndpointUrls.login);
        return this.httpClient.post<UserAuthInfo>(url, { email, password }).pipe(
            map((userAuthInfo) => {
                userAuthInfo.auth.exp = this.parseIATOffset(userAuthInfo.auth.iat, userAuthInfo.auth.exp);
                return userAuthInfo;
            })
        );
    }

    logout(refreshToken: string): Observable<unknown> {
        const url = ApiSettings.baseUrl(AuthEndpointUrls.logout);
        return this.httpClient.post<UserAuthInfo>(url, { refresh_token: refreshToken });
    }

    refreshAuthToken(appName: string, refreshToken: string): Observable<BasicAuth> {
        const url = ApiSettings.fullUrl(appName, AuthEndpointUrls.refreshToken);
        return this.httpClient.post<BasicAuth>(url, { refresh_token: refreshToken }).pipe(
            map((basicAuth) => {
                basicAuth.exp = this.parseIATOffset(basicAuth.iat, basicAuth.exp);
                return basicAuth;
            })
        );
    }

    getDownloadToken(): Observable<{ token: string; expires: string }> {
        return this.httpClient.get<{ token: string; expires: string }>(ApiSettings.downloadTokenFullUrl());
    }

    submitPasscode(appName: string, passcode: string): Observable<any> {
        const url = ApiSettings.fullUrl(appName, AuthEndpointUrls.verifyPasscode);
        return this.httpClient.post(url, { passcode });
    }

    submitForgotPassword(appName: string, emailAddress: string): Observable<any> {
        const url = AuthEndpointUrls.forgotPasswordFullUrl();
        return this.httpClient.post(url, { email: emailAddress, app: appName });
    }

    checkEmailAddress(appName: string, emailAddress: string): Observable<UserEmailInfo> {
        const url = ApiSettings.fullUrl(appName, AuthEndpointUrls.checkEmail);
        return this.httpClient.post<UserEmailInfo>(url, { email: emailAddress });
    }

    requestSendVerificationEmail(appName: string, emailAddress: string): Observable<any> {
        const url = ApiSettings.fullUrl(appName, AuthEndpointUrls.sendVerificationEmail);
        return this.httpClient.post(url, { email: emailAddress });
    }

    register(
        appName: string,
        email: string,
        password: string,
        firstName: string,
        lastName: string
    ): Observable<UserAuthInfo> {
        const url = ApiSettings.fullUrlV2(appName, AuthEndpointUrls.register);
        return this.httpClient.post<UserAuthInfo>(url, {
            email: email.toLowerCase(),
            password,
            first_name: firstName,
            last_name: lastName
        });
    }

    updatePassword(uid: string, newPassword: string, token: string): Observable<any> {
        const url = AuthEndpointUrls.updatePasswordFullUrl();
        return this.httpClient.post(url, {
            uid,
            new_password: newPassword,
            token
        });
    }

    verifyEmailAddress(token: string): Observable<any> {
        const url = AuthEndpointUrls.verifyEmailFullUrl(token);
        return this.httpClient.get(url);
    }

    getIdentityProviders(appName: string): Observable<any> {
        const url = AuthEndpointUrls.getAppIdentityProviders(appName);
        return this.httpClient.get(url);
    }

    getAuthenticatedPerson(appName: string): Observable<AuthenticatedPersonWithHeaders> {
        const url = ApiSettings.fullUrl(appName, ApiSettings.editProfile);
        return this.httpClient.get(url, { observe: 'response' }).pipe(
            map(({ headers, body }) => {
                return {
                    headers: {
                        Authorization: headers.get('Authorization'),
                        RefreshToken: headers.get('RefreshToken'),
                        'X-CC-MFA-Valid': headers.get('X-CC-MFA-Valid')
                    },
                    body
                } as AuthenticatedPersonWithHeaders;
            })
        );
    }

    private parseIATOffset(iat: number, exp: number): number {
        const iatOffset = iat - new Date().getTime() / 1000;
        const newExp = exp - iatOffset;

        return newExp;
    }
}

export interface AuthenticatedPersonWithHeaders {
    headers: {
        Authorization: string | null;
        RefreshToken: string | null;
        'X-CC-MFA-Valid': string | null;
    };
    body: People;
}
