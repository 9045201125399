import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AppDocument } from '@shared/api';

@Component({
    selector: 'cc-person-documents',
    templateUrl: './person-documents.component.html',
    styleUrls: ['./person-documents.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PersonDocumentsComponent {
    @Input()
    documents: AppDocument[];

    @Output()
    tapDocument = new EventEmitter<AppDocument>();

    onTapDocument(document: AppDocument): void {
        this.tapDocument.emit(document);
    }
}
