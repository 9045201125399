import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserAuthInfo } from '@shared/api';
import { combineLatest, Observable, throwError } from 'rxjs';
import { catchError, finalize, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AppCoreFacadeService } from '../../app-core/services/app-core-facade.service';
import { AuthInterceptorActions } from '../../root-store/store/authentication/actions/auth-interceptor.actions';
import { StorageService } from '../../storage/services/storage.service';
import { AuthEndpointUrls } from '../constants/auth-endpoint-urls';
import { AuthStorageKeys } from '../constants/auth-storage-keys';
import { AuthenticationApiService } from '../services/authentication-api.service';
import { AuthenticationFacadeService } from '../services/authentication-facade.service';

/**
 * Interceptor which handles attaching an auth header to http request for authenticated users,
 * and refreshing the auth token as necessary.
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    refreshing$: Observable<UserAuthInfo>;

    constructor(
        private authenticationFacadeService: AuthenticationFacadeService,
        private authenticationApiService: AuthenticationApiService,
        private appCoreFacadeService: AppCoreFacadeService,
        private storageService: StorageService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return combineLatest([
            this.authenticationFacadeService.getUserAuthInfo(),
            this.appCoreFacadeService.getAppName()
        ]).pipe(
            take(1),
            switchMap(([userAuthInfo, appName]) => {
                // If the user is not authenticated or the request is not going to the CrowdComms API,
                // then don't intercept the request or response.
                if (!userAuthInfo || !req.url.includes(environment.API_URL)) {
                    return next.handle(req);
                }

                const requestWithAuth = this.requestWithAuth(req, userAuthInfo);

                return next.handle(requestWithAuth).pipe(
                    catchError((error: HttpErrorResponse) => {
                        // If the error is not a 401 then rethrow it.
                        if (error.status !== 401) {
                            return throwError(error);
                        }

                        // If the refresh token endpoint returns a 401 then throw an error and fire the appropriate action.
                        if (req.url.includes(AuthEndpointUrls.refreshToken)) {
                            this.authenticationFacadeService.dispatch(
                                AuthInterceptorActions.refreshTokenFailure({ error })
                            );
                            return throwError(error);
                        }

                        // If the token is not already being refreshed, refresh it.
                        if (!this.refreshing$) {
                            const refreshToken = userAuthInfo.auth.refresh_token ?? this.getRefreshToken(appName);

                            if (refreshToken === undefined || refreshToken === '' || refreshToken === null) {
                                this.authenticationFacadeService.dispatch(
                                    AuthInterceptorActions.refreshTokenFailure({ error })
                                );
                                return throwError(error);
                            }

                            this.refreshing$ = this.authenticationApiService
                                .refreshAuthToken(appName, refreshToken)
                                .pipe(
                                    tap((basicAuth) => {
                                        this.authenticationFacadeService.dispatch(
                                            AuthInterceptorActions.refreshTokenSuccess({ basicAuth })
                                        );
                                    }),
                                    map((basicAuth) => ({
                                        ...userAuthInfo,
                                        auth: {
                                            ...userAuthInfo.auth,
                                            ...basicAuth
                                        }
                                    })),
                                    finalize(() => (this.refreshing$ = undefined)),
                                    shareReplay(1)
                                );
                        }

                        // Wait for the token to be refreshed and then try the failed request again.
                        return this.refreshing$.pipe(
                            switchMap((refreshedUserAuthInfo) => {
                                const requestWithRefreshedAuth = this.requestWithAuth(req, refreshedUserAuthInfo);
                                return next.handle(requestWithRefreshedAuth);
                            })
                        );
                    })
                );
            })
        );
    }

    private requestWithAuth(req: HttpRequest<any>, userAuthInfo: UserAuthInfo): HttpRequest<any> {
        return req.clone({
            headers: req.headers.set('Authorization', `Token ${userAuthInfo.auth.token}`)
        });
    }

    private getRefreshToken(appName: string): string {
        const storageToken = this.storageService.get(
            AuthStorageKeys.appUserAuthInfo(appName) || this.storageService.get(AuthStorageKeys.userAuthInfo)
        );

        return storageToken && storageToken.refresh_token;
    }
}
