<ng-container *ngIf="pollItem" [ngSwitch]="pollItem.poll?.poll_type">
    <cc-text-poll
        *ngSwitchCase="PollTypeEnum.Text"
        [submitButtonText]="submitButtonText"
        [poll]="pollItem.poll"
        [textPoll]="pollItem.textPoll"
        [initialValue]="initialValue"
        [submitting]="submitting"
        [closed]="closed"
        [showPreviousButton]="showPreviousButton"
        [showNextButton]="showNextButton"
        (pollNext)="onPollNext()"
        (pollPrevious)="onPollPrevious()"
        (pollSubmit)="onPollSubmit($event)"
    ></cc-text-poll>
    <cc-multiple-choice-poll
        *ngSwitchCase="PollTypeEnum.Choice"
        [submitButtonText]="submitButtonText"
        [poll]="pollItem.poll"
        [choicePoll]="pollItem.choicePoll"
        [choicePollChoices]="pollItem.choicePollChoices"
        [choicePollChoiceFormItems]="choicePollChoiceFormItems"
        [submitting]="submitting"
        [closed]="closed"
        [showPreviousButton]="showPreviousButton"
        [showNextButton]="showNextButton"
        (pollNext)="onPollNext()"
        (pollPrevious)="onPollPrevious()"
        (pollSubmit)="onPollSubmit($event)"
    ></cc-multiple-choice-poll>
    <cc-rating-poll
        *ngSwitchCase="PollTypeEnum.Rating"
        [submitButtonText]="submitButtonText"
        [poll]="pollItem.poll"
        [ratingPoll]="pollItem.ratingPoll"
        [initialValue]="initialValue"
        [submitting]="submitting"
        [closed]="closed"
        [showPreviousButton]="showPreviousButton"
        [showNextButton]="showNextButton"
        (pollNext)="onPollNext()"
        (pollPrevious)="onPollPrevious()"
        (pollSubmit)="onPollSubmit($event)"
    ></cc-rating-poll>
</ng-container>
