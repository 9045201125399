import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PwaSetupService } from '@core/pwa/services/pwa-setup/pwa-setup.service';
import { PwaUpdateService } from '@core/pwa/services/pwa-update/pwa-update.service';
import { ToastService } from '@core/toast/services/toast/toast.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, of, race } from 'rxjs';
import { catchError, distinctUntilKeyChanged, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AblyMessagingService } from '../../../../ably/services/ably-messaging.service';
import { AppCoreApiService } from '../../../../app-core/services/app-core-api.service';
import { AppCoreFacadeService } from '../../../../app-core/services/app-core-facade.service';
import { BrandingService } from '../../../../branding/services/branding.service';
import { DeviceService } from '../../../../device/services/device.service';
import { I18nService } from '../../../../i18n/services/i18n.service';
import { SplashScreenService } from '../../../../native/services/splash-screen.service';
import { StatusBarService } from '../../../../native/services/status-bar.service';
import { PageRoutes } from '../../../../routing/constants/page-routes.constant';
import { AppRoutingActions } from '../../app-routing/actions/app-routing.actions';
import { AuthenticationApiActions } from '../../authentication/actions/authentication-api.actions';
import { AppCoreApiActions } from '../actions/app-core-api.actions';
import { AppCoreActions } from '../actions/app-core.actions';

@Injectable()
export class AppCoreEffects {
    /**
     * Wait for the different parts of the core app to initialise, then emit a success or failure action.
     */
    initialiseApp$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppCoreActions.initialiseApp),
            switchMap(() => {
                // Succeed only when each of the individual parts of the initialisation process have succeeded.
                const success$ = forkJoin([
                    this.actions$.pipe(ofType(AuthenticationApiActions.initialiseAuthenticationSuccess)).pipe(take(1)),
                    this.actions$.pipe(ofType(AppCoreApiActions.loadAppSettingsSuccess)).pipe(take(1)),
                    this.actions$.pipe(ofType(AppCoreActions.initialiseAppLanguageSuccess)).pipe(take(1)),
                    this.actions$.pipe(ofType(AppCoreActions.initialiseStatusBarSuccess)).pipe(take(1))
                ]).pipe(map(() => AppCoreActions.initialiseAppSuccess()));

                // Fail if any 1 part of the initialisation fails.
                const failure$ = this.actions$.pipe(ofType(AppCoreApiActions.loadAppSettingsFailure)).pipe(
                    take(1),
                    tap(() => this.toastService.error('ERROR_APP_FAILED')),
                    map(({ error }: any) => AppCoreActions.initialiseAppFailure({ error }))
                );

                return race(success$, failure$);
            })
        )
    );

    setWebManifest$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AppCoreActions.initialiseApp),
                distinctUntilKeyChanged('appName'),
                filter(() => environment.PWA_ENABLED),
                switchMap(({ appName }) =>
                    /* Checks to see if manifest exists so to not assign a bad manifest to the link element, causing console errors */
                    this.pwaSetupService.fetchRemoteManifest(appName).pipe(
                        tap(() => {
                            this.pwaSetupService.setWebManifest(appName);
                            this.pwaUpdateService.listenForUpdates();
                        }),
                        catchError(() => {
                            this.pwaSetupService.removeExistingManifest();
                            this.pwaUpdateService.stopListeningForUpdates();
                            return of(undefined);
                        })
                    )
                )
            ),
        { dispatch: false }
    );

    initialiseLanguages$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppCoreApiActions.loadAppSettingsSuccess),
            tap(({ appSettings }) => {
                this.i18nService.initialiseAppLanguages(appSettings);
            }),
            map(() => AppCoreActions.initialiseAppLanguageSuccess())
        )
    );

    initialiseStatusBar$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppCoreActions.initialiseApp),
            tap(() => {
                this.statusBarService.initialise();
            }),
            map(() => AppCoreActions.initialiseStatusBarSuccess())
        )
    );

    loadAppSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppCoreActions.initialiseApp, AppCoreActions.loadAppSettings, AppCoreActions.refreshAppSettings),
            switchMap(({ appName }) => {
                return this.appCoreApiService.getAppSettings(appName).pipe(
                    map((appSettings) => AppCoreApiActions.loadAppSettingsSuccess({ appSettings })),
                    catchError((error: HttpErrorResponse) => of(AppCoreApiActions.loadAppSettingsFailure({ error })))
                );
            })
        )
    );

    listenForAppUpdates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppCoreActions.initialiseAppSuccess),
            switchMap(() => this.ablyMessagingService.getMessageStream(['app', 'apptheme'])),
            withLatestFrom(this.appCoreFacadeService.getAppName()),
            map(([, appName]) => AppCoreActions.refreshAppSettings({ appName }))
        )
    );

    loadBranding$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppCoreApiActions.loadAppSettingsSuccess),
            switchMap(() => this.appCoreFacadeService.getAppSettings()),
            filter((app) => !!app),
            tap((app) => this.brandingService.initialise(app)),
            map(() => AppCoreActions.initialiseBranding())
        )
    );

    setDeviceId$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AppCoreActions.initialiseApp),
                tap(() => this.deviceService.setDeviceId())
            ),
        { dispatch: false }
    );

    navigateToHomepage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppCoreActions.navigateHome),
            withLatestFrom(this.appCoreFacadeService.getAppSettings()),
            filter(([, { homepage }]) => !this.router.url.includes(`modules/${homepage.id}`)),
            map(([, { homepage }]) => AppRoutingActions.goToAppModule({ urlSegments: [homepage.id] }))
        )
    );

    navigateToFailedToLoad$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppCoreApiActions.loadAppSettingsFailure),
            withLatestFrom(this.appCoreFacadeService.getAppName()),
            map(([{}, appName]) => AppRoutingActions.goToPage({ urlSegments: [PageRoutes.failedToLoad, appName] }))
        )
    );

    hideSplashscreen$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AppCoreActions.initialiseAppSuccess),
                tap(() => this.splashscreenService.hide())
            ),
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private appCoreApiService: AppCoreApiService,
        private appCoreFacadeService: AppCoreFacadeService,
        private ablyMessagingService: AblyMessagingService,
        private brandingService: BrandingService,
        private deviceService: DeviceService,
        private i18nService: I18nService,
        private splashscreenService: SplashScreenService,
        private statusBarService: StatusBarService,
        private router: Router,
        private toastService: ToastService,
        private pwaSetupService: PwaSetupService,
        private pwaUpdateService: PwaUpdateService
    ) {}
}
