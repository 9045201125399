import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NavigationEffects } from './effects/navigation.effects';
import { navigationStoreKey } from './models/navigation.state.model';
import { navigationReducer } from './reducers/navigation.reducer';

@NgModule({
    imports: [
        StoreModule.forFeature(navigationStoreKey, navigationReducer),
        EffectsModule.forFeature([NavigationEffects])
    ]
})
export class NavigationStoreModule {}
