import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { QrScannerModalComponent } from '@shared/qr-scanner/components/qr-scanner-modal/qr-scanner-modal.component';

@Injectable({
    providedIn: 'root'
})
export class QrScannerModalService {
    constructor(private modalController: ModalController) {}

    public async openQrScannerModal(): Promise<string> {
        const modal = await this.modalController.create({
            component: QrScannerModalComponent
        });

        await modal.present();
        return (await modal.onDidDismiss()).data;
    }
}
