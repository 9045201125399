import { Component, ViewChild, AfterViewInit, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { DeviceService } from '@core/device/services/device.service';
import { QrScannerService } from '@shared/qr-scanner/services/qr-scanner/qr-scanner.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { firstValueFrom } from 'rxjs';
import { QrScannerNativeService } from '@shared/qr-scanner/services/qr-scanner-native/qr-scanner-native.service';
import { ModalController } from '@ionic/angular';

@UntilDestroy()
@Component({
    selector: 'cc-qr-scanner-modal',
    templateUrl: './qr-scanner-modal.component.html',
    styleUrls: ['./qr-scanner-modal.component.scss']
})
export class QrScannerModalComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('qrScannerVideo')
    public videoElementRef: ElementRef<HTMLVideoElement>;

    public isNative: boolean;
    public hasCamera: boolean;
    public loading: boolean = true;

    constructor(
        private qrScannerService: QrScannerService,
        private qrScannerNativeService: QrScannerNativeService,
        private deviceService: DeviceService,
        private modalController: ModalController
    ) {}

    public async ngOnInit(): Promise<void> {
        this.isNative = this.deviceService.isNative();
        this.hasCamera = await this.qrScannerService.hasCamera();
    }

    public async ngAfterViewInit(): Promise<void> {
        await this.startScanner();
        this.loading = false;

        const result = await this.getScannerResult();

        if (result) {
            this.dismiss(result);
        }
    }

    public ngOnDestroy(): void {
        this.cleanupScanner();
    }

    public dismiss(qr?: string): void {
        this.modalController.dismiss(qr);
    }

    private startScanner(): Promise<void> {
        if (!this.isNative) {
            this.qrScannerService.createScanner(this.videoElementRef);
            return this.qrScannerService.startScanner();
        }

        return this.qrScannerNativeService.prepareScanner();
    }

    private cleanupScanner(): Promise<void> {
        if (!this.isNative) {
            this.qrScannerService.cleanupScanner();
            return;
        }

        return this.qrScannerNativeService.cleanupScanner();
    }

    private getScannerResult(): Promise<string> {
        if (!this.isNative) {
            return firstValueFrom(this.qrScannerService.scannerStream);
        }

        return this.qrScannerNativeService.getScanResult();
    }
}
