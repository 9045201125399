import { Component, Input, OnInit } from '@angular/core';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { isNumber } from 'lodash';
import { combineLatest, interval, Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

const ONE_SECOND = 1000;
const ONE_MINUTE = ONE_SECOND * 60;
const ONE_HOUR = ONE_MINUTE * 60;
const ONE_DAY = ONE_HOUR * 24;

@Component({
    selector: 'cc-countdown-timer',
    templateUrl: './countdown-timer.component.html',
    styleUrls: ['./countdown-timer.component.scss']
})
export class CountdownTimerComponent implements OnInit {
    // Countdown in milliseconds
    @Input()
    target: number | string;

    @Input()
    title = 'HTML_COUNTDOWN_TITLE';

    visible = true;

    countdown$: Observable<Countdown>;

    constructor(private appCoreFacadeService: AppCoreFacadeService) {}

    ngOnInit(): void {
        this.countdown$ = combineLatest([
            this.target ? of(this.target) : this.appStart(),
            interval(ONE_SECOND).pipe(startWith(0))
        ]).pipe(map(([target]) => this.getCountdown(target)));
    }

    getCountdown(target: number | string): Countdown {
        const dateInput = this.getDateInput(target);
        const adjustedTarget = new Date(dateInput).valueOf();
        const millisecondsUntilStart = adjustedTarget - new Date().getTime();

        if (millisecondsUntilStart <= 0) {
            this.visible = false;
        }

        let offset = 0;

        const days = Math.floor(millisecondsUntilStart / ONE_DAY);
        offset += days * ONE_DAY;

        const hours = Math.floor((millisecondsUntilStart - offset) / ONE_HOUR);
        offset += hours * ONE_HOUR;

        const minutes = Math.floor((millisecondsUntilStart - offset) / ONE_MINUTE);
        offset += minutes * ONE_MINUTE;

        const seconds = Math.ceil((millisecondsUntilStart - offset) / ONE_SECOND);

        return { days, hours, minutes, seconds };
    }

    appStart(): Observable<number> {
        return this.appCoreFacadeService.getAppSettings().pipe(map(({ start }) => Date.parse(start)));
    }

    /**
     * @description
     * Converts the target in to a readable format by the JS date object.
     * JS date objects do not accept a stringified unix timestamp, so if the target is a string
     * that can be parsed as a number, it should be parsed as a number.
     */
    private getDateInput(value: string | number): string | number {
        /* Converting to Number will return NaN if the string is not wholly a number */
        if (isNumber(value) || isNaN(Number(value))) {
            return value;
        }

        return parseInt(value, 10);
    }
}

interface Countdown {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}
