import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { People, UserAuthInfo } from '@shared/api';

export const AuthenticationActions = createActionGroup({
    source: 'Authentication',
    events: {
        Login: props<{ email: string; password: string }>(),
        Logout: emptyProps(),
        'Logout Success': emptyProps(),
        'Set User Auth Info': props<{ userAuthInfo: UserAuthInfo; app?: string }>(),
        'Check Email Address': props<{ email: string }>(),
        'Refresh Authenticated Person': props<{ appName: string }>(),
        'Refresh Download Token': emptyProps(),
        'Patch Authenticated Person': props<{ patch: Partial<People> }>(),
        'Set Passcode Token': props<{ appName: string }>(),
        'Clear Passcode Token': props<{ appName: string }>(),
        'Set Anonymous Token': props<{ anonymousToken: string }>(),
        'Initialise Persisted Auth Data Success': props<{
            appName: string;
            userAuthInfo: UserAuthInfo;
            passcodeTokens: { [key: string]: boolean };
            anonymousToken: string;
            hasDismissedMfa: boolean;
            otpSignature: string;
        }>(),
        'Submit Passcode': props<{ passcode: string }>(),
        'Request Reset Password': props<{ email: string }>(),
        'Request Verification Email': props<{ email: string }>(),
        'Register User': props<{ firstName: string; lastName: string; email: string; password: string }>(),
        'Verify Email Address': props<{ token: string }>(),
        'Update Password': props<{ newPassword: string; uid: string; token: string }>(),
        'Get App Identity Providers': emptyProps(),
        'Set Redirect Url': props<{ redirectUrl: string }>(),
        'Sso Open Login': props<{ loginUrl: string }>(),
        'Sso Exchange Token': props<{ authToken: string }>(),
        'Sso Has Settled': emptyProps(),
        'Mfa Dismiss Prompt': props<{ askAgain: boolean }>(),
        'Mfa Go To Setup Step': emptyProps(),
        'Mfa Get QR': emptyProps(),
        'Mfa Go To Calibrate Step': emptyProps(),
        'Mfa Go To Validate Step': emptyProps(),
        'Mfa Go To Email Validate Step': emptyProps(),
        'Mfa Validate': props<{ mfaCode: string; mfaTrust: boolean }>(),
        'Mfa Calibrate': props<{ mfaCode: string }>(),
        'Mfa Send Email': emptyProps(),
        'Mfa Set Otp Signature': props<{ otpSignature: string }>(),
        'Get Authenticated Person': emptyProps(),
        'Tos Completed': emptyProps(),
        Completed: emptyProps()
    }
});
