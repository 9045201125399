<ion-header>
    <ion-toolbar>
        <ion-title class="cc-primary-header">
            {{ 'QR_SCANNER_MODAL_TITLE' | translate }}
        </ion-title>

        <ion-buttons slot="end">
            <ion-button color="default" fill="clear" (click)="dismiss()">
                <i class="material-icons" slot="icon-only">close</i>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<div class="qr-scanner-modal__no-camera" *ngIf="!isNative && !hasCamera">
    <p>
        {{ 'QR_SCANNER_MODAL_NO_CAMERA_1' | translate }}
    </p>
    <p>
        {{ 'QR_SCANNER_MODAL_NO_CAMERA_2' | translate }}
    </p>
</div>

<cc-loading-spinner *ngIf="loading && hasCamera" message="QR_SCANNER_MODAL_LOADING"></cc-loading-spinner>

<div class="qr-scanner-modal__capture-region" *ngIf="!loading"></div>

<div class="qr-scanner-modal__video-container" [hidden]="isNative || loading">
    <video #qrScannerVideo class="qr-scanner-modal__video"></video>
</div>
