import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'cc-loading-spinner',
    templateUrl: './loading-spinner.component.html',
    styleUrls: ['./loading-spinner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingSpinnerComponent {
    @Input()
    public message: string;
}
