import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PeopleState, peopleStoreKey } from '../models/people.state.model';
import { flatMap, uniqBy } from 'lodash';
import { PeopleSessions } from '../../models/people-session.model';

const selectPeopleState = createFeatureSelector<PeopleState>(peopleStoreKey);

const selectPeopleVisibility = createSelector(selectPeopleState, (state) => state.peopleVisibility);

// Detail selectors
const selectPerson = createSelector(selectPeopleState, (state) =>
    state.detailState ? state.detailState.person : undefined
);
const selectDetailInitialising = createSelector(selectPeopleState, (state) =>
    state.detailState ? state.detailState.initialising : undefined
);
const selectDocuments = (language) =>
    createSelector(selectPeopleState, (state) => {
        if (!state.detailState || !state.detailState.person) {
            return;
        }
        const documents = state.detailState.person.documents;
        return documents.filter((document) => {
            const languages = document.available_languages;
            return !languages.length || languages.includes(language);
        });
    });
const selectCustomFields = createSelector(selectPeopleState, (state) =>
    state.detailState ? state.detailState.customFields : undefined
);
const selectSessions = createSelector(selectPeopleState, (state) => {
    if (!state.detailState || !state.detailState.sessions) {
        return [];
    }
    const peopleSessions = state.detailState.sessions;
    const allSessions: PeopleSessions.Session[] = [
        ...peopleSessions.sessions,
        ...peopleSessions.session_participation.map((participation) => participation.session)
    ];
    const modules: { name: string; id: number }[] = uniqBy(
        allSessions.map((session) => ({ name: session.agenda_name, id: session.module_id })),
        (agenda) => agenda.id
    );
    return modules.map((module) => ({
        module,
        sessions: allSessions.filter((session) => session.module_id === module.id).map((x) => ({ slim: x }))
    }));
});
const selectSocialInfo = createSelector(selectPeopleState, (state) => {
    if (!state.detailState || !state.detailState.person) {
        return;
    }
    return state.detailState.person.socialinfo;
});

const selectPersonPageTitle = createSelector(selectPeopleState, (state) => {
    if (!state?.detailState?.person) {
        return;
    }
    return `${state.detailState.person.first_name} ${state.detailState.person.last_name}`;
});

const selectPersonBusinessCard = createSelector(selectPeopleState, (state) => state.detailState.businessCard);

// List Selectors
const selectModule = (moduleId: number) => createSelector(selectPeopleState, (state) => state.peopleModule[moduleId]);
const selectListPeople = createSelector(selectPeopleState, (state) => {
    if (!state.listState) {
        return undefined;
    }
    const peopleId = flatMap<number>(Object.values(state.listState.pages));
    return peopleId.map((pId) => state.listState.people.find((p) => p.id === pId)).filter((person) => !!person);
});
const selectListInitialising = createSelector(selectPeopleState, (state) =>
    state.listState ? state.listState.initialising : undefined
);
const selectListRefreshing = createSelector(selectPeopleState, (state) =>
    state.listState ? state.listState.refreshing : undefined
);
const selectPaginationState = createSelector(selectPeopleState, (state) => {
    if (!state.listState) {
        return;
    }
    return state.listState.pagination;
});
const selectScrollTopDisabled = createSelector(selectPeopleState, (state) => {
    if (!state.listState) {
        return;
    }
    return !!state.listState.pages[0] || state.listState.pagination.visiblePages.length < 1;
});

const selectScrollBottomDisabled = createSelector(selectPeopleState, (state) =>
    state.listState ? state.listState.scrollBottomDisabled : undefined
);
const selectListModuleId = createSelector(selectPeopleState, (state) => {
    if (!state.listState) {
        return;
    }
    return state.listState.moduleId;
});
const selectListModule = createSelector(selectPeopleState, (state) => {
    if (!state.listState) {
        return;
    }
    return state.listState.module;
});

// Filters Selectors
const selectFilters = createSelector(selectPeopleState, (state) =>
    state.filterState ? state.filterState.filters : undefined
);
const selectAppliedFilters = createSelector(selectPeopleState, (state) =>
    state.filterState ? state.filterState.appliedFilters : undefined
);
const selectFilterMode = createSelector(selectPeopleState, (state) =>
    state.filterState ? state.filterState.filterMode : undefined
);

export const PeopleSelectors = {
    selectPeopleVisibility,

    // Detail selectors
    selectPerson,
    selectDetailInitialising,
    selectDocuments,
    selectCustomFields,
    selectSessions,
    selectSocialInfo,
    selectPersonPageTitle,
    selectPersonBusinessCard,

    // List selectors
    selectModule,
    selectListPeople,
    selectListInitialising,
    selectListRefreshing,
    selectPaginationState,
    listScrollTopDisabled: selectScrollTopDisabled,
    listScrollBottomDisabled: selectScrollBottomDisabled,
    listModuleId: selectListModuleId,
    listModule: selectListModule,

    // filter selectors
    selectFilters,
    selectAppliedFilters,
    selectFilterMode
};
