import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BusinessCardExchangeModalComponent } from '@shared/business-cards/components/business-card-exchange-modal/business-card-exchange-modal.component';

@Injectable({
    providedIn: 'root'
})
export class BusinessCardsModalService {
    constructor(private modalController: ModalController) {}

    public async openExchangeBusinessCardModal(): Promise<any> {
        const modal = await this.modalController.create({
            component: BusinessCardExchangeModalComponent
        });

        await modal.present();
        const result = await modal.onDidDismiss();

        return result.data;
    }
}
