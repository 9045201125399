import { Injectable } from '@angular/core';
import { AppState } from '@core/root-store/store/app-state.model';
import { GlobalSettingsSelectors } from '@core/root-store/store/global-settings/selectors/global-settings.selectors';
import { MeetingBookingConfigModel } from '@features/meeting-booking/store/models/meeting-booking-config.model';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GlobalSettingsFacadeService {
    constructor(private store: Store<AppState>) {}

    public getCanCreateExhibitorMeetings(): Observable<boolean> {
        return this.store.select(GlobalSettingsSelectors.canCreateExhibitorMeetings);
    }

    public getCanCreateDelegateMeetings(): Observable<boolean> {
        return this.store.select(GlobalSettingsSelectors.canCreateDelegateMeetings);
    }

    public getMeetingConfig(): Observable<MeetingBookingConfigModel> {
        return this.store.select(GlobalSettingsSelectors.meetingsState);
    }
}
