import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PortalConfig } from '@features/portal/interfaces/portal-config.interface';
import { AppTheme } from '@shared/api';
import { ModuleWithRouteCommands } from '@shared/api/models/ModuleWithRouteCommands';
import { TooltipOptions } from 'ng2-tooltip-directive-major-angular-updates';

@Component({
    selector: 'cc-sidebar-left-icons',
    templateUrl: './sidebar-left-icons.component.html',
    styleUrls: ['./sidebar-left-icons.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarLeftIconsComponent {
    @Input()
    public modules: ModuleWithRouteCommands[];

    @Input()
    public theme: AppTheme;

    @Input()
    public showLabels: boolean = false;

    @Input()
    public portalConfig: PortalConfig;

    @Input()
    public selectedModule: number;

    @Output()
    public moduleClick = new EventEmitter<void>();

    @Output()
    public toggleCollapse = new EventEmitter<void>();

    @Output()
    public appIconClick = new EventEmitter<void>();

    @Output()
    public portalReturn = new EventEmitter<void>();

    public tooltipOptions: TooltipOptions = {
        placement: 'right',
        offset: 8,
        'hide-delay': 0,
        'animation-duration': 0
    };

    public onModuleClick(): void {
        this.moduleClick.emit();
    }

    public onPortalReturn(): void {
        this.portalReturn.emit();
    }

    public onToggleCollapse(): void {
        this.toggleCollapse.emit();
    }

    public onAppIconClick(): void {
        this.appIconClick.emit();
    }
}
