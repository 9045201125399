<ion-header>
    <ion-toolbar class="cookie-notice-dialog-header">
        <ion-title>
            <span>
                {{ 'COOKIE_NOTICE_TITLE' | translate }}
            </span>
        </ion-title>
    </ion-toolbar>
</ion-header>
<ng-container
    *ngIf="{
        enabledTags: enabledTags$ | async,
        showClientSection: showClientSection$ | async
    } as pageData"
>
    <div class="cookie-notice-content">
        <h4 class="cookie-notice-content__sub-heading">
            {{ 'COOKIE_NOTICE_WHAT_ARE_COOKIES_TITLE' | translate }}
        </h4>
        <p class="cookie-notice-content__text" [innerHTML]="'COOKIE_NOTICE_WHAT_ARE_COOKIES_DESC' | translate"></p>

        <h4 class="cookie-notice-content__sub-heading">
            {{ 'COOKIE_NOTICE_WHY_USE_COOKIES_TITLE' | translate }}
        </h4>
        <p class="cookie-notice-content__text" [innerHTML]="'COOKIE_NOTICE_WHY_USE_COOKIED_DESC' | translate"></p>

        <h4 class="cookie-notice-content__sub-heading">
            {{ 'COOKIE_NOTICE_COOKIES_SET_TITLE' | translate }}
        </h4>
        <p class="cookie-notice-content__text" [innerHTML]="'COOKIE_NOTICE_COOKIES_SET_DESC' | translate"></p>

        <table class="cookie-notice-table">
            <th class="cookie-notice-table__heading">
                {{ 'COOKIE_NOTICE_TABLE_COOKIE_NAME_HEADING' | translate }}
            </th>
            <th class="cookie-notice-table__heading">
                {{ 'COOKIE_NOTICE_TABLE_PURPOSE_HEADING' | translate }}
            </th>
            <tr class="cookie-notice-table__row">
                <td>{{ 'COOKIE_NOTICE_TABLE_GA_LABEL' | translate }}</td>
                <td>{{ 'COOKIE_NOTICE_TABLE_GA_DESC' | translate }}</td>
            </tr>
            <tr class="cookie-notice-table__row">
                <td>{{ 'COOKIE_NOTICE_TABLE_MUX_LABEL' | translate }}</td>
                <td>
                    {{ 'COOKIE_NOTICE_TABLE_MUX_DESC' | translate }}
                    <a href="https://mux.com/privacy" target="_blank">https://mux.com/privacy</a>
                </td>
            </tr>
        </table>

        <ng-container *ngIf="pageData.showClientSection === true">
            <h4 class="cookie-notice-content__sub-heading">
                {{ 'COOKIE_NOTICE_THIRD_PARTY_SUBHEADING' | translate }}
            </h4>
            <p
                *ngIf="pageData.enabledTags?.hotjar"
                class="cookie-notice-content__text"
                [innerHTML]="'COOKIE_NOTICE_THIRD_PARTY_DESC_HOTJAR' | translate"
            ></p>
            <p class="cookie-notice-content__text" [innerHTML]="'COOKIE_NOTICE_THIRD_PARTY_DESC' | translate"></p>

            <table class="cookie-notice-table">
                <th class="cookie-notice-table__heading">
                    {{ 'COOKIE_NOTICE_TABLE_COOKIE_NAME_HEADING' | translate }}
                </th>
                <th class="cookie-notice-table__heading">
                    {{ 'COOKIE_NOTICE_TABLE_PURPOSE_HEADING' | translate }}
                </th>

                <tr *ngIf="pageData.enabledTags?.ga || pageData.enabledTags?.gtag" class="cookie-notice-table__row">
                    <td>
                        {{ 'COOKIE_NOTICE_TABLE_GA_LABEL' | translate }}
                    </td>
                    <td>
                        {{ 'COOKIE_NOTICE_TABLE_GA_DESC' | translate }}
                    </td>
                </tr>
                <tr *ngIf="pageData.enabledTags?.hotjar" class="cookie-notice-table__row">
                    <td>{{ 'COOKIE_NOTICE_TABLE_HOTJAR_LABEL' | translate }}</td>
                    <td>
                        {{ 'COOKIE_NOTICE_TABLE_HOTJAR_DESC' | translate }}
                        <a href="https://help.hotjar.com/hc/en-us/categories/115001323967-About-Hotjar" target="_blank">
                            {{ 'COOKIE_NOTICE_TABLE_HOTJAR_LINK_TEXT' | translate }} </a
                        >.
                    </td>
                </tr>
            </table>
        </ng-container>

        <p class="cookie-notice-content__text">
            {{ 'COOKIE_NOTICE_OPT_OUT' | translate }}
        </p>

        <div class="cookie-notice-content__full-terms">
            <p class="cookie-notice-content__text">{{ 'COOKIE_NOTICE_FULL_TERMS_DESC' | translate }}</p>
            <a (click)="showTermsAndConditions()">{{ 'COOKIE_NOTICE_FULL_TERMS_LINK' | translate }}</a>
        </div>
    </div>
    <ion-footer class="cookie-notice-footer">
        <div class="footer-content">
            <div class="cookie-notice-button-row">
                <ion-button class="cookie-notice-button" (click)="close()">
                    {{ 'TOS_DIALOG_BUTTON_CLOSE' | translate }}
                </ion-button>
            </div>
        </div>
    </ion-footer>
</ng-container>

<ng-template #loading>
    <cc-loading-spinner></cc-loading-spinner>
</ng-template>
