import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { fadeIn, fadeOut } from '@common/animations/animations';
import { DeeplinksService } from '@core/native/services/deeplinks.service';
import { ExpandedApiDrivenNotification } from '@core/notifications/interfaces/expanded-api-driven-notification.interface';
import { AppAlert } from '@shared/api';
import { TooltipOptions } from 'ng2-tooltip-directive-major-angular-updates';

@Component({
    selector: 'cc-alert-list-item',
    templateUrl: './alert-list-item.component.html',
    styleUrls: ['./alert-list-item.component.scss'],
    animations: [fadeIn(100), fadeOut(100)],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertListItemComponent {
    @Input()
    public alert: AppAlert;

    @Input()
    public notification: ExpandedApiDrivenNotification;

    @Output()
    public archive = new EventEmitter<void>();

    @Output()
    public unarchive = new EventEmitter<void>();

    @Output()
    public markAsRead = new EventEmitter<void>();

    public tooltipOptions: TooltipOptions = {
        'hide-delay': 0,
        'animation-duration': 0
    };

    constructor(private deeplinkService: DeeplinksService) {}

    public onToggleArchive(): void {
        if (this.notification.archived) {
            this.unarchive.emit();
        } else {
            this.archive.emit();
        }
    }

    public onAlertAction(): void {
        this.deeplinkService.handle(this.alert.deeplink_url);
    }
}
