import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { Shade } from '@core/branding/enums/shade.enum';

@Component({
    selector: 'cc-powered-by-logo',
    templateUrl: './powered-by-logo.component.html',
    styleUrls: ['./powered-by-logo.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PoweredByLogoComponent implements OnChanges {
    @Input()
    public shade: Shade = Shade.Dark;

    public imageSrc: string;

    public ngOnChanges(): void {
        this.updateImageSrc();
    }

    private updateImageSrc(): void {
        this.imageSrc = `/assets/images/powered-by/powered-by-${this.shade}.svg`;
    }
}
