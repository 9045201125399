import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import * as Ably from 'ably';

export const RealtimeActions = createActionGroup({
    source: 'Realtime Api',
    events: {
        'Initialise Ably Success': emptyProps(),
        'Initialise Ably Failure': props<{ error: any }>(),
        'Qanda Update': props<{ message: Ably.Types.Message }>(),
        'Initialise Firebase Success': emptyProps(),
        'Initialise Firebase Failure': props<{ error: HttpErrorResponse }>(),
        'Refresh Firebase Success': emptyProps(),
        'Refresh Firebase Failure': props<{ error: HttpErrorResponse }>(),
        'Connected To Private Channel': emptyProps(),
        'Disconnected From Private Channel': emptyProps(),
        'Firebase Sign Out': emptyProps(),
        'Presence Update': emptyProps()
    }
});
