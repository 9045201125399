import { AppState } from '../../../../core/root-store/store/app-state.model';
import { AgendaModuleState } from '../../agenda-module/store/models/agenda-module.state.model';
import {
    AgendaSessionPageState,
    AgendaSessionZoomState
} from '../../agenda-session/store/models/agenda-session.state.model';

export const agendaStoreKey = 'agenda';

// Interfaces have to be flattened as action reducer maps can only go 1 level deep, no nesting :(
export interface AgendaState {
    // Session Page
    sessionPage: AgendaSessionPageState;
    sessionZoom: AgendaSessionZoomState;

    // Module List
    module: AgendaModuleState;

    // Shared
    shared: AgendaSharedState;
}

export interface AgendaSharedState {
    personalAgenda: number[];
    personalWaitingList: number[];
}

export interface AgendaAppState extends AppState {
    [agendaStoreKey]: AgendaState;
}
