import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from '../../storage/services/storage.service';
import { DeviceService } from '../services/device.service';

@Injectable()
export class DeviceInterceptor implements HttpInterceptor {
    constructor(
        private storageService: StorageService,
        private deviceService: DeviceService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const deviceId = this.storageService.getFromStorage(this.deviceService.deviceIdKey);
        if (!deviceId) {
            return next.handle(req);
        }

        const modifiedReq = req.clone({
            headers: req.headers.set('X-CC-Device-ID', deviceId)
        });
        return next.handle(modifiedReq);
    }
}
