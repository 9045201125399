import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { Module } from '@shared/api';
import { SidebarService } from '../../services/sidebar.service';
import { UntypedFormControl } from '@angular/forms';
import { App } from '@shared/api';
import { filter, map, tap } from 'rxjs/operators';
import { NavigationFacadeService } from '../../services/navigation-facade.service';
import { NavigationActions } from '../../store/actions/navigation.actions';
import { AppCoreFacadeService } from '../../../../core/app-core/services/app-core-facade.service';
import { PortalService } from '@features/portal/services/portal.service';
import { DOCUMENT } from '@angular/common';
import { AuthenticationFacadeService } from '../../../authentication/services/authentication-facade.service';
import { AppRoutingFacadeService } from '@core/routing/services/app-routing-facade.service';
import { SidebarType } from '@core/navigation/enums/sidebar-type.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Breakpoints, BreakpointService } from '@shared/utilities/breakpoint.service';
import { PortalConfig } from '@features/portal/interfaces/portal-config.interface';
import { ModuleWithRouteCommands } from '@shared/api/models/ModuleWithRouteCommands';
import { ModuleService } from '@core/module/services/module/module.service';
import { NavigationHistoryService } from '@core/navigation-history/services/navigation-history.service';
import { Shade } from '@core/branding/enums/shade.enum';
import { BrandingService } from '@core/branding/services/branding.service';

@UntilDestroy()
@Component({
    selector: 'cc-sidebar-left-container',
    templateUrl: './sidebar-left-container.component.html',
    styleUrls: ['./sidebar-left-container.component.scss']
})
export class SidebarLeftContainerComponent implements OnInit, OnDestroy {
    public initialising$: Observable<boolean>;
    public modules$: Observable<ModuleWithRouteCommands[]>;
    public app$: Observable<App>;
    public collapsed$: Observable<boolean>;
    public selectedModule$: Observable<number>;
    public portalConfig$: Observable<PortalConfig>;
    public poweredByLogoShade$: Observable<Shade>;

    public searchControl = new UntypedFormControl('');

    private splitPane: HTMLElement;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private sidebarService: SidebarService,
        private portalService: PortalService,
        private moduleService: ModuleService,
        private navigationFacadeService: NavigationFacadeService,
        private navigationHistoryService: NavigationHistoryService,
        private appCoreFacadeService: AppCoreFacadeService,
        private authenticationFacadeService: AuthenticationFacadeService,
        private appRoutingFacadeService: AppRoutingFacadeService,
        private breakpointService: BreakpointService,
        private brandingService: BrandingService
    ) {}

    public ngOnInit(): void {
        this.splitPane = this.document.getElementById('split-pane');

        this.initialising$ = this.navigationFacadeService.getInitialising();
        this.app$ = this.appCoreFacadeService.getAppSettings();
        this.collapsed$ = this.navigationFacadeService.getLeftSidebarCollapsed();

        this.modules$ = this.navigationFacadeService.getModules().pipe(
            map((modules) =>
                modules.map((module) => {
                    const routeCommands = this.moduleService.getRouterCommandsForModule(module);

                    return {
                        ...module,
                        routeCommands
                    };
                })
            )
        );

        this.selectedModule$ = this.appRoutingFacadeService.getCurrentModuleId();
        this.portalConfig$ = this.appCoreFacadeService
            .getAppName()
            .pipe(map((appName) => this.portalService.getPortalConfigForApp(appName)));

        this.poweredByLogoShade$ = this.brandingService.contentOnSidebarShade$;

        this.subscribeToEnableSidebarChanges();
        this.subscribeToBreakpointChanges();
        this.subscribeToCollapseChanges();
    }

    public onModuleClick(): void {
        this.navigationHistoryService.forget();
        this.sidebarService.close(SidebarType.Left);
    }

    public onAppBannerClick(): void {
        this.navigationFacadeService.dispatch(NavigationActions.appImageClicked());
    }

    public onSearchSubmit(searchTerm: string): void {
        this.navigationFacadeService.dispatch(NavigationActions.searchSubmitted({ searchTerm }));
    }

    public portalReturnClick(): void {
        this.navigationFacadeService.dispatch(NavigationActions.portalReturnClick());
    }

    public showTosDialog(): void {
        this.navigationFacadeService.dispatch(NavigationActions.openTermsAndConditions());
    }

    public onToggleCollapse(): void {
        this.navigationFacadeService.dispatch(NavigationActions.toggleLeftSidebarCollapsed());
    }

    public setCollapsed(collapsed: boolean): void {
        this.navigationFacadeService.dispatch(NavigationActions.setLeftSidebarCollapsed({ collapsed }));
    }

    public ngOnDestroy(): void {
        this.navigationFacadeService.dispatch(NavigationActions.destroyed());
    }

    private subscribeToEnableSidebarChanges(): void {
        combineLatest([
            this.app$,
            this.modules$,
            this.authenticationFacadeService.hasAppAccess(),
            this.navigationFacadeService.getShouldDisplaySidebbar(),
            this.portalConfig$
        ])
            .pipe(
                untilDestroyed(this),
                filter(([app]) => !!app),
                map(([app, modules, hasAccess, shouldDisplaySidebar, portalConfig]) =>
                    this.shouldEnableSidebar(app, modules, hasAccess, shouldDisplaySidebar, portalConfig)
                ),
                tap((shouldEnable) => this.sidebarService.enable(SidebarType.Left, shouldEnable))
            )
            .subscribe();
    }

    private subscribeToBreakpointChanges(): void {
        this.breakpointService
            .is(({ width }) => width >= Breakpoints.ExtraLarge)
            .pipe(
                untilDestroyed(this),
                filter((isExpanded) => !isExpanded)
            )
            .subscribe(() => {
                this.setCollapsed(false);
            });
    }

    private subscribeToCollapseChanges(): void {
        this.navigationFacadeService
            .getLeftSidebarCollapsed()
            .pipe(
                untilDestroyed(this),
                tap((collapsed) => {
                    if (collapsed) {
                        this.splitPane.classList.add('collapsed');
                        return;
                    }

                    this.splitPane.classList.remove('collapsed');
                })
            )
            .subscribe();
    }

    private shouldEnableSidebar(
        app: App,
        modules: Array<Module>,
        hasAccess: boolean,
        shouldDisplaySidebar: boolean,
        portalConfig: PortalConfig | undefined
    ): boolean {
        if (!shouldDisplaySidebar) {
            return false;
        }

        const isPublicApp = app.privacy === App.PrivacyEnum.Public;

        if (!(isPublicApp || hasAccess)) {
            return false;
        }

        const hasMoreThanOneModule = modules.length > 1;

        if (!(hasMoreThanOneModule || portalConfig)) {
            return false;
        }

        return true;
    }
}
