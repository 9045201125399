import { Component, Input } from '@angular/core';
import { NavigationHistoryService } from '@core/navigation-history/services/navigation-history.service';
import { PortalConfig } from '@features/portal/interfaces/portal-config.interface';
import { ModalController } from '@ionic/angular';
import { ModuleWithRouteCommands } from '@shared/api/models/ModuleWithRouteCommands';

@Component({
    selector: 'cc-tabbar-more',
    templateUrl: './tabbar-more.component.html',
    styleUrls: ['./tabbar-more.component.scss']
})
export class TabbarMoreComponent {
    @Input()
    public selectedModuleId: number;

    @Input()
    public modules: ModuleWithRouteCommands[];

    @Input()
    public portalConfig: PortalConfig;

    constructor(
        private modalController: ModalController,
        private navigationHistoryService: NavigationHistoryService
    ) {}

    public onModuleClick(): void {
        this.navigationHistoryService.forget();
        this.dismiss();
    }

    public portalReturn(): void {
        this.dismiss({ portal: true });
    }

    public openTerms(): void {
        this.dismiss({ terms: true });
    }

    public dismiss(data?: any): void {
        this.modalController.dismiss(data);
    }
}
