import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WebPushNotificationPayload } from '@core/notifications/interfaces/web-push-notification-payload.interface';
import { ApiSettings } from '@shared/settings/api-settings.constant';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NotificationsApiService {
    constructor(private httpClient: HttpClient) {}

    public getNotificationDeeplink(appName: string, id: number | string): Observable<{ deeplink: string }> {
        const url = ApiSettings.fullUrl(appName, `/me/notifications/${id}/deeplink/`);

        return this.httpClient.get<{ deeplink: string }>(url);
    }

    public requestPushNotifications(appName: string, payload: WebPushNotificationPayload): Observable<unknown> {
        const url = ApiSettings.fullUrl(appName, '/me/browser-notifications/');

        return this.httpClient.post<unknown>(url, payload);
    }
}
