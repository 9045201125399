import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@core/window.service';
import { DeviceService } from '../../device/services/device.service';
import { CapacitorService } from './capacitor.service';

@Injectable({
    providedIn: 'root'
})
export class NativeBadgeService {
    constructor(
        @Inject(WINDOW) private window: Window,
        private capacitorService: CapacitorService,
        public deviceService: DeviceService
    ) {}

    async setNativeBadge(badgeNumber: number): Promise<void> {
        if (!this.deviceService.isNative()) {
            return;
        }

        const supportedResult = await this.capacitorService.badge.isSupported();

        if (!supportedResult.isSupported) {
            return;
        }

        const permission = await this.capacitorService.badge.checkPermissions();
        const hasPermission = permission.display === 'granted';

        if (!hasPermission) {
            const newPermission = await this.capacitorService.badge.requestPermissions();
            const hasNewPermission = newPermission.display === 'granted';

            if (!hasNewPermission) {
                return;
            }
        }

        if (badgeNumber === 0) {
            return this.capacitorService.badge.clear();
        } else {
            return this.capacitorService.badge.set({ count: badgeNumber });
        }
    }
}
