import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { AppAlert } from '@shared/api';

export const AlertsApiActions = createActionGroup({
    source: 'Alerts Api',
    events: {
        'Fetch Alerts Success': props<{ alerts: AppAlert[] }>(),
        'Fetch Alerts Failure': props<{ error: HttpErrorResponse }>(),
        'Archive Success': emptyProps(),
        'Archive All Success': emptyProps(),
        'Unarchive Success': emptyProps(),
        'Mark As Read Success': emptyProps(),
        'Mark Alert As Read Success': props<{ alert: AppAlert }>(),
        'Mark All As Read Success': emptyProps(),
        'Mark As Read Failure': props<{ error: HttpErrorResponse }>()
    }
});
