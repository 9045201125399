import { TagEntity } from '@core/tag-management/models/tag-entity.interface';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TagManagementState, tagManagerFeatureKey } from '../reducers/tag-manager.reducer';

const tagManagerState = createFeatureSelector<TagManagementState>(tagManagerFeatureKey);

const selectTagEntities = createSelector(tagManagerState, (state: TagManagementState): TagEntity[] => {
    return state?.tags;
});

const selectLoadedTags = createSelector(tagManagerState, (state: TagManagementState): TagEntity[] => {
    return state?.loadedTags;
});

export const TagManagementSelectors = {
    selectTagEntities,
    selectLoadedTags
};
