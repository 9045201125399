import { LocationMap, PinDrop, StaticText } from '@shared/api';

export type Location = LocationMap | PinDrop | StaticText;

export interface GenericLocation {
    id: number;
    model_type: LocationType;
    ctype: string;
    location_name: string;
}

export enum LocationType {
    locationMap = 'LocationMap',
    pindrop = 'Pindrop',
    staticText = 'StaticText'
}
