import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AppDocument } from '@shared/api';
import { TooltipOptions } from 'ng2-tooltip-directive-major-angular-updates';

@Component({
    selector: 'cc-agenda-card-document-item',
    templateUrl: './agenda-card-document-item.component.html',
    styleUrl: './agenda-card-document-item.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgendaCardDocumentItemComponent {
    @Input({ required: true })
    public document: AppDocument;

    public tooltipOptions: TooltipOptions = {
        'hide-delay': 0,
        'show-delay': 100,
        'animation-duration': 200
    };
}
