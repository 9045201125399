import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { LoginStep } from '../models/login.state';

export const LoginPageActions = createActionGroup({
    source: 'Login Page',
    events: {
        'Enter Page': props<{ loginStep: LoginStep }>(),
        'Leave Page': emptyProps(),
        'Back To Portal': emptyProps(),
        'Back To Container': emptyProps(),
        'Set Language': props<{ language: string }>(),
        'Toggle Password Visibility': emptyProps(),
        'Go To Login Step': props<{ loginStep: LoginStep }>()
    }
});
