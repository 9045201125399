import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DateTime } from 'luxon';
import { interval } from 'rxjs';
import { tap } from 'rxjs/operators';

@UntilDestroy()
@Pipe({
    name: 'dateRelative',
    pure: false
})
export class DateRelativePipe implements PipeTransform {
    constructor(private changeDetectorRef: ChangeDetectorRef) {
        this.startInterval();
    }

    transform(date: any): any {
        const inPast = new Date(date) < new Date();
        if (inPast) {
            return DateTime.fromISO(date).toRelative();
        } else {
            return DateTime.fromISO(date).toLocal().toRelative();
        }
    }

    startInterval(): void {
        // By default, check every 10 seconds
        const period = 10000;
        interval(period)
            .pipe(
                tap(() => this.changeDetectorRef.markForCheck()),
                untilDestroyed(this)
            )
            .subscribe();
    }
}
