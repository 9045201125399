import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { MableCustomField, MableCustomFieldGroup, People, PeopleModule, PeopleVisibility } from '@shared/api';
import { BusinessCard } from '@shared/business-cards/interfaces/business-card.interface';
import { PaginatedResponse } from '../../../../shared/pagination/models/paginated-response.model';
import { ExpandedPeople } from '../../models/expanded-people.model';
import { PeopleSessions } from '../../models/people-session.model';

export const PeopleApiActions = createActionGroup({
    source: 'People Api',
    events: {
        'Fetch Detail Success': props<{
            person: ExpandedPeople;
            sessions: PeopleSessions;
            customFields: (MableCustomField | MableCustomFieldGroup)[];
        }>(),
        'Fetch Detail Failure': props<{ error: HttpErrorResponse }>(),
        'Fetch Detail Business Card Success': props<{ businessCard: BusinessCard }>(),
        'Fetch Detail Business Card Failure': props<{ error: HttpErrorResponse }>(),
        'Exchange Detail Business Card Success': emptyProps(),
        'Exchange Detail Business Card Failure': props<{ error: HttpErrorResponse }>(),
        'Fetch List Success': props<{ module: PeopleModule; paginatedResponse: PaginatedResponse<People> }>(),
        'Fetch List Failure': props<{ error: HttpErrorResponse }>(),
        'Fetch Pagination Success': props<{ page: number; paginatedResponse: PaginatedResponse<People> }>(),
        'Fetch Pagination Failure': props<{ error: any }>(),
        'Fetch Search Success': props<{ paginatedResponse: PaginatedResponse<People> }>(),
        'Fetch Search Failure': props<{ error: any }>(),
        'Fetch Visibility Success': props<{ peopleVisibility: PeopleVisibility }>(),
        'Fetch Visibility Failure': props<{ error: HttpErrorResponse }>(),
        'Fetch People Module Success': props<{ module: PeopleModule }>(),
        'Http Error': props<{ error: HttpErrorResponse }>()
    }
});
