import { Injectable, Inject } from '@angular/core';
import { WINDOW } from '../../window.service';

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    public useCache = true;
    public cache = {};

    constructor(@Inject(WINDOW) private window: any) {}

    public put(key: string, value: any): void {
        if (this.useCache) {
            this.cache[key] = value;
        }

        return this.setStorageItem(key, value);
    }

    public get(key: string): any {
        if (!this.useCache) {
            return this.getFromStorage(key);
        }

        if (!this.cache.hasOwnProperty(key)) {
            this.cache[key] = this.getFromStorage(key);
        }

        return this.cache[key];
    }

    public clear(key: string): void {
        if (this.useCache) {
            delete this.cache[key];
        }

        return this.removeFromStorage(key);
    }

    public setStorageItem(key: string, value: string | any): void {
        if (typeof value !== 'object') {
            (value as any) = { $$_: value };
        }
        value = JSON.stringify(value);
        this.window.localStorage.setItem(key, value);
    }

    public getFromStorage(key: string): any {
        let val = this.window.localStorage.getItem(key);

        if (!!val) {
            try {
                val = JSON.parse(val);
                return val.hasOwnProperty('$$_') ? val.$$_ : val;
            } catch (err) {
                // Token is invalid, make them authenticate again
                this.clear(key);
                return;
            }
        }
    }

    public removeFromStorage(key: string): void {
        this.window.localStorage.removeItem(key);
    }
}
