/**
 * Routes scoped to a page within an app. /{appName}/*
 */
export const AppPageRoutes = {
    challenge: 'challenge',
    chat: 'chat',
    company: 'company',
    error404: '404',
    infoPage: 'info-page',
    livePoll: 'live-polling',
    liveStream: 'stream',
    login: 'login',
    map: 'map',
    module: 'modules',
    person: 'people',
    qanda: 'qanda',
    passwordReset: 'reset',
    session: 'session',
    poll: 'poll',
    user: 'user',
    verifyEmail: 'verify',
    note: 'note',
    infobooth: 'infobooth' /* Old URL for infoPage - still used by custom HTML templates */,
    pwaInstall: 'install',
    deeplinkNotification: 'deeplink-notification'
};
