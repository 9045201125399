import { createSelector } from '@ngrx/store';
import { LoginAppState, loginStoreKey } from '../models/login-app.state';

const selectLoginState = (state: LoginAppState) => state[loginStoreKey];

const selectLoginPageState = createSelector(selectLoginState, (loginState) => loginState?.loginPageState);

const selectPasswordResetPageState = createSelector(
    selectLoginState,
    (loginState) => loginState.passwordResetPageState
);

export const LoginSelectors = {
    selectLoginPageState,
    selectPasswordResetPageState
};
