import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { AuthenticationFacadeService } from '@core/authentication/services/authentication-facade.service';
import { NavigationHistoryService } from '@core/navigation-history/services/navigation-history.service';
import { AppCoreActions } from '@core/root-store/store/app-core/actions/app-core.actions';
import { AuthenticationActions } from '@core/root-store/store/authentication/actions/authentication.actions';
import { ToastService } from '@core/toast/services/toast/toast.service';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class UnauthorisedInterceptor implements HttpInterceptor {
    constructor(
        private authenticationFacadeService: AuthenticationFacadeService,
        private appCoreFacadeService: AppCoreFacadeService,
        private navigationHistoryService: NavigationHistoryService,
        private toastService: ToastService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((errorResponse: HttpErrorResponse) => {
                if (
                    errorResponse.status !== 403 ||
                    !req.url.includes(environment.API_URL) ||
                    req.url.match(/\/portal\/apps\/\d+\/authorize\//)
                ) {
                    return throwError(errorResponse);
                }

                // Any 403s that are not a failed attempt to refresh the token should just redirect back to home
                if (!req.url.match(/\/refresh-token\/\S*/)) {
                    return this.navigationHistoryService.canGoBack().pipe(
                        take(1),
                        tap((canGoBack) => {
                            /* If the user can go back, it means that no redirecting needs to be done as they are on an app page already */
                            if (canGoBack) {
                                return;
                            }

                            /* Necessary for when the user deep-links to a restricted module */
                            this.appCoreFacadeService.dispatch(AppCoreActions.navigateHome());
                        }),
                        tap(() => this.toastService.error('UNAVAILABLE_RESOURCE')),
                        switchMap(() => throwError(errorResponse))
                    );
                }

                this.toastService.error('UNAUTHORISED_TOAST_MESSAGE');
                this.authenticationFacadeService.dispatch(AuthenticationActions.logout());

                return this.appCoreFacadeService.getAppName().pipe(
                    take(1),
                    tap((appName) => {
                        this.appCoreFacadeService.dispatch(AppCoreActions.initialiseApp({ appName }));
                    }),
                    switchMap(() => throwError(errorResponse))
                );
            })
        );
    }
}
