import { defer, MonoTypeOperatorFunction, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { isEqual } from 'lodash';
import { isDevMode } from '@angular/core';

/**
 * @description filters out null or undefined values from a stream so you only emit values
 * @example
 * interval(1000).pipe(
 * map(value => value === 0 ? undefined : value),
 * filterNil()
 * ).subscribe(value => console.log(value));
 */
export function filterNil(): MonoTypeOperatorFunction<any> {
    return filter((value: any) => value !== undefined && value !== null);
}
/**
 *
 * @param tag description of value in stream
 * @description logs an output for each emission from stream
 */
export function debug<T = any>(tag: string = 'emitted'): MonoTypeOperatorFunction<T> {
    return tap({
        next(value: any): void {
            console.log(`%c[${tag}: Next]`, 'background: #009688; color: #fff; padding: 3px; font-size: 9px;', value);
        },
        error(error: Error): void {
            console.log(`%[${tag}: Error]`, 'background: #E91E63; color: #fff; padding: 3px; font-size: 9px;', error);
        },
        complete(): void {
            console.log(`%c[${tag}]: Complete`, 'background: #00BCD4; color: #fff; padding: 3px; font-size: 9px;');
        }
    });
}

/**
 *
 * @param tag description of value in stream
 * @param enabled whether the logging is active
 * @description logs an output for each emission from stream when enabled
 */
export function switchDebug(tag: string, enabled: boolean): MonoTypeOperatorFunction<any> {
    return (source) =>
        defer(() => {
            return source.pipe(
                filter(() => enabled),
                tap({
                    next(value: any): void {
                        console.log(
                            `%c[${tag}: Next]`,
                            'background: #009688; color: #fff; padding: 3px; font-size: 9px;',
                            value
                        );
                    },
                    error(error: Error): void {
                        console.log(
                            `%[${tag}: Error]`,
                            'background: #E91E63; color: #fff; padding: 3px; font-size: 9px;',
                            error
                        );
                    },
                    complete(): void {
                        console.log(
                            `%c[${tag}]: Complete`,
                            'background: #00BCD4; color: #fff; padding: 3px; font-size: 9px;'
                        );
                    }
                })
            );
        });
}

/**
 *
 * @param tag description of value in stream
 * @description logs an output for each emission from stream when in development
 */
export function devDebug(tag: string): MonoTypeOperatorFunction<any> {
    return (source) =>
        defer(() => {
            return source.pipe(
                filter(() => isDevMode()),
                tap({
                    next(value: any): void {
                        console.log(
                            `%c[${tag}: Next]`,
                            'background: #009688; color: #fff; padding: 3px; font-size: 9px;',
                            value
                        );
                    },
                    error(error: Error): void {
                        console.log(
                            `%[${tag}: Error]`,
                            'background: #E91E63; color: #fff; padding: 3px; font-size: 9px;',
                            error
                        );
                    },
                    complete(): void {
                        console.log(
                            `%c[${tag}]: Complete`,
                            'background: #00BCD4; color: #fff; padding: 3px; font-size: 9px;'
                        );
                    }
                })
            );
        });
}

/**
 *
 * @param key filters keyboard events by passed in key
 * @example
 * fromEvent(document, 'keyup')
 * .pipe(
 *   filterKey('Escape')
 * ).subscribe();
 */
export function filterKey(key: string): MonoTypeOperatorFunction<KeyboardEvent> {
    return filter((event: KeyboardEvent) => event.key === key);
}

/*
 * Debounces and ensures distinct emissions
 */
export function performant<T>(debounce = 100): MonoTypeOperatorFunction<T> {
    return function (source: Observable<T>) {
        return source.pipe(debounceTime(debounce), distinctUntilChanged(isEqual));
    };
}
