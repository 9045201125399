import { Directive, HostListener, Input } from '@angular/core';
import { ActionPopoverService } from '@shared/action-popover/services/action-popover/action-popover.service';
import { ActionPopoverType } from '../enums/action-popover-type.enum';

@Directive({
    selector: '[ccActionPopover]'
})
export class ActionPopoverDirective {
    @Input('ccActionPopover')
    actionType: ActionPopoverType;

    @Input()
    actionContent: string;

    @HostListener('click', ['$event'])
    onClick(event: MouseEvent): void {
        this.actionPopoverService.display(this.actionType, this.actionContent, event);
    }

    constructor(private actionPopoverService: ActionPopoverService) {}
}
