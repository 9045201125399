import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FirebaseService } from '../../../../core/firebase/services/firebase.service';

@Component({
    selector: 'cc-online-status',
    templateUrl: './online-status.component.html',
    styleUrls: ['./online-status.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OnlineStatusComponent implements OnInit {
    @Input()
    public deviceIds: string[] = [];

    @Input()
    public indicatorOnly?: boolean;

    public isOnline$: Observable<boolean>;

    constructor(private firebaseService: FirebaseService) {}

    ngOnInit(): void {
        this.isOnline$ = this.firebaseService.isOnline(this.deviceIds);
    }

    getIndicatorClass(online: boolean): string {
        return `online-status__indicator--${online ? 'online' : 'offline'}`;
    }
}
