import { Pipe, PipeTransform } from '@angular/core';
import { ImageService } from '@common/services/image/image.service';

@Pipe({
    name: 'imageDimensions'
})
export class ImageDimensionsPipe implements PipeTransform {
    constructor(private image: ImageService) {}

    transform(input: string, width: number, height?: number): string {
        if (!width) {
            return input;
        }

        if (!input) {
            return input;
        }

        const imageSrc = this.image.applyDimensions(input, width, height ?? width);
        return imageSrc;
    }
}
