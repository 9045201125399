<div class="business-card-details__container">
    <p *ngIf="canDisplayField('phone') && person.phone" class="business-card-details__phone">
        {{ person.phone }}
    </p>

    <p *ngIf="canDisplayField('email') && person.email" class="business-card-details__email">
        {{ person.email }}
    </p>

    <p *ngIf="canDisplayField('website') && person.website" class="business-card-details__website">
        {{ person.website | readableUrl }}
    </p>

    <p *ngIf="canDisplayField('address') && person.address" class="business-card-details__address">
        {{ person.address }}
    </p>

    <p *ngIf="canDisplayField('location') && person.location" class="business-card-details__location">
        {{ person.location }}
    </p>
</div>
