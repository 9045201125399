<ng-container
    *ngIf="{
        shouldShowCustomCssSwitch: shouldShowCustomCssSwitch$ | async,
        customCssEnabled: customCssEnabled$ | async
    } as data"
>
    <ion-item
        *ngIf="data.shouldShowCustomCssSwitch"
        button
        lines="none"
        class="custom-css-switch"
        color="sidebar"
        (click)="toggleCustomCss()"
    >
        <ng-container *ngIf="data.customCssEnabled; else showCustomCss">
            <i slot="start" aria-hidden="true" class="material-icons">style</i>

            <ion-label>
                {{ 'APP_SIDEBAR_RIGHT_CUSTOM_CSS_HIDE' | translate }}
            </ion-label>
        </ng-container>

        <ng-template #showCustomCss>
            <i slot="start" aria-hidden="true" class="material-icons-outlined">style</i>

            <ion-label>
                {{ 'APP_SIDEBAR_RIGHT_CUSTOM_CSS_SHOW' | translate }}
            </ion-label>
        </ng-template>
    </ion-item>
</ng-container>
