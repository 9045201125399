import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ExpandedPeople } from '@features/people/models/expanded-people.model';
import { PersonActionService } from '@features/people/services/person-action/person-action.service';
import { People } from '@shared/api';
import { Observable } from 'rxjs';

@Component({
    selector: 'cc-person-footer',
    templateUrl: './person-footer.component.html',
    styleUrls: ['./person-footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PersonFooterComponent implements OnInit {
    @Input()
    public person: People | ExpandedPeople;

    public canChat$: Observable<boolean>;
    public canVideoCall$: Observable<boolean>;
    public canUseMeetingBooking$: Observable<boolean>;

    constructor(private personActionService: PersonActionService) {}

    public ngOnInit(): void {
        this.canChat$ = this.personActionService.canChat(this.person);
        this.canVideoCall$ = this.personActionService.canVideoCall(this.person);
        this.canUseMeetingBooking$ = this.personActionService.canUseMeetingBooking(this.person);
    }

    public onChat(): void {
        this.personActionService.chat(this.person);
    }

    public onVideoCall(): void {
        this.personActionService.videoCall(this.person);
    }

    public onScheduleMeeting(): void {
        this.personActionService.scheduleMeeting(this.person);
    }
}
