import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MableCustomValue, People, SocialInfo } from '@shared/api';
import { Observable } from 'rxjs';
import { ApiSettings } from '../../../shared/settings/api-settings.constant';
import { UserProfileEndpointUrls } from '../constants/user-profile-endpoint-urls.constant';

@Injectable({
    providedIn: 'root'
})
export class UserProfileApiService {
    constructor(private httpClient: HttpClient) {}

    public patchUserProfile(appName: string, person: Partial<People>): Observable<People> {
        return this.httpClient.patch<People>(ApiSettings.fullUrl(appName, UserProfileEndpointUrls.editProfile), person);
    }

    public createOrUpdateSocialInfo(appName: string, socialInfo: SocialInfo): Observable<any> {
        if (socialInfo.uuid) {
            return this.httpClient.patch(
                ApiSettings.fullUrl(appName, UserProfileEndpointUrls.socialInfoById(socialInfo.uuid)),
                socialInfo
            );
        } else {
            return this.httpClient.post(ApiSettings.fullUrl(appName, UserProfileEndpointUrls.socialInfos), socialInfo);
        }
    }

    public deleteSocialInfo(appName: string, socialInfo: SocialInfo): Observable<any> {
        return this.httpClient.delete(
            ApiSettings.fullUrl(appName, UserProfileEndpointUrls.socialInfoById(socialInfo.uuid))
        );
    }

    public createOrUpdateCustomValue(appName: string, customValue: MableCustomValue): Observable<MableCustomValue> {
        if (customValue.uuid) {
            return this.httpClient.patch<MableCustomValue>(
                ApiSettings.fullUrl(appName, UserProfileEndpointUrls.customValueById(customValue.uuid)),
                customValue
            );
        } else {
            return this.httpClient.post<MableCustomValue>(
                ApiSettings.fullUrl(appName, UserProfileEndpointUrls.customValues),
                customValue
            );
        }
    }

    public getSocialInfo(appName: string): Observable<SocialInfo[]> {
        const url = ApiSettings.fullUrl(appName, UserProfileEndpointUrls.userSocialInfo);
        return this.httpClient.get<SocialInfo[]>(url);
    }
}
