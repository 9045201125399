<div class="challenge-completed-modal__container">
    <ng-lottie class="challenge-completed-modal__animation" [options]="trophyAnimationOptions"></ng-lottie>

    <h2 class="challenge-completed-modal__title">
        {{ 'GAMIFICATION_CHALLENGE_COMPLETED_MODAL_TITLE' | translate }}
    </h2>

    <p class="challenge-completed-modal__message">
        {{ 'GAMIFICATION_CHALLENGE_COMPLETED_MODAL_MESSAGE' | translate: { points: completion.points_scored } }}
    </p>
</div>
