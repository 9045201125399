export interface Module {
    id?: number;
    app: string;
    type: Module.TypeEnum;
    name?: string;
    /**
     * This text should be the class definition of a font awesome icon http://fortawesome.github.io/Font-Awesome/examples/
     */
    icon_text?: string;
    icon_img?: string;
    icon_toggle?: Module.IconToggleEnum;
    order?: number;
    import_overwrite?: boolean;
    is_visible?: boolean;
    is_active?: boolean;
    default_view?: Module.DefaultViewEnum;
    allow_banner_ads?: boolean;
}

export namespace Module {
    export enum TypeEnum {
        Agenda = 'agenda',
        Document = 'document',
        Feed = 'feed',
        Map = 'map',
        Directlink = 'directlink',
        Html = 'html',
        Infobooth = 'infobooth',
        Widget = 'widget',
        Survey = 'survey',
        People = 'people',
        Companies = 'companies',
        Livepolling = 'livepolling',
        Socialmedia = 'socialmedia',
        Qanda = 'qanda',
        Challenge = 'challenge',
        Iframe = 'iframe',
        Portal = 'portal'
    }
    export enum IconToggleEnum {
        None = 'none',
        Text = 'text',
        Img = 'img'
    }
    export enum DefaultViewEnum {
        List = 'list',
        Grid = 'grid'
    }
}
