import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@core/window.service';

@Injectable({
    providedIn: 'root'
})
export class PwaBadgeService {
    constructor(@Inject(WINDOW) private window: Window) {}

    async setPwaBadge(badgeNumber: number): Promise<void> {
        if ('setAppBadge' in this.window.navigator) {
            return badgeNumber <= 0
                ? this.window.navigator.clearAppBadge()
                : this.window.navigator.setAppBadge(badgeNumber);
        }
    }
}
