export interface Observer {
    id?: number;
    app?: string;
    event_type: Observer.EventTypeEnum;
    value?: string;
    repeatable?: boolean;
    actions: Array<number>;
}

export namespace Observer {
    export enum EventTypeEnum {
        CHOICEPOLLRESPONSE = 'CHOICE_POLL_RESPONSE',
        TEXTPOLLRESPONSE = 'TEXT_POLL_RESPONSE',
        RATINGPOLLRESPONSE = 'RATING_POLL_RESPONSE',
        POLLRESPONSE = 'POLL_RESPONSE',
        PROFILEPICTUREUPLOADED = 'PROFILE_PICTURE_UPLOADED'
    }
}
