import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import URI from 'urijs';
import { ImageOrientation } from './enums/image-orientation.enum';
import { ImageDimension, ImageDetail } from './interfaces/image-dimension.interface';

@Injectable({
    providedIn: 'root'
})
export class ImageService {
    constructor(private translateService: TranslateService) {}

    public applyDimensions(url: string, widthPx: number, heightPx: number): string {
        if (!url) {
            return '';
        }

        if (!url.includes(environment.CC_MEDIA_URL)) {
            return url;
        }

        if (!widthPx && !heightPx) {
            return url;
        }

        return URI(url)
            .search({ d: `${widthPx}x${heightPx}` })
            .readable();
    }

    public async getImageDetail(imageUrl: string): Promise<ImageDetail> {
        const dimension = await this.getImageDimension(imageUrl);
        return { ...dimension, orientation: this.calculateImageOrientation(dimension), source: imageUrl };
    }

    public getImageDimension(imageUrl: string): Promise<ImageDimension> {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = imageUrl;
            img.onload = () => resolve({ width: img.width, height: img.height });
            img.onerror = () => reject(this.translateService.instant('IMAGE_SERVICE_FAILED_TO_LOAD'));
        });
    }

    public calculateImageOrientation({ width: imageWidth, height: imageHeight }: ImageDimension): ImageOrientation {
        let orientation = ImageOrientation.Square;
        if (imageHeight > imageWidth) {
            orientation = ImageOrientation.Portrait;
        } else if (imageWidth > imageHeight) {
            orientation = ImageOrientation.Landscape;
        }
        return orientation;
    }
}
