import { AppState } from '../../../root-store/store/app-state.model';
import { Module } from '@shared/api';
import { RightSidebarItem } from '@core/navigation/services/sidebar-right-items.service';

export const navigationStoreKey = 'navigation';

export interface NavigationState {
    initialising: boolean;
    modules: Module[];
    rightSidebarItems: RightSidebarItem[];
    leftSidebarCollapsed: boolean;
}

export interface NavigationAppState extends AppState {
    [navigationStoreKey]: NavigationState;
}
