import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PeopleListActions } from '../actions/people-list.actions';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { PeopleApiService } from '../../services/people-api.service';
import { forkJoin, of } from 'rxjs';
import { PeopleApiActions } from '../actions/people-api.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { PeopleFacadeService } from '../../services/people-facade.service';
import { AnalyticsOptionsService } from '@core/analytics/services/analytics-options.service';
import { AnalyticsService } from '@core/analytics/services/analytics.service';
import { PeopleAnalyticsKeys } from '../../constants/people-analytics-keys.constant';
import { AppRoutingActions } from '@core/root-store/store/app-routing/actions/app-routing.actions';
import { AppPageRoutes } from '@core/routing/constants/app-page-routes.constant';
import { TagFilterMode } from '@shared/tags/enums/tag-filter-mode.enum';
import { PeopleFilterActions } from '../actions/people-filter.actions';

@Injectable()
export class PeopleListEffects {
    fetchInitialPeopleData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PeopleListActions.enterPage, PeopleListActions.submitSearch, PeopleFilterActions.filterResults),
            withLatestFrom(
                this.appCoreFacadeService.getAppName(),
                this.peopleFacadeService.getListPagination(),
                this.peopleFacadeService.getListModuleId(),
                this.peopleFacadeService.getAppliedFilters(),
                this.peopleFacadeService.getFilterMode()
            ),
            switchMap(([, appUrl, { search, page, limit }, moduleId, filters, mode]) =>
                forkJoin([
                    this.peopleApiService.getPeopleModule(appUrl, moduleId),
                    this.peopleApiService.getPaginatedPeople(appUrl, moduleId, search, page, limit, {
                        additive: mode === TagFilterMode.Additive,
                        tags: filters?.map((tag) => tag.id) ?? []
                    })
                ]).pipe(
                    map(([module, paginatedResponse]) =>
                        PeopleApiActions.fetchListSuccess({
                            module,
                            paginatedResponse
                        })
                    ),
                    catchError((error: HttpErrorResponse) => of(PeopleApiActions.fetchListFailure({ error })))
                )
            )
        )
    );

    fetchMorePages$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PeopleListActions.scrollTo),
            withLatestFrom(
                this.appCoreFacadeService.getAppName(),
                this.peopleFacadeService.getListPagination(),
                this.peopleFacadeService.getListModuleId(),
                this.peopleFacadeService.getAppliedFilters(),
                this.peopleFacadeService.getFilterMode()
            ),
            switchMap(([{ onComplete }, appUrl, { page, limit, search }, moduleId, filters, mode]) => {
                return this.peopleApiService
                    .getPaginatedPeople(appUrl, moduleId, search, page, limit, {
                        additive: mode === TagFilterMode.Additive,
                        tags: filters?.map((tag) => tag.id) ?? []
                    })
                    .pipe(
                        tap(() => onComplete()),
                        map((paginatedResponse) =>
                            PeopleApiActions.fetchPaginationSuccess({
                                paginatedResponse,
                                page
                            })
                        ),
                        catchError((error: HttpErrorResponse) => of(PeopleApiActions.fetchPaginationFailure({ error })))
                    );
            })
        )
    );

    navigateToDetail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PeopleListActions.selectPerson),
            tap(({ personId }) => {
                const options = this.analyticsOptionsService.clickItem(PeopleAnalyticsKeys.peopleListItem, personId);
                this.analyticsService.emit(options);
            }),
            map(({ personId }) => AppRoutingActions.goToAppPage({ urlSegments: [AppPageRoutes.person, personId] }))
        )
    );

    constructor(
        private actions$: Actions,
        private appCoreFacadeService: AppCoreFacadeService,
        private peopleApiService: PeopleApiService,
        private peopleFacadeService: PeopleFacadeService,
        private analyticsOptionsService: AnalyticsOptionsService,
        private analyticsService: AnalyticsService
    ) {}
}
