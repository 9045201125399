import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NotificationType } from '@capacitor/haptics';
import { SoundEffects } from '@common/constants/sound-effects.enum';
import { SoundService } from '@common/services/sound/sound.service';
import { CapacitorService } from '@core/native/services/capacitor.service';
import { ModalController } from '@ionic/angular';
import { ChallengeCompletion } from '@shared/api';
import { AnimationOptions } from 'ngx-lottie';
import { timer } from 'rxjs';

@Component({
    selector: 'cc-challenge-completed-modal',
    templateUrl: './challenge-completed-modal.component.html',
    styleUrl: './challenge-completed-modal.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChallengeCompletedModalComponent implements OnInit {
    @Input()
    public completion: ChallengeCompletion;

    public readonly trophyAnimationOptions: AnimationOptions = {
        autoplay: true,
        path: '/assets/lottie-animations/trophy.lottie.json',
        loop: false
    };

    constructor(
        private modalController: ModalController,
        private soundService: SoundService,
        private capacitorService: CapacitorService
    ) {}

    public ngOnInit(): void {
        timer(2500).subscribe(() => {
            this.modalController.dismiss();
        });

        this.soundService.play(SoundEffects.challengeCompleted, 0.2);

        if (this.capacitorService.isNative) {
            this.capacitorService.haptics.notification({ type: NotificationType.Success });
        }
    }
}
