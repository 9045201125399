import * as models from '@shared/api';

export interface CompaniesModule {
    id?: number;
    companies?: Array<number>;
    company_groups?: Array<string>;
    company_ordering?: models.ContentOrderingSettings;
    companygroup_ordering?: models.ContentOrderingSettings;
    view_type?: string;
    uuid?: string;
    order_toggle?: CompaniesModule.OrderToggleEnum;
    company_display?: CompaniesModule.CompanyDisplayEnum;
    view_by?: CompaniesModule.ViewByEnum;
    module: number;
}

export namespace CompaniesModule {
    export enum OrderToggleEnum {
        Alpha = 'alpha',
        ManualCompanygroups = 'manual_companygroups',
        ManualCompany = 'manual_company'
    }
    export enum CompanyDisplayEnum {
        All = 'all',
        Groups = 'groups',
        Company = 'company'
    }
    export enum ViewByEnum {
        Companies = 'companies',
        Groups = 'groups'
    }
}
