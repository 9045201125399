import { Injectable } from '@angular/core';
import { AppCoreEndpointUrls } from '../constants/app-core-endpoint-urls';
import { App } from '@shared/api';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiSettings } from '@shared/settings/api-settings.constant';

@Injectable({
    providedIn: 'root'
})
export class AppCoreApiService {
    constructor(private http: HttpClient) {}

    public getAppSettings(appName: string): Observable<App> {
        const url = ApiSettings.fullUrl(appName, AppCoreEndpointUrls.appSettings);
        return this.http.get<App>(url);
    }
}
