import { PaginationState } from '@shared/pagination/models/pagination-state.model';
import URI from 'urijs';

export const PeopleEndpoints = {
    autocompletePeople: () => '/peoples/autocomplete/',
    paginatedPeople: (query: PaginationState) => {
        const [page, limit, search] = [query.page ?? 1, query.limit ?? 10, query.search ?? ''];

        return URI('/peoples/table/')
            .search({
                search,
                offset: ((page - 1) * limit).toString(),
                limit: limit.toString()
            })
            .readable();
    },
    uncachedPaginatedPeople: () => '/peoples/uncached-table/',
    person: (personId: number | string) => `/peoples/${personId}/`,
    personPublicBusinessCard: (personId: number | string) => `/peoples/${personId}/business-card-public/`,
    personExchangeBusinessCard: (personId: number | string) => `/peoples/${personId}/exchange-cards/`,
    inviteToCall: (appUrl: string, personId: number, apiUrl: string) =>
        `${apiUrl}apps/${appUrl}/peoples/${personId}/invite-to-call/`,
    expandedPerson: (personId: number) => `/peoples/${personId}/?expand=~all`,
    visibility: '/peoplevisibility/',
    socialInfo: (personId: number) => `/peoples/${personId}/social-media/`,
    peopleModule: (moduleId) => `/modules/${moduleId}/people/`,
    table: (moduleId: number, search: string, page: number, limit: number) => {
        return URI(`/modules/${moduleId}/people/peoples/table/`)
            .search({
                search,
                offset: (page * limit).toString(),
                limit: limit.toString(),
                fields: peopleListFields
            })
            .readable();
    },
    peopleSessions: (personId: number) => `/peoples/${personId}/sessions/`,
    peopleTags: () => `/peoples-tags/`
};

const peopleListFields = [
    'last_name',
    'company',
    'title',
    'device_ids',
    'id',
    'job_title',
    'app_picture',
    'first_name',
    'pronouns',
    'tags',
    'user_tags'
];
