import { Pipe, PipeTransform } from '@angular/core';
import { containsOnlyEmojis } from '@shared/emoji/functions/contains-only-emojis/contains-only-emojis.function';

@Pipe({
    name: 'isOnlyEmoji',
    standalone: true
})
export class IsOnlyEmojiPipe implements PipeTransform {
    transform(value: string): boolean {
        return containsOnlyEmojis(value);
    }
}
