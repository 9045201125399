import { NgModule } from '@angular/core';
import { CustomFieldsComponent } from '@shared/custom-fields/components/custom-fields/custom-fields.component';
import { HtmlModule } from '@features/html/html.module';
import { AppCommonModule } from '@common/common.module';

@NgModule({
    declarations: [CustomFieldsComponent],
    exports: [CustomFieldsComponent],
    imports: [HtmlModule, AppCommonModule]
})
export class CustomFieldsModule {}
