import * as models from '@shared/api';
import { FeedModule } from '@shared/api';

export interface Session {
    privacy?: Session.PrivacyEnum;
    allowed_peoplegroups?: Array<number>;
    id?: number;
    documents?: Array<number>;
    location?: Array<number>;
    title?: string;
    subtitle?: string;
    tile_image?: string;
    questions?: boolean;
    /**
     * People allowed to view this session
     */
    allowed_people?: Array<number>;
    speaker_ordering?: models.ContentOrderingSettings;
    google_calendar_event_id?: string;
    cover_image?: string;
    list_row_bk_colour?: string;
    cover_colour?: string;
    document_ordering?: models.ContentOrderingSettings;
    hide_start_time?: boolean;
    hide_end_time?: boolean;
    is_active?: boolean;
    tracks?: Array<number>;
    survey?: number;
    expand_show_documents?: boolean;
    expand_show_features?: boolean;
    expand_show_location?: boolean;
    expand_show_speakers?: boolean;
    expand_show_subtitle?: boolean;
    participant_groups: Array<models.SessionParticipantGroup>;
    qanda?: number;
    end: string;
    start: string;
    cover_toggle?: Session.CoverToggleEnum;
    question_moderation?: boolean;
    participant_group_ordering_settings?: models.ContentOrderingSettings;
    livepoll?: number;
    speakers?: Array<number>;
    list_row_text_colour?: string;
    description?: string;
    agenda: number;
    agenda_name: string;
    livestream: number;
    simulive_enabled: boolean;
    notes_enabled: boolean;
    chat_enabled: boolean;
    chat_images_enabled: boolean;
    video_call: string;
    video_call_display: Session.VideoCallDisplayEnum;
    video?: string;
    sponsor_banner_image?: string;
    hosts?: Array<number>;
    show_attendees?: boolean;
    schedule_icon?: string;
    add_to_schedule_text?: string;
    remove_from_schedule_text?: string;
    activity_feed: FeedModule['id'] | null;
}

export namespace Session {
    export enum PrivacyEnum {
        Public = 'public' as any,
        Anonymize = 'anonymize' as any,
        Moderated = 'moderated' as any
    }
    export enum CoverToggleEnum {
        CoverImage = 'cover_image' as any,
        CoverColour = 'cover_colour' as any,
        ImageUrl = 'image_url' as any,
        None = 'none' as any
    }
    export enum VideoCallDisplayEnum {
        Embedded = 'embedded',
        NewTab = 'new_tab'
    }
}
