import { Injectable } from '@angular/core';
import { StorageService } from '../../storage/services/storage.service';

@Injectable({
    providedIn: 'root'
})
export class I18nTokenService {
    constructor(private storageService: StorageService) {}

    set(app: string, locale: string): void {
        const key = this.getKey(app);

        this.storageService.put(key, locale);
    }

    get(app: string): string {
        const key = this.getKey(app);
        return this.storageService.get(key);
    }

    private getKey(app: string): string {
        return `language-${app}`;
    }
}
