import { createSelector } from '@ngrx/store';
import { surveysStoreKey, SurveysAppState } from '../models/survey-module-app-state.model';
import { PollGroup, Poll } from '@shared/api';
import { filterBySearchTerm } from '../../../../shared/utilities/sorting-utility.functions';
import { createPollItem } from '../../functions/surveys-shared.functions';
import { AppRoutingSelectors } from '@core/root-store/store/app-routing/selectors/app-routing.selectors';

const selectSurveysState = (state: SurveysAppState) => state[surveysStoreKey];

const selectFilteredPollGroups = (surveysModuleId) =>
    createSelector(selectSurveysState, (surveysState) => {
        const pollGroups = surveysState.pollGroups;
        const surveyModule = surveysState.surveyModules[surveysModuleId];

        if (!pollGroups || !surveyModule) {
            return undefined;
        }

        if (!pollGroups.length) {
            return [];
        }

        const searchTerm = surveysState.surveysListPageState ? surveysState.surveysListPageState.searchTerm : undefined;

        return filterBySearchTerm(pollGroups, searchTerm, ['title']);
    });

const selectFilteredPersonalPollGroups = (personId: number) =>
    createSelector(selectSurveysState, (surveysState) => {
        const pollGroups = surveysState.pollGroups;
        const pollResponses = surveysState.pollResponses;

        if (!pollGroups || !pollResponses) {
            return undefined;
        }

        const filterByPersonPredicate = (g: PollGroup) =>
            !g.livepollgroup &&
            pollResponses.filter((r) => r.person === personId).some((r) => (g.polls as number[]).includes(r.poll));

        const searchTerm = surveysState.personalSurveysListPageState
            ? surveysState.personalSurveysListPageState.searchTerm
            : undefined;

        let filteredGroups = filterBySearchTerm(pollGroups, searchTerm, ['title']);
        filteredGroups = filteredGroups.filter(filterByPersonPredicate);

        return filteredGroups;
    });

const selectPollResponses = createSelector(selectSurveysState, (surveysState) => surveysState.pollResponses);

const selectSurveysListPageState = createSelector(
    selectSurveysState,
    (surveysState) => surveysState.surveysListPageState
);

const selectPersonalSurveysListPageState = createSelector(
    selectSurveysState,
    (surveysState) => surveysState.personalSurveysListPageState
);

const selectSurveyPageState = createSelector(selectSurveysState, (surveysState) => surveysState.surveyPageState);

const selectCurrentPollItem = (pollGroupId: number) =>
    createSelector(selectSurveysState, (surveysState) => {
        if (!surveysState.surveyPageState || !surveysState.pollGroupDetails[pollGroupId]) {
            return undefined;
        }

        const poll = (surveysState.pollGroupDetails[pollGroupId].polls as Poll[]).find(
            (p) => p.id === surveysState.surveyPageState.currentPoll
        );

        if (!poll) {
            return undefined;
        }

        return createPollItem(poll, surveysState.pollResponses);
    });

const selectCurrentPollIntermission = (pollGroupId: number) =>
    createSelector(selectSurveysState, (surveysState) => {
        if (!surveysState.surveyPageState || !surveysState.pollGroupDetails[pollGroupId]) {
            return undefined;
        }

        return surveysState.pollGroupDetails[pollGroupId].intermissions.find(
            (p) => p.id === surveysState.surveyPageState.currentPollIntermission
        );
    });

const selectPollGroupDetail = (pollGroupId: number) =>
    createSelector(selectSurveysState, (surveysState) => surveysState.pollGroupDetails[pollGroupId]);

const selectSurveyCompletePageState = createSelector(
    selectSurveysState,
    (surveysState) => surveysState.surveyCompletePageState
);

const selectSurveyTitle = createSelector(
    selectSurveysState,
    AppRoutingSelectors.selectRouteParams,
    (state, routeParams) => {
        const pollGroupDetails = state.pollGroupDetails[parseInt(routeParams['pollGroupId'], 10)];
        return pollGroupDetails?.title;
    }
);

export const SurveysSelectors = {
    selectFilteredPollGroups,
    selectFilteredPersonalPollGroups,
    selectPollResponses,
    selectSurveysListPageState,
    selectPersonalSurveysListPageState,
    selectSurveyPageState,
    selectCurrentPollItem,
    selectCurrentPollIntermission,
    selectPollGroupDetail,
    selectSurveyCompletePageState,
    selectSurveyTitle
};
