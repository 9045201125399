<ng-container *ngIf="agendas && agendas.length">
    <ion-card *ngFor="let agenda of agendas">
        <ion-card-header>
            <ion-card-title class="bold">{{ agenda.module.name }}</ion-card-title>
        </ion-card-header>
        <ion-card-content class="person-agenda-session__container">
            <cc-schedule-list
                [personalAgendaSessions]="personalAgendaSessions"
                [sessions]="$any(agenda.sessions)"
                [enabledFeatures]="enabledFeatures"
                [showTicket]="false"
                [showDayDate]="true"
                (sessionClick)="onSessionClick($any($event))"
            >
            </cc-schedule-list>
        </ion-card-content>
    </ion-card>
</ng-container>
