import { NgModule } from '@angular/core';
import { AppCommonModule } from '@common/common.module';
import { DocumentsListComponent } from './components/documents-list/documents-list.component';

@NgModule({
    declarations: [DocumentsListComponent],
    imports: [AppCommonModule],
    exports: [DocumentsListComponent]
})
export class SharedDocumentsModule {}
