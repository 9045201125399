import { NgModule } from '@angular/core';
import { BannerAdComponent } from './components/banner-ad/banner-ad.component';
import { AppCommonModule } from '@common/common.module';

@NgModule({
    declarations: [BannerAdComponent],
    exports: [BannerAdComponent],
    imports: [AppCommonModule]
})
export class BannerAdModule {}
