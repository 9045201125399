<ng-container *ngIf="cookieConfig$ | async; let config">
    <ng-container *ngIf="!config.initialising && (!config.cookies_dismissed || config.showCustomisation)">
        <ng-container *ngIf="showCustomise$ | async; else showBasicCookiePanel">
            <cc-cookie-customisation-banner [cookieConfig]="cookieConfig$ | async"></cc-cookie-customisation-banner>
        </ng-container>
    </ng-container>
</ng-container>

<ng-template #showBasicCookiePanel>
    <div class="cookie-banner__container">
        <div class="cookie-banner__text">
            <div class="cookie-banner__content">
                <h5 class="cookie-banner__heading">{{ 'COOKIES_HEADER' | translate | uppercase }}</h5>
                <div>
                    <p class="cookie-banner__description">{{ 'COOKIES_DESCRIPTION' | translate }}</p>
                    <p class="cookie-banner__description">{{ 'COOKIES_DESCRIPTION_ACKNOWLEDGE' | translate }}</p>
                </div>
            </div>
        </div>

        <div class="cookie-banner__actions">
            <ion-button (click)="customiseCookies()">
                {{ 'COOKIES_CUSTOMISE' | translate }}
            </ion-button>
            <ion-button color="primary" (click)="acceptCookies()">
                {{ 'COOKIES_ACCEPT' | translate }}
            </ion-button>
        </div>
    </div>
</ng-template>
