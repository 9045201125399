import { ChangeDetectionStrategy, Component, HostBinding, HostListener, Input, OnChanges } from '@angular/core';
import { ActionHandlerService } from '@common/services/actions-handler/action-handler.service';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { Action } from '@shared/api';
import { AppActionApiService } from '@shared/shared-actions/services/app-action-api/app-action-api.service';
import { filter, Observable, of, switchMap, take } from 'rxjs';

@Component({
    selector: 'cc-open-direct-link',
    templateUrl: './open-direct-link.component.html',
    styleUrl: './open-direct-link.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpenDirectLinkComponent implements OnChanges {
    @Input()
    public actionId: number;

    @HostBinding('attr.role')
    public role = 'button';

    @HostBinding('attr.tabindex')
    public tabIndex = 0;

    @HostListener('keyup.enter')
    @HostListener('click')
    public openModule(): void {
        if (!this.actionId) {
            return;
        }

        this.action$.pipe(filter(Boolean), take(1)).subscribe((action) => {
            this.actionHandlerService.handleAction(action);
        });
    }

    private action$: Observable<Action | null>;

    constructor(
        private actionHandlerService: ActionHandlerService,
        private appActionApiService: AppActionApiService,
        private appCoreFacadeService: AppCoreFacadeService
    ) {}

    public ngOnChanges(): void {
        if (!this.actionId) {
            this.action$ = of(null);
            return;
        }

        this.action$ = this.appCoreFacadeService
            .getAppName()
            .pipe(switchMap((appName: string) => this.appActionApiService.fetchAction(appName, this.actionId)));
    }
}
