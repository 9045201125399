import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { VideoCallEffects } from './effects/video-call.effects';
import { VideoCallApiEffects } from './effects/video-call-api.effects';
import { StoreModule } from '@ngrx/store';
import { videoCallReducer } from './reducers/video-call.reducer';
import { videoCallStoreKey } from './models/video-call.state';

@NgModule({
    imports: [
        StoreModule.forFeature(videoCallStoreKey, videoCallReducer),
        EffectsModule.forFeature([VideoCallEffects, VideoCallApiEffects])
    ]
})
export class VideoStoreModule {}
