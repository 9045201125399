import { Action, createReducer, on } from '@ngrx/store';
import { omit } from 'lodash';
import { AuthInterceptorActions } from '../actions/auth-interceptor.actions';
import { AuthenticationApiActions } from '../actions/authentication-api.actions';
import { AuthenticationActions } from '../actions/authentication.actions';
import { AuthenticationState } from '../models/authentication-state.model';

export const initialState: AuthenticationState = {
    passcodeTokens: {},
    anonymousToken: undefined,
    userEmailInfo: undefined,
    userAuthInfo: undefined,
    redirectUrl: undefined,
    authenticatedPerson: undefined,
    providers: [],
    hasDismissedMfa: undefined,
    otpSignature: undefined,
    ssoHasSettled: false,
    customFieldValues: []
};

const authenticationReducerFn = createReducer(
    initialState,
    on(
        AuthenticationApiActions.initialiseAuthenticationFailure,
        AuthenticationActions.logoutSuccess,
        AuthInterceptorActions.refreshTokenFailure,
        (state) => ({
            ...state,
            userAuthInfo: undefined,
            authenticatedPerson: undefined,
            userEmailInfo: undefined,
            customFieldValues: []
        })
    ),
    on(
        AuthenticationActions.initialisePersistedAuthDataSuccess,
        (state, { userAuthInfo, passcodeTokens, anonymousToken, hasDismissedMfa, otpSignature }) => ({
            ...state,
            userAuthInfo,
            passcodeTokens: { ...state.passcodeTokens, ...passcodeTokens },
            anonymousToken,
            hasDismissedMfa,
            otpSignature
        })
    ),
    on(AuthenticationApiActions.setUserCustomFieldValues, (state, { customFieldValues }) => ({
        ...state,
        customFieldValues
    })),
    on(
        AuthenticationApiActions.initialiseAuthenticationSuccess,
        AuthenticationApiActions.refreshAuthenticatedPersonSuccess,
        AuthenticationApiActions.getAuthenticatedPersonSuccess,
        (state, { authenticatedPerson }) => ({
            ...state,
            authenticatedPerson
        })
    ),
    on(
        AuthenticationApiActions.loginGetTokenSuccess,
        AuthenticationActions.setUserAuthInfo,
        (state, { userAuthInfo }) => ({
            ...state,
            userAuthInfo
        })
    ),
    on(AuthInterceptorActions.refreshTokenSuccess, (state, { basicAuth }) => ({
        ...state,
        userAuthInfo: {
            ...state.userAuthInfo,
            auth: {
                ...state.userAuthInfo.auth,
                ...basicAuth
            }
        }
    })),
    on(AuthenticationActions.mfaDismissPrompt, (state) => ({
        ...state,
        hasDismissedMfa: true
    })),
    on(
        AuthenticationApiActions.mfaCalibrateSuccess,
        AuthenticationApiActions.mfaValidateSuccess,
        AuthenticationApiActions.manualMfaCalibrateSuccess,
        (state, { otpSignature }) => ({
            ...state,
            userAuthInfo: {
                ...state.userAuthInfo,
                meta: {
                    ...state.userAuthInfo.meta,
                    mfa_required: false,
                    has_mfa_device: true
                }
            },
            otpSignature
        })
    ),
    on(AuthenticationActions.mfaSetOtpSignature, (state, { otpSignature }) => ({
        ...state,
        otpSignature
    })),
    on(AuthenticationActions.setAnonymousToken, (state, { anonymousToken }) => ({
        ...state,
        anonymousToken
    })),
    on(
        AuthenticationActions.setPasscodeToken,
        AuthenticationApiActions.submitPasscodeSuccess,
        (state, { appName }) => ({
            ...state,
            passcodeTokens: { ...state.passcodeTokens, [appName]: true }
        })
    ),
    on(AuthenticationActions.clearPasscodeToken, (state, { appName }) => ({
        ...state,
        passcodeTokens: omit(state.passcodeTokens, appName)
    })),
    on(AuthenticationActions.setRedirectUrl, (state, { redirectUrl }) => ({
        ...state,
        redirectUrl
    })),
    on(AuthenticationApiActions.getDownloadTokenSuccess, (state, { token, expires }) => ({
        ...state,
        userAuthInfo: {
            ...state.userAuthInfo,
            auth: {
                ...state.userAuthInfo.auth,
                download_token: token,
                download_token_expiry: expires
            }
        }
    })),
    on(AuthenticationApiActions.checkEmailAddressSuccess, (state, { userEmailInfo }) => ({
        ...state,
        userEmailInfo
    })),
    on(AuthenticationApiActions.getAppIdentityProvidersSuccess, (state, { providers }) => ({
        ...state,
        providers
    })),
    on(AuthenticationApiActions.exchangeAuthTokenSuccess, (state, { userAuthInfo, otpSignature }) => {
        return {
            ...state,
            userAuthInfo,
            otpSignature,
            hasDismissedMfa: true // To skip prompting for MFA when logging in via SSO.
        };
    }),
    on(AuthenticationActions.ssoHasSettled, (state) => ({
        ...state,
        ssoHasSettled: true
    }))
);

export function authenticationReducer(state: AuthenticationState | undefined, action: Action): AuthenticationState {
    return authenticationReducerFn(state, action);
}
