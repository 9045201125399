import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ToolbarLeftType } from '../../enums/toolbar-left-type.enum';

@Component({
    selector: 'cc-toolbar-left',
    templateUrl: './toolbar-left.component.html',
    styleUrls: ['./toolbar-left.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarLeftComponent {
    @Input()
    type: ToolbarLeftType;

    @Input()
    showMenuButton: boolean;

    @Input()
    canGoBack: boolean;

    @Output()
    menuButtonClick = new EventEmitter<void>();

    @Output()
    backClick = new EventEmitter<void>();

    typeEnum = ToolbarLeftType;

    onMenuButtonClick(): void {
        this.menuButtonClick.emit();
    }

    onBackClick(): void {
        this.backClick.emit();
    }
}
