<div
    *ngIf="{
        loggedInUser: (loggedInUser$ | async),
        sidebarItems: (sidebarItems$ | async),
        language: (language$ | async)
    } as pageData"
>
    <ion-header class="ion-no-border">
        <ion-toolbar class="sidebar-right-header" color="accent">
            <ion-buttons slot="start">
                <ion-button (click)="closeSidebar()" tabindex="-1" aria-hidden="true">
                    <i class="material-icons" tabindex="1" [attr.aria-label]="'APP_SIDEBAR_RIGHT_CLOSE' | translate"
                        >chevron_left</i
                    >
                </ion-button>
            </ion-buttons>
            <ion-title
                [attr.aria-label]="
                    'APP_SIDEBAR_RIGHT_LOGGED_IN_AS'
                        | translate
                            : {
                                  firstName: pageData.loggedInUser?.first_name,
                                  secondName: pageData.loggedInUser?.last_name,
                                  suffix: pageData.loggedInUser?.pronouns
                              }
                "
            >
                <cc-person-name [person]="pageData.loggedInUser" excludes="title pronouns"> </cc-person-name>
            </ion-title>
        </ion-toolbar>
    </ion-header>
    <nav role="list">
        <ng-container *ngFor="let item of pageData.sidebarItems">
            <ng-container *ngIf="!item.show || (item.show() | async)">
                <div [ngSwitch]="item.className">
                    <cc-language-selection
                        *ngSwitchCase="'language'"
                        class="sidebar-right-item cc-sidebar-right-language cc-primary-header"
                        [currentLanguage]="pageData.language"
                        [itemLabel]="item.translateKey | translate"
                        [languages]="languages"
                        (languageChange)="loadLanguage($event)"
                    ></cc-language-selection>
                    <ion-item
                        *ngSwitchDefault
                        button
                        (click)="item.onClick(); onItemClick()"
                        lines="none"
                        color="sidebar"
                        role="listitem"
                        class="sidebar-right-item"
                        [ngClass]="['cc-sidebar-right-' + item.className, 'cc-' + item.className + '-all']"
                    >
                        <i class="material-icons" slot="start" aria-hidden="true">{{ item.icon }}</i>
                        <ion-label class="cc-primary-header">
                            {{ item.translateKey | translate }}
                        </ion-label>
                    </ion-item>
                </div>
            </ng-container>
        </ng-container>

        <cc-custom-css-switch></cc-custom-css-switch>
    </nav>
</div>
