<div class="auto-scroll">
    <ion-content
        *ngIf="useIonContent"
        #ionContent
        ccIonicScrollbar
        ccResizeObserver
        class="auto-scroll__scroll"
        [scrollEvents]="true"
        (ionScroll)="onScroll()"
        (resize)="onResize()"
    >
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </ion-content>

    <div
        #scrollContainer
        (scroll)="onScroll()"
        *ngIf="!useIonContent"
        class="auto-scroll__scroll"
        [ngClass]="{ reversed: reversed }"
    >
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>

    <ion-fab-button
        class="auto-scroll__indicator"
        size="small"
        [ngClass]="{ 'auto-scroll__indicator--active': !scrollOnNewItems }"
        (click)="onScrollToBottomClicked()"
    >
        <i class="material-icons">expand_more</i>
    </ion-fab-button>
</div>

<ng-template #content>
    <ng-content></ng-content>
</ng-template>
