<div class="person-business-card-exchange__container">
    <ion-card class="person-business-card-exchange__card">
        <ion-card-header class="person-business-card-exchange__card-header">
            <ion-card-title class="person-business-card-exchange__card-title">
                {{ 'PEOPLE_BUSINESS_CARD_TITLE' | translate }}
            </ion-card-title>
        </ion-card-header>

        <ion-card-content class="person-business-card-exchange__content">
            <ng-container *ngIf="businessCard; else skeleton">
                <cc-business-card
                    class="person-business-card-exchange__card"
                    [design]="businessCard.design"
                    [person]="businessCard.profile"
                    [socialInfo]="businessCard.social"
                >
                </cc-business-card>
            </ng-container>

            <ng-template #skeleton>
                <cc-business-card-skeleton></cc-business-card-skeleton>
            </ng-template>

            <ion-button
                class="person-business-card-exchange__button"
                [disabled]="!businessCard"
                (click)="emitExchangeClicked()"
            >
                <i class="material-icons" slot="start">swap_horiz</i>
                <span>{{ 'PEOPLE_BUSINESS_CARD_EXCHANGE' | translate }}</span>
            </ion-button>
        </ion-card-content>
    </ion-card>
</div>
