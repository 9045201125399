import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree, CanActivateFn } from '@angular/router';
import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

/**
 * Ensures route guard run in order, see https://stackoverflow.com/questions/45618687/angular-2-router-guards-order
 */
@Injectable({
    providedIn: 'root'
})
export class CompositeRouteGuard {
    constructor(
        protected router: Router,
        protected injector: Injector
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        let compositeCanActivateObservable: Observable<boolean | UrlTree> = of(true);

        const routeGuards = route.data.routeGuards;

        if (routeGuards) {
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < routeGuards.length; i++) {
                const routeGuard = this.injector.get<{
                    canActivate: CanActivateFn;
                }>(routeGuards[i]);
                const canActivateObservable = routeGuard.canActivate(route, state) as Observable<boolean | UrlTree>;
                compositeCanActivateObservable = compositeCanActivateObservable.pipe(
                    mergeMap((bool) => {
                        if (!bool) {
                            return of(false);
                        }
                        return canActivateObservable;
                    })
                );
            }
        }

        return compositeCanActivateObservable;
    }
}
