import { Component } from '@angular/core';

@Component({
    selector: 'cc-app',
    template: `
        <ion-app class="new-app">
            <router-outlet></router-outlet>
        </ion-app>
    `
})
export class AppComponent {}
