import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { fadeIn } from '@common/animations/animations';
import { AnimationOptions } from 'ngx-lottie';

@Component({
    selector: 'cc-no-data',
    templateUrl: './no-data.component.html',
    styleUrls: ['./no-data.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [fadeIn(500)]
})
export class NoDataComponent {
    @Input()
    public message: string;

    @Input()
    public title: string;

    public readonly noDataAnimationOptions: AnimationOptions = {
        autoplay: true,
        path: '/assets/lottie-animations/no-data.lottie.json',
        loop: false
    };
}
