import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GenericSocialInfo } from '@shared/social-media/interfaces/generic-social-info.interface';

@Component({
    selector: 'cc-social-media',
    templateUrl: './social-media.component.html',
    styleUrls: ['./social-media.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SocialMediaComponent {
    public href: string;

    private _socialInfo: GenericSocialInfo;

    @Input()
    public set socialInfo(socialInfo: GenericSocialInfo) {
        this._socialInfo = socialInfo;
        this.href = this.buildHref(socialInfo);
    }

    public get socialInfo(): GenericSocialInfo {
        return this._socialInfo;
    }

    public buildHref(socialInfo: GenericSocialInfo): string {
        switch (socialInfo.site) {
            case 'twitter':
                // Build Twitter URL if only a username is given.
                return socialInfo.info.includes('twitter.com/')
                    ? socialInfo.info
                    : `https://twitter.com/${socialInfo.info}`;
            default:
                return socialInfo.info;
        }
    }
}
