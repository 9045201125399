@if (
    {
        state: state$ | async,
        loading: loading$ | async,
        submitting: submitting$ | async,
        isAuthenticated: isAuthenticated$ | async
    };
    as pageData
) {
    <div class="survey" [class.survey__container]="!hideCard" [class.hide-card]="hideCard">
        @if (pageData.loading) {
            <ng-container *ngTemplateOutlet="loading"></ng-container>
        } @else if (!pageData.isAuthenticated && pageData.state?.pollGroup?.login_required) {
            <div class="auth-required" translate>AGENDA_SESSION_FEEDBACK_TAB_AUTH_REQUIRED</div>
        } @else if (pageData.isAuthenticated || !pageData.state.pollGroup.login_required) {
            @if (pageData.state.pollGroup && !pageData.state.complete && pageData.state.totalCount > 1) {
                <cc-survey-header
                    [currentCount]="pageData.state.currentIndex + 1"
                    [totalCount]="pageData.state.totalCount"
                ></cc-survey-header>
            }

            @if (pageData.state.complete) {
                <cc-poll-intermission
                    navButtonIcon="chevron_right"
                    navButtonText="SURVEY_COMPLETE"
                    [message]="pageData.state.pollGroup.completion_message"
                    [showBackButton]="showCompleteButton"
                    [completed]="true"
                    (navButtonClick)="onComplete()"
                ></cc-poll-intermission>
            } @else {
                @if (pageData.state.currentPoll) {
                    <cc-poll
                        [pollItem]="pageData.state.currentPoll"
                        [choicePollChoiceFormItems]="pageData.state.pollChoiceItems"
                        [submitting]="pageData.submitting"
                        [closed]="false"
                        [initialValue]="pageData.state.pollInitialValue"
                        [showPreviousButton]="pageData.state.currentIndex > 0"
                        [showNextButton]="
                            !pageData.state.currentPoll?.poll?.required ||
                            (pageData.state.currentPoll?.poll?.required &&
                                pageData.state.currentPoll?.pollResponse?.response_obj?.response)
                        "
                        (pollNext)="onPollNext()"
                        (pollPrevious)="onPollPrev()"
                        (pollSubmit)="onPollSubmit($event)"
                    ></cc-poll>
                }

                @if (pageData.state.currentPollIntermission) {
                    <cc-poll-intermission
                        navButtonIcon="chevron_right"
                        navButtonText="CONTINUE"
                        [message]="pageData.state.currentPollIntermission.title"
                        (navButtonClick)="onPollNext()"
                    ></cc-poll-intermission>
                }
            }
        }
    </div>
}
<ng-template #loading>
    <cc-loading-spinner></cc-loading-spinner>
</ng-template>
