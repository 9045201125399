import { Injectable, inject } from '@angular/core';
import { QrScannerModalService } from '@shared/qr-scanner/services/qr-scanner-modal/qr-scanner-modal.service';
import { GlobalScannerCodeType } from '../../enums/global-scanner-code-type.enum';
import { Observable, catchError, filter, forkJoin, from, switchMap } from 'rxjs';
import { GlobalScannerQrData } from '../../interfaces/global-scanner-qr-data.interface';
import { GlobalScannerUserBusinessCardsService } from '../global-scanner-user-business-cards/global-scanner-user-business-cards.service';
import { GlobalScannerHandlerService } from '../../interfaces/global-scanner-handler-service.interface';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { GlobalScannerChallengesService } from '../global-scanner-challenges/global-scanner-challenges.service';

@Injectable({
    providedIn: 'root'
})
export class GlobalScannerService {
    private actionServices: Record<GlobalScannerCodeType, GlobalScannerHandlerService> = {
        [GlobalScannerCodeType.BusinessCard]: inject(GlobalScannerUserBusinessCardsService),
        [GlobalScannerCodeType.Challenge]: inject(GlobalScannerChallengesService)
    };

    constructor(
        private qrScannerModalService: QrScannerModalService,
        private alertController: AlertController,
        private translateService: TranslateService
    ) {}

    public openScanner(): Observable<unknown> {
        return from(this.qrScannerModalService.openQrScannerModal()).pipe(
            filter(Boolean),
            switchMap((result) => {
                const qrData = this.decodeQrCode(result);
                const normalisedQrData = this.normaliseQrData(qrData);
                const service = this.actionServices[normalisedQrData.type];

                return this.handleScan(service, normalisedQrData);
            })
        );
    }

    private decodeQrCode(encodedQr: string): string | GlobalScannerQrData {
        try {
            return JSON.parse(encodedQr);
        } catch (error) {
            return encodedQr;
        }
    }

    private normaliseQrData(qrData: string | GlobalScannerQrData): GlobalScannerQrData {
        if (typeof qrData === 'string') {
            return {
                type: GlobalScannerCodeType.BusinessCard,
                value: qrData
            };
        }

        return qrData;
    }

    private handleScan(service: GlobalScannerHandlerService, qrData: GlobalScannerQrData): Observable<unknown> {
        return service.canProcess().pipe(
            switchMap(({ processable, message }) => {
                if (!processable) {
                    return this.presentAccessRejectedDialog(message);
                }

                return service.processScan(qrData).pipe(
                    switchMap((data) => service.onSuccess(data)),
                    catchError((data) => service.onError(data))
                );
            })
        );
    }

    private presentAccessRejectedDialog(message?: string): Observable<unknown> {
        return forkJoin({
            header: this.translateService.get('GLOBAL_SCANNER_ACCESS_REJECTED_HEADER'),
            message: this.translateService.get(message ?? 'GLOBAL_SCANNER_ACCESS_REJECTED_MESSAGE'),
            button: this.translateService.get('GLOBAL_SCANNER_ACCESS_REJECTED_BUTTON')
        }).pipe(
            switchMap((translations) =>
                this.alertController.create({
                    header: translations.header,
                    message: translations.message,
                    buttons: [translations.button]
                })
            ),
            switchMap((alert) => alert.present())
        );
    }
}
