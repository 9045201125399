import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { People } from '@api';

@Component({
    selector: 'cc-chat-message',
    templateUrl: './chat-message.component.html',
    styleUrls: ['./chat-message.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatMessageComponent {
    @Input()
    public user: People;

    @Input()
    public message: string;

    @Input()
    public sentByPeer: boolean;
}
