import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ColorPalette } from '@core/branding/models/color-palette.model';
import { App } from '@shared/api';
import Color from 'color';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { Shade } from '../enums/shade.enum';
import { GoogleFontsService } from './google-fonts.service';

@Injectable({
    providedIn: 'root'
})
export class BrandingService {
    public contentOnPrimaryShade$: Observable<Shade>;
    public contentOnAccentShade$: Observable<Shade>;
    public contentOnSidebarShade$: Observable<Shade>;
    public customCssEnabled$: Observable<boolean>;

    public styleElement: HTMLStyleElement;
    public titleElement: HTMLTitleElement;
    public iconElements: HTMLLinkElement[];

    private contentOnPrimaryShadeSubject: ReplaySubject<Shade> = new ReplaySubject();
    private contentOnAccentShadeSubject: ReplaySubject<Shade> = new ReplaySubject();
    private contentOnSidebarShadeSubject: ReplaySubject<Shade> = new ReplaySubject();
    private customCssEnabledSubject: BehaviorSubject<boolean> = new BehaviorSubject(true);
    private loadedFontElement: HTMLLinkElement;

    constructor(
        @Inject(DOCUMENT)
        public document: Document,
        private googleFontsService: GoogleFontsService
    ) {
        this.contentOnPrimaryShade$ = this.contentOnPrimaryShadeSubject.asObservable();
        this.contentOnAccentShade$ = this.contentOnAccentShadeSubject.asObservable();
        this.contentOnSidebarShade$ = this.contentOnSidebarShadeSubject.asObservable();
        this.customCssEnabled$ = this.customCssEnabledSubject.asObservable();
    }

    public initialise(app: App): void {
        this.generateThemeColors(app);
        this.setCss(app.theme.app_css);
        this.setTitle(app.name);
        this.setIcon(app.theme.icon_image);
        this.setAppFont(app.theme.font_family);
    }

    public generatePalette(baseColor: string, contrastColor: string): ColorPalette {
        const base = Color(baseColor.toLowerCase());
        const contrast = Color(contrastColor.toLowerCase());
        return {
            base: base.hex(),
            baseRgb: base.rgb().array().join(),
            contrast: contrast.hex(),
            contrastRgb: contrast.rgb().array().join(),
            shade: base.darken(0.1).hex(),
            tint: base.lighten(0.3).hex()
        };
    }

    public generatePaletteCssProperties(customProperty: string, palette: ColorPalette): { [property: string]: string } {
        return {
            [customProperty]: palette.base,
            [`${customProperty}-rgb`]: palette.baseRgb,
            [`${customProperty}-contrast`]: palette.contrast,
            [`${customProperty}-contrast-rgb`]: palette.contrastRgb,
            [`${customProperty}-shade`]: palette.shade,
            [`${customProperty}-tint`]: palette.tint
        };
    }

    public toggleCustomCss(): void {
        this.styleElement.disabled = !this.styleElement.disabled;
        this.customCssEnabledSubject.next(!this.styleElement.disabled);
    }

    private generateThemeColors(app: App): void {
        if (!app) {
            return;
        }
        this.generatePalettes('--cc-primary-color', app.theme.primary_color, app.theme.text_on_primary_color);
        this.createCssProperty('--cc-text-on-primary-color', app.theme.text_on_primary_color);

        this.generatePalettes('--cc-accent-color', app.theme.accent_color, app.theme.text_on_accent_color);
        this.createCssProperty('--cc-text-on-accent-color', app.theme.text_on_accent_color);

        const sidebarColor = app.theme.sidebar_background_color || app.theme.primary_color;
        const sidebarTextColor = app.theme.sidebar_text_color || app.theme.text_on_primary_color;

        this.generatePalettes('--cc-sidebar-color', sidebarColor, sidebarTextColor);
        this.createCssProperty('--cc-text-on-sidebar-color', sidebarTextColor);

        this.generatePalettes('--cc-success-color', app.theme.success_color, app.theme.list_item_background_color);
        this.generatePalettes('--cc-info-color', app.theme.info_color, app.theme.list_item_background_color);

        this.generatePalettes('--cc-warn-color', app.theme.warn_color, app.theme.text_on_warn_color);
        this.createCssProperty('--cc-text-on-warn-color', app.theme.text_on_warn_color);

        this.createCssProperty('--cc-background-color', app.theme.background_color);
        this.createCssProperty('--cc-default-text-color', app.theme.default_text_color);
        this.createCssProperty('--cc-default-text-color-rgb', Color(app.theme.default_text_color).rgb().array().join());
        this.createCssProperty('--cc-link-color', app.theme.link_color);
        this.createCssProperty('--cc-card-background', app.theme.list_item_background_color);

        this.generateSteppedPalette(app.theme.list_item_background_color, app.theme.default_text_color);

        this.updateContentOnVariables(
            app.theme.text_on_primary_color,
            app.theme.text_on_accent_color,
            sidebarTextColor
        );
    }

    private generatePalettes(customProperty: string, appBaseColor: string, appContrastColor: string): any {
        const palette = this.generatePalette(appBaseColor, appContrastColor);
        const cssProperties = this.generatePaletteCssProperties(customProperty, palette);
        Object.entries(cssProperties).forEach(([property, value]) => this.createCssProperty(property, value));
    }

    // Generate 19 colors between the item color and the default text color, at varying levels of being mixed
    private generateSteppedPalette(background: string, defaultTextColor: string): void {
        const color = Color(background.toLowerCase());
        const defaultColor = Color(defaultTextColor.toLowerCase());

        const min = 0.4; // The mix of the lightest text color ie. --ion-color-step-50
        const max = 0.95; // The mix of the darkest text color ie. --ion-color-step-950
        const numberOfSteps = 19; // Decided by ionic
        const increment = (max - min) / (numberOfSteps - 1);

        for (let i = 0; i < numberOfSteps; i++) {
            const mixAmount = i * increment + min;
            const steppedColor = color.mix(defaultColor, mixAmount).hex();
            const step = i * 50 + 50; // Convert 0,1..18 into 50,100..950
            this.createCssProperty(`--ion-color-step-${step}`, steppedColor);
        }
    }

    private updateContentOnVariables(textOnPrimary: string, textOnAccent: string, textOnSidebar: string): void {
        const textOnPrimaryColor = Color(textOnPrimary.toLowerCase());
        this.contentOnPrimaryShadeSubject.next(this.determineShade(textOnPrimaryColor));

        const textOnAccentColor = Color(textOnAccent.toLowerCase());
        this.contentOnAccentShadeSubject.next(this.determineShade(textOnAccentColor));

        const textOnSidebarColor = Color(textOnSidebar.toLowerCase());
        this.contentOnSidebarShadeSubject.next(this.determineShade(textOnSidebarColor));
    }

    private determineShade(color: Color): Shade {
        return color.isDark() ? Shade.Dark : Shade.Light;
    }

    private createCssProperty(prop: string, value: string): void {
        this.document.documentElement.style.setProperty(prop, value);
    }

    private setCss(css: string): void {
        if (this.styleElement && this.styleElement.innerHTML === css) {
            return;
        }

        if (!this.styleElement) {
            this.createStyleElement();
        }

        if (!!css && css.search('script') !== -1) {
            const scriptRegex = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;

            css = css.replace(scriptRegex, '');
        }

        this.styleElement.innerHTML = css;
    }

    private createStyleElement(): HTMLStyleElement {
        this.styleElement = this.document.createElement('style');
        this.styleElement.className = 'cc-custom-css';

        this.document.head.append(this.styleElement);

        return this.styleElement;
    }

    private setTitle(title: string): void {
        if (this.titleElement && this.titleElement.innerHTML === title) {
            return;
        }
        if (!this.titleElement) {
            this.titleElement = this.createTitleElement();
        }

        this.titleElement.innerHTML = title;
    }

    private createTitleElement(): HTMLTitleElement {
        this.titleElement = this.document.createElement('title');

        this.document.head.append(this.titleElement);

        return this.titleElement;
    }

    private setIcon(url: string): void {
        if (!this.iconElements) {
            this.iconElements = this.createIconElements();
        }

        this.iconElements.forEach((element) => element.setAttribute('href', url));

        const linkIcon = document.querySelector('link[rel="icon"]');
        if (linkIcon) {
            (linkIcon as any).href = url;
        }
    }

    private createIconElements(): HTMLLinkElement[] {
        const elements = [this.document.createElement('link'), this.document.createElement('link')];

        elements[0].setAttribute('rel', 'apple-touch-icon');
        elements[1].setAttribute('rel', 'apple-touch-icon-precomposed');

        elements.forEach((element) => this.document.head.append(element));

        return elements;
    }

    private setAppFont(name: string | null): void {
        this.clearLoadedFonts();

        if (!name) {
            return;
        }

        this.createCssProperty('--font-family', name);

        this.importFont(name);
    }

    private importFont(name: string): void {
        const url = this.googleFontsService.getFontFamiliesStylesheetUrl(name);

        if (!url) {
            return;
        }

        const head = this.document.querySelector('head');
        const element = this.document.createElement('link');

        element.href = url;
        element.rel = 'stylesheet';

        head?.appendChild(element);
        this.loadedFontElement = element;
    }

    private clearLoadedFonts(): void {
        this.loadedFontElement?.remove();

        this.createCssProperty('--font-family', '');
    }
}
