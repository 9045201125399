import { Injectable } from '@angular/core';
import { AppNotification } from '@core/notifications/interfaces/notification.interface';
import { NotificationsSelectors } from '@core/notifications/store/selectors/notifications.selectors';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NotificationsFacadeService {
    constructor(private store: Store<any>) {}

    public getNotifications(): Observable<Array<AppNotification>> {
        return this.store.select(NotificationsSelectors.notifications);
    }

    public getNotification(id: string): Observable<AppNotification> {
        return this.store.select(NotificationsSelectors.notification(id));
    }

    public dispatch(action: Action): void {
        this.store.dispatch(action);
    }
}
