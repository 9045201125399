import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { AlertsFacadeService } from '@features/alerts/services/alerts-facade.service';
import { AlertsActions } from '@features/alerts/store/actions/alerts.actions';
import { ModalController } from '@ionic/angular';
import { AppAlert } from '@shared/api';

@Component({
    selector: 'cc-alert-popup-modal',
    templateUrl: './alert-popup-modal.component.html',
    styleUrls: ['./alert-popup-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertPopupModalComponent {
    @Input()
    public alert: AppAlert;

    constructor(
        private modalController: ModalController,
        private alertsFacadeService: AlertsFacadeService
    ) {}

    public dismiss(): void {
        this.alertsFacadeService.dispatch(AlertsActions.markAsRead({ alert: this.alert }));
        this.modalController.dismiss();
    }

    public pollComplete(): void {
        this.dismiss();
    }
}
