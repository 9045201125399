<div class="cookie-banner__container">
    <div class="cookie-banner__text">
        <div class="cookie-banner__content">
            <ion-label>
                <h5 class="cookie-banner__heading">{{ 'COOKIES_CUSTOMISE_HEADER' | translate | uppercase }}</h5>
                <p class="cookie-banner__description">{{ 'COOKIES_CUSTOMISE_DESCRIPTION' | translate }}</p>
                <a class="cookie-banner__description" (click)="showCookieNotice($event)">{{
                    'COOKIES_CUSTOMISE_DESCRIPTION2' | translate
                }}</a>
            </ion-label>
        </div>
    </div>

    <form class="toggle-list__container" *ngIf="cookieForm" [formGroup]="cookieForm">
        <div class="toggle-item">
            <div class="toggle-item__text">
                <ion-label>
                    <h2 class="toggle-item__title">{{ 'COOKIES_CUSTOMISE_ESSENTIAL_TITLE' | translate }}</h2>
                    <p class="toggle-item__description">{{ 'COOKIES_CUSTOMISE_ESSENTIAL_DESCRIPTION' | translate }}</p>
                </ion-label>
            </div>
        </div>

        <div class="toggle-item">
            <div class="toggle-item__text">
                <ion-label>
                    <h2 class="toggle-item__title">{{ 'COOKIES_CUSTOMISE_ANALYTICS_TITLE' | translate }}</h2>
                    <p class="toggle-item__description">
                        {{ 'COOKIES_CUSTOMISE_ANALYTICS_DESCRIPTION' | translate }}
                    </p>
                </ion-label>
            </div>
            <div class="toggle-item__toggle">
                <ion-toggle formControlName="allow_analytics" slot="end"></ion-toggle>
            </div>
        </div>
    </form>

    <div class="cookie-banner__actions">
        <ion-button color="primary" (click)="acceptCookies()">
            {{ 'COOKIES_ACCEPT' | translate }}
        </ion-button>
        <ion-button (click)="saveSelection()">
            {{ 'COOKIES_CUSTOMISE_SAVE_SELECTION' | translate }}
        </ion-button>
    </div>
</div>
