import { Directive, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fromIntersectionObserver, IntersectionStatus } from '@shared/utilities/from-intersection-observer';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

/*
 * From https://blog.bitsrc.io/angular-maximizing-performance-with-the-intersection-observer-api-23d81312f178
 */

@UntilDestroy()
@Directive({
    selector: '[ccIntersectionObserver]'
})
export class IntersectionObserverDirective implements OnInit {
    @Input()
    intersectionDebounce = 0;

    @Input()
    intersectionRootMargin = '0px';

    @Input()
    intersectionRoot: HTMLElement;

    @Input()
    intersectionThreshold: number | number[];

    @Output()
    visibilityChange = new EventEmitter<IntersectionStatus>();

    constructor(private element: ElementRef) {}

    ngOnInit(): void {
        const element = this.element.nativeElement;
        const config: IntersectionObserverInit = {
            root: this.intersectionRoot,
            rootMargin: this.intersectionRootMargin,
            threshold: this.intersectionThreshold
        };

        fromIntersectionObserver(element, config, this.intersectionDebounce)
            .pipe(untilDestroyed(this))
            .subscribe((status) => this.visibilityChange.emit(status));
    }
}
