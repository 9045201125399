import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AnalyticsService } from '@core/analytics/services/analytics.service';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { AuthenticationFacadeService } from '@core/authentication/services/authentication-facade.service';
import { BannerFacadeService } from '@core/banner/services/banner-facade/banner-facade.service';
import { BannerActions } from '@core/banner/store/actions/banner.actions';
import { AuthenticationActions } from '@core/root-store/store/authentication/actions/authentication.actions';
import { TagType } from '@core/tag-management/enums/tag-type.enum';
import { TagEntity } from '@core/tag-management/models/tag-entity.interface';
import { TagManagerApiService } from '@core/tag-management/services/tag-manager-api.service';
import { TagManagerService } from '@core/tag-management/services/tag-manager.service';
import { WINDOW } from '@core/window.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { devDebug } from '@shared/rxjs/custom.rxjs';
import { of } from 'rxjs';
import { catchError, exhaustMap, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { TagManagerActions } from '../actions/tag-manager.actions';
import { TagManagerFacadeService } from '../services/tag-manager-facade.service';
import { App } from '@shared/api';

@Injectable()
export class TagManagerEffects {
    canInitialiseTrackers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                BannerActions.cookiesDismissed,
                BannerActions.fetchCookiesConfigurationSuccess,
                AuthenticationActions.completed
            ),
            withLatestFrom(this.bannerFacadeService.getCookieConfig()),
            filter(([_, cookieConsent]) => cookieConsent.cookies_dismissed && cookieConsent.allow_analytics),
            map(() => TagManagerActions.initialiseTrackers())
        )
    );

    canRemoveTrackers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BannerActions.cookiesDismissed),
            withLatestFrom(this.bannerFacadeService.getCookieConfig()),
            filter(([_, cookieConsent]) => !cookieConsent.allow_analytics),
            map(() => TagManagerActions.removeTrackers())
        )
    );

    startAnalyticsService$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TagManagerActions.loadTrackingScriptsSuccess),
            map((_) => {
                this.analyticsService.initialise();
                return TagManagerActions.initialiseAnalyticsSuccess();
            })
        )
    );

    retrieveTags$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TagManagerActions.initialiseTrackers),
            withLatestFrom(
                this.appCoreFacadeService.getAppName(),
                this.authenticationFacadeService.hasAppAccessAndAuthenticated(),
                this.appCoreFacadeService.getAppSettings()
            ),
            switchMap(([_, app, authenticated, appSettings]) => {
                if (!authenticated && appSettings.privacy !== App.PrivacyEnum.Public) {
                    return of(TagManagerActions.fetchTagsSuccess({ tags: [] }));
                }
                return this.tagManagerApiService.fetchTags(app).pipe(
                    map((tags: TagEntity[]) => TagManagerActions.fetchTagsSuccess({ tags })),
                    catchError((error: HttpErrorResponse) => of(TagManagerActions.fetchTagsFailure({ error })))
                );
            })
        )
    );

    determineTrackers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TagManagerActions.fetchTagsSuccess),
            withLatestFrom(this.tagManagerFacadeService.selectTags()),
            map(([_, tags]) => {
                const distinctTrackers: Set<TagType> = new Set(tags.map((tag: TagEntity) => tag.tag_type));
                return [...distinctTrackers];
            }),
            map((trackersToLoad) => TagManagerActions.loadTrackingScripts({ types: trackersToLoad }))
        )
    );

    loadScripts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TagManagerActions.loadTrackingScripts),
            withLatestFrom(this.tagManagerFacadeService.selectTags()),
            exhaustMap(([action, tags]) => this.tagManagerService.loadTags(action.types, tags)),
            map(() => TagManagerActions.loadTrackingScriptsSuccess())
        )
    );

    removeScripts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TagManagerActions.removeTrackers),
            exhaustMap(() => this.tagManagerService.removeTags().pipe(devDebug('remove tracking scripts'))),
            map(() => TagManagerActions.removeTrackersSuccess()),
            tap(() => {
                // Removing Hotjar in parts will cause errors, cleanest workaround is to force reload the page so the script doesn't load in the first place
                if (this.window.hj) {
                    this.window.location.reload();
                }
            })
        )
    );

    constructor(
        private actions$: Actions,
        private tagManagerApiService: TagManagerApiService,
        private appCoreFacadeService: AppCoreFacadeService,
        private tagManagerService: TagManagerService,
        private tagManagerFacadeService: TagManagerFacadeService,
        private analyticsService: AnalyticsService,
        private authenticationFacadeService: AuthenticationFacadeService,
        private bannerFacadeService: BannerFacadeService,
        @Inject(WINDOW) private window: Window
    ) {}
}
