import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppCommonModule } from '@common/common.module';
import { CompileHtmlDirective } from '@features/html/directives/compile-html/compile-html.directive';
import { CountdownTimerComponent } from '@features/html/embeddable-components/countdown-timer/countdown-timer.component';
import { OpenAgendaFilterComponent } from '@features/html/embeddable-components/open-agenda-filter/open-agenda-filter.component';
import { OpenModuleComponent } from '@features/html/embeddable-components/open-module/open-module.component';
import { VideoComponent } from '@features/html/embeddable-components/video/video.component';
import { HtmlService } from '@features/html/services/html/html.service';
import { MediaModule } from '@shared/media/media.module';
import { PersonPopoverModule } from '@shared/person-popover/person-popover.module';
import { MuxModule } from '@shared/mux/mux.module';
import { CustomHtmlElementComponent } from './embeddable-components/custom-html-element/custom-html-element.component';
import { LinkOverrideComponent } from './embeddable-components/link-override/link-override.component';
import { TagComponent } from './embeddable-components/tag/tag.component';
import { OpenDirectLinkComponent } from './embeddable-components/open-direct-link/open-direct-link.component';

@NgModule({
    declarations: [
        OpenModuleComponent,
        VideoComponent,
        CountdownTimerComponent,
        OpenAgendaFilterComponent,
        CompileHtmlDirective,
        TagComponent,
        CustomHtmlElementComponent,
        LinkOverrideComponent,
        OpenDirectLinkComponent
    ],
    imports: [AppCommonModule, RouterModule, MuxModule, MediaModule, PersonPopoverModule],
    exports: [CompileHtmlDirective, VideoComponent],
    providers: [HtmlService]
})
export class HtmlModule {}
