import { HttpErrorResponse } from '@angular/common/http';
import { RefresherEventDetail } from '@ionic/core';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { PortalApp } from '@shared/api';

export const PortalListPageActions = createActionGroup({
    source: 'Portal List Page',
    events: {
        'Enter Page': props<{ moduleId: number }>(),
        'Leave Page': emptyProps(),
        'Refresh Page Data': props<{ moduleId: number; getRefresher: () => RefresherEventDetail }>(),
        'Submit Search': props<{ searchTerm: string }>(),
        'Go To Portal App': props<{ portalApp: PortalApp }>(),
        'Preauth Nav To Portal App': props<{ portalApp: PortalApp }>(),
        'Preauth Nav To Portal App Failure': props<{ error: HttpErrorResponse; portal: PortalApp }>(),
        'Open Filter': props<{ filterName: string }>(),
        'Update Filter': props<{ filterName: string }>(),
        'Close Filter': emptyProps(),
        'Go To Portal State': props<{ appUrl: string }>()
    }
});
