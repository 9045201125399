import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { LoginAppState } from '../store/models/login-app.state';
import { LoginSelectors } from '../store/selectors/login.selectors';
import { LoginPageState, PasswordResetPageState } from '../store/models/login.state';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoginFacadeService {
    constructor(private store: Store<LoginAppState>) {}

    getLoginPageState(): Observable<LoginPageState> {
        return this.store.select(LoginSelectors.selectLoginPageState);
    }

    getPasswordResetPageState(): Observable<PasswordResetPageState> {
        return this.store.select(LoginSelectors.selectPasswordResetPageState);
    }

    dispatch(action: Action): void {
        this.store.dispatch(action);
    }
}
