import { NgModule } from '@angular/core';
import { AppCommonModule } from '@common/common.module';
import { ChallengeCompletedModalComponent } from './components/challenge-completed-modal/challenge-completed-modal.component';
import { ChallengeFailedModalComponent } from './components/challenge-failed-modal/challenge-failed-modal.component';
import { ChallengeCompletionStoreModule } from './store/challenge-completion-store.module';
import { LottieModule } from 'ngx-lottie';

@NgModule({
    declarations: [ChallengeCompletedModalComponent, ChallengeFailedModalComponent],
    imports: [AppCommonModule, ChallengeCompletionStoreModule, LottieModule]
})
export class ChallengeCompletionModule {}
