import { Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'cc-page',
    templateUrl: './page.component.html',
    styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit {
    public moduleId: number;

    @HostBinding('class')
    get themeClass(): string {
        return 'cc-module-' + this.moduleId;
    }

    @HostBinding('attr.data-session-module-id')
    get data(): number {
        return this.moduleId;
    }

    constructor(private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.route.paramMap.subscribe((params) => {
            this.moduleId = +params.get('moduleId');
        });
    }
}
