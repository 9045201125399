import { ExpandedPeople } from '@features/people/models/expanded-people.model';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { AppDocument, People, PeopleMinimal } from '@shared/api';

export const PeopleDetailActions = createActionGroup({
    source: 'People Detail',
    events: {
        'Enter Page': props<{ personId: number }>(),
        'Agenda Item Click': props<{ moduleId: number; sessionId: number }>(),
        'Document Item Click': props<{ document: AppDocument }>(),
        'Open Video Chat': props<{ person: People | ExpandedPeople | PeopleMinimal }>(),
        'Open Exchange Business Card Modal': emptyProps(),
        'Leave Page': emptyProps()
    }
});
