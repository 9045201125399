import emojiRegex from 'emoji-regex';

export function containsOnlyEmojis(text: string): boolean {
    const visibleCharsRegex = new RegExp(/[\n\r]+|( )+/g);

    const emojiMatches = text.match(emojiRegex()) ?? [];
    const emojis = emojiMatches.join('');
    const visibleChars = text.replace(visibleCharsRegex, '');

    return emojis.length === visibleChars.length;
}
