import * as models from '@shared/api';

export interface ContentOrderingSettings {
    id?: number;
    fields: Array<models.ContentOrderingCriteria>;
    order_by?: ContentOrderingSettings.OrderByEnum;
}

export namespace ContentOrderingSettings {
    export enum OrderByEnum {
        Field = 'field',
        Custom = 'custom'
    }
}
