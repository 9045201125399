import { PortalApp } from '@shared/api';
import { FilterTranslationKeys } from '../constants/filter-translation-keys.constant';

export const filterPortalAppsByStart = (app: PortalApp, filterValue: string): boolean => {
    const now = new Date();
    const start = new Date(app.start);
    const end = new Date(app.end);

    switch (filterValue) {
        case FilterTranslationKeys.upcoming:
            return start >= now;
        case FilterTranslationKeys.feature:
            return app.featured === true;
        case FilterTranslationKeys.past:
            return end <= now;
        case FilterTranslationKeys.live:
            return start <= now && end >= now;
        case FilterTranslationKeys.all:
            return true;
    }
};
