import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@core/window.service';

@Injectable({
    providedIn: 'root'
})
export class CustomNotificationRulesService {
    constructor(@Inject(WINDOW) private window: Window) {}

    public shouldDisplayNotification(eventType: string): boolean {
        switch (eventType) {
            case 'chatmessage_sent': {
                return !this.window.location.href.includes('/chat/');
            }
            default: {
                return true;
            }
        }
    }
}
