import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'cc-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalComponent {
    @Input()
    public header: string;

    @Input()
    public icon?: string;

    @Input()
    public canClose: boolean = true;

    @Output()
    public closeClicked: EventEmitter<void> = new EventEmitter<void>();

    public emitCloseClicked(): void {
        this.closeClicked.emit();
    }
}
