import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { DateFormatCode, DateFormatPipe } from '@common/pipes/date-format/date-format.pipe';
import { map, tap } from 'rxjs';
import { SelectedTimezoneService } from '../services/selected-timezone/selected-timezone.service';

@Pipe({
    name: 'localisedTimezone',
    pure: false
})
export class LocalisedTimezonePipe implements PipeTransform {
    private asyncPipe: AsyncPipe;

    constructor(
        private dateFormatPipe: DateFormatPipe,
        private selectedTimezoneService: SelectedTimezoneService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        this.asyncPipe = new AsyncPipe(changeDetectorRef);
    }

    public transform(value: string, options: DateFormatCode | Intl.DateTimeFormatOptions): string {
        const observable$ = this.selectedTimezoneService.activeTimezone$.pipe(
            map((timezone) => this.dateFormatPipe.transform(value, options, timezone)),
            tap(() => this.changeDetectorRef.markForCheck())
        );

        return this.asyncPipe.transform(observable$);
    }
}
