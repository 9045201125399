import { AuthenticationActions } from '@core/root-store/store/authentication/actions/authentication.actions';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { AppAlert } from '@shared/api';
import { AppCoreActions } from '../../../../core/root-store/store/app-core/actions/app-core.actions';
import { AlertsTabEnum } from '../../enums/alerts-tab.enum';
import { AlertsApiActions } from '../actions/alerts-api.actions';
import { AlertsActions } from '../actions/alerts.actions';
import { AlertsState } from '../models/alerts.state.model';

export const alertsEntityAdapter: EntityAdapter<AppAlert> = createEntityAdapter<AppAlert>({
    sortComparer: sortByStart
});

export const initialAlertsState: AlertsState = {
    currentTab: undefined,
    unreadNotificationCount: 0,
    alerts: alertsEntityAdapter.getInitialState({
        loading: true,
        total: 0
    })
};

const alertsReducerFn = createReducer(
    initialAlertsState,
    on(AlertsActions.enterPage, (state) => ({
        ...state,
        currentTab: AlertsTabEnum.Current
    })),
    on(AlertsActions.fetchAlerts, (state) => ({
        ...state,
        alerts: {
            ...state.alerts,
            loading: true
        }
    })),
    on(AlertsApiActions.fetchAlertsSuccess, (state, { alerts }) => {
        return {
            ...state,
            alerts: {
                ...alertsEntityAdapter.upsertMany(alerts, state.alerts),
                loading: false,
                total: alerts.length
            }
        };
    }),
    on(AlertsActions.realtimeNewAlert, (state, { alert }) => ({
        ...state,
        alerts: {
            ...alertsEntityAdapter.addOne(alert, state.alerts),
            total: state.alerts.total + 1
        }
    })),
    on(AlertsActions.changeTab, (state, { newTab }) => ({
        ...state,
        currentTab: newTab
    })),
    on(AlertsActions.updateStoredNotificationCount, (state, { count }) => ({
        ...state,
        unreadNotificationCount: count
    })),
    on(AlertsActions.decrementNotificationsBadge, (state) => ({
        ...state,
        unreadNotificationCount: state.unreadNotificationCount - 1
    })),
    on(AlertsActions.incrementNotificationsBadge, (state) => ({
        ...state,
        unreadNotificationCount: state.unreadNotificationCount + 1
    })),
    on(AlertsApiActions.markAlertAsReadSuccess, (state, { alert }) => ({
        ...state,
        alerts: alert
            ? alertsEntityAdapter.updateOne({ id: alert.id, changes: { read: true } }, state.alerts)
            : state.alerts,
        unreadNotificationCount: state.unreadNotificationCount - 1
    })),
    on(AppCoreActions.initialiseApp, AuthenticationActions.logout, () => initialAlertsState)
);

export function alertsReducer(state: AlertsState | undefined, action: Action): AlertsState {
    return alertsReducerFn(state, action);
}

function sortByStart(a: AppAlert, b: AppAlert): number {
    return String(b.start).localeCompare(String(a.start));
}
