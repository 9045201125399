import { Component, ChangeDetectionStrategy, Input, OnChanges } from '@angular/core';
import { People as Person } from '@shared/api';

@Component({
    selector: 'cc-business-card-title',
    templateUrl: './business-card-title.component.html',
    styleUrls: ['./business-card-title.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BusinessCardTitleComponent implements OnChanges {
    @Input()
    public person: Person;

    @Input()
    public stacked: boolean;

    @Input()
    public profileFields: Array<keyof Person>;

    public nameFontSize: number;

    public ngOnChanges(): void {
        this.nameFontSize = this.calculateNameFontSize();
    }

    public canDisplayField(field: keyof Person): boolean {
        return this.profileFields.includes(field);
    }

    private calculateNameFontSize(): number {
        const parts = [];

        if (this.person.title && this.canDisplayField('title')) {
            parts.push(this.person.title);
        }

        if (this.person.first_name && this.canDisplayField('first_name')) {
            parts.push(this.person.first_name);
        }

        if (this.person.last_name && this.canDisplayField('last_name')) {
            parts.push(this.person.last_name);
        }

        const fullName = parts.join(' ');

        // All of the numbers below are guestimations, character width and font could mess this up.
        if (fullName.length <= 18) {
            return 18;
        } else if (fullName.length <= 26) {
            return 16;
        } else if (fullName.length <= 32) {
            return 14;
        } else {
            return 12;
        }
    }
}
