import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoopinEndpoints } from '@features/loopin/constants/loopin-endpoints.constant';
import { LoopinRoom } from '@features/loopin/interfaces/loopin-room.interface';
import { LoopinTokenDetails } from '@features/loopin/interfaces/loopin-token-details.interface';
import { ApiSettings } from '@shared/settings/api-settings.constant';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoopinApiService {
    constructor(private httpClient: HttpClient) {}

    public getRooms(appName: string, moduleId: number): Observable<LoopinRoom[]> {
        const url = ApiSettings.fullUrl(appName, LoopinEndpoints.rooms(moduleId));
        return this.httpClient.get<LoopinRoom[]>(url);
    }

    public getAllRooms(appName: string): Observable<LoopinRoom[]> {
        const url = ApiSettings.fullUrl(appName, LoopinEndpoints.allRooms);
        return this.httpClient.get<LoopinRoom[]>(url);
    }

    public getTokenDetails(appUrl: string, roomId: number): Observable<LoopinTokenDetails> {
        const url = ApiSettings.fullUrl(appUrl, LoopinEndpoints.inviteTokenDetails(roomId));

        return this.httpClient.get<LoopinTokenDetails>(url);
    }
}
