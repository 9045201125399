import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AuthenticationFacadeService } from '../services/authentication-facade.service';

@Injectable()
export class MfaInterceptor implements HttpInterceptor {
    constructor(private authenticationFacadeService: AuthenticationFacadeService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.authenticationFacadeService.getOtpSignature().pipe(
            take(1),
            switchMap((otpSignature) => {
                if (!otpSignature || !request.url.includes(environment.API_URL)) {
                    return next.handle(request);
                }

                const clonedRequest = request.clone({
                    setHeaders: {
                        'X-CC-MFA-Valid': otpSignature
                    }
                });

                return next.handle(clonedRequest);
            })
        );
    }
}
