import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { AppRouteParams } from '@core/routing/constants/app-route-params.constant';
import { map, Observable } from 'rxjs';
import { NotificationsApiService } from './services/notifications-api/notifications-api.service';

@Injectable({
    providedIn: 'root'
})
export class DeeplinkNotificationsGuard {
    constructor(
        private router: Router,
        private notificationsApiService: NotificationsApiService
    ) {}

    public canActivate(route: ActivatedRouteSnapshot): Observable<UrlTree> {
        const notificationId = route.params[AppRouteParams.deeplinkNotificationId];
        const appName = route.params[AppRouteParams.appName];

        return this.notificationsApiService.getNotificationDeeplink(appName, notificationId).pipe(
            map(({ deeplink }) => {
                const [url, params] = deeplink.split('?');
                const queryParams = Object.fromEntries(new URLSearchParams(params).entries());
                return this.router.createUrlTree([url], { queryParams });
            })
        );
    }
}
