import { Injectable } from '@angular/core';
import { CookieConfigurationState } from '@core/banner/interfaces/cookie-configuration-state.interface';
import { BannerAppState, GlobalBannerState } from '@core/banner/store/models/banner.state.model';
import { BannerSelectors } from '@core/banner/store/selectors/banner.selectors';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BannerFacadeService {
    constructor(private store: Store<BannerAppState>) {}

    public dispatch(action: Action): void {
        this.store.dispatch(action);
    }

    public getGlobalBannerConfig(): Observable<GlobalBannerState> {
        return this.store.select(BannerSelectors.globalBannerConfig);
    }

    public getCookieConfig(): Observable<CookieConfigurationState> {
        return this.store.select(BannerSelectors.selectCookieConfig);
    }

    public selectShowCookieCustomisation(): Observable<boolean> {
        return this.store.select(BannerSelectors.selectShowCookieCustomisation);
    }

    public selectCookiesDismissed(): Observable<boolean> {
        return this.store.select(BannerSelectors.selectCookiesDismissed);
    }

    public selectAllowAnalytics(): Observable<boolean> {
        return this.store.select(BannerSelectors.selectAnalyticsConsent);
    }
}
