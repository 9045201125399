<ion-list class="documents-list__container" lines="none" role="list">
    <ion-card *ngFor="let document of documents" class="documents-list__card">
        <ion-item
            role="listitem"
            class="ion-no-margin documents-list__item"
            button
            [ngClass]="'cc-document-list-item-' + document.id"
            (click)="onTapDocument(document)"
        >
            <ion-thumbnail slot="start">
                <div class="documents-list__icon">
                    <ng-container [ngSwitch]="document.icon_toggle">
                        <img
                            *ngSwitchCase="iconToggle.Img"
                            class="documents-list__img-thumbnail"
                            [src]="document.thumbnail | imageDimensions: 56 : 56"
                            [alt]="document.name"
                        />

                        <i *ngSwitchCase="iconToggle.Icon" class="material-icons" aria-hidden="true">
                            {{ document.mimetype | mimetypeIcon }}
                        </i>
                    </ng-container>
                </div>
            </ion-thumbnail>

            <ion-label class="ion-text-wrap">
                <h2>{{ document.name }}</h2>
            </ion-label>
        </ion-item>
    </ion-card>
</ion-list>
