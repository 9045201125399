import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ExpandedApiDrivenNotification } from '@core/notifications/interfaces/expanded-api-driven-notification.interface';
import { AlertFilter } from '@features/alerts/enums/alerts-filter.enum';

@Component({
    selector: 'cc-alert-list-filter-panel',
    templateUrl: './alert-list-filter-panel.component.html',
    styleUrls: ['./alert-list-filter-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertListFilterPanelComponent {
    @Input()
    public disabled: boolean;

    @Input()
    public currentItems: ExpandedApiDrivenNotification[];

    @Output()
    public setFilter = new EventEmitter<AlertFilter>();

    @Output()
    public markAllRead = new EventEmitter<void>();

    @Output()
    public archiveAll = new EventEmitter<void>();

    public allRead: boolean = true;
    public allUnread: boolean = true;

    public applyFilter(): void {
        if ((this.allRead && this.allUnread) || (!this.allRead && !this.allUnread)) {
            this.setFilter.emit(AlertFilter.All);
            return;
        }

        const filter = this.allRead ? AlertFilter.Read : AlertFilter.Unread;
        this.setFilter.emit(filter);
    }
}
