import { NgModule } from '@angular/core';
import { AppCommonModule } from '@common/common.module';
import { TextEmojisDirective } from './directives/text-emojis/text-emojis.directive';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { EmojiPickerPopoverComponent } from './components/chat-tab-emoji-popover/emoji-picker-popover.component';

@NgModule({
    declarations: [TextEmojisDirective, EmojiPickerPopoverComponent],
    imports: [AppCommonModule, PickerModule],
    exports: [TextEmojisDirective]
})
export class EmojiModule {}
