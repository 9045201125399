import { Inject, Injectable } from '@angular/core';
import { DeviceService } from '@core/device/services/device.service';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { WINDOW } from '../../../core/window.service';
import { VideoCall } from '../models/video-call.interface';
import { VideoCallsApiActions } from '../store/actions/video-calls-api.actions';
import { VideoCallFacadeService } from './video-call-facade.service';

@Injectable({
    providedIn: 'root'
})
export class VideoCallService {
    public windowTab = null;

    constructor(
        private alertController: AlertController,
        private videoFacadeService: VideoCallFacadeService,
        private translateService: TranslateService,
        private deviceService: DeviceService,
        @Inject(WINDOW) private window: any
    ) {}

    public async showBlockCallerDialog(incomingCall: VideoCall): Promise<void> {
        const translations = await this.blockCallerTranslations();

        const alertOptions = {
            backdropDismiss: false,
            header: translations['VIDEO_CALL_BLOCK_DIALOG_HEADER'],
            message: `
                 <p>${translations['VIDEO_CALL_BLOCK_DIALOG_PREVENT']} ${incomingCall.first_name} ${incomingCall.last_name}</p>
            `,
            buttons: [
                {
                    text: translations['VIDEO_CALL_BLOCK_DIALOG_NO'],
                    role: 'cancel'
                },
                {
                    text: translations['VIDEO_CALL_BLOCK_DIALOG_YES'],
                    handler: () => {
                        this.videoFacadeService.dispatch(VideoCallsApiActions.blockCaller({ incomingCall }));
                    }
                }
            ]
        };

        const alert = await this.alertController.create(alertOptions);

        await alert.present();
    }

    public async showCallerUnavailableDialog(message: { first_name: string; last_name: string }): Promise<void> {
        const translations = await this.callerUnavailableTranslations();

        const alertOptions = {
            header: translations['VIDEO_CALL_UNAVAILABLE_DIALOG_HEADER'],
            message: `${message.first_name} ${translations['VIDEO_CALL_UNAVAILABLE_DIALOG_MESSAGE']}`,
            buttons: [
                {
                    text: translations['VIDEO_CALL_UNAVAILABLE_DIALOG_CLOSE'],
                    role: 'cancel'
                }
            ]
        };

        const alert = await this.alertController.create(alertOptions);

        await alert.present();
    }

    // This is to prevent browser blocking the popup as it needs to be opened on direct user interaction
    // So we call window.open immediately and then assign the location in the async call back
    public openNewWindow(): void {
        if (this.deviceService.isIos()) {
            // Don't bother on native iOS. The only way to open windows is by setting window location.
            return;
        }

        let newTabOptions: string;

        if (!this.deviceService.isNative()) {
            // On web we want to define some sizes for the window and position it in the bottom right.
            const width = 600;
            const height = 700;
            const { top, left } = this.calculateNewWindowLocation(width, height);

            newTabOptions = `width=${width},height=${height},top=${top},left=${left}`;
        }

        this.windowTab = this.window.open('about:blank', '_system', newTabOptions);
    }

    public setUrl(url: string): void {
        if (this.windowTab) {
            this.windowTab.location = url;
        } else {
            this.window.location = url;
        }
    }

    public closeWindow(): void {
        this.windowTab?.close();
    }

    // Calculate bottom right position for new window
    private calculateNewWindowLocation(newWindowWidth: number, newWindowHeight: number): { top: number; left: number } {
        const topOffset = 50;
        const screenLeft = this.window.screenLeft;
        const screenTop = this.window.screenTop;
        const windowWidth = this.window.innerWidth;
        const windowHeight = this.window.innerHeight;
        const newWindowLeft = windowWidth - newWindowWidth + screenLeft;
        const newWindowTop = windowHeight - newWindowHeight + screenTop + topOffset;

        return {
            top: newWindowTop,
            left: newWindowLeft
        };
    }

    private blockCallerTranslations(): Promise<string[]> {
        return this.translateService
            .get([
                'VIDEO_CALL_BLOCK_DIALOG_HEADER',
                'VIDEO_CALL_BLOCK_DIALOG_NO',
                'VIDEO_CALL_BLOCK_DIALOG_YES',
                'VIDEO_CALL_BLOCK_DIALOG_PREVENT'
            ])
            .toPromise();
    }

    private callerUnavailableTranslations(): Promise<string[]> {
        return this.translateService
            .get([
                'VIDEO_CALL_UNAVAILABLE_DIALOG_HEADER',
                'VIDEO_CALL_UNAVAILABLE_DIALOG_MESSAGE',
                'VIDEO_CALL_UNAVAILABLE_DIALOG_CLOSE'
            ])
            .toPromise();
    }
}
