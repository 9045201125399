import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SlimPeople } from '@api';

@Component({
    selector: 'cc-potential-new-chat',
    templateUrl: './potential-new-chat.component.html',
    styleUrl: './potential-new-chat.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PotentialNewChatComponent {
    @Input()
    public person: SlimPeople;

    @Output()
    public startNewChat = new EventEmitter<void>();

    public onStartNewChat(): void {
        this.startNewChat.emit();
    }
}
