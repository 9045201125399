import { Inject, Injectable } from '@angular/core';
import { AnalyticsPageView } from '@core/analytics/interfaces/analytics-page-view-data.interface';
import { AnalyticsSetting } from '@core/analytics/interfaces/analytics-setting.interface';
import { AnalyticsDataStreamService } from '@core/analytics/services/analytics-data-stream.service';
import { LoadScriptService } from '@core/browser-utilities/services/load-script.service';
import { TagStatus } from '@core/tag-management/constants/tag-status.constant';
import { TagBuilder } from '@core/tag-management/models/tag-builder.interface';
import { TagTracker } from '@core/tag-management/models/tag-token.interface';
import { WINDOW } from '@core/window.service';
import { CookieService } from 'ngx-cookie-service';
import { GaService } from './ga.service';

@Injectable({
    providedIn: 'root'
})
export class GaBuilderService implements TagBuilder {
    public trackers: TagTracker[];

    constructor(
        private scriptLoader: LoadScriptService,
        private gaService: GaService,
        private analyticsService: AnalyticsDataStreamService,
        private cookieService: CookieService,
        @Inject(WINDOW) private window: Window
    ) {}

    public configure(trackers: TagTracker[]): TagBuilder {
        this.trackers = trackers;
        return this;
    }

    public load(): string {
        if (this.window.ga || this.scriptLoader.getScript('ga-tag')) {
            return TagStatus.skipped('GA', 'Script already loaded');
        }

        if (!this.trackers || this.trackers.length === 0) {
            console.warn('CC Tag Manager: No tracking properties found for Universal Analytics!');
            return TagStatus.skipped('GA', 'No tracking properties');
        }

        const loaded = this.scriptLoader.loadScript({
            id: 'ga-tag',
            src: 'https://www.google-analytics.com/analytics.js',
            onload: () => {
                this.setupTag();
            },
            onerror: (error) => this.handleError(error)
        });

        if (loaded) {
            return TagStatus.loaded('GA');
        }
    }

    public remove(): void {
        // Delete cookies
        ['_ga', '_gid', '_gat'].forEach((key) => this.cookieService.delete(key, '/', this.window.location.hostname));

        // Remove script element
        this.scriptLoader.getScript('ga-tag').element.remove();

        // Unset global functions
        this.window.ga = undefined;

        this.trackers.forEach((token) => (this.window[`ga-disable-${token}`] = true));
    }

    private handleError(error: Event | string): void {
        console.warn(error);
    }

    private setupTag(): void {
        this.window.GoogleAnalyticsObject = this.window.GoogleAnalyticsObject || 'ga';

        this.reactivateDisabledProperties();

        if (!this.window.ga) {
            this.window.ga = function () {
                this.window.ga.q.push(arguments);
            };

            this.window.ga.q = [];
        }

        this.startTracking();
    }

    private startTracking(): void {
        this.window.ga.l = new Date().getTime();

        this.gaService.checkNative(this.trackers);

        this.analyticsService.event$.subscribe((event: AnalyticsSetting) =>
            this.gaService.handleEvent({ ...event }, this.trackers)
        );
        this.analyticsService.pageView$.subscribe((view: AnalyticsPageView) =>
            this.gaService.handlePageView({ ...view }, this.trackers)
        );
    }

    private reactivateDisabledProperties(): void {
        // Check any disabled tags matching the analytics tokens are re-enabled
        Object.keys(this.window)
            .filter((key) => this.trackers.some((token) => key === `ga-disable-${token.token}`))
            .map((key) => {
                this.window[key] = false;
            });
    }
}
