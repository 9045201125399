import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { fadeIn } from '@common/animations/animations';
import { AlertDigestNotificationId } from '@core/notifications/constants/alerts-notification-map.constant';
import { ExpandedApiDrivenNotification } from '@core/notifications/interfaces/expanded-api-driven-notification.interface';
import { NotificationsFacadeService } from '@core/notifications/services/notifications-facade/notifications-facade.service';
import { NotificationsActions } from '@core/notifications/store/actions/notifications.actions';
import { AlertFilter } from '@features/alerts/enums/alerts-filter.enum';
import { NotificationsPaginationStoreService } from '@features/alerts/services/notifications-pagination-store.service';
import { AlertController } from '@ionic/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { performant } from '@shared/rxjs/custom.rxjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationFacadeService } from '../../../../core/authentication/services/authentication-facade.service';
import { AlertsTabEnum } from '../../enums/alerts-tab.enum';
import { AlertsFacadeService } from '../../services/alerts-facade.service';
import { AlertsActions } from '../../store/actions/alerts.actions';

@UntilDestroy()
@Component({
    selector: 'cc-alerts',
    templateUrl: './alerts.component.html',
    styleUrls: ['./alerts.component.scss'],
    providers: [NotificationsPaginationStoreService],
    animations: [fadeIn(500)]
})
export class AlertsComponent implements OnInit {
    public isAuthenticated$: Observable<boolean>;
    public currentTab$: Observable<AlertsTabEnum>;
    public loading$: Observable<boolean>;
    public hasReachedEnd$: Observable<boolean>;
    public paginationResponse$: Observable<ExpandedApiDrivenNotification[]>;

    public tabEnum = AlertsTabEnum;
    public searchInput: UntypedFormControl;

    private loadComplete: () => void;

    constructor(
        private authenticationFacadeService: AuthenticationFacadeService,
        private alertsFacadeService: AlertsFacadeService,
        private notificationFacadeService: NotificationsFacadeService,
        private alertController: AlertController,
        private translateService: TranslateService,
        private notificationsPaginationStoreService: NotificationsPaginationStoreService
    ) {}

    public trackByFn = (_: number, item: ExpandedApiDrivenNotification): string => item.id;

    public ngOnInit(): void {
        this.alertsFacadeService.dispatch(AlertsActions.enterPage());

        this.currentTab$ = this.alertsFacadeService.getCurrentTab();
        this.isAuthenticated$ = this.authenticationFacadeService.isAuthenticated();

        this.dismissDigest();

        this.notificationsPaginationStoreService.initialise({
            page: 1,
            limit: 20,
            archived: false
        });

        this.paginationResponse$ = this.notificationsPaginationStoreService.resource$.pipe(
            map((resource) => {
                if (this.loadComplete) {
                    this.loadComplete();
                    this.loadComplete = undefined;
                }

                return resource.results;
            })
        );

        this.loading$ = this.notificationsPaginationStoreService.isLoading$;
        this.hasReachedEnd$ = this.notificationsPaginationStoreService.hasReachedEnd$;

        this.setupSearch().subscribe((val) => {
            this.notificationsPaginationStoreService.patchQuery({
                search: val,
                page: 1
            });
        });
    }

    public onChangeTab(newTab: AlertsTabEnum): void {
        this.alertsFacadeService.dispatch(AlertsActions.changeTab({ newTab }));

        if (newTab === AlertsTabEnum.Archived) {
            this.notificationsPaginationStoreService.showArchivedNotifications();
        } else {
            this.notificationsPaginationStoreService.showUnarchivedNotifications();
        }
    }

    public archiveNotification(notification: ExpandedApiDrivenNotification): void {
        this.notificationsPaginationStoreService.archiveNotification(notification);
    }

    public unarchiveNotification(id: string): void {
        this.notificationsPaginationStoreService.unarchiveNotification(id);
    }

    public markNotificationAsRead(id: string): void {
        this.notificationsPaginationStoreService.markNotificationAsRead(id);
    }

    public updateQuery(filter: AlertFilter): void {
        if (filter === AlertFilter.Read) {
            this.notificationsPaginationStoreService.showOnlyReadNotifications();
        } else if (filter === AlertFilter.Unread) {
            this.notificationsPaginationStoreService.showOnlyUnreadNotifications();
        } else {
            this.notificationsPaginationStoreService.showAllNotifications();
        }
    }

    public archiveAll(): void {
        this.confirmChoice({
            action: () => this.notificationsPaginationStoreService.archiveAllNotifications(),
            message: 'ALERTS_ARCHIVE_ALL_MESSAGE'
        });
    }

    public markAllRead(): void {
        this.confirmChoice({
            action: () => this.notificationsPaginationStoreService.markAllNotificationsAsRead(),
            message: 'ALERTS_MARK_ALL_READ_MESSAGE'
        });
    }

    public onActionClicked(notification: ExpandedApiDrivenNotification): void {
        this.notificationFacadeService.dispatch(
            NotificationsActions.apiDrivenNotificationActioned({
                id: notification.id,
                customDeeplink: notification.custom_deeplink,
                canMarkAsRead: false
            })
        );
    }

    public nextPage(event: any): void {
        this.notificationsPaginationStoreService.nextPage();
        this.loadComplete = () => event.target.complete();
    }

    private async confirmChoice(options: { action: () => void; message: string }): Promise<void> {
        const alert = await this.alertController.create({
            header: this.translateService.instant('ALERTS_BULK_ACTION_TITLE'),
            message: this.translateService.instant(options.message),
            buttons: [
                {
                    text: this.translateService.instant('ALERTS_BULK_ACTION_CANCEL'),
                    role: 'cancel'
                },
                {
                    text: this.translateService.instant('ALERTS_BULK_ACTION_CONFIRM'),
                    role: 'confirm'
                }
            ]
        });

        await alert.present();
        const result = (await alert.onDidDismiss()) as any;

        if (result?.role === 'confirm') {
            options.action();
        }
    }

    private setupSearch(): Observable<string> {
        this.searchInput = new UntypedFormControl();
        return this.searchInput.valueChanges.pipe(untilDestroyed(this), performant(200));
    }

    private dismissDigest(): void {
        this.notificationFacadeService
            .getNotification(AlertDigestNotificationId)
            .pipe(untilDestroyed(this))
            .subscribe((digest) => {
                if (digest) {
                    this.notificationFacadeService.dispatch(
                        NotificationsActions.dismissNotification({ id: AlertDigestNotificationId })
                    );
                }
            });
    }
}
