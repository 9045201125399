import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'cc-search-input',
    templateUrl: './search-input.component.html',
    styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent {
    @Input()
    public searchControl: UntypedFormControl;
}
