import { Injectable } from '@angular/core';
import { DeeplinksService } from '@core/native/services/deeplinks.service';
import { AlertsNotificationMap } from '@core/notifications/constants/alerts-notification-map.constant';
import { BusinessCardNotificationMap } from '@core/notifications/constants/business-card-notification-map.constant';
import { MeetingNotificationMap } from '@core/notifications/constants/meeting-notification-map.constant';
import { NotificationDurations } from '@core/notifications/constants/notification-durations.constant';
import { AlertBehaviourType } from '@core/notifications/enums/alert-notification-type.enum';
import { ApiDrivenNotification } from '@core/notifications/interfaces/api-driven-notification.interface';
import { BusinessCardApiNotification } from '@core/notifications/interfaces/business-card-api-notification.interface';
import { MeetingApiNotification } from '@core/notifications/interfaces/meeting-api-notification.interface';
import { AppNotification } from '@core/notifications/interfaces/notification.interface';
import { NotificationsActions } from '@core/notifications/store/actions/notifications.actions';
import { AlertsFacadeService } from '@features/alerts/services/alerts-facade.service';
import { AlertsActions } from '@features/alerts/store/actions/alerts.actions';
import { AlertTypeEnum, AppAlert } from '@shared/api';
import { v4 as uuidv4 } from 'uuid';
import { NotificationsFacadeService } from '../notifications-facade/notifications-facade.service';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
    constructor(
        private notificationsFacadeService: NotificationsFacadeService,
        private deeplinkService: DeeplinksService,
        private alertsFacadeService: AlertsFacadeService
    ) {}

    public createMeetingApiNotification(meetingApiNotification: MeetingApiNotification): AppNotification {
        const notification = MeetingNotificationMap[meetingApiNotification.event_type];

        const senderName = [meetingApiNotification.sender?.first_name, meetingApiNotification.sender?.last_name]
            .filter(Boolean)
            .join(' ');

        const receiverName = [meetingApiNotification.receiver?.first_name, meetingApiNotification.receiver?.last_name]
            .filter(Boolean)
            .join(' ');

        notification.translateParams = { senderName, receiverName };

        return this.createNotification(notification);
    }

    public createBusinessCardApiNotification(
        businessCardApiNotification: BusinessCardApiNotification
    ): AppNotification {
        const notification = BusinessCardNotificationMap[businessCardApiNotification.event_type];

        const name = [
            businessCardApiNotification.request.card.profile.first_name,
            businessCardApiNotification.request.card.profile.last_name
        ]
            .filter(Boolean)
            .join(' ');

        notification.translateParams = { name };

        return this.createNotification(notification);
    }

    public createAlertApiNotification(alertNotification: AppAlert): AppNotification {
        const notification: Partial<AppNotification> = {
            id: alertNotification.id.toString(),
            title: alertNotification.title,
            message: alertNotification.description_short,
            ...AlertsNotificationMap[alertNotification.alert_type]
        };

        if (alertNotification.alert_type === AlertTypeEnum.Persistent) {
            notification.buttons = [
                {
                    text: alertNotification.cta_button_text,
                    handler: () => {
                        this.alertsFacadeService.dispatch(AlertsActions.markAsRead({ alert: alertNotification }));
                        this.deeplinkService.handle(alertNotification.deeplink_url);
                    },
                    icon: 'launch'
                }
            ];
        }

        return this.createNotification(notification);
    }

    public createAlertsDigestNotification(count: number): AppNotification {
        const notification = AlertsNotificationMap[AlertBehaviourType.Digest];

        notification.translateParams = { count };

        return this.createNotification(notification);
    }

    public createNotification(options: Partial<AppNotification>): AppNotification {
        const id = this.generateNotificationId();

        const notification: AppNotification = {
            id: options.id ?? id,
            title: options.title,
            message: options.message,
            actionAriaLabel: options.actionAriaLabel,
            action: options.action,
            theme: options.theme,
            buttons: options.buttons,
            icon: options.icon ?? 'notifications',
            translateParams: options.translateParams ?? {},
            duration: options.duration ?? NotificationDurations.default,
            silent: options.silent
        };

        this.notificationsFacadeService.dispatch(NotificationsActions.showNotification({ notification }));

        return notification;
    }

    /**
     * @description
     * One notification handler to rule them all.
     * This method is for API notifications that don't need mapping to specific actions.
     * It returns HTML instead of title & message (this is something to do with API translations).
     */
    public createApiDrivenNotification(data: ApiDrivenNotification): AppNotification {
        const { title, message } = this.convertHtmlNotificationToObject(data.message);

        const notification = this.createNotification({
            id: data.id.toString(),
            action: NotificationsActions.apiDrivenNotificationActioned({
                id: data.id.toString(),
                customDeeplink: data.custom_deeplink,
                canMarkAsRead: !data.custom_deeplink
            }),
            title,
            message,
            icon: 'info'
        });
        return notification;
    }

    /**
     * @param html
     * HTML that is part of the notification payload delivered by the API
     *
     * @returns
     * The HTML payload parsed in to a title and list of messages
     */
    public convertHtmlNotificationToObject(html: string): { title: string; message: string[] } {
        const parser = new DOMParser();
        const virtualDom = parser.parseFromString(html, 'text/html');

        const title = virtualDom.querySelector('h2')?.textContent?.trim();

        const messageElements = Array.from(virtualDom.querySelectorAll('p') ?? []);
        const message = messageElements.map((el) => el.textContent.trim());

        return { title, message };
    }

    public generateNotificationId(): string {
        return uuidv4();
    }
}
