import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiSettings } from '@shared/settings/api-settings.constant';
import { Video } from '@api/models/Video';
import { PlaybackId } from '@features/live-stream/interfaces/stream';
import { PlaybackTokens } from '@shared/mux/interfaces/playback-tokens.interface';

@Injectable({
    providedIn: 'root'
})
export class VideoApiService {
    constructor(private httpClient: HttpClient) {}

    getVideo(appUrl: string, videoId: string): Observable<Video> {
        const url = ApiSettings.fullUrl(appUrl, VideoApiEndpoints.video(videoId));
        return this.httpClient.get<Video>(url);
    }

    getPlaybackIds(appUrl: string, videoId: string): Observable<PlaybackId[]> {
        const url = ApiSettings.fullUrl(appUrl, VideoApiEndpoints.playbackIds(videoId));
        return this.httpClient.get<PlaybackId[]>(url);
    }

    getPlaybackTokens(appUrl: string, videoId: string, playbackId: string): Observable<PlaybackTokens> {
        const url = ApiSettings.fullUrl(appUrl, VideoApiEndpoints.playbackTokens(videoId, playbackId));
        return this.httpClient.get<PlaybackTokens>(url);
    }
}

export const VideoApiEndpoints = {
    video: (videoId: string) => `/videos/${videoId}/`,
    playbackIds: (videoId: string) => `/videos/${videoId}/playback-ids/`,
    playbackTokens: (videoId: string, playbackId: string) =>
        `/videos/${videoId}/playback-ids/${playbackId}/get-playback-tokens/`
};
