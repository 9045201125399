export interface LiveDisplayScreenAgenda {
    id?: number;
    headers?: Array<number>;
    footers?: Array<number>;
    background_color?: string;
    toggle_background?: LiveDisplayScreenAgenda.ToggleBackgroundEnum;
    title?: string;
    order?: number;
    is_visible?: boolean;
    card_color?: string;
    card_text_color?: string;
    layout?: LiveDisplayScreenAgenda.LayoutEnum;
    display_images?: LiveDisplayScreenAgenda.DisplayImagesEnum;
    title_color?: string;
    title_text_color?: string;
    background_image?: number;
    live_display: number;
    agenda: number;
}

export namespace LiveDisplayScreenAgenda {
    export enum ToggleBackgroundEnum {
        Color = 'background_color',
        Image = 'background_image'
    }
    export enum LayoutEnum {
        Column = 'column',
        List = 'list'
    }
    export enum DisplayImagesEnum {
        Banners = 'banners',
        Speakers = 'speakers',
        None = 'none'
    }
}
