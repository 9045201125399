import { createSelector } from '@ngrx/store';
import { NavigationHistoryAppState, navigationHistoryStoreKey } from '../models/navigation-history.state.model';

const selectNavigationState = (state: NavigationHistoryAppState) => state[navigationHistoryStoreKey];

const selectHistoryState = createSelector(selectNavigationState, (state) => state.history);
const selectCurrentState = createSelector(selectHistoryState, (state) => state[state.length - 1]);

export const NavigationHistorySelectors = {
    currentState: selectCurrentState,
    history: selectHistoryState
};
