import * as models from '@shared/api';
import { Action } from '@shared/api';

export interface Infobooth {
    text_color?: string;
    updated?: Date;
    created?: Date;
    id?: string;
    background_color?: string;
    documents?: Array<number>;
    location?: number;
    title?: string;
    icon_toggle?: string;
    banner_img?: number;
    is_active?: boolean;
    module: number;
    more?: Array<Infobooth>;
    tracks?: Array<models.InfoboothTrack>;
    icon_text?: string;
    actions?: Array<Action>;
    is_visible?: boolean;
    icon_img?: string;
    order?: number;
    content_area?: string;
    infobooth_toggle?: Infobooth.InfoboothToggleEnum;
    content_panels?: Array<models.InfoboothContentPanel>;
}

export namespace Infobooth {
    export enum InfoboothToggleEnum {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        Infobooth = 'infobooth',
        Actions = 'actions'
    }
}
