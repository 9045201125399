import { Injectable } from '@angular/core';
import { MeetingCategory } from '@features/meeting-booking/enums/meeting-category.enum';
import { MeetingType } from '@features/meeting-booking/enums/meeting-type.enum';
import {
    MeetingAvailabilityResponse,
    MeetingAvailabilitySegment
} from '@features/meeting-booking/store/models/meeting-availability-segment.interface';
import { MeetingBookingAppState } from '@features/meeting-booking/store/models/meeting-booking-app-state.model';
import { MeetingBookingConfigModel } from '@features/meeting-booking/store/models/meeting-booking-config.model';
import { BookableRange } from '@features/meeting-booking/store/models/meeting-booking-state.model';
import { MeetingBookingTag } from '@features/meeting-booking/store/models/meeting-booking-tag.model';
import { MeetingLocation } from '@features/meeting-booking/store/models/meeting-location.interface';
import { ExpandedMeeting } from '@features/meeting-booking/store/models/meeting.model';
import { MeetingBookingSidebarSelectors } from '@features/meeting-booking/store/selectors/meeting-booking-sidebar.selectors';
import { MeetingBookingSelectors } from '@features/meeting-booking/store/selectors/meeting-booking.selectors';
import { Actions, ofType } from '@ngrx/effects';
import { Action, ActionCreator, Selector, Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MeetingBookingFacadeService {
    constructor(
        private store: Store<MeetingBookingAppState>,
        private actions$: Actions
    ) {}

    public dispatch(action: Action): void {
        this.store.dispatch(action);
    }

    public actionOfType(...actions: ActionCreator[]): Observable<any> {
        return this.actions$.pipe(ofType(...actions));
    }

    public getAvailableTags(): Observable<MeetingBookingTag[]> {
        return this.store.select(MeetingBookingSelectors.availableTags);
    }

    public getReceivedMeetings(): Observable<ExpandedMeeting[]> {
        return this.getMeetings(MeetingBookingSidebarSelectors.receivedMeetings);
    }

    public getSentMeetings(): Observable<ExpandedMeeting[]> {
        return this.getMeetings(MeetingBookingSidebarSelectors.sentMeetings);
    }

    public getUpcomingMeetings(): Observable<ExpandedMeeting[]> {
        return this.getMeetings(MeetingBookingSidebarSelectors.upcomingMeetings);
    }

    public getCompletedMeetings(): Observable<ExpandedMeeting[]> {
        return this.getMeetings(MeetingBookingSidebarSelectors.completedMeetings);
    }

    public getAvailableLocations(): Observable<MeetingLocation[]> {
        return this.store.select(MeetingBookingSelectors.availableLocations);
    }

    public getSelectedAvailability(): Observable<MeetingAvailabilitySegment[]> {
        return this.store.select(MeetingBookingSidebarSelectors.selectedAvailability);
    }

    public getReceivedAvailability(): Observable<MeetingAvailabilityResponse[]> {
        return this.store.select(MeetingBookingSidebarSelectors.receivedAvailability);
    }

    public getSelectedDate(): Observable<string> {
        return this.store.select(MeetingBookingSidebarSelectors.selectedDate);
    }

    public getBookableDates(): Observable<BookableRange> {
        return this.store.select(MeetingBookingSelectors.bookableRange);
    }

    public getAvailabilityIsLoading(): Observable<boolean> {
        return this.store.select(MeetingBookingSidebarSelectors.availabilityIsLoading);
    }

    public getMeetingAmountDisplayed(category: MeetingCategory): Observable<number> {
        return this.store.select(MeetingBookingSidebarSelectors.meetingsState).pipe(
            map((meetingState) => meetingState[category]),
            map((state) => state.ids.length)
        );
    }

    public getMeetingHasReachedEnd(category: MeetingCategory): Observable<boolean> {
        return this.store.select(MeetingBookingSidebarSelectors.meetingsState).pipe(
            map((meetingState) => meetingState[category]),
            map((state) => state.ids.length >= state.total)
        );
    }

    public getMeetingCount(category: MeetingCategory): Observable<number> {
        return this.store.select(MeetingBookingSidebarSelectors.meetingsState).pipe(
            map((meetingState) => meetingState[category]),
            map((state) => state.total)
        );
    }

    public getMinMeetingLength(): Observable<number> {
        return this.store.select(MeetingBookingSelectors.minMeetingLength);
    }

    public getMaxMeetingLength(): Observable<number> {
        return this.store.select(MeetingBookingSelectors.maxMeetingLength);
    }

    public getDayStartTime(): Observable<string> {
        return this.store
            .select(MeetingBookingSelectors.dayStartTime)
            .pipe(map((time) => DateTime.fromISO(time + 'Z').toFormat('HH:mm')));
    }

    public getDayEndTime(): Observable<string> {
        return this.store
            .select(MeetingBookingSelectors.dayEndTime)
            .pipe(map((time) => DateTime.fromISO(time + 'Z').toFormat('HH:mm')));
    }

    public getUseCompanyLocation(): Observable<boolean> {
        return this.store.select(MeetingBookingSelectors.useCompanyLocation);
    }

    public getMeetingListIsLoading(category: MeetingCategory): Observable<boolean> {
        return this.store.select(MeetingBookingSidebarSelectors.meetingsState).pipe(
            map((meetingState) => meetingState[category]),
            map((state) => state.loading)
        );
    }

    public getConfiguredMeetingTypes(): Observable<MeetingType> {
        return this.store.select(MeetingBookingSelectors.meetingType);
    }

    public getMeetingConfig(): Observable<MeetingBookingConfigModel> {
        return this.store.select(MeetingBookingSelectors.config);
    }

    private getMeetings(
        meetingsSelector: Selector<MeetingBookingAppState, ExpandedMeeting[]>
    ): Observable<ExpandedMeeting[]> {
        return this.store
            .select(meetingsSelector)
            .pipe(map((meetings) => meetings.filter((meeting) => meeting.invited_users?.length >= 2)));
    }
}
