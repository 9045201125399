<ng-container *ngIf="!loadSurvey; else showSurvey">
    <ng-container>
        <ng-container>
            <ion-card class="alert-survey" @fadeIn @fadeOut>
                <ion-card-content>
                    <div class="alert-survey__content">
                        <p>{{ 'ALERTS_SURVEY_ATTACHED' | translate }}</p>
                        <ion-button class="alert-survey__action" (click)="loadSurveyClicked()">{{
                            'ALERTS_SURVEY_LOAD_BUTTON' | translate
                        }}</ion-button>
                    </div>
                </ion-card-content>
            </ion-card>
        </ng-container>
        <ng-template #loadingPollData>
            <cc-loading-spinner></cc-loading-spinner>
        </ng-template>
    </ng-container>
</ng-container>
<ng-template #showSurvey>
    <cc-survey [pollGroupId]="pollGroupId" [showCompleteButton]="false" @fadeIn></cc-survey>
</ng-template>
