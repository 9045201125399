import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DateFormatPipe } from './date-format/date-format.pipe';
import { DateRelativePipe } from './date-relative/date-relative.pipe';
import { DurationPipe } from './duration/duration.pipe';
import { EllipsisPipe } from './ellipsis/ellipsis.pipe';
import { ImageDimensionsPipe } from './image-dimensions/image-dimensions.pipe';
import { MimetypeIconPipe } from './mimetype-icon/mimetype-icon.pipe';
import { ReadableUrlPipe } from './readable-url/readable-url.pipe';
import { SafeUrlPipe } from './safe-url/safe-url.pipe';
import { SafePipe } from './safe/safe.pipe';
import { ConcatPipe } from './concat/concat.pipe';
import { ConcatMapPipe } from './concat-map/concat-map.pipe';

@NgModule({
    declarations: [
        DateFormatPipe,
        DateRelativePipe,
        DurationPipe,
        MimetypeIconPipe,
        SafePipe,
        SafeUrlPipe,
        ImageDimensionsPipe,
        EllipsisPipe,
        ReadableUrlPipe,
        ConcatPipe,
        ConcatMapPipe
    ],
    imports: [CommonModule],
    exports: [
        DateFormatPipe,
        DateRelativePipe,
        DurationPipe,
        MimetypeIconPipe,
        SafePipe,
        SafeUrlPipe,
        ImageDimensionsPipe,
        EllipsisPipe,
        ReadableUrlPipe,
        ConcatPipe,
        ConcatMapPipe
    ]
})
export class CommonPipesModule {}
