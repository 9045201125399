<ion-button class="person-header-button__button" expand="block" [ngClass]="classes" (click)="emitClicked()">
    <ng-container *ngIf="icon !== 'website'; else websiteIcon">
        <i class="material-icons-outlined" aria-hidden="true" slot="start">
            {{ icon }}
        </i>
    </ng-container>

    <ng-template #websiteIcon>
        <cc-svg-icon
            class="person-header-button__website-icon"
            slot="start"
            name="website"
            aria-hidden="true"
        ></cc-svg-icon>
    </ng-template>

    <span>{{ label | translate }}</span>

    <ion-ripple-effect></ion-ripple-effect>
</ion-button>
