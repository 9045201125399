import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AgendaModuleEnabledFeatures } from '@shared/api';
import { PeopleSessions } from '../../models/people-session.model';

@Component({
    selector: 'cc-person-agenda-sessions',
    templateUrl: './person-agenda-sessions.component.html',
    styleUrls: ['./person-agenda-sessions.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PersonAgendaSessionsComponent {
    @Input()
    public agendas: { module: { name: string; id: number }; sessions: Array<{ slim: PeopleSessions.Session }> }[];

    @Input()
    public enabledFeatures: AgendaModuleEnabledFeatures;

    @Input()
    public personalAgendaSessions: number[];

    @Output()
    public sessionClick = new EventEmitter<PeopleSessions.Session>();

    public onSessionClick(session: PeopleSessions.Session): void {
        this.sessionClick.emit(session);
    }
}
