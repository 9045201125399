<div class="chat-detail-footer__container">
    <div class="chat-detail-footer__input">
        <ion-textarea
            class="chat-detail-footer__textarea"
            rows="1"
            auto-grow="true"
            autocapitalize="sentences"
            ccTextEmojis
            [placeholder]="'CHAT_POPUP_MESSAGE_PLACEHOLDER' | translate"
            [formControl]="messageControl"
            (keydown.enter)="$event.preventDefault(); onSend()"
        ></ion-textarea>

        <ion-button
            class="chat-detail-footer__emoji-button"
            fill="clear"
            shape="round"
            [attr.aria-label]="'CHAT_POPUP_EMOJI_BUTTON' | translate"
            (click)="onEmojiPickerClicked($event)"
        >
            <i class="material-icons-outlined" aria-hidden="true" slot="icon-only">emoji_emotions</i>
        </ion-button>

        <ion-button
            class="chat-detail-footer__submit-button"
            fill="clear"
            shape="round"
            type="submit"
            [attr.aria-label]="'CHAT_POPUP_SEND_BUTTON' | translate"
            [disabled]="!messageControl.valid"
            (click)="onSend()"
        >
            <i class="material-icons-outlined" aria-hidden="true" slot="icon-only">send</i>
        </ion-button>
    </div>
</div>
