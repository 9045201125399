import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonPipesModule } from '@common/pipes/pipes.module';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { LottieModule } from 'ngx-lottie';
import { ButtonLoadingSpinnerComponent } from './button-loading-spinner/button-loading-spinner.component';
import { CompanyIconComponent } from './company-icon/company-icon.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { ModalComponent } from './modal/modal.component';
import { NoDataComponent } from './no-data/no-data.component';
import { PageComponent } from './page/page.component';
import { PersonIconDeletedComponent } from './person-icon-deleted/person-icon-deleted.component';
import { PersonIconComponent } from './person-icon/person-icon.component';
import { PersonNameComponent } from './person-name/person-name.component';
import { PoweredByLogoComponent } from './powered-by-logo/powered-by-logo.component';
import { RefresherComponent } from './refresher/refresher.component';
import { SvgIconComponent } from './svg-icon/svg-icon.component';
import { TruncatedTextComponent } from './truncated-text/truncated-text.component';
import { FabButtonGroupComponent } from './fab-buttons/components/fab-button-group/fab-button-group.component';
import { FabButtonComponent } from './fab-buttons/components/fab-button/fab-button.component';
import { PageTitleComponent } from './page/components/page-title/page-title.component';
import { TooltipModule } from 'ng2-tooltip-directive-major-angular-updates';

@NgModule({
    declarations: [
        PoweredByLogoComponent,
        SvgIconComponent,
        NoDataComponent,
        PersonIconComponent,
        PersonIconDeletedComponent,
        LoadingSpinnerComponent,
        PageComponent,
        RefresherComponent,
        CompanyIconComponent,
        ButtonLoadingSpinnerComponent,
        PersonNameComponent,
        ModalComponent,
        TruncatedTextComponent,
        FabButtonGroupComponent,
        FabButtonComponent,
        PageTitleComponent
    ],
    imports: [CommonModule, IonicModule, TranslateModule, CommonPipesModule, LottieModule, TooltipModule],
    exports: [
        PoweredByLogoComponent,
        SvgIconComponent,
        NoDataComponent,
        PersonIconComponent,
        PersonIconDeletedComponent,
        LoadingSpinnerComponent,
        PageComponent,
        RefresherComponent,
        PersonNameComponent,
        CompanyIconComponent,
        ButtonLoadingSpinnerComponent,
        ModalComponent,
        TruncatedTextComponent,
        FabButtonGroupComponent,
        FabButtonComponent,
        PageTitleComponent
    ]
})
export class CommonComponentsModule {}
