import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'cc-global-message-banner',
    templateUrl: './global-message-banner.component.html',
    styleUrls: ['./global-message-banner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlobalMessageBannerComponent {
    @Input()
    public content: string;

    @Input()
    public dismissable: boolean;

    @Output()
    public dismiss = new EventEmitter<void>();
}
