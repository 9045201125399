export interface AppDocument {
    icon?: string;
    link?: string;
    icon_toggle?: AppDocument.IconToggleEnum;
    thumbnail?: string;
    uuid?: string;
    app: number;
    mimetype?: string;
    created?: Date;
    id?: number;
    filename?: string;
    is_active?: boolean;
    moderated?: boolean;
    name?: string;
    available_languages?: Array<string>;
}

export namespace AppDocument {
    export enum IconToggleEnum {
        Img = 'img',
        Icon = 'icon'
    }
}
