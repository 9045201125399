import { ExpandedApiDrivenNotification } from '@core/notifications/interfaces/expanded-api-driven-notification.interface';
import { EntityState } from '@ngrx/entity';
import { AppAlert } from '@shared/api';
import { AppState } from '../../../../core/root-store/store/app-state.model';
import { AlertsTabEnum } from '../../enums/alerts-tab.enum';

export const alertsStoreKey = 'alerts';

export interface AlertsState {
    currentTab: AlertsTabEnum;
    unreadNotificationCount: number;
    alerts: AlertsCollectionState;
}

export interface AlertsCollectionState extends EntityState<AppAlert> {
    loading: boolean;
    total: number;
}

export interface NotificationsCollectionState extends EntityState<ExpandedApiDrivenNotification> {
    loading: boolean;
    total: number;
}

export interface AlertsAppState extends AppState {
    [alertsStoreKey]: AlertsState;
}
