import { Injectable } from '@angular/core';
import { AnalyticsOptionsService } from '../../../core/analytics/services/analytics-options.service';
import { AnalyticsService } from '../../../core/analytics/services/analytics.service';
import { DocumentsAnalyticsKeys } from '../constants/documents-analytics-keys.constant';
import { DownloadService } from '@shared/utilities/download/download.service';

@Injectable({
    providedIn: 'root'
})
export class DocumentsService {
    constructor(
        private analyticsOptionsService: AnalyticsOptionsService,
        private analyticsService: AnalyticsService,
        private downloadService: DownloadService
    ) {}

    async openDocument(id: number, link: string): Promise<void> {
        this.sendDocumentAnalytics(id);
        return this.downloadService.download(link);
    }

    private sendDocumentAnalytics(id: number): void {
        const options = this.analyticsOptionsService.clickItem(DocumentsAnalyticsKeys.documentsListItem, id);
        this.analyticsService.emit(options);
    }
}
