export interface LiveDisplayBanner {
    id?: number;
    app: string;
    background_color?: string;
    toggle_background?: LiveDisplayBanner.ToggleBackgroundEnum;
    is_visible?: boolean;
    text?: string;
    time_visible?: boolean;
    text_color?: string;
    background_image?: number;
    left_image?: number;
}

export namespace LiveDisplayBanner {
    export enum ToggleBackgroundEnum {
        Color = 'background_color',
        Image = 'background_image'
    }
}
