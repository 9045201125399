import { Component, OnInit } from '@angular/core';
import { ContainerService } from '../../../../features/container/services/container.service';
import { TranslateService } from '@ngx-translate/core';
import { SplashScreenService } from '../../../native/services/splash-screen.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PageRoutes } from '../../../routing/constants/page-routes.constant';
import { Observable } from 'rxjs';
import { AppRouteParams } from '@core/routing/constants/app-route-params.constant';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { AppCoreActions } from '@core/root-store/store/app-core/actions/app-core.actions';
import { map, take } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';

@Component({
    selector: 'cc-app-failed',
    templateUrl: './app-failed.component.html',
    styleUrls: ['./app-failed.component.scss']
})
export class AppFailedComponent implements OnInit {
    public retrying: boolean = false;
    public appName: string;
    public isRunningInContainer: boolean;

    constructor(
        private containerService: ContainerService,
        private translateService: TranslateService,
        private splashScreenService: SplashScreenService,
        private route: ActivatedRoute,
        private router: Router,
        private appCoreFacadeService: AppCoreFacadeService,
        private actions$: Actions
    ) {}

    public async ngOnInit(): Promise<void> {
        this.appName = this.route.snapshot.paramMap.get(AppRouteParams.appName);
        this.translateService.use('en');
        this.isRunningInContainer = this.containerService.isRunningInContainer(this.appName);
        this.splashScreenService.hide();

        // Retry on page load, if navigated directly to this page (eg. after page refresh)
        this.fromApp().subscribe((fromApp) => {
            if (!fromApp) {
                this.retry();
            }
        });
    }

    public returnToContainer(): void {
        this.containerService.resetApp();
        this.router.navigate([PageRoutes.container]);
    }

    public retry(): void {
        if (this.retrying) {
            return;
        }

        this.retrying = true;
        this.appCoreFacadeService.dispatch(AppCoreActions.initialiseApp({ appName: this.appName }));
        this.handleRetryResult();
    }

    public handleRetryResult(): void {
        this.actions$
            .pipe(ofType(AppCoreActions.initialiseAppSuccess, AppCoreActions.initialiseAppFailure), take(1))
            .subscribe(({ type }) => {
                if (type === AppCoreActions.initialiseAppSuccess.type) {
                    this.router.navigate([this.appName]);
                }
                if (type === AppCoreActions.initialiseAppFailure.type) {
                    setTimeout(() => (this.retrying = false), 1000); // Delay stops the user from spamming retry
                }
            });
    }

    /**
     * If this page was navigated to from the app failing to load, rather than navigated to directly (eg. after refresh)
     * @private
     */
    public fromApp(): Observable<boolean> {
        return this.appCoreFacadeService.getAppName().pipe(
            take(1),
            map((appName) => !!appName)
        );
    }
}
