<div class="agenda-card-documents-list__container">
    <div class="agenda-card-documents-list__header" @fadeIn>
        <i class="agenda-card-documents-list__icon material-icons"> folder </i>

        <ion-label class="agenda-card-documents-list__title">
            {{ 'AGENDA_CARDS_DOCUMENTS_LIST_TITLE' | translate }}
        </ion-label>
    </div>

    <div class="agenda-card-documents-list__items">
        <cc-agenda-card-document-item
            *ngFor="let document of documents"
            class="agenda-card-documents-list__item"
            [document]="document"
            @fadeIn
        ></cc-agenda-card-document-item>
    </div>
</div>
