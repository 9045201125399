<div *ngIf="poll" class="poll">
    <div class="poll__content-container">
        <ion-card class="poll__content">
            <h2>{{ poll.title }}</h2>
            <p *ngIf="canSubmit" class="poll__description">
                {{ getDescription() | translate: { min: choicePoll.min_choices, max: choicePoll.max_choices } }}
            </p>
            <p *ngIf="!canSubmit" class="poll__description">
                {{ 'SURVEYS_BLURB_ANSWERED' | translate }}
            </p>
            <ion-card class="poll__option" *ngFor="let choice of choicePollChoiceFormItems">
                <ion-button
                    [ngClass]="{ selected: choice.selected }"
                    [disabled]="choice.disabled || !canSubmit || closed"
                    (click)="onChoiceClick(choice)"
                    >{{ choice.text }}</ion-button
                >
            </ion-card>
        </ion-card>
    </div>

    <cc-poll-controls
        [submitButtonText]="submitButtonText"
        [disabled]="!formIsValid() || !canSubmit"
        [submitting]="submitting"
        [closed]="closed"
        [showPreviousButton]="showPreviousButton"
        [showNextButton]="showNextButton"
        (pollSubmit)="onPollSubmit()"
        (pollNext)="onPollNext()"
        (pollPrevious)="onPollPrevious()"
    ></cc-poll-controls>
</div>
