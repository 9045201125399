import { GenericLocation, PinDrop } from '@shared/api';

export interface LocationMap extends GenericLocation {
    order?: number;
    uuid?: string;
    app: string;
    created?: Date;
    filename?: string;
    is_active?: boolean;
    image?: string;
    description?: string;
    module: number;
    map_type?: LocationMap.MapTypeEnum;
    google_link?: string;
    pindrops: PinDrop[];
}

export namespace LocationMap {
    export enum MapTypeEnum {
        Img = 'img',
        Gmap = 'gmap'
    }
}
