<div *ngIf="visible">
    <div class="title">{{ title | translate }}</div>
    <div *ngIf="countdown$ | async as countdown" class="row">
        <div class="item">
            {{ 'HTML_COUNTDOWN_DAYS' | translate }}
            <div class="block">{{ countdown.days }}</div>
        </div>
        <div class="item">
            {{ 'HTML_COUNTDOWN_HOURS' | translate }}
            <div class="block">{{ countdown.hours }}</div>
        </div>
        <div class="item">
            {{ 'HTML_COUNTDOWN_MINUTES' | translate }}
            <div class="block">{{ countdown.minutes }}</div>
        </div>
        <div class="item">
            {{ 'HTML_COUNTDOWN_SECONDS' | translate }}
            <div class="block">{{ countdown.seconds }}</div>
        </div>
    </div>
</div>
