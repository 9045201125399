<ion-tab-bar
    *ngIf="{
        modules: modules$ | async,
        tabbarModules: tabbarModules$ | async,
        shouldShowLabels: shouldShowLabels$ | async,
        selectedModuleId: selectedModuleId$ | async,
        portalConfig: portalConfig$ | async
    } as tabbarData"
    color="sidebar"
    class="tabbar__container"
>
    <ion-tab-button
        *ngFor="let module of tabbarData.tabbarModules"
        class="tabbar__item"
        [selected]="tabbarData.selectedModuleId === module.id"
        [routerLink]="module.routeCommands"
        (click)="onModuleClick()"
    >
        <cc-module-icon [module]="module" [attr.aria-label]="module.name"></cc-module-icon>
        <ion-label *ngIf="tabbarData.shouldShowLabels" class="tabbar__label">{{ module.name }}</ion-label>
    </ion-tab-button>

    <ion-tab-button
        class="tabbar__item"
        (click)="openMore(tabbarData.modules, tabbarData.selectedModuleId, tabbarData.portalConfig)"
    >
        <i aria-hidden="true" class="material-icons tabbar-icon">more_horiz</i>
        <ion-label *ngIf="tabbarData.shouldShowLabels">{{ 'TABBAR_MORE' | translate }}</ion-label>
    </ion-tab-button>
</ion-tab-bar>
