import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PollItem } from '@features/live-polling/models/poll-item.model';
import { PollSubmission } from '@features/live-polling/models/poll-submission.model';
import { Poll } from '@shared/api';
import { ChoicePollChoiceFormItem } from '../../models/choice-poll-choice-ui-item.model';

@Component({
    selector: 'cc-poll',
    templateUrl: './poll.component.html',
    styleUrls: ['./poll.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PollComponent {
    @Input()
    pollItem: PollItem;

    @Input()
    choicePollChoiceFormItems: ChoicePollChoiceFormItem[];

    @Input()
    submitting: boolean;

    @Input()
    closed: boolean;

    @Input()
    showNextButton: boolean;

    @Input()
    showPreviousButton: boolean;

    @Input()
    initialValue: { value: number | string };

    @Input()
    submitButtonText: string = 'SUBMIT';

    @Output()
    pollSubmit = new EventEmitter<PollSubmission>();

    @Output()
    pollNext = new EventEmitter<void>();

    @Output()
    pollPrevious = new EventEmitter<void>();

    PollTypeEnum = Poll.PollTypeEnum;

    onPollSubmit(submission: string | number | number[]): void {
        this.pollSubmit.emit({
            submissionValue: submission,
            pollId: this.pollItem.poll.id
        });
    }

    onPollNext(): void {
        this.pollNext.emit();
    }

    onPollPrevious(): void {
        this.pollPrevious.emit();
    }
}
