import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PeopleDetailActions } from '../actions/people-detail.actions';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AppCoreFacadeService } from '../../../../core/app-core/services/app-core-facade.service';
import { HttpErrorResponse } from '@angular/common/http';
import { forkJoin, of } from 'rxjs';
import { PeopleApiActions } from '../actions/people-api.actions';
import { AuthenticationFacadeService } from '../../../../core/authentication/services/authentication-facade.service';
import { AnalyticsOptionsService } from '../../../../core/analytics/services/analytics-options.service';
import { AnalyticsService } from '../../../../core/analytics/services/analytics.service';
import { AgendaAnalyticsKeys } from '../../../agenda/constants/agenda-analytics-keys.constant';
import { PeopleAnalyticsKeys } from '../../constants/people-analytics-keys.constant';
import { PeopleApiService } from '../../services/people-api.service';
import { LoginPromptService } from '../../../login/services/login-prompt.service';
import { TranslateService } from '@ngx-translate/core';
import { VideoCallService } from '../../../video-calls/services/video-call.service';
import { VideoCallsApiActions } from '../../../video-calls/store/actions/video-calls-api.actions';
import { DocumentsService } from '../../../documents/services/documents.service';
import { convertCustomValuesToFields } from '../../../../shared/custom-fields/functions/custom-value.functions';
import { MableCustomValue } from '../../../../shared/api';
import { AppRoutingActions } from '../../../../core/root-store/store/app-routing/actions/app-routing.actions';
import { AppPageRoutes } from '../../../../core/routing/constants/app-page-routes.constant';
import { PeopleFacadeService } from '@features/people/services/people-facade.service';
import { BusinessCardsActions } from '@shared/business-cards/store/actions/business-cards.actions';
import { PersonActionService } from '@features/people/services/person-action/person-action.service';
import { AppModuleRoutes } from '@core/routing/constants/module-routes.constant';

@Injectable()
export class PeopleDetailEffects {
    fetchPerson$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PeopleDetailActions.enterPage),
            tap(({ personId }) => {
                const options = this.analyticsOptionsService.viewPage(PeopleAnalyticsKeys.peoplePage, personId);
                this.analyticsService.emit(options);
            }),
            withLatestFrom(
                this.appCoreFacadeService.getAppName(),
                this.authenticationFacadeService.getAuthenticatedPerson()
            ),
            switchMap(([{ personId }, appUrl, authenticatedPerson]) =>
                forkJoin([
                    this.peopleApiService.getExpandedPerson(appUrl, personId),
                    this.peopleApiService.getPeopleSessions(appUrl, personId)
                ]).pipe(
                    map(([person, sessions]) => {
                        const isOwnProfile = authenticatedPerson ? authenticatedPerson.id === personId : false;
                        const customFields = convertCustomValuesToFields(
                            person.custom_values as MableCustomValue[],
                            isOwnProfile
                        );
                        return PeopleApiActions.fetchDetailSuccess({
                            person,
                            sessions,
                            customFields
                        });
                    }),
                    catchError((error: HttpErrorResponse) => of(PeopleApiActions.fetchDetailFailure({ error })))
                )
            )
        )
    );

    fetchPersonBusinessCard$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PeopleApiActions.fetchDetailSuccess),
            withLatestFrom(
                this.peopleFacadeService
                    .getPerson()
                    .pipe(switchMap((person) => this.personActionService.canUseBusinessCards(person))),
                this.peopleFacadeService.getPerson(),
                this.appCoreFacadeService.getAppName()
            ),
            filter(([_, canUseBusinessCards]) => canUseBusinessCards),
            switchMap(([_, __, person, appName]) =>
                this.peopleApiService.getPersonPublicBusinessCard(appName, person.id).pipe(
                    map((businessCard) => PeopleApiActions.fetchDetailBusinessCardSuccess({ businessCard })),
                    catchError((error: HttpErrorResponse) =>
                        of(PeopleApiActions.fetchDetailBusinessCardFailure({ error }))
                    )
                )
            )
        )
    );

    openExchangeBusinessCardModal$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PeopleDetailActions.openExchangeBusinessCardModal),
            withLatestFrom(this.peopleFacadeService.getPerson()),
            map(([_, person]) => BusinessCardsActions.openExchangeModal({ personId: person.id }))
        )
    );

    navigateToSession$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PeopleDetailActions.agendaItemClick),
            tap(({ sessionId }) => {
                const options = this.analyticsOptionsService.clickItem(AgendaAnalyticsKeys.agendaListItem, sessionId);
                this.analyticsService.emit(options);
            }),
            map(({ sessionId, moduleId }) =>
                AppRoutingActions.goToAppModule({
                    urlSegments: [moduleId, AppModuleRoutes.agenda, AppPageRoutes.session, sessionId]
                })
            )
        )
    );

    openDocument$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(PeopleDetailActions.documentItemClick),
                tap(({ document }) => this.documentsService.openDocument(document.id, document.link))
            ),
        { dispatch: false }
    );

    openVideoChat$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PeopleDetailActions.openVideoChat),
            withLatestFrom(
                this.authenticationFacadeService.getAuthenticatedPerson(),
                this.appCoreFacadeService.getAppName()
            ),
            filter(([{ person }, loggedInUser]) => {
                if (loggedInUser && person.accepts_video_calls) {
                    return true;
                }

                if (!person.accepts_video_calls) {
                    const { first_name, last_name } = person;
                    this.videoCallService.showCallerUnavailableDialog({ first_name, last_name });
                } else {
                    const translation = this.translateService.instant('PEOPLE_LOGIN_TO_VIDEO_CHAT');
                    this.loginPromptService.showPrompt(translation);
                }
                return false;
            }),
            switchMap(([{ person }, , appUrl]) =>
                this.peopleApiService.inviteToCall(appUrl, person.id).pipe(
                    map((incomingCall) => VideoCallsApiActions.inviteToCallSuccess({ incomingCall })),
                    catchError((error: HttpErrorResponse) => {
                        this.videoCallService.windowTab.close();
                        return of(VideoCallsApiActions.inviteToCallFailure({ error }));
                    })
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private peopleApiService: PeopleApiService,
        private appCoreFacadeService: AppCoreFacadeService,
        private authenticationFacadeService: AuthenticationFacadeService,
        private analyticsOptionsService: AnalyticsOptionsService,
        private analyticsService: AnalyticsService,
        private loginPromptService: LoginPromptService,
        private translateService: TranslateService,
        private videoCallService: VideoCallService,
        private documentsService: DocumentsService,
        private peopleFacadeService: PeopleFacadeService,
        private personActionService: PersonActionService
    ) {}
}
