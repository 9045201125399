import { createFeatureSelector, createSelector } from '@ngrx/store';
import { userBusinessCardsKey, UserBusinessCardsState } from '../models/user-business-cards-state.model';

const userBadgeContactsStateSelector = createFeatureSelector<UserBusinessCardsState>(userBusinessCardsKey);
const userBadgeContactsEditPageStateSelector = createSelector(userBadgeContactsStateSelector, (state) => state.edit);

const selectAppSettings = createSelector(userBadgeContactsEditPageStateSelector, (state) => state.appSettings);
const selectAppDesign = createSelector(userBadgeContactsEditPageStateSelector, (state) => state.appDesign);

export const UserBusinessCardsEditPageSelectors = {
    selectAppSettings,
    selectAppDesign
};
