<div class="app-failed-container">
    <ion-card>
        <ion-card-header>
            <ion-card-title>
                {{ 'ERROR_APP_FAILED_TO_LOAD_A' | translate }}
            </ion-card-title>
            <ion-card-subtitle>
                {{ 'ERROR_APP_FAILED_TO_LOAD_B' | translate }}
            </ion-card-subtitle>
        </ion-card-header>
        <ion-card-content>
            <ion-button *ngIf="!isRunningInContainer" [disabled]="retrying" (click)="retry()">
                <span *ngIf="!retrying">
                    {{ 'ERROR_APP_FAILED_RETRY' | translate }}
                </span>
                <ion-spinner *ngIf="retrying" slot="icon-only"></ion-spinner>
            </ion-button>
            <ion-button *ngIf="isRunningInContainer" (click)="returnToContainer()">
                {{ 'APP_SIDEBAR_RIGHT_CONTAINER' | translate }}
            </ion-button>
        </ion-card-content>
    </ion-card>
</div>
