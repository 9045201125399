<div
    *ngIf="{
        resource: resource$ | async,
        isLoading: isLoading$ | async,
        hasReachedEnd: hasReachedEnd$ | async,
        hasOverflowed: hasOverflowed$ | async,
        canScrollForwards: canScrollForwards$ | async,
        canScrollBackwards: canScrollBackwards$ | async
    } as state"
    class="agenda-card-attendees-list__container"
    ccIntersectionObserver
    (visibilityChange)="onVisibilityChanged($event)"
>
    <div class="agenda-card-attendees-list__header" @fadeIn>
        <i class="agenda-card-attendees-list__icon material-icons"> groups </i>

        <ion-label class="agenda-card-attendees-list__title">
            {{ 'AGENDA_CARDS_ATTENDEES_LIST_TITLE' | translate }} - {{ state.resource?.count }}
        </ion-label>
    </div>

    <div class="agenda-card-attendees-list__inner" (click)="preventEventPropagation($event)">
        <div *ngIf="state.hasOverflowed" class="agenda-card-attendees-list__previous" @fadeIn>
            <ion-button
                class="agenda-card-attendees-list__previous-button ion-no-margin"
                fill="clear"
                shape="round"
                [disabled]="!state.canScrollBackwards"
                (click)="scrollBackwards($event)"
            >
                <i class="material-icons" slot="icon-only"> keyboard_arrow_left </i>
            </ion-button>
        </div>

        <div
            class="agenda-card-attendees-list__items"
            ccInfiniteScroll
            ccInfiniteScrollDirection="horizontal"
            [ccInfiniteScrollIsLoading]="state.isLoading"
            [ccInfiniteScrollHasReachedEnd]="state.hasReachedEnd"
            (ccInfiniteScrollReachEnd)="fetchNextPage()"
            (scroll)="onScroll()"
            #attendeesList
        >
            <cc-agenda-card-attendee-item
                *ngFor="let attendee of state.resource?.results; trackBy: trackByFn"
                class="agenda-card-attendees-list__item"
                [attendee]="attendee"
                @fadeIn
                @fadeOut
            ></cc-agenda-card-attendee-item>
        </div>

        <div *ngIf="state.hasOverflowed" class="agenda-card-attendees-list__next" @fadeIn>
            <ion-button
                class="agenda-card-attendees-list__next-button ion-no-margin"
                fill="clear"
                shape="round"
                [disabled]="!state.canScrollForwards"
                (click)="scrollForwards($event)"
            >
                <i *ngIf="!state.isLoading" class="material-icons" slot="icon-only"> keyboard_arrow_right </i>
                <ion-spinner *ngIf="state.isLoading" slot="icon-only"></ion-spinner>
            </ion-button>
        </div>
    </div>
</div>
