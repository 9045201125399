import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { InlineSession } from '@features/agenda/agenda-module/models/inline-session.model';

@Component({
    selector: 'cc-agenda-card-expand-button',
    templateUrl: './agenda-card-expand-button.component.html',
    styleUrl: './agenda-card-expand-button.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgendaCardExpandButtonComponent {
    @Input({ required: true })
    public session: InlineSession;

    @Input({ required: true })
    public expanded: boolean;

    @Output()
    public toggleExpandedClicked: EventEmitter<void> = new EventEmitter<void>();

    public onToggleExpandedClicked(): void {
        this.toggleExpandedClicked.emit();
    }
}
