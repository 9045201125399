import { CookieConfigurationState } from '@core/banner/interfaces/cookie-configuration-state.interface';
import { GlobalBannerConfig } from '@core/banner/interfaces/global-banner-config.interface';
import { AppState } from '@core/root-store/store/app-state.model';

export const bannerStoreKey = 'banner';

export type GlobalBannerState = GlobalBannerConfig & { hasDismissed: boolean; enabled: boolean };

export interface BannerState {
    globalBanner: GlobalBannerState;
    cookies: CookieConfigurationState;
}

export interface BannerAppState extends AppState {
    [bannerStoreKey]: BannerState;
}
