import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { InlineSession } from '@features/agenda/agenda-module/models/inline-session.model';
import { ExpandedSession } from '@features/agenda/agenda-session/models/expanded-session.model';
import { AgendaModuleEnabledFeatures } from '@shared/api/models/AgendaModule';

@Component({
    selector: 'cc-agenda-card',
    templateUrl: './agenda-card.component.html',
    styleUrls: ['./agenda-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgendaCardComponent {
    @Input()
    public session: InlineSession;

    @Input()
    public expandedSession: ExpandedSession;

    @Input()
    public enabledFeatures: AgendaModuleEnabledFeatures;

    @Input()
    public showTicket = true;

    @Input()
    public showDate: boolean;

    @Input()
    public isInPersonalAgenda: boolean;

    @Input()
    public isOnNow: boolean;

    @Input()
    public isTogglingInProgress: boolean;

    @Input()
    public flatStyling: boolean;

    @Input()
    public isOnWaitingList: boolean;

    @Input()
    public addToMySchedule: boolean;

    @Output()
    public sessionClick = new EventEmitter<InlineSession>();

    @Output()
    public ticketClick = new EventEmitter<void>();

    @Output()
    public sessionExpanded = new EventEmitter<void>();

    public expanded: boolean = false;

    public onToggleExpandedClicked(): void {
        this.expanded = !this.expanded;

        if (this.expanded) {
            this.sessionExpanded.emit();
        }
    }

    public onSessionClick(): void {
        this.sessionClick.emit();
    }

    public onTicketClick(): void {
        this.ticketClick.emit();
    }
}
