import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { TooltipModule } from 'ng2-tooltip-directive-major-angular-updates';
import { CommonComponentsModule } from './components/components.module';
import { CommonDirectivesModule } from './directives/directives.module';
import { CommonPipesModule } from './pipes/pipes.module';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        RxReactiveFormsModule,
        LayoutModule,
        TooltipModule,
        CommonPipesModule,
        CommonDirectivesModule,
        CommonComponentsModule
    ],
    exports: [
        CommonModule,
        IonicModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        RxReactiveFormsModule,
        LayoutModule,
        TooltipModule,
        CommonPipesModule,
        CommonDirectivesModule,
        CommonComponentsModule
    ]
})
export class AppCommonModule {}
