import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ChatRouteParams } from '@features/chat/constants/chat-route-params.constant';
import { map } from 'rxjs/operators';

@Component({
    selector: 'cc-chat-page',
    templateUrl: './chat-page.component.html',
    styleUrls: ['./chat-page.component.scss']
})
export class ChatPageComponent implements OnInit {
    chatId$: Observable<string>;

    constructor(private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.chatId$ = this.route.paramMap.pipe(map((params) => params.get(ChatRouteParams.chatId)));
    }
}
