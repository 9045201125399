import { Injectable } from '@angular/core';
import * as Ably from 'ably';

@Injectable({
    providedIn: 'root'
})
export class AblyFactoryService {
    public createAblyClient(options: Ably.Types.ClientOptions): Ably.Realtime {
        return new Ably.Realtime(options);
    }
}
