import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppTagComponent } from './components/app-tag/app-tag.component';
import { TagListComponent } from './components/tag-list/tag-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { TagFiltersComponent } from './components/tag-filters/tag-filters.component';
import { TagFilterDialogComponent } from './components/tag-filter-dialog/tag-filter-dialog.component';
import { AppCommonModule } from '@common/common.module';
import { SearchModule } from '@shared/search/search.module';

@NgModule({
    declarations: [AppTagComponent, TagListComponent, TagFiltersComponent, TagFilterDialogComponent],
    imports: [CommonModule, TranslateModule, AppCommonModule, SearchModule],
    exports: [AppTagComponent, TagListComponent, TagFiltersComponent, TagFilterDialogComponent],
    providers: []
})
export class AppTagModule {}
