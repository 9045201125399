import { createActionGroup, props } from '@ngrx/store';
import { ChallengeCompletion } from '@shared/api';

export const ChallengeCompletionActions = createActionGroup({
    source: 'Challenge Completion',
    events: {
        'Challenge Completed': props<{ challengeId: number; completion: ChallengeCompletion }>(),
        'Challenge Failed': props<{ challengeId: number }>()
    }
});
