<ng-container
    *ngIf="{
        shouldDisplay: shouldDisplay$ | async,
        activeTimezone: activeTimezone$ | async,
        tooltipText: tooltipText$ | async
    } as data"
>
    <span
        *ngIf="data.shouldDisplay"
        class="timezone-tooltip__text"
        [tooltip]="data.tooltipText | translate: { timezone: data.activeTimezone }"
    >
        ({{ data.activeTimezone }})
    </span>
</ng-container>
