import { NgModule } from '@angular/core';
import { AppCommonModule } from '@common/common.module';
import { LocaleModule } from '@core/locale/locale.module';
import { ScrollModule } from '@shared/scroll/scroll.module';
import { TooltipModule } from 'ng2-tooltip-directive-major-angular-updates';
import { AgendaCardComponent } from './components/agenda-card/agenda-card.component';
import { CapacityStatusComponent } from './components/capacity-status/capacity-status.component';
import { LocationChipsComponent } from './components/location-chips/location-chips.component';
import { ScheduleListComponent } from './components/schedule-list/schedule-list.component';
import { TrackChipsComponent } from './components/track-chips/track-chips.component';
import { AgendaCardAttendeesListComponent } from './components/agenda-card-attendees-list/agenda-card-attendees-list.component';
import { AgendaCardAttendeeItemComponent } from './components/agenda-card-attendee-item/agenda-card-attendee-item.component';
import { PaginationModule } from '@shared/pagination/pagination.module';
import { AgendaCardFeatureIconsComponent } from './components/agenda-card-feature-icons/agenda-card-feature-icons.component';
import { AgendaCardScheduleButtonComponent } from './components/agenda-card-schedule-button/agenda-card-schedule-button.component';
import { AgendaCardExpandedComponent } from './components/agenda-card-expanded/agenda-card-expanded.component';
import { AgendaCardSpeakerItemComponent } from './components/agenda-card-speaker-item/agenda-card-speaker-item.component';
import { AgendaCardDocumentItemComponent } from './components/agenda-card-document-item/agenda-card-document-item.component';
import { AgendaCardExpandButtonComponent } from './components/agenda-card-expand-button/agenda-card-expand-button.component';
import { PersonPopoverModule } from '@shared/person-popover/person-popover.module';
import { AgendaCardSpeakersListComponent } from './components/agenda-card-speakers-list/agenda-card-speakers-list.component';
import { AgendaCardDocumentsListComponent } from './components/agenda-card-documents-list/agenda-card-documents-list.component';

@NgModule({
    declarations: [
        ScheduleListComponent,
        TrackChipsComponent,
        LocationChipsComponent,
        CapacityStatusComponent,
        AgendaCardComponent,
        CapacityStatusComponent,
        AgendaCardAttendeesListComponent,
        AgendaCardAttendeeItemComponent,
        AgendaCardFeatureIconsComponent,
        AgendaCardScheduleButtonComponent,
        AgendaCardExpandedComponent,
        AgendaCardExpandButtonComponent,
        AgendaCardSpeakerItemComponent,
        AgendaCardDocumentItemComponent,
        AgendaCardSpeakersListComponent,
        AgendaCardDocumentsListComponent
    ],
    imports: [AppCommonModule, ScrollModule, TooltipModule, LocaleModule, PaginationModule, PersonPopoverModule],
    exports: [
        ScheduleListComponent,
        TrackChipsComponent,
        LocationChipsComponent,
        CapacityStatusComponent,
        AgendaCardComponent
    ]
})
export class SharedAgendaModule {}
