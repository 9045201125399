import { Injectable } from '@angular/core';
import { UserBusinessCardsState } from '@features/user-business-cards/store/models/user-business-cards-state.model';
import { Action, Store } from '@ngrx/store';
import { SocialInfo } from '@shared/api';
import { BusinessCardDesign } from '@shared/business-cards/interfaces/business-card-design.interface';
import { BusinessCardsSelectors } from '@shared/business-cards/store/selectors/user-business-cards-shared.selectors';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BusinessCardsFacadeService {
    constructor(private store: Store<UserBusinessCardsState>) {}

    public getMyCardDesign(): Observable<BusinessCardDesign> {
        return this.store.select(BusinessCardsSelectors.selectDesign);
    }

    public getMySocialInfo(): Observable<Array<SocialInfo>> {
        return this.store.select(BusinessCardsSelectors.selectSocialInfo);
    }

    public dispatch(action: Action): void {
        this.store.dispatch(action);
    }
}
