import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BusinessCardsEffects } from './effects/business-cards.effects';
import { businessCardsKey } from './models/business-cards-state.model';
import { businessCardsReducer } from './reducers/business-cards.reducer';

@NgModule({
    imports: [
        StoreModule.forFeature(businessCardsKey, businessCardsReducer),
        EffectsModule.forFeature([BusinessCardsEffects])
    ]
})
export class BusinessCardsStoreModule {}
