import { Injectable } from '@angular/core';
import { UserBusinessCardsOrder } from '@features/user-business-cards/enums/user-business-cards-order.enum';
import { BusinessCardRequest } from '@features/user-business-cards/interfaces/business-card-request.interface';
import { BusinessCardSettings } from '@features/user-business-cards/interfaces/business-card-settings.interface';
import { UserBusinessCardsState } from '@features/user-business-cards/store/models/user-business-cards-state.model';
import { UserBusinessCardsEditPageSelectors } from '@features/user-business-cards/store/selectors/user-business-cards-edit-page.selectors';
import { UserBusinessCardsPageSelectors } from '@features/user-business-cards/store/selectors/user-business-cards-page.selectors';
import { Action, Store } from '@ngrx/store';
import { BusinessCardDesign } from '@shared/business-cards/interfaces/business-card-design.interface';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserBusinessCardsFacadeService {
    constructor(private store: Store<UserBusinessCardsState>) {}

    public getContacts(): Observable<Array<BusinessCardRequest>> {
        return this.store.select(UserBusinessCardsPageSelectors.selectContacts);
    }

    public getContactsWithoutSelected(): Observable<Array<BusinessCardRequest>> {
        return this.store.select(UserBusinessCardsPageSelectors.selectContactsWithoutSelected);
    }

    public getContactsSelected(): Observable<BusinessCardRequest> {
        return this.store.select(UserBusinessCardsPageSelectors.selectContactsSelected);
    }

    public getContactsLoading(): Observable<boolean> {
        return this.store.select(UserBusinessCardsPageSelectors.selectContactsLoading);
    }

    public getContactsTotal(): Observable<number> {
        return this.store.select(UserBusinessCardsPageSelectors.selectContactsTotal);
    }

    public getContactsOffset(): Observable<number> {
        return this.store.select(UserBusinessCardsPageSelectors.selectContactsOffset);
    }

    public getContactsSearching(): Observable<boolean> {
        return this.store.select(UserBusinessCardsPageSelectors.selectContactsSearching);
    }

    public getContactsSearch(): Observable<string> {
        return this.store.select(UserBusinessCardsPageSelectors.selectContactsSearch);
    }

    public getContactsOrdering(): Observable<UserBusinessCardsOrder> {
        return this.store.select(UserBusinessCardsPageSelectors.selectContactsOrdering);
    }

    public getRequests(): Observable<Array<BusinessCardRequest>> {
        return this.store.select(UserBusinessCardsPageSelectors.selectRequests);
    }

    public getRequestsLoading(): Observable<boolean> {
        return this.store.select(UserBusinessCardsPageSelectors.selectRequestsLoading);
    }

    public getRequestsTotal(): Observable<number> {
        return this.store.select(UserBusinessCardsPageSelectors.selectRequestsTotal);
    }

    public getRequestsOffset(): Observable<number> {
        return this.store.select(UserBusinessCardsPageSelectors.selectRequestsOffset);
    }

    public getFlipped(): Observable<boolean> {
        return this.store.select(UserBusinessCardsPageSelectors.selectFlipped);
    }

    public getAppBusinessCardDesign(): Observable<BusinessCardDesign> {
        return this.store.select(UserBusinessCardsEditPageSelectors.selectAppDesign);
    }

    public getAppBusinessCardSettings(): Observable<BusinessCardSettings> {
        return this.store.select(UserBusinessCardsEditPageSelectors.selectAppSettings);
    }

    public dispatch(action: Action): void {
        this.store.dispatch(action);
    }
}
