import { Component, Input } from '@angular/core';
import { SessionLocation } from '@features/agenda/models/session-location.model';
import { LocationService } from '@features/map/services/location/location.service';
import { Location, LocationType } from '@shared/api';

@Component({
    selector: 'cc-location-chips',
    templateUrl: './location-chips.component.html',
    styleUrls: ['./location-chips.component.scss']
})
export class LocationChipsComponent {
    @Input()
    public locations: (Location | SessionLocation)[];

    @Input()
    public clickable = true;

    public locationType = LocationType;

    constructor(private locationService: LocationService) {}

    public onLocationClick(location: Location | SessionLocation): void {
        if (!this.clickable) {
            return;
        }

        this.locationService.openLocation(location);
    }
}
