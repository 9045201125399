import { environment } from '../../../environments/environment';
export const ApiSettings = {
    baseUrl: (endpointUrl: string) => `${environment.API_URL.replace(/\/$/, '')}${endpointUrl}`,
    fullUrl: (appName, endpointUrl) => `${environment.API_URL}apps/${appName}${endpointUrl}`,
    fullUrlV2: (appName, endpointUrl) => `${environment.API_URL}v2/apps/${appName}${endpointUrl}`,
    branding: '/theme/',
    customFields: '/custom-fields/',
    customValues: '/custom-values/',
    customFieldGroups: '/custom-field-groups/',
    downloadTokenFullUrl: () => `${environment.API_URL}users/get-download-token/`,
    editProfile: `/edit-profile/`,
    peopleModule: (moduleId: number) => {
        return `/modules/${moduleId}/people/`;
    },
    person: (personId: number | string) => {
        return `/peoples/${personId}/`;
    },
    peopleVisibility: '/peoplevisibility/',
    socialMediaLinks: '/social-info/',
    modules: '/authorized/',
    pindrops: '/pindrops/',
    maps: '/module-types/map/',
    emailSurveys: (userId) => `/users/${userId}/email-survey-responses/`,
    livePollGroup: (id: number) => `/livepollgroups/${id}/`,
    livePollGroups: '/livepollgroups/',
    livePollingModule: (moduleId: number) => `/modules/${moduleId}/livepolling/`
};
