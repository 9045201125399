import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { NavigationItemState } from '../models/navigation-item-state.model';
import { NavigationHistorySelectors } from '../store/selectors/navigation-history.selectors';
import { Observable } from 'rxjs';
import { NavigationHistoryAppState } from '../store/models/navigation-history.state.model';

@Injectable({
    providedIn: 'root'
})
export class NavigationHistoryFacadeService {
    constructor(private store: Store<NavigationHistoryAppState>) {}

    getCurrentState(): Observable<NavigationItemState> {
        return this.store.select(NavigationHistorySelectors.currentState);
    }

    getHistory(): Observable<NavigationItemState[]> {
        return this.store.select(NavigationHistorySelectors.history);
    }

    dispatch(action: Action): void {
        this.store.dispatch(action);
    }
}
