import { Injectable } from '@angular/core';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { AppCoreActions } from '@core/root-store/store/app-core/actions/app-core.actions';
import { AuthenticationActions } from '@core/root-store/store/authentication/actions/authentication.actions';
import { ToastService } from '@core/toast/services/toast/toast.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BusinessCardsApiService } from '@shared/business-cards/services/business-cards-api/business-cards-api.service';
import { BusinessCardsModalService } from '@shared/business-cards/services/business-cards-modal/business-cards-modal.service';
import { catchError, filter, from, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { BusinessCardsActions } from '../actions/business-cards.actions';

@Injectable()
export class BusinessCardsEffects {
    public getSocialInfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BusinessCardsActions.getSocialInfo),
            withLatestFrom(this.appCoreFacadeService.getAppName()),
            switchMap(([_, appName]) =>
                this.businessCardsApiService.getSocialInfo(appName).pipe(
                    map((socialInfo) => BusinessCardsActions.getSocialInfoSuccess({ socialInfo })),
                    catchError((error) => of(BusinessCardsActions.getSocialInfoFailure({ error })))
                )
            )
        )
    );

    public getMyCardDesign$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BusinessCardsActions.getMyCardDesign),
            withLatestFrom(this.appCoreFacadeService.getAppName()),
            switchMap(([_, appName]) =>
                this.businessCardsApiService.getMyCardDesign(appName).pipe(
                    map((design) => BusinessCardsActions.getMyCardDesignSuccess({ design })),
                    catchError((error) => of(BusinessCardsActions.getMyCardDesignFailure({ error })))
                )
            )
        )
    );

    openExchangeModal$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BusinessCardsActions.openExchangeModal),
            switchMap(({ personId, isCompany }) =>
                from(this.businessCardsModalService.openExchangeBusinessCardModal()).pipe(
                    filter((result) => !!result?.send),
                    map(({ message }) => BusinessCardsActions.exchangeBusinessCard({ personId, message, isCompany }))
                )
            )
        )
    );

    exchangeBusinessCard$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BusinessCardsActions.exchangeBusinessCard),
            withLatestFrom(this.appCoreFacadeService.getAppName()),
            switchMap(([{ personId, message, isCompany }, appName]) =>
                this.businessCardsApiService
                    .exchangeBusinessCards(appName, personId, { message, is_company: isCompany })
                    .pipe(
                        map(() => BusinessCardsActions.exchangeBusinessCardSuccess()),
                        catchError((error) => of(BusinessCardsActions.exchangeBusinessCardFailure({ error })))
                    )
            )
        )
    );

    exchangeBusinessCardSuccessToast$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(BusinessCardsActions.exchangeBusinessCardSuccess),
                tap(() => this.toastService.success('BUSINESS_CARDS_EXCHANGE_SUCCESS_TOAST'))
            ),
        { dispatch: false }
    );

    reset$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppCoreActions.initialiseApp, AuthenticationActions.logout),
            map(() => BusinessCardsActions.reset())
        )
    );

    constructor(
        private actions$: Actions,
        private appCoreFacadeService: AppCoreFacadeService,
        private businessCardsApiService: BusinessCardsApiService,
        private businessCardsModalService: BusinessCardsModalService,
        private toastService: ToastService
    ) {}
}
