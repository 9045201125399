import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AuthenticationFacadeService } from '@core/authentication/services/authentication-facade.service';
import { ModalController } from '@ionic/angular';
import { People as Person, SocialInfo } from '@shared/api';
import { BusinessCardDesign } from '@shared/business-cards/interfaces/business-card-design.interface';
import { BusinessCardsFacadeService } from '@shared/business-cards/services/business-cards-facade/business-cards-facade.service';
import { BusinessCardsActions } from '@shared/business-cards/store/actions/business-cards.actions';
import { Observable } from 'rxjs';

@Component({
    selector: 'cc-business-card-exchange-modal',
    templateUrl: './business-card-exchange-modal.component.html',
    styleUrls: ['./business-card-exchange-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BusinessCardExchangeModalComponent implements OnInit {
    public profile$: Observable<Person>;
    public design$: Observable<BusinessCardDesign>;
    public social$: Observable<Array<SocialInfo>>;

    public messageControl: UntypedFormControl;

    constructor(
        private modalController: ModalController,
        private businessCardsFacadeService: BusinessCardsFacadeService,
        private authenticationFacadeService: AuthenticationFacadeService
    ) {}

    public ngOnInit(): void {
        this.messageControl = new UntypedFormControl('');

        this.profile$ = this.authenticationFacadeService.getAuthenticatedPerson();
        this.design$ = this.businessCardsFacadeService.getMyCardDesign();
        this.social$ = this.businessCardsFacadeService.getMySocialInfo();

        this.businessCardsFacadeService.dispatch(BusinessCardsActions.getMyCardDesign());
        this.businessCardsFacadeService.dispatch(BusinessCardsActions.getSocialInfo());
    }

    public dismiss(): void {
        this.modalController.dismiss();
    }

    public send(): void {
        this.modalController.dismiss({
            send: true,
            message: this.messageControl.value
        });
    }
}
