import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { ToolbarLeftType } from '../enums/toolbar-left-type.enum';
import { map, skip } from 'rxjs/operators';
import { PortalService } from '../../../features/portal/services/portal.service';
import { NavigationFacadeService } from './navigation-facade.service';
import { AppCoreFacadeService } from '../../app-core/services/app-core-facade.service';
import { AppCoreActions } from '../../root-store/store/app-core/actions/app-core.actions';
import { SidebarService } from './sidebar.service';
import { SidebarType } from '../enums/sidebar-type.enum';

@Injectable({
    providedIn: 'root'
})
export class ToolbarService {
    constructor(
        private appCoreFacadeService: AppCoreFacadeService,
        private portalService: PortalService,
        private navigationFacadeService: NavigationFacadeService,
        private sidebarService: SidebarService
    ) {}

    async handleMenuButtonClick(type: ToolbarLeftType): Promise<void> {
        switch (type) {
            case ToolbarLeftType.Apps:
                const appName = await this.appCoreFacadeService.getAppName().toPromise();
                return this.portalService.returnToParentPortal(appName);
            case ToolbarLeftType.Home:
                this.appCoreFacadeService.dispatch(AppCoreActions.navigateHome());
                break;
            case ToolbarLeftType.Menu:
                return this.sidebarService.open(SidebarType.Left);
        }
    }

    isPortalApp(): Observable<boolean> {
        return this.appCoreFacadeService
            .getAppName()
            .pipe(map((appName) => !!this.portalService.getPortalConfigForApp(appName)));
    }

    getMenuButtonType(): Observable<ToolbarLeftType> {
        return combineLatest([this.isPortalApp(), this.navigationFacadeService.getModules()]).pipe(
            /*
             * The skip operator is there to fix the issue where the sidebar is sometimes missing when
             * the app first loads due to the modules not being defined
             */
            skip(1),
            map(([isPortalApp, modules]) => {
                if (modules.length > 1) {
                    return ToolbarLeftType.Menu;
                }
                if (isPortalApp) {
                    return ToolbarLeftType.Apps;
                }
                return ToolbarLeftType.Home;
            })
        );
    }

    getShowMenuButton(): Observable<boolean> {
        return combineLatest([this.isPortalApp(), this.navigationFacadeService.getModules()]).pipe(
            map(([isPortalApp, modules]) => {
                const hasMoreThanOneModule = modules.length > 1;
                return hasMoreThanOneModule || isPortalApp;
            })
        );
    }
}
