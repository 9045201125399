import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SocialInfo } from '@shared/api';

@Component({
    selector: 'cc-person-social-media-links',
    templateUrl: './person-social-media-links.component.html',
    styleUrls: ['./person-social-media-links.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PersonSocialMediaLinksComponent {
    @Input()
    socialInfo: SocialInfo[];
}
