import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PeopleRouteParams } from './constants/people-route-params.constant';
import { PersonComponent } from './pages/person/person.component';
import { CustomRoute } from '@core/routing/types/custom-route';

const routes: CustomRoute[] = [
    {
        path: `:${PeopleRouteParams.personId}`,
        component: PersonComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PeopleRoutingModule {}
