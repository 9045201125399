import { Directive, ElementRef, EventEmitter, Output, OnDestroy } from '@angular/core';
import ResizeObserver from 'resize-observer-polyfill';

const entriesMap = new WeakMap();

const ro = new ResizeObserver((entries) => {
    for (const entry of entries) {
        if (entriesMap.has(entry.target)) {
            const comp: ResizeObserverDirective = entriesMap.get(entry.target);
            comp.resizeCallback(entry);
        }
    }
});

@Directive({
    selector: '[ccResizeObserver]'
})
export class ResizeObserverDirective implements OnDestroy {
    @Output()
    // eslint-disable-next-line @angular-eslint/no-output-native
    resize = new EventEmitter();

    constructor(private el: ElementRef) {
        const target = this.el.nativeElement;
        entriesMap.set(target, this);
        ro.observe(target);
    }

    public resizeCallback(entry): void {
        this.resize.emit(entry);
    }

    ngOnDestroy(): void {
        const target = this.el.nativeElement;
        ro.unobserve(target);
        entriesMap.delete(target);
    }
}
