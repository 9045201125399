import { NgModule } from '@angular/core';
import { AppCommonModule } from '@common/common.module';
import { QrScannerModalComponent } from './components/qr-scanner-modal/qr-scanner-modal.component';

@NgModule({
    declarations: [QrScannerModalComponent],
    imports: [AppCommonModule],
    exports: [QrScannerModalComponent]
})
export class QrScannerModule {}
