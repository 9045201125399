import { AppState } from '../../../../core/root-store/store/app-state.model';
import { LocationMap, MapModule } from '@shared/api';
import { Size } from '@shared/models/size.model';

export const mapStoreKey = 'map';

export interface MapState {
    maps: LocationMap[];
    mapsListPageState: MapsListPageState;
    mapDetailPageState: MapDetailPageState;
    mapModule: { [key: number]: MapModule };
}

export interface MapsListPageState {
    initialising: boolean;
    searchTerm: string;
    refreshing: boolean;
}

export interface MapDetailPageState {
    initialising: boolean;
    map: LocationMap;
    mapId: number;
    gmapState: {
        url: string;
    };
    leafletState: {
        imageSize: Size;
        activePindrops: number[];
        focused: boolean;
        mapSize: Size;
    };
}

export interface MapAppState extends AppState {
    [mapStoreKey]: MapState;
}
