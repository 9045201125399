import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { PollGroup, PollResponse, SurveyModule } from '@shared/api';
import { PollSubmission } from '../../../live-polling/models/poll-submission.model';

export const SurveysApiActions = createActionGroup({
    source: 'Surveys Api',
    events: {
        'Submit Poll Success': props<{ pollGroupId: number; submission: PollSubmission }>(),
        'Submit Poll Failure': props<{ error: HttpErrorResponse }>(),
        'Email Surveys Success': emptyProps(),
        'Email Surveys Failure': props<{ error: HttpErrorResponse }>(),
        'Get Surveys List Page Data Success': props<{
            surveyModule: SurveyModule;
            pollGroups: PollGroup[];
            pollResponses: PollResponse[];
        }>(),
        'Get Surveys List Page Data Failure': props<{ error: HttpErrorResponse }>(),
        'Get Personal Surveys List Page Data Success': props<{
            pollGroups: PollGroup[];
            pollResponses: PollResponse[];
        }>(),
        'Get Personal Surveys List Page Data Failure': props<{ error: HttpErrorResponse }>(),
        'Get Survey Page Data Success': props<{
            pollGroupDetail: PollGroup;
            pollResponses: PollResponse[];
        }>(),
        'Get Survey Page Data Failure': props<{ error: HttpErrorResponse }>(),
        'Get Survey Complete Page Data Success': props<{ pollGroupDetail: PollGroup }>(),
        'Get Survey Complete Page Data Failure': props<{ error: HttpErrorResponse }>()
    }
});
