import { Injectable } from '@angular/core';
import { TrackerTarget } from '@core/analytics/enums/tracker-target.enum';
import { GaBuilderService } from '@core/analytics/ga/services/ga-builder.service';
import { GtagBuilderService } from '@core/analytics/gtag/services/gtag-builder.service';
import { TagBuilder } from '@core/tag-management/models/tag-builder.interface';
import { Observable, of } from 'rxjs';
import { TagEntity } from '../models/tag-entity.interface';
import { TagTracker } from '../models/tag-token.interface';
import { TagType } from '../enums/tag-type.enum';
import { HotjarBuilderService } from '../tags/hotjar/services/hotjar-builder.service';

@Injectable({
    providedIn: 'root'
})
export class TagManagerService {
    private loadedBuilderServices: TagBuilder[] = [];

    constructor(
        private gtag: GtagBuilderService,
        private ga: GaBuilderService,
        private hotjar: HotjarBuilderService
    ) {}

    public get loadedBuilders(): TagBuilder[] {
        return this.loadedBuilderServices;
    }

    public removeTags(): Observable<void[]> {
        return of(this.loadedBuilderServices.map((tracker) => tracker.remove()));
    }

    public loadTags(types: TagType[], tags: TagEntity[]): Observable<(string | void)[]> {
        return of(this.buildTrackingTags(types, tags).map((tracker) => tracker.load()));
    }

    private buildTrackingTags(types: TagType[], tags: TagEntity[]): TagBuilder[] {
        return types.map((tracker) =>
            this.getBuilder(tracker, [
                ...tags
                    .filter((tag) => tag.tag_type === tracker)
                    .map(
                        (tag) => ({ name: tag.name ? tag.name : TrackerTarget.Client, token: tag.token }) as TagTracker
                    )
            ])
        );
    }

    private getBuilder(trackerType: TagType, trackers: TagTracker[]): TagBuilder {
        const tagBuilder: TagBuilder = this.configureTracker(trackerType, trackers);
        this.loadedBuilderServices.push(tagBuilder);
        return tagBuilder;
    }

    private configureTracker(type: TagType, trackers: TagTracker[]): TagBuilder {
        switch (type) {
            case TagType.GA:
                return this.ga.configure(trackers);
            case TagType.GTAG:
                return this.gtag.configure(trackers);
            case TagType.HOTJAR:
                return this.hotjar.configure({ tracker: trackers[0], version: 6 });
        }
    }
}
