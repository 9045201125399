<div class="business-card-title__container">
    <p class="business-card-title__name" [style.font-size.px]="nameFontSize">
        <span *ngIf="canDisplayField('title') && person.title" class="business-card-title__name">
            {{ person.title }}
        </span>

        <span *ngIf="canDisplayField('first_name') && person.first_name" class="business-card-title__first-name">
            {{ person.first_name }}
        </span>

        <span *ngIf="canDisplayField('last_name') && person.last_name" class="business-card-title__last-name">
            {{ person.last_name }}
        </span>
    </p>

    <p *ngIf="canDisplayField('pronouns') && person.pronouns && !stacked" class="business-card-title__suffix">
        {{ '(' + person.pronouns + ')' }}
    </p>

    <p *ngIf="canDisplayField('job_title') && person.job_title && !stacked" class="business-card-title__jobtitle">
        {{ person.job_title }}
    </p>

    <p *ngIf="canDisplayField('company') && person.company && !stacked" class="business-card-title__company">
        {{ person.company }}
    </p>
</div>
