import { NgModule } from '@angular/core';
import { AppCommonModule } from '@common/common.module';

import { CookieBannerComponent } from './components/cookie-banner/cookie-banner.component';
import { CookieCustomisationBannerComponent } from './components/cookie-customisation-banner/cookie-customisation-banner.component';
import { GlobalMessageBannerComponent } from './components/global-message-banner/global-message-banner.component';
import { BannerStoreModule } from './store/banner-store.module';

@NgModule({
    declarations: [GlobalMessageBannerComponent, CookieBannerComponent, CookieCustomisationBannerComponent],
    imports: [AppCommonModule, BannerStoreModule],
    exports: [GlobalMessageBannerComponent, CookieBannerComponent]
})
export class BannerModule {}
