import { createSelector } from '@ngrx/store';
import { AppState } from '../../app-state.model';

const selectAppCoreState = (state: AppState) => state.appCore;

const selectAppInitialising = createSelector(selectAppCoreState, (appCoreState) => {
    return appCoreState.appInitialising;
});

const selectAppName = createSelector(selectAppCoreState, (appCoreState) => {
    return appCoreState.appName;
});

const selectAppSettings = createSelector(selectAppCoreState, (appCoreState) => {
    return appCoreState.appSettings;
});

const selectOneSignalUserId = createSelector(selectAppCoreState, (appCoreState) => {
    return appCoreState.oneSignalUserId;
});

export const AppCoreSelectors = {
    selectAppInitialising,
    selectAppName,
    selectAppSettings,
    selectOneSignalUserId
};
