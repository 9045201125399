import { Injectable } from '@angular/core';
import { Store, Action } from '@ngrx/store';
import { VideoCall } from '../models/video-call.interface';
import { VideoCallState } from '../store/models/video-call.state';
import { VideoSelectors } from '../store/selectors/video-call.selector';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class VideoCallFacadeService {
    constructor(private store: Store<VideoCallState>) {}

    dispatch(action: Action): void {
        this.store.dispatch(action);
    }

    getCall(): Observable<VideoCall> {
        return this.store.select(VideoSelectors.selectVideoCall);
    }
}
