<div
    *ngIf="{
        app: app$ | async,
        user: user$ | async,
        cookieConsent: cookieConsent$ | async
    } as data"
    class="mux-player__container"
>
    <mux-player
        *ngIf="data.app && data.user"
        class="mux-player__player"
        #muxPlayer
        [attr.playback-id]="playbackTokens ? playbackTokens.playback_id : undefined"
        [attr.playback-token]="playbackTokens ? playbackTokens.playback_token : undefined"
        [attr.thumbnail-token]="playbackTokens ? playbackTokens.thumbnail_token : undefined"
        [attr.storyboard-token]="playbackTokens ? playbackTokens.storyboard_token : undefined"
        [attr.src]="overrideSrc ?? undefined"
        [attr.stream-type]="streamType"
        [attr.accent-color]="data.app.theme.accent_color"
        [attr.autoplay]="autoPlay ? 'any' : null"
        [attr.loop]="loop ? true : null"
        [attr.muted]="muted ? true : null"
        [attr.start-time]="startTime ?? null"
        [attr.poster]="poster ?? null"
        [attr.default-hidden-captions]="true"
        [attr.metadata-viewer-user-id]="data.user.username"
        [attr.metadata-video-series]="data.app.url_name"
        [attr.metadata-video-title]="analyticsTitle"
        [attr.metadata-video-id]="contentId"
        [attr.metadata-custom-1]="isSimulive"
        [attr.disable-cookies]="!data.cookieConsent"
        [attr.env-key]="analyticsMuxDataKey"
        [ngClass]="{ 'hide-seek-controls': isSimulive }"
        (loadeddata)="onVideoLoaded()"
        (play)="onVideoPlayed()"
        (pause)="onVideoPaused()"
        (ended)="onVideoEnded()"
    ></mux-player>

    <div *ngIf="showUnmuteButton" class="mux-player__unmute" @fadeOut>
        <ion-button shape="round" (click)="onUnmuteClicked()">
            <i class="material-icons-outlined" slot="start" aria-hidden="true">volume_off</i>
            <ion-label>{{ 'VIDEO_UNMUTE' | translate }}</ion-label>
        </ion-button>
    </div>
</div>
