import { MeetingCategory } from '@features/meeting-booking/enums/meeting-category.enum';
import { MeetingTimeSegment } from '@features/meeting-booking/interfaces/meeting-time-segment.interface';
import { createAction, props } from '@ngrx/store';
import { PaginatedResponse } from '@shared/pagination/models/paginated-response.model';
import { MeetingAvailabilityResponse } from '../models/meeting-availability-segment.interface';
import { ExpandedMeeting } from '../models/meeting.model';

const prefix = `[Meeting Booking] `;

const fetchMeetingList = createAction(`${prefix} Fetch meeting list`, props<{ status: MeetingCategory }>());

const fetchMeetingListSuccess = createAction(
    `${prefix} Fetch meeting list success`,
    props<{ status: MeetingCategory; meetings: PaginatedResponse<ExpandedMeeting> }>()
);

const fetchMoreMeetings = createAction(`${prefix} Fetch more meetings`, props<{ status: MeetingCategory }>());

const fetchMoreMeetingsSuccess = createAction(
    `${prefix} Fetch more meetings success`,
    props<{ status: MeetingCategory; meetings: PaginatedResponse<ExpandedMeeting> }>()
);

const cancelMeeting = createAction(`${prefix} Cancel meeting`, props<{ meetingId: string; status: MeetingCategory }>());

const cancelMeetingSuccess = createAction(
    `${prefix} Cancel meeting success`,
    props<{ meetingId: string; status: MeetingCategory }>()
);

const cancelRecurringMeeting = createAction(
    `${prefix} Cancel recurring meeting`,
    props<{ meetingId: string; recurrenceParentId: string; originalStart: string; status: MeetingCategory }>()
);

const cancelRecurringMeetingSuccess = createAction(
    `${prefix} Cancel recurring meeting success`,
    props<{ meetingId: string; status: MeetingCategory }>()
);

const cancelRecurringSeries = createAction(
    `${prefix} Cancel recurring meeting series`,
    props<{ meetingId: string; status: MeetingCategory }>()
);

const acceptMeeting = createAction(`${prefix} Accept meeting`, props<{ meetingId: string }>());

const declineMeeting = createAction(`${prefix} Decline meeting`, props<{ meetingId: string }>());
const declineMeetingSeries = createAction(`${prefix} Decline meeting series`, props<{ meetingId: string }>());

const acceptMeetingSuccess = createAction(`${prefix} Accept meeting success`, props<{ meetingId: string }>());

const declineMeetingSuccess = createAction(`${prefix} Decline meeting success`, props<{ meetingId: string }>());

const acceptRecurringMeeting = createAction(
    `${prefix} Accept recurring meeting`,
    props<{ meetingId: string; recurrenceParentId: string; originalStart: string }>()
);

const acceptRecurringtMeetingSuccess = createAction(
    `${prefix} Accept recurring meeting success`,
    props<{ meetingId: string }>()
);

const declineRecurringMeeting = createAction(
    `${prefix} Decline recurring meeting`,
    props<{ meetingId: string; recurrenceParentId: string; originalStart: string }>()
);

const declineRecurringMeetingSuccess = createAction(
    `${prefix} Decline recurring meeting success`,
    props<{ meetingId: string }>()
);

const getMyAvailability = createAction(`${prefix} Get my availability`, props<{ date: string }>());

const getMyAvailabilitySuccess = createAction(
    `${prefix} Get my availability success`,
    props<{ availability: Array<MeetingAvailabilityResponse> }>()
);

const updateStoredAvailability = createAction(
    `${prefix} Update stored availability segments`,
    props<{ segments: Array<MeetingTimeSegment> }>()
);

const changeAvailabilityTabDate = createAction(`${prefix} Change availability tab date`, props<{ date: string }>());

const updateStoredAvailabilityDate = createAction(
    `${prefix} Update stored availability date`,
    props<{ date: string }>()
);

const persistSavedAvailability = createAction(`${prefix} Persist saved availability`);

const setAvailabilityLoading = createAction(`${prefix} Set availability loading`, props<{ loading: boolean }>());

const resetSelectedAvailability = createAction(`${prefix} Reset selected availability`);

const initialiseSidebar = createAction(`${prefix} Initialise sidebar`);

const downloadMeetings = createAction(`${prefix} Download meetings`, props<{ status: MeetingCategory }>());
const downloadMeetingsSuccess = createAction(`${prefix} Download meetings success`);

export const MeetingBookingSidebarActions = {
    fetchMeetingList,
    fetchMeetingListSuccess,
    acceptMeeting,
    cancelMeeting,
    cancelMeetingSuccess,
    declineMeeting,
    acceptMeetingSuccess,
    declineMeetingSuccess,
    getMyAvailability,
    getMyAvailabilitySuccess,
    updateStoredAvailability,
    updateStoredAvailabilityDate,
    persistSavedAvailability,
    changeAvailabilityTabDate,
    setAvailabilityLoading,
    resetSelectedAvailability,
    initialiseSidebar,
    fetchMoreMeetings,
    fetchMoreMeetingsSuccess,
    acceptRecurringMeeting,
    acceptRecurringtMeetingSuccess,
    declineRecurringMeeting,
    declineRecurringMeetingSuccess,
    cancelRecurringMeeting,
    cancelRecurringMeetingSuccess,
    cancelRecurringSeries,
    declineMeetingSeries,
    downloadMeetings,
    downloadMeetingsSuccess
};
