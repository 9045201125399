import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { assign, pickBy } from 'lodash';

export class I18nPruningTranslationLoader implements TranslateLoader {
    constructor(
        private httpClient: HttpClient,
        private prefix: string,
        private suffix: string
    ) {}

    getTranslation(currentLang: string): Observable<any> {
        const backupLang = 'en';
        const languageFiles = [currentLang, backupLang].map((lang) => `${this.prefix}${lang}${this.suffix}`);
        return forkJoin(languageFiles.map((url) => this.httpClient.get(url))).pipe(
            map(([currentLanguage, backupLanguage]) => this.process(currentLanguage, backupLanguage))
        );
    }

    private process(currentLanguage: any, backupLanguage: any): any {
        // Remove entries which have empty values
        const processedLanguage = pickBy(currentLanguage);
        return assign(backupLanguage, processedLanguage);
    }
}
