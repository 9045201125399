import { ContentOrderingCriteria, ContentOrderingSettings } from '@shared/api';
import { ListIterator, Many, NotVoid, orderBy } from 'lodash';

/*
 * Shared function for sorting a collection of items based on ContentOrderingSettings.
 * Note that this is a function rather than being part of a service so that it can be used inside of NgRx selectors.
 */
export function getSortedItems<T, I>(
    items: T[],
    orderingSettings: ContentOrderingSettings,
    orderedIds: I[],
    selectId: (T) => I = (item) => item.id
): T[] {
    let sortCriteria: Many<ListIterator<T, NotVoid>> = [];
    let sortDirection = [];

    if (orderingSettings.order_by === ContentOrderingSettings.OrderByEnum.Field) {
        sortCriteria = orderingSettings.fields.map((field) => {
            return (item: T) =>
                typeof item[field.field] === 'string' ? item[field.field].toLowerCase() : item[field.field];
        });
        sortDirection = orderingSettings.fields.map((f) => {
            return f.direction === ContentOrderingCriteria.DirectionEnum.Ascending ? 'asc' : 'desc';
        });
    } else if (orderingSettings.order_by === ContentOrderingSettings.OrderByEnum.Custom) {
        sortCriteria = [(item: T) => orderedIds.indexOf(selectId(item))];
        sortDirection = ['asc'];
    }

    return orderBy<T>(items, sortCriteria, sortDirection);
}

export function filterBySearchTerm<T>(
    collection: T[],
    searchTerm: string,
    searchProp: string[],
    nestedObject?: (T) => any
): T[] {
    return !searchTerm ? collection : filterItemsBySearchTerm(collection, searchTerm, searchProp, nestedObject);
}

function filterItemsBySearchTerm<T>(items: T[], searchTerm: string, keys: string[], nestedObject?: (T) => any): T[] {
    return items.filter((item) => {
        const searchedItem = !!nestedObject ? nestedObject(item) : item;
        return keys.some((key) => String(searchedItem[key]).toLowerCase().includes(searchTerm.toLowerCase()));
    });
}

export function isInCollection(collectionIds: number[], id: number): boolean {
    return collectionIds.some((collection) => collection === id);
}

export function removeDuplicates<T>(
    items: T[],
    predicate: (i1: T, i2: T) => boolean = (i1: T, i2: T) => i1 === i2
): T[] {
    return items.filter((i1, index) => items.findIndex((i2) => predicate(i1, i2)) === index);
}
