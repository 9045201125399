import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { ChallengeCompletionActions } from '@features/challenge-completion/store/actions/challenge-completion.actions';
import { GamificationApiService } from '@features/gamification/services/gamification-api/gamification-api.service';
import { GlobalScannerHandlerService } from '@features/global-scanner/interfaces/global-scanner-handler-service.interface';
import { GlobalScannerQrChallengeData } from '@features/global-scanner/interfaces/global-scanner-qr-data.interface';
import { ChallengeCompletion } from '@shared/api';
import { Observable, of, switchMap, take } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GlobalScannerChallengesService
    implements GlobalScannerHandlerService<GlobalScannerQrChallengeData, ChallengeCompletion, HttpErrorResponse>
{
    constructor(
        private appCoreFacadeService: AppCoreFacadeService,
        private gamificationApiService: GamificationApiService
    ) {}

    public canProcess(): Observable<{ processable: boolean; message?: string }> {
        return of({ processable: true });
    }

    public processScan(qrData: GlobalScannerQrChallengeData): Observable<ChallengeCompletion> {
        return this.appCoreFacadeService.getAppName().pipe(
            take(1),
            switchMap((appName) =>
                this.gamificationApiService.submitScanChallenge(appName, qrData.module_id, qrData.value)
            )
        );
    }

    public onSuccess(challengeCompletion: ChallengeCompletion): Observable<unknown> {
        this.appCoreFacadeService.dispatch(
            ChallengeCompletionActions.challengeCompleted({
                completion: challengeCompletion,
                challengeId: challengeCompletion.challenge
            })
        );

        return of(null);
    }

    public onError(): Observable<unknown> {
        this.appCoreFacadeService.dispatch(ChallengeCompletionActions.challengeFailed({ challengeId: null }));

        return of(null);
    }
}
