import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { BehaviorSubject, Observable, Subscription, filter } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PwaUpdateService {
    public updateAvailable$: Observable<boolean>;
    private updateSubscription: Subscription | undefined;
    private updateAvailableSubject: BehaviorSubject<boolean>;

    constructor(
        private swUpdate: SwUpdate,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.updateAvailableSubject = new BehaviorSubject<boolean>(false);
        this.updateAvailable$ = this.updateAvailableSubject.asObservable();
    }

    public listenForUpdates(): void {
        if (!this.swUpdate.isEnabled) {
            return;
        }

        if (this.updateSubscription) {
            this.updateSubscription.unsubscribe();
        }

        this.updateSubscription = this.swUpdate.versionUpdates
            .pipe(filter((event) => event.type === 'VERSION_READY'))
            .subscribe(() => {
                this.updateAvailableSubject.next(true);
            });
    }

    public stopListeningForUpdates(): void {
        if (!this.updateSubscription) {
            return;
        }

        this.updateSubscription.unsubscribe();
    }

    public updateApp(): void {
        this.swUpdate.activateUpdate().then(() => {
            this.document.location.reload();
        });
    }
}
