import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Input } from '@angular/core';
import { MableCustomField, MableCustomFieldGroup } from '@shared/api';

@Component({
    selector: 'cc-custom-fields',
    templateUrl: './custom-fields.component.html',
    styleUrls: ['./custom-fields.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomFieldsComponent {
    @Input()
    customFields: (MableCustomField | MableCustomFieldGroup)[];
}
