import { createFeatureSelector, createSelector } from '@ngrx/store';
import { vodKey } from '../models/vod-app-state.model';
import { VodState } from '../models/vod-state.model';

const vodState = createFeatureSelector<VodState>(vodKey);

const moduleData = createSelector(vodState, (state) => state.module);
const isLoading = createSelector(vodState, (state) => state.isLoading);
const videoPlaybackTokens = createSelector(vodState, (state) => state.video.playbackTokens);
const isSearching = createSelector(vodState, (state) => state.isSearching);
const activeVideo = createSelector(vodState, (state) => state.video);
const selectTitle = createSelector(vodState, (state) => state.video?.video?.title);

export const VodSelectors = {
    moduleData,
    isLoading,
    videoPlaybackTokens,
    isSearching,
    activeVideo,
    selectTitle
};
