import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'cc-poll-controls',
    templateUrl: './poll-controls.component.html',
    styleUrls: ['./poll-controls.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PollControlsComponent {
    @Input()
    public submitting: boolean;

    @Input()
    public closed: boolean;

    @Input()
    public disabled: boolean;

    @Input()
    public showNextButton: boolean;

    @Input()
    public showPreviousButton: boolean;

    @Input()
    public submitButtonText: string = 'SUBMIT';

    @Output()
    public pollSubmit = new EventEmitter<void>();

    @Output()
    public pollNext = new EventEmitter<void>();

    @Output()
    public pollPrevious = new EventEmitter<void>();

    public onPollNext(): void {
        this.pollNext.emit();
    }

    public onPollPrevious(): void {
        this.pollPrevious.emit();
    }

    public onPollSubmit(): void {
        this.pollSubmit.emit();
    }
}
