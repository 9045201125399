export interface AppEmailTemplate {
    id?: number;
    app: string;
    template_type: AppEmailTemplate.TemplateTypeEnum;
    template_html?: string;
    template_txt?: string;
}

export namespace AppEmailTemplate {
    export enum TemplateTypeEnum {
        ResetPassword = 'reset_password',
        ValidateEmail = 'validate_email',
        DownloadNotes = 'download_notes',
        InviteEditProfile = 'invite_edit_profile',
        InviteEditCompany = 'invite_edit_company'
    }
}
