import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { Video } from '@api/models/Video';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { AuthenticationFacadeService } from '@core/authentication/services/authentication-facade.service';
import { LoginPromptService } from '@features/login/services/login-prompt.service';
import { VideoApiService } from '@features/video/services/video-api.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { IntersectionStatus } from '@shared/utilities/from-intersection-observer';
import { PlaybackTokens } from '@shared/mux/interfaces/playback-tokens.interface';

@Component({
    selector: 'cc-video',
    templateUrl: './video.component.html',
    styleUrls: ['./video.component.scss']
})
export class VideoComponent {
    @Input({ required: true })
    public videoid: Video['id'];

    @Input()
    public autoPlay = false;

    @Input()
    public muted = false;

    public video$: Observable<Video>;
    public playbackTokens$: Observable<PlaybackTokens>;
    public posterStyle: Record<string, string> | null;
    public error: string;

    public step$ = new BehaviorSubject<VideoStep>(VideoStep.Idle);
    public videoStepEnum = VideoStep;

    private loggedIn = this.authenticationFacadeService.isLoggedIn;
    private appUrl = this.appCoreFacadeService.appUrl;

    constructor(
        private appCoreFacadeService: AppCoreFacadeService,
        private authenticationFacadeService: AuthenticationFacadeService,
        private videoApiService: VideoApiService,
        private loginPromptService: LoginPromptService
    ) {}

    public play(step: VideoStep, event?: MouseEvent): void {
        event?.stopPropagation();
        event?.preventDefault();

        if (step !== VideoStep.WaitingForPlay) {
            return;
        }

        if (!this.loggedIn()) {
            this.loginPromptService.showPrompt('VIDEO_LOGIN_TO_WATCH');
            return;
        }

        this.step$.next(VideoStep.FetchingPlaybackInfo);

        this.playbackTokens$ = this.fetchPlaybackTokens$();
    }

    public onVisibilityChange(status: IntersectionStatus): void {
        if (status === IntersectionStatus.Visible && this.step$.value === VideoStep.Idle) {
            this.fetchVideo();
        }
    }

    public retry(event: MouseEvent): void {
        this.step$.next(VideoStep.WaitingForPlay);

        this.play(this.step$.value, event);
    }

    private fetchVideo(): void {
        this.step$.next(VideoStep.FetchingVideo);

        this.video$ = this.videoApiService.getVideo(this.appUrl(), this.videoid).pipe(
            tap((video) => {
                this.step$.next(VideoStep.WaitingForPlay);
                this.posterStyle = this.getPosterStyle(video);

                if (this.autoPlay) {
                    this.play(VideoStep.WaitingForPlay);
                }
            }),
            catchError((error: HttpErrorResponse) => this.handleError(error.statusText))
        );
    }

    private fetchPlaybackTokens$(): Observable<PlaybackTokens> {
        return this.videoApiService.getPlaybackIds(this.appUrl(), this.videoid).pipe(
            tap((playbackIds) => {
                if (playbackIds.length === 0 || !playbackIds[0].playback_id) {
                    throw new Error('Playback ids not found');
                }
            }),
            switchMap(([{ playback_id }]) =>
                this.videoApiService.getPlaybackTokens(this.appUrl(), this.videoid, playback_id)
            ),
            tap(() => this.step$.next(VideoStep.Ready)),
            catchError((error: HttpErrorResponse | Error) =>
                this.handleError(error instanceof HttpErrorResponse ? error.statusText : error.message)
            )
        );
    }

    private handleError(error: string): Observable<any> {
        this.step$.next(VideoStep.Error);
        this.error = error;
        return of(undefined);
    }

    private getPosterStyle(video: Video): Record<string, string> | null {
        if (!video.poster && !video.thumbnail) {
            return;
        }
        const imageUrl = video.poster || video.thumbnail;
        return { 'background-image': `url(${imageUrl})` };
    }
}

export enum VideoStep {
    Idle,
    FetchingVideo,
    WaitingForPlay,
    FetchingPlaybackInfo,
    Ready,
    Error
}
