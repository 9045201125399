import { Injectable } from '@angular/core';
import { Module } from '@api';
import { ActionHandlerService } from '@common/services/actions-handler/action-handler.service';
import { AnalyticsOptionsService } from '@core/analytics/services/analytics-options.service';
import { AnalyticsService } from '@core/analytics/services/analytics.service';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { AppRoutingActions } from '@core/root-store/store/app-routing/actions/app-routing.actions';
import { AppPageRoutes } from '@core/routing/constants/app-page-routes.constant';
import { AppModuleRoutes } from '@core/routing/constants/module-routes.constant';
import { DirectLinkApiService } from '@features/direct-link/services/direct-link-api/direct-link-api.service';
import { Observable } from 'rxjs';
import { ModuleApiService } from '../module-api/module-api.service';

@Injectable({
    providedIn: 'root'
})
export class ModuleService {
    constructor(
        private appCoreFacadeService: AppCoreFacadeService,
        private moduleApiService: ModuleApiService,
        private analyticsService: AnalyticsService,
        private analyticsOptionsService: AnalyticsOptionsService,
        private actionHandlerService: ActionHandlerService,
        private directLinkApiService: DirectLinkApiService
    ) {}

    public getModule(appUrl: string, moduleId: number | string): Observable<Module> {
        return this.moduleApiService.getModule(appUrl, moduleId);
    }

    public async handleModule(module: Module): Promise<void> {
        const options = this.analyticsOptionsService.clickItem('module', module.id);
        this.analyticsService.emit(options);

        if (module.type === Module.TypeEnum.Directlink) {
            const directLinkModule = await this.directLinkApiService
                .getDirectLinkModule(module.app, module.id)
                .toPromise();

            this.actionHandlerService.handleActions(directLinkModule.actions);
            return;
        }

        const moduleRoute = AppModuleRoutes[module.type];

        this.appCoreFacadeService.dispatch(AppRoutingActions.goToAppModule({ urlSegments: [module.id, moduleRoute] }));
    }

    public getRouterCommandsForModule(module: Module): Array<string | number> {
        const baseCommands = [AppPageRoutes.module, module.id];

        if (module.type === Module.TypeEnum.Directlink) {
            return baseCommands;
        }

        return [...baseCommands, AppModuleRoutes[module.type]];
    }
}
