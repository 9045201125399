import { MableCustomField, MableCustomFieldGroup, People, PeopleModule, PeopleVisibility } from '@shared/api';
import { BusinessCard } from '@shared/business-cards/interfaces/business-card.interface';
import { AppState } from '../../../../core/root-store/store/app-state.model';
import { ExpandedPeople } from '../../models/expanded-people.model';
import { PeopleSessions } from '../../models/people-session.model';
import { TagFilterMode } from '@shared/tags/enums/tag-filter-mode.enum';
import { AppTag } from '@shared/tags/interfaces/app-tag.interface';

export const peopleStoreKey = 'people';

export interface PeopleState {
    peopleModule: { [key: number]: PeopleModule };
    detailState: PeopleDetailState;
    listState: PeopleListState;
    filterState: PeopleFilterState;
    peopleVisibility: PeopleVisibility;
}

export interface PeopleDetailState {
    initialising: boolean;
    person: ExpandedPeople;
    customFields: (MableCustomField | MableCustomFieldGroup)[];
    sessions: PeopleSessions;
    businessCard: BusinessCard;
}

export interface PeopleListState {
    moduleId: number;
    module: PeopleModule;
    initialising: boolean;
    refreshing: boolean;
    pagination: PeoplePaginationState;
    pages: { [pageNumber: number]: number[] };
    scrollTopDisabled: boolean;
    scrollBottomDisabled: boolean;
    people: People[];
}

export interface PeopleFilterState {
    filters: AppTag[];
    appliedFilters: AppTag[];
    filterMode: TagFilterMode;
}

export interface PeoplePaginationState {
    search: string;
    page: number;
    limit: number;
    visiblePages: number[];
}

export interface PeopleAppState extends AppState {
    [peopleStoreKey]: PeopleState;
}
