import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ImageViewerComponent } from '@shared/images/components/image-viewer/image-viewer.component';

@Injectable({
    providedIn: 'root'
})
export class ImageViewerService {
    constructor(private modalController: ModalController) {}

    public async show(imageUrl: string): Promise<void> {
        const imageViewer = await this.modalController.create({
            component: ImageViewerComponent,
            componentProps: {
                imageUrl
            },
            cssClass: 'image-viewer'
        });
        return await imageViewer.present();
    }
}
