import { Injectable } from '@angular/core';
import { GoogleAnalyticsEventType } from '../enums/google-analytics-event-type.enum';
import { GoogleAnalyticOptions } from '../interfaces/google-analytics-options.interface';

@Injectable({
    providedIn: 'root'
})
export class AnalyticsOptionsService {
    constructor() {}

    viewPage(key: string, value: string | number, label?: string): GoogleAnalyticOptions {
        return this.createOptions(key, value, label, GoogleAnalyticsEventType.View);
    }

    clickItem(key: string, value: string | number, label?: string): GoogleAnalyticOptions {
        return this.createOptions(key, value, label, GoogleAnalyticsEventType.Click);
    }

    createOptions(
        key: string,
        value: string | number,
        label: string,
        event: GoogleAnalyticsEventType
    ): GoogleAnalyticOptions {
        return {
            event,
            item: {
                key,
                value: value?.toString()
            },
            label
        };
    }
}
