import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppCommonModule } from '@common/common.module';
import { BannerModule } from '@core/banner/banner.module';
import { NavigationModule } from '@core/navigation/navigation.module';
import { NotificationsModule } from '@core/notifications/notifications.module';
import { AppCoreActions } from '@core/root-store/store/app-core/actions/app-core.actions';
import { AppState } from '@core/root-store/store/app-state.model';
import { PopupChatModule } from '@features/chat/popup-chat.module';
import { Store } from '@ngrx/store';
import { AppRoutingModule } from '../routing/app-routing.module';
import { AppLayoutComponent } from './components/app-layout/app-layout.component';
import { AppPageComponent } from './pages/app-page/app-page.component';

export const appCoreInitialiserFactory = (coreStore: Store<AppState>): any => {
    return () => {
        coreStore.dispatch(AppCoreActions.initialisePlatform());
    };
};

export const appCoreInitialiser = {
    provide: APP_INITIALIZER,
    useFactory: appCoreInitialiserFactory,
    multi: true,
    deps: [Store]
};

@NgModule({
    imports: [AppCommonModule, NavigationModule, AppRoutingModule, BannerModule, PopupChatModule, NotificationsModule],
    declarations: [AppPageComponent, AppLayoutComponent],
    exports: [AppPageComponent],
    providers: [appCoreInitialiser]
})
export class AppCoreModule {}
