import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const AppCoreActions = createActionGroup({
    source: 'App Core',
    events: {
        'Initialise Platform': emptyProps(),
        'Initialise App': props<{ appName: string }>(),
        'Initialise App Success': emptyProps(),
        'Initialise App Failure': props<{ error: HttpErrorResponse }>(),
        'Set App Name': props<{ appName: string }>(),
        'Load App Settings': props<{ appName: string }>(),
        'Refresh App Settings': props<{ appName: string }>(),
        'Navigate Home': emptyProps(),
        'Initialise Push Notifications Success': props<{ osUserId: string }>(),
        'Initialise App Language Success': emptyProps(),
        'Initialise Analytics Success': emptyProps(),
        'Initialise Branding': emptyProps(),
        'Initialise Status Bar Success': emptyProps()
    }
});
