import { ElementRef, Injectable } from '@angular/core';
import QrScanner from 'qr-scanner';
import { Observable, Subject } from 'rxjs';
import { QrScannerFactoryService } from '../qr-scanner-factory/qr-scanner-factory.service';

@Injectable({
    providedIn: 'root'
})
export class QrScannerService {
    public scannerStream: Observable<string>;

    private scannerSubject: Subject<string>;
    private scanner: QrScanner;

    constructor(private qrScannerFactoryService: QrScannerFactoryService) {}

    public createScanner(videoElementRef: ElementRef<HTMLVideoElement>): void {
        this.scannerSubject = new Subject<string>();
        this.scannerStream = this.scannerSubject.asObservable();
        const videoElement = videoElementRef.nativeElement;
        this.scanner = this.qrScannerFactoryService.createQrScanner(videoElement, this.scannerSubject);
    }

    public startScanner(): Promise<void> {
        if (!this.scanner) {
            return;
        }

        return this.scanner.start();
    }

    public stopScanner(): void {
        if (!this.scanner) {
            return;
        }

        this.scanner.stop();
    }

    public pauseScanner(): Promise<boolean> {
        if (!this.scanner) {
            return;
        }

        return this.scanner.pause();
    }

    public cleanupScanner(): void {
        if (!this.scanner) {
            return;
        }

        this.stopScanner();
        this.destroyScanner();
    }

    public destroyScanner(): void {
        if (!this.scanner) {
            return;
        }

        this.scanner.destroy();
        this.scanner = undefined;
    }

    public hasCamera(): Promise<boolean> {
        return QrScanner.hasCamera();
    }
}
