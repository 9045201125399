<div
    *ngIf="{
        canAttendMeetings: canAttendMeetings$ | async,
        canChat: canChat$ | async,
        canCall: canCall$ | async,
        minimalPerson: minimalPerson$ | async
    } as data"
    class="person-popover__container"
    @fadeIn
>
    <div class="person-popover__header-container">
        <cc-person-icon class="person-popover__icon" [person]="data.minimalPerson"></cc-person-icon>

        <ion-label class="person-popover__label">
            <h2 *ngIf="data.minimalPerson.first_name && data.minimalPerson.last_name" class="person-popover__name">
                <cc-person-name [person]="data.minimalPerson" excludes="pronouns"> </cc-person-name>
            </h2>

            <p *ngIf="data.minimalPerson.pronouns" class="person-popover__pronouns">
                ({{ data.minimalPerson.pronouns }})
            </p>

            <p *ngIf="data.minimalPerson.job_title" class="person-popover__job-title">
                {{ data.minimalPerson.job_title }}
            </p>

            <p *ngIf="data.minimalPerson.company" class="person-popover__company">
                {{ data.minimalPerson.company }}
            </p>
        </ion-label>

        <ion-button
            class="person-popover__button person-popover__button--close ion-no-margin"
            fill="clear"
            size="small"
            color="default"
            shape="round"
            [attr.aria-label]="'PERSON_POPOVER_CLOSE_BUTTON_HINT' | translate"
            (click)="close()"
        >
            <i class="material-icons-outlined" aria-hidden="true">close</i>
        </ion-button>
    </div>

    <div class="person-popover__buttons">
        <ion-button
            class="person-popover__button person-popover__button--person ion-no-margin"
            fill="clear"
            size="small"
            color="default"
            shape="round"
            [attr.aria-label]="'PERSON_POPOVER_PROFILE_BUTTON_HINT' | translate"
            [tooltip]="'PERSON_POPOVER_PROFILE_BUTTON_HINT' | translate"
            [options]="tooltipOptions"
            (click)="openPerson()"
        >
            <i class="material-icons-outlined" aria-hidden="true">account_circle</i>
        </ion-button>

        <ion-button
            *ngIf="data.canCall"
            class="person-popover__button person-popover__button--call ion-no-margin"
            fill="clear"
            size="small"
            color="default"
            shape="round"
            [attr.aria-label]="'PERSON_POPOVER_VBO_BUTTON_HINT' | translate"
            [tooltip]="'PERSON_POPOVER_VBO_BUTTON_HINT' | translate"
            [options]="tooltipOptions"
            (click)="videoCall(data.minimalPerson)"
        >
            <i class="material-icons-outlined" aria-hidden="true">voice_chat</i>
        </ion-button>

        <ion-button
            *ngIf="data.canChat"
            class="person-popover__button person-popover__button--chat ion-no-margin"
            fill="clear"
            size="small"
            color="default"
            shape="round"
            [attr.aria-label]="'PERSON_POPOVER_CHAT_BUTTON_HINT' | translate"
            [tooltip]="'PERSON_POPOVER_CHAT_BUTTON_HINT' | translate"
            [options]="tooltipOptions"
            (click)="startChat()"
        >
            <i class="material-icons-outlined" aria-hidden="true">textsms</i>
        </ion-button>

        <ion-button
            *ngIf="data.canAttendMeetings"
            class="person-popover__button person-popover__button--meeting ion-no-margin"
            fill="clear"
            size="small"
            color="default"
            shape="round"
            [attr.aria-label]="'PERSON_POPOVER_MEETING_BUTTON_HINT' | translate"
            [tooltip]="'PERSON_POPOVER_MEETING_BUTTON_HINT' | translate"
            [options]="tooltipOptions"
            (click)="scheduleMeeting()"
        >
            <i class="material-icons-outlined" aria-hidden="true">date_range</i>
        </ion-button>
    </div>
</div>
