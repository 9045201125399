export enum PollSubmitContentType {
    Session = 'session',
    Abstracts = 'ccabstracts.abstractsubmission'
}

export enum PollFetchContentType {
    Abstracts = 'abstractsubmission'
}

export type PollContentType = PollSubmitContentType | PollFetchContentType;
