import { AppRoutingSelectors } from '@core/root-store/store/app-routing/selectors/app-routing.selectors';
import { LivePollingRouteParams } from '@features/live-polling/constants/live-polling-route-params.constant';
import { createSelector } from '@ngrx/store';
import { sortBy } from 'lodash';
import { PollItem } from '../../models/poll-item.model';
import { LivePollingAppState, livePollingKey } from '../models/live-polling-app-state.model';

const selectLivePollingState = (state: LivePollingAppState) => state[livePollingKey];

const selectLivePollGroupItem = (livePollGroupId: number) =>
    createSelector(selectLivePollingState, (livePollingState) => livePollingState.livePollGroupItems[livePollGroupId]);

const selectLivePollingModule = (livePollingModuleId: number) =>
    createSelector(
        selectLivePollingState,
        (livePollingState) => livePollingState.livePollingModules[livePollingModuleId]
    );

const selectLivePollGroup = (livePollGroupId: number) =>
    createSelector(
        selectLivePollGroupItem(livePollGroupId),
        (livePollGroupItem) => livePollGroupItem && livePollGroupItem.livePollGroup
    );

const selectPollGroup = (livePollGroupId: number) =>
    createSelector(
        selectLivePollGroupItem(livePollGroupId),
        (livePollGroupItem) => livePollGroupItem && livePollGroupItem.pollGroup
    );

const selectPollIntermission = (livePollGroupId: number) =>
    createSelector(selectLivePollGroupItem(livePollGroupId), (livePollGroupItem) => {
        if (!livePollGroupItem) {
            return undefined;
        }
        const objectId = livePollGroupItem.livePollGroup.object_id;
        return livePollGroupItem.pollGroup.intermissions.find((intermission) => intermission.id === objectId);
    });

const selectPollItem = (livePollGroupId: number) =>
    createSelector(selectLivePollGroupItem(livePollGroupId), (livePollGroupItem) => {
        if (!livePollGroupItem || !livePollGroupItem.pollGroup) {
            return undefined;
        }

        const objectId = livePollGroupItem.livePollGroup.object_id;

        const poll = livePollGroupItem.pollGroup.polls.find((p) => p.id === objectId);

        if (!poll) {
            return undefined;
        }

        const pollResponse = livePollGroupItem.pollResponse;

        const choicePoll = poll.choicepoll;

        const allowedNumberOfResponses = livePollGroupItem.allowedNumberOfResponses;
        const hasSubmittedProxyResponses = livePollGroupItem.hasSubmittedProxyResponses;

        const pollItem: PollItem = {
            poll,
            textPoll: poll.textpoll,
            ratingPoll: poll.ratingpoll,
            choicePoll: poll.choicepoll,
            choicePollChoices: choicePoll ? sortBy(choicePoll.choices, 'order') : undefined,
            pollResponse,
            allowedNumberOfResponses,
            hasSubmittedProxyResponses
        };

        return pollItem;
    });

const selectLivePollPageState = createSelector(
    selectLivePollingState,
    (livePollingState) => livePollingState.livePollPageState
);

const selectLivePollingLandingPageState = createSelector(
    selectLivePollingState,
    (livePollingState) => livePollingState.livePollingLandingPageState
);

const selectTitle = createSelector(selectLivePollingState, AppRoutingSelectors.selectRouteParams, (state, params) => {
    const livePollId = +params[LivePollingRouteParams.livePollingGroupId];
    return state.livePollGroupItems[livePollId]?.pollGroup.title;
});

export const LivePollingSelectors = {
    selectLivePollingModule,
    selectLivePollGroup,
    selectPollGroup,
    selectPollItem,
    selectPollIntermission,
    selectLivePollPageState,
    selectLivePollingLandingPageState,
    selectTitle
};
