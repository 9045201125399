import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AppTag, VisualAppTag } from '@shared/tags/interfaces/app-tag.interface';

@Component({
    selector: 'cc-app-tag',
    templateUrl: './app-tag.component.html',
    styleUrls: ['./app-tag.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppTagComponent {
    @Input() public tag: AppTag | VisualAppTag;
}
