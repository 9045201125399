import { createFeatureSelector, createSelector } from '@ngrx/store';
import { abstractsKey } from '../models/abstracts-app-state.model';
import { AbstractsState } from '../models/abstracts-state.model';

const abstractsState = createFeatureSelector<AbstractsState>(abstractsKey);

const abstractsModule = createSelector(abstractsState, (state) => state.module);
const filters = createSelector(abstractsState, (state) => state.list?.filters);
const appliedFilters = createSelector(abstractsState, (state) => state.list?.appliedFilters);
const filterMode = createSelector(abstractsState, (state) => state.list?.filterMode);
const filterFavourites = createSelector(abstractsState, (state) => state.list?.filterFavourites);
const layout = createSelector(abstractsState, (state) => state.list?.layout);
const abstractsModuleLoading = createSelector(abstractsState, (state) => state.loading);
const submissionDetail = createSelector(abstractsState, (state) => state.detail?.submission);
const submissionInitialising = createSelector(abstractsState, (state) => state.detail?.initialising);
const submissionCurrentTab = createSelector(abstractsState, (state) => state.detail?.currentTab);
const submissionImageDetails = createSelector(abstractsState, (state) => state?.detail?.imageDetails);
const selectTitle = createSelector(abstractsState, (state) => state.detail?.submission?.title);

export const AbstractsSelectors = {
    abstractsModule,
    abstractsModuleLoading,
    submissionDetail,
    submissionInitialising,
    submissionCurrentTab,
    submissionImageDetails,
    filters,
    appliedFilters,
    filterMode,
    filterFavourites,
    layout,
    selectTitle
};
