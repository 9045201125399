import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Module } from '@shared/api';
import { HttpClient } from '@angular/common/http';
import { ApiSettings } from '@shared/settings/api-settings.constant';

@Injectable({
    providedIn: 'root'
})
export class NavigationApiService {
    constructor(private http: HttpClient) {}

    public getModules(appName: string): Observable<Module[]> {
        const url = ApiSettings.fullUrl(appName, NavigationEndpoints.modules);
        return this.http.get<Module[]>(url);
    }
}

export const NavigationEndpoints = {
    modules: '/modules/'
};
