import { Injectable } from '@angular/core';
import { BannerAd } from '@shared/api';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiSettings } from '@shared/settings/api-settings.constant';

@Injectable({
    providedIn: 'root'
})
export class BannerAdApiService {
    constructor(private httpClient: HttpClient) {}

    public getBannerAds(appName: string): Observable<BannerAd[]> {
        const url = ApiSettings.fullUrl(appName, '/banners/');
        return this.httpClient.get<BannerAd[]>(url);
    }
}
