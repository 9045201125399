import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { fadeIn } from '@common/animations/animations';
import { AppDocument } from '@shared/api';

@Component({
    selector: 'cc-agenda-card-documents-list',
    templateUrl: './agenda-card-documents-list.component.html',
    styleUrl: './agenda-card-documents-list.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [fadeIn()]
})
export class AgendaCardDocumentsListComponent {
    @Input({ required: true })
    public documents: Array<AppDocument>;
}
