import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ApiSettings } from '../../../../shared/settings/api-settings.constant';
import { GamificationApiSettings } from '../../constants/gamification-api-settings.constant';
import { AppChallenge, ChallengeAttempt, ChallengeCompletion, ChallengeModule, LeaderboardScore } from '@shared/api';
import { PaginatedResponse } from '@shared/pagination/models/paginated-response.model';
import { GamificationProgress } from '../../models/GamificationProgress';
import { ChallengeProgress } from '../../interfaces/challenge-progress.interface';
import { ChallengeListFilter } from '@features/gamification/enum/challenge-list-filter.enum';

@Injectable({
    providedIn: 'root'
})
export class GamificationApiService {
    constructor(private httpClient: HttpClient) {}

    public submitSingleChallenge(appName: string, challengeId: number, code: string): Observable<ChallengeCompletion> {
        const url = ApiSettings.fullUrl(appName, GamificationApiSettings.submitSingleChallenge(challengeId));
        return this.httpClient.post<ChallengeCompletion>(url, { code });
    }

    public submitScanChallenge(appName: string, moduleId: string, code: string): Observable<ChallengeCompletion> {
        const url = ApiSettings.fullUrl(appName, GamificationApiSettings.submitScanChallenge(moduleId));
        return this.httpClient.post<ChallengeCompletion>(url, { code });
    }

    public submitAllChallenges(
        appName: string,
        moduleId: number,
        code: string
    ): Observable<Array<ChallengeCompletion>> {
        const url = ApiSettings.fullUrl(appName, GamificationApiSettings.submitAllChallenges(moduleId));
        return this.httpClient.post<Array<ChallengeCompletion>>(url, { code });
    }

    public getChallengeModule(appName: string, moduleId: number): Observable<ChallengeModule> {
        const url = ApiSettings.fullUrl(appName, GamificationApiSettings.challengeModule(moduleId));
        return this.httpClient.get<ChallengeModule>(url);
    }

    public getPaginatedChallenges(
        appName: string,
        moduleId: number,
        query?: { limit?: number; offset?: number; only?: ChallengeListFilter }
    ): Observable<PaginatedResponse<AppChallenge>> {
        const url = ApiSettings.fullUrl(appName, GamificationApiSettings.paginatedChallenges(moduleId));
        return this.httpClient.get<PaginatedResponse<AppChallenge>>(url, { params: query });
    }

    public getChallenge(appName: string, challengeId: number): Observable<AppChallenge> {
        const url = ApiSettings.fullUrl(appName, GamificationApiSettings.challenge(challengeId));
        return this.httpClient.get<AppChallenge>(url);
    }

    public getChallengeProgress(appName: string, challengeId: number): Observable<ChallengeProgress> {
        const url = ApiSettings.fullUrl(appName, GamificationApiSettings.challengeProgress(challengeId));
        return this.httpClient.get<ChallengeProgress>(url);
    }

    public getChallengeAttempts(appName: string): Observable<ChallengeAttempt[]> {
        const url = ApiSettings.fullUrl(appName, GamificationApiSettings.challengeAttempts);
        return this.httpClient.get<ChallengeAttempt[]>(url);
    }

    public getChallengeCompletions(appName: string, moduleId: number): Observable<ChallengeCompletion[]> {
        const url = ApiSettings.fullUrl(appName, GamificationApiSettings.challengeCompletions(moduleId));
        return this.httpClient.get<ChallengeCompletion[]>(url);
    }

    public getGamificationProgress(appName: string, moduleId: number): Observable<GamificationProgress> {
        const url = ApiSettings.fullUrl(appName, GamificationApiSettings.progress(moduleId));
        return this.httpClient.get<GamificationProgress>(url);
    }

    public getLeaderboardInitialPage(
        appName: string,
        moduleId: number
    ): Observable<PaginatedResponse<LeaderboardScore>> {
        const url = ApiSettings.fullUrl(appName, GamificationApiSettings.leaderboardPage(moduleId));
        return this.httpClient.get<PaginatedResponse<LeaderboardScore>>(url);
    }

    public getLeaderboardPage(url: string): Observable<PaginatedResponse<LeaderboardScore>> {
        return this.httpClient.get<PaginatedResponse<LeaderboardScore>>(url);
    }
}
