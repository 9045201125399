import { Inject, Injectable } from '@angular/core';
import { AuthenticationFacadeService } from '@core/authentication/services/authentication-facade.service';
import { take } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { WINDOW } from '@core/window.service';
import { AuthenticationActions } from '@core/root-store/store/authentication/actions/authentication.actions';
import { DeviceService } from '@core/device/services/device.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DownloadService {
    constructor(
        private authenticationFacadeService: AuthenticationFacadeService,
        private deviceService: DeviceService,
        @Inject(WINDOW) private window: Window
    ) {}

    /** Appends valid download token to url and opens */
    public async download(resourceUrl: string): Promise<void> {
        const isAuthenticated = await this.authenticationFacadeService.isAuthenticated().pipe(take(1)).toPromise();
        if (!isAuthenticated) {
            return this.open(resourceUrl);
        }

        const token = await this.getValidatedToken();

        return this.openWithToken(resourceUrl, token);
    }

    /** Retrieves a valid token from the user auth and appends it to the provided URL */
    public async tokeniseDownloadUrl(resourceUrl: string): Promise<string> {
        const token = await this.getValidatedToken();
        return `${resourceUrl}?token=${token}`;
    }

    private async getValidatedToken(): Promise<string> {
        const userAuthInfo = await this.authenticationFacadeService.getUserAuthInfo().pipe(take(1)).toPromise();

        const validToken: boolean =
            !!userAuthInfo.auth.download_token &&
            DateTime.fromISO(userAuthInfo.auth.download_token_expiry) >= DateTime.now();

        if (validToken) {
            return userAuthInfo.auth.download_token;
        }

        // Refresh the download token, at this point it's expired
        this.authenticationFacadeService.dispatch(AuthenticationActions.refreshDownloadToken());
        const { token } = await lastValueFrom(
            this.authenticationFacadeService.refreshDownloadTokenSuccess$.pipe(take(1))
        );
        return token;
    }

    private openWithToken(url: string, token: string): void {
        if (!token) {
            return this.open(url);
        }
        const urlWithToken = `${url}?token=${token}`;

        return this.open(urlWithToken);
    }

    private open(url: string): void {
        if (this.deviceService.isSafari() || this.deviceService.isIos()) {
            this.window.location.assign(url);
        } else {
            this.window.open(url, '_blank');
        }
    }
}
