import { MeetingAvailabilityState } from '@features/meeting-booking/constants/meeting-availability-state.constant';
import { MeetingCategory } from '@features/meeting-booking/enums/meeting-category.enum';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { DateTime } from 'luxon';
import { MeetingBookingSidebarActions } from '../actions/meeting-booking-sidebar.actions';
import { MeetingBookingActions } from '../actions/meeting-booking.actions';
import { MeetingBookingState } from '../models/meeting-booking-state.model';
import { ExpandedMeeting } from '../models/meeting.model';

export const meetingListAdapters: Record<MeetingCategory, EntityAdapter<ExpandedMeeting>> = {
    [MeetingCategory.Upcoming]: createEntityAdapter<ExpandedMeeting>(),
    [MeetingCategory.Completed]: createEntityAdapter<ExpandedMeeting>(),
    [MeetingCategory.Sent]: createEntityAdapter<ExpandedMeeting>(),
    [MeetingCategory.Received]: createEntityAdapter<ExpandedMeeting>()
};

export const meetingBookingInitialState: MeetingBookingState = {
    availableTags: [],
    locations: [],
    meetings: {
        [MeetingCategory.Sent]: meetingListAdapters[MeetingCategory.Sent].getInitialState({
            loading: true,
            total: 0
        }),
        [MeetingCategory.Completed]: meetingListAdapters[MeetingCategory.Completed].getInitialState({
            loading: true,
            total: 0
        }),
        [MeetingCategory.Upcoming]: meetingListAdapters[MeetingCategory.Upcoming].getInitialState({
            loading: true,
            total: 0
        }),
        [MeetingCategory.Received]: meetingListAdapters[MeetingCategory.Received].getInitialState({
            loading: true,
            total: 0
        })
    },
    availability: {
        selectedDate: DateTime.now().toISODate(),
        selectedAvailability: undefined,
        receivedAvailability: [],
        loading: false
    },
    config: {
        min_length: 10,
        max_length: 0,
        meeting_start: '00:00',
        meeting_end: '23:59',
        meetings_start_date: undefined,
        meetings_end_date: undefined,
        meeting_type: undefined,
        smart_sessions_allowed: false
    },
    bookable: undefined
};

const meetingBookingReducerFn = createReducer(
    meetingBookingInitialState,
    on(MeetingBookingActions.loadMeetingBookingConfigSuccess, (state, action) => ({
        ...state,
        config: action.config
    })),
    on(MeetingBookingActions.setBookableRange, (state, { start, end }) => ({
        ...state,
        availability: {
            ...state.availability,
            selectedDate: DateTime.max(
                DateTime.fromISO(start),
                DateTime.fromISO(state.availability.selectedDate)
            ).toISODate()
        },
        bookable: {
            start,
            end
        }
    })),
    on(MeetingBookingSidebarActions.fetchMeetingListSuccess, (state, action) => ({
        ...state,
        meetings: {
            ...state.meetings,
            [action.status]: {
                ...meetingListAdapters[action.status].setAll(action.meetings.results, state.meetings[action.status]),
                total: action.meetings.count,
                loading: false
            }
        }
    })),
    on(MeetingBookingActions.getAvailableTagsSuccess, (state, { tags }) => ({
        ...state,
        availableTags: tags
    })),
    on(MeetingBookingActions.getLocationsSuccess, (state, { locations }) => ({
        ...state,
        locations
    })),
    on(
        MeetingBookingSidebarActions.changeAvailabilityTabDate,
        MeetingBookingSidebarActions.getMyAvailability,
        MeetingBookingSidebarActions.persistSavedAvailability,
        (state) => ({
            ...state,
            availability: {
                ...state.availability,
                loading: true
            }
        })
    ),
    on(MeetingBookingSidebarActions.updateStoredAvailabilityDate, (state, { date }) => ({
        ...state,
        availability: {
            ...state.availability,
            selectedDate: date,
            selectedAvailability: undefined
        }
    })),
    on(MeetingBookingSidebarActions.updateStoredAvailability, (state, { segments }) => ({
        ...state,
        availability: {
            ...state.availability,
            selectedAvailability: segments.reduce((accumulatedSegments, nextSegment) => {
                const lastAddedSegment = accumulatedSegments[accumulatedSegments.length - 1];

                /* We only store segments that are not selected, i.e. not available for meetings */
                if (nextSegment.state !== MeetingAvailabilityState.NotSelected) {
                    return accumulatedSegments;
                }

                /* If the start date of this selected element matches the end date of the previously stored element, combine them in to one segment */
                if (nextSegment.start === lastAddedSegment?.end) {
                    const arrayMinusLast = accumulatedSegments.slice(0, -1);

                    return [
                        ...arrayMinusLast,
                        {
                            start: lastAddedSegment.start,
                            end: nextSegment.end,
                            free: false
                        }
                    ];
                }

                /* When this selected segment does not immediately precede another selected element, create a new segment in the list */
                return [
                    ...accumulatedSegments,
                    {
                        start: nextSegment.start,
                        end: nextSegment.end,
                        free: false
                    }
                ];
            }, [])
        }
    })),
    on(MeetingBookingSidebarActions.getMyAvailabilitySuccess, (state, { availability }) => {
        return {
            ...state,
            availability: {
                ...state.availability,
                receivedAvailability: availability,
                loading: false
            }
        };
    }),
    on(MeetingBookingSidebarActions.resetSelectedAvailability, (state) => ({
        ...state,
        availability: {
            ...state.availability,
            selectedAvailability: undefined,
            loading: false
        }
    })),
    on(MeetingBookingSidebarActions.fetchMoreMeetingsSuccess, (state, action) => ({
        ...state,
        meetings: {
            ...state.meetings,
            [action.status]: {
                ...meetingListAdapters[action.status].addMany(action.meetings.results, state.meetings[action.status]),
                total: action.meetings.count
            }
        }
    })),
    on(
        MeetingBookingSidebarActions.acceptMeetingSuccess,
        MeetingBookingSidebarActions.acceptRecurringtMeetingSuccess,
        MeetingBookingSidebarActions.declineMeetingSuccess,
        MeetingBookingSidebarActions.declineRecurringMeetingSuccess,
        (state, action) => ({
            ...state,
            meetings: {
                ...state.meetings,
                [MeetingCategory.Received]: {
                    ...meetingListAdapters[MeetingCategory.Received].removeOne(
                        action.meetingId.toString(),
                        state.meetings[MeetingCategory.Received]
                    ),
                    total: Math.max(state.meetings[MeetingCategory.Received].total - 1, 0)
                },
                [MeetingCategory.Upcoming]: {
                    ...meetingListAdapters[MeetingCategory.Upcoming].removeOne(
                        action.meetingId.toString(),
                        state.meetings[MeetingCategory.Upcoming]
                    ),
                    total: Math.max(state.meetings[MeetingCategory.Upcoming].total - 1, 0)
                }
            }
        })
    ),
    on(
        MeetingBookingSidebarActions.cancelMeetingSuccess,
        MeetingBookingSidebarActions.cancelRecurringMeetingSuccess,
        (state, action) => ({
            ...state,
            meetings: {
                ...state.meetings,
                [action.status]: meetingListAdapters[action.status].removeOne(
                    action.meetingId,
                    state.meetings[action.status]
                )
            }
        })
    ),
    on(MeetingBookingActions.leavePage, () => meetingBookingInitialState)
);

export function meetingBookingReducer(state: MeetingBookingState | undefined, action: Action): MeetingBookingState {
    return meetingBookingReducerFn(state, action);
}
