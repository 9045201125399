import { animate, group, query, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BannerAd } from '@shared/api';
import { BannerAdApiService } from '@shared/banner-ad/services/banner-ad-api.service';
import { combineLatest, interval, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { AppCoreFacadeService } from '../../../../core/app-core/services/app-core-facade.service';
import { AuthenticationFacadeService } from '../../../../core/authentication/services/authentication-facade.service';
import { BannerAdService } from '../../services/banner-ad.service';

export const BANNER_INTERVAL = 4000;

@UntilDestroy()
@Component({
    selector: 'cc-banner-ad',
    templateUrl: './banner-ad.component.html',
    styleUrls: ['./banner-ad.component.scss'],
    animations: [
        trigger('slideAnimation', [
            transition(
                ':increment',
                group([
                    query(
                        ':enter',
                        [
                            style({
                                transform: 'translateX(100%)'
                            }),
                            animate('0.5s ease-out')
                        ],
                        { optional: true }
                    ),
                    query(
                        ':leave',
                        [
                            animate(
                                '0.5s ease-out',
                                style({
                                    transform: 'translateX(-100%)'
                                })
                            )
                        ],
                        { optional: true }
                    )
                ])
            )
        ])
    ]
})
export class BannerAdComponent implements OnInit {
    @Input()
    public moduleId: number;

    public banner$: Observable<BannerAd[]>;
    public count = 0;

    constructor(
        private bannerAdService: BannerAdService,
        private bannerAdApiService: BannerAdApiService,
        private appCoreFacadeService: AppCoreFacadeService,
        private authenticationFacadeService: AuthenticationFacadeService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    public ngOnInit(): void {
        const appUrl$ = this.appCoreFacadeService.getAppName();

        this.banner$ = combineLatest([this.authenticationFacadeService.hasAppAccess(), appUrl$]).pipe(
            filter(([hasAccess]) => hasAccess),
            switchMap(([, appUrl]) => this.bannerAdApiService.getBannerAds(appUrl)),
            map((banners) => banners.sort((a, b) => a.id - b.id)),
            untilDestroyed(this)
        );

        interval(BANNER_INTERVAL)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.count++;
                this.changeDetectorRef.markForCheck();
            });
    }

    public bannerClicked(banners: BannerAd[]): void {
        this.bannerAdService.handleBannerClick(banners[this.count % banners.length]);
    }
}
